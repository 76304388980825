import {Component, Input} from '@angular/core';
import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'nirby-hint',
    templateUrl: './hint.component.html',
    styleUrls: ['./hint.component.scss']
})
/**
 * Component to display a hint icon with a tooltip.
 */
export class HintComponent {
    hintIcon = faQuestionCircle;
    @Input() hint: string | null = null;
    @Input() hintPosition = 'right';
}
