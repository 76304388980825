<form [formGroup]="widgetForm" (ngSubmit)="createWidget()">
    <div class="form-group">
        <label class="subtitle">Name</label>
        <input
            type="text"
            class="form-control"
            placeholder="e.g. My Example App"
            autocomplete="off"
            formControlName="urlName"
            id="urlSite"
        />
        <div
            *ngIf="urlName?.touched && urlName?.invalid"
            class="alert alert-danger mt-3"
        >
            A name for this widget is required
        </div>
    </div>

    <label class="subtitle">Domain Name</label>
    <div class="form-group">
        <input
            type="text"
            class="form-control"
            placeholder="e.g. fixe.cl"
            autocomplete="off"
            formControlName="url"
            name="name"
            aria-describedby="baseURLHelpBlock"
        />
        <div id="baseURLHelpBlock" class="form-text text-muted mb-2">
            This considers only the domain or sub-domain. Do not include
            "https://", directories, paramenters, etc.
        </div>

        <h3 class="segment-title">Examples:</h3>
        <ul class="examples list-group">
            <li
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                fixe.cl
                <span class="badge badge-success badge-sm ms-2">Correct</span>
            </li>
            <li
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                https://fixe.cl/contact/?param=loremipsum
                <span class="badge badge-danger badge-sm ms-2">Incorrect</span>
            </li>
            <li
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                subdomain.fixe.cl
                <span class="badge badge-success badge-sm ms-2">Correct</span>
            </li>
            <li
                class="list-group-item d-flex justify-content-between align-items-center"
            >
                fixe
                <span class="badge badge-danger badge-sm ms-2">Incorrect</span>
            </li>
        </ul>
        <div
            *ngIf="url.touched && url.invalid && url.errors"
            class="alert alert-danger mt-3"
        >
            <div *ngIf="url.errors['required']">Domain Name is required.</div>
            <div *ngIf="url.errors['pattern']">This not a valid Domain Name.</div>
        </div>
    </div>

    <button
        class="btn btn-primary float-right"
        type="submit"
        *ngIf="!loading; else loadingButtonTemplate"
        [disabled]="!widgetForm.valid"
    >
        Generate widget
        <fa-icon class="arrow-icon" [icon]="arrowIcon"></fa-icon>
    </button>
</form>

<ng-template #loadingButtonTemplate>
    <button class="btn btn-primary float-right" type="button" disabled>
        <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
        ></span>
        Loading...
    </button>
</ng-template>
