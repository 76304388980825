import {UserRegistrationComponent} from './user-registration/user-registration.component';
import {
    ResetPasswordComponent
} from './reset-password/reset-password.component';
import {UserLoginComponent} from './user-login/user-login.component';
import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {IsUnauthenticatedGuard} from '../../guards';

export const routes: Routes = [
    {
        path: 'signup',
        component: UserRegistrationComponent,
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent,
    },
    {
        path: 'login',
        component: UserLoginComponent,
        canActivate: [IsUnauthenticatedGuard],
    },
    {
        path: '**',
        redirectTo: 'login',
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthRoutingModule {}
