<form [formGroup]="routeForm" (ngSubmit)="createNewRoute()">
    <div class="modal-header">
        <h4 class="modal-title">Add a new route</h4>
        <button
            type="button"
            class="close"
            aria-label="close"
            (click)="modal.dismiss('Cross click')"
        ></button>
    </div>

    <div class="modal-body">
        <label class="basic-url"> Your new url: </label>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <span
                    *ngIf="url$ | async as url"
                    class="input-group-text"
                    id="basic-addon3"
                    >{{ url }}/</span
                >
            </div>
            <input
                type="text"
                class="form-control"
                [formControl]="routeControl"
                id="basic-url"
                aria-describedby="basic-addon3"
            />
            <div
                *ngIf="routeControl.touched && routeControl.invalid"
                class="text-danger"
            >
                <span *ngIf="routeControl.errors?.['required']">
                    New route is required.</span
                >
                <span *ngIf="routeControl.errors?.['pattern']">
                    This is not a valid url.</span
                >
            </div>
        </div>
        <div class="input-group">
            <select class="form-control" [formControl]="routeTypeControl">
                <option [ngValue]="'match'">Exact match</option>
                <option [ngValue]="'begins-with'">Contains</option>
            </select>
        </div>
    </div>

    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-outline-secondary"
            (click)="modal.dismiss('cancel click')"
        >
            Cancel
        </button>
        <button
            type="submit"
            class="btn btn-outline-primary"
            (click)="modal.close('save click')"
            *ngIf="!loading; else loadingButtonTemplate"
            [disabled]="!routeForm.valid"
        >
            Save
        </button>
    </div>
</form>

<ng-template #loadingButtonTemplate>
    <button class="btn btn-primary float-right" type="button" disabled>
        <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
        ></span>
        Loading...
    </button>
</ng-template>
