export type VerticalAnchor = 'top' | 'middle' | 'bottom';
export type HorizontalAnchor = 'left' | 'center' | 'right';

export interface ResizeHandler {
    vertical: VerticalAnchor;
    horizontal: HorizontalAnchor;
}

interface TransformationBase {
    type: string;
}

export interface RotationTransformation extends TransformationBase {
    type: 'rotation';
}

interface ResizeTransformationBase {
    type: string;
    event: MouseEvent;
    handler: ResizeHandler;
}

export interface WidthChangeTransformation extends ResizeTransformationBase {
    type: 'widthChange';
    handler: {
        vertical: 'middle';
        horizontal: 'left' | 'right';
    };
}

export interface HeightChangeTransformation extends ResizeTransformationBase {
    type: 'heightChange';
    handler: {
        vertical: 'top' | 'bottom';
        horizontal: 'center';
    };
}

export interface MultiSizeChangeTransformation
    extends ResizeTransformationBase {
    type: 'sizeChange';
    handler: {
        vertical: 'top' | 'bottom';
        horizontal: 'left' | 'right';
    };
}

export type AnchorName =
    | 'top-left'
    | 'top-center'
    | 'top-right'
    | 'middle-left'
    | 'middle-right'
    | 'bottom-left'
    | 'bottom-center'
    | 'bottom-right';

export function anchorNameToHandler(handler: AnchorName): ResizeHandler {
    const split = handler.split('-');
    const vertical = split[0] as VerticalAnchor;
    const horizontal = split[1] as HorizontalAnchor;
    return { horizontal, vertical };
}

export type ResizeTransformation =
    | WidthChangeTransformation
    | HeightChangeTransformation
    | MultiSizeChangeTransformation;

export interface MoveTransformation extends TransformationBase {
    type: 'move';
    event: Event;
}

export type Transformation =
    | WidthChangeTransformation
    | HeightChangeTransformation
    | MultiSizeChangeTransformation
    | RotationTransformation
    | MoveTransformation;

export type TransformType = Transformation['type'];
