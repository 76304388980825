import {NgModule} from '@angular/core';
import {provideRouter, RouterModule, Routes, withDebugTracing} from '@angular/router';
import {IsLoggedInGuard} from '@nirby/creators/root';
import {MaintenanceGuard} from './guards/maintenance.guard';
import {NotMaintenanceGuard} from './guards/not-maintenance.guard';
import {MaintenanceComponent} from './views/maintenance/maintenance.component';
import {environment} from '../environments/environment';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'auth/login',
    },
    {
        path: 'admin',
        loadChildren: () =>
            import('./modules/admin/admin.module').then((m) => m.AdminModule),
        canActivate: [NotMaintenanceGuard, IsLoggedInGuard],
        data: {
            requiresAdminAccess: true,
        },
    },
    {
        path: 'workspaces',
        loadChildren: () => import('@nirby/creators/root').then((m) => m.WorkspaceListModule),
        canActivate: [NotMaintenanceGuard, IsLoggedInGuard],
    },
    {
        path: 'embed',
        loadChildren: () => import('./modules/embed/embed.module').then((m) => m.EmbedModule),
    },
    {
        path: 'widgets',
        redirectTo: 'workspaces',
    },
    {
        path: 'widget',
        redirectTo: 'workspaces',
    },
    {
        path: 'tutorial',
        loadChildren: () =>
            import('@nirby/creators/root').then((m) => m.TutorialModule),
        canActivate: [NotMaintenanceGuard],
    },
    {
        path: 'auth',
        loadChildren: () =>
            import('@nirby/creators/root').then((m) => m.AuthModule),
        canActivate: [NotMaintenanceGuard],
    },
    {
        path: 'maintenance',
        component: MaintenanceComponent,
        canActivate: environment.production ? [MaintenanceGuard] : [],
    },
    {
        path: '**',
        redirectTo: 'auth',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    ...(environment.production ? {} : {
        providers: [
            provideRouter(routes, withDebugTracing()),
        ]
    })
})
export class AppRoutingModule {
}
