<div *ngIf="usePreview && embedUrl" class="preview mb-2">
    <iframe [src]="embedUrl" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
</div>

<div class="actions" *ngIf="useModal">
    <button
        class="btn btn-dark btn-sm d-inline-flex justify-content-between align-items-center"
        (click)="openSelectModal(selectorModal); $event.preventDefault()"
    >
        <span class="text">
            {{ video ? 'Change' : 'Select Video' }}
        </span>
        <fa-icon class="icon ms-2" [icon]="iconCloudUpload"></fa-icon>
    </button>

    <button
        class="btn btn-secondary btn-sm ms-2 d-inline-flex justify-content-between align-items-center"
        *ngIf="useClearButton && video"
        (click)="clear(); $event.preventDefault()"
    >
        <span class="text"> Clear </span>
        <fa-icon class="icon ms-2" [icon]="iconTrash"></fa-icon>
    </button>
</div>

<div *ngIf="!useModal">
    <nirby-video-select-content [allowedSource]="allowedSource" (videoSelect)="onVideoSelect($event)"></nirby-video-select-content>
</div>

<ng-template #selectorModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Select Video</h4>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" *ngIf="modal">
        <nirby-video-select-content [allowedSource]="allowedSource" (videoSelect)="onVideoSelect($event)"></nirby-video-select-content>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-outline-dark"
            (click)="modal.close()"
        >
            Close
        </button>
    </div>
</ng-template>
