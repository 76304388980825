import {Component} from '@angular/core';
import {BlockContentComponent} from '../../block-content.component';
import {IconBlockContent} from '@nirby/models/nirby-player';
import { Icon, IconService } from '@nirby/shared/widgets';

@Component({
    selector: 'nirby-icon-content',
    templateUrl: './icon-content.component.html',
    styleUrls: ['./icon-content.component.scss'],
})
export class IconContentComponent extends BlockContentComponent<IconBlockContent> {
    icon = '\uf0e7';
    searchResults: Icon[] = [];

    constructor(private iconService: IconService) {
        super();
    }

    setData(content: IconBlockContent | null): void {
        if (!content) return;
        this.icon = content.icon;
    }

    async search(query: string): Promise<void> {
        this.searchResults = await this.iconService.search(query, 50);
    }

    async onQueryChange(target: any): Promise<void> {
        await this.search(target.value);
    }

    selectIcon(icon: string): void {
        this.sendData({icon});
    }
}
