import {Component} from '@angular/core';
import {UntypedFormControl, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '@nirby/shared/database';
import {Router} from '@angular/router';
import {AlertsService} from '@nirby/shared/alerts';

@Component({
    selector: 'nirby-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
    constructor(
        private auth: AuthenticationService,
        private router: Router,
        private alerts: AlertsService
    ) {}
    emailControl = new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    ]);

    form = new FormGroup({
        email: this.emailControl,
    });

    loading = false;

    errorHasCode(e: unknown): e is {code: string} {
        return typeof (e as {code: unknown}).code === 'string';
    }

    async resetPassword(): Promise<void> {
        this.emailControl.disable();
        this.loading = true;
        try {
            await this.auth.resetPassword(this.emailControl.value);
            this.alerts.showSuccess(`E-mail sent to ${this.emailControl.value}`);
            await this.router.navigate(['login']);
        } catch (e) {
            if (this.errorHasCode(e)) {
                if (e.code === 'auth/user-not-found') {
                    this.alerts.showDanger('No user found with this e-mail');
                } else {
                    this.alerts.showDanger('An error occurred');
                }
            }
            this.emailControl.enable();
            this.loading = false;
        }
    }
}
