import { ChangeDetectorRef, Component } from '@angular/core';
import { BlockContentComponent } from '../../block-content.component';
import {
    faAlignCenter,
    faAlignJustify,
    faAlignLeft,
    faAlignRight,
    faArrowDown,
    faArrowRight,
    faArrowUp,
    faBold,
    faFont,
    faItalic,
    faStrikethrough,
    faUnderline,
} from '@fortawesome/free-solid-svg-icons';
import {
    FontDecoration,
    FontStyle,
    TextAlignment,
    VerticalTextAlignment,
} from '@nirby/runtimes/canvas';
import { FontSource } from '@nirby/media-models';
import { TextBlockContent } from '@nirby/models/nirby-player';
import { ButtonDisplay } from '@nirby/shared/widgets';

@Component({
    selector: 'nirby-text-block-content',
    templateUrl: './block-text-content.component.html',
    styleUrls: ['./block-text-content.component.scss'],
})
export class BlockTextContentComponent extends BlockContentComponent<TextBlockContent> {
    public verticalAlignmentOptions: ButtonDisplay<VerticalTextAlignment>[] = [
        {
            value: 'top',
            icon: faArrowUp,
        },
        {
            value: 'middle',
            icon: faArrowRight,
        },
        {
            value: 'bottom',
            icon: faArrowDown,
        },
    ];

    public alignmentOptions: ButtonDisplay<TextAlignment>[] = [
        {
            value: 'left',
            icon: faAlignLeft,
        },
        {
            value: 'center',
            icon: faAlignCenter,
        },
        {
            value: 'right',
            icon: faAlignRight,
        },
        {
            value: 'justify',
            icon: faAlignJustify,
        },
    ];

    public fontStyleOptions: ButtonDisplay<FontStyle>[] = [
        {
            value: 'normal',
            icon: faFont,
        },
        {
            value: 'bold',
            icon: faBold,
        },
        {
            value: 'italic',
            icon: faItalic,
        },
    ];
    public fontDecorationOptions: ButtonDisplay<FontDecoration>[] = [
        {
            value: '',
            icon: faFont,
        },
        {
            value: 'line-through',
            icon: faStrikethrough,
        },
        {
            value: 'underline',
            icon: faUnderline,
        },
    ];

    text = 'Default text...';
    fontStyle: FontStyle = 'normal';
    fontFamily = 'Roboto';
    alignment: TextAlignment = 'left';
    lineHeight = 1;
    decoration: FontDecoration = '';
    color = '#000000';
    verticalAlignment: VerticalTextAlignment = 'top';
    fontSize = 42;

    constructor(private cdr: ChangeDetectorRef) {
        super();
    }

    setData(content: TextBlockContent): void {
        this.text = content?.text;
        this.fontStyle = content.style;
        this.fontFamily = content.fontFamily;
        this.alignment = content.alignment;
        this.lineHeight = content.lineHeight;
        this.decoration = content.decoration;
        this.verticalAlignment = content.verticalAlign;
        this.fontSize = content.fontScale;
        this.color = content.color;
        this.cdr.detectChanges();
    }

    onChange(value: string): void {
        this.sendData({ text: value });
    }

    chooseColor(color: string | null): void {
        this.sendData({ color: color ?? '#ffffff' });
    }

    chooseFontStyle(fontStyle: FontStyle): void {
        this.sendData({ style: fontStyle });
    }

    chooseFontFamily(fontFamily: FontSource): void {
        this.sendData({ fontFamily: fontFamily.name });
    }

    chooseAlignment(alignment: TextAlignment): void {
        this.sendData({ alignment });
    }

    chooseVerticalAlignment(verticalAlign: VerticalTextAlignment): void {
        this.sendData({ verticalAlign });
    }

    chooseFontDecoration(fontDecoration: FontDecoration): void {
        this.sendData({ decoration: fontDecoration });
    }

    updateLineHeight(lineHeight: number): void {
        this.sendData({ lineHeight });
    }

    updateFontSize(fontScale: number): void {
        this.sendData({ fontScale });
    }
}
