import {BehaviorSubject, Observable, Subject} from 'rxjs';

type AsyncReturnType<TFunction extends (...args: unknown[]) => Promise<unknown>> = TFunction extends (...args: unknown[]) => Promise<infer T> ? T : never;

export type ProcessRepeatableStatus = 'running' | 'stopped';

/**
 * Helper to call an async function multiple times while storing whether it's processing it or not.
 *
 * @see {@link AsyncChannel}
 */
export class ProcessRepeatable<TFunction extends (...args: unknown[]) => Promise<unknown>> {
    private readonly processSubject = new Subject<Promise<AsyncReturnType<TFunction>>>();

    /**
     * Constructor.
     * @param generator The function to call.
     */
    constructor(private readonly generator: TFunction) {
    }

    private currentProcessIndex = -1;
    private lastCompletedIndex = -1;

    private readonly statusSubject = new BehaviorSubject<ProcessRepeatableStatus>('stopped');

    public readonly status$: Observable<ProcessRepeatableStatus> = this.statusSubject.asObservable();

    /**
     * Marks the given process as completed
     * @param index The index of the process to mark as completed
     * @private
     */
    private markAsCompleted(index: number): void {
        this.lastCompletedIndex = Math.max(index, this.lastCompletedIndex);
        this.updateStatus();
    }

    /**
     * Updates the status of the process.
     * @private
     */
    private updateStatus(): void {
        this.statusSubject.next(
            this.lastCompletedIndex === this.currentProcessIndex ? 'stopped' : 'running'
        );
    }

    /**
     * Start a process.
     * @param args The arguments to pass to the function.
     *
     * @returns - The result of the function.
     */
    public run(...args: Parameters<TFunction>): Promise<AsyncReturnType<TFunction>> {
        const index = this.currentProcessIndex + 1;
        this.currentProcessIndex = index;
        const promise = (
            this.generator(...args) as Promise<AsyncReturnType<TFunction>>
        )
            .then(result => {
                this.markAsCompleted(index);
                return result;
            })
            .catch(e => {
                this.markAsCompleted(index);
                throw e;
            });
        this.processSubject.next(promise);
        this.updateStatus();
        return promise;
    }
}
