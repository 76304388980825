import {Injectable} from '@angular/core';

import {ComponentRegisterService} from '../component-register';
import {BlockActionComponent} from '../../components/blocks/block-action.component';
import {ActionGoToCardComponent} from '../../components/actions/action-go-to-card/action-go-to-card.component';
import {ActionTriggerDomComponent} from '../../components/actions/action-trigger-dom/action-trigger-dom.component';
import {
    ActionRequestContactInfoComponent
} from '../../components/actions/action-request-contact-info/action-request-contact-info.component';
import {ActionVideoLinkComponent} from '../../components/actions/action-video-link/action-video-link.component';
import {ActionGoToUrlComponent} from '../../components/actions/action-go-to-url/action-go-to-url.component';
import {
    ActionVariableUpdateComponent
} from '../../components/actions/action-variable-update/action-variable-update.component';
import {ActionTrackEventComponent} from '../../components/actions/action-track-event/action-track-event.component';
import {AnyCardActionType} from '@nirby/models/nirby-player';
import {ActionGoToPrimeComponent} from '../../components/actions/action-go-to-prime/action-go-to-prime.component';
import {ActionGoBackComponent} from '../../components/actions/action-go-back/action-go-back.component';

@Injectable({
    providedIn: 'root',
})
/**
 * Service to create components to edit block actions.
 */
export class BlockActionRegisterService extends ComponentRegisterService<AnyCardActionType,
    BlockActionComponent> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected register: Map<AnyCardActionType, any> = new Map<AnyCardActionType,
        typeof BlockActionComponent>([
        [
            'card-link',
            ActionGoToCardComponent as unknown as typeof BlockActionComponent,
        ],
        [
            'go-to-url',
            ActionGoToUrlComponent as unknown as typeof BlockActionComponent,
        ],
        [
            'trigger-dom',
            ActionTriggerDomComponent as unknown as typeof BlockActionComponent,
        ],
        [
            'variable-update',
            ActionVariableUpdateComponent as unknown as typeof BlockActionComponent,
        ],
        [
            'video-link',
            ActionVideoLinkComponent as unknown as typeof BlockActionComponent,
        ],
        [
            'open-form',
            ActionRequestContactInfoComponent as unknown as typeof BlockActionComponent,
        ],
        [
            'track-event',
            ActionTrackEventComponent as unknown as typeof BlockActionComponent,
        ],
        [
            'go-to-prime',
            ActionGoToPrimeComponent as unknown as typeof BlockActionComponent,
        ],
        [
            'go-back',
            ActionGoBackComponent as unknown as typeof BlockActionComponent,
        ]
    ]);
}
