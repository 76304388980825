import { Migrator } from '@nirby/store/migrator';
import { z } from 'zod';
import { BaseBlock, buildBlockSchema } from './block.model';

export type IconBlock = BaseBlock<
    'Icon',
    {
        icon: string;
    },
    {
        color: string;
    }
>;
export type IconBlockStyle = IconBlock['style'];
export type IconBlockContent = IconBlock['content'];
export const ICON_BLOCK_SCHEMA = buildBlockSchema<IconBlock>(
    'Icon' as const,
    z.object({
        icon: z.string().default('\uf00c'),
    }),
    z.object({
        color: z.string().default('#ffffff'),
    })
);

export const ICON_BLOCK_MIGRATOR = new Migrator<IconBlock>(
    ICON_BLOCK_SCHEMA,
    () => ({
        actions: {
            click: [],
        },
        content: { icon: '\uf00c' },
        hash: '',
        position: [
            { x: 0, y: 0 },
            { x: 128, y: 128 },
        ],
        rotation: 0,
        style: {
            color: 'rgb(0,0,0)',
        },
        type: 'Icon',
    })
);
