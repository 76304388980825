import { Component, Input } from '@angular/core';
import { VideoRecord } from '@nirby/media-models';
import {VideoFactoryService} from '@nirby/media/video-factory';

@Component({
    selector: 'nirby-video-view',
    templateUrl: './video-view.component.html',
    styleUrls: ['./video-view.component.scss'],
})
export class VideoViewComponent {
    @Input() video: VideoRecord | null = null;

    constructor(private videoService: VideoFactoryService) {}

    getEmbed(): string | null {
        const video = this.video;
        if (!video) {
            return null;
        }
        const service = this.videoService.getSourceService(video.type);
        return service.getEmbedUrlFromId(video.id);
    }
}
