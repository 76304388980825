<div ngbDropdown *ngIf="widget">
    <a
        [routerLink]="[]"
        queryParamsHandling="merge"
        class="header-dropdown dropdown d-flex align-items-center p-3"
        ngbDropdownToggle
    >
        <div class="logo d-flex justify-content-center align-items-center me-2">
            <img
                src="assets/img/auxi/nby-logo-header.svg"
                class="logo-img"
                alt="Nirby Pop"
                title="Nirby Pop"
            />
        </div>
        <span class="text">{{ widget.urlName }}</span>
    </a>
    <div ngbDropdownMenu *ngIf="widgets$ | async as widgets">
        <!--<input [(ngModel)]="filterTerm.urlName" type="text" class="form-control" placeholder="search widget">-->
        <button
            ngbDropdownItem
            (click)="selectWidget(widget)"
            [ngClass]="{ active: (currentWidgetId$ | async) === widget.ref.id }"
            *ngFor="let widget of widgets"
            [routerLink]="widget.ref ? ['/workspaces', widget.ref.id] : []"
        >
            {{ widget.data.workspaceName }}
        </button>
    </div>
</div>
