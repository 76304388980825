import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import * as Sentry from '@sentry/angular';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {environment} from '../environments/environment';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HomeComponent} from './views/home/home.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {HIGHLIGHT_OPTIONS} from 'ngx-highlightjs';
import {MaintenanceComponent} from './views/maintenance/maintenance.component';
import {Router} from '@angular/router';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {LoadScreenModule} from '@nirby/shared/load-screen';
import {ToastContainerComponent} from '@nirby/creators/root';
import {CREATORS_ENVIRONMENT} from '@nirby/environment/base';
import {defaultConfig} from '@nirby/models/nirby-player';
import {FileDropModule} from '@nirby/shared/file-drop';
import {FONT_PICKER_CONFIG} from 'ngx-font-picker';
import {NIRBY_PLAYER_CONFIG} from '@nirby/shared/player-config';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {connectFirestoreEmulator, getFirestore, provideFirestore} from '@angular/fire/firestore';
import {connectAuthEmulator, getAuth, provideAuth} from '@angular/fire/auth';
import {getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService} from '@angular/fire/analytics';
import {connectStorageEmulator, getStorage, provideStorage} from '@angular/fire/storage';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        MaintenanceComponent,
        ToastContainerComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        provideFirebaseApp(() => {
            return initializeApp(environment.firebase);
        }),
        provideFirestore(() => {
            const firestore = getFirestore();
            if (environment.useEmulators) {
                connectFirestoreEmulator(firestore, 'localhost', 8080);
            }
            return firestore;
        }),
        provideStorage(() => {
            const storage = getStorage();
            if (environment.useEmulators) {
                connectStorageEmulator(storage, 'localhost', 8086);
            }
            return storage;
        }),
        provideAuth(() => {
            const auth = getAuth();
            if (environment.useEmulators) {
                connectAuthEmulator(auth, 'http://localhost:9099');
            }
            return auth;
        }),
        ...(environment.production ? [
            provideAnalytics(() => getAnalytics()),
        ] : []),
        NgbModule,
        ToastModule,
        HttpClientModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) =>
                    new TranslateHttpLoader(http, '/assets/i18n/', '.json'),
                deps: [HttpClient],
            },
        }),
        LoadScreenModule,
        ConfirmDialogModule,
        FileDropModule,
    ],
    providers: [
        {
            provide: CREATORS_ENVIRONMENT,
            useValue: environment,
        },
        {
            provide: NIRBY_PLAYER_CONFIG,
            useValue: {
                ...defaultConfig,
                api: environment.api,
            },
        },
        {
            provide: FONT_PICKER_CONFIG,
            useValue: {
                apiKey: environment.firebase.apiKey,
            },
        },
        // set-up sentry error handler
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: environment.production,
            }),
        },
        // set-up sentry trace
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        // set-up sentry app initializer
        {
            provide: APP_INITIALIZER,
            useFactory: (_: Sentry.TraceService) => async () => {
                return;
            },
            deps: [Sentry.TraceService],
            multi: true,
        },
        // set-up syntax highlight options (for THE SCRIPT syntax)
        {
            provide: HIGHLIGHT_OPTIONS,
            useValue: {
                coreLibraryLoader: () => import('highlight.js'),
                languages: {
                    xml: () => import('highlight.js/lib/languages/xml'),
                },
            },
        },
        // firebase user tracking
        UserTrackingService,
        // firebase screen tracking
        ScreenTrackingService,
        // Set-up primeng confirmation service
        ConfirmationService,
        ScreenTrackingService,
        UserTrackingService,
        environment.media,
        environment.database,
        MessageService,
    ],
    exports: [],
    bootstrap: [AppComponent],
})
/**
 * Module for the Nirby main application.
 */
export class AppModule {
}
