<form *ngIf="groupForm" [formGroup]="groupForm">
    <div class="btn-group btn-group-toggle">
        <label ngbButtonLabel class="btn-light" *ngFor="let option of options">
            <input
                [name]="option.value.toString()"
                [formControl]="getControl(option.value)"
                type="checkbox"
                ngbButton
                (ngModelChange)="update()"
            />
            <fa-icon *ngIf="option.icon" [icon]="option.icon"></fa-icon>
            <span *ngIf="option.label">{{ option.label }}</span>
        </label>
    </div>
</form>
