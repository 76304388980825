import {ArtBoardEditorMaster, Block, NirbyBoardStateNode, QuickUpdater} from '@nirby/runtimes/canvas';
import {NEVER, Observable, Subscription, switchMap, Unsubscribable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {LoggerService} from '@nirby/ngutils';
import {ActionTrigger, AnyBlock, AnyCardAction, AnyCardActionType, BlockActions} from '@nirby/models/nirby-player';

/**
 * Checks if an art-board item is a block and returns it if it is.
 * @param item The item to convert
 *
 * @returns - The converted block
 */
export function itemToBlock<TMeta>(
    item: NirbyBoardStateNode<TMeta>,
): NirbyBoardStateNode<TMeta, Block<TMeta>> | null {
    if (item.type === 'block') {
        return item as NirbyBoardStateNode<TMeta, Block<TMeta>>;
    }
    return null;
}

/**
 * Class to handle actions updates
 */
export class BlockControllerActions<TMeta> {
    /**
     * Actions that stop a card
     */
    public static readonly ENDING_ACTIONS: AnyCardActionType[] = [
        'card-link',
        'video-link',
        'close',
    ];

    /**
     * Constructor.
     * @param controller The block controller
     */
    constructor(private readonly controller: BlockController<TMeta>) {
    }

    /**
     * Returns the action set.
     *
     * @returns - The action set.
     */
    public get actions(): BlockActions | null {
        const item = this.controller.value;
        if (!item) {
            return null;
        }
        return item.actions;
    }

    /**
     * Selected card actions
     */
    public get actions$(): Observable<BlockActions> {
        return this.controller.value$.pipe(map((a) => a?.actions ?? null));
    }


    /**
     * Receives 2 actions arrays, an old one, and a new one. For each action, the new one will only be used
     * if the new action type is different from before or if the index does not exist. Otherwise, the data
     * will be copied into the old object.
     *
     * This avoids reloading the view (and losing focus) because of a reference change on the action.
     *
     * The resulting array will have the same length as the new actions array.
     *
     * @param oldActions Old actions array
     * @param newActions New actions array
     *
     * @returns - The resulting array
     */
    public static mergeAndKeepOldActions(
        oldActions: AnyCardAction[],
        newActions: AnyCardAction[],
    ): AnyCardAction[] {
        const merged = newActions.map((newAction, index) => {
            if (index >= oldActions.length) {
                return newAction;
            }
            const oldAction = oldActions[index];
            if (oldAction.type === newAction.type) {
                // eslint-disable-next-line
                const options: any = newAction.options;

                let propertyKey: string;
                for (propertyKey in options) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (!options.hasOwnProperty(propertyKey)) {
                        continue;
                    }
                    // eslint-disable-next-line
                    (oldAction.options as any)[propertyKey] =
                        options[propertyKey];
                }
                return oldAction;
            }
            return newAction;
        });
        oldActions.splice(0, oldActions.length);
        oldActions.push(...merged);
        return oldActions;
    }

    /**
     * Watches a trigger actions.
     * @param trigger The trigger
     *
     * @returns - The observable
     */
    watchTrigger(trigger: keyof BlockActions): Observable<AnyCardAction[]> {
        return this.actions$.pipe(
            map((actions) => (actions ? actions[trigger] : [])),
        );
    }

    /**
     * Check if an action trigger includes an ending action
     * @param trigger Trigger (e.g. click)
     *
     * @returns - True if the trigger includes an ending action
     */
    public watchHasEndingAction$(trigger: ActionTrigger): Observable<boolean> {
        return this.actions$.pipe(
            map((actions) => {
                if (!actions) {
                    return false;
                }
                return actions[trigger].reduce<boolean>(
                    (prev, current) =>
                        prev ||
                        BlockControllerActions.ENDING_ACTIONS.includes(
                            current.type,
                        ),
                    false,
                );
            }),
        );
    }

    /**
     * Update the selected block action at the given index
     * @param trigger Action trigger
     * @param index Index
     * @param action Action
     */
    public updateAt(
        trigger: ActionTrigger,
        index: number,
        action: AnyCardAction,
    ): void {
        const actions = this.actions;
        if (!actions) {
            LoggerService.warnStyled(
                'ACTION-INPUT',
                'Tried to update selected block actions. But no actions ar found',
            );
            return;
        }
        const actionArray: AnyCardAction[] = actions[trigger];
        actionArray[index] = action;
        this.controller.update({
            actions: {
                ...actions,
                [trigger]: actionArray,
            },
        });
    }

    /**
     * Add action at the end of trigger
     * @param trigger Trigger
     * @param action Action
     * @param index The index to insert the action at
     */
    public addTo(trigger: ActionTrigger, action: AnyCardAction, index?: number): void {
        const block = this.controller.value;
        if (!block) {
            LoggerService.warnStyled(
                'ACTION-INPUT',
                'Tried to update selected block actions. But no block is selected',
            );
            return;
        }
        const newActionsArray = [...block.actions[trigger]];
        newActionsArray.splice(index ?? newActionsArray.length, 0, action);
        this.controller.update({
            actions: {
                ...block.actions,
                [trigger]: newActionsArray,
            },
        });
    }

    /**
     * Delete action at index
     * @param trigger Trigger
     * @param index Action index
     */
    public deleteAt(trigger: ActionTrigger, index: number): void {
        const block = this.controller.value;
        if (!block) {
            return;
        }
        const newActions = [...block.actions[trigger]];
        newActions.splice(index, 1);
        const newActionsBlock: BlockActions = {
            ...block.actions,
            [trigger]: newActions,
        };
        this.controller.update({
            actions: newActionsBlock,
        });
    }
}

/**
 * Controller to manage a block properties safely
 */
export class BlockController<TMeta, TB extends AnyBlock = AnyBlock>
    implements Unsubscribable {
    public readonly actions: BlockControllerActions<TMeta>;
    private readonly subscription = new Subscription();

    /**
     * Watches the block value.
     */
    public get value$(): Observable<TB> {
        return this.master.store.watchLevel(this.id).pipe(
            filter((b) => b?.type === 'block'),
            switchMap((b) => b?.value$ ?? NEVER),
            map((b) => itemToBlock(b)),
            filter((s): s is NirbyBoardStateNode<TMeta, Block<TMeta>> => !!s),
            map((b) => b.properties as TB),
        );
    }

    private readonly updater: QuickUpdater<NirbyBoardStateNode<TMeta>>;

    /**
     * The block type
     */
    get type(): TB['type'] | null {
        return this.value?.type ?? null;
    }

    /**
     * Constructor.
     * @param id ID of the block to edit
     * @param master The art board editor master.
     */
    constructor(
        private readonly id: string,
        private readonly master: ArtBoardEditorMaster<TMeta>,
    ) {
        this.updater = this.master.store.startQuickUpdate(this.id, 1000);
        this.subscription.add(this.updater);
        const controller = this as unknown as BlockController<TMeta>;
        this.actions = new BlockControllerActions<TMeta>(controller);
    }

    /**
     * Dispose the controller.
     */
    unsubscribe(): void {
        this.dispose();
    }

    /**
     * Updates some properties of the block
     * @param newDataInsert New properties
     */
    update(newDataInsert: Partial<TB>): void {
        const oldData = this.blockState;
        if (!oldData) {
            return;
        }
        const newData: NirbyBoardStateNode<TMeta, Block<TMeta>> = {
            ...oldData,
            properties: {
                ...oldData.properties,
                ...newDataInsert,
            },
        };
        this.updater.update(newData);
    }

    /**
     * Gets the current block state
     */
    public get blockState(): NirbyBoardStateNode<TMeta, Block<TMeta, TB>> | null {
        const item = this.master.store.get(this.id)?.value;
        if (!item) {
            return null;
        }
        const block = itemToBlock(item);
        return block as unknown as NirbyBoardStateNode<TMeta, Block<TMeta, TB>>;
    }

    /**
     * Gets the current block data
     */
    public get value(): TB | null {
        const block = this.blockState;
        if (!block) {
            return null;
        }
        return block.properties;
    }

    /**
     * Disposes this controller
     */
    dispose(): void {
        this.subscription.unsubscribe();
    }
}
