<div *ngIf="generatedScript$ | async as nbyScript" class="nirby-card">
    <button
        class="btn btn-sm btn-outline-dark"
        ngxClipboard
        [cbContent]="nbyScript"
        (cbOnSuccess)="onCopyResponse($event)"
        (cbOnError)="onCopyError($event)"
    >
        Copy
    </button>
    <pre class="mt-2 mb-0">
        <code
            [highlight]="nbyScript"
            [languages]="['xml']">
        </code>
    </pre>
</div>
<div *ngIf="generatedLocalScript$ | async as nbyScript" class="nirby-card mt-4">
    <h3>Local Testing Script</h3>
    <button
        class="btn btn-sm btn-outline-dark"
        ngxClipboard
        [cbContent]="nbyScript"
        (cbOnSuccess)="onCopyResponse($event)"
        (cbOnError)="onCopyError($event)"
    >
        Copy
    </button>
    <pre class="mt-2 mb-0">
        <code
            [highlight]="nbyScript"
            [languages]="['xml']">
        </code>
    </pre>
</div>
<div
    *ngIf="generatedStagingScript$ | async as nbyScript"
    class="nirby-card mt-4"
>
    <h3>Staging Script</h3>
    <button
        class="btn btn-sm btn-outline-dark"
        ngxClipboard
        [cbContent]="nbyScript"
        (cbOnSuccess)="onCopyResponse($event)"
        (cbOnError)="onCopyError($event)"
    >
        Copy
    </button>
    <pre class="mt-2 mb-0">
        <code
            [highlight]="nbyScript"
            [languages]="['xml']">
        </code>
    </pre>
</div>
