import {
    ActionOptionsOf, AnyCardAction,
    AnyCardActionType,
    PickStandardAction,
} from '../actions';
import {v4} from 'uuid';

export const defaultActions: {
    [ActionType in AnyCardActionType]: ActionOptionsOf<
        PickStandardAction<ActionType>
    >;
} = {
    'go-back': {
        variablesHandling: 'keep',
        method: 'aggro',
        time: 'where-user-left',
    },
    'go-to-prime': {
        primeId: null,
        sourceId: null,
        time: 0,
        variablesHandling: 'keep',
        ignoreAggroGeneration: false,
    },
    'answer-question': {
        questionId: '',
        question: '',
        answer: '',
    },
    'track-event': {
        tags: [],
        event: 'my-custom-event',
    },
    'open-form': {
        title: 'Form',
        description: null,
        type: 'contact',
        attributes: [
            {
                id: 'email',
                type: 'email',
                label: 'E-mail',
                required: true,
                store: false,
            },
        ],
    },
    'prime-pause': {
        delay: 0,
    },
    'video-link': {
        key: null,
        time: 0,
        ignoreAggroGeneration: false,
    },
    'card-link': {
        key: null,
    },
    'go-to-url': {
        href: 'https://www.nir.by/',
        target: '_blank',
    },
    'open-embed': {
        src: 'https://www.nir.by/',
    },
    video: null,
    close: {},
    'trigger-dom': {
        selector: '',
        event: 'click',
    },
    'variable-update': {
        operation: 'set',
        variable: '',
        amount: '',
    },
};

/**
 * Creates a default action object with the given type.
 * @param type Action type.
 * @param id Action id.
 *
 * @returns - Default action object.
 */
export function factoryActions<TAction extends AnyCardAction>(type: TAction['type'], id?: string): TAction {
    const options: TAction['options'] = defaultActions[type];
    return {
        id: id ?? v4(),
        type,
        options: {
            ...options,
        },
    } as TAction;
}
