import {Transaction} from 'firebase/firestore';
import {serverTimestamp} from '@angular/fire/firestore';
import {NirbyTransaction} from '@nirby/store/base';
import {FirestoreDocumentReference} from './reference';
import {FirestoreNirbyDocument} from './document';
import {StandardConverter} from './converter';
import {DateTime} from 'luxon';

export class FirestoreTransaction implements NirbyTransaction {
    constructor(
        private readonly transaction: Transaction,
    ) {
    }

    delete<T extends object>(docRef: FirestoreDocumentReference<T>): this {
        this.transaction.delete(docRef.ref);
        return this;
    }

    async get<T extends object>(docRef: FirestoreDocumentReference<T>): Promise<FirestoreNirbyDocument<T> | null> {
        const snapshot = await this.transaction.get(docRef.ref);
        return FirestoreNirbyDocument.fromFirestore<T>(snapshot, docRef.migrator);
    }

    set<T extends object>(docRef: FirestoreDocumentReference<T>, data: T): this {
        this.transaction.set(docRef.ref, StandardConverter.cleanDataForFirestore({
            ...data,
            _lastUpdate: serverTimestamp() as unknown as DateTime,
            _creationTime: serverTimestamp() as unknown as DateTime,
            _databaseVersion: docRef.migrator.currentVersion,
        }));
        return this;
    }

    update<T extends object>(docRef: FirestoreDocumentReference<T>, data: Partial<T>): this {
        this.transaction.update(docRef.ref, StandardConverter.cleanDataForFirestore({
            ...data,
            _lastUpdate: serverTimestamp() as unknown as DateTime,
        }));
        return this;
    }
}
