import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {EditorLayoutComponent} from './layout/editor-layout.component';
import {RoutesComponent} from './views/routes/routes.component';
import {RouteHomeGuard, WidgetsGuard} from './guards';
import {SettingsLayoutComponent} from './views/settings/settings-layout/settings-layout.component';
import {SettingsWidgetComponent} from './views/settings/widget/settings-widget/settings-widget.component';
import {
    SettingsWidgetGeneralComponent,
} from './views/settings/widget/settings-widget-general/settings-widget-general.component';
import {
    SettingsWidgetScriptComponent,
} from './views/settings/widget/settings-widget-script/settings-widget-script.component';
import {RoutesEmptyComponent} from './views/routes/routes-empty/routes-empty.component';
import {RoutesFormComponent} from './views/routes/routes-form/routes-form.component';
import {WidgetMigrationComponent} from './views/migration/widget-migration.component';
import {WelcomeComponent} from './views/settings/welcome/welcome.component';
import {
    SettingsWidgetBillingComponent,
} from './views/settings/widget/settings-widget-billing/settings-widget-billing.component';
import {Workspace} from '@nirby/models/editor';
import {SettingsPersonalComponent} from './views/settings/personal/settings-personal/settings-personal.component';
import {
    SettingsPersonalGeneralComponent,
} from './views/settings/personal/settings-personal-general/settings-personal-general.component';
import {
    SettingsPersonalBillingComponent,
} from './views/settings/personal/settings-personal-billing/settings-personal-billing.component';

export const routes: Routes = [
    {
        path: 'migrate',
        component: WidgetMigrationComponent,
    },
    {
        path: '',
        component: EditorLayoutComponent,
        canActivate: [WidgetsGuard],
        children: [
            {
                path: 'media-library',
                loadChildren: () => import('./modules/media-library/media-library.module').then(m => m.MediaLibraryModule),
            },
            {
                path: 'settings',
                component: SettingsLayoutComponent,
                children: [
                    {
                        path: 'widget',
                        component: SettingsWidgetComponent,
                        children: [
                            {
                                path: 'general',
                                component: SettingsWidgetGeneralComponent,
                            },
                            {
                                path: 'script',
                                component: SettingsWidgetScriptComponent,
                            },
                            {
                                path: 'billing',
                                component: SettingsWidgetBillingComponent,
                            },
                            {
                                path: '**',
                                redirectTo: 'general',
                            },
                        ],
                    },
                    {
                        path: 'personal',
                        component: SettingsPersonalComponent,
                        children: [
                            {
                                path: 'general',
                                component: SettingsPersonalGeneralComponent,
                            },
                            {
                                path: 'billing',
                                component: SettingsPersonalBillingComponent,
                            },
                            {
                                path: '**',
                                redirectTo: 'general',
                            },
                        ],
                    },
                    {
                        path: 'welcome',
                        canActivate: [
                            // HasPlanGuard,
                        ],
                        component: WelcomeComponent,
                    },
                    {
                        path: '**',
                        redirectTo: 'personal',
                    },
                ],
            },
            {
                path: 'campaigns',
                canActivate: [
                    // HasPlanGuard,
                ],
                data: {
                    validatePlan: (plan: NonNullable<Workspace['plan']>): boolean => plan.productMetadata.hasPop === 'true',
                },
                loadChildren: () =>
                    import('./campaigns/pops.module').then((m) => m.PopsModule),
            },
            {
                path: 'droplets',
                loadChildren: () =>
                    import('./routes/droplets/editor-droplets.module').then(
                        (m) => m.EditorDropletsModule,
                    ),
            },
            {
                path: 'primes',
                canActivate: [
                    // HasPlanGuard,
                ],
                data: {
                    validatePlan: (plan: NonNullable<Workspace['plan']>): boolean => plan.productMetadata.hasPrime === 'true',
                },
                loadChildren: () =>
                    import('./routes/primes/editor-primes.module').then(
                        (m) => m.EditorPrimesModule,
                    ),
            },
            {
                path: 'dashboard',
                canActivate: [
                    // HasPlanGuard,
                ],
                data: {
                    validatePlan: (plan: NonNullable<Workspace['plan']>): boolean => !!plan.productMetadata.analyticsHistoryDays,
                },
                loadChildren: () =>
                    import('./routes/dashboard/dashboard.module').then(
                        (m) => m.DashboardModule,
                    ),
            },
            {
                path: 'contacts',
                canActivate: [
                    // HasPlanGuard,
                ],
                data: {
                    validatePlan: (plan: NonNullable<Workspace['plan']>): boolean => plan.productMetadata.hasContacts === 'true',
                },
                loadChildren: () =>
                    import('./routes/contacts/contacts.module').then(
                        (m) => m.ContactsModule,
                    ),
            },
            {
                path: 'routes',
                component: RoutesComponent,
                canActivate: [
                    // HasPlanGuard,
                ],
                data: {
                    validatePlan: (plan: NonNullable<Workspace['plan']>): boolean => plan.productMetadata.hasRoutes === 'true',
                },
                children: [
                    {
                        path: '',
                        component: RoutesEmptyComponent,
                        canActivate: [RouteHomeGuard],
                    },
                    {
                        path: ':routeId',
                        component: RoutesFormComponent,
                    },
                    {
                        path: '**',
                        redirectTo: '',
                    },
                ],
            },
            {
                path: 'landpops',
                loadChildren: () =>
                    import('./routes/landpop/editor-landpop.module').then(
                        (m) => m.EditorLandpopModule,
                    ),
            },
            {
                path: 'cards',
                loadChildren: () =>
                    import('./routes/editor-cards/cards.module').then(
                        (m) => m.CardsModule,
                    ),
            },
            {
                path: 'analytics',
                canActivate: [
                    // HasPlanGuard,
                ],
                data: {
                    validatePlan: (plan: NonNullable<Workspace['plan']>): boolean => plan.productMetadata.analyticsRole === 'advanced',
                },
                loadChildren: () =>
                    import('./routes/analytics/analytics.module').then(
                        (m) => m.AnalyticsModule,
                    ),
            },
            {
                path: 'plans',
                // component: PricingComponent,
                redirectTo: 'settings/welcome',
            },
            {
                path: '**',
                redirectTo: 'settings/welcome',
            },
        ],
    },
    {
        path: '**',
        redirectTo: '/workspaces',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
/**
 * Routing for the Workspace module
 */
export class WorkspaceRoutingModule {
}
