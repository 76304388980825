import {Component} from '@angular/core';
import {LoadScreenService} from '../load-screen.service';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'nirby-load-block',
    templateUrl: './load-block.component.html',
    styleUrls: ['./load-block.component.scss'],
    animations: [
        trigger('bounce', [
            transition(':enter', [
                style({opacity: 0}),
                animate('100ms ease-in', style({opacity: 1})),
            ]),
            transition(':leave', [
                animate('200ms ease-in', style({opacity: 0}))
            ])
        ])
    ]
})
/**
 * Load screen that puts itself fullscreen and blocks the user from interacting with the app.
 */
export class LoadBlockComponent {
    public readonly label$ = this.service.watchLoadingLabel('block');

    /**
     * Constructor.
     * @param service The service to manage loading screens.
     */
    constructor(private service: LoadScreenService) {
    }
}
