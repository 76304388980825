import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EditorLayoutComponent} from './layout/editor-layout.component';
import {WorkspaceRoutingModule} from './workspace-routing.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {HttpClientModule} from '@angular/common/http';

import {ProjectHomeComponent} from './views/project-home/project-home.component';
import {RoutesComponent} from './views/routes/routes.component';
import {DirectorysComponent} from './components/directorys/directorys.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
    ModalDirectoriesComponent
} from './components/functCampaign/modals/modal-directories/modal-directories.component';
import {SelectWidgetComponent} from './components/select-widget/select-widget.component';
import {SelectDirectoriesComponent} from './components/functCampaign/select-directories/select-directories.component';
import {UserRegistrationComponent} from './modules/auth/user-registration/user-registration.component';
import {UserLoginComponent} from './modules/auth/user-login/user-login.component';
import {UiSwitchModule} from 'ngx-ui-switch';
import {RoutesPanelComponent} from './components/routes/routes-panel/routes-panel.component';
import {RoutePanelModalComponent} from './components/routes/modal/route-panel-modal/route-panel-modal.component';
import {FilterPipeModule} from 'ngx-filter-pipe';
import {TutorialPanelComponent} from './components/tutorials/tutorial-panel/tutorial-panel.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {
    SelectTutorialPanelComponent
} from './components/tutorials/select-tutorial-panel/select-tutorial-panel.component';
import {TutorialSidebarComponent} from './components/tutorials/tutorial-sidebar/tutorial-sidebar.component';
import {ClipboardModule} from 'ngx-clipboard';
import {ImageUploadComponent} from './components/image-upload/image-upload.component';
import {FileUploadService, FireStorageService} from '@nirby/shared/database';
import {SettingsWidgetComponent} from './views/settings/widget/settings-widget/settings-widget.component';
import {SettingsLayoutComponent} from './views/settings/settings-layout/settings-layout.component';
import {
    SettingsWidgetGeneralComponent
} from './views/settings/widget/settings-widget-general/settings-widget-general.component';
import {
    SettingsWidgetScriptComponent
} from './views/settings/widget/settings-widget-script/settings-widget-script.component';
import {RoutesEmptyComponent} from './views/routes/routes-empty/routes-empty.component';
import {RoutesFormComponent} from './views/routes/routes-form/routes-form.component';
import {WidgetMigrationComponent} from './views/migration/widget-migration.component';
import {NirbyCommonModule, PopCommonModule} from '@nirby/shared/widgets';
import {NirbyPlayerModule} from '@nirby/player';
import {ToolbarModule} from './modules';
import {WelcomeComponent} from './views/settings/welcome/welcome.component';
import {
    SettingsWidgetBillingComponent
} from './views/settings/widget/settings-widget-billing/settings-widget-billing.component';
import {PlanTableComponent} from './components/plan-table/plan-table.component';
import {PlanTableItemComponent} from './components/plan-table-item/plan-table-item.component';
import {PriceSelectPipe} from './pipes/price-selector.pipe';
import {SharedSliderSelectModule} from '@nirby/shared/slider-select';
import {
    PlanTableItemUsageItemComponent
} from './components/plan-table-item-usage-item/plan-table-item-usage-item.component';
import {TooltipModule} from 'primeng/tooltip';
import {PricingComponent} from './views/settings/pricing/pricing.component';
import {
    SettingsPersonalGeneralComponent
} from './views/settings/personal/settings-personal-general/settings-personal-general.component';
import {SettingsPersonalComponent} from './views/settings/personal/settings-personal/settings-personal.component';
import { SettingsPersonalBillingComponent } from './views/settings/personal/settings-personal-billing/settings-personal-billing.component';

@NgModule({
    declarations: [
        EditorLayoutComponent,
        ProjectHomeComponent,
        RoutesComponent,
        DirectorysComponent,
        ModalDirectoriesComponent,
        SelectWidgetComponent,
        SelectDirectoriesComponent,
        UserRegistrationComponent,
        UserLoginComponent,
        RoutesPanelComponent,
        RoutePanelModalComponent,
        TutorialPanelComponent,
        SelectTutorialPanelComponent,
        TutorialSidebarComponent,
        ImageUploadComponent,
        SettingsWidgetComponent,
        SettingsLayoutComponent,
        SettingsWidgetGeneralComponent,
        SettingsWidgetScriptComponent,
        RoutesEmptyComponent,
        RoutesFormComponent,
        WidgetMigrationComponent,
        WelcomeComponent,
        SettingsWidgetBillingComponent,
        PlanTableComponent,
        PlanTableItemComponent,
        PriceSelectPipe,
        PlanTableItemUsageItemComponent,
        PricingComponent,
        SettingsPersonalComponent,
        SettingsPersonalGeneralComponent,
        SettingsPersonalBillingComponent,
    ],
    imports: [
        CommonModule,
        WorkspaceRoutingModule,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        UiSwitchModule,
        FilterPipeModule,
        HttpClientModule,
        DragDropModule,
        ClipboardModule,
        PopCommonModule,
        NirbyCommonModule,
        NirbyPlayerModule,
        ToolbarModule,
        SharedSliderSelectModule,
        TooltipModule
    ],
    providers: [
        {
            provide: FileUploadService,
            useClass: FireStorageService,
        },
    ],
})
/**
 * This module is the main module to explore a workspace.
 */
export class WorkspaceModule {
}
