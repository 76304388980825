import {Inject, Injectable} from '@angular/core';
import {AnalyticsApi, ContactsApi, NirbyApi} from '@nirby/runtimes/analytics';
import {NIRBY_API} from '@nirby/shared/player-config';


@Injectable({
    providedIn: 'root',
})
/**
 * The analytics service is a wrapper around the Nirby analytics API.
 */
export class AnalyticsService extends AnalyticsApi {
    /**
     * Constructor for Angular.
     * Injects the {@link NirbyApi} through the {@link NIRBY_API} token.
     *
     * @param api The NirbyApi instance.
     */
    constructor(
        @Inject(NIRBY_API) api: NirbyApi,
    ) {
        super(api);
    }
}
