import {Component, EventEmitter, forwardRef, Input, Output,} from '@angular/core';
import {SelectButtonOption} from '../selector-toggle';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {VoidFunction} from '@nirby/js-utils/types';

@Component({
    selector: 'nirby-selector-box',
    templateUrl: './selector-box.component.html',
    styleUrls: ['./selector-box.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectorBoxComponent),
            multi: true,
        },
    ],
})
export class SelectorBoxComponent<T = unknown> implements ControlValueAccessor {
    @Input() options: SelectButtonOption<T>[] = [];
    @Input() defaultValue: (SelectButtonOption<T> & { id: null }) | null = null;

    @Input() disabled = false;
    @Input() value: T | null = null;
    @Output() valueChange = new EventEmitter<T | null>();

    get cleanedValue(): T | null {
        if (this.options.find((o) => o.id === this.value)) {
            return this.value;
        }
        return null;
    }

    onChange: VoidFunction<T> = () => {
        return;
    };
    onTouched: VoidFunction<void> = () => {
        return;
    };

    registerOnChange(fn: VoidFunction<T>): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: VoidFunction<void>): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: T | null): void {
        this.value = obj;
    }

    update(id: T): void {
        this.onChange(id);
        this.valueChange.emit(id);
    }
}
