<button
    class="w-100 upload-container"
    [disabled]="loading"
    nirbyUpload
    (click)="openFileUploader(); $event.preventDefault()"
    (fileDropped)="onFileListSelected($event)"
>
    <span *ngIf="!loading">Drag to upload</span>
    <span *ngIf="loading">Uploading...</span>
</button>
<div *ngIf="loading" class="text-dark spinner-border"></div>
<input
    [hidden]="true"
    #fileInput
    type="file"
    (change)="onFileSelected($event)"
/>
