import {Block} from './block';
import Konva from 'konva';
import {AnyBlock} from '@nirby/models/nirby-player';
import {Subscription} from 'rxjs';
import {Designer} from '../designs/designer';
import {DesignSet} from '../designs';
import {Logger} from '@nirby/logger';

/**
 * Block that can be designed using a configuration object.
 */
export abstract class DesignableBlock<
    TMeta,
    TB extends AnyBlock = AnyBlock
> extends Block<TMeta, TB, Konva.Group> {
    override MIN_WIDTH = 24;
    override MIN_HEIGHT = 24;
    protected readonly baseSize: {
        width: number;
        height: number;
    } | null = null;


    private designerEventsSubscription = new Subscription();

    designer: Designer | null = null;

    /**
     * Implementation of the {@link Block#setCursor} method nullifying the parent method.
     */
    override setCursor() {
        return;
    }

    abstract designSet: DesignSet;

    /**
     * Builds the shape that draw this block in a group.
     * @protected
     */
    protected async generateShape(): Promise<Konva.Group> {
        const block = this.propertiesPost;

        // initialize designer
        this.designer = new Designer(
            {
                state: this.memory,
                block,
                context: this.context,
            },
            this.designSet,
            this.editable,
            () => this.getCurrentDesignId(),
            {
                width: this.configSize.x,
                height: this.configSize.y,
            },
            this.baseSize,
        );

        // watch shape units events
        this.designerEventsSubscription.add(
            this.designer.events$.subscribe((event) =>
                this.triggerBlockEvent(event),
            ),
        );

        await this.designer.build(block);
        return this.designer.root;
    }

    /**
     * Disposes the block and unsubscribes from block events
     */
    override dispose() {
        super.dispose();
        this.designerEventsSubscription.unsubscribe();
        this.designerEventsSubscription = new Subscription();
        if (this.designer) {
            this.designer.dispose();
        }
    }

    protected abstract getCurrentDesignId(): string;

    /**
     * Rebuilds this block's design
     * @protected
     */
    protected async updateShapeFromBlock(): Promise<void> {
        if (!this.designer) {
            Logger.warnStyled(
                'BLOCK',
                'Trying to update shape from block but designer is not initialized',
            );
            return;
        }
        await this.designer.build(this.propertiesPost);
    }

    /**
     * Subscribe to events to handle size change
     * @param shape Shape to subscribe to
     *
     * @returns - Subscription to events
     */
    protected override subscribeToEvents(shape: Konva.Group): Subscription {
        const subscriptions = super.subscribeToEvents(shape);
        if (!this.editable) {
            subscriptions.add(
                this.controllerCursor.enter$.subscribe(() =>
                    this.memory.set('hover', true),
                ),
            );
            subscriptions.add(
                this.controllerCursor.leave$.subscribe(() =>
                    this.memory.set('hover', false),
                ),
            );
        }
        return subscriptions;
    }

    protected override adjustScaleAndPosition(resize: { x: number; y: number; width: number; height: number }) {
        this.designer?.adjustToParentAfterSizeChange(resize);
    }
}
