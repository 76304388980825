import {IMAGE_RECORD_SCHEMA, ImageRecord} from '@nirby/media-models';
import {z} from 'zod';
import {ZodSchemaBuild} from '@nirby/store/models';

export type PopOpeningStyle = 'fullscreen' | 'bottom-right';
export type PopperDesign = 'small' | 'standard' | 'large';

export type PopPosition =
    | 'CENTER'
    | 'BOTTOM-LEFT-CORNER'
    | 'BOTTOM-RIGHT-CORNER'
    | 'TOP-LEFT-CORNER'
    | 'TOP-RIGHT-CORNER';

export interface PopperConfig {
    body: string;
    buttonLabel: string;
    imageSrc: ImageRecord | null | string;
    design: PopperDesign;
    margin: {
        x: number;
        y: number;
    };
    openingStyle?: PopOpeningStyle;
    position?: PopPosition;
}

export const POPPER_CONFIG_SCHEMA: ZodSchemaBuild<PopperConfig> = z.object({
    body: z.string(),
    buttonLabel: z.string(),
    imageSrc: z.union([z.string(), z.null(), IMAGE_RECORD_SCHEMA]),
    design: z.union([
        z.literal('small'),
        z.literal('standard'),
        z.literal('large'),
    ]),
    margin: z.object({
        x: z.number(),
        y: z.number(),
    }),
    openingStyle: z.union([z.literal('fullscreen'), z.literal('bottom-right')]),
    position: z.union([
        z.literal('CENTER'),
        z.literal('BOTTOM-LEFT-CORNER'),
        z.literal('BOTTOM-RIGHT-CORNER'),
        z.literal('TOP-LEFT-CORNER'),
        z.literal('TOP-RIGHT-CORNER'),
    ]),
});
