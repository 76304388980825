import { Component, Input, OnInit } from '@angular/core';
import { ImageRecord } from '@nirby/media-models';

@Component({
    selector: 'nirby-image-record-view',
    templateUrl: './image-record-view.component.html',
    styleUrls: ['./image-record-view.component.scss'],
})
export class ImageRecordViewComponent {
    @Input() image?: ImageRecord | null = null;
    @Input() alt = 'image';
}
