import Konva from 'konva';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';
import { clamp } from '@nirby/js-utils/math';
import Vector2d = Konva.Vector2d;
import { filter } from 'rxjs/operators';

const ZOOM_RANGE = [0.1, 8.0];

const ZOOM_BY = 1.03;
const PAN_SPEED = 0.3;

/**
 * Controller in charge of Zoom
 */
export class ZoomAndPanController {
    private readonly zoomSubject: BehaviorSubject<number>;

    public readonly zoom$: Observable<number>;

    /**
     * Starts listening for wheel events on the stage, and updates the zoom level accordingly
     *
     * @returns - A subscription that should be used to unsubscribe from the event
     */
    public subscribeToZoom(): Subscription {
        return fromEvent<WheelEvent>(this.stage, 'wheel')
            .pipe(filter((evt) => evt.ctrlKey))
            .subscribe((event) => {
                event.preventDefault();
                this.zoomRelative(event.deltaY);
            });
    }

    /**
     * Starts listening for wheel button press, and pans the stage accordingly
     *
     * @returns - A subscription that should be used to unsubscribe from the event
     */
    public subscribeToPan(): Subscription {
        const subscriptions = new Subscription();
        // mark stage as draggable when clicked with the middle button
        subscriptions.add(
            fromEvent<MouseEvent>(this.stage, 'mousedown').subscribe((evt) => {
                this.stage.draggable(evt.button === 1);
            }),
        );
        subscriptions.add(
            fromEvent(this.stage, 'dragmove').subscribe(() => {
                this.stage.batchDraw();
            }),
        );
        subscriptions.add(
            fromEvent<WheelEvent>(this.stage, 'wheel')
                .pipe(filter((evt) => !evt.ctrlKey))
                .subscribe((event) => {
                    event.preventDefault();
                    this.pan(event.deltaX, event.deltaY);
                }),
        );
        return subscriptions;
    }

    /**
     * Constructor.
     * @param stage Stage to zoom
     * @param centerShape Whether to center the stage on the center of the stage or its top left corner
     */
    constructor(
        private stage: Konva.Stage,
        private readonly centerShape?: Konva.Node,
    ) {
        this.zoomSubject = new BehaviorSubject<number>(1);
        this.zoom$ = this.zoomSubject.asObservable();
    }

    /**
     * Zoom level
     * @param newScale New zoom level
     */
    public set zoom(newScale: number) {
        this.zoomAt(newScale, {
            x: this.stage.width() / 2,
            y: this.stage.height() / 2,
        });
    }
    /**
     * Zoom level
     */
    public get zoom(): number {
        return this.stage.scaleX();
    }

    public pan(x: number, y: number): void {
        const oldPosition = this.stage.position();
        this.stage.position({
            x: oldPosition.x + x * PAN_SPEED * -1,
            y: oldPosition.y + y * PAN_SPEED * -1,
        });
        this.stage.batchDraw();
    }

    get resetPosition(): Vector2d {
        const container = this.stage.container();
        if (this.centerShape && container) {
            const centerShape = this.centerShape.position();
            const shapeScale = this.centerShape.getAbsoluteScale();
            const shapeSize = {
                width: this.centerShape.width() * shapeScale.x,
                height: this.centerShape.height() * shapeScale.y,
            };
            return {
                x:
                    container.clientWidth / 2 +
                    centerShape.x -
                    shapeSize.width / 2,
                y:
                    container.clientHeight / 2 +
                    centerShape.y -
                    shapeSize.height / 2,
            };
        }
        return {
            x: 0,
            y: 0,
        };
    }

    public reset(): void {
        this.zoom = 1;
        this.stage.position(this.resetPosition);
    }

    public zoomAt(newScale: number, pointer: Vector2d): void {
        const oldScale = this.stage.scaleX();

        const mousePointTo = {
            x: (pointer.x - this.stage.x()) / oldScale,
            y: (pointer.y - this.stage.y()) / oldScale,
        };

        const newPos = {
            x: pointer.x - mousePointTo.x * newScale,
            y: pointer.y - mousePointTo.y * newScale,
        };
        this.stage.position(newPos);
        this.stage.scale({ x: newScale, y: newScale });

        this.zoomSubject.next(newScale);
        this.stage.batchDraw();
    }

    public zoomRelative(delta: number): void {
        const pointer = this.stage.getPointerPosition();
        if (!pointer) {
            return;
        }

        delta *= -1;
        const oldScale = this.stage.scaleX();
        const newScale = clamp(
            delta > 0 ? oldScale * ZOOM_BY : oldScale / ZOOM_BY,
            ZOOM_RANGE[0],
            ZOOM_RANGE[1],
        );
        this.zoomAt(newScale, pointer);
    }
}
