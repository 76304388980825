import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {RouteParametersService} from '@nirby/shared/database';

@Component({
    selector: 'nirby-settings-widget-script',
    templateUrl: './settings-widget-script.component.html',
    styleUrls: ['./settings-widget-script.component.scss'],
})
export class SettingsWidgetScriptComponent {
    widgetId$: Observable<string>;

    constructor(private routeParams: RouteParametersService) {
        this.widgetId$ = routeParams.widgetId$;
    }
}
