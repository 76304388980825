import {z} from 'zod';
import {ZodSchemaBuild} from '@nirby/store/models';

export type GoToPrimeSettings = {
    primeId: string | null;
    sourceId: string | null;
    time: number;
    variablesHandling: 'keep' | 'clean';
    ignoreAggroGeneration: boolean;
};
export const GO_TO_PRIME_SETTINGS_SCHEMA: ZodSchemaBuild<GoToPrimeSettings> =
    z.object({
        primeId: z.string().nullable(),
        sourceId: z.string().nullable(),
        time: z.number(),
        variablesHandling: z.union([z.literal('keep'), z.literal('clean')]),
        ignoreAggroGeneration: z.boolean().default(false),
    });
