<div class="card route-panel border-0 rounded-0">
    <div class="list-group" id="routes-list" role="tablist">
        <!--NgClass to selected route, draggable list of custom routes. -->
        <div
            class="list-group-item list-group-item-action routes-group border-0"
        >
            <!-- Dropdown menu. -->
            <li class="option-route float-right" ngbDropdown>prueba</li>
        </div>
    </div>
</div>
