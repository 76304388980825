<div class="nirby-card p-3 mb-4">
    <div class="dashboard-projector">
        <div class="projector-header py-2 mb-3">
            <div class="d-lg-flex">
                <div
                    class="projector-header-title me-lg-4 mb-3 mb-lg-0 text-center text-lg-left d-flex flex-column justify-content-center"
                >
                    <ng-content select="[header]"></ng-content>
                </div>
            </div>
        </div>
        <div class="projector-body" [ngStyle]="{'min-height': minHeight + 'px'}">
            <div class="d-flex" [ngClass]="['flex-' + orientation]">
                <div>
                    <ng-content select="[subheader]"></ng-content>
                </div>
                <div class="flex-1">
                    <ng-content select="[body]"></ng-content>
                </div>
            </div>
        </div>
    </div>
</div>
