<input
    #textInput
    [hidden]="!renameMode || disabled"
    class="edit-in-place"
    (blur)="update(textInput.value)"
    [(ngModel)]="valueEdit"
    (keydown.enter)="$event.preventDefault();update(textInput.value)"
    (keydown.escape)="$event.preventDefault();cancelRename()"
>
<ng-container *ngIf="!renameMode || disabled">
    <ng-container [ngSwitch]="tag">
        <ng-container *ngSwitchCase="'span'">
            <span
                class="edit-in-place"
                [ngClass]="spanClass"
                (click)="startRenameOnClick(textInput)"
            >
                <ng-container *ngTemplateOutlet="inner"></ng-container>
            </span>
        </ng-container>
        <ng-container *ngSwitchCase="'p'">
            <p
                class="edit-in-place"
                [ngClass]="spanClass"
                (click)="startRenameOnClick(textInput)"
            >
                <ng-container *ngTemplateOutlet="inner"></ng-container>
            </p>
        </ng-container>
        <ng-container *ngSwitchCase="'h1'">
            <h1
                class="edit-in-place"
                [ngClass]="spanClass"
                (click)="startRenameOnClick(textInput)"
            >
                <ng-container *ngTemplateOutlet="inner"></ng-container>
            </h1>
        </ng-container>
        <ng-container *ngSwitchCase="'h2'">
            <h2
                class="edit-in-place"
                [ngClass]="spanClass"
                (click)="startRenameOnClick(textInput)"
            >
                <ng-container *ngTemplateOutlet="inner"></ng-container>
            </h2>
        </ng-container>
        <ng-container *ngSwitchCase="'h3'">
            <h3
                class="edit-in-place"
                [ngClass]="spanClass"
                (click)="startRenameOnClick(textInput)"
            >
                <ng-container *ngTemplateOutlet="inner"></ng-container>
            </h3>
        </ng-container>
        <ng-container *ngSwitchCase="'h4'">
            <h4
                class="edit-in-place"
                [ngClass]="spanClass"
                (click)="startRenameOnClick(textInput)"
            >
                <ng-container *ngTemplateOutlet="inner"></ng-container>
            </h4>
        </ng-container>
        <ng-container *ngSwitchCase="'h5'">
            <h5
                class="edit-in-place"
                [ngClass]="spanClass"
                (click)="startRenameOnClick(textInput)"
            >
                <ng-container *ngTemplateOutlet="inner"></ng-container>
            </h5>
        </ng-container>
        <ng-container *ngSwitchCase="'h6'">
            <h6
                class="edit-in-place"
                [ngClass]="spanClass"
                (click)="startRenameOnClick(textInput)"
            >
                <ng-container *ngTemplateOutlet="inner"></ng-container>
            </h6>
        </ng-container>
    </ng-container>
</ng-container>
<ng-template #inner>
    <span class="me-2">{{ preview }}</span>
    <fa-icon [classes]="['text-color-black', styleClass ?? '']" [icon]="icons.edit"></fa-icon>
</ng-template>
