import Konva from 'konva';
import {Block} from '../block';
import {ImageBlock} from '@nirby/models/nirby-player';
import {getImageClip} from '../../utils';
import {BehaviorSubject, distinctUntilChanged, Observable, shareReplay, Subscription, switchMap} from 'rxjs';
import {ERROR_IMAGE, waitImageLoad} from '../utils';

/**
 * Image block drawable.
 */
export class ImageBlockDrawable<TMeta> extends Block<TMeta, ImageBlock, Konva.Image> {
    private readonly imageSourceController = new BehaviorSubject<string | null>(
        null,
    );
    public readonly image$: Observable<HTMLImageElement> =
        this.imageSourceController.pipe(
            distinctUntilChanged(),
            switchMap((image) => image ? waitImageLoad(image) : ERROR_IMAGE),
            shareReplay({refCount: true, bufferSize: 1}),
        );

    private subscription = new Subscription();

    /**
     * Changes the current Image source.
     * @param source The new source.
     */
    changeImageSource(source: string): void {
        this.imageSourceController.next(source);
    }

    /**
     * Crops an image to fit the block.
     * @param img The image to crop.
     * @private
     */
    private cropShape(img: Konva.Image): void {
        img.setAttr('lastCropUsed', this.blockProperties.positioning);
        const crop = getImageClip(
            img.image() as HTMLImageElement,
            {width: img.width(), height: img.height()},
            this.blockProperties.positioning,
        );
        img.setAttrs(crop);
    }

    /**
     * Crops the current image.
     * @private
     */
    private crop(): void {
        if (!this.shape) {
            return;
        }
        this.cropShape(this.shape);
    }

    /**
     * Generates the shape.
     */
    async generateShape(): Promise<Konva.Image> {
        const image = await ERROR_IMAGE;
        this.imageSourceController.next(this.blockProperties.src);
        const konvaImage = new Konva.Image({
            x: this.configPosition.x,
            y: this.configPosition.y,
            rotation: this.properties.rotation,
            image,
            width: this.configSize.x,
            height: this.configSize.y,
        });
        this.subscription.add(
            this.image$.subscribe((image) => {
                konvaImage.image(image);
                this.cropShape(konvaImage);
                this.batchDraw();
            }),
        );
        this.cropShape(konvaImage);
        return konvaImage;
    }

    /**
     * Unsubscribes from everything.
     */
    override dispose() {
        super.dispose();
        this.subscription.unsubscribe();
        this.subscription = new Subscription();
    }

    /**
     * Updates the shape from the block data.
     * @protected
     */
    protected async updateShapeFromBlock(): Promise<void> {
        const shape = this.shape;
        if (!shape) {
            return;
        }
        this.changeImageSource(this.blockProperties.src);
    }

    /**
     * Subscribe to the block events.
     * @param shape The shape to subscribe to.
     * @protected
     *
     * @returns The subscription.
     */
    protected override subscribeToEvents(shape: Konva.Image): Subscription {
        const subscription = super.subscribeToEvents(shape);
        if (this.editable) {
            subscription.add(
                this.transformController.resizer.postResize$.subscribe(() =>
                    this.crop(),
                ),
            );
        }
        return subscription;
    }

    /**
     * Preloads the image.
     *
     * @returns - The image.
     */
    override preload(): Promise<void> {
        return waitImageLoad(this.properties.content.src).then();
    }
}
