import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
} from '@angular/core';
import {
    EmojiData,
    EmojiGroup,
    EmojiService,
} from '../../../services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { faSearch, faSmile } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'nirby-emoji-picker',
    templateUrl: './emoji-picker.component.html',
    styleUrls: ['./emoji-picker.component.scss'],
})
export class EmojiPickerComponent {
    @Input() disabled: boolean | null = null;
    @Input() value: string | null = null;
    @Output() valueChange = new EventEmitter<string | null>();

    @ViewChild('picker') picker: ElementRef | null = null;
    emojis$: Observable<EmojiGroup[]> = of([]);
    searchQuery = new UntypedFormControl('');
    iconSearch = faSearch;
    iconSmile = faSmile;
    currentEmojiGroup: EmojiGroup | null = null;
    isSearching = false;

    constructor(private service: EmojiService, private modal: NgbModal) {
        this.search();
        this.isSearching = false;
    }

    changeCurrentEmojiGroup(emojiGroup: EmojiGroup): void {
        this.isSearching = false;
        this.currentEmojiGroup = emojiGroup;
    }

    async add(): Promise<void> {
        if (!this.value) {
            await this.pickRandom();
        } else {
            this.modal.open(this.picker, { size: 'lg' });
        }
    }

    clear(): void {
        this.value = null;
        this.valueChange.emit(null);
        this.modal.dismissAll();
    }

    async pickRandom(): Promise<void> {
        this.pick(await this.service.getRandom());
        this.modal.dismissAll();
    }

    pick(emoji: EmojiData): void {
        this.value = emoji.emoji;
        this.valueChange.emit(emoji.emoji);
        this.modal.dismissAll();
    }

    search(): void {
        this.isSearching = true;
        this.emojis$ = this.service.searchGroupedEmojis(this.searchQuery.value);
    }
}
