<button
    class="btn btn-radius btn-emoji-picker"
    [disabled]="disabled"
    (click)="add(); $event.preventDefault(); $event.stopPropagation()"
>
    <span *ngIf="value">
        {{ value }}
    </span>
    <small *ngIf="!value">
        <fa-icon class="icon me-1" [icon]="iconSmile"></fa-icon> Add Icon
    </small>
</button>
<ng-template #picker let-c="close">
    <div class="nby-modal">
        <div class="modal-body">
            <div
                class="area-search d-flex justify-content-between align-items-center mb-4"
            >
                <div class="form-group form-group-search flex-1 mb-0 me-2">
                    <div class="inner-addon right-addon">
                        <input
                            id="search"
                            class="form-control"
                            [formControl]="searchQuery"
                            (input)="search()"
                            placeholder="Search Emoji..."
                        />

                        <div class="inner-addon-item p-3">
                            <fa-icon class="icon" [icon]="iconSearch"></fa-icon>
                        </div>
                    </div>
                </div>

                <div
                    class="actions d-flex justify-content-end align-items-center"
                >
                    <button
                        class="btn btn-outline-dark d-flex align-items-center btn-sm me-1"
                        (click)="pickRandom()"
                    >
                        Random
                    </button>
                    <button
                        class="btn btn-outline-danger d-flex align-items-center btn-sm"
                        (click)="clear()"
                    >
                        Remove
                    </button>
                </div>
            </div>

            <div class="area-emoji-groups">
                <button
                    type="button"
                    class="btn btn-sm btn-radius btn-emoji-group me-1 mb-1"
                    (click)="changeCurrentEmojiGroup(group)"
                    [ngClass]="
                        currentEmojiGroup?.name === group.name
                            ? 'btn-primary'
                            : 'btn-secondary'
                    "
                    *ngFor="let group of emojis$ | async"
                >
                    {{ group.name }}
                </button>
            </div>

            <div class="area-emojies mt-2" *ngIf="isSearching">
                <div class="group" *ngFor="let group of emojis$ | async">
                    <label>{{ group.name }}</label>

                    <div class="emojies d-flex flex-wrap">
                        <div class="emoji" *ngFor="let emoji of group.emojis">
                            <button class="btn btn-icon" (click)="pick(emoji)">
                                {{ emoji.emoji }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="area-emojies d-flex flex-wrap mt-2"
                *ngIf="currentEmojiGroup && !isSearching"
            >
                <div
                    class="emoji"
                    *ngFor="let emoji of currentEmojiGroup.emojis"
                >
                    <button class="btn btn-icon" (click)="pick(emoji)">
                        {{ emoji.emoji }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
