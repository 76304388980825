<header class="component-content-header p-3">
    <h1 class="main-title mb-0">General Settings</h1>
</header>
<div class="component-content-inner p-3">
    <ng-container
        *ngIf="authenticatedUser$ | async as user; else loading"
    >
        <div class="form-group">
            <label class="text-dark" for="email">E-mail</label>
            <input
                id="email"
                class="form-control"
                [disabled]="true"
                [value]="user.email"
            />
        </div>
        <div class="form-group">
            <label class="text-dark" for="password">Password</label>
            <input
                id="password"
                type="password"
                class="form-control"
                [disabled]="true"
                value="password"
            />
        </div>

        <div class="actions mt-3">
            <button
                class="btn btn-outline-secondary w-100 mt-3"
                (click)="sendVerificationEmail(user)"
                [disabled]="user.emailVerified"
            >
                Send email verification again
            </button>
        </div>
    </ng-container>
    <ng-template #loading>
        <div class="spinner-border text-primary"></div>
    </ng-template>
</div>
