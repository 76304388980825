import {Component} from '@angular/core';
import {WorkspaceService} from '@nirby/shared/database';
import {map} from 'rxjs/operators';

@Component({
    selector: 'nirby-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.css'],
})
/**
 * Welcome component
 */
export class WelcomeComponent {
    public readonly workspacePlan$ = this.workspaces.activeWidget$.pipe(
        map(plan => plan?.data.plan ?? null),
    );

    /**
     * The current workspace ID.
     */
    get workspaceId(): string {
        return this.workspaces.workspaceId;
    }

    /**
     * Constructor.
     * @param workspaces The workspace service.
     */
    constructor(
        private readonly workspaces: WorkspaceService,
    ) {
    }
}
