import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {RouteService, WorkspaceService} from '@nirby/shared/database';
import {firstValueFrom, Observable, switchMap} from 'rxjs';
import {map} from 'rxjs/operators';
import {AlertsService} from '@nirby/shared/alerts';

@Component({
    selector: 'nirby-route-panel-modal',
    templateUrl: './route-panel-modal.component.html',
    styleUrls: ['./route-panel-modal.component.scss'],
})
export class RoutePanelModalComponent {
    constructor(
        public modal: NgbActiveModal,
        private routesService: RouteService,
        private widgets: WorkspaceService,
        private alerts: AlertsService,
    ) {
    }

    url$: Observable<string | null> = this.widgets.workspaceId$.pipe(
        switchMap((widgetId) => this.widgets.collection.get(widgetId)),
        map((widget) => widget?.data?.url ?? null),
    );

    get widgetId(): string {
        return this.widgets.workspaceId;
    }

    loading = false;

    routeControl = new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9*/!"#$%&=?_.,-]*$'),
    ]);
    routeTypeControl = new UntypedFormControl('match', [
        Validators.pattern(/^(match|begins-with)$/),
    ]);
    routeForm = new FormGroup({
        newRoute: this.routeControl,
    });


    async createNewRoute(): Promise<void> {
        const route = this.cleanRoute(this.routeControl.value);

        try {
            this.loading = true;

            // Create new route.
            const routePath = await this.routesService.collection(this.widgetId).add(
                {
                    route: {
                        content: route,
                        type: this.routeTypeControl.value ?? 'match',
                    },
                    campaigns: [],
                },
            );

            // Add a new route reference to widget.
            const widgetData = await firstValueFrom(this.widgets.collection.get(this.widgetId))
                .then((w) => w?.data);
            if (!widgetData) {
                this.alerts.error('Something went wrong. Please try again later.');
                return;
            }
            widgetData.priorityList.push({
                routePath,
                route: {
                    content: route,
                    type: this.routeTypeControl.value ?? 'match',
                },
            });

            // Update widget with the new route reference.
            await this.widgets.update(this.widgetId, widgetData);
        } catch (e) {
            this.loading = false;

            this.alerts.error('Something went wrong. Please try again later.');
            throw e;
        }
    }

    cleanRoute(route: string): string {
        // remove duplicated slashes
        let cleanRoute = route.replace(/\/{2,}/g, '/');

        if (!cleanRoute.startsWith('/')) {
            cleanRoute = '/' + cleanRoute;
        }
        if (cleanRoute.endsWith('/')) {
            cleanRoute = cleanRoute.slice(0, -1);
        }

        return cleanRoute;
    }
}
