import {z} from 'zod';
import {ZodSchemaBuild} from '@nirby/store/models';

export interface GoBackSettings {
    variablesHandling: 'keep' | 'clean';
    method: 'aggro' | 'history';
    time: 'start' | 'where-user-left';
    fallbackSourceId?: string | null;
    fallbackTime?: number | null;
}

export const GO_BACK_SETTINGS_SCHEMA: ZodSchemaBuild<GoBackSettings> = z.object(
    {
        variablesHandling: z.union([z.literal('keep'), z.literal('clean')]),
        method: z
            .union([z.literal('aggro'), z.literal('history')])
            .catch(() => 'aggro' as const),
        time: z
            .union([z.literal('start'), z.literal('where-user-left')])
            .catch(() => 'start' as const),
        fallbackSourceId: z.string().nullable().default(null),
        fallbackTime: z.number().nullable().default(null),
    },
);
