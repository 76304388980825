import {Component} from '@angular/core';
import {BlockActionComponent} from '../../blocks/block-action.component';
import {GoBackAction} from '@nirby/models/nirby-player';
import {DropdownItem, Form} from '@nirby/shared/widgets';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PrimeSourcesService} from '@nirby/shared/database';

@Component({
    selector: 'nirby-action-go-back',
    templateUrl: './action-go-back.component.html',
    styleUrls: ['./action-go-back.component.scss'],
})
/**
 * Go back action form component.
 */
export class ActionGoBackComponent extends BlockActionComponent<GoBackAction> {
    goToOptions$: Observable<DropdownItem<null | string>[]>;

    formG = Form.group<GoBackAction['options']>({
        variablesHandling: 'keep',
        method: 'history',
        time: 'where-user-left',
        fallbackSourceId: null,
        fallbackTime: null,
    });

    form = this.formG.form;
    timeControl = this.formG.getFormControl('time');
    methodControl = this.formG.getFormControl('method');
    variablesHandling = this.formG.getFormControl('variablesHandling');
    videosControl = this.formG.getFormControl('fallbackSourceId');
    fallbackTimeControl = this.formG.getFormControl('fallbackTime');

    constructor(
        private sources: PrimeSourcesService,
    ) {
        super();
        this.goToOptions$ = this.sources.availableVideos$.pipe(
            map((siblings) => {
                return [
                    {
                        label: 'Select a video...',
                        value: null,
                    },
                    ...siblings
                        .sort((a, b) => a.data.name.localeCompare(b.data.name))
                        .map((s) => ({
                            label: s.data.name,
                            value: s.ref.id,
                        })),
                ];
            }),
        );
    }

    methods: DropdownItem<GoBackAction['options']['method']>[] = [
        {
            label: 'Highest aggro',
            value: 'aggro',
        },
        {
            label: 'Last visited source',
            value: 'history',
        },
    ];
    timeOptions: DropdownItem<GoBackAction['options']['time']>[] = [
        {
            label: 'Where user left',
            value: 'where-user-left',
        },
        {
            label: 'Beginning of Prime',
            value: 'start',
        },
    ];
    variableHandlingOptions: DropdownItem<GoBackAction['options']['variablesHandling']>[] = [
        {
            label: 'Keep variables',
            value: 'keep',
        },
        {
            label: 'Reset variables',
            value: 'clean',
        },
    ];

    /**
     * Set data to the form.
     * @param content The data to set.
     */
    setData(content: GoBackAction | null): void {
        this.form.patchValue(content?.options ?? {});
    }
}
