import {readBinaryFile} from '@tauri-apps/api/fs';
import {KNOWN_MIMES, MimePattern} from './mime-patterns';

/**
 * Class to detect the MIME type of a file.
 */
export class FileDetector {
    private static _instance = new FileDetector();

    /**
     * Constructor.
     * @private
     */
    private constructor() {
        return;
    }

    /**
     * Instance of the FileDetector.
     */
    public static get instance(): FileDetector {
        return this._instance;
    }

    /**
     * Checks if the Magic Number of a file matches a given mime type.
     * @param bytes - The first bytes of the file.
     * @param mime - The mime type to check.
     *
     * @returns - True if the mime type matches, false otherwise.
     */
    public checkMimePattern(bytes: Uint8Array, mime: MimePattern): boolean {
        for (let i = 0; i < mime.pattern.length; i++) {
            if (!mime.pattern[i] && bytes[i + mime.offset] !== mime.pattern[i]) {
                return false;
            }
        }
        return true;
    }

    /**
     * Load the mime type based on the signature of the first bytes of the file
     * @param  {File}   fileData        A instance of File
     *
     * @returns {Promise}            A promise that resolves to the mime type
     *
     * @author Victor www.vitim.us
     * @date   2017-03-23
     *
     * @reference https://stackoverflow.com/questions/18299806/how-to-check-file-mime-type-with-javascript-before-upload
     */
    public loadFileMime(fileData: Uint8Array): string | null {
        const blob = fileData.slice(0, 4);

        for (const mime of KNOWN_MIMES) {
            if (this.checkMimePattern(blob, mime)) {
                return mime.mime;
            }
        }
        return null;
    }

    /**
     * Loads a local file path to a file object.
     * @param path - The file path in the user's file system.
     *
     * @returns - The file object.
     * @private
     */
    public async transformPathToFile(path: string): Promise<File> {
        const response = await readBinaryFile(path);
        const mimeType = this.loadFileMime(response);
        return new File([response], path, mimeType ? {type: mimeType} : undefined);
    }
}
