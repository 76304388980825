import { Component, Input, OnInit } from '@angular/core';
import { faArrowRight, faPlay } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'nirby-videos-widget',
    templateUrl: './videos-widget.component.html',
    styleUrls: ['./videos-widget.component.scss'],
})
export class VideosWidgetComponent {
    @Input() thumbSource: string | null = null;
    @Input() videoURL: string | null = null;

    icon = faArrowRight;
    iconPlay = faPlay;




}
