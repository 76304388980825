import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { FontInterface } from 'ngx-font-picker';
import { FontSource } from '@nirby/media-models';

@Component({
    selector: 'nirby-font-picker',
    templateUrl: './font-picker.component.html',
    styleUrls: ['./font-picker.component.scss'],
})
export class FontPickerComponent implements OnInit, OnChanges {
    @Input() fontFamily?: FontSource;
    @Output() fontFamilyChange = new EventEmitter<FontSource>();

    font: FontInterface = {
        family: 'Roboto',
        size: '16px',
        style: '',
    };

    ngOnInit(): void {
        this.updateFont();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['fontFamily']) {
            this.updateFont();
        }
    }

    private updateFont(): void {
        this.font = {
            family: this.fontFamily?.name ?? 'Roboto',
            size: '16px',
            style: 'regular',
        };
    }

    selectFont(font: FontInterface): void {
        this.font = {
            family: font.family ?? 'Roboto',
            size: '16px',
            style: 'regular',
        };
        this.fontFamily = {
            name: font.family,
            src: font.files.regular,
        };
        this.fontFamilyChange.emit(this.fontFamily);
    }
}
