/* eslint-disable */
import Konva from 'konva';
import {Attributes, PartialRawAttributes, RawAttributes,} from './attributes.model';
import {Attribute, callFunction} from '../functions.model';
import {
    AnyShapeUnitName,
    BlockContext,
    ShapeUnitAttributesOf,
    ShapeUnitListenableEvent,
    ShapeUnitRawDescription,
} from './index';
import {AxisMixin, BlockEvent, ShapeUnitEventsMap} from '../unit.model';
import {filter, Subject, Subscription} from 'rxjs';
import {UnitCursorListener} from '../../unit-cursor-listener';
import {UnitMemoryInterface} from '../../unit-memory-interface';
import {AnyBlock, AttributeValue} from '@nirby/models/nirby-player';
import {WeakTypedState} from '@nirby/runtimes/context';
import Vector2d = Konva.Vector2d;

export function parsePartialAttribute<TA extends AttributeValue>(
    attr: Attribute<TA> | undefined,
    defaultValue: TA | null = null,
    context: BlockContext,
    unitState: WeakTypedState<string>
): TA | null {
    const value =
        typeof attr === 'object' && attr != null
            ? (callFunction(attr, context, unitState) as TA)
            : attr;
    return value ? parseAttribute(value, context, unitState) : defaultValue;
}

export function parseAttribute<TA extends AttributeValue>(
    attr: Attribute<TA>,
    context: BlockContext,
    unitState: WeakTypedState<string>
): TA | null {
    return typeof attr === 'object' && attr != null
        ? (callFunction(attr, context, unitState) as TA)
        : attr;
}

export function parseAttributes<T extends Attributes>(
    attrs: RawAttributes<T>,
    context: BlockContext,
    unitState: WeakTypedState<string>
): T {
    const parsed: Partial<T> = {};
    let attr: keyof T;
    for (attr in attrs) {
        parsed[attr] =
            parseAttribute<T[typeof attr]>(attrs[attr], context, unitState) ??
            undefined;
    }
    return parsed as T;
}

export function parsePartialAttributes<T extends Attributes>(
    attrs: PartialRawAttributes<T>,
    defaultValues: T,
    context: BlockContext,
    unitState: WeakTypedState<string>
): T {
    const parsed: Partial<T> = {};
    let attr: keyof T;
    for (attr in defaultValues) {
        if (!defaultValues.hasOwnProperty(attr)) {
            continue;
        }
        parsed[attr] =
            parsePartialAttribute<T[typeof attr]>(
                attrs[attr],
                defaultValues[attr],
                context,
                unitState
            ) ?? undefined;
    }
    return parsed as T;
}

export interface Size {
    width: number;
    height: number;
}

export type Bounds = Vector2d & Size;

export class ContextUtils {
    constructor(
        public readonly context: BlockContext,
        private readonly unitState: WeakTypedState
    ) {
    }

    public parse<T extends AttributeValue>(
        attr: Attribute<T> | undefined,
        defaultValue: T
    ): T {
        if (attr === undefined) {
            return defaultValue;
        }
        return parseAttribute<T>(attr, this.context, this.unitState) ?? defaultValue;
    }
}

/**
 * ShapeUnit is a base class for all shape units.
 *
 * To create a new shape unit, extend this class and implement the {@link draw} method to draw this unit.
 */
export abstract class ShapeUnit<ShapeUnitName extends AnyShapeUnitName> {
    public get events(): ShapeUnitEventsMap {
        return this.description?.events ?? {};
    }

    /**
     * The event controller for the stateful shape unit.
     * @private
     */
    private readonly eventsController = new Subject<BlockEvent>();

    /**
     * The state memory of the shape unit.
     */
    public readonly memory: UnitMemoryInterface;

    /**
     * Emits an event.
     * @param event The event to emit.
     * @protected
     */
    protected emit(event: BlockEvent): void {
        this.eventsController.next(event);
    }

    /**
     * Observe events emitted by this shape unit.
     */
    public readonly events$ = this.eventsController.asObservable();

    public get rotation(): number {
        return this.shape.parent?.rotation() ?? this.shape.rotation();
    }

    public get rotationCosine(): number {
        return Math.cos((this.rotation * Math.PI) / 180);
    }

    public get rotationSine(): number {
        return Math.sin((this.rotation * Math.PI) / 180);
    }

    public get attributes(): ShapeUnitAttributesOf<ShapeUnitName> {
        return this.storedAttributes ?? this.defaultValues;
    }

    public get parentBounds(): Bounds {
        return this.lastBounds ?? {x: 0, y: 0, width: 0, height: 0};
    }

    public get block(): AnyBlock {
        return this.context.block;
    }

    public constructor(
        public readonly id: string,
        private readonly context: BlockContext,
        protected readonly shape: Konva.Shape,
        private readonly children: ShapeUnit<ShapeUnitName>[],
        public readonly editable: boolean,
        initialDescription: ShapeUnitRawDescription<ShapeUnitName>
    ) {
        this.memory = new UnitMemoryInterface(context.state.mask(this.id));
        this.description = initialDescription;
        this.cursorListener = new UnitCursorListener(shape, () => this.bounds);
    }

    protected readonly cursorListener: UnitCursorListener;

    /**
     * Get current Konva layer
     * @private
     */
    protected get layer(): Konva.Layer | null {
        // TODO: Get the shape's layer storing it on the constructor
        return this.shape.getLayer();
    }

    abstract defaultValues: ShapeUnitAttributesOf<ShapeUnitName>;

    private storedAttributes?: ShapeUnitAttributesOf<ShapeUnitName>;
    private lastBounds?: Bounds;

    protected description: ShapeUnitRawDescription<ShapeUnitName>;

    /**
     * Batch draws the layer
     * @protected
     */
    protected batchDraw(): void {
        this.layer?.batchDraw();
    }

    /**
     * Calculates the bounds of the shape unit from its axis description and its parent bounds.
     * @param axisMixin The axis description of the shape unit.
     * @param parentBounds The bounds of the parent shape unit.
     */
    public static getChildBounds(
        axisMixin: AxisMixin,
        parentBounds: Bounds,
        context: ContextUtils,
    ): Bounds {
        const bounds: Bounds = {...parentBounds};
        // horizontal bounds
        switch (axisMixin.axis.horizontal) {
            case 'center':
                bounds.x =
                    parentBounds.x +
                    parentBounds.width / 2 -
                    axisMixin.axis.width / 2;
                bounds.width = axisMixin.axis.width;
                break;
            case 'stretch':
                bounds.x = parentBounds.x + axisMixin.axis.left;
                bounds.width =
                    parentBounds.width -
                    axisMixin.axis.right -
                    axisMixin.axis.left;
                break;
            case 'pin-left':
                bounds.x = parentBounds.x + axisMixin.axis.left;
                bounds.width = axisMixin.axis.width;
                break;
            case 'pin-right':
                bounds.x =
                    parentBounds.x +
                    parentBounds.width -
                    axisMixin.axis.right -
                    axisMixin.axis.width;
                bounds.width = axisMixin.axis.width;
                break;
            case 'relative':
                bounds.x =
                    parentBounds.x + axisMixin.axis.left * parentBounds.width;
                bounds.width =
                    parentBounds.width *
                    (1 - axisMixin.axis.right - axisMixin.axis.left);
                break;
        }
        switch (axisMixin.axis.vertical) {
            case 'center':
                bounds.y =
                    parentBounds.y +
                    parentBounds.height / 2 -
                    context.parse(axisMixin.axis.height, 0) / 2;
                bounds.height = context.parse(axisMixin.axis.height, 0);
                break;
            case 'stretch':
                bounds.y = parentBounds.y + axisMixin.axis.top;
                bounds.height =
                    parentBounds.height -
                    axisMixin.axis.bottom -
                    axisMixin.axis.top;
                break;
            case 'pin-top':
                bounds.y = parentBounds.y + axisMixin.axis.top;
                bounds.height = context.parse(axisMixin.axis.height, 0);
                break;
            case 'pin-bottom':
                bounds.y =
                    parentBounds.y +
                    parentBounds.height -
                    axisMixin.axis.bottom -
                    context.parse(axisMixin.axis.height, 0);
                bounds.height = context.parse(axisMixin.axis.height, 0);
                break;
            case 'relative':
                bounds.y =
                    parentBounds.y + parentBounds.height * axisMixin.axis.top;
                bounds.height =
                    parentBounds.height *
                    (1 - axisMixin.axis.bottom - axisMixin.axis.top);
                break;
        }
        return bounds;
    }

    myBounds?: Bounds;
    extraListenTo: ShapeUnitListenableEvent[] = [];

    get bounds(): Bounds {
        return this.myBounds ?? {x: 0, y: 0, width: 0, height: 0};
    }

    /**
     * Draws this unit and all its children.
     * @param ctx The context to draw on.
     * @param description The description of this unit.
     * @param context The context of the block.
     * @param bounds The bounds of this unit.
     */
    public drawTree(
        ctx: CanvasRenderingContext2D,
        description: ShapeUnitRawDescription<ShapeUnitName>,
        context: BlockContext,
        bounds: Bounds
    ): void {
        const attributes = description.attributes as RawAttributes<
            ShapeUnitAttributesOf<ShapeUnitName>
        >;
        const parsed = parsePartialAttributes<
            ShapeUnitAttributesOf<ShapeUnitName>
        >(attributes, this.defaultValues, context, this.memory.state);
        this.myBounds = bounds;
        this.draw(ctx, parsed, bounds);

        // Draw the children of this shape
        let child: ShapeUnit<any>;
        for (child of this.children) {
            if (!child.description) {
                continue;
            }
            const childBounds = ShapeUnit.getChildBounds(
                child.description,
                bounds,
                new ContextUtils(context, this.memory.state)
            );
            child.drawTree(ctx, child.description, context, childBounds);
        }
    }

    /**
     * Draws the shape on the given bounds.
     * @param bounds The shape bounds.
     */
    public adjustToParent(bounds: Bounds): void {
        this.lastBounds = bounds;
        this.children.forEach((child) => child.adjustToParent(bounds));
    }

    /**
     * This method is called to draw the shape into the canvas in the given bounds.
     * @param ctx Canvas rendering context
     * @param attributes Attributes of the shape
     * @param bounds Bounds where the shape will be drawn into
     * @protected
     */
    protected abstract draw(
        ctx: CanvasRenderingContext2D,
        attributes: ShapeUnitAttributesOf<ShapeUnitName>,
        bounds: Bounds
    ): void;

    /**
     * Updates this shape unit.
     * @param description The new description of this unit.
     * @param context The context of the block.
     * @param bounds The bounds of this unit.
     */
    public async update(
        description: ShapeUnitRawDescription<ShapeUnitName>,
        context: BlockContext,
        bounds: Bounds
    ): Promise<void> {
        await Promise.all(
            this.children.map(async (child, index) => {
                const d = description.children[index];
                await child.update(d as any, context, bounds);
            })
        );
        this.storedAttributes = parsePartialAttributes<
            ShapeUnitAttributesOf<ShapeUnitName>
        >(
            description.attributes as RawAttributes<
                ShapeUnitAttributesOf<ShapeUnitName>
            >,
            this.defaultValues,
            context,
            this.memory.state
        );
        this.description = description;
        this.lastBounds = bounds;
        await this.onUpdate(this.attributes);
    }

    /**
     * Called when the attributes of this shape have been updated.
     * @protected
     */
    protected async onUpdate(
        attributes: ShapeUnitAttributesOf<ShapeUnitName>
    ): Promise<void> {
    }

    public get cursor(): string {
        const container = this.shape.getStage()?.container();
        if (container) {
            return container.style.cursor;
        }
        return 'normal';
    }

    public set cursor(value: string) {
        const container = this.shape.getStage()?.container();
        if (container && value !== 'ignore') {
            container.style.cursor = value;
        }
    }

    subscriptions = new Subscription();

    startPlayMode() {
        const events = this.events;
        if (events.click) {
            this.subscriptions.add(
                this.cursorListener.click$
                    .pipe(filter(() => !this.memory.disabled))
                    .subscribe(() => {
                        const event = this.events.click;
                        if (!event) {
                            return;
                        }
                        const parsed: BlockEvent = {
                            type: event.type,
                            properties: parseAttributes(
                                event.properties,
                                this.context,
                                this.memory.state
                            ),
                        } as BlockEvent;
                        this.emit(parsed);
                    })
            );
        }
        const listenableEvents = new Set([
            ...(this.description?.listenTo ?? []),
            ...this.extraListenTo,
        ]);

        if (listenableEvents.has('isHover')) {
            this.subscriptions.add(
                this.cursorListener.hover$.subscribe((isHovering) => {
                    this.memory.set('isHover', isHovering);
                    if (this.attributes.cursor !== 'ignore') {
                        this.cursor = isHovering
                            ? this.attributes.cursor
                            : 'default';
                    }
                    this.batchDraw();
                })
            );
        }
        if (listenableEvents.has('isMouseDown')) {
            this.subscriptions.add(
                this.cursorListener.isMouseDown$.subscribe((isMouseDown) => {
                    this.memory.set('isMouseDown', isMouseDown);
                    this.batchDraw();
                })
            );
        }
        if (listenableEvents.has('stateChanges')) {
            this.subscriptions.add(
                this.memory.state$.subscribe(() => this.batchDraw())
            );
        }
    }

    dispose(): void {
        this.children.forEach((s) => s.dispose());
        this.subscriptions.unsubscribe();
        this.subscriptions = new Subscription();
    }

    onStart(): void {
        if (!this.editable) {
            this.startPlayMode();
        }
    }
}
