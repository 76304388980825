<ng-container *ngIf="blockController$ | async as controller">
    <div class="h-100 bar">
        <div class="header p-3">
            <ul ngbNav #nav="ngbNav" class="nav-pills">
                <li
                    ngbNavItem="content"
                    id="content"
                    [disabled]="!hasContent(controller)"
                    *ngIf="hasContent(controller)"
                >
                    <a ngbNavLink>Content</a>
                    <ng-template ngbNavContent>
                        <div class="p-3" *ngIf="controller.type">
                            <ng-template
                                ngDefaultControl
                                nirbyBlockContentHost
                                [controller]="controller"
                            ></ng-template>
                        </div>
                    </ng-template>
                </li>
                <li
                    ngbNavItem="style"
                    id="style"
                    [disabled]="!hasStyle(controller)"
                    *ngIf="hasStyle(controller)"
                >
                    <a ngbNavLink>Style</a>
                    <ng-template ngbNavContent>
                        <div class="p-3" *ngIf="controller.type">
                            <ng-template
                                ngDefaultControl
                                nirbyBlockStyleHost
                                [controller]="controller"
                            ></ng-template>
                        </div>
                    </ng-template>
                </li>
                <li ngbNavItem="actions" id="actions">
                    <a ngbNavLink>Actions</a>
                    <ng-template ngbNavContent>
                        <div class="p-3" *ngIf="controller">
                            <nirby-action-generic-editor
                                [variables]="variables"
                                [cardSiblings]="cardSiblings"
                                [productType]="productType"
                                [controller]="controller"
                                (closeRequest)="close()"
                            ></nirby-action-generic-editor>
                        </div>
                    </ng-template>
                </li>
            </ul>
        </div>
        <div [ngbNavOutlet]="nav"></div>
    </div>
</ng-container>
