import {Component} from '@angular/core';

@Component({
    selector: 'nirby-load-screen',
    templateUrl: './load-screen.component.html',
    styleUrls: ['./load-screen.component.scss']
})
/**
 * Load screen that includes toast and block screens.
 */
export class LoadScreenComponent {
}
