import {DocumentData, FirestoreDataConverter, QueryDocumentSnapshot, Timestamp} from 'firebase/firestore';
import {buildTimestampSchema, NirbyDocumentReference, replaceDeep, ReplaceDeep} from '@nirby/store/base';
import {DateTime} from 'luxon';
import {z} from 'zod';
import {FirestoreModelReference} from './document';
import {FirestoreDocumentReference} from './reference';
import {FIRESTORE_TIMESTAMP_SCHEMA} from './timestamp-schema';
import {NirbyTimestamp, Uploaded, ZodSchemaBuild} from '@nirby/store/models';
import {MigratorLike} from '@nirby/store/migrator';

/**
 * Converter to cast DocumentData to an Uploaded interface.
 */
export class StandardConverter<T extends object>
    implements FirestoreDataConverter<Uploaded<T>> {
    private readonly timestampSchema: ZodSchemaBuild<NirbyTimestamp>;

    private constructor(
        private readonly migrator: MigratorLike<T>,
        timestampSchema: ZodSchemaBuild<NirbyTimestamp>,
    ) {
        this.timestampSchema = buildTimestampSchema(timestampSchema);
    }

    /**
     * Convert a DocumentData to an Uploaded interface
     * @param snapshot The DocumentData to convert
     *
     * @returns The converted Uploaded interface
     */
    fromFirestore(snapshot: QueryDocumentSnapshot<Uploaded<T>>): Uploaded<T> {
        const data = snapshot.data();

        const migrated = this.migrator.migrate(data).migrated;
        return {
            ...migrated,
            _creationTime: this.timestampSchema.parse(data._creationTime ?? DateTime.now()),
            _lastUpdate: this.timestampSchema.parse(data._lastUpdate ?? DateTime.now()),
            _databaseVersion: data._databaseVersion,
        };
    }

    /**
     * Convert an Uploaded interface to a DocumentData
     * @param modelObject The Uploaded interface to convert
     *
     * @returns The DocumentData
     */
    toFirestore(modelObject: Uploaded<T>): DocumentData {
        return StandardConverter.cleanDataForFirestore(modelObject);
    }

    /**
     * Creates a new instance of the converter.
     * @param migrator The migrator to use
     *
     * @returns A new instance of the converter
     */
    public static for<T extends object>(migrator: MigratorLike<T>): StandardConverter<T> {
        return new StandardConverter<T>(
            migrator,
            FIRESTORE_TIMESTAMP_SCHEMA.transform(
                (value) => DateTime.fromJSDate(value.toDate()),
            ),
        );
    }

    public static cleanDataForFirestore<T extends object>(
        data: Partial<Uploaded<T>>,
    ): DocumentData {
        const replacedDates = replaceDeep<
            DateTime | Date | Timestamp,
            Date,
            Partial<Uploaded<T>>
        >(
            data,
            z.union([
                z
                    .custom<DateTime>((d) => d instanceof DateTime)
                    .transform((value) => value.toJSDate()),
                z.date().transform((value) => value),
                z.instanceof(Timestamp).transform((value) => value.toDate()),
            ]),
        );
        return replaceDeep<
            NirbyDocumentReference<any>,
            FirestoreModelReference<any>,
            ReplaceDeep<Partial<Uploaded<T>>, DateTime | Date | Timestamp, Date>
        >(
            replacedDates,
            z
                .custom<FirestoreDocumentReference<any>>(
                    (d) => d instanceof FirestoreDocumentReference,
                )
                .transform((value) => value.ref),
        );
    }
}
