export interface PickerTimestamp {
    hour: number;
    minute: number;
    second: number;
}

export function lerp(a: number, b: number, t: number): number {
    return a + t * (b - a);
}

export function clamp(num: number, a: number, b: number): number {
    return Math.min(Math.max(num, a), b);
}

export function msToDateStamp(ms: number): PickerTimestamp {
    const totalSeconds = Math.floor(ms / 1000);

    const hour = Math.floor(totalSeconds / (60 * 60));
    const minute = Math.floor(totalSeconds / 60) % 60;
    const second = totalSeconds % 60;

    return {
        hour,
        minute,
        second,
    };
}

export function dateStampToMs(date: Partial<PickerTimestamp>): number {
    return (
        (((date.hour ?? 0) * 60 + (date.minute ?? 0)) * 60 +
            (date.second ?? 0)) *
        1000
    );
}

export function dateStampSorted(
    a: Partial<PickerTimestamp>,
    b: Partial<PickerTimestamp>,
): [Partial<PickerTimestamp>, Partial<PickerTimestamp>] {
    const aMs = dateStampToMs(a);
    const bMs = dateStampToMs(b);
    if (aMs < bMs) {
        return [a, b];
    }
    return [b, a];
}
