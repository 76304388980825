import {Injectable} from '@angular/core';
import {AuthenticationService} from './auth';
import {first, map, share, switchMap} from 'rxjs/operators';
import {combineLatest, firstValueFrom, from, Observable} from 'rxjs';
import {AppError} from '@nirby/js-utils/errors';
import {TypeStrictSafe} from '@nirby/shared/typestrict';
import {FileUploadService, UploadTask, UserFile, UserFileMetadata} from './images';
import {Logger} from '@nirby/logger';
import {User} from '@angular/fire/auth';
import {
    deleteObject,
    getDownloadURL,
    getMetadata,
    listAll,
    ref,
    Storage,
    StorageReference,
    uploadBytesResumable,
    UploadTaskSnapshot,
} from '@angular/fire/storage';

@Injectable({
    providedIn: 'root',
})
export class FireStorageService extends FileUploadService {
    /**
     * Constructor.
     * @param auth Authentication service.
     * @param storage Angular Fire Storage.
     */
    constructor(
        private auth: AuthenticationService,
        private storage: Storage,
    ) {
        super();
    }

    /**
     * Get a user file from a reference.
     * @param uploaderUserId User ID of the uploader.
     * @param ref Reference to the file.
     *
     * @returns User file.
     */
    static async userFileFromReference(
        uploaderUserId: string,
        ref: StorageReference,
    ): Promise<UserFile> {
        const url = TypeStrictSafe.toString(await getDownloadURL(ref));
        const metadata = TypeStrictSafe.toObject<UserFileMetadata>(
            await getMetadata(ref),
        );
        return {
            ref,
            name: ref.name,
            uploaderUserId,
            url,
            metadata: {
                size: metadata.size ?? 0,
            },
        };
    }

    upload(file: File, parent: string): UploadTask<null> {
        const userId = this.auth
            .getAuthenticatedUserReference()
            .then((ref) => ref.id);

        const randomId = Math.random().toString(36).substring(2);
        const destiny = `${parent}/${randomId}`;
        const ngRef = ref(this.storage, destiny);

        const task = uploadBytesResumable(ngRef, file, {
            customMetadata: {
                size: file.size.toString(10),
            },
        });
        const snap$ = new Observable<UploadTaskSnapshot>(
            (observer) => {
                task.on(
                    'state_changed',
                    s => observer.next(s),
                    err => observer.error(err),
                    () => observer.complete(),
                );
            },
        ).pipe(
            share(),
        );
        const percentage = snap$.pipe(
            map((snap) => {
                if (!snap) {
                    return 0;
                }
                return snap.bytesTransferred / snap.totalBytes;
            }),
        );
        const userFile: Promise<UserFile> = firstValueFrom(
            snap$.pipe(
                switchMap((snap): Promise<StorageReference> => {
                    return new Promise((res, rej) => {
                        snap.task
                            .then(v => res(v.ref))
                            .catch(e => rej(e));
                    });
                }),
                switchMap(async (ref) => {
                    return await FireStorageService.userFileFromReference(
                        await userId,
                        ref,
                    );
                }),
                first(),
            ),
        );
        return {
            file: userFile,
            progress$: percentage,
            metadata: new Promise((res) => res(null)),
        };
    }

    private async getUser(): Promise<User> {
        const user = await this.auth.getCurrentUser();
        if (!user) {
            throw new AppError(
                'Trying to list/upload a file without being authenticated',
            );
        }
        return user;
    }

    async uploadPublic(file: File): Promise<UploadTask<null>> {
        const user = await this.getUser();
        return this.upload(file, `/images/${user.uid}/public`);
    }

    async uploadPrivate(file: File): Promise<UploadTask<null>> {
        const user = await this.getUser();
        return this.upload(file, `/images/${user.uid}/private`);
    }

    private queryFiles(path: string): Observable<StorageReference[]> {
        const dirRef = ref(this.storage, path);
        return from(listAll(dirRef)).pipe(map((ref) => ref.items));
    }

    public queryPrivateFiles(): Observable<StorageReference[]> {
        return this.auth.authenticatedUserSafe$.pipe(
            switchMap((user) => this.queryFiles(`/images/${user.id}/private`)),
        );
    }

    public queryPublicFiles(): Observable<StorageReference[]> {
        return this.auth.authenticatedUserSafe$.pipe(
            switchMap((user) => this.queryFiles(`/images/${user.id}/public`)),
        );
    }

    public queryAllFiles(): Observable<StorageReference[]> {
        return combineLatest([
            this.queryPrivateFiles(),
            this.queryPublicFiles(),
        ]).pipe(
            map(([privates, publics]) => {
                return [...privates, ...publics];
            }),
        );
    }

    public async delete(path: string): Promise<void> {
        Logger.logAt('STORAGE', `Deleting file at ${path}`);
        return await deleteObject(ref(this.storage, path));
    }
}

export {UploadTask} from './images';
