<div
    class="button-select-container"
>
    <label
        class="button-select"
        *ngFor="let option of options"
        [ngClass]="{'button-select-active': value === option.value}"
    >
        <input type="radio" [value]="option.value" [ngModel]="value === option.value" (ngModelChange)="onStyleChange($event)">
        <fa-icon
            *ngIf="option.icon"
            class="icon me-1"
            [icon]="option.icon"
        ></fa-icon>
        <span class="text" *ngIf="option.label">{{ option.label }}</span>
    </label>
</div>
