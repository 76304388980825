import {z} from 'zod';

export const TRIGGER_DOM_EVENTS_SCHEMA = z.union([
    z.literal('click'),
    z.literal('dblclick'),
    z.literal('swipeleft'),
    z.literal('swiperight'),
    z.literal('swipeup'),
    z.literal('swipedown'),
]);
export type TriggerDomEvents = z.infer<typeof TRIGGER_DOM_EVENTS_SCHEMA>;
