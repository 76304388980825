import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {FormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {AuthenticationService, RouteService, UserService, WorkspaceService} from '@nirby/shared/database';
import {RouteData, Workspace} from '@nirby/models/editor';
import {STANDARD_REGEX} from '@nirby/shared/widgets';
import {AppError} from '@nirby/js-utils/errors';
import {AlertsService} from '@nirby/shared/alerts';
import {CREATORS_ENVIRONMENT, NirbyEnvironment} from '@nirby/environment/base';
import {NirbyDocumentReference} from '@nirby/store/base';

@Component({
    selector: 'nirby-new-widget-form',
    templateUrl: './new-widget-form.component.html',
    styleUrls: ['./new-widget-form.component.scss'],
})
/**
 * Form to create a new widget
 */
export class NewWidgetFormComponent {
    /**
     * Creates component
     * @param environment The environment
     * @param workspaces Workspace service
     * @param routes Route service
     * @param auth Authentication service
     * @param users User service
     * @param alerts Alert service
     */
    constructor(
        @Inject(CREATORS_ENVIRONMENT)
        private readonly environment: NirbyEnvironment,
        private workspaces: WorkspaceService,
        private routes: RouteService,
        private auth: AuthenticationService,
        private users: UserService,
        private readonly alerts: AlertsService,
    ) {
    }

    urlName = new UntypedFormControl(this.environment.production ? '' : 'app', Validators.required);
    url = new UntypedFormControl(this.environment.production ? '' : 'nir.by', [
        Validators.required,
        Validators.pattern(STANDARD_REGEX.domainName),
    ]);

    @Input() index = 0;
    @Output() widgetCreated = new EventEmitter<
        NirbyDocumentReference<Workspace>
    >();

    arrowIcon = faArrowRight;
    a = false;

    loading = false;

    widgetForm = new FormGroup({
        urlName: this.urlName,
        url: this.url,
    });

    /**
     * Creates a new widget
     */
    async createWidget(): Promise<void> {
        const urlName = this.widgetForm.get('urlName')?.value;
        const url = this.widgetForm.get('url')?.value;
        const route = '/';

        let workspaceRef: NirbyDocumentReference<Workspace> | undefined =
            undefined;
        let baseRouteRef: NirbyDocumentReference<RouteData>;

        if (!urlName || !url) {
            return;
        }

        try {
            this.loading = true;
            const user = await this.auth.getAuthenticatedUser();
            if (!user || !user.id) {
                // noinspection ExceptionCaughtLocallyJS
                throw new AppError('User is not authenticated');
            } // Create a new widget.
            const newWidget: Workspace = {
                installedPlugins: {},
                urlName,
                url,
                priorityList: [],
                ownerPlan: user.data.plan,
                plan: null,
                _deleted: false,
            };
            await this.workspaces.collection.runTransaction(
                async (transaction) => {
                    // create widget
                    workspaceRef =
                        await this.workspaces.collection.setTransaction(
                            transaction,
                            newWidget,
                        );

                    // create base route
                    baseRouteRef = await this.routes
                        .collection(workspaceRef.id)
                        .setTransaction(transaction, {route, campaigns: []});

                    // update route
                    await this.workspaces.collection.updateTransaction(
                        transaction,
                        workspaceRef.id,
                        {
                            urlName,
                            url,
                            priorityList: [
                                {
                                    routePath: this.routes
                                        .collection(workspaceRef.id)
                                        .ref(baseRouteRef.id),
                                    route,
                                },
                            ],
                        },
                    );

                    // add widget to user
                    user.data.widgets?.push({
                        ref: this.workspaces.getReference(workspaceRef.id),
                        name: urlName,
                    });

                    await this.users.collection.updateTransaction(
                        transaction,
                        user.id,
                        {
                            widgets: user.data.widgets,
                        },
                    );
                },
            );
        } catch (e) {
            this.loading = false;
            // show error message
            await this.alerts.error('Something happened');
            return;
        }
        // show successful message.
        if (workspaceRef) {
            this.widgetCreated.emit(workspaceRef);
        }
    }
}
