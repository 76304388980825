import {NirbyDocumentReference} from '@nirby/store/base';
import {z} from 'zod';
import {buildFirestoreUploadedReferenceSchema} from '@nirby/store/firebase-web';
import {Migrator} from '@nirby/store/migrator';
import {ZodSchemaBuild} from '@nirby/store/models';

/**
 * Builds an uploaded reference schema that can be used to validate a reference that was uploaded to Firestore or
 * AceBase
 * @param migratorFn A function that returns a migrator instance
 *
 * @returns - A zod schema that validates a DocumentReference
 */
export function buildUploadedReferenceSchema<T extends object>(
    migratorFn: () => Migrator<T>,
): ZodSchemaBuild<NirbyDocumentReference<T>> {
    return z.lazy(() => {
        return buildFirestoreUploadedReferenceSchema<T>(migratorFn);
    });
}
