/* eslint-disable @typescript-eslint/no-explicit-any */
import {DesignSet} from './index';
import Konva from 'konva';
import {
    BlockContext,
    Bounds,
    ContextUtils,
    generateDesignTree,
    ShapeUnit,
    ShapeUnitRawDescription,
    Size,
} from './shapes';
import {AppError} from '@nirby/js-utils/errors';
import {EMPTY, merge, Observable} from 'rxjs';
import {BlockEvent} from './unit.model';
import {NirbyContext, NirbyMemoryMask} from '@nirby/runtimes/context';
import {AnyBlock} from '@nirby/models/nirby-player';

export type DesignTree = Record<string, ShapeUnit<any>>;

/**
 * The designer is responsible for creating a design tree from a design set, to be drawn on a canvas.
 */
export class Designer<Designs extends DesignSet = DesignSet> {
    /**
     * The group of all the shapes in this design.
     * @private
     */
    private readonly group: Konva.Group;
    /**
     * The background that covers the design
     * @private
     */
    private readonly background: Konva.Rect;
    /**
     * The shape at the top of the design.
     * @private
     */
    private readonly container: Konva.Shape;
    /**
     * All the designs in this designer.
     * @private
     */
    private readonly designTree: DesignTree;

    /**
     * The current design.
     * @private
     */
    private get currentDesign(): ShapeUnitRawDescription {
        return (
            (this.designSet[
                this.currentDesignId as string
                ] as ShapeUnitRawDescription) ?? {
                shape: 'Container',
                children: [],
                attributes: {},
                axis: {
                    horizontal: 'stretch',
                    vertical: 'stretch',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                },
            }
        );
    }

    /**
     * The current design ID.
     * @private
     */
    private get currentDesignId(): keyof Designs {
        return this.designIdPicker();
    }

    public events$: Observable<BlockEvent> = EMPTY;

    /**
     * The shape at the top of the design.
     */
    public get shape(): Konva.Node {
        return this.background;
    }

    /**
     * The root group of the design.
     */
    public get root(): Konva.Group {
        return this.group;
    }

    protected block: AnyBlock;
    protected readonly state: NirbyMemoryMask;
    protected readonly context: NirbyContext;

    /**
     * Constructor.
     * @param context The context of the block this designer is for.
     * @param designSet The design set to use
     * @param editable Whether the designer is editable
     * @param designIdPicker A function that returns the current design ID
     * @param initialSize The initial size of the designer
     * @param baseSize The base size of the designer
     */
    constructor(
        context: BlockContext,
        private readonly designSet: Designs,
        private readonly editable: boolean,
        private readonly designIdPicker: () => keyof Designs,
        initialSize: Size,
        private readonly baseSize: Size | null = null,
    ) {
        this.block = context.block;
        this.state = context.state;
        this.context = context.context;
        this.group = new Konva.Group({});
        this.background = new Konva.Rect({
            fill: 'rgba(0, 0, 0, 0)',
        });
        this.container = new Konva.Shape({
            name: 'root',
            hitFunc: (ctx, shape) => {
                ctx.beginPath();
                ctx.rect(0, 0, shape.width(), shape.height());
                ctx.closePath();
                ctx.fillStrokeShape(shape);
            },
            sceneFunc: (ctx: Konva.Context, shape: Konva.Shape) => {
                const design = this.currentDesign;
                const context: BlockContext = {
                    state: this.state,
                    block: this.block,
                    context: this.context,
                };
                const childBounds = ShapeUnit.getChildBounds(design, {
                    x: 0,
                    y: 0,
                    width: shape.width(),
                    height: shape.height(),
                }, new ContextUtils(context, this.state.state));
                this.shapeUnit.drawTree(
                    ctx._context,
                    design,
                    context,
                    childBounds,
                );
            },
        });
        this.group.add(this.background, this.container);
        this.group.setAttrs({
            x: 0,
            y: 0,
            width: initialSize.width,
            height: initialSize.height,
        });
        this.designTree = this.prebuildDesigns();
        this.adjustContainer();
    }

    /**
     * The current design.
     */
    public get design(): ShapeUnitRawDescription {
        return this.currentDesign;
    }

    /**
     * Gets the current design top Shape Unit
     * @private
     */
    private get shapeUnit(): ShapeUnit<any> {
        const unit = this.designTree[this.currentDesignId as string];
        if (!unit) {
            throw new AppError(
                `No shape unit in design with id ${this.currentDesignId as string}`,
            );
        }
        return unit;
    }

    /**
     * Build a DesignTree for every design in the design set
     * @private
     *
     * @returns {DesignTree} The built design tree
     */
    private prebuildDesigns(): DesignTree {
        const designTree: DesignTree = {};
        const events$: Observable<BlockEvent>[] = [];
        Object.keys(this.designSet).forEach((designId) => {
            const design = this.designSet[designId];
            if (!design) {
                return;
            }
            designTree[designId] = generateDesignTree(
                0,
                {
                    state: this.state,
                    block: this.block,
                    context: this.context,
                },
                design,
                this.group,
                this.container,
                events$,
                this.editable,
            );
        });
        if (this.designTree) {
            this.dispose();
        }
        this.events$ = merge(...events$);
        return designTree;
    }

    /**
     * (Re-)builds the current design using a (new) block data
     * @param block The new block data
     */
    async build(block: AnyBlock): Promise<void> {
        const bounds: Bounds = {
            x: 0,
            y: 0,
            width: this.root.width(),
            height: this.root.height(),
        };
        this.block = block;
        await this.shapeUnit.update(
            this.design,
            {
                state: this.state,
                block,
                context: this.context,
            },
            bounds,
        );
        this.adjustContainer();
    }

    /**
     * Adjusts the designer to the container size
     * @param adjustUnit Whether to adjust the unit
     *
     * @private
     */
    private adjustContainer(): void {
        let bounds: Bounds;
        let scale: number;
        const width = this.root.width();
        const height = this.root.height();

        if (this.baseSize) {
            scale = Math.min(
                width / this.baseSize.width,
                height / this.baseSize.height,
            );
            bounds = {
                x: (width - this.baseSize.width * scale) / 2,
                y: (height - this.baseSize.height * scale) / 2,
                width: this.baseSize.width,
                height: this.baseSize.height,
            };
        } else {
            scale = 1;
            bounds = {
                x: 0,
                y: 0,
                width: width,
                height: height,
            };
        }
        this.container.setAttrs({
            ...bounds,
            scaleX: scale,
            scaleY: scale,
        });
        this.background.setAttrs({
            x: 0,
            y: 0,
            width: this.root.width(),
            height: this.root.height(),
        });

        this.shapeUnit.adjustToParent(bounds);
    }

    /**
     * Adjusts the designer to the container size
     * @param resize The new size and absolute position of the designer
     */
    adjustToParentAfterSizeChange(resize: {
        width: number;
        height: number;
        x: number;
        y: number;
    }): void {
        this.root.setAttrs({
            scaleX: 1,
            scaleY: 1,
            width: resize.width,
            height: resize.height,
            absolutePosition: {
                x: resize.x,
                y: resize.y,
            },
        });
        this.adjustContainer();
    }

    /**
     * Disposes the designer.
     */
    public dispose(): void {
        this.shapeUnit.dispose();
    }
}
