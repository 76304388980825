import {NirbyFieldValue} from './utils';
import {Migrator, MigratorLike} from '@nirby/store/migrator';
import {z} from 'zod';

/**
 * A reference to a document.
 */
export abstract class NirbyDocumentReference<T extends object> {
    /**
     * Constructor.
     * @param migrator The migrator.
     * @protected
     */
    protected constructor(public readonly migrator: MigratorLike<T>) {
    }

    abstract get id(): string;

    abstract get path(): string;

    abstract toFieldValue(): NirbyFieldValue;

    /**
     * Gets the parent path of the document.
     * e.g. if the path is `users/1234`, the parent path is `users`.
     * If the path is `users`, the parent path is `null`.
     *
     * @returns The parent path.
     */
    get parentPath(): string | null {
        const parts = this.path.split('/');
        if (parts.length < 2) {
            return null;
        }
        return parts.slice(0, parts.length - 1).join('/');
    }

    getParentDoc<U extends object>(
        migrator: MigratorLike<U>,
        safe?: true
    ): NirbyDocumentReference<U> | null;

    getParentDoc<U extends object>(
        migrator: MigratorLike<U>,
        safe: false
    ): NirbyDocumentReference<U>;
    getParentDoc<U extends object>(
        migrator: MigratorLike<U>,
        safe = true,
    ): NirbyDocumentReference<U> | null {
        const result = this.parent.getParent<U>(migrator);
        if (!result && !safe) {
            throw new Error(`Could not get parent of ${this.path}`);
        }
        return result;
    }

    /**
     * Gets the parent document ID.
     *
     * @returns The parent document ID. Or null if there is no parent.
     */
    getParentDocId(): string | null {
        const parent = this.getParentDoc({
            currentVersion: 0,
            migrate: () => ({} as never),
            expectedSchema: z.never(),
        });
        if (parent instanceof NirbyDocumentReference) {
            return parent.id;
        }
        return null;
    }

    abstract get parent(): NirbyCollectionReference<T>;

    /**
     * Gets the top parent of the document.
     *
     * @returns The top parent.
     */
    getTopParent(): NirbyDocumentReference<object> {
        let topParent: NirbyDocumentReference<object> =
            this as NirbyDocumentReference<object>;
        while (topParent) {
            const parent = topParent.getParentDoc(Migrator.any());
            if (!parent) {
                return topParent;
            }
            topParent = parent;
        }
        return this;
    }

    /**
     * Whether this reference is local.
     */
    get isLocal(): boolean {
        return this.parent.isLocal;
    }
}

/**
 * A reference to a collection.
 */
export abstract class NirbyCollectionReference<T extends object> {
    /**
     * Constructor.
     * @param migrator The migrator.
     * @param isLocal Whether the collection is local.
     * @protected
     */
    protected constructor(
        public readonly migrator: MigratorLike<T>,
        public readonly isLocal: boolean,
    ) {
    }

    abstract get id(): string;

    abstract get path(): string;

    abstract doc(id?: string): NirbyDocumentReference<T>;

    abstract getParent<U extends object>(
        migrator: MigratorLike<U>
    ): NirbyDocumentReference<U> | null;
}
