import { fromEvent, NEVER, Observable, share, switchMap } from 'rxjs';
import { ArtBoardItem } from './art-board-item';
import { map } from 'rxjs/operators';
import Konva from 'konva';
import KonvaEventObject = Konva.KonvaEventObject;

/**
 *
 */
export class ShapeCursorListener<TMeta> {
    public readonly enter$ = this.watchEvent('mouseenter touchstart');
    public readonly leave$ = this.watchEvent('mouseleave touchend');
    public readonly click$ = this.watchEvent('click tap');
    public readonly down$ = this.watchEvent('mousedown touchstart');
    public readonly up$ = this.watchEvent('mouseup touchend');

    private watchEvent(
        eventName: string
    ): Observable<KonvaEventObject<MouseEvent>> {
        return this.item.shape$.pipe(
            switchMap((shape) => (shape ? fromEvent(shape, eventName) : NEVER)),
            map((evt) => evt as unknown as KonvaEventObject<MouseEvent>),
            share()
        );
    }

    constructor(private readonly item: ArtBoardItem<TMeta>) {}
}
