<ng-template #templateRef>
    <div class="flex flex-column p-2">
        <div
            class="flex flex-row justify-content-between align-items-center"
            *ngIf="!!header || !!backTo"
        >
            <button
                class="btn btn-brand-primary me-2"
                *ngIf="backTo"
                (click)="goTo(backTo)"
            >
                <fa-icon [icon]="icons.back"></fa-icon>
            </button>
            <span class="flex-1" *ngIf="header">{{header}}</span>
        </div>
        <p-divider></p-divider>
        <ng-content></ng-content>
    </div>
</ng-template>
