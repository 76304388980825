<div class="container py-4">
    <div class="row justify-content-center">
        <div class="col-lg-5">
            <div class="logo text-center mb-4">
                <a href="/">
                    <img
                        src="assets/img/auxi/nby-logo-header.svg"
                        alt="Nirby"
                        title="Nirby"
                        height="96"
                    />
                </a>
            </div>

            <div class="auth-content bg-light p-4">
                <form
                    id="npop-auth-signup"
                    [formGroup]="newUserForm"
                    (ngSubmit)="signUp()"
                >
                    <h1 class="text-dark mb-5">Create an account</h1>
                    <div class="mb-3">
                        <label class="text-dark col-form-label" for="mail"
                            >E-mail</label
                        ><br />
                        <input
                            formControlName="email"
                            type="email"
                            id="mail"
                            name="mail"
                            placeholder="example@mail.com"
                            class="form-control"
                        />
                        <div
                            *ngIf="emailControl.touched && emailControl.invalid"
                            class="alert alert-danger alert-text"
                        >
                            E-mail is required
                        </div>
                    </div>
                    <div class="mb-3">
                        <label class="text-dark col-form-label" for="password"
                            >Password</label
                        ><br />
                        <input
                            formControlName="password"
                            type="password"
                            name="password"
                            placeholder="Enter your password"
                            class="form-control"
                            id="password"
                        />
                        <div
                            *ngIf="passwordControl.touched && passwordControl.invalid"
                            class="alert alert-danger alert-text"
                        >
                            Password required
                        </div>
                    </div>
                    <div class="mb-3">
                        <label
                            class="col-form-label"
                            for="confirmPassword"
                            >Confirm password</label
                        ><br />
                        <input
                            id="confirmPassword"
                            [formControl]="passwordConfirmControl"
                            type="password"
                            name="confirmPassword"
                            placeholder="Enter your password"
                            class="form-control"
                        />
                        <div
                            *ngIf="
                                passwordConfirmControl.touched &&
                                passwordConfirmControl.invalid
                            "
                            class="alert alert-danger alert-text"
                        >
                            Confirm password required
                        </div>
                    </div>
                    <div class="actions">
                        <button
                            type="submit"
                            class="btn btn-primary w-100"
                            [disabled]="!newUserForm.valid || loading"
                        >
                            Sign Up
                        </button>
                    </div>

                    <div class="actions-secondary mt-5">
                        <a
                            [routerLink]="['/auth/login']"
                            class="btn btn-link btn-block btn-sm"
                            >Already have an account?</a
                        >
                    </div>
                </form>
            </div>
            <div class="auth-extra text-center mt-3">
                <small class="text-muted mb-0"
                    >By using our software, you agree to our
                    <a
                        href="https://fixecl.notion.site/T-rminos-condiciones-15e611289b54448ea7c1c21eed75703a"
                        target="_blank"
                        >Terms</a
                    >
                    &amp;
                    <a
                        href="https://fixecl.notion.site/Pol-tica-de-privacidad-f43043669c2b423a99e033c2e0925d53"
                        target="_blank"
                        >Privacy Policy</a>. By signing up, you also agree to receive our Newsletter
                    and other Announcements.</small>
            </div>
        </div>
    </div>
</div>
