import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { MaintenanceService } from '../services/maintenance.service';

@Injectable({
    providedIn: 'root',
})
export class NotMaintenanceGuard implements CanActivate {
    constructor(
        private router: Router,
        private maintenance: MaintenanceService
    ) {}

    async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        const inMaintenance = await this.maintenance.fetchIsInMaintenance();
        if (!inMaintenance) {
            return true;
        }
        await this.router.navigate(['/maintenance']);
        return false;
    }
}
