import {MimeName} from './mime-types';

export interface MimePattern {
    mime: MimeName;
    pattern: (number | null)[];
    offset: number;
    extension: string;
}

/**
 * @see https://en.wikipedia.org/wiki/List_of_file_signatures
 */
export const KNOWN_MIMES: MimePattern[] = [
    // IMAGES
    {
        mime: 'image/jpeg',
        extension: 'jpg',
        pattern: [0xFF, 0xD8, 0xFF],
        offset: 0,
    },
    {
        mime: 'image/png',
        extension: 'png',
        pattern: [0x89, 0x50, 0x4E, 0x47],
        offset: 0,
    },
    {
        mime: 'image/gif',
        extension: 'gif',
        pattern: [0x47, 0x49, 0x46, 0x38, 0x37, 0x61],
        offset: 0,
    },
    {
        mime: 'image/gif',
        extension: 'gif',
        pattern: [0x47, 0x49, 0x46, 0x38, 0x39, 0x61],
        offset: 0,
    },
    {
        mime: 'image/bmp',
        extension: 'bmp',
        pattern: [0x42, 0x4D],
        offset: 0,
    },
    // VIDEO
    {
        mime: 'video/mp4',
        extension: 'mp4',
        pattern: [0x66, 0x74, 0x79, 0x70, 0x69, 0x73, 0x6F, 0x6D],
        offset: 4,
    },
    {
        mime: 'video/mpeg',
        extension: 'mpeg',
        pattern: [0x00, 0x00, 0x01, 0xBA],
        offset: 0,
    },
    {
        mime: 'video/x-msvideo',
        extension: 'avi',
        pattern: [0x52, 0x49, 0x46, 0x46, null, null, null, null, 0x41, 0x56, 0x49, 0x20],
        offset: 0,
    },
    {
        mime: 'video/webm',
        extension: 'webm',
        pattern: [0x1A, 0x45, 0xDF, 0xA3],
        offset: 0,
    },
    // AUDIO
    {
        mime: 'audio/wav',
        extension: 'wav',
        pattern: [0x52, 0x49, 0x46, 0x46, null, null, null, null, 0x57, 0x41, 0x56, 0x45],
        offset: 0,
    },
    {
        mime: 'audio/mpeg',
        extension: 'mp3',
        pattern: [0xFF, 0xFB],
        offset: 0,
    },
    {
        mime: 'audio/mpeg',
        extension: 'mp3',
        pattern: [0xFF, 0xF3],
        offset: 0,
    },
    {
        mime: 'audio/mpeg',
        extension: 'mp3',
        pattern: [0xFF, 0xF2],
        offset: 0,
    },
    {
        mime: 'audio/mpeg',
        extension: 'mp3',
        pattern: [0x49, 0x44, 0x33],
        offset: 0,
    },
];
