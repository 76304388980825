import { Component } from '@angular/core';

@Component({
    selector: 'nirby-media-library',
    templateUrl: './media-library.component.html',
    styleUrls: ['./media-library.component.scss'],
})
/**
 * View that display videos and images
 */
export class MediaLibraryComponent {
    mode = 'images';
}
