<ng-container *ngIf="!hideMessages">
    <div class="p-3" *ngIf="nextMaintenance$ | async as nextMaintenance">
        <ngb-alert class="mb-0" type="warning" (closed)="onClose()">
            <strong>Scheduled maintenance:</strong> Nirby Pop will be
            temporarily unavailable due to a scheduled maintenance. This
            downtime will last 1 hour, from {{ nextMaintenance.start }} –
            {{ nextMaintenance.end }}.
        </ngb-alert>
    </div>
</ng-container>
<p-confirmDialog></p-confirmDialog>
<router-outlet></router-outlet>
<nirby-toast-container aria-atomic="true"></nirby-toast-container>
<nirby-load-screen></nirby-load-screen>
<nirby-file-drop-overlay></nirby-file-drop-overlay>
