import { ArtBoardItem, ArtBoardItemChange } from '../../';
import { Observable, Subject } from 'rxjs';
import { Logger } from '@nirby/logger';

/**
 * Controller for the events of the canvas.
 */
export class BlockEventController<TMeta, T extends ArtBoardItem<TMeta> = ArtBoardItem<TMeta>> {
    private readonly updateSignalSubject = new Subject<ArtBoardItemChange>();

    /**
     * Emits when an item is updated in the canvas. e.g. position, rotation, scale, z-index
     */
    public get updateSignal$(): Observable<ArtBoardItemChange> {
        return this.updateSignalSubject.asObservable();
    }

    /**
     * Constructor.
     * @param item The item to control.
     */
    constructor(public readonly item: T) {}

    /* Handlers */

    /**
     * Notifies that this item has been updated.
     * @param relativeZIndex The relative z-index of the item to which update.
     */
    public notifyUpdate(relativeZIndex: ArtBoardItemChange['relativeZIndex'] = 0): void {
        const shape = this.item.shape;
        if (!shape) {
            Logger.warn(
                'BlockEventController',
                'notifyUpdate',
                'Shape is null',
            );
            return;
        }
        const { width, height } = shape.size();
        const position = shape.position();
        const change: ArtBoardItemChange = {
            position: [
                position,
                { x: position.x + width, y: position.y + height },
            ],
            id: shape.id(),
            rotation: shape.rotation(),
            scale: shape.scale(),
            relativeZIndex,
        };
        this.updateSignalSubject.next(change);
    }
}
