<div
    class="card border-0 h-100 d-flex flex-column justify-content-center no-campaign-selected"
>
    <div class="card-body d-flex justify-content-center align-items-center">
        <div class="container">
            <div
                class="area-icon d-flex justify-content-center align-items-center mb-3"
            >
                <div
                    class="circle d-flex justify-content-center align-items-center"
                >
                    <fa-icon
                        class="icon fa-2x"
                        [icon]="iconArrowLeft"
                    ></fa-icon>
                </div>
            </div>
            <h1 class="title text-center h3 mb-0">
                Select a Route, or create a new one.
            </h1>

            <div class="actions mt-3 text-center">
                <a
                    class="btn btn-secondary btn-sm"
                    href="https://fixecl.notion.site/Configurando-tus-Routes-20df056e196f419abc5e65d10b75aa4e"
                    target="_blank"
                    >What's this Route thing you speak of? 👀</a
                >
            </div>
        </div>
    </div>
</div>
