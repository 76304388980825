import { AppError } from '@nirby/js-utils/errors';
import { Subject } from 'rxjs';

export abstract class Hostable<TD> {
    private dataChangeSubject = new Subject<TD>();
    public readonly dataChange = this.dataChangeSubject.asObservable();

    private lastData: TD | null = null;

    protected get lastSetData(): TD | null {
        return this.lastData;
    }

    public sendData(data: Partial<TD>): void {
        if (!this.lastData) {
            throw new AppError(
                'Some data should be set before sending new data'
            );
        }
        const sentData = { ...this.lastData, ...data };
        this.dataChangeSubject.next(sentData);
        this.lastData = sentData;
    }

    public fill_(data: TD | null): void {
        this.lastData = data;
        this.setData(data);
    }

    public abstract setData(content: TD | null): void;
}
