<div id="selector" class="ms-1">
    <div class="d-flex flex-column justify-content-center">
        <nirby-block-selector-item
            *ngFor="let item of baseItems"
            [item]="item"
        ></nirby-block-selector-item>
        <nirby-block-selector-item
            *ngIf="includeCard"
            [item]="cardItem"
        >
            <nirby-block-selector-card-menu></nirby-block-selector-card-menu>
        </nirby-block-selector-item>
    </div>
</div>
