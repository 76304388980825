import {BaseBlock, buildBlockSchema} from './block.model';
import {VIDEO_RECORD_SCHEMA, VideoRecord} from '@nirby/media-models';
import {z} from 'zod';
import {Migrator} from '@nirby/store/migrator';
import {ZodSchemaBuild} from '@nirby/store/models';

export type VideoBlockContent = {
    video: VideoRecord | null;
};

export const VIDEO_BLOCK_CONTENT_SCHEMA: ZodSchemaBuild<VideoBlockContent> =
    z.object({
        video: VIDEO_RECORD_SCHEMA.nullable(),
    });

export type VideoBlock = BaseBlock<'Video', VideoBlockContent>;

export const VIDEO_BLOCK_SCHEMA = buildBlockSchema<VideoBlock>(
    'Video',
    VIDEO_BLOCK_CONTENT_SCHEMA,
    z.object({}),
);

export const VIDEO_BLOCK_MIGRATOR = new Migrator<VideoBlock>(
    VIDEO_BLOCK_SCHEMA,
    () => ({
        hash: '',
        position: [
            {x: 0, y: 0},
            {x: 128, y: 128},
        ],
        rotation: 0,
        content: {
            video: null,
        },
        style: {},
        actions: {
            click: [],
        },
        type: 'Video',
    }),
);
