<form [formGroup]="form" *ngIf="goToOptions$ | async as options; else loading">
    <div class="form-group">
        <nirby-dropdown-filter
            label="Back method"
            hint="How the destiny source will be selected"
            [items]="methods"
            [formControl]="methodControl"
        ></nirby-dropdown-filter>
    </div>
    <div class="form-group">
        <nirby-dropdown-filter
            label="Back time"
            hint="At what time this action will go to on the destiny source"
            [items]="timeOptions"
            [formControl]="timeControl"
        ></nirby-dropdown-filter>
    </div>
    <div class="form-group">
        <nirby-dropdown-filter
            label="Fallback Source"
            hint="What source should be used if no previous source is found"
            [formControl]="videosControl"
            [items]="options"
            [filter]="true"
            styleClass="w-100"
        >
        </nirby-dropdown-filter>
        <nirby-video-timestamp-picker [formControl]="fallbackTimeControl">
        </nirby-video-timestamp-picker>
    </div>
    <nirby-dropdown-filter
        *ngIf="false"
        label="Variables Handling"
        hint="What should happen with the variables when going back"
        [items]="variableHandlingOptions"
        [formControl]="variablesHandling"
    ></nirby-dropdown-filter>
</form>
<ng-template #loading>
    <div class="spinner spinner-border"></div>
</ng-template>
