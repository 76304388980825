<div class="d-flex flex-row">
    <button class="menu-item btn d-flex flex-column align-items-center" (click)="createFromScratch()">
        <fa-icon [icon]="icons.fromScratch"></fa-icon>
        <span>New</span>
    </button>
    <button class="menu-item btn d-flex flex-column align-items-center" (click)="openModal()">
        <fa-icon [icon]="icons.fromLibrary"></fa-icon>
        <span>Library</span>
    </button>
</div>
<p-dialog [modal]="true" [(visible)]="showModal" appendTo="body" styleClass="w-75">
    <div *ngIf="showModal" class="w-100 d-flex flex-row">
        <div class="w-50">
            <h2>Cards</h2>
            <div class="mb-1">
                <p-dropdown
                    appendTo="body"
                    styleClass="w-100"
                    *ngIf="products$ | async as products"
                    placeholder="Select a product"
                    [options]="products"
                    [group]="true"
                    [formControl]="productControl"
                >
                </p-dropdown>
            </div>
            <div *ngIf="cards$ | async as cards" class="w-100 d-flex flex-column card-list">
                <span class="text-center w-100 mt-5" *ngIf="cards.length === 0">
                    No cards found
                </span>
                <ng-container *ngFor="let card of cards">
                    <li class="d-flex flex-row mb-2 card-item">
                        <div class="flex-fill d-flex flex-row align-items-center">
                            <nirby-touch-to-edit [value]="card.data.title" (valueChange)="updateCardTitle($event, card)"></nirby-touch-to-edit>
                        </div>
                        <button class="btn w-25 ms-1" (click)="preview(card.data.card)">
                            <fa-icon [icon]="icons.preview"></fa-icon>
                            <span class="ms-1">Preview</span>
                        </button>
                        <button class="btn btn-outline-dark w-25 ms-1" (click)="use(card.data.card)">
                            <fa-icon [icon]="icons.add"></fa-icon>
                            <span class="ms-1">Use copy</span>
                        </button>
                    </li>
                </ng-container>
            </div>
        </div>
        <div class="flex-fill">
            <p>Preview</p>
            <div *ngIf="cardPreviewUrl" class="d-flex flex-row justify-content-center">
                <img class="w-50 card-preview" [src]="cardPreviewUrl" alt="Card preview"/>
            </div>
        </div>
    </div>
</p-dialog>
