import { z, ZodSchema } from 'zod';
import { Migrator } from '@nirby/store/migrator';

export type ImageSource = 'unsplash' | 'upload' | 'link';

export interface ImageRecordBase<TS extends ImageSource, TM> {
    id: string;
    lastUrl: string | null;
    source: TS;
    metadata: TM;
}

export type MediaType = 'image' | 'other';

export interface MediaItemBase<TT extends MediaType, TC> {
    path: string;
    type: TT;
    content: TC;
}

export type MediaItemImage = MediaItemBase<'image', ImageRecord>;
const IMAGE_RECORD_UNSPLASH_METADATA_SCHEMA = z.strictObject({});

const IMAGE_RECORD_UPLOAD_METADATA_SCHEMA = z.strictObject({
    uploaderUserId: z.string(),
});
const IMAGE_RECORD_LINK_METADATA_SCHEMA = z.strictObject({});
export type ImageRecordUnsplash = ImageRecordBase<
    'unsplash',
    z.infer<typeof IMAGE_RECORD_UNSPLASH_METADATA_SCHEMA>
>;

const IMAGE_RECORD_UNSPLASH_SCHEMA = z.strictObject({
    id: z.string(),
    lastUrl: z.string().nullable(),
    source: z.literal('unsplash'),
    metadata: IMAGE_RECORD_UNSPLASH_METADATA_SCHEMA,
}) as ZodSchema<ImageRecordUnsplash>;
const IMAGE_RECORD_UPLOAD_SCHEMA = z.strictObject({
    id: z.string(),
    lastUrl: z.string().nullable(),
    source: z.literal('upload'),
    metadata: IMAGE_RECORD_UPLOAD_METADATA_SCHEMA,
}) as ZodSchema<ImageRecordUpload>;

export type ImageRecordUpload = ImageRecordBase<
    'upload',
    z.infer<typeof IMAGE_RECORD_UPLOAD_METADATA_SCHEMA>
>;
const IMAGE_RECORD_LINK_SCHEMA = z.strictObject({
    id: z.string(),
    lastUrl: z.string().nullable(),
    source: z.literal('link'),
    metadata: IMAGE_RECORD_LINK_METADATA_SCHEMA,
}) as ZodSchema<ImageRecordLink>;

export type ImageRecordLink = ImageRecordBase<
    'link',
    z.infer<typeof IMAGE_RECORD_LINK_METADATA_SCHEMA>
>;
export const IMAGE_RECORD_SCHEMA = z.union([
    IMAGE_RECORD_UNSPLASH_SCHEMA,
    IMAGE_RECORD_UPLOAD_SCHEMA,
    IMAGE_RECORD_LINK_SCHEMA,
]);

export const MEDIA_ITEM_IMAGE_SCHEMA = z.object({
    path: z.string(),
    type: z.literal('image'),
    content: IMAGE_RECORD_SCHEMA,
});

export type ImageRecord = z.infer<typeof IMAGE_RECORD_SCHEMA>;

export type MediaItem = MediaItemImage | MediaItemBase<'other', object>;

export const MEDIA_ITEM_SCHEMA = z.union([
    MEDIA_ITEM_IMAGE_SCHEMA,
    z.object({
        path: z.string(),
        type: z.literal('other'),
        content: z.object({}),
    }),
]);

export const MEDIA_MIGRATOR = new Migrator<MediaItem>(
    MEDIA_ITEM_SCHEMA,
    () => ({
        content: {
            source: 'link',
            lastUrl: 'assets/img/bg/nby-pop-button.jpg',
            id: '',
            metadata: {},
        },
        path: '',
        type: 'image',
    })
);
