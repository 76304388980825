<div class="form-group">
    <label class="d-block">Text</label>
    <textarea
        class="form-control font-weight-bolder"
        [ngModel]="text"
        (ngModelChange)="onChange($event)"
    ></textarea>
</div>
<!-- Color -->
<div class="form-group">
    <label class="d-block" for="color">Color</label>
    <nirby-color-picker
        id="color"
        [value]="color"
        (valueChange)="chooseColor($event)"
    ></nirby-color-picker>
</div>

<!-- Font Family -->
<div class="form-group">
    <label class="d-block" for="font">Font Family</label>
    <nirby-font-picker
        id="font"
        [fontFamily]="{ name: fontFamily }"
        (fontFamilyChange)="chooseFontFamily($event)"
    ></nirby-font-picker>
</div>

<!-- Alignment -->
<div class="form-group">
    <label class="d-block" for="alignment">Alignment</label>
    <nirby-button-select
        id="alignment"
        name="alignment"
        [ngModel]="alignment"
        (ngModelChange)="chooseAlignment($event)"
        [options]="alignmentOptions"
    ></nirby-button-select>
</div>

<!-- Alignment -->
<div class="form-group">
    <label class="d-block" for="vertical-alignment">Vertical Alignment</label>
    <nirby-button-select
        id="vertical-alignment"
        name="vertical-alignment"
        [ngModel]="verticalAlignment"
        (ngModelChange)="chooseVerticalAlignment($event)"
        [options]="verticalAlignmentOptions"
    ></nirby-button-select>
</div>

<!-- Text Style -->
<div class="form-group">
    <label class="d-block" for="styling">Text Style</label>
    <nirby-button-select
        id="styling"
        name="fontStyle"
        [ngModel]="fontStyle"
        (ngModelChange)="chooseFontStyle($event)"
        [options]="fontStyleOptions"
    ></nirby-button-select>
</div>

<!-- Font Decoration -->
<div class="form-group">
    <label class="d-block" for="decoration">Font Decoration</label>
    <nirby-button-select
        id="decoration"
        name="fontDecoration"
        [ngModel]="decoration"
        (ngModelChange)="chooseFontDecoration($event)"
        [options]="fontDecorationOptions"
    ></nirby-button-select>
</div>

<div class="form-group mb-3">
    <nirby-slider
        id="line-height"
        label="Line Height"
        [min]="0.1"
        [max]="2"
        [step]="0.1"
        [value]="lineHeight"
        (valueChange)="updateLineHeight($event)"
    ></nirby-slider>
</div>

<div class="form-group mb-3">
    <nirby-slider
        id="font-size"
        label="Font Size"
        [min]="7"
        [max]="300"
        [step]="1"
        [value]="fontSize"
        (valueChange)="updateFontSize($event)"
    ></nirby-slider>
</div>
