import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ImagePosition} from '@nirby/models/pop';

interface PositioningGridDisplay {
    label: string;
    value: ImagePosition;
}

@Component({
    selector: 'nirby-positioning-grid',
    templateUrl: './positioning-grid.component.html',
    styleUrls: ['./positioning-grid.component.scss'],
})
export class PositioningGridComponent {
    @Input() public value: ImagePosition = 'center center';

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() public input = new EventEmitter<ImagePosition>();

    positioning: PositioningGridDisplay[] = [
        {label: 'L-T', value: 'left top'},
        {label: 'C-T', value: 'center top'},
        {label: 'R-T', value: 'right top'},

        {label: 'L-C', value: 'left center'},
        {label: 'C-C', value: 'center center'},
        {label: 'R-C', value: 'right center'},

        {label: 'L-B', value: 'left bottom'},
        {label: 'C-B', value: 'center bottom'},
        {label: 'R-B', value: 'right bottom'},
    ];
}
