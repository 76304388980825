import {Injectable} from '@angular/core';
import {RouteParametersService} from '../route-parameters.service';
import {Database} from '@nirby/ngutils';
import {NirbyCollection, NirbyDocument, NirbyDocumentReference, QueryBuilder} from '@nirby/store/base';
import {AnyAppPrimeTrack, AppPrimeSourceVideo, PREPARERS, Prime} from '@nirby/models/editor';
import {PrimeSourcesService} from './prime-sources.service';
import {COLLECTION_KEYS} from '@nirby/store/collections';
import {firstValueFrom, Observable} from 'rxjs';
import {DocumentLike} from '@nirby/store/models';

@Injectable({
    providedIn: 'root',
})
/**
 * Service for managing prime tracks.
 */
export class PrimeTracksService {
    /**
     * Splits an array into chunks of the given size.
     * @param array The array to split.
     * @param chunkSize The size of each chunk.
     * @private
     *
     * @returns The array split into chunks.
     */
    private static chunkArray<T>(array: T[], chunkSize: number): T[][] {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    }

    /**
     * Gets the tracks collection for a workspace.
     * @param workspaceId The workspace ID.
     *
     * @returns - The tracks collection.
     */
    public collection(workspaceId: string): NirbyCollection<AnyAppPrimeTrack> {
        return this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(workspaceId),
            COLLECTION_KEYS.PRIME_TRACKS,
        );
    }

    /**
     * Constructor.
     * @param routeParams The route parameters service.
     * @param sources The prime sources service.
     * @param db The Firestore service.
     */
    constructor(
        private routeParams: RouteParametersService,
        private sources: PrimeSourcesService,
        private readonly db: Database,
    ) {
    }

    /**
     * Get all the tracks from a Prime
     * @param workspaceId The workspace ID
     * @private
     *
     * @returns - The Prime collection
     */
    private primes(workspaceId: string): NirbyCollection<Prime> {
        return this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(workspaceId),
            COLLECTION_KEYS.PRIMES,
        );
    }

    /**
     * Get all the tracks of the given sources.
     * @param workspaceId The workspace ID.
     * @param sourceReferences The source references.
     *
     * @returns - The tracks.
     */
    getTracksForSource(
        workspaceId: string,
        ...sourceReferences: NirbyDocumentReference<AppPrimeSourceVideo>[]
    ): Promise<DocumentLike<AnyAppPrimeTrack>[]> {
        return Promise.all(
            PrimeTracksService.chunkArray(sourceReferences, 10).map(
                (references) => {
                    const constraints: QueryBuilder<AnyAppPrimeTrack> =
                        this.collection(workspaceId).query();
                    if (references.length === 0) {
                        constraints.where('location.source', '==', null);
                    } else {
                        constraints.where('location.source', 'in', references);
                    }
                    return firstValueFrom(constraints.get());
                },
            ),
        )
            .then((results) => results.flat())
            .then((tracks) =>
                tracks.map((track) =>
                    track.map(() => PREPARERS.prepareTrack(track.data)),
                ),
            );
    }

    /**
     * Get all the tracks from a Prime
     * @param workspaceId The workspace ID
     * @param primeId The Prime ID
     *
     * @returns - An observable that resolves to the tracks
     */
    getTracksForPrime(
        workspaceId: string,
        primeId: string,
    ): Observable<NirbyDocument<AnyAppPrimeTrack>[]> {
        return this.collection(workspaceId)
            .query()
            .where('usedBy', '==', this.primes(workspaceId).ref(primeId))
            .get();
    }

    /**
     * Get all tracks from a given Prime
     * @param workspaceId The workspace ID
     * @param primeId The Prime ID
     *
     * @returns - A promise that resolves to the tracks
     */
    getUsedBy(
        workspaceId: string,
        primeId: string,
    ): Promise<NirbyDocument<AnyAppPrimeTrack>[]> {
        return firstValueFrom(
            this.collection(workspaceId)
                .query()
                .where('usedBy', '==', this.primes(workspaceId).ref(primeId))
                .get(),
        );
    }
}
