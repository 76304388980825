import Konva from 'konva';
import { Bounds } from './designs';
import Vector2d = Konva.Vector2d;
import {
    distinctUntilChanged,
    filter,
    fromEvent,
    mapTo,
    merge,
    Observable,
    pairwise,
    share,
    startWith,
    switchMap,
    take,
    takeWhile,
    tap,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from '@nirby/logger';

export type CursorEventType = 'click' | 'move' | 'down' | 'up';

export interface CursorEvent<Type extends CursorEventType | string> {
    readonly position: Vector2d;
    readonly inShape: boolean;
    readonly inBlock: boolean;
    readonly type: Type;
}

/**
 * A shape listener is a function that is called when a shape event is triggered.
 *
 * @param e The event that triggered the listener.
 */
export class UnitCursorListener {
    static initializedPointer = false;

    /**
     * Observes the global mouse move event.
     */
    static globalMouseMove$ = merge(
        fromEvent<MouseEvent>(document, 'mousemove'),
        fromEvent<MouseEvent>(document, 'touchmove'),
    ).pipe(share());

    /**
     * Observes the global mouse up event.
     */
    static globalMouseUp$ = merge(
        fromEvent<MouseEvent>(document, 'mouseup'),
        fromEvent<MouseEvent>(document, 'touchend'),
    ).pipe(share());

    /**
     * Observes the global mouse up event.
     */
    static globalMouseDown$ = fromEvent<MouseEvent>(
        document,
        'mousedown touchstart',
    ).pipe(share());

    /**
     * Observes if the mouse is being pressed
     */
    static readonly globalIsMouseDown$ = merge(
        UnitCursorListener.globalMouseUp$.pipe(mapTo(false)),
        UnitCursorListener.globalMouseDown$.pipe(mapTo(true)),
    ).pipe(distinctUntilChanged());

    /**
     * Observes the cursor move event.
     */
    readonly move$: Observable<CursorEvent<'move'>> =
        UnitCursorListener.globalMouseMove$.pipe(
            map((e) => {
                if ((e.target as HTMLElement).tagName !== 'CANVAS') {
                    return;
                }
                return this.createEvent('move');
            }),
            filter(
                (event): event is CursorEvent<'move'> =>
                    typeof event !== 'undefined',
            ),
        );

    /**
     * Observes if the cursor is hovering the unit
     */
    readonly hover$ = UnitCursorListener.globalMouseMove$.pipe(
        map(() => this.isHover),
        distinctUntilChanged(),
    );

    /**
     * Observes if the cursor is hovering the shape
     */
    readonly hoverBlock$: Observable<boolean> =
        UnitCursorListener.globalMouseMove$.pipe(
            map(() => this.isHoverBlock),
            distinctUntilChanged(),
        );

    /**
     * Observes the click events on the shape.
     */
    readonly click$ = fromEvent(this.shape, 'click tap').pipe(
        map(() => this.createEvent('click')),
        tap(() => Logger.logAt('UNIT', 'CLICK')),
        share(),
    );

    /**
     * Emits when the mouse is pressed over the shape.
     */
    readonly down$ = fromEvent(this.shape, 'mousedown touchstart').pipe(
        map(() => this.createEvent('down')),
        tap(() => Logger.logAt('UNIT', 'MOUSE DOWN')),
        share(),
    );

    readonly isMouseDown$: Observable<boolean> = this.down$.pipe(
        switchMap(() =>
            UnitCursorListener.globalMouseUp$.pipe(
                mapTo(false),
                startWith(true),
                take(2),
            ),
        ),
        distinctUntilChanged(),
    );

    /**
     * Observes the cursor up event when it has already been pressed over the shape.
     */
    readonly up$ = this.isMouseDown$.pipe(
        filter((s) => !s),
        map(() => this.createEvent('up')),
    );

    static stagePointersAreInitialized(stage: Konva.Stage | null): boolean {
        if (!stage) {
            return false;
        }
        return (
            stage._pointerPositions.length > 0 ||
            stage._changedPointerPositions.length > 0
        );
    }

    /**
     * Gets the current cursor position.
     * @protected
     */
    public get mousePosition(): Vector2d {
        const stage = this.shape.getStage();
        if (!UnitCursorListener.stagePointersAreInitialized(stage)) {
            return {
                x: 0,
                y: 0,
            };
        }
        const relativeToShape = this.shape.getRelativePointerPosition() ?? {
            x: 0,
            y: 0,
        };
        return {
            x: relativeToShape.x - this.bounds.x,
            y: relativeToShape.y - this.bounds.y,
        };
    }

    /**
     * Check if the cursor is inside the bounds of the block.
     */
    public get isHoverBlock(): boolean {
        const relativeToShape = this.shape.getRelativePointerPosition() ?? {
            x: 0,
            y: 0,
        };
        return (
            relativeToShape.x > 0 &&
            relativeToShape.x < this.shape.width() &&
            relativeToShape.y > 0 &&
            relativeToShape.y < this.shape.height()
        );
    }

    /**
     * Check if the cursor is inside the bounds of the shape.
     */
    public get isHover(): boolean {
        const pointer = this.mousePosition;
        return (
            pointer.x > 0 &&
            pointer.x < this.bounds.width &&
            pointer.y > 0 &&
            pointer.y < this.bounds.height
        );
    }

    /**
     * Creates an event to be emitted when an event occurs.
     * @param type The type of the event.
     *
     * @returns The event to be emitted.
     */
    private createEvent<Type extends string>(type: Type): CursorEvent<Type> {
        return {
            position: this.mousePosition,
            inShape: this.isHover,
            inBlock: this.isHoverBlock,
            type,
        };
    }

    /**
     * The current bounds of the shape.
     * @private
     */
    private get bounds(): Bounds {
        return this.boundsSelect();
    }
    /**
     * Constructor.
     * @param shape The shape to listen to.
     * @param boundsSelect A function that returns the bounds inside the shape to handle the cursor at
     */
    constructor(
        private readonly shape: Konva.Shape,
        private readonly boundsSelect: () => Bounds,
    ) {}

    /**
     * Emits everytime the mouse emits an event while it is being pressed
     * @param onlyIf The condition under which to start capturing a down event to start emitting
     *
     * @returns - The event to be emitted.
     */
    public pressed$(onlyIf?: () => boolean): Observable<boolean> {
        // catch mouse down events
        return this.down$.pipe(
            filter(() => (onlyIf ? onlyIf() : true)),
            // while mouse down, watch mouse move and up events to check if the shape is being pressed
            switchMap(() =>
                merge(
                    // mouse up events are mapped to false
                    UnitCursorListener.globalMouseUp$.pipe(mapTo(false)),
                    // mouse move events are mapped to a check if the mouse is over the shape
                    this.move$.pipe(map((event) => event.inBlock)),
                ).pipe(
                    // emit true at the start, equivalent to the mouse down event
                    startWith(true),
                    // stop watching one event after the mouse was released
                    pairwise(),
                    takeWhile(([wasPressing, _]) => wasPressing),
                    // emit only the last event
                    map(([_, isPressing]) => isPressing),
                    // emit true again, as the pairwise prevents it form being emitted
                    startWith(true),
                ),
            ),
        );
    }
}
