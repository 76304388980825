import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef} from '@angular/core';
import { faCloudUploadAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VideoRecord, VideoSource } from '@nirby/media-models';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {VideoFactoryService} from '@nirby/media/video-factory';

@Component({
    selector: 'nirby-video-select',
    templateUrl: './video-select.component.html',
    styleUrls: ['./video-select.component.scss'],
})
export class VideoSelectComponent implements OnInit, OnChanges {
    @Input() usePreview = false;

    @Input() useModal = false;
    @Input() modalTitle = 'Select Video';
    @Input() video?: VideoRecord | null;
    @Input() allowedSource: VideoSource[] = ['cloudflare-stream', 'youtube', 'vimeo'];
    @Input() useClearButton = false;
    @Output() videoChange = new EventEmitter<VideoRecord | null>();
    public embedUrl: SafeUrl | null = null;

    iconCloudUpload = faCloudUploadAlt;

    iconTrash = faTrash;
    constructor(
        private modal: NgbModal,
        private videoFactory: VideoFactoryService,
        private domSanitizer: DomSanitizer,
    ) {}

    onVideoSelect(video: VideoRecord | null): void {
        this.video = video;
        this.videoChange.emit(video);
        if (this.useModal) {
            this.modal.dismissAll();
        }
    }

    private updateEmbedUrl(): void {
        if (this.video) {
            this.embedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
                this.videoFactory.getSourceService(this.video.type).getEmbedUrlFromId(this.video.id)
            );
        } else {
            this.embedUrl = null;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['video']) {
            this.updateEmbedUrl();
        }
    }

    ngOnInit(): void {
        this.updateEmbedUrl();
    }

    clear(): void {
        this.onVideoSelect(null);
        this.modal.dismissAll();
    }

    openSelectModal(content: TemplateRef<unknown>): void {
        this.modal.open(content);
    }
}
