import {Component, EventEmitter, forwardRef, Input, Output,} from '@angular/core';
import Color from 'color';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {VoidFunction} from '@nirby/js-utils/types';

@Component({
    selector: 'nirby-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ColorPickerComponent),
            multi: true,
        },
    ],
})
export class ColorPickerComponent implements ControlValueAccessor {
    get colors(): string[] {
        return this.colorOptions.filter(
            (v) => this.allowTransparency || new Color(v).alpha() === 1
        );
    }

    get code(): string | null {
        return this.color?.toString() ?? null;
    }

    get codeOpaque(): string | null {
        return this.color?.toString() ?? null;
    }

    set codeOpaque(value: string | null) {
        if (value) {
            const newColor = new Color(value);
            if (this.isTransparent(value)) {
                this.color = newColor;
            } else {
                this.color = newColor.alpha(this.opacity);
            }
        } else {
            this.color = null;
        }
    }

    get opacity(): number {
        return this.color?.alpha() ?? 1;
    }

    set opacity(value: number) {
        if (!this.color) {
            return;
        }
        this.color = this.color.alpha(value);
    }

    get color(): Color | null {
        if (!this.value) {
            return null;
        } else {
            return new Color(this.value);
        }
    }

    set color(value: Color | null) {
        if (this.value === null || this.isTransparent(this.value)) {
            value = value?.alpha(this.defaultOpacity) ?? null;
        }

        if (!value) {
            this.value = null;
        } else {
            this.value = value.toString();
        }
        this.emit();
    }

    disabled = false;

    @Input() defaultOpacity = 1;
    @Input() value: string | null = '#ffffff';
    @Input() haveAutoOption = false;
    @Input() position: 'bottom' | 'top' = 'bottom';
    @Input() allowTransparency = false;
    @Output() valueChange = new EventEmitter<string | null>();

    colorOptions: string[] = [
        'rgba(255,255,255,0)',
        '#ffffff',
        '#1754d9',
        '#1de07a',
        '#92f0ff',
        '#d7269f',
        '#6297ff',
        '#ff4d4d',
        '#ffd14e',
        '#1adec5',
        '#46807d',
        '#bda97e',
        '#1d9349',
        '#46807d',
        '#a11f1f',
        '#000000',
        '#000000',
        '#20232a',
        '#3d424c',
        '#646a77',
        '#9094a8',
        '#a6adbb',
        '#cbd0db',
    ];
    transparentIcon = faTimes;

    emit(): void {
        this.onChange(this.value);
        this.valueChange.emit(this.value);
    }

    onChange: VoidFunction<string | null> = () => {
        return;
    };
    onTouched: VoidFunction<void> = () => {
        return;
    };

    registerOnChange(fn: VoidFunction<string | null>): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: VoidFunction<void>): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(color?: string): void {
        this.value = color ?? null;
    }

    needsBorder(colorName: string | null): boolean {
        if (!colorName) {
            return true;
        }
        const color = new Color(colorName);
        return this.isTransparent(colorName) || color.lightness() > 90;
    }

    isTransparent(colorName: string | null): boolean {
        if (!colorName) {
            return false;
        }
        const color = new Color(colorName);
        return color.alpha() < 0.1;
    }

    onAutoChange($event: Event): void {
        const value = ($event.target as any).value;
        if (value) {
            this.codeOpaque = null;
        }
    }
}
