// noinspection JSUnusedGlobalSymbols

import {Observable} from 'rxjs';
import {NirbyDocument} from './document';
import {DateTime} from 'luxon';
import {z} from 'zod';
import {FieldPathMap, NirbyFieldPathSpecial} from './field-path';
import {NirbyFieldValue, replaceDeep} from './utils';
import {NirbyDocumentReference} from './reference';
import {ModelMetadata} from '@nirby/store/models';

/**
 * This is a recursive type that will generate a dot notation path for a given object.
 * T: The object to generate the path for
 * Prefix: The prefix to prepend to the path
 * Depth: The depth of the recursion. This is used to limit the recursion depth to 3 levels.
 */
type DotNotationFieldPath<
    T extends object,
    Prefix extends string,
    Depth extends string
> = {
    [K in keyof T & string]: Depth extends '000'
        ? `${Prefix}${K}`
        : T[K] extends DateTime | NirbyDocumentReference<object>
            ? `${Prefix}${K}`
            : NonNullable<T[K]> extends object
                ?
                | DotNotationFieldPath<
                NonNullable<T[K]>,
                `${Prefix}${K}.`,
                `${Depth}0`
            >
                | `${Prefix}${K}`
                : `${Prefix}${K}`;
}[keyof T & string];

export type NirbyFieldObjectPath<T extends object> =
    | DotNotationFieldPath<T, '', ''>
    | keyof ModelMetadata;
export type NirbyFieldPath<T extends object> =
    | NirbyFieldObjectPath<T>
    | NirbyFieldPathSpecial;

export type QueryOperator =
    | '<'
    | '<='
    | '=='
    | '!='
    | '>='
    | '>'
    | 'array-contains'
    | 'in';
export type QueryOrderByDirection = 'asc' | 'desc';

/**
 * An abstraction to build query constraints in a database-agnostic way.
 */
export abstract class QueryBuilder<
    T extends object,
    Constraint = unknown,
    Path = unknown
> {
    protected readonly constraints: Constraint[] = [];

    abstract _where(
        field: string | Path,
        operator: QueryOperator,
        value: NirbyFieldValue
    ): Constraint;

    abstract buildPathMap(): FieldPathMap<Path>;

    /**
     * Receives a {@link NirbyFieldPath} and returns an object that can be understood by the database.
     * @param path The path to clean
     * @private
     *
     * @returns The cleaned path
     */
    private cleanPath(path: NirbyFieldPath<T>): string | Path {
        if (path instanceof NirbyFieldPathSpecial) {
            return this.buildPathMap().get(path);
        }
        return path;
    }

    /**
     * Receives a {@link NirbyFieldValue} and returns an object that can be understood by the database.
     * @param value The value to clean
     * @private
     *
     * @returns The cleaned value
     */
    private cleanValue<R extends object>(
        value: NirbyFieldValue | NirbyDocumentReference<R>,
    ): NirbyFieldValue {
        return replaceDeep(
            value,
            z.union([
                z
                    .custom<NirbyDocumentReference<object>>(
                        (v) => v instanceof NirbyDocumentReference,
                    )
                    .transform((v) => v.toFieldValue()),
                z
                    .custom<DateTime>(
                        (v) => v instanceof DateTime,
                    )
                    .transform((v) => v.toJSDate()),
            ]),
        );
    }

    /**
     * Adds a where constraint to the query.
     * @param field The field to query
     * @param operator The operator to use
     * @param value The value to compare against
     *
     * @returns The query builder instance
     */
    where(
        field: NirbyFieldPath<T>,
        operator: QueryOperator,
        value: NirbyFieldValue | NirbyDocumentReference<object>,
    ): this {
        this.constraints.push(
            this._where(this.cleanPath(field), operator, this.cleanValue(value)),
        );
        return this;
    }

    abstract _orderBy(
        field: NirbyFieldObjectPath<T>,
        direction?: QueryOrderByDirection
    ): Constraint;

    /**
     * Adds an order by constraint to the query.
     * @param field The field to order by
     * @param direction The direction to order by
     *
     * @returns The query builder instance
     */
    orderBy(
        field: NirbyFieldObjectPath<T>,
        direction?: QueryOrderByDirection,
    ): this {
        this.constraints.push(this._orderBy(field, direction));
        return this;
    }

    abstract _limit(limit: number): Constraint;

    /**
     * Adds a limit constraint to the query.
     * @param limit The limit to apply
     *
     * @returns The query builder instance
     */
    limit(limit: number): this {
        this.constraints.push(this._limit(limit));
        return this;
    }

    abstract _startAt(value: NirbyFieldValue): Constraint;

    /**
     * Adds a start at constraint to the query.
     * @param value The value to start at
     *
     * @returns The query builder instance
     */
    startAt(value: NirbyFieldValue): this {
        this.constraints.push(this._startAt(value));
        return this;
    }

    abstract _startAfter(value: NirbyFieldValue): Constraint;

    /**
     * Adds a start after constraint to the query.
     * @param value The value to start after
     *
     * @returns The query builder instance
     */
    startAfter(value: NirbyFieldValue): this {
        this.constraints.push(this._startAfter(value));
        return this;
    }

    abstract _endAt(value: NirbyFieldValue): Constraint;

    /**
     * Adds an end at constraint to the query.
     * @param value The value to end at
     *
     * @returns The query builder instance
     */
    endAt(value: NirbyFieldValue): this {
        this.constraints.push(this._endAt(value));
        return this;
    }

    abstract _endBefore(value: NirbyFieldValue): Constraint;

    /**
     * Adds an end before constraint to the query.
     * @param value The value to end before
     *
     * @returns The query builder instance
     */
    endBefore(value: NirbyFieldValue): this {
        this.constraints.push(this._endBefore(value));
        return this;
    }

    abstract watch(): Observable<NirbyDocument<T>[]>;

    abstract get(): Observable<NirbyDocument<T>[]>;

    abstract getFirst(): Observable<NirbyDocument<T> | null>;

    abstract watchFirst(): Observable<NirbyDocument<T> | null>;
}
