import {Component, Inject} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthenticationService, RouteParametersService} from '@nirby/shared/database';
import {faComments} from '@fortawesome/free-solid-svg-icons';

import {CREATORS_ENVIRONMENT, NirbyEnvironment} from '@nirby/environment/base';
import {User} from '@angular/fire/auth';

@Component({
    selector: 'nirby-settings-personal',
    templateUrl: './settings-personal.component.html',
    styleUrls: ['./settings-personal.component.scss'],
})
export class SettingsPersonalComponent {
    authenticatedUser$: Observable<User | null> = this.auth.user$;

    iconComments = faComments;

    get version(): string {
        return this.environment.version;
    }

    constructor(
        @Inject(CREATORS_ENVIRONMENT) private environment: NirbyEnvironment,
        private auth: AuthenticationService,
        private routeParams: RouteParametersService,
    ) {

    }

    get widgetId(): string {
        return this.routeParams.workspaceId;
    }

}
