import {Pipe, PipeTransform} from '@angular/core';
import {StripeProductPrice} from '@nirby/models/billing';

/**
 * Class to display a Price in a human readable format.
 */
export class PriceDisplay {
    private static MONTHS_PER_INTERVAL: {
        [key in PriceDisplay['interval']]: number;
    } = {
        month: 1,
        year: 12,
        week: 1 / 4,
        day: 1 / 30,
    };

    /**
     * Constructor.
     * @param interval The interval of the price.
     * @param amount The amount of the price.
     * @param currency The currency of the price.
     */
    constructor(
        public readonly interval: 'month' | 'year' | 'week' | 'day',
        public readonly amount: number,
        public readonly currency: string,
    ) {
    }

    /**
     * Gets the integer part of the price.
     */
    public get intPart(): number {
        return Math.floor(this.amount);
    }

    /**
     * Gets the decimal part of the price.
     */
    public get decPart(): number {
        return Math.round((this.amount - this.intPart) * 100);
    }

    /**
     * Gets the price display as a string.
     *
     * @returns The price display as a string.
     */
    public toString(): string {
        return `${this.intPart}.${this.decPart} ${this.currency}`;
    }

    /**
     * Gets the price per month.
     *
     * @returns The price per month.
     */
    public get perMonth(): PriceDisplay {
        if (this.interval === 'month') {
            return this;
        }
        const monthsPerInterval = PriceDisplay.MONTHS_PER_INTERVAL[this.interval];
        const pricePerMonth = this.amount / monthsPerInterval;
        return new PriceDisplay(
            'month',
            pricePerMonth,
            this.currency,
        );
    }
}

@Pipe({
    name: 'priceSelect',
})
/**
 * Pipe to select a price from a list of Stripe prices.
 */
export class PriceSelectPipe implements PipeTransform {
    private readonly DECIMALS_PER_CURRENCY: Record<string, number | undefined> = {
        usd: 2,
        eur: 2,
        jpy: 0,
    };

    /**
     * Receives a Price and a currency and returns a {@link PriceDisplay} object to display the price.
     * @param price The price.
     * @param currency The currency.
     *
     * @returns The price display. Or null if the price is not available in the currency.
     */
    transform(price: Readonly<StripeProductPrice> | undefined | null, currency = 'usd'): PriceDisplay | null {
        if (!price || !price.unit_amount) {
            return null;
        }
        const decimals = this.DECIMALS_PER_CURRENCY[currency.toLowerCase()];
        if (decimals === undefined) {
            return null;
        }
        const amount = price.unit_amount / 10 ** decimals;
        return new PriceDisplay(
            price.interval,
            amount,
            currency,
        );
    }
}
