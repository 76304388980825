import {BaseBlock, buildBlockSchema} from '../block.model';
import {Migrator} from '@nirby/store/migrator';
import {z} from 'zod';

export const SLIDER_BLOCK_CONTENT_SCHEMA = z.object({
    title: z.string(),
    color: z.string(),
    emoji: z.string(),
    submitMessage: z.string().default(''),
});

export type WidgetSliderBlock = BaseBlock<
    'Slider',
    {
        title: string;
        color: string;
        emoji: string;
        submitMessage: string;
    }
>;
export const SLIDER_BLOCK_SCHEMA = buildBlockSchema<WidgetSliderBlock>(
    'Slider' as const,
    SLIDER_BLOCK_CONTENT_SCHEMA,
    z.object({}),
);

export const SLIDER_BLOCK_MIGRATOR = new Migrator<WidgetSliderBlock>(
    SLIDER_BLOCK_SCHEMA,
    () => ({
        hash: '',
        position: [
            {x: 0, y: 0},
            {x: 128, y: 128},
        ],
        rotation: 0,
        content: {
            color: '#3b14d3',
            emoji: '😍',
            title: 'Insert text here...',
            submitMessage: 'Answer submitted successfully!',
        },
        style: {},
        actions: {
            click: [],
        },
        type: 'Slider',
    }),
);
