import { Component } from '@angular/core';
import { RectangleBlockContent } from '@nirby/models/nirby-player';
import { BlockContentComponent } from '../../block-content.component';

@Component({
    selector: 'nirby-block-rectangle-content',
    templateUrl: './block-rectangle-content.component.html',
    styleUrls: ['./block-rectangle-content.component.scss'],
})
export class BlockRectangleContentComponent extends BlockContentComponent<RectangleBlockContent> {
    color = '#000000';

    setData(content: RectangleBlockContent): void {
        this.color = content.color;
    }

    chooseColor(color: string | null): void {
        this.color = color ?? '#ffffff';
        this.sendData({
            color: this.color,
        });
    }
}
