import {FullProductId, NirbyEvent, ProductId} from '@nirby/analytics-typings';
import {Logger} from '@nirby/logger';
import {Contact, LiteEvent, NirbyContext} from '@nirby/runtimes/context';
import {
    combineLatest,
    defer,
    distinctUntilChanged,
    EMPTY,
    filter,
    from,
    fromEvent,
    mergeMap,
    NEVER,
    of,
    retry,
    Subscription,
    switchMap,
    tap,
} from 'rxjs';
import {ContactsApi} from './contacts-api';
import {catchError, map, shareReplay} from 'rxjs/operators';
import {NirbyApi} from './nirby-api';

interface CreateEventResponse {
    message: 'OK';
    eventId: string;
}

export interface CreateEventBody<T extends NirbyEvent> {
    type: T['type'] | string;
    productId: string | null;
    token: string | null;
    tags: string[];
    properties: T['properties'];
}

/**
 * Service to track contact's events
 */
export class AnalyticsApi {
    private readonly contacts: ContactsApi;

    /**
     * Constructor.
     * @param api The API service.
     * @param contacts The contacts service.
     */
    constructor(
        private api: NirbyApi,
    ) {
        this.contacts = new ContactsApi(api);
    }

    /**
     * Creates the payload to post an event to the API
     * @param productId The product ID.
     * @param contactToken The contact token.
     * @param event The event to post.
     *
     * @returns - The payload to post.
     */
    private static createEventBody<TEvent extends NirbyEvent>(
        productId: ProductId | null,
        contactToken: string | null,
        event: LiteEvent<TEvent>,
    ): CreateEventBody<TEvent> {
        return {
            productId: productId?.uniqueId ?? null,
            properties: event.properties as TEvent['properties'],
            tags: event.tags,
            token: contactToken ?? null,
            type: event.type,
        };
    }

    /**
     * Registers an event for the given context. It returns true if the event is registered successfully, or false if
     * the context isn't ready or the register request failed for some readonly
     * @param at The product ID the event is associated with
     * @param by The contact that triggered the event
     * @param event The event to register
     */
    public async track<TEvent extends NirbyEvent>(
        at: FullProductId,
        by: Contact,
        event: LiteEvent<TEvent>,
    ): Promise<LiteEvent<TEvent>> {
        const workspaceId = at.workspaceId;
        const productId = at.asProductId();
        const contactToken = by.token;
        if (!workspaceId || !productId) {
            return event;
        }
        try {
            await this.postEvent(
                event.type,
                workspaceId,
                productId,
                event.tags,
                event.properties,
                contactToken ?? null,
            );
        } catch (e) {
            Logger.errorStyled('EVENTS', 'Could not post event', e);
        }
        return event;
    }

    /**
     * Posts an event to the API.
     * @param type The type of the event.
     * @param workspaceId The workspace ID.
     * @param productId The product ID.
     * @param tags The tags of the event.
     * @param properties The properties of the event.
     * @param contactToken The token of the contact that triggered the event.
     * @private
     */
    private async postEvent<TEvent extends NirbyEvent>(
        type: TEvent['type'] | string,
        workspaceId: string,
        productId: ProductId | null,
        tags: string[],
        properties: TEvent['properties'],
        contactToken: string | null = null,
    ): Promise<string> {
        const body: CreateEventBody<TEvent> = AnalyticsApi.createEventBody(
            productId,
            contactToken,
            {properties, tags, type},
        );
        const response = await this.api.post<CreateEventResponse>(
            `/workspaces/${workspaceId}/events`,
            body,
        );
        return response.eventId;
    }

    /**
     * Returns an observable that, when subscribed to, will track the events and actions emitted by the given context.
     * This will also create a contact if the contact isn't "authenticated" yet.
     *
     * @param context The context to track.
     * @param storePersistent Whether to store the memory on the storage.
     * @param storeContact Whether to store the contact on the storage.
     * @param trackEvents Whether to track the events.
     * @param onUnloadEvent The event to trigger when the context is unloaded.
     *
     * @returns - A subscription to the event tracking, contact creation and action listener.
     */
    public trackContext(
        context: NirbyContext,
        storePersistent: boolean,
        storeContact: boolean,
        trackEvents = true,
        onUnloadEvent?: () => LiteEvent | undefined,
    ): Subscription {
        const subscription = new Subscription();

        const productId$ = context.productId$.pipe(
            filter((productId): productId is FullProductId => !!productId),
        );

        const contactAndProductId$ = productId$
            .pipe(
                // get the contact and merge it with the current product ID
                switchMap((productId) =>
                    context.contact$.pipe(
                        distinctUntilChanged(
                            (a, b) => a.token === b.token,
                        ),
                        map((contact) => ({productId, contact})),
                    )),
                // create a new contact if it doesn't exist
                switchMap(({contact, productId}) => {
                    if (!trackEvents) {
                        return of({
                            contact: Contact.unauthenticated(),
                            productId,
                        });
                    }
                    if (
                        !contact.isAuthenticated ||
                        productId.workspaceId !== contact.workspaceId
                    ) {
                        // if not authenticated or in another workspace, create a new contact
                        return from(
                            this.contacts.create(
                                productId,
                                Contact.loadPropertiesFromQuery() ?? undefined,
                                Contact.loadUniqueAliasFromQuery() ?? undefined,
                            ),
                        ).pipe(
                            filter((c): c is Contact => c !== null),
                            tap((c) => (context.contact = c)),
                            switchMap(() => NEVER),
                        );
                    } else {
                        // if authenticated, return the contact
                        return of({
                            contact,
                            productId,
                        });
                    }
                }),
                shareReplay({refCount: true, bufferSize: 1}),
            );
        subscription.add(contactAndProductId$.subscribe());

        // track events
        if (trackEvents) {
            subscription.add(
                combineLatest([
                    productId$,
                    context.contact$,
                ]).pipe(
                    // start listening for events
                    switchMap(([productId, contact]) =>
                        context.events$.pipe(
                            map((event) => ({event, contact, productId})),
                        ),
                    ),
                    // register event
                    mergeMap(({event, contact, productId}) =>
                        defer(() => this.track(productId, contact, event)).pipe(
                            retry(3),
                            catchError((e) => {
                                Logger.errorStyled('EVENTS', 'Could not post event', e);
                                return EMPTY;
                            }),
                        ),
                    ),
                )
                    .subscribe(),
            );
        }

        // keep contact in storage
        if (storeContact) {
            subscription.add(
                context.contact$.subscribe((contact) => {
                    contact.saveToStorage();
                }),
            );
        }

        // store persistent
        if (storePersistent) {
            subscription.add(context.storeInPersistentStorage().subscribe());
        }

        // unload event
        if (onUnloadEvent) {
            subscription.add(
                contactAndProductId$.pipe(
                    switchMap(({contact, productId}) => fromEvent(window, 'visibilitychange').pipe(
                        filter(() => document.visibilityState === 'hidden'),
                        map(() => ({contact, productId})),
                    )),
                ).subscribe(({contact, productId}) => {
                    const event = onUnloadEvent();
                    if (event) {
                        this.api.sendBeacon(
                            `/workspaces/${productId.workspaceId}/events`,
                            AnalyticsApi.createEventBody(productId.asProductId(), contact.token ?? null, event),
                        );
                    }
                }),
            );
        }

        return subscription;
    }
}
