import { Component, EventEmitter, Output } from '@angular/core';

import {
    faCheck,
    faPlay,
    faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { UntypedFormControl } from '@angular/forms';
import { VideoRecord } from '@nirby/media-models';
import {YoutubeService} from '@nirby/media/video-factory';

@Component({
    selector: 'nirby-video-youtube-url',
    templateUrl: './video-youtube-url.component.html',
    styleUrls: ['./video-youtube-url.component.scss'],
})
export class VideoYoutubeUrlComponent {
    @Output() private selectVideo = new EventEmitter<VideoRecord>();

    urlControl = new UntypedFormControl('');
    validUrl: boolean | null = null;

    badIcon = faTimesCircle;
    okIcon = faCheck;
    iconPlay = faPlay;

    loading = false;

    constructor(private youtube: YoutubeService) {}

    async onSubmit(): Promise<void> {
        this.loading = true;
        this.urlControl.disable();
        const video = await this.youtube.getVideoFromUrl(this.urlControl.value);
        if (video) {
            this.selectVideo.emit(video);
            this.validUrl = true;
        } else {
            this.validUrl = false;
        }
        this.urlControl.enable();
        this.loading = false;
    }
}
