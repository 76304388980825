import {Injectable} from '@angular/core';
import type {VideoLiteService} from './video-lite.service';
import {YoutubeLiteService} from './youtube.service';
import {VimeoLiteService} from './vimeo.service';
import type {VideoSource} from '@nirby/media-models';
import {AppError} from '@nirby/js-utils/errors';
import {CloudflareLiteService} from './cloudflare-lite.service';

@Injectable({
    providedIn: 'root',
})
export class VideoLiteFactoryService {
    private services: Record<VideoSource, VideoLiteService | null> = {
        youtube: null,
        vimeo: null,
        'cloudflare-stream': null,
    };

    constructor(
        private youtube: YoutubeLiteService,
        private vimeo: VimeoLiteService,
        private upload: CloudflareLiteService,
    ) {
        this.services.youtube = youtube;
        this.services.vimeo = vimeo;
        this.services['cloudflare-stream'] = upload;
    }

    public getSourceService(source: VideoSource): VideoLiteService {
        const service = this.services[source];
        if (!service) {
            throw new AppError(`No image service for source: ${source}`);
        }
        return service;
    }
}
