export type ReturnFunction<T, R> = (p: T) => R;
export type VoidFunction<T> = ReturnFunction<T, void>;

export type Nullable<T> = {
    [P in keyof T]: T[P] | null;
};

export type InnerRequired<T> = {
    [P in keyof T]-?: InnerRequired<T[P]>;
};

export type InnerPartial<T> = {
    [P in keyof T]?: InnerPartial<T[P]>;
};

export type Defaulter<T> = {
    [P in keyof T]: Defaulter<T[P]>;
} & (T extends object ? { _defaultMergeIfNecessary?: boolean } : any);

export function fillDefault<T>(
    original: Partial<T> | undefined,
    defaultValues: T
): T {
    if (defaultValues && typeof defaultValues === 'object') {
        let key: any;

        const filled: any = {};

        const source: any = defaultValues;
        for (key in source) {
            // eslint-disable-next-line no-prototype-builtins
            if (!source.hasOwnProperty(key)) {
                continue;
            }
            const defaultValue = source[key];
            filled[key] = (original as any)[key] ?? defaultValue;
        }
        return Object.assign({}, filled);
    }
    return Object.assign({}, defaultValues);
}

export function fillDefaultRecursive<T>(
    original: InnerPartial<T> | undefined,
    defaultValues: Defaulter<T>
): T {
    const toObject = typeof defaultValues === 'object';
    const toArray = Array.isArray(defaultValues);
    if (defaultValues === null) {
        return original === undefined ? (null as any) : (original as T);
    } else if (original === undefined) {
        if (toArray) {
            return [...(defaultValues as unknown as any[])] as unknown as T;
        } else if (toObject) {
            const returned = { ...defaultValues };
            delete returned._defaultMergeIfNecessary;
            return returned as unknown as T;
        } else {
            return defaultValues as unknown as T;
        }
    } else if (original === null) {
        return null as any;
    } else if (toArray) {
        return original as T;
    } else if (toObject) {
        const source: any = defaultValues;
        if (source._defaultIgnore) {
            return original as unknown as T;
        }

        let key: any;
        const filled: any = {};
        for (key in source) {
            // eslint-disable-next-line no-prototype-builtins
            if (!source.hasOwnProperty(key)) {
                continue;
            }
            const defaultValue = source[key];
            const orig = (original as InnerPartial<any>)[key];
            filled[key] = fillDefaultRecursive(orig, defaultValue);
        }
        if (source._defaultMergeIfNecessary) {
            const returned = { ...filled, ...original };
            delete returned._defaultMergeIfNecessary;
            return returned;
        }
        return { ...filled };
    }
    return original as T;
}
