import {ReadOnlyKeyValueStorage} from './key-value-storage';

/**
 * Object to check if a value filter is affected by a context change.
 */
export interface StorageFilter<TValue, TContext> {
    readonly storage: ReadOnlyKeyValueStorage<TValue>;

    filter(value: TValue, context: TContext): boolean;
}

/**
 * A key-value storage that filters the values without changing the underlying storage using a context to validate
 * the filter.
 */
export class FilteredKeyValueStorage<TValue, TContext> implements ReadOnlyKeyValueStorage<TValue> {
    protected context: TContext;

    /**
     * Constructor.
     * @param internal The storage to filter.
     * @param initialContext The initial context to use to filter the values.
     */
    public constructor(
        private readonly internal: StorageFilter<TValue, TContext>,
        initialContext: TContext,
    ) {
        this.context = initialContext;
    }

    /**
     * Get all entries.
     */
    * entries(): Iterable<[string, TValue]> {
        for (const [id, value] of this.internal.storage.entries()) {
            if (this.filter(value)) {
                yield [id, value];
            }
        }
    }

    /**
     * Get the value for the given ID.
     * @param id The ID of the value to get.
     *
     * @returns The value for the given ID, or undefined if the ID is not found.
     */
    get(id: string): TValue | undefined {
        const value = this.internal.storage.get(id);
        if (value === undefined) {
            return undefined;
        }
        if (this.filter(value)) {
            return value;
        }
        return undefined;
    }

    /**
     * Checks if the given value should be included in the storage.
     * @param value The value to check.
     * @protected
     *
     * @returns True if the value should be included in the storage, false otherwise.
     */
    private filter(value: TValue): boolean {
        return this.internal.filter(value, this.context);
    }

    /**
     * Changes the context used to filter the values.
     * @param context The new context to use.
     */
    setContext(context: TContext): void {
        this.context = context;
    }
}
