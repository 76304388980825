import {Component} from '@angular/core';
import {UntypedFormControl, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '@nirby/shared/database';

@Component({
    selector: 'nirby-user-login',
    templateUrl: './user-login.component.html',
    styleUrls: ['./user-login.component.scss']
})
/**
 * A component for logging in users.
 */
export class UserLoginComponent {
    emailControl = new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
    ]);
    passwordControl = new UntypedFormControl('', Validators.required);
    form = new FormGroup({
        email: this.emailControl,
        password: this.passwordControl
    });

    loading = false;

    /**
     * Constructor.
     * @param auth The authentication service.
     */
    constructor(public auth: AuthenticationService) {
    }

    /**
     * Sign in with Google.
     */
    async loginGoogle(): Promise<void> {
        this.loading = true;
        const success = await this.auth.signInGoogle();
        if (!success) {
            this.loading = false;
        }
    }

    /**
     * Sign in with email and password.
     */
    async loginUserEmailPassword(): Promise<void> {
        this.loading = true;
        this.form.disable();
        const success = await this.auth.signInEmail(
            this.emailControl.value,
            this.passwordControl.value
        );
        if (!success) {
            this.loading = false;
            this.form.enable();
        }
    }
}
