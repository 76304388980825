import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {AnyCardAction, factoryActions} from '@nirby/models/nirby-player';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

interface ActionOption {
    type: AnyCardAction['type'];
    label: string;
}

@Component({
    selector: 'nirby-action-form-control',
    templateUrl: './action-form-control.component.html',
    styleUrls: ['./action-form-control.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ActionFormControlComponent),
            multi: true,
        },
    ]
})
/**
 * Action form component.
 */
export class ActionFormControlComponent implements ControlValueAccessor {
    private readonly allActions: ActionOption[] = [
        {
            type: 'go-to-url',
            label: 'Go to URL',
        },
        /*
        {
            type: 'card-link',
            label: 'Go to card',
        },
         */
        {
            type: 'close',
            label: 'Close',
        },
        /*
        {
            type: 'trigger-dom',
            label: 'Trigger DOM event',
        },
         */
        {
            type: 'video',
            label: 'Open video',
        },
        {
            type: 'variable-update',
            label: 'Update variable',
        },
        /*
        {
            type: 'video-link',
            label: 'Go to source',
        },
         */
        /*
        {
            type: 'prime-pause',
            label: 'Pause'
        }
         */
        {
            type: 'open-form',
            label: 'Open form',
        },
        /*
        {
            type: 'go-to-prime',
            label: 'Go To Prime',
        },
        {
            type: 'go-back',
            label: 'Go Back',
        },
         */
        {
            type: 'track-event',
            label: 'Track custom event',
        },
        {
            type: 'answer-question',
            label: 'Answer question',
        },
        {
            type: 'open-embed',
            label: 'Open embedded site',
        }
    ];

    public availableActions: ActionOption[] = [
        ...this.allActions
    ];
    @Output() public readonly closeRequest = new EventEmitter<void>();

    /**
     * Actions that are selectable in the form.
     * @param value Array of actions.
     */
    @Input() set actions(value: AnyCardAction['type'][] | null) {
        this.availableActions = value ?
            this.allActions.filter(action => value.includes(action.type)) :
            [...this.allActions];
    }

    disabled = false;
    value: AnyCardAction | null = null;

    icons = {
        close: faTimes
    };

    onChange: (value: AnyCardAction) => void = () => {
        return;
    };
    onTouched: () => void = () => {
        return;
    };

    /**
     * Register a function to be called when the control's value changes.
     * @param fn Function to be registered.
     */
    registerOnChange(fn: (action: AnyCardAction) => void): void {
        this.onChange = fn;
    }

    /**
     * Register a function to be called when the control receives a touch event.
     * @param fn Function to be registered.
     */
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    /**
     * Sets the disabled state of the control.
     * @param isDisabled Whether the control should be disabled.
     */
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Write a new value to the element.
     * @param obj Value to be written to the element.
     */
    writeValue(obj: AnyCardAction | null): void {
        this.value = obj;
    }

    /**
     * Sets the action to be used as the default action of the given type.
     * @param actionType Type of the action to be used as the default.
     */
    onChangeType<TAction extends AnyCardAction>(actionType: TAction['type']): void {
        if (this.value?.type !== actionType) {
            this.value = factoryActions(actionType, this.value?.id ?? undefined);
            this.onChange(this.value);
        }
    }

    /**
     * Requests to close the editor.
     */
    close(): void {
        this.closeRequest.emit();
    }
}
