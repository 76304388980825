import {ArtBoardItem, ArtBoardItemKonvaNode, ArtBoardTransformer, RelativeZIndex} from '../../';
import {
    HeightChangeTransformation,
    MultiSizeChangeTransformation,
    ResizeHandler,
    ResizeTransformation,
    RotationTransformation,
    Transformation,
    WidthChangeTransformation,
} from '../../transformation';
import Konva from 'konva';
import {Vector2d} from 'konva/lib/types';
import {
    BehaviorSubject,
    combineLatest,
    distinctUntilChanged,
    filter,
    fromEvent,
    merge,
    NEVER,
    Observable,
    of,
    share,
    switchMap,
    takeUntil,
} from 'rxjs';
import {map} from 'rxjs/operators';
import {KonvaEventObject} from 'konva/lib/Node';
import {BlockEventController} from './events';
import {Logger} from '@nirby/logger';

interface GuideLine {
    lineGuide: number;
    offset: number;
    orientation: 'V' | 'H';
    snap: 'start' | 'center' | 'end';
}

interface GuideLinesStops {
    horizontal: number[];
    vertical: number[];
}

interface SnappingEdge {
    guide: number;
    offset: number;
    snap: 'start' | 'center' | 'end';
}

interface ObjectBounds {
    horizontal: SnappingEdge[];
    vertical: SnappingEdge[];
}

interface GuideLineTemp {
    lineGuide: number;
    offset: number;
    diff: number;
    snap: 'start' | 'center' | 'end';
}

interface SizeChange {
    width: number;
    height: number;
    x: number;
    y: number;
}

const MIN_SIZE = 1;
const GUIDELINE_OFFSET = 5;

export class BlockTransformRotateController<TMeta> {
    public readonly rotate$ = this.controller.transform$.pipe(
        filter((r): r is RotationTransformation => !!r && r.type === 'rotation'),
    );

    constructor(
        private item: ArtBoardItem<TMeta>,
        private readonly controller: ArtBoardItemTransformController<TMeta>,
    ) {
    }
}

export class BlockTransformDragController<TMeta,
    TB extends ArtBoardItem<TMeta> = ArtBoardItem<TMeta>> {
    public dragStart$: Observable<KonvaEventObject<MouseEvent>> =
        this.item.shape$.pipe(
            switchMap((shape) =>
                shape
                    ? fromEvent(shape, 'dragstart').pipe(
                        filter(() => shape.draggable()),
                        map(
                            (evt) =>
                                evt as unknown as KonvaEventObject<MouseEvent>,
                        ),
                    )
                    : NEVER,
            ),
            share(),
        );

    public readonly dragChange$: Observable<Vector2d | null> =
        this.dragStart$.pipe(
            switchMap(() =>
                this.dragMove$.pipe(
                    map(() => this.draggingSnap()),
                    takeUntil(this.dragEnd$),
                ),
            ),
            share(),
        );

    public get dragMove$(): Observable<MouseEvent> {
        return this.item.shape$.pipe(
            switchMap((shape) =>
                shape
                    ? fromEvent(shape, 'dragmove').pipe(
                        filter(() => shape.draggable()),
                        map((evt) => evt as unknown as MouseEvent),
                    )
                    : NEVER,
            ),
        );
    }

    public dragEnd$: Observable<KonvaEventObject<MouseEvent>> =
        this.item.shape$.pipe(
            switchMap((shape) =>
                shape
                    ? fromEvent(shape, 'dragend').pipe(
                        filter(() => shape.draggable()),
                        map(
                            (evt) =>
                                evt as unknown as KonvaEventObject<MouseEvent>,
                        ),
                    )
                    : NEVER,
            ),
            share(),
        );

    public isCursorOverContainer(evt: MouseEvent): boolean {
        const container: HTMLDivElement | null = this.container;
        if (!container) {
            return false;
        }
        const rect = container.getBoundingClientRect();
        return (
            evt.clientX >= rect.left &&
            evt.clientX <= rect.right &&
            evt.clientY >= rect.top &&
            evt.clientY <= rect.bottom
        );
    }

    public get isDraggingOutside$(): Observable<boolean> {
        return this.dragStart$.pipe(
            switchMap(() =>
                this.dragMove$.pipe(
                    map((event) => {
                        if (!this.isCursorOverContainer(event)) {
                            return true;
                        }
                        const shape = this.item.shape;
                        if (!shape) {
                            Logger.warnStyled('BLOCK-EDITOR', 'no shape');
                            return false;
                        }
                        const parentArtBoard =
                            shape?.findAncestor('.art-board', false) ?? null;
                        const stage = parentArtBoard?.getStage();
                        if (!parentArtBoard) {
                            Logger.warnStyled(
                                'BLOCK-EDITOR',
                                'no parent art board',
                                this.item.id,
                            );
                            return false;
                        }
                        if (!stage) {
                            Logger.warnStyled(
                                'BLOCK-EDITOR',
                                'no parent art board',
                            );
                            return false;
                        }
                        const absPos = parentArtBoard.absolutePosition();
                        const absScale = parentArtBoard.getAbsoluteScale();
                        const absSize = {
                            width: parentArtBoard.width() * absScale.x,
                            height: parentArtBoard.height() * absScale.y,
                        };

                        const pointerPos = {
                            x: stage.getPointerPosition()?.x ?? 0,
                            y: stage.getPointerPosition()?.y ?? 0,
                        };

                        return (
                            pointerPos.x < absPos.x ||
                            pointerPos.x > absPos.x + absSize.width ||
                            pointerPos.y < absPos.y ||
                            pointerPos.y > absPos.y + absSize.height
                        );
                    }),
                    distinctUntilChanged(),
                    takeUntil(this.dragEnd$),
                ),
            ),
        );
    }

    public get onDragOut$(): Observable<KonvaEventObject<MouseEvent>> {
        // when dragging outside and the drag ends, emit the event
        return this.isDraggingOutside$.pipe(
            switchMap((isDraggingOutside) =>
                isDraggingOutside ? this.dragEnd$ : NEVER,
            ),
        );
    }

    public get container(): HTMLDivElement | null {
        return this.controller.layer?.getStage().container() ?? null;
    }

    constructor(
        private item: ArtBoardItem<TMeta>,
        private readonly controller: ArtBoardItemTransformController<TMeta>,
    ) {
    }

    draggingSnap(): Vector2d | null {
        this.controller.destroyGuidelines();
        const guides = this.controller.drawAndGetGuides();

        const shape = this.item.shape;
        if (!shape) {
            return null;
        }
        const absPos = shape.absolutePosition();
        guides.forEach((lg) => {
            switch (lg.snap) {
                case 'start': {
                    switch (lg.orientation) {
                        case 'V': {
                            absPos.x = lg.lineGuide + lg.offset;
                            break;
                        }
                        case 'H': {
                            absPos.y = lg.lineGuide + lg.offset;
                            break;
                        }
                    }
                    break;
                }
                case 'center': {
                    switch (lg.orientation) {
                        case 'V': {
                            absPos.x = lg.lineGuide + lg.offset;
                            break;
                        }
                        case 'H': {
                            absPos.y = lg.lineGuide + lg.offset;
                            break;
                        }
                    }
                    break;
                }
                case 'end': {
                    switch (lg.orientation) {
                        case 'V': {
                            absPos.x = lg.lineGuide + lg.offset;
                            break;
                        }
                        case 'H': {
                            absPos.y = lg.lineGuide + lg.offset;
                            break;
                        }
                    }
                    break;
                }
            }
        });
        return {x: absPos.x, y: absPos.y};
    }
}

export class BlockTransformResizeController<TMeta> {
    public readonly postResize$ = this.item.shape$.pipe(
        switchMap((shape) => {
            if (!shape) {
                return NEVER;
            }
            return this.controller.transform$.pipe(
                filter(
                    (
                        transformation,
                    ): transformation is
                        | WidthChangeTransformation
                        | HeightChangeTransformation
                        | MultiSizeChangeTransformation => {
                        return (
                            transformation !== null &&
                            (transformation.type === 'sizeChange' ||
                                transformation.type === 'widthChange' ||
                                transformation.type === 'heightChange')
                        );
                    },
                ),
                map((transformation) => ({transformation, shape})),
            );
        }),
        share(),
    );

    public readonly onSizeChange$: Observable<SizeChange> =
        this.postResize$.pipe(
            map(({shape, transformation}) =>
                this.getNewResize(shape, transformation),
            ),
        );

    constructor(
        private item: ArtBoardItem<TMeta>,
        private readonly controller: ArtBoardItemTransformController<TMeta>,
    ) {
    }

    public get canResizeSnap(): boolean {
        return false;
    }

    getNewResizeDimension(
        shape: ArtBoardItemKonvaNode,
        transformation: ResizeTransformation,
    ): SizeChange {
        const scale = shape.scale() ?? {
            x: 1,
            y: 1,
        };
        const attrs: SizeChange = {
            width: shape.width() * scale.x,
            height: shape.height() * scale.y,
            x: shape.absolutePosition().x,
            y: shape.absolutePosition().y,
        };
        if (!this.canResizeSnap) {
            return attrs;
        }

        if (transformation.event.altKey || transformation.event.shiftKey) {
            return attrs;
        }

        const useVerticalGuidelines =
            transformation.type === 'widthChange' ||
            transformation.type === 'sizeChange';
        const useHorizontalGuidelines =
            transformation.type === 'heightChange' ||
            transformation.type === 'sizeChange';
        const guides = this.controller
            .drawAndGetGuides(
                useHorizontalGuidelines,
                useVerticalGuidelines,
                transformation.handler,
            )
            .sort((a, b) => a.offset - b.offset);

        const oldAttrs = {...attrs};
        const handler = transformation.handler;
        for (const lg of guides) {
            switch (lg.orientation) {
                case 'H':
                    // multi or height change
                    if (handler.vertical === 'top' && lg.snap === 'start') {
                        attrs.y = lg.lineGuide;
                        attrs.height =
                            oldAttrs.y + oldAttrs.height - lg.lineGuide;
                        if (attrs.height < MIN_SIZE) {
                            attrs.y = lg.lineGuide - MIN_SIZE;
                            attrs.height = MIN_SIZE;
                        }
                    }
                    if (handler.vertical === 'bottom' && lg.snap === 'end') {
                        attrs.height = lg.lineGuide - oldAttrs.y;
                        if (attrs.height < MIN_SIZE) {
                            attrs.height = MIN_SIZE;
                        }
                    }

                    // keep aspect ratio if required
                    /*
                    if (attrs.aspectRatio) {
                        attrs.width = attrs.aspectRatio * attrs.height;
                        if (handler.horizontal === 'left') {
                            attrs.x = oldAttrs.x + oldAttrs.width - attrs.width;
                        }
                    }
                    */
                    break;
                case 'V':
                    // multi or width change
                    if (handler.horizontal === 'left' && lg.snap === 'start') {
                        attrs.x = lg.lineGuide;
                        attrs.width =
                            oldAttrs.x + oldAttrs.width - lg.lineGuide;
                        if (attrs.width < MIN_SIZE) {
                            attrs.x = lg.lineGuide - MIN_SIZE;
                            attrs.width = MIN_SIZE;
                        }
                    }
                    if (handler.horizontal === 'right' && lg.snap === 'end') {
                        attrs.width = lg.lineGuide - oldAttrs.x;
                        if (attrs.width < MIN_SIZE) {
                            attrs.width = MIN_SIZE;
                        }
                    }

                    // keep aspect ratio if required
                    /*
                    if (attrs.aspectRatio) {
                        attrs.height = attrs.width / attrs.aspectRatio;
                        if (handler.vertical === 'top') {
                            attrs.y = oldAttrs.y + oldAttrs.height - attrs.height;
                        }
                    }
                    */
                    break;
            }
        }
        return {
            width: attrs.width,
            height: attrs.height,
            x: attrs.x,
            y: attrs.y,
        };
    }

    getNewResize(
        shape: ArtBoardItemKonvaNode,
        transformation: ResizeTransformation,
    ): SizeChange {
        this.controller.destroyGuidelines();
        if (transformation.event.altKey) {
            const absolutePosition = shape.absolutePosition();
            return {
                width: shape.width() * shape.scaleX(),
                height: shape.height() * shape.scaleY(),
                x: absolutePosition.x,
                y: absolutePosition.y,
            };
        }

        return this.getNewResizeDimension(shape, transformation);
    }
}

export class ArtBoardItemTransformController<TMeta> {
    public readonly resizer: BlockTransformResizeController<TMeta>;
    public readonly rotator: BlockTransformRotateController<TMeta>;
    public readonly dragger: BlockTransformDragController<TMeta>;

    private readonly activeTransformerSubject =
        new BehaviorSubject<ArtBoardTransformer<TMeta> | null>(null);
    public readonly activeTransformer$ =
        this.activeTransformerSubject.asObservable();

    constructor(private readonly events: BlockEventController<TMeta>) {
        this.resizer = new BlockTransformResizeController(events.item, this);
        this.rotator = new BlockTransformRotateController(events.item, this);
        this.dragger = new BlockTransformDragController(events.item, this);
    }

    public get item(): ArtBoardItem<TMeta> {
        return this.events.item;
    }

    public readonly transformationEnd$: Observable<'transformend' | 'dragend'> = merge(
        this.watchEvent('transformend', () => 'transformend' as const),
        this.watchEvent('dragend', () => 'dragend' as const),
    );

    public readonly transform$: Observable<Transformation | null> = this.watchEvent(
        'transform dragmove',
        (shape, transformer) => transformer.current,
    );

    onTransformationEnd(redraw = true, relativeZIndex: RelativeZIndex = 0): void {
        this.destroyGuidelines();
        this.events.notifyUpdate(relativeZIndex);

        if (redraw) {
            this.layer?.batchDraw();
        }
    }

    private watchEvent<T>(
        eventName: string,
        mapFn: (
            shape: ArtBoardItemKonvaNode,
            transformer: ArtBoardTransformer<TMeta>,
            eventType: string
        ) => T,
    ): Observable<T> {
        return combineLatest([this.item.shape$, this.activeTransformer$]).pipe(
            switchMap(([shape, transformer]) => {
                if (!shape || !transformer) {
                    return NEVER;
                }
                return fromEvent<KonvaEventObject<unknown>>(shape, eventName).pipe(
                    switchMap((event) =>
                        transformer ? of(mapFn(shape, transformer, event.type)) : NEVER,
                    ),
                );
            }),
        );
    }

    public start(transformer: ArtBoardTransformer<TMeta>): void {
        if (transformer) {
            transformer.select(this.item);
        }
        this.activeTransformerSubject.next(transformer);
    }

    finish(): void {
        this.activeTransformerSubject.next(null);
    }

    public get currentTransformer(): ArtBoardTransformer<TMeta> | null {
        return this.activeTransformerSubject.value;
    }

    getStageGuideLinesStops(): GuideLinesStops {
        const shape = this.item.shape;
        const stage = this.stage;
        if (!stage || !shape) {
            return {horizontal: [], vertical: []};
        }

        const vertical: number[] = [];
        const horizontal: number[] = [];

        // and we snap over edges and center of each object on the canvas
        stage.find('.art-board-item').forEach((guideItem) => {
            if (guideItem === shape) {
                return;
            }
            const box = guideItem.getClientRect();
            vertical.push(...[box.x, box.x + box.width, box.x + box.width / 2]);
            horizontal.push(
                ...[box.y, box.y + box.height, box.y + box.height / 2],
            );
        });
        return {vertical, horizontal};
    }

    getGuides(
        horizontal = true,
        vertical = true,
        handler?: ResizeHandler,
    ): GuideLine[] {
        const stage = this.stage;
        if (!stage) {
            Logger.warnStyled(
                'BLOCK-EDITOR',
                'Tried to get guides without a stage',
            );
            return [];
        }

        const lineGuideStops: GuideLinesStops = this.getStageGuideLinesStops();
        const itemBounds: ObjectBounds = this.getBounds(handler);

        const resultV: GuideLineTemp[] = [];
        const resultH: GuideLineTemp[] = [];

        if (vertical) {
            lineGuideStops.vertical.forEach((lineGuide) => {
                itemBounds.vertical.forEach((itemBound) => {
                    const diff =
                        Math.abs(lineGuide - itemBound.guide) / stage.scaleY();
                    // only consider guidelines close enough to the shape
                    if (diff < GUIDELINE_OFFSET) {
                        resultV.push({
                            lineGuide,
                            diff,
                            snap: itemBound.snap,
                            offset: itemBound.offset,
                        });
                    }
                });
            });
        }

        if (horizontal) {
            lineGuideStops.horizontal.forEach((lineGuide) => {
                itemBounds.horizontal.forEach((itemBound) => {
                    const diff =
                        Math.abs(lineGuide - itemBound.guide) / stage.scaleX();
                    // only consider guidelines close enough to the shape
                    if (diff < GUIDELINE_OFFSET) {
                        resultH.push({
                            lineGuide,
                            diff,
                            snap: itemBound.snap,
                            offset: itemBound.offset,
                        });
                    }
                });
            });
        }

        const guides: GuideLine[] = [];

        // find closest snap
        const minV = resultV.sort((a, b) => a.diff - b.diff)[0];
        const minH = resultH.sort((a, b) => a.diff - b.diff)[0];
        if (minV) {
            guides.push({
                lineGuide: minV.lineGuide,
                offset: minV.offset,
                orientation: 'V',
                snap: minV.snap,
            });
        }
        if (minH) {
            guides.push({
                lineGuide: minH.lineGuide,
                offset: minH.offset,
                orientation: 'H',
                snap: minH.snap,
            });
        }

        if (!guides.length) {
            return [];
        }

        return guides;
    }

    drawAndGetGuides(
        horizontal = true,
        vertical = true,
        resizeHandler?: ResizeHandler,
    ): GuideLine[] {
        const layer = this.layer;
        const stage = this.stage;
        const guides = this.getGuides(horizontal, vertical, resizeHandler);
        if (!layer || !stage) {
            return guides;
        }

        const style = {
            stroke: 'rgb(255,0,72)',
            strokeWidth: 1 / stage.scaleX(),
            name: 'guide-line',
            dash: [4 / stage.scaleY(), 6 / stage.scaleY()],
        };

        // draw guidelines
        let line;
        guides.forEach((lg) => {
            if (lg.orientation === 'H') {
                line = new Konva.Line({
                    points: [-6000, 0, 6000, 0],
                    ...style,
                });
                layer.add(line);
                line.absolutePosition({
                    x: 0,
                    y: lg.lineGuide,
                });
            } else if (lg.orientation === 'V') {
                line = new Konva.Line({
                    points: [0, -6000, 0, 6000],
                    ...style,
                });
                layer.add(line);
                line.absolutePosition({
                    x: lg.lineGuide,
                    y: 0,
                });
            }
        });
        layer.batchDraw();
        return guides;
    }

    get layer(): Konva.Layer | null {
        return this.item.shape?.getLayer() ?? null;
    }

    get stage(): Konva.Stage | null {
        return this.item.shape?.getStage() ?? null;
    }

    destroyGuidelines(): void {
        if (!this.layer) {
            return;
        }
        this.layer.find('.guide-line').forEach((n) => n.destroy());
    }

    /**
     * Get this shape edges in absolute coordinates
     * @param at Optional transformation to apply to the edges
     *
     * @returns An array of points in absolute coordinates
     */
    getBounds(at?: ResizeHandler): ObjectBounds {
        const node = this.item.shape;
        if (!node) {
            return {
                horizontal: [],
                vertical: [],
            };
        }
        const box = node.getClientRect();
        const absPos = node.absolutePosition();

        const bounds: ObjectBounds = {
            vertical: [],
            horizontal: [],
        };
        if (!at || at.vertical === 'top') {
            bounds.horizontal.push({
                guide: Math.round(box.y),
                offset: Math.round(absPos.y - box.y),
                snap: 'start',
            });
        }
        if (!at) {
            bounds.horizontal.push({
                guide: Math.round(box.y + box.height / 2),
                offset: Math.round(absPos.y - box.y - box.height / 2),
                snap: 'center',
            });
        }
        if (!at || at.vertical === 'bottom') {
            bounds.horizontal.push({
                guide: Math.round(box.y + box.height),
                offset: Math.round(absPos.y - box.y - box.height),
                snap: 'end',
            });
        }
        if (!at || at.horizontal === 'left') {
            bounds.vertical.push({
                guide: Math.round(box.x),
                offset: Math.round(absPos.x - box.x),
                snap: 'start',
            });
        }
        if (!at) {
            bounds.vertical.push({
                guide: Math.round(box.x + box.width / 2),
                offset: Math.round(absPos.x - box.x - box.width / 2),
                snap: 'center',
            });
        }
        if (!at || at.horizontal === 'right') {
            bounds.vertical.push({
                guide: Math.round(box.x + box.width),
                offset: Math.round(absPos.x - box.x - box.width),
                snap: 'end',
            });
        }
        return bounds;
    }
}
