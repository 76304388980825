import {Injectable} from '@angular/core';
import {FirestoreService} from './services';
import {
    CollectionKey,
    DatabaseService,
    DocumentKey,
    NirbyCollection,
    NirbyCollectionReference,
    NirbyDocument,
    NirbyDocumentReference,
    NirbyTransaction,
    QueryBuilder,
} from '@nirby/store/base';
import {deleteField, serverTimestamp} from 'firebase/firestore';

@Injectable({
    providedIn: 'root',
})
/**
 * Service that selects the correct database (Firestore or AceBase) based on the collection key.
 */
export class Database {
    /**
     * Constructor.
     * @param remote The remote database (Firestore).
     * @param local The local database (AceBase).
     */
    constructor(
        protected readonly remote: FirestoreService,
    ) {
    }

    /**
     * Gets the collection with the given keys.
     * If the collection key is local, the local collection is returned.
     * @param keys The keys to the collection.
     *
     * @returns The collection.
     */
    public collection<T extends object, SyncContext extends object>(
        ...keys: [...DocumentKey<object>[], CollectionKey<T, SyncContext>]
    ): NirbyCollection<T> {
        return this.remote.collection(...keys);
    }

    /**
     * Runs a transaction.
     * @param fn The function to run.
     *
     * @returns The result of the transaction.
     */
    async runTransaction<T = void>(
        fn: (transaction: NirbyTransaction) => T | Promise<T>,
    ): Promise<T> {
        return this.remote.runTransaction<T>(async (rt) => fn(rt));
    }

    /**
     * Generates a new ID.
     *
     * @returns The new ID.
     */
    generateId(): string {
        return this.remote.generateId();
    }

    /**
     * Gets the server timestamp.
     *
     * @returns The server timestamp.
     */
    serverTimestamp(): Date {
        return serverTimestamp() as unknown as Date;
    }

    /**
     * Deletes a field.
     *
     * @returns The deleted field.
     */
    deleteField(): undefined {
        return deleteField() as unknown as undefined;
    }

    /**
     * Gets the database for a given collection or document reference.
     * @param ref The collection or document reference.
     *
     * @returns The database.
     */
    databaseFor<T extends object>(
        ref: NirbyCollectionReference<T> | NirbyDocumentReference<T> | CollectionKey<T> | DocumentKey<T>,
    ): DatabaseService<object> {
        return this.remote;
    }

    /**
     * Gets a document reference from a path.
     * @param path The path to the document.
     * @param key The key of the document.
     *
     * @returns The document reference.
     */
    fromPath<T extends object>(
        path: string,
        key: CollectionKey<T>,
    ): NirbyDocumentReference<T> {
        return this.databaseFor(key).docFromPath(
            path,
            key,
        );
    }

    /**
     * Queries a collection group.
     * @param key The key of the collection.
     *
     * @returns The query builder.
     */
    queryGroup<T extends object>(key: CollectionKey<T>): QueryBuilder<T> {
        return this.databaseFor(key).queryGroup(key);
    }

    /**
     * Gets many documents from an array of references.
     * @param transaction The transaction.
     * @param references The references.
     *
     * @returns The documents.
     */
    getManyTransaction<T extends object>(
        transaction: NirbyTransaction,
        references: NirbyDocumentReference<T>[],
    ): Promise<(NirbyDocument<T> | null)[]> {
        return this.databaseFor(references[0]).getManyTransaction(transaction, references);
    }
}
