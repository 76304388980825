import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EditInPlaceComponent} from './edit-in-place/edit-in-place.component';
import {FormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';


@NgModule({
    declarations: [
        EditInPlaceComponent
    ],
    exports: [
        EditInPlaceComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        FontAwesomeModule
    ]
})
/**
 * Edit in place module.
 */
export class EditInPlaceModule {
}

export {EditInPlaceComponent} from './edit-in-place/edit-in-place.component';
