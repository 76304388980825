<div class="container">
    <div class="nirby-card p-0 row">
        <aside class="component-sidebar col-md-3 p-0">
            <div
                class="component-sidebar-inner d-flex flex-column justify-content-between h-100"
            >
                <header class="area-top p-3">
                    <h1 class="main-title">Preferences</h1>
                    <div class="main-tab" *ngIf="widgetId">
                        <a
                            class="main-tab-item me-2"
                            routerLinkActive="active"
                            [routerLink]="[
                                '/workspaces',
                                widgetId,
                                'settings',
                                'personal'
                            ]"
                        >Personal</a
                        >
                        <a
                            class="main-tab-item me-2"
                            routerLinkActive="active"
                            [routerLink]="[
                                '/workspaces',
                                widgetId,
                                'settings',
                                'widget'
                            ]"
                        >Workspace</a
                        >
                    </div>
                </header>

                <div class="area-middle flex-1">
                    <div class="list-group">
                        <a
                            class="list-group-item list-group-item-action border-0 d-flex justify-content-between align-items-center"
                            routerLinkActive="active"
                            [routerLink]="'general'"
                        >
                            General Information
                        </a>
                        <a
                            class="list-group-item list-group-item-action border-0 d-flex justify-content-between align-items-center"
                            routerLinkActive="active"
                            [routerLink]="'billing'"
                        >
                            Billing
                        </a>
                    </div>
                </div>
                <footer class="area-bottom p-3">
                    <p class="text-color-grey-light font-size-xs">
                        Software Version: {{ version }}
                        <span class="badge badge-dark badge-beta ms-3"
                        >Beta</span
                        >
                    </p>
                    <a
                        href="https://forms.gle/gVata2muWrxVyMhj7"
                        target="_blank"
                        id="btn-contact"
                        class="btn btn-link btn-sm px-0 btn-block text-left"
                    >
                        Need help? Contact Us
                    </a>
                    <a
                        href="https://discord.gg/hpPqnTsYfH"
                        target="_blank"
                        id="btn-join-community"
                        class="btn btn-primary btn-sm btn-block text-left d-flex justify-content-between align-items-center"
                    >
                        <span class="text flex-1">Or Join the Community</span>
                        <fa-icon [icon]="iconComments"></fa-icon>
                    </a>
                </footer>
            </div>
        </aside>
        <div class="component-content col-md-9 p-0">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
