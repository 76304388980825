<div class="list-plan-inner p-4 d-flex flex-column justify-content-between flex-1"
     *ngIf="planGroup$ | async as planGroup">
    <span
        class="plan-name d-block py-2 px-4 text-center text-white"
        [ngStyle]="{'background-color': tableStyle.primaryColor}"
        *ngIf="label"
    >
        {{label}}
    </span>
    <div class="price-selector w-100 d-flex flex-column justify-content-center">
        <div class="price text-center mt-3">
            <ng-container *ngIf="planGroup.items; else letsTalk">
                <ng-container *ngIf="(selectedItem?.price?.data | priceSelect)?.perMonth as price">
                    <span class="currency">
                $
            </span>
                    <span class="num" id="plan-startup-pricing">
                        {{price.intPart}}.{{price.decPart | number: '2.0-0'}} {{price.currency | uppercase}}
                    </span>
                    <span class="period">/mo</span>
                </ng-container>
            </ng-container>
            <ng-template #letsTalk>
                <span class="title">Let's talk</span>
            </ng-template>
        </div>
        <ng-container *ngIf="planGroup.items">
            <div class="form-group mb-2">
                <nirby-slider-select
                    [defaultOptionIndex]="0"
                    [options]="planGroup.items"
                    [(ngModel)]="selectedItem"
                ></nirby-slider-select>
            </div>
        </ng-container>
    </div>
    <div>
        <ng-container *ngIf="planGroup.items && selectedItem">
            <button
                *ngIf="!currentSubscription"
                class="btn btn-outline-dark btn-block"
                (click)="createCheckoutSession(selectedItem.price)"
            >
                Subscribe
            </button>
            <ng-container *ngIf="currentSubscription">
                <button
                    *ngIf="comparePrice(currentSubscription, selectedItem.plan, selectedItem.price) as planComparison"
                    class="btn btn-outline-dark btn-block"
                    [disabled]="planComparison === 'current'"
                    (click)="updateSubscription(selectedItem.price)"
                >
                    <ng-container [ngSwitch]="planComparison">
                        <ng-container *ngSwitchCase="'change'">
                            Change plan
                        </ng-container>
                        <ng-container *ngSwitchCase="'upgrade'">
                            Upgrade
                        </ng-container>
                        <ng-container *ngSwitchCase="'downgrade'">
                            Downgrade
                        </ng-container>
                        <ng-container *ngSwitchCase="'current'">
                            Current plan
                        </ng-container>
                    </ng-container>
                </button>
            </ng-container>
        </ng-container>
        <a
            *ngIf="!planGroup.items"
            class="btn btn-outline-dark btn-block"
            href="https://forms.gle/4tqnNEb2nm7UVUmC6"
            target="_blank"
        >
            Schedule Demo
        </a>
    </div>
    <div class="usage my-3" *ngIf="selectedItem || !planGroup.items">
        <h2 class="title-separator text-center">
            Plan Usage
        </h2>
        <div class="list-usage text-center">
            <nirby-plan-table-item-usage-item
                label="Video Storage"
                [maximumValue]="selectedItem?.limits?.videoStorage"
                [color]="tableStyle.secondaryColor"
                pTooltip="How many minutes of video will you be able to store on Nirby in total."
                unit="Hrs."
            ></nirby-plan-table-item-usage-item>
            <nirby-plan-table-item-usage-item
                label="Video Upload Quota"
                [maximumValue]="selectedItem?.limits?.videoUploadQuota"
                [color]="tableStyle.secondaryColor"
                pTooltip="How many minutes of video a month will you be able to publish on Nirby."
                unit="Hrs. / mo"
            ></nirby-plan-table-item-usage-item>
            <nirby-plan-table-item-usage-item
                label="Video Delivery Quota"
                [maximumValue]="selectedItem?.limits?.videoDeliveryQuota"
                [color]="tableStyle.secondaryColor"
                pTooltip="How many minutes will your users be able to watch your interactive video-experiences every month."
                unit="Hrs. / mo"
            ></nirby-plan-table-item-usage-item>
        </div>
    </div>
    <div class="includes mt-3 flex-1 d-flex flex-column justify-content-between">
        <h2 class="title-separator text-center">Includes</h2>
        <div class="list-includes p-3 text-center flex-1">
            <ng-container *ngFor="let featureGroup of features">
                <div class="list-includes-item separator mt-3" *ngIf="featureGroup.title">
                    {{featureGroup.title}}
                </div>
                <div class="list-includes-item" *ngFor="let feature of featureGroup.features">
                    {{feature}}
                </div>
            </ng-container>
        </div>
    </div>
</div>
