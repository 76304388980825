import { DesignableBlock } from '../block-designable';
import { TextBlock } from '@nirby/models/nirby-player';
import { DesignSet } from '../../designs';
import { ShapeUnitRawDescription } from '../../designs';
import Konva from 'konva';
import { Subscription } from 'rxjs';
import {loadFont} from '../../utils';

const defaultTextDesign: ShapeUnitRawDescription<'Text'> = {
    shape: 'Text',
    axis: {
        horizontal: 'stretch',
        vertical: 'stretch',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    attributes: {
        text: {
            method: 'GetBlockContent',
            arguments: ['text'],
        },
        fontFamily: {
            method: 'GetBlockContent',
            arguments: ['fontFamily'],
        },
        fill: {
            method: 'GetBlockContent',
            arguments: ['color'],
        },
        align: {
            method: 'GetBlockContent',
            arguments: ['alignment'],
        },
        verticalAlign: {
            method: 'GetBlockContent',
            arguments: ['verticalAlign'],
        },
        fontStyle: {
            method: 'GetBlockContent',
            arguments: ['style'],
        },
        textDecoration: {
            method: 'GetBlockContent',
            arguments: ['decoration'],
        },
        fontSize: {
            method: 'GetBlockContent',
            arguments: ['fontScale'],
        },
        lineHeight: {
            method: 'GetBlockContent',
            arguments: ['lineHeight'],
        },
    },
    children: [],
};

/**
 * Text block drawable
 */
export class TextBlockDrawable<TMeta> extends DesignableBlock<TMeta, TextBlock> {
    override MIN_WIDTH = 16;
    override MIN_HEIGHT = 16;
    designSet: DesignSet = {
        default: defaultTextDesign,
    };

    fontSize = 10;

    /**
     * default
     * @protected
     *
     * @returns - 'default'
     */
    protected getCurrentDesignId(): string {
        return 'default';
    }

    /**
     * Subscribe to events
     * @param shape - Konva shape
     * @protected
     *
     * @returns - Subscription
     */
    protected override subscribeToEvents(shape: Konva.Group): Subscription {
        const subscription = super.subscribeToEvents(shape);
        if (this.editable) {
            subscription.add(
                this.transformController.resizer.postResize$.subscribe(
                    ({ transformation }) => {
                        if (transformation.type === 'sizeChange') {
                            this.fontSize *= shape.scaleY();
                        }
                    }
                )
            );
        }
        return subscription;
    }

    /**
     * Loads the variables into the text.
     * @param options The variables to load.
     * @protected
     */
    protected override preprocess(options: TextBlock): void {
        options.content.text = this.global.transform(options.content.text);
    }

    /**
     * Preloads the font used by the text block.
     *
     * @returns - A promise that resolves when the font has been loaded.
     */
    override preload(): Promise<void> {
        return loadFont(this.properties.content.fontFamily, this.properties.content.style).then();
    }
}
