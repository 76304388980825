import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {MediaLibraryRoutingModule} from './media-library-routing.module';
import {
    ImageSearchComponent,
    ImageSelectorComponent,
    ImageUploadComponent,
    ImageUrlInputComponent,
    MediaLibraryImagesComponent,
    MediaLibraryVideosComponent,
} from './components';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NirbyCommonModule} from '@nirby/shared/widgets';
import {NgbNavModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedSanitizersModule} from '@nirby/shared/sanitizers';
import {CfThumbnailPipe} from './pipes/cf-thumbnail.pipe';
import {MediaLibraryComponent} from './views/media-library/media-library.component';


@NgModule({
    declarations: [
        MediaLibraryImagesComponent,
        ImageSearchComponent,
        ImageSelectorComponent,
        ImageUploadComponent,
        ImageUrlInputComponent,
        MediaLibraryVideosComponent,
        MediaLibraryComponent,
        CfThumbnailPipe,
        MediaLibraryVideosComponent,
    ],
    exports: [
        MediaLibraryImagesComponent,
        ImageSelectorComponent,
    ],
    imports: [
        CommonModule,
        MediaLibraryRoutingModule,
        FontAwesomeModule,
        FormsModule,
        NirbyCommonModule,
        NgbNavModule,
        SharedSanitizersModule,
        ReactiveFormsModule,
    ],
})
export class MediaLibraryModule {
}
