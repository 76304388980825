<div class="p-3">
    <ng-container
        *ngIf="{displayedVideos: displayedVideos$ | async, formGroup: formGroup$ | async} as items; else loading"
    >
        <div
            class="videos-grid"
            *ngIf="items.displayedVideos !== null && items.formGroup !== null"
        >
            <div class="alert alert-info flex-1 w-100" *ngIf="items.displayedVideos.length === 0">
                <p class="mb-0">You haven't uploaded any file yet.</p>
            </div>

            <div
                class="input-group mb-2"
                [formGroup]="items.formGroup"
                *ngFor="let media of items.displayedVideos"
            >
                <div class="item">
                    <div class="thumb">
                        <img
                            [src]="(media.data.data.preview | cfThumbnail) | safeUrl"
                            [alt]="media.data.data.preview"
                            *ngIf="media.data.data"
                        />
                    </div>
                    <div class="actions">
                        <button
                            class="btn btn-link text-white"
                            (click)="
                                delete(media);
                                $event.preventDefault();
                                $event.stopPropagation();
                            "
                        >
                            <fa-icon [icon]="faDelete"></fa-icon>
                        </button>
                    </div>
                </div>
                <input type="text" [formControlName]="media.id" class="form-control mt-2" placeholder="Name">
            </div>
        </div>
    </ng-container>
    <ng-template #loading>
        <div class="spinner-border text-primary"></div>
    </ng-template>
</div>
