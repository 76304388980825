import {Component} from '@angular/core';
import {FormGroup, UntypedFormControl} from '@angular/forms';
import {BlockActionComponent} from '../../blocks/block-action.component';
import {VariableOperation, VariableUpdateAction} from '@nirby/models/nirby-player';
import {AppVariable} from '@nirby/models/editor';
import {Observable, switchMap} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {NirbyVariablesService} from '@nirby/shared/database';
import {NirbyDocument} from '@nirby/store/base';
import {DocumentLike} from '@nirby/store/models';

interface VariableOperationOption {
    value: VariableOperation;
    label: string;
    type: AppVariable['type'];
}

@Component({
    selector: 'nirby-action-variable-update',
    templateUrl: './action-variable-update.component.html',
    styleUrls: ['./action-variable-update.component.scss'],
})
export class ActionVariableUpdateComponent extends BlockActionComponent<VariableUpdateAction> {
    public readonly operationControl = new UntypedFormControl('set');
    public readonly variableControl = new UntypedFormControl(null);
    public readonly amountControl = new UntypedFormControl(0);

    public readonly operations: VariableOperationOption[] = [
        {
            value: 'set',
            label: 'Set',
            type: 'string',
        },
        {
            value: 'add',
            label: 'Add',
            type: 'number',
        },
        {
            value: 'subtract',
            label: 'Subtract',
            type: 'number',
        },
    ];
    public selectedOperation$: Observable<VariableOperationOption | null> =
        this.operationControl.valueChanges.pipe(
            startWith(this.operationControl.value),
            map(
                (value: VariableOperation) =>
                    this.operations.find(
                        (operation) => operation.value === value,
                    ) ?? null,
            ),
        );

    constructor(private readonly variables: NirbyVariablesService) {
        super();
    }

    form = new FormGroup({
        operation: this.operationControl,
        amount: this.amountControl,
        variable: this.variableControl,
    });

    public readonly variables$ = this.variables.availableVariables$.pipe(
        switchMap((variables) =>
            this.variableControl.valueChanges.pipe(
                startWith(this.variableControl.value),
                map((selectedVariable: string) => ({
                    selectedVariable,
                    variables: variables as DocumentLike<AppVariable>[],
                })),
            ),
        ),
        map(({selectedVariable, variables}) => {
            const variable = variables.find(
                (variable) =>
                    'global.' + variable.data.name === selectedVariable,
            );
            if (selectedVariable && !variable) {
                const workspaceId =
                    this.variables.productRef?.workspaceRef.id ?? null;
                if (!workspaceId) {
                    throw new Error('No workspace id');
                }
                variables = [
                    ...variables,
                    NirbyDocument.createLocal<AppVariable>(
                        {
                            initialValue: '',
                            name: this.removeGlobalPrefix(selectedVariable),
                            displayName: '(variable deleted)',
                            source: 'query',
                            type: 'string',
                            usedBy:
                                this.variables.productRef?.ref ??
                                (null as never),
                        },
                        this.variables.collection(workspaceId),
                    ),
                ];
            }
            return variables;
        }),
    );
    public readonly selectedVariable$: Observable<DocumentLike<AppVariable> | null> =
        this.variables$.pipe(
            // after getting the array of variables, load the selected variable
            switchMap((variables) =>
                this.variableControl.valueChanges.pipe(
                    startWith(this.variableControl.value),
                    map((selectedVariable: string) => ({
                        selectedVariable,
                        variables,
                    })),
                ),
            ),
            // find in the variables array the selected variable
            map(({selectedVariable, variables}) => {
                return (
                    variables.find(
                        (variable) =>
                            'global.' + variable.data.name === selectedVariable,
                    ) ?? null
                );
            }),
        );

    removeGlobalPrefix(name: string): string {
        return name.replace(/^global\./, '');
    }

    setData(content: VariableUpdateAction | null): void {
        if (content) {
            this.operationControl.setValue(content.options.operation);
            this.variableControl.setValue(content.options.variable);
            this.amountControl.setValue(content.options.amount);
        }
    }
}
