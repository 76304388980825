import {IMAGE_RECORD_SCHEMA, ImageRecord} from '@nirby/media-models';
import {APP_CARD_MIGRATOR, AppCard} from '../cards';
import {Directory, DIRECTORY_MIGRATOR} from '../directory.model';
import {Vector2d} from '@nirby/models/nirby-player';
import {z} from 'zod';
import {v4} from 'uuid';
import {Migrator} from '@nirby/store/migrator';
import {ZodSchemaBuild} from '@nirby/store/models';
import {buildUploadedReferenceSchema} from '@nirby/store/proxy';
import {NirbyDocumentReference} from '@nirby/store/base';

export type PopStatus = 'active' | 'archived' | 'deleted';

export interface DirectoryPop {
    ref: NirbyDocumentReference<Directory>;
    name: string;
}

export type PopperDesign = 'small' | 'standard' | 'large';

export interface Popper {
    body: string;
    buttonLabel: string;
    imageSrc: ImageRecord | null | string;
    design: PopperDesign;
    margin: Vector2d;
    openingStyle: PopOpeningStyle;
}

export const POPPER_SCHEMA: ZodSchemaBuild<Popper> = z
    .object({
        body: z.string().default(''),
        buttonLabel: z.string().default(''),
        openingStyle: z.union([
            z.literal('fullscreen'),
            z.literal('bottom-right'),
        ]),
        imageSrc: z
            .union([z.string().nullable(), IMAGE_RECORD_SCHEMA])
            .default(null),
        design: z
            .union([
                z.literal('small'),
                z.literal('standard'),
                z.literal('large'),
            ])
            .default('standard'),
        margin: z.object({
            x: z.number().default(0),
            y: z.number().default(0),
        }),
    })
    .catch(
        (): Popper => ({
            body: '',
            buttonLabel: '',
            imageSrc: null,
            design: 'standard',
            openingStyle: 'bottom-right',
            margin: {
                x: 20,
                y: 20,
            },
        }),
    );

export interface Status {
    name: string;
    status: PopStatus;
    quantity: number;
}

export interface Pop {
    emoji: string | null;
    hash: string;
    title: string;
    welcome: Popper;
    status: PopStatus;
    image: ImageRecord | null;
    directory: DirectoryPop | null;
    initialCard: NirbyDocumentReference<AppCard> | null;
}

export type PopOpeningStyle = 'fullscreen' | 'bottom-right';

export const POP_SCHEMA: ZodSchemaBuild<Pop> = z.object({
    emoji: z.string().nullable().default(null),
    hash: z.string().catch(() => v4()),
    title: z.string().default('Untitled Pop'),
    welcome: POPPER_SCHEMA,
    status: z
        .union([
            z.literal('active'),
            z.literal('archived'),
            z.literal('deleted'),
        ])
        .default('active'),
    image: IMAGE_RECORD_SCHEMA.nullable().default(null),
    directory: z
        .object({
            ref: buildUploadedReferenceSchema<Directory>(
                () => DIRECTORY_MIGRATOR,
            ),
            name: z.string().default(''),
        })
        .nullable()
        .default(null),
    initialCard: buildUploadedReferenceSchema<AppCard>(() => APP_CARD_MIGRATOR)
        .nullable()
        .default(null),
    openingStyle: z
        .union([z.literal('fullscreen'), z.literal('bottom-right')])
        .catch((): PopOpeningStyle => 'bottom-right'),
});

export const POP_MIGRATOR = new Migrator<Pop>(POP_SCHEMA, () => ({
    emoji: null,
    hash: '',
    title: 'Title',
    welcome: {
        imageSrc: null,
        body: '¿Puedo ayudarte con algo? ¿Necesitas más info?',
        buttonLabel: '¡Si, por favor!',
        design: 'standard',
        margin: {
            x: 20,
            y: 20,
        },
        openingStyle: 'bottom-right',
    },
    status: 'active',
    image: null,
    directory: null,
    initialCard: null,
    openingStyle: 'bottom-right',
}));
