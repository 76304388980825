import {Component, Input} from '@angular/core';
import {AlertsService} from '@nirby/shared/alerts';
import {v4} from 'uuid';

@Component({
    selector: 'nirby-share-html',
    templateUrl: './share-html.component.html',
    styleUrls: ['./share-html.component.scss']
})
export class ShareHtmlComponent {
    @Input() label = 'Copy';
    @Input() debug = false;

    constructor(private readonly alerts: AlertsService) {
    }

    /**
     * Wait for element to be rendered
     * @param selector The selector of the element to wait for.
     *
     * @returns {Promise<void>} Promise
     */
    waitFor(selector: string): Promise<HTMLElement> {
        return new Promise(resolve => {
            const interval = setInterval(() => {
                const element = document.querySelector(selector) as HTMLElement;
                if (element) {
                    clearInterval(interval);
                    resolve(element);
                }
            }, 100);
        });
    }

    /**
     * Copies the HTML content to the clipboard.
     * @param element The element to copy.
     *
     * @returns - A promise that resolves when the copy operation is complete.
     */
    public async copy(element: HTMLElement): Promise<void> {
        if (window.ClipboardItem !== undefined) {
            // if the browser supports ClipboardItem, use it
            const blobInput = new Blob([element.innerHTML], {type: 'text/html'});
            const item = new ClipboardItem({
                'text/html': blobInput
            });
            await navigator.clipboard.write([item]);
        } else {
            // otherwise, use the hacky way

            // create div to copy
            const div = document.createElement('div');
            div.id = 'clipboard-' + v4();
            div.innerHTML = element.innerHTML;
            div.style.position = 'fixed';
            div.style.top = '-1000px'
            div.style.left = '-1000px';

            // add to body and wait for it to be rendered
            document.body.appendChild(div);
            await this.waitFor('#' + div.id);

            // select the content
            const selection = window.getSelection();
            if (!selection) {
                console.error('Unable to copy');
                return;
            }
            selection.removeAllRanges();

            const range = document.createRange();
            range.selectNode(div);
            selection.addRange(range);

            // copy the selected content
            // noinspection JSDeprecatedSymbols
            document.execCommand('copy');

            // remove the div
            document.body.removeChild(div);
        }

        // show toast
        this.alerts.showSuccess('Copied to clipboard');
    }
}
