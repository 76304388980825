import {
    AbstractControl,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';

export function optionalValidator(
    validators: (ValidatorFn | null | undefined)[] = []
): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null;
        }
        const composed = Validators.compose(validators);
        if (!composed) {
            return null;
        }
        return composed(control);
    };
}
