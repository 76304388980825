import { DateTime } from 'luxon';

export function isNowBefore(date: DateTime): boolean {
    return date.diffNow().milliseconds > 0;
}

export function isNowAfter(date: DateTime): boolean {
    return date.diffNow().milliseconds < 0;
}

export function isNowInsidePeriod(start: DateTime, end: DateTime): boolean {
    return isNowAfter(start) && isNowBefore(end);
}
