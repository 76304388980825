import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

import {DateTime} from 'luxon';
import {FieldValue, Timestamp} from '@angular/fire/firestore';

@Pipe({
    name: 'timestamp',
})
/**
 * Transform a timestamp to a human readable date
 */
export class TimestampPipe implements PipeTransform {
    /**
     * Constructor.
     * @param datePipe The Angular Date pipe.
     */
    constructor(private datePipe: DatePipe) {
    }

    /**
     * Transforms a timestamp or {{@link FieldValue}} to a date using the date pipe
     * @param value The value to transform
     * @param format The format to use
     * @param timezone The timezone to use
     * @param locale The locale to use
     *
     * @returns - The transformed value
     */
    transform(
        value: Timestamp | FieldValue | DateTime | Date,
        format?: string,
        timezone?: string,
        locale?: string,
    ): string | null {
        if (value instanceof Date) {
            value = DateTime.fromJSDate(value);
        }
        if (value instanceof DateTime) {
            return this.datePipe.transform(value.toJSDate(), format, timezone, locale);
        }
        const date = value instanceof Timestamp ? value.toDate() : Date.now();
        return this.datePipe.transform(date, format, timezone, locale);
    }
}
