<div *ngIf="goToOptions$ | async as options; else loading">
    <form [formGroup]="form">
        <div class="form-group">
            <select [formControl]="selectedCardController" class="form-control">
                <option [ngValue]="option.id" *ngFor="let option of options; trackBy: trackByFn">
                    {{option.name}}
                </option>
            </select>
        </div>
    </form>
</div>
<ng-template #loading>
    <div class="spinner spinner-border"></div>
</ng-template>
