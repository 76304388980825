import {Component, Input} from '@angular/core';
import {AppVariable} from '@nirby/models/editor';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DropdownItem} from '@nirby/shared/widgets';

@Component({
    selector: 'nirby-variable-selector',
    templateUrl: './variable-selector.component.html',
    styleUrls: ['./variable-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: VariableSelectorComponent,
            multi: true
        }
    ]
})
export class VariableSelectorComponent implements ControlValueAccessor {

    public value: string | null = null;

    public variablesItems: DropdownItem<string | null>[] = [];

    @Input() set variables(value: AppVariable[]) {
        this.variablesItems = value.map((v): DropdownItem<string> => {
            return {
                label: v.displayName,
                value: v.name
            }
        })
        this.variablesItems.unshift({
            label: 'None',
            value: null
        });
    }

    disabled = false;
    onChange: (v: string | null) => void = () => {
        return;
    }
    onTouched: () => void = () => {
        return;
    }

    registerOnChange(fn: (v: string | null) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: string | undefined | null): void {
        this.value = obj ?? null;
    }

    emitChange(newValue: string | null): void {
        this.value = newValue;
        this.onChange(this.value);
    }
}
