import {Component, Inject} from '@angular/core';
import {AuthenticationService} from '@nirby/shared/database';
import {CREATORS_ENVIRONMENT, NirbyEnvironment} from '@nirby/environment/base';
import {sendEmailVerification, User} from '@angular/fire/auth';

@Component({
    selector: 'nirby-settings-personal-general',
    templateUrl: './settings-personal-general.component.html',
    styleUrls: ['./settings-personal-general.component.scss'],
})
/**
 * The component for the general page of the personal settings.
 */
export class SettingsPersonalGeneralComponent {
    authenticatedUser$ = this.auth.user$;

    /**
     * Constructor.
     * @param environment The environment.
     * @param auth The authentication service.
     */
    constructor(
        @Inject(CREATORS_ENVIRONMENT) private environment: NirbyEnvironment,
        private auth: AuthenticationService,
    ) {
    }

    /**
     * Sends an email verification to the user.
     * @param user The user.
     *
     * @returns A promise that resolves when the email has been sent.
     */
    async sendVerificationEmail(user: User): Promise<void> {
        await sendEmailVerification(user);
    }
}
