import {IMAGE_RECORD_SCHEMA, ImageRecord} from '@nirby/media-models';
import {z} from 'zod';
import {Pop, POP_MIGRATOR, Workspace, WORKSPACE_MIGRATOR} from '@nirby/models/editor';
import {Migrator} from '@nirby/store/migrator';
import {NirbyDocument, NirbyDocumentReference} from '@nirby/store/base';
import {buildUploadedReferenceSchema} from '@nirby/store/proxy';
import {ZodSchemaBuild} from '@nirby/store/models';

export type LandPopStatus = 'active' | 'archived';

export interface BackgroundMedia {
    type: 'video' | 'image';
    src: ImageRecord | null;
}

export type LandPopLogoSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type ImagePosition =
    | 'left top'
    | 'left center'
    | 'left bottom'
    | 'right top'
    | 'right center'
    | 'right bottom'
    | 'center top'
    | 'center center'
    | 'center bottom';

interface LandPopLogo {
    src: ImageRecord | null;
    size: LandPopLogoSize;
    fit: 'contain' | 'cover';
    position: ImagePosition;
}

export interface LandPopContent {
    title: string;
    description: string;
    background: BackgroundMedia;
    backgroundColor: string;
    veilColor: string | null;
    logo: LandPopLogo;
}

export const LANDPOP_CONTENT_SCHEMA: ZodSchemaBuild<LandPopContent> = z.object({
    title: z.string(),
    description: z.string(),
    background: z.object({
        type: z.union([z.literal('video'), z.literal('image')]),
        src: IMAGE_RECORD_SCHEMA.nullable(),
    }),
    backgroundColor: z.string(),
    veilColor: z.string().nullable(),
    logo: z.object({
        src: IMAGE_RECORD_SCHEMA.nullable(),
        size: z.union([
            z.literal('xs'),
            z.literal('sm'),
            z.literal('md'),
            z.literal('lg'),
            z.literal('xl'),
        ]),
        fit: z.union([z.literal('contain'), z.literal('cover')]),
        position: z.union([
            z.literal('left top'),
            z.literal('left center'),
            z.literal('left bottom'),
            z.literal('right top'),
            z.literal('right center'),
            z.literal('right bottom'),
            z.literal('center top'),
            z.literal('center center'),
            z.literal('center bottom'),
        ]),
    }),
});

export interface LandPopItem {
    landpop: NirbyDocument<LandPop>;
    publishedPath: string | null;
}

export interface LandPop {
    title: string;
    path: string;
    emoji: string | null;
    status: LandPopStatus;
    directory: null;
    content: LandPopContent;
    pop: NirbyDocumentReference<Pop> | null;
    widget: NirbyDocumentReference<Workspace>;
}

export const LANDPOP_SCHEMA: ZodSchemaBuild<LandPop> = z.object({
    title: z.string(),
    path: z.string(),
    emoji: z.string().nullable(),
    status: z.union([z.literal('active'), z.literal('archived')]),
    directory: z.null(),
    content: LANDPOP_CONTENT_SCHEMA,
    pop: buildUploadedReferenceSchema(() => POP_MIGRATOR).nullable(),
    widget: buildUploadedReferenceSchema(() => WORKSPACE_MIGRATOR),
});

export const LANDPOP_MIGRATOR = new Migrator<LandPop>(LANDPOP_SCHEMA, () => ({
    content: {
        title: 'Title',
        description: 'Description',
        backgroundColor: '#20232a',
        veilColor: 'rgba(0,0,0,0.3)',
        background: {
            type: 'image',
            src: null,
        },
        logo: {
            src: null,
            size: 'md',
            fit: 'cover',
            position: 'center center',
        },
    },
    directory: null,
    emoji: null,
    path: '',
    pop: null,
    status: 'active',
    title: '',
    widget: null as never,
}));
