// noinspection JSUnusedGlobalSymbols

import Konva from 'konva';
import {
    BehaviorSubject,
    first,
    last,
    lastValueFrom,
    mergeScan,
    Observable,
    of,
    share,
    startWith,
    Subject,
    Subscription,
    tap,
} from 'rxjs';
import {
    ArtBoardItem,
    ArtBoardItemKonvaNode,
    ArtBoardItemKonvaParent,
    ArtBoardItemPropertiesBase,
} from './art-board-item';
import {AsyncChannel} from '@nirby/js-utils/async';
import {filter, map} from 'rxjs/operators';
import {ArtBoardItemFactory, NirbyBoardItemStandard, NirbyBoardStateNode} from './art-board-item-factory';
import {v4} from 'uuid';
import {Block} from './blocks';
import {ArtBoardItemTransformController} from './blocks/controllers/transform';
import {BlockEventController} from './blocks/controllers/events';
import {IRect} from 'konva/lib/types';
import {NirbyContext} from '@nirby/runtimes/context';
import {Container} from 'konva/lib/Container';
import {MapUtils} from './subjects';
import {ShapeCursorListener} from './shape-cursor-listener';
import {CanvasBackground, Card, NirbyPlayerConfig} from '@nirby/models/nirby-player';
import {Logger} from '@nirby/logger';
import Vector2d = Konva.Vector2d;

interface ArtBoardConfig extends ArtBoardItemPropertiesBase {
    mask: boolean;
    background: Readonly<Partial<CanvasBackground>>;
}

/**
 * An extension of {@link Konva.Group} where the group's client rect is limited by
 * the size of the board, and not its children.
 */
export class ArtBoardGroup extends Konva.Group {
    /**
     * Constructor.
     * @param config Konva container config
     * @param backgroundNode The background node
     */
    constructor(
        config: Konva.ContainerConfig,
        public readonly backgroundNode: Konva.Shape,
    ) {
        super(config);
        backgroundNode.moveTo(this);
    }

    /**
     * Get the client rect of the group.
     * @param config The config to use
     *
     * @returns - The client rect
     */
    override getClientRect(config?: {
        skipTransform?: boolean;
        skipShadow?: boolean;
        skipStroke?: boolean;
        relativeTo?: Konva.Container;
    }): IRect {
        return this.backgroundNode.getClientRect(config);
    }
}


/**
 * Class for drawing a card.
 */
export class ArtBoard<TMeta> implements ArtBoardItem<TMeta, Konva.Group, ArtBoardConfig> {
    private static readonly DEFAULT_BACKGROUND: Readonly<CanvasBackground> = {
        borderRadius: 0,
        fillColor: 'rgba(0,0,0,0)',
        strokeColor: 'rgba(0,0,0,0)',
    };

    type = 'art-board' as const;

    /**
     * If the art-board should keep the aspect ratio.
     * @constructor
     */
    get KEEP_ASPECT_RATIO(): boolean {
        return true;
    }

    private readonly creationChannel = new AsyncChannel();

    ANCHORS = ['bottom-left', 'bottom-right', 'top-right', 'top-left'];

    /**
     * Checks if the art board has been initialized.
     */
    get initialized(): boolean {
        return !!this.subscription;
    }

    /**
     * Gets the cursor that should be used when hovering over this item.
     */
    get cursorHover(): string | null {
        return null;
    }

    MIN_WIDTH = 0;

    MIN_HEIGHT = 0;
    public readonly context: NirbyContext;

    private readonly itemsSubject = new BehaviorSubject<ReadonlyArray<NirbyBoardStateNode<TMeta>>>([]);
    public readonly group: Konva.Group;
    public readonly groupChildren: Konva.Group;

    public readonly backgroundNode: Konva.Rect;

    /**
     * The art board's shape.
     */
    public get shape$(): Observable<Konva.Group> {
        return of(this.group);
    }

    private readonly loadedSubject = new Subject();

    private itemsById: Map<string, ArtBoardItem<TMeta>> = new Map();
    public readonly transformController: ArtBoardItemTransformController<TMeta>;
    public readonly eventsController: BlockEventController<TMeta>;

    public readonly controllerCursor: ShapeCursorListener<TMeta>;
    public readonly ID: string;
    private layer: Konva.Layer | null = null;
    private readonly parentSubject =
        new BehaviorSubject<ArtBoardItemKonvaParent | null>(null);

    /**
     * Blocks observable
     * @private
     */
    private readonly itemsInitializer$: Observable<Map<string, ArtBoardItem<TMeta>>> =
        this.itemsSubject.pipe(
            // destroy previous blocks and initialize new blocks
            mergeScan(async (previous, next) => {
                const incoming = this.mapById(
                    next
                        .map((state) => this.createItem(state))
                        .filter((item): item is ArtBoardItem<TMeta> => item !== null),
                );
                const items = await this.creationChannel.wait(
                    () => this.prepareItems(incoming, previous),
                );
                let value: ArtBoardItem<TMeta>;
                for (value of items.values()) {
                    const shape = value.shape;
                    if (!shape) {
                        continue;
                    }
                    shape.moveTo(this.groupChildren);
                }
                return new Map([...items.entries()]);
            }, this.mapById([]), 1),
            // emit a "load" event when all blocks are initialized
            tap((s) => {
                this.itemsById = s;
                this.loadedSubject.next(undefined);
                this.batchDraw();
            }),
            share(),
        );

    readonly emptyClipFunc: (
        ctx: CanvasRenderingContext2D,
        shape: Container<Konva.Shape | Konva.Group>
    ) => void;

    private subscription: Subscription | null = null;

    private itemsPrivate: ReadonlyArray<ArtBoardItem<TMeta>> = [];

    /**
     * Is the given item an ArtBoard?
     * @param item The item to check
     *
     * @returns - True if the item is an ArtBoard, false otherwise
     */
    static isArtBoard<TMeta>(item: unknown): item is ArtBoard<TMeta> {
        return item instanceof ArtBoard;
    }

    /**
     * Is the given item an ArtBoard state?
     * @param item The item to check
     *
     * @returns - True if the item is an ArtBoard, false otherwise
     */
    static isArtBoardState<TMeta>(item: NirbyBoardStateNode<TMeta>): item is NirbyBoardStateNode<TMeta, ArtBoard<TMeta>> {
        return item.type === 'art-board';
    }

    /**
     * Creates a new ArtBoard with the common properties used by a card.
     * @param card The card to create the art board from
     * @param cardSize The size of the card
     * @param meta The metadata to use
     * @param position The position of the art board
     * @param scale The scale of the art board
     * @param borderRadius The border radius of the art board
     * @param rotation The rotation of the art board
     *
     * @returns - The created art board
     */
    public static cardState<TMeta>(
        card: Card,
        cardSize: Vector2d,
        meta: TMeta | null,
        position?: Vector2d,
        scale?: number,
        borderRadius = 10,
        rotation = 0,
    ): NirbyBoardStateNode<TMeta, ArtBoard<TMeta>> {
        const sourcePosition = position ?? {x: 0, y: 0};
        return {
            children: card.blocks.map((block) => Block.blockToState<TMeta>(block, null)),
            id: card.hash,
            meta,
            properties: {
                mask: true,
                position: [
                    sourcePosition,
                    {
                        x: sourcePosition.x + cardSize.x,
                        y: sourcePosition.y + cardSize.y,
                    },
                ],
                rotation,
                background: {
                    fillColor: 'rgba(255,255,255,1)',
                    borderRadius,
                },
                scale,
            },
            type: 'art-board',
        };
    }

    /**
     * Creates an empty art board with the given board.
     * @param children The children of the art board
     * @param size The size of the art board
     * @param meta The metadata of the art board
     * @param mask Whether the art board is masked
     * @param borderRadius The border radius of the art board
     * @param id The id of the art board
     *
     * @returns - The created art board
     */
    public static emptyState<TMeta>(
        children: NirbyBoardStateNode<TMeta>[],
        size: Vector2d,
        meta: TMeta | null,
        mask = true,
        borderRadius = 5,
        id: string | null,
    ): NirbyBoardStateNode<TMeta, ArtBoard<TMeta>> {
        return {
            type: 'art-board',
            id: id ?? v4(),
            meta: null,
            properties: {
                mask,
                background: {
                    borderRadius,
                    fillColor: 'rgba(255,255,255,0)',
                },
                position: [{x: 0, y: 0}, size],
                rotation: 0,
            },
            children,
        };
    }

    /**
     * Gets the size for an art-board to fit in the given aspect ratio using the given base dimension size of the
     * player configuration.
     *
     * @param aspectRatio The aspect ratio to fit in (width / height)
     * @param config The player configuration
     * @param baseDimension Can be either 'width' or 'height'. Which dimension will be constant when scaling.
     *
     * @returns - The size of the art-board
     */
    public static sizeFromAspectRatio(
        aspectRatio: number,
        config: NirbyPlayerConfig,
        baseDimension: 'width' | 'height' = 'height',
    ): Vector2d {
        const baseSize = config.responsive.baseDimension;
        switch (baseDimension) {
            case 'width':
                return {
                    x: baseSize,
                    y: baseSize / aspectRatio,
                };
            case 'height':
                return {
                    x: baseSize * aspectRatio,
                    y: baseSize,
                };
        }
    }

    /**
     * Get all the items on this art board and all of its children mapped by ID
     *
     * @returns - A map of all the items on this art board and all of its children
     */
    public getDeepMapById(): Map<string, ArtBoardItem<TMeta>> {
        const childrenEntries: [string, ArtBoardItem<TMeta>][] = [
            ...this.itemsById.values(),
        ]
            // get children entries
            .map((item) =>
                item instanceof ArtBoard
                    ? [...item.getDeepMapById().entries()]
                    : [],
            )
            // flatten
            .reduce((acc, curr) => acc.concat(curr), []);

        const entries: [string, ArtBoardItem<TMeta>][] = [
            ...this.itemsById.entries(),
            ...childrenEntries,
        ];
        return new Map(entries);
    }

    /**
     * Constructor
     * @param id ID of this item's shape
     * @param meta Metadata of this canvas
     * @param context Context of the application
     * @param editable Whether the board is editable
     * @param initialConfig Configuration of this canvas
     * @param items Initial items
     */
    constructor(
        id: string,
        public readonly meta: TMeta | null,
        context?: NirbyContext,
        private readonly editable = false,
        initialConfig?: ArtBoardConfig,
        items: NirbyBoardStateNode<TMeta>[] = [],
    ) {
        this.context = context ?? NirbyContext.mock();
        this.ID = id ?? v4();
        this.backgroundNode = new Konva.Rect({
            id: this.ID + '-background',
            name: 'art-board-background',
        });
        this.group = new ArtBoardGroup(
            {
                id: this.ID,
                name: 'art-board',
            },
            this.backgroundNode,
        );
        this.groupChildren = new Konva.Group({
            id: this.ID + '-children',
            name: 'art-board-children',
        });
        this.group.add(this.groupChildren);
        this.controllerCursor = new ShapeCursorListener(this);
        const config: ArtBoardConfig = initialConfig ?? {
            mask: false,
            background: {},
            position: [
                {x: 0, y: 0},
                {x: 350, y: 350},
            ],
            rotation: 0,
        };

        this.set(config);
        this.itemsState = items;
        this.eventsController = new BlockEventController(this);
        this.transformController = new ArtBoardItemTransformController(
            this.eventsController,
        );
        this.emptyClipFunc = this.group.clipFunc();
    }

    /**
     * Creates a map of items by their ID from an array of items
     * @param items The items to create a map from
     *
     * @returns A map of items by their ID
     */
    mapById(items: ReadonlyArray<ArtBoardItem<TMeta>>): Map<string, ArtBoardItem<TMeta>> {
        return new Map<string, ArtBoardItem<TMeta>>(
            items.map((item) => [item.id, item]),
        );
    }

    /**
     * Items by ID
     */
    public get itemsById$(): Observable<Map<string, ArtBoardItem<TMeta>>> {
        return this.itemsInitializer$.pipe(startWith(this.itemsById));
    }

    /**
     * Items array observable
     */
    public get items$(): Observable<ArtBoardItem<TMeta>[]> {
        return this.itemsById$.pipe(map((items) => [...items.values()]));
    }

    /**
     * Wait for the next items to be loaded
     */
    async nextItems(): Promise<ReadonlyArray<ArtBoardItem<TMeta>>> {
        const results = await lastValueFrom(
            this.itemsInitializer$.pipe(
                first(),
                map((items) => [...items.values()]),
            ),
        );
        this.batchDraw();
        return results;
    }

    /**
     * Set current items
     *
     * @param value Items to set
     */
    private set itemsState(value: ReadonlyArray<NirbyBoardStateNode<TMeta>>) {
        this.itemsSubject.next(value);
    }

    /**
     * Get current items
     *
     * @returns - Current items
     */
    private get itemsState(): ReadonlyArray<NirbyBoardStateNode<TMeta>> {
        return this.itemsSubject.value;
    }

    /**
     * Default function that clips the art board
     * @param ctx - Canvas rendering context
     */
    readonly defaultClipFunc = (ctx: CanvasRenderingContext2D) => {
        const radius = this.borderRadius;
        const width = this.backgroundNode.width();
        const height = this.backgroundNode.height();
        ctx.moveTo(radius, 0);
        ctx.arcTo(width, 0, width, height, Math.min(height / 2, radius));
        ctx.arcTo(width, height, 0, height, Math.min(width / 2, radius));
        ctx.arcTo(0, height, 0, 0, Math.min(height / 2, radius));
        ctx.arcTo(0, 0, width, 0, Math.min(width / 2, radius));
    };

    /**
     * Sets the configuration for this art-board, including the background and items
     * @param config The configuration to set
     */
    public set(config: Partial<ArtBoardConfig>): void {
        if (typeof config.mask === 'boolean') {
            this.group.clipFunc(
                config.mask ? this.defaultClipFunc : this.emptyClipFunc,
            );
        }

        if (typeof config.background === 'object') {
            const background: CanvasBackground = {
                ...ArtBoard.DEFAULT_BACKGROUND,
                ...config.background,
            };
            this.backgroundNode.cornerRadius(background.borderRadius);
            this.backgroundNode.fill(background.fillColor);
            this.backgroundNode.stroke(background.strokeColor);
        }

        if (config.position) {
            this.group.position(config.position[0]);
            this.backgroundNode.position({x: 0, y: 0});

            const size = {
                width: config.position[1].x - config.position[0].x,
                height: config.position[1].y - config.position[0].y,
            };
            this.group.size(size);
            this.backgroundNode.size(size);
        }

        if (config.rotation) {
            this.group.rotation(config.rotation);
        }

        if (config.scale) {
            this.group.scale(
                typeof config.scale === 'object'
                    ? config.scale
                    : {x: config.scale, y: config.scale},
            );
        }

        this.batchDraw();
    }

    /**
     * Initializes shape
     */
    async initializeShape(): Promise<Konva.Group> {
        return this.group;
    }

    /**
     * Get ID of this art-board
     */
    get id(): string {
        return this.group.id();
    }

    /**
     * Get shape of this art-board
     */
    get shape(): Konva.Group {
        return this.group;
    }

    /**
     * Get border radius of this art-board
     */
    get borderRadius(): number {
        const radius = this.backgroundNode.cornerRadius();
        if (!Array.isArray(radius)) {
            return radius;
        }
        return Math.max(...radius);
    }

    /**
     * Whether the shape is being masked
     */
    get mask(): boolean {
        return this.group.clipFunc() === this.defaultClipFunc;
    }

    /**
     * Get configuration of this art-board
     */
    get properties(): ArtBoardConfig {
        return {
            mask: this.mask,
            background: {
                fillColor: this.backgroundNode.fill(),
                borderRadius: this.borderRadius,
            },
            position: [
                this.group.position(),
                {
                    x: this.group.x() + this.backgroundNode.width(),
                    y: this.group.y() + this.backgroundNode.height(),
                },
            ],
            rotation: this.group.rotation(),
            scale: this.group.scale(),
        };
    }

    /**
     * The current children
     */
    get children(): NirbyBoardStateNode<TMeta>[] {
        return [...this.itemsState]
            .map((item) => this.itemsById.get(item.id))
            .sort((a, b) => {
                const aItem = a?.shape;
                const bItem = b?.shape;
                if (aItem && bItem) {
                    return aItem.zIndex() - bItem.zIndex();
                }
                return 0;
            })
            .filter((item): item is NirbyBoardItemStandard<TMeta> => !!item)
            .map((i) => ArtBoardItemFactory.instance.stateOf<TMeta, NirbyBoardItemStandard<TMeta>>(i));
    }

    /**
     * Set the children
     * @param value The children to set
     */
    set children(value: NirbyBoardStateNode<TMeta>[]) {
        this.itemsState = value;
    }

    /**
     * Get items of this art-board
     */
    public get items(): ArtBoardItem<TMeta>[] {
        return [...this.itemsPrivate];
    }

    /**
     * Initializes the art board
     *
     * @param parent Parent of this art-board
     *
     * @returns - Promise of the shape that resolves when the art-board is initialized
     */
    async init(parent: ArtBoardItemKonvaParent): Promise<Konva.Group> {
        if (this.subscription) {
            Logger.warnStyled(
                'ART-BOARD',
                'Trying to initialize an already initialized ArtBoard',
            );
            return this.group;
        }
        this.parentSubject.next(parent);
        this.layer = parent.getLayer();

        this.subscription = new Subscription();

        // this will dispose all the children when the art board is disposed
        this.subscription.add(
            this.itemsInitializer$.subscribe((items) => {
                this.itemsPrivate = [...items.values()];
            }),
        );
        this.subscription.add(
            this.itemsInitializer$.pipe(last()).subscribe((items) => {
                let value: ArtBoardItem<TMeta>;
                for (value of items.values()) {
                    value.dispose();
                }
            }),
        );
        this.subscription.add(
            this.transformController.transformationEnd$.subscribe((type) => {
                this.transformController.onTransformationEnd(
                    undefined,
                    type === 'dragend' ? 'top' : 0,
                );
            }),
        );

        // dragging outside the art-board
        if (this.editable) {
            this.subscription.add(
                this.transformController.dragger.isDraggingOutside$.subscribe(
                    (isDraggingOutside) => {
                        if (this.mask) {
                            this.group.opacity(isDraggingOutside ? 0.5 : 1.0);
                        }
                    },
                ),
            );
            this.subscription.add(
                this.transformController.dragger.dragStart$.subscribe(() => {
                    this.group.moveToTop();
                }),
            );
        }

        this.group.moveTo(parent);
        await this.nextItems();
        return this.group;
    }

    /**
     * Disposes this art-board
     */
    dispose(): void {
        if (!this.subscription) {
            Logger.warnStyled(
                'BLOCK-EDITOR:ART-BOARD',
                `Trying to dispose a non-initialized ArtBoard (ID: ${this.ID})`,
            );
            return;
        }
        this.transformController.currentTransformer?.select(null);
        this.itemsById.forEach((item) => item.dispose());
        this.backgroundNode.destroy();
        this.groupChildren.destroy();
        this.group.destroy();
        this.subscription.unsubscribe();
        this.subscription = null;
    }

    /**
     * Set the blocks at the art-board.
     *
     * This will:
     *
     * a. Delete blocks that **are** on the cardboard and **are not** on the blocks array.
     *
     * b. Update blocks that **are** on the cardboard and **are** on the blocks array.
     *
     * c. Create blocks that **are not** on the cardboard and **are** on the blocks array.
     *
     * @param incomingItems The blocks to be set
     * @param previousItems The blocks that were on the art-board
     */
    private async prepareItems(
        incomingItems: Map<string, ArtBoardItem<TMeta>>,
        previousItems: Map<string, ArtBoardItem<TMeta>>,
    ): Promise<Map<string, ArtBoardItem<TMeta>>> {
        Logger.logStyled(
            'BLOCK-EDITOR:CONTAINER',
            'PREPARING NEW ITEMS',
        );

        // update index
        const source = previousItems;
        const value = incomingItems;
        const indicesById = new Map<string, number>(
            [...incomingItems.keys()].map((itemId, idx) => [itemId, idx]),
        );

        // Get the blocks that are on the canvas but not on the blocks array
        const deleted = MapUtils.deleteInstructions(
            source,
            Array.from(value.keys()),
        );
        const {added, updated, result} = MapUtils.update(
            source,
            value,
            (oldValue) => oldValue,
        );

        // Initialize non-existing blocks
        const indexedContainers: [Promise<ArtBoardItemKonvaNode>, number][] =
            added
                // index by ID
                .map(([id, item]) => [
                    item.init(this.groupChildren),
                    indicesById.get(id) ?? 0,
                ]);
        await Promise.all(indexedContainers.map(([d]) => d));

        // wait for all the blocks to be initialized
        let shapePromise: Promise<ArtBoardItemKonvaNode>;
        let index: number;
        for ([shapePromise, index] of indexedContainers) {
            const shape = await shapePromise;
            shape.zIndex(index);
            shape.addName('art-board-item');
            Logger.logStyled(
                'BLOCK-EDITOR:CONTAINER',
                `Shape ${shape.id()} of name "${shape.name()}" initialized at index ${index}`,
            );
        }

        // Update existing blocks using data of the new block that has the same ID
        let entry: [string, ArtBoardItem<TMeta>, [ArtBoardItem<TMeta>, ArtBoardItem<TMeta>]];
        for (entry of updated) {
            await entry[1].set(entry[2][1].properties);
            const index = indicesById.get(entry[0]);
            const shape = entry[1].shape;
            if (shape) {
                shape.zIndex(index ?? 0);
            }
        }

        // Delete remaining blocks
        let container: ArtBoardItem<TMeta>;
        for (container of deleted.map((d) => d[1])) {
            container.dispose();
        }

        Logger.logStyled(
            'BLOCK-EDITOR:CONTAINER',
            'FINISHED PREPARING NEW ITEMS',
        );
        return result;
    }

    /**
     * Creates a new art-board item from a state
     * @param state The state of the block
     * @private
     *
     * @returns - The created art-board item
     */
    private createItem(state: NirbyBoardStateNode<TMeta>): ArtBoardItem<TMeta> | null {
        return ArtBoardItemFactory.instance.create<TMeta, ArtBoardItem<TMeta>>(
            state,
            this.context,
            this.editable,
            state.children,
        );
    }

    /**
     * Removes a child item from the art-board
     * @param id The ID of the child item
     */
    public removeChildById(id: string): void {
        this.itemsState = this.itemsState
            // filter out the child
            .filter((item) => item.id !== id)
            // get the current state of the siblings of the child
            .map((item) => this.itemsById.get(item.id))
            .filter((item): item is ArtBoardItem<TMeta> => !!item)
            .map((item) =>
                ArtBoardItemFactory.instance.stateOf(
                    item as NirbyBoardItemStandard<TMeta>,
                ),
            );
    }

    /**
     * Get a child item by its ID
     * @param id The ID of the child item
     *
     * @returns - The child item
     */
    public getChildById(id: string): ArtBoardItem<TMeta> | null {
        return this.itemsPrivate.find((item) => item.id === id) ?? null;
    }

    /**
     * Wait for a child item to be initialized
     * @param id The ID of the child item
     *
     * @returns - The child item
     */
    public watchChildById(id: string): Observable<ArtBoardItem<TMeta> | null> {
        return this.items$.pipe(
            map((items) => items.find((item) => item.id === id) ?? null),
        );
    }

    /**
     * Adds a child item to the art-board
     * @param state The state of the child item
     * @param index Index where to insert the child item
     *
     * @returns - The added child item
     */
    public async addChild<T extends NirbyBoardItemStandard<TMeta>>(
        state: NirbyBoardStateNode<TMeta, T>,
        index?: number | null,
    ): Promise<T> {
        const newChildren = [...this.children];
        if (index !== undefined && index !== null) {
            newChildren.splice(index, 0, state);
        } else {
            newChildren.push(state);
        }
        this.children = newChildren;

        return await this.waitChildById(state.id);
    }

    /**
     * Waits for a child item to be added to the art-board
     * @param rootId The ID of the child item
     *
     * @returns - The added child item
     */
    waitChildById<T extends NirbyBoardItemStandard<TMeta>>(rootId: string): Promise<T> {
        return lastValueFrom(
            this.items$.pipe(
                map((items) => items.find((item) => item.id === rootId)),
                filter((s): s is T => !!s),
                first(),
            ),
        );
    }

    /**
     * Batch draw
     */
    public batchDraw() {
        this.group.getLayer()?.batchDraw();
    }
}
