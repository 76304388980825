import {ActivatedRouteSnapshot} from '@angular/router';

/**
 * Reduces the params of a route into a single map of strings.
 * @param route The route.
 *
 * @returns The params.
 */
export function reduceParams(route: ActivatedRouteSnapshot): Map<string, string> {
    const allRouteParams = new Map<string, string>();

    let child: ActivatedRouteSnapshot | null = route;
    while (child?.parent) {
        child = child.parent;
    }

    do {
        for (const [key, value] of Object.entries(child.params)) {
            allRouteParams.set(key, value);
        }
        child = child.firstChild;
    } while (child);

    return allRouteParams;
}
