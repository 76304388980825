import {catchError, from, NEVER, Observable, shareReplay, startWith, Subject, switchMap} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * Helper to call a method and return the result as an observable.
 */
export class ProcessObserver<TParams extends Array<unknown>, TReturn> {
    private readonly subject = new Subject<TParams>()

    public readonly result$: Observable<TReturn> = this.subject.pipe(
        startWith(this.initialParams()),
        map(params => this.process(...params)),
        switchMap(promise => from(promise)),
        catchError(() => NEVER),
        shareReplay({refCount: true, bufferSize: 1})
    );

    /**
     * Constructor.
     * @param process The process to observe.
     * @param initialParams The initial parameters to pass to the process.
     */
    constructor(
        private readonly process: (...args: TParams) => PromiseLike<TReturn> | Observable<TReturn>,
        private readonly initialParams: () => TParams
    ) {
    }

    /**
     * Start the process.
     * @param args The arguments to pass to the process.
     */
    public call(...args: TParams): void {
        this.subject.next(args);
    }
}
