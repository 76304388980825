import {BehaviorSubject, Observable} from 'rxjs';

/**
 * Class to record a video from a connected camera.
 */
export class VideoRecorder {
    private readonly recorder: MediaRecorder;

    /**
     * Subjects that emits everytime a new result is available.
     * @private
     */
    private readonly lastResultSubject = new BehaviorSubject<Blob | null>(null);

    public readonly videoOnlyStream: MediaStream;

    private chunks: Blob[] = [];

    /**
     * Whether the recorder is paused.
     */
    public get paused(): boolean {
        return this.recorder.state === 'paused';
    }

    /**
     * Gets the last result.
     */
    public get lastResult(): Blob | null {
        return this.lastResultSubject.value;
    }

    /**
     * Starts recording.
     */
    public static async record(): Promise<VideoRecorder> {
        const stream = await navigator.mediaDevices.getUserMedia({video: true, audio: true});
        return new VideoRecorder(stream);
    }

    /**
     * Watches the last result.
     *
     * @returns The observable that emits the last result.
     */
    public watchLastResult(): Observable<Blob | null> {
        return this.lastResultSubject.asObservable();
    }

    /**
     * Checks if the recorder is recording.
     */
    public get recording(): boolean {
        return this.recorder.state === 'recording';
    }

    /**
     * Checks if a recording is in progress.
     */
    public get inProgress(): boolean {
        return this.recording || this.paused;
    }

    /**
     * Constructor.
     * @param stream The stream to record.
     */
    constructor(
        public readonly stream: MediaStream,
    ) {
        this.recorder = new MediaRecorder(stream, {mimeType: 'video/webm;codecs=vp9,opus'});

        this.recorder.ondataavailable = (event) => {
            this.chunks.push(event.data);
        }

        this.recorder.onstop = () => {
            const blob = new Blob(this.chunks, {type: 'video/webm'});
            this.lastResultSubject.next(blob);
            this.chunks = [];
        }

        this.videoOnlyStream = new MediaStream(stream.getVideoTracks());
    }

    /**
     * Starts recording.
     */
    public start(): void {
        this.recorder.start();
    }

    /**
     * Stops recording.
     */
    public stop(): void {
        if (this.inProgress) {
            this.recorder.stop();
        }
    }

    /**
     * Pauses the recording.
     */
    public pause(): void {
        if (this.recording) {
            this.recorder.pause();
        }
    }

    /**
     * Resumes the recording.
     */
    public resume(): void {
        if (this.paused) {
            this.recorder.resume();
        }
    }

    /**
     * Disposes the recorder.
     */
    public dispose(): void {
        this.stop();
        this.stream.getTracks().forEach(track => track.stop());
    }

    /**
     * Clears the last result.
     */
    clear(): void {
        this.lastResultSubject.next(null);
    }
}
