import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Pipe({
    name: 'safe'
})
/**
 * A pipe to sanitize html
 */
export class SafeHtmlPipe implements PipeTransform {
    /**
     * Constructor.
     * @param sanitizer The sanitizer.
     */
    constructor(
        private readonly sanitizer: DomSanitizer
    ) {
    }

    /**
     * Transforms the value.
     * @param value The value.
     *
     * @returns The sanitized value.
     */
    transform(value: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
}
