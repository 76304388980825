<form [formGroup]="newDirectoryForm" (ngSubmit)="addDirectory()">
    <div class="modal-header">
        <h4 class="modal-tittle text-dark">New Directory</h4>
        <button
            class="close"
            aria-label="close"
            type="button"
            (click)="modal.dismissAll()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="mb-3">
            <label for="directoryName" class="col-form-label text-dark"
                >Directory Name:</label
            ><br />
            <input
                formControlName="directory"
                type="text"
                placeholder="Directory"
                id="directoryName"
                name="dirName"
                class="form-control"
            />
            <div
                *ngIf="directory.touched && directory.invalid"
                class="alert alert-danger"
            >
                Directory name is required
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-outline-danger"
            [disabled]="loading"
            (click)="modal.dismissAll()"
        >
            Cancel
        </button>
        <button
            type="submit"
            class="btn btn-primary"
            [disabled]="loading || directory.invalid"
        >
            Create directory
        </button>
    </div>
</form>
