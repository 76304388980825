<ng-container *ngIf="{hovering: filesHovering$ | async, dropped: filesDropped$ | async} as data">
    <div
        class="file-drop-overlay d-flex flex-colum justify-content-center align-items-center"
        *ngIf="data.hovering || data.dropped"
    >
        <div class="file-drop-overlay-content d-flex flex-column align-items-center text-white">
            <ng-container *ngIf="data.hovering">
                <div class="file-drop-overlay-icon">
                    <fa-icon [icon]="icons.upload"></fa-icon>
                </div>
                <div class="file-drop-overlay-text">
                    <h2>Drop files to upload</h2>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
