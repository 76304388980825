import {z} from 'zod';
import {Migrator} from '@nirby/store/migrator';
import {NirbyTimestamp, ZodSchemaBuild} from '@nirby/store/models';
import {TIMESTAMP_SCHEMA} from '@nirby/store/proxy';

export interface CloudFlareStreamVideoProcessedData {
    title: string;
    readyToStream: boolean;
    playback: {
        hls: string;
        dash: string;
    };
    preview: string;
    duration: number;
    input: {
        width: number;
        height: number;
    };
    created: NirbyTimestamp;
    modified: NirbyTimestamp;
}

export interface CloudFlareStreamVideo {
    uid: string;
    status: 'processing' | 'ready' | 'failed' | 'deleted';
    errorCode: string | null;
    workspaceId: string;
    data: CloudFlareStreamVideoProcessedData | null;
}

export const CLOUDFLARE_STREAM_VIDEO_PROCESSED_DATA_SCHEMA: ZodSchemaBuild<CloudFlareStreamVideoProcessedData> =
    z.object({
        title: z.string().default(''),
        readyToStream: z.boolean().default(false),
        playback: z.object({
            hls: z.string(),
            dash: z.string(),
        }),
        preview: z.string(),
        duration: z.number(),
        input: z.object({
            width: z.number(),
            height: z.number(),
        }),
        created: TIMESTAMP_SCHEMA,
        modified: TIMESTAMP_SCHEMA,
    });

export const CLOUDFLARE_STREAM_VIDEO_SCHEMA: ZodSchemaBuild<CloudFlareStreamVideo> =
    z.object({
        uid: z.string(),
        status: z.union([
            z.literal('processing'),
            z.literal('ready'),
            z.literal('failed'),
            z.literal('deleted'),
        ]),
        errorCode: z.string().nullable(),
        workspaceId: z.string(),
        data: CLOUDFLARE_STREAM_VIDEO_PROCESSED_DATA_SCHEMA.nullable(),
    });

export const CF_STREAM_VIDEO_MIGRATOR = new Migrator<CloudFlareStreamVideo>(
    CLOUDFLARE_STREAM_VIDEO_SCHEMA,
    (): CloudFlareStreamVideo => ({
        uid: '',
        status: 'processing',
        errorCode: null,
        workspaceId: '',
        data: null,
    }),
);
