<div class="container mt-4">
    <div class="row justify-content-center">
        <div class="col-5 bg-light p-4 rounded">
            <h2 class="text-dark">Reset password</h2>
            <form [formGroup]="form" (ngSubmit)="resetPassword()">
                <label class="text-dark mt-4" for="email">E-mail</label>
                <input
                    class="form-control w-100 mt-2"
                    [formControl]="emailControl"
                    type="email"
                    id="email"
                    name="email"
                />
                <button
                    class="btn btn-outline-primary w-100 mt-4"
                    [disabled]="form.invalid || loading"
                    type="submit"
                >
                    Reset password
                </button>
                <div class="text-danger" *ngIf="emailControl.invalid && emailControl.touched">
                    The given e-mail is invalid
                </div>
            </form>
            <div class="mt-4 text-center">
                <a [routerLink]="['login']">I remembered my password!</a>
            </div>
        </div>
    </div>
</div>
