import {BaseShapeUnitDescription} from '../unit.model';
import {UnitAttributes} from '.';
import {Bounds, ShapeUnit} from './shape-unit';
import {Logger} from '@nirby/logger';
import {BehaviorSubject, distinctUntilChanged, from, Observable, of, startWith, switchMap} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {EMPTY_IMAGE, ERROR_IMAGE} from '../..';

export interface ImageUnitAttributes extends UnitAttributes {
    src: string | null;
}

export type ImageUnit = BaseShapeUnitDescription<'Image', ImageUnitAttributes>;

const DEFAULT_THUMBNAIL = '/assets/img/bg/nby-pop-button.jpg';

export class ImageShapeUnit extends ShapeUnit<'Image'> {

    private currentImage: HTMLImageElement | null = null;
    private readonly imageSourceController = new BehaviorSubject<string | null>(
        null,
    );

    public readonly image$: Observable<HTMLImageElement> =
        this.imageSourceController.pipe(
            distinctUntilChanged(),
            switchMap((image) =>
                image
                    ? from(ImageShapeUnit.waitImageLoad(image)).pipe(
                        startWith(null),
                    )
                    : of(null),
            ),
            map((image) => image ?? EMPTY_IMAGE),
            shareReplay({refCount: true, bufferSize: 1}),
        );

    defaultValues: ImageUnitAttributes = {
        src: null,
        cursor: 'ignore',
    };

    static waitImageLoad(src: string): Promise<HTMLImageElement> {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = () => {
                if (src !== DEFAULT_THUMBNAIL) {
                    resolve(ERROR_IMAGE);
                } else {
                    Logger.warn(
                        'ImageBlockDrawable',
                        `Could not load neither the given image (${src}) nor the fallback image (${DEFAULT_THUMBNAIL})`,
                    );
                    resolve(ERROR_IMAGE);
                }
            };
            img.src = src;
        });
    }

    protected draw(
        ctx: CanvasRenderingContext2D,
        attributes: ImageUnitAttributes,
        bounds: Bounds,
    ): void {
        ctx.drawImage(
            this.currentImage ?? EMPTY_IMAGE,
            bounds.x,
            bounds.y,
            bounds.width,
            bounds.height,
        );
    }

    protected override async onUpdate(attrs: ImageUnitAttributes): Promise<void> {
        this.imageSourceController.next(attrs.src);
    }

    override onStart() {
        super.onStart();
        this.subscriptions.add(
            this.image$.subscribe((image) => {
                this.currentImage = image;
                this.shape.draw();
            }),
        );
    }
}
