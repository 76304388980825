import {Component, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'nirby-slider-select',
  templateUrl: './slider-select.component.html',
  styleUrls: ['./slider-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: SliderSelectComponent,
            multi: true
        }
    ]
})
/**
 * A component to select a value from a range of values using a slider.
 */
export class SliderSelectComponent<T extends object, TV extends (keyof T | null)> implements ControlValueAccessor {
    @Input() defaultOptionIndex: number | 'middle' = 'middle';
    @Input() valueField: TV | null = null;

    /**
     * The available options.
     * @param value - The new options
     */
    @Input() set options(value: T[] | null) {
        this.availableOptions = value ?? [];
        if (this.availableOptions.length > 0) {
            if (this.selectedIndex < this.availableOptions.length) {
                this.update(this.selectedIndex);
                return;
            }
            let index: number;
            if (this.defaultOptionIndex === 'middle') {
                index = Math.floor(this.availableOptions.length / 2);
            } else {
                index = Math.min(this.defaultOptionIndex, this.availableOptions.length - 1);
            }
            this.update(index);
        } else {
            this.update(-1);
        }
    }

    /**
     * The available options.
     */
    get options(): T[] {
        return this.availableOptions;
    }

    selectedIndex = 0;
    disabled = false;

    public availableOptions: T[] = [];

    onChange: (action: unknown | null) => void = () => {
        return;
    };
    onTouched = () => {
        return;
    };

    /**
     * Write a new action to the model.
     * @param obj - Action to write.
     */
    writeValue(obj?: T): void {
        this.selectedIndex = this.availableOptions
            .findIndex((option) => {
                if (this.valueField === null) {
                    return option === obj;
                }
                return option[this.valueField] === obj;
            });
    }

    /**
     * Register a callback function that is called when the value has changed.
     * @param fn - Callback function.
     */
    registerOnChange(fn: (action: unknown | null) => void): void {
        this.onChange = fn;
    }

    /**
     * Register a callback function that is called when the control has been touched.
     * @param fn - Callback function.
     */
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    /**
     * Set the disabled state of the component.
     * @param isDisabled Whether the component should be disabled.
     */
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Notifies the control value accessor that a change is being made to the value.
     * @param value - New value.
     */
    update(value: number): void {
        this.selectedIndex = value;
        const option = this.getOption(value);
        if (option === null) {
            this.onChange(null);
        } else {
            const value = this.valueField === null ? option : option[this.valueField]
            this.onChange(value);
        }
    }

    /**
     * Get the option at the given index.
     * @param id - The index.
     * @private
     *
     * @returns The option at the given index. Or null if the index is out of bounds.
     */
    private getOption(id: number): T | null {
        return this.availableOptions.find((option, index) => id === index) ?? null;
    }
}
