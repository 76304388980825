import { Component } from '@angular/core';
import { BlockStyleComponent } from '../../block-style.component';
import {
    faAlignCenter,
    faAlignJustify,
    faAlignLeft,
    faAlignRight,
    faBold,
    faFont,
    faItalic,
    faStrikethrough,
    faUnderline,
} from '@fortawesome/free-solid-svg-icons';
import { FontDecoration, FontStyle, TextAlignment } from '@nirby/runtimes/canvas';
import { AppError } from '@nirby/js-utils/errors';
import { FontSource } from '@nirby/media-models';
import { ButtonDisplay } from '@nirby/shared/widgets';

@Component({
    selector: 'nirby-text-block-style',
    templateUrl: './text-block-style.component.html',
    styleUrls: ['./text-block-style.component.scss'],
})
// eslint-disable-next-line
export class TextBlockStyleComponent extends BlockStyleComponent<any> {
    public bStyle?: object;

    public alignmentOptions: ButtonDisplay<TextAlignment>[] = [
        {
            value: 'left',
            icon: faAlignLeft,
        },
        {
            value: 'center',
            icon: faAlignCenter,
        },
        {
            value: 'right',
            icon: faAlignRight,
        },
        {
            value: 'justify',
            icon: faAlignJustify,
        },
    ];

    public fontStyleOptions: ButtonDisplay<FontStyle>[] = [
        {
            value: 'normal',
            icon: faFont,
        },
        {
            value: 'bold',
            icon: faBold,
        },
        {
            value: 'italic',
            icon: faItalic,
        },
    ];
    public fontDecorationOptions: ButtonDisplay<FontDecoration>[] = [
        {
            value: '',
            icon: faFont,
        },
        {
            value: 'line-through',
            icon: faStrikethrough,
        },
        {
            value: 'underline',
            icon: faUnderline,
        },
    ];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setData(style: any): void {
        this.bStyle = style ? {} : undefined;
    }

    onStyleChange(): void {
        if (this.bStyle) {
            this.sendData(this.bStyle);
        }
    }

    chooseColor(_: string | null): void {
        if (!this.bStyle) {
            throw new AppError('Trying to update undefined style');
        }
        // this.bStyle.color = color ?? '#ffffff';
        this.onStyleChange();
    }

    chooseFontFamily(_: FontSource): void {
        if (!this.bStyle) {
            throw new AppError('Trying to update undefined style');
        }
        // this.bStyle.fontFamily = fontFamily;
        this.onStyleChange();
    }

    chooseAlignment(_: TextAlignment): void {
        if (!this.bStyle) {
            throw new AppError('Trying to update undefined style');
        }
        // this.bStyle.alignment = alignment;
        this.onStyleChange();
    }

    chooseFontStyle(_: FontStyle): void {
        if (!this.bStyle) {
            throw new AppError('Trying to update undefined style');
        }
        // this.bStyle.style = style;
        this.onStyleChange();
    }

    chooseFontDecoration(_: FontDecoration): void {
        if (!this.bStyle) {
            throw new AppError('Trying to update undefined style');
        }
        // this.bStyle.decoration = decoration;
        this.onStyleChange();
    }

    updateLineHeight(_: number): void {
        if (!this.bStyle) {
            throw new AppError('Trying to update undefined style');
        }
        // this.bStyle.lineHeight = value;
        this.onStyleChange();
    }
}
