import {Component, Input} from '@angular/core';

@Component({
    selector: 'nirby-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss']
})
export class CardComponent {
    @Input() orientation: 'row' | 'column' = 'column';
    @Input() minHeight = 120;
}
