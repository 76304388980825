<!-- Section: Border -->
<section class="section mb-4">
    <div class="section-body">
        <div class="form-group">
            <label class="d-block button-style-alignment">Alignment</label>
            <select
                class="form-control"
                id="button-style-alignment"
                [value]="alignment"
                (change)="onAlignmentChange($event)"
            >
                <option value="between">Between</option>
                <option value="center">Center</option>
                <option value="icon-right">Icon Right</option>
                <option value="icon-left">Icon Left</option>
            </select>
        </div>
        <div class="form-group">
            <label>Fill Color (Background)</label>
            <nirby-color-picker
                [allowTransparency]="true"
                [value]="color"
                (valueChange)="onColorChange($event)"
            ></nirby-color-picker>
        </div>
    </div>
</section>

<!-- Section: Font & Icon -->
<section class="section section-alt p-3 mb-4">
    <div class="section-header">
        <h1 class="section-title">Font &amp; Icon</h1>
    </div>
    <div class="section-body">
        <div class="form-group">
            <nirby-slider
                label="Font Size"
                [min]="1"
                [max]="100"
                [step]="1"
                [value]="fontSize"
                (valueChange)="onFontScaleChange($event)"
            ></nirby-slider>
        </div>
        <div class="form-group">
            <label>Font Family</label>
            <nirby-font-picker
                [fontFamily]="{ name: fontFamily }"
                (fontFamilyChange)="onFontChange($event)"
            ></nirby-font-picker>
        </div>
        <!-- Text Style -->
        <div class="form-group">
            <label class="d-block" for="styling">Font Style</label>
            <nirby-button-select
                id="styling"
                name="fontStyle"
                [ngModel]="fontStyle"
                (ngModelChange)="onFontStyleChange($event)"
                [options]="fontStyleOptions"
            ></nirby-button-select>
        </div>
        <div class="form-group">
            <label>Main Color</label>
            <nirby-color-picker
                [haveAutoOption]="true"
                [value]="fontColor"
                (valueChange)="onFontColorChange($event)"
            ></nirby-color-picker>
        </div>

        <div class="form-group">
            <label class="d-block">Icon Scale</label>

            <div class="btn-group btn-group-sm">
                <button
                    class="btn"
                    [ngClass]="{
                        'active btn-primary': !iconScale,
                        'btn-secondary': iconScale
                    }"
                    (click)="onIconScaleChange(null)"
                    role="button"
                >
                    Auto
                </button>
                <button
                    class="btn"
                    [ngClass]="{
                        'active btn-primary': iconScale,
                        'btn-secondary': !iconScale
                    }"
                    tabindex="0"
                    (click)="onIconScaleChange(fontSize)"
                    role="button"
                >
                    Custom
                </button>
            </div>

            <nirby-slider
                class="mt-5"
                *ngIf="!!iconScale"
                [min]="1"
                [max]="100"
                [step]="1"
                [value]="iconScale"
                (valueChange)="onIconScaleChange($event)"
            ></nirby-slider>
        </div>
    </div>
</section>

<!-- Section: Border -->
<section class="section section-alt p-3 mb-4">
    <div class="section-header">
        <h1 class="section-title">Border</h1>
    </div>
    <div class="section-body">
        <div class="form-group">
            <nirby-slider
                label="Border Radius"
                [min]="0"
                [max]="250"
                [step]="0.01"
                [value]="borderRadius"
                (valueChange)="onRadiusChange($event)"
            ></nirby-slider>
        </div>
        <div class="form-group">
            <nirby-slider
                label="Border Width"
                [min]="0"
                [max]="50"
                [step]="1"
                [value]="borderWidth"
                (valueChange)="onBorderWidthChange($event)"
            ></nirby-slider>
        </div>
        <div class="form-group" *ngIf="borderWidth > 0">
            <label> Border Color </label>
            <nirby-color-picker
                [value]="borderColor"
                [haveAutoOption]="true"
                (valueChange)="onBorderColorChange($event)"
            ></nirby-color-picker>
        </div>
    </div>
</section>
