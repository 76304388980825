import { Injectable } from '@angular/core';
import jsonexport from 'jsonexport/dist';

@Injectable({
    providedIn: 'root',
})
export class JsonExporterService {

    createObjectFileUrl<T extends object>(obj: T): string {
        const data = JSON.stringify(obj);

        const file = new Blob([data], { type: 'application/json' });
        return URL.createObjectURL(file);
    }

    createTextFileUrl(obj: string): string {
        const file = new Blob([obj], { type: 'application/csv' });
        return URL.createObjectURL(file);
    }

    download<T extends object>(obj: T, name: string): void {
        const url = this.createObjectFileUrl(obj);
        this.downloadUrl(url, name);
    }

    downloadUrl(url: string, name: string): void {
        const a = document.createElement('a');
        a.download = name;
        a.href = url;
        a.click();
    }

    async downloadAsCsv<T extends object>(
        obj: T,
        name: string,
        delimiter = ','
    ): Promise<void> {
        const csv = await jsonexport(obj, { rowDelimiter: delimiter });
        const url = this.createTextFileUrl(csv);
        this.downloadUrl(url, name);
    }
}
