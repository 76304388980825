import {PublishedJson} from './published.model';
import {ZodSchemaBuild} from '@nirby/store/models';
import {NIRBY_VARIABLE_DECLARATION_SET_SCHEMA, NirbyVariableDeclarationSet} from '@nirby/runtimes/state';
import {Card, CARD_SCHEMA, RouteCondition} from '@nirby/models/nirby-player';
import {POPPER_CONFIG_SCHEMA, PopperConfig} from '@nirby/js-utils/embed';
import {z} from 'zod';
import {NirbyDocumentReference} from '@nirby/store/base';

type PopPlugins = Record<'nirby-prime-score', unknown>;

export interface CompiledPop {
    title: string;
    firstCardKey: string;
    welcome: PopperConfig;
    cards: Record<string, Card>;
    plugins: Partial<PopPlugins>;
    variableDeclaration?: NirbyVariableDeclarationSet;
}

export const COMPILED_POP_SCHEMA: ZodSchemaBuild<CompiledPop> = z.object({
    title: z.string(),
    firstCardKey: z.string(),
    welcome: POPPER_CONFIG_SCHEMA,
    cards: z.record(CARD_SCHEMA),
    plugins: z.object({
        'nirby-prime-score': z.unknown(),
    }),
    variableDeclaration: NIRBY_VARIABLE_DECLARATION_SET_SCHEMA.default({}),
});

export interface PublishedRoute {
    path: RouteCondition | string;
    campaigns: NirbyDocumentReference<PublishedJson>[];
}

export interface PublishedWidget {
    routes: PublishedRoute[];
}
