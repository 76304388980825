import {Component} from '@angular/core';

@Component({
    selector: 'nirby-toast-container',
    template: `
        <p-toast></p-toast>
    `,
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {'[class.ngb-toasts]': 'true'},
})
/**
 * A component container for all toast notifications.
 */
export class ToastContainerComponent {
}
