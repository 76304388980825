import {DesignableBlock} from '../block-designable';
import {ButtonBlock} from '@nirby/models/nirby-player';
import {BlockContext, DesignSet, ShapeUnitRawDescription, TextAlignment, VerticalTextAlignment,} from '../../designs';
import {AxisMixin} from '../../designs/unit.model';
import {Attribute} from '../../designs/functions.model';
import {tryParseColor} from '../../utils/color';

const ICON_SIZE = 16;
const MARGIN = 12;
const VERTICAL_INNER_PADDING = 8;

type LightTextDescription = {
    verticalAlign: VerticalTextAlignment;
    align: TextAlignment;
    container?: AxisMixin['axis'];
} & AxisMixin;

// 'between' | 'center' | 'icon-right' | 'icon-left'

/**
 * Creates a button description with a text and an icon.
 * @param axes Axes of the label and the icon
 * @param debug Debug mode
 *
 * @returns A button description
 */
function createButton(
    axes: { label?: LightTextDescription; icon?: LightTextDescription },
    debug = false
): ShapeUnitRawDescription<'Rect'> {
    const { label, icon } = axes;
    const children: ShapeUnitRawDescription[] = [];
    const fontColor: Attribute<string> = {
        method: 'IfElseBlock',
        arguments: [
            'hover',
            {
                method: 'Custom',
                arguments: [
                    (context: BlockContext<ButtonBlock>) => {
                        // parse font color, if it's not set, keep it null
                        let fontColor = context.block.style.fontColor
                            ? tryParseColor(context.block.style.fontColor)
                            : null;

                        // calculate light and dark font color versions
                        const lightFontColor =
                            fontColor?.lighten(0.6) ?? tryParseColor('#ffffff');
                        const darkFontColor =
                            fontColor?.darken(0.6) ?? tryParseColor('#000000');

                        // parse background color, if it's not set, use transparent
                        const backgroundColor = tryParseColor(
                            backgroundColorCalculator(context)
                        );

                        // parse border color, if it's not set, use transparent
                        const borderColor = tryParseColor(
                            context.block.style.borderColor ?? 'rgba(0,0,0,0)'
                        );

                        // check background and border colors opacity
                        const isBackgroundTransparent =
                            backgroundColor.alpha() < 0.1;
                        const isBorderTransparent = borderColor.alpha() < 0.1;

                        fontColor = backgroundColor.isDark()
                            ? lightFontColor
                            : darkFontColor;

                        if (isBackgroundTransparent && isBorderTransparent) {
                            // has no background and border
                            return '#ffffff';
                        } else if (isBackgroundTransparent) {
                            // has only border
                            return borderColor.isDark()
                                ? '#ffffff'
                                : borderColor.darken(0.6).toString();
                        } else if (isBorderTransparent) {
                            // has only background
                            return fontColor.toString();
                        } else {
                            // has both background and border
                            return fontColor.toString();
                        }
                    },
                ],
            },
            {
                method: 'Custom',
                arguments: [
                    (
                        context: BlockContext<ButtonBlock>,
                    ) => {
                        if (context.block.style.fontColor !== null) {
                            return context.block.style.fontColor;
                        }

                        const backgroundColor = tryParseColor(
                            context.block.style.color ?? 'rgba(0,0,0,0)'
                        );
                        const borderColor = tryParseColor(
                            context.block.style.borderColor ?? 'rgba(0,0,0,0)'
                        );

                        const isBackgroundTransparent =
                            backgroundColor.alpha() < 0.1;
                        const isBorderTransparent = borderColor.alpha() < 0.1;
                        if (isBackgroundTransparent && isBorderTransparent) {
                            // has no background and border
                            return '#000000';
                        } else if (isBackgroundTransparent) {
                            // has only border
                            return borderColor.toString();
                        } else if (isBorderTransparent) {
                            // has only background
                            return backgroundColor.isDark()
                                ? '#ffffff'
                                : backgroundColor.darken(0.8).toString();
                        }
                        return backgroundColor.isDark()
                            ? '#ffffff'
                            : backgroundColor.darken(0.8).toString();
                    },
                ],
            },
        ],
    };
    if (label) {
        let shape: ShapeUnitRawDescription = {
            shape: 'Text',
            listenTo: ['isHover'],
            attributes: {
                text: {
                    method: 'GetBlockContent',
                    arguments: ['label'],
                },
                textBaseline: 'bottom',
                fontFamily: {
                    method: 'GetBlockStyle',
                    arguments: ['fontFamily'],
                },
                fontStyle: {
                    method: 'GetBlockStyle',
                    arguments: ['fontStyle'],
                },
                verticalAlign: label.verticalAlign,
                align: label.align,
                fill: fontColor,
                fontSize: {
                    method: 'GetBlockStyle',
                    arguments: ['fontSize'],
                },
                debug,
            },
            axis: label.axis,
            children: [],
        } as ShapeUnitRawDescription<'Text'>;
        if (label.container) {
            shape = {
                shape: 'Container',
                attributes: {},
                axis: label.container,
                children: [shape],
            } as ShapeUnitRawDescription<'Container'>;
        }
        children.push(shape);
    }
    if (icon) {
        let shape: ShapeUnitRawDescription = {
            shape: 'Text',
            attributes: {
                text: {
                    method: 'GetBlockContent',
                    arguments: ['icon'],
                },
                fontFamily: 'FontAwesome',
                verticalAlign: icon.verticalAlign,
                textBaseline: 'bottom',
                align: icon.align,
                fill: fontColor,
                fontSize: {
                    method: 'GetBlockStyle',
                    arguments: ['iconScale'],
                },
                debug,
            },
            axis: icon.axis,
            children: [],
        } as ShapeUnitRawDescription<'Text'>;
        if (icon.container) {
            shape = {
                shape: 'Container',
                attributes: {},
                axis: icon.container,
                children: [shape],
            } as ShapeUnitRawDescription<'Container'>;
        }
        children.push(shape);
    }

    /**
     * Calculates the border color from the block context
     * @param context The block context
     *
     * @returns The border color
     */
    const borderColorCalculator = (context: BlockContext) => {
        const block = context.block as ButtonBlock;
        if (block.style.borderColor) {
            return block.style.borderColor;
        }
        const backgroundColorObject = tryParseColor(block.style.color);
        if (backgroundColorObject.alpha() < 0.1) {
            return '#000000';
        }
        return backgroundColorObject.darken(0.1).toString();
    };

    const backgroundColorCalculator = (context: BlockContext) => {
        const block = context.block as ButtonBlock;
        const backgroundColor = tryParseColor(block.style.color);
        const borderColor = tryParseColor(borderColorCalculator(context));

        const isBackgroundTransparent = backgroundColor.alpha() < 0.1;
        if (isBackgroundTransparent) {
            return borderColor.toString();
        } else {
            return backgroundColor.darken(0.2).toString();
        }
    };

    return {
        shape: 'Rect',
        listenTo: ['isHover'],
        attributes: {
            borderRadius: {
                method: 'GetBlockStyle',
                arguments: ['borderRadius'],
            },
            borderRadiusMode: 'absolute',
            borderColor: {
                method: 'Custom',
                arguments: [borderColorCalculator],
            },
            borderWidth: {
                method: 'GetBlockStyle',
                arguments: ['borderWidth'],
            },
            backgroundColor: {
                method: 'IfElseBlock',
                arguments: [
                    'hover',
                    {
                        method: 'Custom',
                        arguments: [
                            (
                                context: BlockContext<ButtonBlock>,
                            ) => {
                                return backgroundColorCalculator(context);
                            },
                        ],
                    },
                    {
                        method: 'Custom',
                        arguments: [
                            (
                                context: BlockContext<ButtonBlock>,
                            ) => {
                                return context.block.style.color;
                            },
                        ],
                    },
                ],
            },
            cursor: 'pointer',
        },
        axis: {
            horizontal: 'stretch',
            vertical: 'stretch',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
        children: [
            {
                shape: 'Container',
                children,
                attributes: {},
                axis: {
                    horizontal: 'stretch',
                    vertical: 'stretch',
                    top: MARGIN * 0.7,
                    bottom: MARGIN * 0.7,
                    left: MARGIN,
                    right: MARGIN,
                },
            },
        ],
    };
}

const textOnly = createButton({
    label: {
        align: 'center',
        verticalAlign: 'middle',
        axis: {
            horizontal: 'stretch',
            vertical: 'stretch',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
    },
});

const iconOnly = createButton({
    icon: {
        align: 'center',
        verticalAlign: 'middle',
        axis: {
            horizontal: 'stretch',
            vertical: 'stretch',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
    },
});

const fullJustify = createButton({
    label: {
        align: 'left',
        verticalAlign: 'middle',
        axis: {
            horizontal: 'stretch',
            vertical: 'stretch',
            top: 0,
            bottom: 0,
            left: 0,
            right: ICON_SIZE,
        },
    },
    icon: {
        align: 'center',
        verticalAlign: 'middle',
        axis: {
            vertical: 'stretch',
            horizontal: 'pin-right',
            top: 0,
            bottom: 0,
            width: ICON_SIZE,
            right: 0,
        },
    },
});

const fullIconLeft = createButton({
    label: {
        align: 'left',
        verticalAlign: 'middle',
        axis: {
            horizontal: 'stretch',
            vertical: 'stretch',
            top: 0,
            bottom: 0,
            left: ICON_SIZE + MARGIN * 2,
            right: 0,
        },
    },
    icon: {
        align: 'left',
        verticalAlign: 'middle',
        container: {
            horizontal: 'pin-left',
            vertical: 'stretch',
            top: -1,
            bottom: 0,
            width: ICON_SIZE + MARGIN * 2,
            left: 0,
        },
        axis: {
            horizontal: 'center',
            vertical: 'center',
            width: ICON_SIZE,
            height: ICON_SIZE,
        },
    },
});

const fullIconRight = createButton({
    label: {
        align: 'left',
        verticalAlign: 'middle',
        axis: {
            horizontal: 'stretch',
            vertical: 'stretch',
            top: 0,
            bottom: 0,
            left: 0,
            right: ICON_SIZE + MARGIN * 2,
        },
    },
    icon: {
        verticalAlign: 'middle',
        align: 'right',
        container: {
            horizontal: 'pin-right',
            vertical: 'stretch',
            top: 0,
            bottom: 0,
            width: ICON_SIZE + MARGIN * 2,
            right: 0,
        },
        axis: {
            horizontal: 'stretch',
            vertical: 'stretch',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
    },
});

const fullCenter = createButton({
    label: {
        verticalAlign: 'top',
        align: 'center',
        container: {
            horizontal: 'stretch',
            vertical: 'relative',
            top: 0.5,
            bottom: 0,
            left: MARGIN,
            right: MARGIN,
        },
        axis: {
            horizontal: 'stretch',
            vertical: 'stretch',
            left: 0,
            right: 0,
            top: VERTICAL_INNER_PADDING / 2,
            bottom: 0,
        },
    },
    icon: {
        verticalAlign: 'bottom',
        align: 'center',
        container: {
            horizontal: 'stretch',
            vertical: 'relative',
            top: 0,
            bottom: 0.5,
            left: MARGIN,
            right: MARGIN,
        },
        axis: {
            horizontal: 'stretch',
            vertical: 'stretch',
            left: 0,
            right: 0,
            top: 0,
            bottom: VERTICAL_INNER_PADDING / 2,
        },
    },
});

export class ButtonBlockDrawable<TMeta> extends DesignableBlock<TMeta, ButtonBlock> {
    designSet: DesignSet = {
        'full-between': fullJustify,
        'full-center': fullCenter,
        'full-icon-right': fullIconRight,
        'full-icon-left': fullIconLeft,
        'text-only-between': textOnly,
        'text-only-center': textOnly,
        'text-only-icon-right': textOnly,
        'text-only-icon-left': textOnly,
        'icon-only-between': iconOnly,
        'icon-only-center': iconOnly,
        'icon-only-icon-right': iconOnly,
        'icon-only-icon-left': iconOnly,
        none: textOnly,
    };

    protected getCurrentDesignId(): string {
        const { label, icon, alignment } = this.blockProperties;

        const [hasLabel, hasIcon] = [label !== null, icon !== null];

        if (hasLabel && hasIcon) {
            return 'full-' + alignment;
        } else if (hasLabel) {
            return 'text-only-' + alignment;
        } else if (hasIcon) {
            return 'icon-only-' + alignment;
        }
        return 'none';
    }

    protected override preprocess(block: ButtonBlock): void {
        if (block.content.label) {
            block.content.label = this.context.transform(
                block.content.label,
                ButtonBlockDrawable.GLOBAL_MASK_NAME
            );
        }
    }
}
