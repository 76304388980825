import { Injectable } from '@angular/core';
import { AppError } from '@nirby/js-utils/errors';
import { ImageService } from './image.service';
import { UnsplashService } from './unsplash.service';
import { ImageSource } from '@nirby/media-models';

@Injectable({
    providedIn: 'root',
})
export class ImageFactoryService {
    private services: Record<ImageSource, ImageService<ImageSource> | null> = {
        unsplash: null,
        upload: null,
        link: null,
    };

    constructor(private unsplash: UnsplashService) {
        this.services.unsplash = unsplash;
    }

    public getSourceService<TS extends ImageSource>(
        source: TS
    ): ImageService<TS> {
        const service = this.services[source] as ImageService<TS> | null;
        if (!service) {
            throw new AppError(`No image service for source: ${source}`);
        }
        return service;
    }
}
