import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FileDropOverlayComponent} from './file-drop-overlay/file-drop-overlay.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';


@NgModule({
    declarations: [
        FileDropOverlayComponent,
    ],
    exports: [
        FileDropOverlayComponent,
    ],
    imports: [
        CommonModule,
        FontAwesomeModule
    ]
})
export class FileDropModule {
}
