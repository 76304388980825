import {NirbyProductType} from '../typings';

export const PRODUCT_TYPES: NirbyProductType[] = ['pop', 'prime'];

export const PRODUCT_COLLECTIONS: Record<NirbyProductType, string> = {
    pop: 'campaigns',
    prime: 'primes',
};

export function validateProductType(productType: string): productType is NirbyProductType {
    /**
     * Validates if the given product type is valid.
     * @param productType The product type to validate.
     *
     * @returns - `true` if the product type is valid, `false` otherwise.
     */
    return productType in PRODUCT_TYPES;
}
