import {Component} from '@angular/core';
import {ButtonBlockContent} from '@nirby/models/nirby-player';
import {BlockContentComponent} from '../../block-content.component';

export type ButtonDesign = 'label' | 'icon' | 'icon-label';

@Component({
    selector: 'nirby-button-content-editor',
    templateUrl: './button-content-editor.component.html',
    styleUrls: ['./button-content-editor.component.scss'],
})
export class ButtonContentEditorComponent extends BlockContentComponent<ButtonBlockContent> {
    design: ButtonDesign = 'label';
    label: string | null = null;
    icon: string | null = null;

    setData(content: ButtonBlockContent | null): void {
        if (!content) {
            return;
        }
        this.label = content.label;
        this.icon = content.icon;

        const hasLabel = !!this.label;
        const hasIcon = !!this.icon;
        if (hasLabel && hasIcon) {
            this.design = 'icon-label';
        } else if (hasIcon) {
            this.design = 'icon';
        } else if (hasLabel) {
            this.design = 'label';
        } else {
            this.label = '';
            this.design = 'label';
        }
    }

    onLabelChange(label: string): void {
        this.label = label;
        this.sendData({label: this.label});
    }

    onIconChange(icon: string): void {
        this.icon = icon;
        this.sendData({icon: this.icon});
    }

    setDesign(change: Event): void {
        const design = (change.target as any).value as ButtonDesign;
        let showLabel = false;
        let showIcon = false;
        switch (design as ButtonDesign) {
            case 'label':
                showLabel = true;
                break;
            case 'icon':
                showIcon = true;
                break;
            case 'icon-label':
                showLabel = true;
                showIcon = true;
                break;
            default:
                showLabel = true;
                break;
        }

        this.design = design as ButtonDesign;
        this.label = showLabel ? this.label ?? 'Click me!' : null;
        this.icon = showIcon ? this.icon ?? '\uf061' : null;

        this.sendData({
            label: this.label,
            icon: this.icon,
        });
    }
}
