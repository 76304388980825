import {LandPop, LANDPOP_CONTENT_SCHEMA, LANDPOP_MIGRATOR, LandPopContent} from './landpop.model';
import {z} from 'zod';
import {NirbyDocumentReference} from '@nirby/store/base';
import {Migrator} from '@nirby/store/migrator';
import {Pop, POP_MIGRATOR} from '@nirby/models/editor';
import {buildUploadedReferenceSchema} from '@nirby/store/proxy';
import {COMPILED_POP_SCHEMA, CompiledPop} from './compiled-campaign';
import {PublishedJsonMetaPartial} from './published.model';
import {ZodSchemaBuild} from '@nirby/store/models';

export type LandPopPublished = PublishedJsonMetaPartial<{
    ref: NirbyDocumentReference<LandPop> | null;
    pop: NirbyDocumentReference<Pop> | null;
}>;

export interface CompiledLandPop {
    campaign: CompiledPop;
    content: LandPopContent;
    popId: string;
    workspaceId: string;
}

export const COMPILED_LANDPOP_SCHEMA: ZodSchemaBuild<CompiledLandPop> =
    z.object({
        campaign: COMPILED_POP_SCHEMA,
        content: LANDPOP_CONTENT_SCHEMA,
        popId: z.string(),
        workspaceId: z.string(),
    });

export const PUBLISHED_LANDPOP_SCHEMA: ZodSchemaBuild<LandPopPublished> =
    z.object({
        json: z.string().nullable(),
        ref: buildUploadedReferenceSchema(() => LANDPOP_MIGRATOR).nullable(),
        pop: buildUploadedReferenceSchema(() => POP_MIGRATOR).nullable(),
    });

export const PUBLISHED_LANDPOP_MIGRATOR = new Migrator<LandPopPublished>(
    PUBLISHED_LANDPOP_SCHEMA,
    (): LandPopPublished => ({
        json: null,
        ref: null,
        pop: null,
    }),
);
