import {z} from 'zod';
import {NirbyTimestamp, ZodSchemaBuild} from '@nirby/store/models';
import {Migrator} from '@nirby/store/migrator';
import {DateTime} from 'luxon';
import {TIMESTAMP_SCHEMA} from '@nirby/store/proxy';

export type NaturalDigit = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
export type Digit = 0 | NaturalDigit;

export type Month = `0${NaturalDigit}` | '11' | '12';
export type Year = `${NaturalDigit}${Digit}${Digit}${Digit}`;

export type BillingMonth = `${Year}-${Month}`;

export const BILLING_MONTH_SCHEMA: ZodSchemaBuild<BillingMonth> = z
    .string()
    .regex(/^\d{4}-((0[1-9])|(1[0-2]))$/) as ZodSchemaBuild<BillingMonth>;

export interface WorkspaceUsageCurrent {
    videoStorageMinutesUsage: number;
    videoUploadMinutesUsage: number;
    videoDeliveryMinutesUsage: number;
}

export const WORKSPACE_USAGE_CURRENT_SCHEMA: ZodSchemaBuild<WorkspaceUsageCurrent> =
    z.object({
        videoStorageMinutesUsage: z.number().default(0),
        videoUploadMinutesUsage: z.number().default(0),
        videoDeliveryMinutesUsage: z.number().default(0),
    });

export type WorkspaceUsage = {
    videoStorageMinutesLimit: number;
    videoUploadMinutesLimit: number;
    videoDeliveryMinutesLimit: number;
    videoStorageMinutesUsage: number;
    videoUploadMinutesUsage: number;
    videoDeliveryMinutesUsage: number;
    subscriptionId: string | null;
    billingPeriod: [NirbyTimestamp, NirbyTimestamp];
    month: BillingMonth;
    period: {
        from: NirbyTimestamp;
        to: NirbyTimestamp;
    };
};

export const WORKSPACE_USAGE_SCHEMA: ZodSchemaBuild<WorkspaceUsage> = z.object({
    videoStorageMinutesLimit: z.number().default(0),
    videoUploadMinutesLimit: z.number().default(0),
    videoDeliveryMinutesLimit: z.number().default(0),
    subscriptionId: z.string().nullable().default(null),
    billingPeriod: z.tuple([TIMESTAMP_SCHEMA, TIMESTAMP_SCHEMA]),
    month: BILLING_MONTH_SCHEMA,
    period: z.object({
        from: TIMESTAMP_SCHEMA,
        to: TIMESTAMP_SCHEMA,
    }),
    videoStorageMinutesUsage: z.number().default(0),
    videoUploadMinutesUsage: z.number().default(0),
    videoDeliveryMinutesUsage: z.number().default(0),
});

export const WORKSPACE_USAGE_MIGRATOR = new Migrator<WorkspaceUsage>(
    WORKSPACE_USAGE_SCHEMA,
    () => ({
        videoStorageMinutesLimit: 0,
        videoUploadMinutesLimit: 0,
        videoDeliveryMinutesLimit: 0,
        subscriptionId: null,
        billingPeriod: [DateTime.now(), DateTime.now()],
        month: '2021-01',
        period: {
            from: DateTime.now(),
            to: DateTime.now(),
        },
        videoStorageMinutesUsage: 0,
        videoUploadMinutesUsage: 0,
        videoDeliveryMinutesUsage: 0,
    }),
);
