import { ButtonAlignment } from '@nirby/models/nirby-player';
import { TextAlignment } from '../../designs';
import { lerp } from '@nirby/js-utils/math';

type VerticalTextAlignment = 'bottom' | 'middle' | 'top';

export interface InnerTextArea {
    align: TextAlignment;
    verticalAlign: VerticalTextAlignment;
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface ButtonDesign {
    horizontal: [number, number];
    vertical: [number, number];
    horizontalAlignment: TextAlignment;
    verticalAlignment: VerticalTextAlignment;
}

interface AlignmentDesigns {
    onlyLabel: { label: ButtonDesign };
    onlyIcon: { icon: ButtonDesign };
    labelIcon: { label: ButtonDesign; icon: ButtonDesign };
}

const defaultDesign: ButtonDesign = {
    horizontal: [0, 1],
    vertical: [0, 1],
    horizontalAlignment: 'left',
    verticalAlignment: 'middle',
};

const ICON_SIZE = 0.2;
const ICON_LABEL_SEPARATION = 0.1;

const designs: Record<ButtonAlignment, AlignmentDesigns> = {
    'icon-left': {
        labelIcon: {
            icon: {
                horizontal: [0, ICON_SIZE - ICON_LABEL_SEPARATION / 2],
                vertical: [0, 1],
                horizontalAlignment: 'right',
                verticalAlignment: 'middle',
            },
            label: {
                horizontal: [ICON_SIZE + ICON_LABEL_SEPARATION / 2, 1],
                vertical: [0, 1],
                horizontalAlignment: 'left',
                verticalAlignment: 'middle',
            },
        },
        onlyIcon: {
            icon: {
                horizontal: [0, 1],
                vertical: [0, 1],
                horizontalAlignment: 'center',
                verticalAlignment: 'middle',
            },
        },
        onlyLabel: {
            label: {
                horizontal: [0, 1],
                vertical: [0, 1],
                horizontalAlignment: 'center',
                verticalAlignment: 'middle',
            },
        },
    },
    'icon-right': {
        labelIcon: {
            label: {
                horizontal: [0, 1 - ICON_SIZE - ICON_LABEL_SEPARATION / 2],
                vertical: [0, 1],
                horizontalAlignment: 'right',
                verticalAlignment: 'middle',
            },
            icon: {
                horizontal: [1 - ICON_SIZE + ICON_LABEL_SEPARATION / 2, 1],
                vertical: [0, 1],
                horizontalAlignment: 'left',
                verticalAlignment: 'middle',
            },
        },
        onlyIcon: {
            icon: {
                horizontal: [0, 1],
                vertical: [0, 1],
                horizontalAlignment: 'center',
                verticalAlignment: 'middle',
            },
        },
        onlyLabel: {
            label: {
                horizontal: [0, 1],
                vertical: [0, 1],
                horizontalAlignment: 'center',
                verticalAlignment: 'middle',
            },
        },
    },
    between: {
        labelIcon: {
            label: {
                horizontal: [0, 1 - ICON_SIZE - ICON_LABEL_SEPARATION / 2],
                vertical: [0, 1],
                horizontalAlignment: 'left',
                verticalAlignment: 'middle',
            },
            icon: {
                horizontal: [1 - ICON_SIZE + ICON_LABEL_SEPARATION / 2, 1],
                vertical: [0, 1],
                horizontalAlignment: 'right',
                verticalAlignment: 'middle',
            },
        },
        onlyIcon: {
            icon: {
                horizontal: [0, 1],
                vertical: [0, 1],
                horizontalAlignment: 'center',
                verticalAlignment: 'middle',
            },
        },
        onlyLabel: {
            label: {
                horizontal: [0, 1],
                vertical: [0, 1],
                horizontalAlignment: 'center',
                verticalAlignment: 'middle',
            },
        },
    },
    center: {
        labelIcon: {
            icon: {
                horizontal: [0, 1],
                vertical: [0, 0.5 - ICON_LABEL_SEPARATION / 2],
                horizontalAlignment: 'center',
                verticalAlignment: 'bottom',
            },
            label: {
                horizontal: [0, 1],
                vertical: [0.5 + ICON_LABEL_SEPARATION / 2, 1],
                horizontalAlignment: 'center',
                verticalAlignment: 'top',
            },
        },
        onlyIcon: {
            icon: {
                horizontal: [0, 1],
                vertical: [0, 1],
                horizontalAlignment: 'center',
                verticalAlignment: 'middle',
            },
        },
        onlyLabel: {
            label: {
                horizontal: [0, 1],
                vertical: [0, 1],
                horizontalAlignment: 'center',
                verticalAlignment: 'middle',
            },
        },
    },
};

function getDesignInnerTextArea(
    design: ButtonDesign,
    containerWidth: number,
    containerHeight: number,
    paddingX: number,
    paddingY: number,
): InnerTextArea {
    const hMargin = (paddingX * containerWidth) / 100;
    const vMargin = (paddingY * containerHeight) / 100;

    const hStart = hMargin;
    const hEnd = containerWidth - hMargin;

    const vStart = vMargin;
    const vEnd = containerHeight - vMargin;

    const x = lerp(hStart, hEnd, design.horizontal[0]);
    const y = lerp(vStart, vEnd, design.vertical[0]);
    const width = lerp(hStart, hEnd, design.horizontal[1]) - x;
    const height = lerp(vStart, vEnd, design.vertical[1]) - y;

    return {
        align: design.horizontalAlignment,
        verticalAlign: design.verticalAlignment,
        x,
        y,
        width,
        height,
    };
}

export interface ContentAlignment {
    label: InnerTextArea;
    icon: InnerTextArea;
}

export function getContentAlignment(
    alignment: ButtonAlignment,
    hasLabel: boolean,
    hasIcon: boolean,
    containerWidth: number,
    containerHeight: number,
    paddingX: number,
    paddingY: number,
): ContentAlignment {
    const availableDesigns = designs[alignment];
    let labelDesign: ButtonDesign | undefined;
    let iconDesign: ButtonDesign | undefined;
    if (hasLabel && hasIcon) {
        labelDesign = availableDesigns.labelIcon.label;
        iconDesign = availableDesigns.labelIcon.icon;
    } else if (hasLabel) {
        labelDesign = availableDesigns.onlyLabel.label;
    } else if (hasIcon) {
        iconDesign = availableDesigns.onlyIcon.icon;
    }
    labelDesign = labelDesign ?? defaultDesign;
    iconDesign = iconDesign ?? defaultDesign;
    return {
        label: getDesignInnerTextArea(
            labelDesign,
            containerWidth,
            containerHeight,
            paddingX,
            paddingY,
        ),
        icon: getDesignInnerTextArea(
            iconDesign,
            containerWidth,
            containerHeight,
            paddingX,
            paddingY,
        ),
    };
}
