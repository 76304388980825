import {Pop, POP_MIGRATOR} from '../pop';
import {ROUTE_CONDITION_SCHEMA, RouteCondition} from '@nirby/models/nirby-player';
import {z} from 'zod';
import {Migrator} from '@nirby/store/migrator';
import {buildUploadedReferenceSchema} from '@nirby/store/proxy';
import {ZodSchemaBuild} from '@nirby/store/models';
import {NirbyDocumentReference} from '@nirby/store/base';

export interface CampaignDisplay {
    ref: NirbyDocumentReference<Pop>;
    title: string;
}

export interface RouteData {
    route: RouteCondition | string;
    campaigns: CampaignDisplay[];
}

export const ROUTE_SCHEMA: ZodSchemaBuild<RouteData> = z.object({
    route: z.union([z.string(), ROUTE_CONDITION_SCHEMA]),
    campaigns: z.array(
        z.object({
            ref: buildUploadedReferenceSchema<Pop>(() => POP_MIGRATOR),
            title: z.string(),
        }),
    ),
});

export const ROUTES_MIGRATOR = new Migrator<RouteData>(ROUTE_SCHEMA, () => ({
    campaigns: [],
    route: '',
}));
