import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {WorkspaceService} from '@nirby/shared/database';

@Injectable({
    providedIn: 'root',
})
export class RouteHomeGuard implements CanActivate {
    constructor(private widgets: WorkspaceService, private router: Router) {
    }

    async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Promise<true | UrlTree> {
        const widget = await this.widgets.getActiveWidget();
        if (!widget) {
            return true;
        }
        const homeRoute = widget.data.priorityList.find((r) => r.route === '/')
            ?.routePath?.id;
        if (!homeRoute) {
            return true;
        }
        return this.router.createUrlTree([
            '/workspaces',
            widget.id,
            'routes',
            homeRoute,
        ]);
    }
}
