import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
    AbstractControl,
    FormArray,
    UntypedFormControl,
    FormGroup,
    Validators, UntypedFormArray, UntypedFormGroup,
} from '@angular/forms';
import {BlockActionComponent} from '../../blocks/block-action.component';
import {faPlus, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {AppError} from '@nirby/js-utils/errors';
import {v4} from 'uuid';
import {FormFieldDeclaration, OpenFormAction} from '@nirby/models/nirby-player';
import {CREATORS_ENVIRONMENT, NirbyEnvironment} from '@nirby/environment/base';

@Component({
    selector: 'nirby-action-request-contact-info',
    templateUrl: './action-request-contact-info.component.html',
    styleUrls: ['./action-request-contact-info.component.scss'],
})
export class ActionRequestContactInfoComponent
    extends BlockActionComponent<OpenFormAction>
    implements OnInit, OnDestroy {
    constructor(
        @Inject(CREATORS_ENVIRONMENT) private environment: NirbyEnvironment
    ) {
        super();
    }
    get productionMode(): boolean {
        return this.environment.production;
    }

    get value(): { attributes: OpenFormAction[] } {
        return this.form.value;
    }

    attributes = new UntypedFormArray([]);
    form = new UntypedFormGroup({
        type: new UntypedFormControl('contact'),
        title: new UntypedFormControl('Form'),
        description: new UntypedFormControl(
            'Enter your information below to continue'
        ),
        attributes: this.attributes,
    });

    iconAdd = faPlus;
    iconDelete = faTrashAlt;

    setData(content: OpenFormAction | null): void {
        this.attributes.clear();
        if (!content) {
            return;
        }
        let attribute: FormFieldDeclaration;
        for (attribute of content.options.attributes) {
            this.attributes.push(this.declarationToGroup(attribute));
        }
    }

    declarationToGroup(attribute: FormFieldDeclaration): FormGroup {
        const group: Record<keyof FormFieldDeclaration, UntypedFormControl> = {
            id: new UntypedFormControl(attribute.id, [Validators.required]),
            label: new UntypedFormControl(attribute.label, [Validators.required]),
            required: new UntypedFormControl(attribute.required, [
                Validators.required,
            ]),
            type: new UntypedFormControl(attribute.type, [Validators.required]),
            store: new UntypedFormControl(false),
        };
        return new FormGroup(group);
    }

    declarationGroupToControls(
        group: AbstractControl
    ): Record<keyof FormFieldDeclaration, UntypedFormControl> {
        if (!(group instanceof FormGroup)) {
            throw new AppError('Invalid group');
        }
        const id = group.get('id');
        const label = group.get('label');
        const required = group.get('required');
        const type = group.get('type');
        const store = group.get('store');
        if (
            !(id instanceof UntypedFormControl) ||
            !(label instanceof UntypedFormControl) ||
            !(required instanceof UntypedFormControl) ||
            !(type instanceof UntypedFormControl) ||
            !(store instanceof UntypedFormControl)
        ) {
            throw new AppError('Invalid group');
        }
        return {id, label, required, type, store};
    }

    add(): void {
        const group = this.declarationToGroup({
            id: v4(),
            label: 'My field',
            required: true,
            type: 'string',
            store: false,
        });
        this.attributes.push(group);
    }

    remove(idx: number): void {
        this.attributes.removeAt(idx);
    }
}
