import {ArtBoard} from './art-board';
import {Block, BlockFactory} from './blocks';
import {ArtBoardItem} from './art-board-item';
import {Logger} from '@nirby/logger';
import {NirbyContext} from '@nirby/runtimes/context';
import {AnyBlock} from '@nirby/models/nirby-player';


export interface ArtBoardItemState<TMeta,
    T extends ArtBoardItem<TMeta> = NirbyBoardItemStandard<TMeta>,
    > {
    id: string;
    type: T['type'];
    properties: T['properties'];
    meta: TMeta | null;
}

export type NirbyBoardItemStandard<TMeta> = ArtBoard<TMeta> | Block<TMeta>;

export interface NirbyBoardStateNode<TMeta, T extends NirbyBoardItemStandard<TMeta> = NirbyBoardItemStandard<TMeta>> extends ArtBoardItemState<TMeta, T> {
    children: NirbyBoardStateNode<TMeta>[];
}

/**
 * A class that can be used to create {@link ArtBoardItem}.
 */
export class ArtBoardItemFactory {
    public static readonly instance = new ArtBoardItemFactory();

    /**
     * Creates an art board item from a state.
     * @param state The state to create the item from.
     * @param context The context to create the item in.
     * @param editable Whether the item is editable.
     * @param children The children items
     *
     * @returns - The created item.
     */
    public create<TMeta, T extends ArtBoardItem<TMeta>>(
        state: ArtBoardItemState<TMeta, T>,
        context: NirbyContext,
        editable: boolean,
        children: NirbyBoardStateNode<TMeta>[] = [],
    ): T | null {
        switch (state.type) {
            case 'art-board':
                return new ArtBoard<TMeta>(
                    state.id,
                    state.meta,
                    context,
                    editable,
                    {
                        ...({...state.properties} as ArtBoard<TMeta>['properties']),
                    },
                    children,
                ) as unknown as T;
            case 'block': {
                const block = state.properties as AnyBlock;
                block.hash = state.id;
                return BlockFactory.fromBlock(
                    context,
                    block,
                    editable,
                ) as unknown as T;
            }
            default:
                Logger.warnStyled(
                    'ART-BOARD-ITEM',
                    'Unknown art board item type: ' + state.type,
                );
                return null;
        }
    }

    /**
     * Creates a state from an art board item.
     * @param item The item to create the state from.
     *
     * @returns - The created state.
     */
    public stateOf<TMeta, T extends NirbyBoardItemStandard<TMeta>>(
        item: T,
    ): NirbyBoardStateNode<TMeta, T> {
        return {
            id: item.id,
            type: item.type,
            properties: item.properties,
            children: item.children,
            meta: item.meta,
        };
    }

    /**
     * Checks if a state is of a given type.
     * @param value The state to check.
     * @param type The type to check for.
     *
     * @returns - Whether the state is of the given type.
     */
    public isStateOfType<TMeta, T extends NirbyBoardItemStandard<TMeta>>(
        value: ArtBoardItemState<TMeta, NirbyBoardItemStandard<TMeta>>,
        type: T['type'],
    ): value is ArtBoardItemState<TMeta, T> {
        return value.type === type;
    }
}
