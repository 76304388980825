<div class="d-flex flex-row justify-content-center mb-3">
    <div class="btn-group btn-group-pricing">
        <button class="btn btn-light billing-interval-btn" [ngClass]="{active: mode === 'images'}"
                (click)="mode = 'images'">
            <strong>Images</strong>
        </button>
        <button class="btn btn-light billing-interval-btn" [ngClass]="{active: mode === 'videos'}"
                (click)="mode = 'videos'">
            <strong>Videos</strong>
        </button>
    </div>
</div>
<ng-container *ngIf="mode === 'images'">
    <nirby-media-library-images></nirby-media-library-images>
</ng-container>
<ng-container *ngIf="mode === 'videos'">
    <nirby-media-library-videos></nirby-media-library-videos>
</ng-container>
