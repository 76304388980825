import { z } from 'zod';

export const VECTOR_2D_SCHEMA = z.object({
    x: z.number(),
    y: z.number(),
});

export type Vector2d = z.infer<typeof VECTOR_2D_SCHEMA>;

/**
 * Class with implementations for common operations for 2D vectors.
 */
export class Vector2 implements Vector2d {
    /**
     * Constructor.
     * @param x The x coordinate.
     * @param y The y coordinate.
     */
    constructor(
        public readonly x: number,
        public readonly y: number,
    ) {
    }

    /**
     * Creates an instance from a {@link Vector2d} object.
     * @param vector The vector to create from.
     *
     * @returns The new instance.
     */
    public static fromModel(vector: Vector2d): Vector2 {
        return new Vector2(vector.x, vector.y);
    }

    /**
     * Creates an instance from an array with an element for each coordinate.
     * @param array The array to create from.
     *
     * @returns - The new instance.
     */
    public static fromArray(array: [number, number]): Vector2 {
        return new Vector2(array[0], array[1]);
    }

    /**
     * Converts to array.
     *
     * @returns - The array.
     */
    public toArray(): [number, number] {
        return [this.x, this.y];
    }

    /**
     * Converts to {@link Vector2d} object (ideal for serialization).
     *
     * @returns - The {@link Vector2d} object.
     */
    public toModel(): Vector2d {
        return {
            x: this.x,
            y: this.y,
        };
    }

    /**
     * Adds another vector to this vector.
     * @param other The other vector.
     *
     * @returns - The result vector.
     */
    public add(other: Vector2d): Vector2 {
        return new Vector2(this.x + other.x, this.y + other.y);
    }

    /**
     * Subtracts another vector from this vector.
     * @param other The other vector.
     *
     * @returns - The result vector.
     */
    public subtract(other: Vector2d): Vector2 {
        return new Vector2(this.x - other.x, this.y - other.y);
    }

    /**
     * Scales this vector by a factor.
     * @param factor The factor.
     *
     * @returns - The result vector.
     */
    public scale(factor: number): Vector2 {
        return new Vector2(this.x * factor, this.y * factor);
    }

    /**
     * Calculates the dot product of this vector and another vector.
     * @param other The other vector.
     *
     * @returns - The dot product.
     */
    public dot(other: Vector2d): number {
        return this.x * other.x + this.y * other.y;
    }

    /**
     * Calculates the cross product of this vector and another vector.
     * @param other The other vector.
     *
     * @returns - The cross product.
     */
    public cross(other: Vector2d): number {
        return this.x * other.y - this.y * other.x;
    }
}
