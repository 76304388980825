import { Block } from '../block';
import { VideoBlock } from '@nirby/models/nirby-player';
import Konva from 'konva';
import { FlexNames, GroupFlex } from '../../group-flex';
import { getImageClip } from '../../utils';
import {
    BehaviorSubject,
    distinctUntilChanged,
    Observable, of,
    shareReplay,
    Subscription,
    switchMap,
} from 'rxjs';
import {EMPTY_IMAGE, waitImageLoad} from '../utils';

import FontFaceObserver from 'fontfaceobserver';

const COVER_ALPHA = 0.3;

const VIDEO_DESIGN: FlexNames = {
    'video-container': {
        mainAxisAlignment: 'center',
        crossAxisAlignment: 'center',
        padding: {
            main: {
                start: 10,
                end: 10,
            },
            cross: {
                start: 10,
                end: 10,
            },
        },
    },
    thumbnail: {
        display: 'fill',
    },
    'play-btn': {
        display: 'flex',
        flex: 1,
        mainAxisConstraints: {
            max: 40,
        },
        crossAxisConstraints: {
            max: 40,
        },
    },
    'button-icon': {
        display: 'fill',
        aspectRatio: 1,
    },
    'button-back': {
        display: 'fill',
        aspectRatio: 1,
    },
    cover: {
        display: 'fill',
    },
};

/**
 * Video block
 */
export class VideoBlockDrawable<TMeta> extends Block<TMeta, VideoBlock, Konva.Group> {
    private subscription = new Subscription();

    /**
     * The current image.
     */
    get imageNode(): Konva.Image {
        if (!this.thumbnail) {
            throw new Error(
                'Trying to get image from uninitialized video drawable'
            );
        }
        return this.thumbnail;
    }

    private thumbnail?: Konva.Image;
    private cover?: Konva.Rect;
    private buttonBackground?: Konva.Rect;

    private readonly imageSourceController = new BehaviorSubject<string | null>(
        null
    );
    public readonly image$: Observable<HTMLImageElement> =
        this.imageSourceController.pipe(
            distinctUntilChanged(),
            switchMap((image) =>
                image ? waitImageLoad(image) : of(EMPTY_IMAGE)
            ),
            shareReplay({ refCount: true, bufferSize: 1 })
        );

    groupFlex?: GroupFlex;

    /**
     * The video cover alpha.
     * @param alpha The alpha value.
     */
    setCoverAlpha(alpha: number): void {
        this.cover?.fill(`rgba(0, 0, 0, ${alpha})`);
    }

    /**
     * The button alpha.
     * @param alpha The alpha value.
     */
    setButtonAlpha(alpha: number): void {
        this.buttonBackground?.fill(`rgba(0, 0, 0, ${alpha})`);
    }

    /**
     * Switches to a new thumbnail image.
     * @param source The new image source.
     */
    changeImageSource(source: string): void {
        this.imageSourceController.next(source);
    }

    /**
     * Creates the video block to be drawn.
     * @protected
     *
     * @returns The video block.
     */
    protected async generateShape(): Promise<Konva.Group> {
        const font = new FontFaceObserver('FontAwesome');
        const [, image] = await Promise.all([font.load(), EMPTY_IMAGE]);

        const group = new Konva.Group({
            name: 'video-container',
            x: this.configPosition.x,
            y: this.configPosition.y,
            width: this.configSize.x,
            height: this.configSize.y,
        });
        const thumbnail = new Konva.Image({
            name: 'thumbnail',
            image,
            width: group.width(),
            height: group.height(),
            draggable: this.editable,
        });
        this.thumbnail = thumbnail;
        const buttonIcon = new Konva.Text({
            name: 'button-icon',
            fill: 'rgb(255, 255, 255)',
            fontFamily: 'FontAwesome',
            text: '\uf04b',
            align: 'center',
            verticalAlign: 'middle',
        });
        this.buttonBackground = new Konva.Rect({
            name: 'button-back',
            cornerRadius: 256,
            fill: 'rgba(0, 0, 0, 0)',
            strokeWidth: 2,
            stroke: 'rgb(255, 255, 255)',
            width: 16,
            height: 16,
        });
        const buttonGroup = new Konva.Group({
            name: 'play-btn',
        });

        buttonGroup.add(buttonIcon);
        buttonGroup.add(this.buttonBackground);

        this.cover = new Konva.Rect({
            name: 'cover',
            fill: `rgba(0, 0, 0, ${COVER_ALPHA})`,
        });

        group.add(this.cover);
        group.add(this.thumbnail);
        group.add(buttonGroup);

        this.groupFlex = new GroupFlex(group, VIDEO_DESIGN);

        group.x(this.configPosition.x);
        group.y(this.configPosition.y);
        group.width(this.configSize.x);
        group.height(this.configSize.y);

        this.groupFlex.update();
        this.cover.moveToTop();
        buttonGroup.moveToTop();
        buttonIcon.moveToTop();

        this.subscription.add(
            this.image$.subscribe((image) => {
                thumbnail.image(image);
                this.crop();
                this.batchDraw();
            })
        );

        return group;
    }

    /**
     * Updates the shape from the block configuration.
     * @protected
     */
    protected async updateShapeFromBlock(): Promise<void> {
        const thumbnailSource =
            this.properties.content.video?.thumbnail ?? null;
        this.imageSourceController.next(thumbnailSource);
    }

    /**
     * Crops the thumbnail image.
     * @private
     */
    private crop(): void {
        const thumbnail = this.thumbnail;
        if (!thumbnail || !this.shape) {
            return;
        }
        const img = thumbnail.image();
        if (!img) {
            return;
        }
        thumbnail.setAttrs({
            lastCropUsed: 'center center',
            width: this.shape.width(),
            height: this.shape.height(),
            x: 0,
            y: 0,
            ...getImageClip(
                img as HTMLImageElement,
                {
                    width: this.shape.width(),
                    height: this.shape.height(),
                },
                'center center'
            ),
        });
    }

    /**
     * Subscribes to the block events.
     * @param shape The shape to subscribe to.
     * @protected
     *
     * @returns The subscription.
     */
    protected override subscribeToEvents(shape: Konva.Group): Subscription {
        const subscription = super.subscribeToEvents(shape);
        if (this.editable) {
            subscription.add(
                this.transformController.resizer.postResize$.subscribe(() => {
                    this.groupFlex?.update();
                    this.crop();
                })
            );
        } else {
            subscription.add(
                this.controllerCursor.enter$.subscribe(() => {
                    this.setCursor('pointer');
                    this.setCoverAlpha(0.1);
                    this.setButtonAlpha(0.4);
                    this.batchDraw();
                })
            );
            subscription.add(
                this.controllerCursor.leave$.subscribe(() => {
                    this.setCursor('default');
                    this.setCoverAlpha(COVER_ALPHA);
                    this.setButtonAlpha(0);
                    this.batchDraw();
                })
            );
            subscription.add(
                this.controllerCursor.click$.subscribe(() => {
                    const video = this.properties.content.video;
                    if (video) {
                        this.trigger('click', [
                            {
                                id: null,
                                type: 'video',
                                options: video,
                            },
                        ]);
                    }
                })
            );
        }
        return subscription;
    }

    /**
     * Disposes the shape
     */
    override dispose() {
        super.dispose();
        this.subscription.unsubscribe();
        this.subscription = new Subscription();
    }
}
