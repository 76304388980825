import {Component, Inject} from '@angular/core';
import {CREATORS_ENVIRONMENT, NirbyEnvironment} from '@nirby/environment/base';
import {AuthenticationService} from '@nirby/shared/database';
import {PlansService} from '../../../../services/plans.service';

@Component({
    selector: 'nirby-settings-personal-billing',
    templateUrl: './settings-personal-billing.component.html',
    styleUrls: ['./settings-personal-billing.component.scss']
})
/**
 * Component for the billing page of the personal settings
 */
export class SettingsPersonalBillingComponent {
    billingCustomerPortalUrl = this.environment.billingCustomerPortalUrl;

    /**
     * Constructor.
     * @param environment The environment.
     * @param auth The authentication service.
     * @param plans The plans service.
     */
    constructor(
        @Inject(CREATORS_ENVIRONMENT) private environment: NirbyEnvironment,
        private auth: AuthenticationService,
        private plans: PlansService
    ) {
    }
}
