import type {AnalyticsModule} from '../routes/analytics/analytics.module';
import {Type} from '@angular/core';

export * from './auth/auth.module';
export * from './tutorial/tutorial.module';
export * from './toolbar/toolbar.module';
export * from './workspace-creation/workspace-list.module';
export * from './action-form-control/action-form-control.module';
export * from './media-library/components';

/**
 * Loads the AnalyticsModule lazily.
 *
 * @returns {Promise<AnalyticsModule>} The AnalyticsModule.
 */
export function loadAnalyticsModule(): Promise<Type<AnalyticsModule>> {
    return import('../routes/analytics/analytics.module').then((m) => m.AnalyticsModule);
}
