import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

type OnChange = (num: number) => void;
type OnTouched = () => void;

@Component({
    selector: 'nirby-number-input',
    templateUrl: './number-input.component.html',
    styleUrls: ['./number-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NumberInputComponent),
            multi: true,
        },
    ],
})
export class NumberInputComponent implements ControlValueAccessor {

    @Input() placeholder = '';
    @Input() decimal = false;
    @Input() signed = true;

    disabled = false;

    value = 0;

    onChange: OnChange = () => {
        return;
    };
    onTouched: OnTouched = () => {
        return;
    };

    get regex(): RegExp {
        return this.decimal ? /^([-+])?[0-9]+(\.[0-9]+)?$/ : /^([-+])?[0-9]+?$/;
    }

    registerOnChange(fn: OnChange): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: OnTouched): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(num?: number): void {
        this.value = num ?? 0;
    }

    onValueChange(evt: Event): void {
        const value: string = (evt.target as any).value;
        if (value.match(this.regex)) {
            this.value = +value;
            if (!this.signed && this.value < 0) {
                this.value = 0;
            }
            this.onChange(this.value);
        }
    }

    formatValue(evt: Event): void {
        const target = evt.target as any;
        target.value = this.value.toString();
    }
}
