import {Inject, Injectable} from '@angular/core';
import {AuthenticationService} from '../auth';

import {WorkspaceService} from './workspace.service';
import {PopService} from './pop.service';
import {Database} from '@nirby/ngutils';
import {CompiledPop} from '@nirby/models/pop';
import {AppError} from '@nirby/js-utils/errors';
import {ImageRecord} from '@nirby/media-models';
import {Droplet, DropletPrivacySetting, Pop} from '@nirby/models/editor';
import {CREATORS_ENVIRONMENT, NirbyEnvironment} from '@nirby/environment/base';
import {COLLECTION_KEYS} from '@nirby/store/collections';
import {NirbyDocumentReference} from '@nirby/store/base';

@Injectable({
    providedIn: 'root',
})
/**
 * Service to manage Droplets.
 */
export class DropletsService {
    /**
     * Constructor.
     * @param db The Firestore service.
     * @param environment The environment.
     * @param widgets The workspace service.
     * @param auth The authentication service.
     * @param pops The pop service.
     * @protected
     */
    protected constructor(
        private readonly db: Database,
        @Inject(CREATORS_ENVIRONMENT)
        private readonly environment: NirbyEnvironment,
        private widgets: WorkspaceService,
        private auth: AuthenticationService,
        private pops: PopService,
    ) {
    }

    collection = this.db.collection(COLLECTION_KEYS.DROPLETS);

    async createPop(
        widgetId: string,
        dropletId: string,
        title?: string,
    ): Promise<NirbyDocumentReference<Pop>> {
        return await this.db.runTransaction<
            NirbyDocumentReference<Pop>
        >(async (transaction) => {
            const droplet = await this.collection.getTransaction(
                transaction,
                dropletId,
            );
            if (!droplet) {
                throw new AppError('Droplet not found');
            }
            const compiled: CompiledPop = JSON.parse(droplet.data.compiledPop);
            compiled.title = title ?? compiled.title;
            await this.collection.updateTransaction(transaction, droplet.id, {
                used: droplet.data.used + 1,
            });
            return await this.pops.createFromCompiled(
                widgetId,
                compiled,
                transaction,
            );
        });
    }

    async createFromPop(
        name: string,
        description: string,
        image: ImageRecord | null,
        price: number,
        featured: boolean,
        tags: string[],
        privacy: DropletPrivacySetting,
        widgetId: string,
        popId: string,
    ): Promise<NirbyDocumentReference<Droplet>> {
        const compiled = await this.pops.compile(widgetId, popId);
        const creator = await this.auth.getAuthenticatedUserReference();
        return await this.collection.add({
            used: 0,
            appVersion: this.environment.version,
            compiledPop: JSON.stringify(compiled),
            creator,
            featured,
            name,
            description,
            thumbnail: image,
            price,
            tags,
            privacy,
        });
    }
}
