import { ArtBoardItem, ArtBoardItemKonvaNode } from '../../art-board-item';
import Konva from 'konva';
import Vector2d = Konva.Vector2d;
import { BlockEventController } from './events';

export class BlockShapeController<TMeta> {
    constructor(private readonly events: BlockEventController<TMeta>) {}

    public get item(): ArtBoardItem<TMeta> {
        return this.events.item;
    }

    apply(fn: (shape: ArtBoardItemKonvaNode) => void) {
        if (this.item.shape) {
            fn(this.item.shape);
        }
    }

    moveDown(): void {
        this.apply((shape) => shape.moveDown());
    }

    moveToTop(): void {
        this.apply((shape) => shape.moveToTop());
    }

    moveUp(): void {
        this.apply((shape) => shape.moveUp());
    }

    moveToBottom(): void {
        this.apply((shape) => shape.moveToBottom());
    }

    setZIndex(value: number): void {
        this.apply((shape) => shape.zIndex(value));
    }

    public moveTo(position: Vector2d, asUserChange = false): void {
        this.apply((shape) => {
            shape.position(position);
            if (asUserChange) {
                this.events.notifyUpdate();
            }
        });
    }
}
