import {Component} from '@angular/core';
import {IconBlockStyle} from '@nirby/models/nirby-player';
import {BlockStyleComponent} from '../../block-style.component';

@Component({
    selector: 'nirby-icon-style',
    templateUrl: './icon-style.component.html',
    styleUrls: ['./icon-style.component.scss'],
})
export class IconStyleComponent extends BlockStyleComponent<IconBlockStyle> {
    color = '#000000';

    setData(style: IconBlockStyle): void {
        this.color = style.color;
    }

    onColorChange(color: string | null): void {
        this.color = color ?? '#ffffff';
        this.sendData({color: this.color});
    }
}
