import {Component, OnDestroy, OnInit} from '@angular/core';
import {BlockContentComponent} from '../../block-content.component';
import {FormControl, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {defaults, WidgetQuestionBlock} from '@nirby/models/nirby-player';

@Component({
    selector: 'nirby-block-question-content',
    templateUrl: './block-question-content.component.html',
    styleUrls: ['./block-question-content.component.scss'],
})
export class BlockQuestionContentComponent
    extends BlockContentComponent<WidgetQuestionBlock['content']>
    implements OnInit, OnDestroy {
    questionControl = new FormControl<string>(
        defaults.blocks.Question.content.question,
        {nonNullable: true},
    );
    successLabelControl = new FormControl<string>(
        defaults.blocks.Question.content.successLabel ?? '',
        {nonNullable: true},
    );

    form = new FormGroup({
        question: this.questionControl,
        successLabel: this.successLabelControl,
    });

    subscription = new Subscription();

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngOnInit(): void {
        this.subscription.add(
            this.form.valueChanges.subscribe((value) => {
                this.sendData({
                    question: value.question,
                    successLabel: value.successLabel,
                });
            }),
        );
    }

    public setData(content: WidgetQuestionBlock['content'] | null): void {
        if (!content) {
            return;
        }
        this.questionControl.setValue(content.question, {emitEvent: false});
        this.successLabelControl.setValue(content.successLabel ?? '', {emitEvent: false});
    }
}
