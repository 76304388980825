import {Injectable} from '@angular/core';
import {combineLatest, firstValueFrom, Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {FirestoreService} from '@nirby/ngutils';
import {NirbyCollection, NirbyDocument} from '@nirby/store/base';
import {MediaItem, MediaType} from '@nirby/media-models';
import {MediaError} from '@nirby/js-utils/errors';
import {AuthenticationService, FireStorageService, UploadTask} from '@nirby/shared/database';
import {TypeStrict} from '@nirby/shared/typestrict';
import {getDownloadURL} from 'firebase/storage';
import {COLLECTION_KEYS} from '@nirby/store/collections';
import {DateTime} from 'luxon';
import {Modeled} from '@nirby/store/models';

type MimeTypeCode = 'image/png' | string;

@Injectable({
    providedIn: 'root',
})
/**
 * Service to handle the Media library of the users.
 */
export class MediaLibraryService {
    /**
     * Constructor.
     * @param storage - FireStorageService
     * @param firestore - FirestoreService
     * @param auth - AuthenticationService
     */
    constructor(
        private storage: FireStorageService,
        private readonly firestore: FirestoreService,
        private auth: AuthenticationService,
    ) {
    }

    private static readonly mimeTypes: Record<MimeTypeCode, MediaType> = {
        'image/png': 'image',
        'image/jpg': 'image',
        'image/jpeg': 'image',
        'image/gif': 'image',
        'image/webp': 'image',
    };

    /**
     * Get the media type of a file.
     * @param file - File
     *
     * @returns - The media type of the file.
     */
    public static getFileMediaType(file: File): MediaType {
        return MediaLibraryService.mimeTypes[file.type] ?? 'other';
    }

    /**
     * Gets a media item for a given file.
     * @param file - The file
     * @param task - The upload task
     * @private
     *
     * @returns - The media item
     */
    private static async getFileMediaItem(
        file: File,
        task: UploadTask<null>,
    ): Promise<MediaItem | null> {
        const code: MediaType = MediaLibraryService.getFileMediaType(file);
        switch (code) {
            case 'image': {
                const userFile = await task.file;
                return {
                    content: {
                        id: '',
                        lastUrl: userFile.url,
                        source: 'upload',
                        metadata: {
                            uploaderUserId: userFile.uploaderUserId,
                        },
                    },
                    path: userFile.ref.fullPath,
                    type: 'image',
                };
            }
            case 'other':
                return null;
        }
    }

    collection(userId: string): NirbyCollection<MediaItem> {
        return this.firestore.collection(
            COLLECTION_KEYS.USERS.doc(userId),
            COLLECTION_KEYS.MEDIA,
        );
    }

    /**
     * Uploads a file to the media library.
     * @param file - The file
     *
     * @returns - The media item
     */
    async upload(file: File): Promise<UploadTask<MediaItem | null>> {
        if (file.size >= 2 * 1024 * 1024) {
            throw new MediaError('Files must have a maximum of 2mb of size');
        }
        const originalTask = await this.storage.uploadPublic(file);
        const metadata = MediaLibraryService.getFileMediaItem(
            file,
            originalTask,
        ).then(async (meta) => {
            if (meta) {
                const userId = await this.auth.getUserId();
                await this.collection(userId).add(meta);
            }
            return meta;
        });
        return {...originalTask, metadata};
    }

    /**
     * Query all the files of the current user.
     * @param source - The source of the media item
     *
     * @returns - The observable of the media items
     */
    queryAllFiles<Item extends MediaItem>(
        source: Item['type'],
    ): Observable<Modeled<Item>[]> {
        const library$ = this.auth.userId$.pipe(
            switchMap((userId) => this.collection(userId).query().watch()),
        );
        const files$ = this.storage.queryAllFiles();
        return combineLatest([files$, library$]).pipe(
            switchMap(async ([files, library]) => {
                const userId = await this.auth.getUserId();
                return Promise.all(
                    files.map(async (f): Promise<Modeled<Item>> => {
                        const defaultItemFn = async (): Promise<Modeled<Item>> => {
                            if (source !== 'image') {
                                throw new Error('Not implemented');
                            }
                            return {
                                content: {
                                    id: '',
                                    lastUrl: TypeStrict.toString(
                                        await getDownloadURL(f),
                                    ),
                                    source: 'upload',
                                    metadata: {uploaderUserId: userId},
                                },
                                path: f.fullPath,
                                type: source,
                                _docId: '',
                                _creationTime: DateTime.now(),
                                _lastUpdate: DateTime.now(),
                                _databaseVersion: 0,
                            } as unknown as Modeled<Item>;
                        };
                        const found: NirbyDocument<Item> | undefined = library
                            .map((l) => l as NirbyDocument<MediaItem> | NirbyDocument<Item>)
                            .find((l): l is NirbyDocument<Item> => l.data.type === source && l.ref.path === f.fullPath && l.ref.id === f.name);
                        return found?.toModeled() ?? await defaultItemFn();
                    }),
                );
            }),
        );
    }

    /**
     * Deletes a media item.
     * @param userId - The user id
     * @param id - The media item id
     *
     * @returns - A promise of the deletion
     */
    async delete(userId: string, id: string): Promise<void> {
        const media = await firstValueFrom(this.collection(userId).get(id));
        if (!media) {
            throw new MediaError('Media item not found');
        }
        await this.collection(userId).delete(id);
        await this.deleteByPath(media?.ref.path);
    }

    /**
     * Deletes a media item by its path.
     * @param path - The path
     *
     * @returns - A promise of the deletion
     */
    async deleteByPath(path: string): Promise<void> {
        await this.storage.delete(path);
    }
}
