import {Injectable} from '@angular/core';
import {fromEvent, Observable, Subject, switchMap, timer} from 'rxjs';
import {distinctUntilChanged, filter, map, startWith} from 'rxjs/operators';
import {Mime, MimeName, MimeType} from '@nirby/media/file-detector';
import {Logger} from '@nirby/logger';

/**
 * A target for file drag and drop events.
 */
class FileDropTarget {
    private readonly droppedFile = new Subject<File[]>();
    private readonly allowedMimeTypes: Set<MimeName>;

    public readonly droppedFile$ = this.droppedFile.asObservable();

    /**
     * Constructor.
     * @param label - The label for the target.
     * @param allowedMimeTypes - The allowed mime types for the target.
     */
    constructor(
        private readonly label: string,
        allowedMimeTypes: Set<MimeName | Mime> | (MimeName | Mime)[],
    ) {
        const mimeTypes = new Set<MimeName>();
        for (const mimeType of allowedMimeTypes) {
            if (typeof mimeType === 'string') {
                const mime = Mime.fromString(mimeType);
                if (mime === null) {
                    Logger.warn(`Invalid mime type: ${mimeType}`);
                    continue;
                }
                mimeTypes.add(mime.toString());
            } else {
                mimeTypes.add(mimeType.toString());
            }
        }
        this.allowedMimeTypes = mimeTypes;
    }

    /**
     * Notifies the target that a file was dropped.
     * @param files - The files that were dropped.
     */
    public drop(files: File[]): void {
        const filteredFiles = files.filter(
            file => this.allowedMimeTypes.has(file.type as MimeName),
        );
        if (filteredFiles.length === 0) {
            return;
        }
        this.droppedFile.next(filteredFiles);
    }
}

export interface DroppedFileList {
    list: File[];
    targets: FileDropTarget[];
}

@Injectable({
    providedIn: 'root',
})
/**
 * Service to handle file drag and drop events.
 */
export class FileDropService {
    public static UPLOADABLE_IMAGE_TYPES: MimeType[] = [
        'image/jpg',
        'image/jpeg',
        'image/png',
        'image/svg+xml',
    ];

    private lastId = 0;
    private readonly targetsMap: Map<number, FileDropTarget> = new Map<number, FileDropTarget>();

    /**
     * Gets the current targets.
     */
    protected get targets(): FileDropTarget[] {
        return Array.from(this.targetsMap.values());
    }

    /**
     * Watches a document drag event.
     * @param eventName - The event name.
     * @private
     *
     * @returns - An observable that emits the event.
     */
    private static watchDocumentDragEvent(eventName: 'drop' | 'dragover' | 'dragleave'): Observable<DragEvent> {
        return new Observable<DragEvent>(
            observer => {
                /**
                 * Emits the drag event
                 * @param event - The drag event.
                 */
                const onDragEvent = (event: DragEvent): void => {
                    event.preventDefault();
                    event.stopPropagation();
                    observer.next(event);
                };
                document.addEventListener(eventName, onDragEvent);
                return () => document.removeEventListener(eventName, onDragEvent);
            },
        );
    }

    /**
     * Watches the files being dragged over the document.
     *
     * @returns - An observable that emits the files being dragged over the document.
     */
    public watchHoveringFiles(): Observable<boolean> {
        return FileDropService.watchDocumentDragEvent('dragover').pipe(
            filter(() => this.isExpectingDrop),
            map((event): boolean => !!event.dataTransfer),
        ).pipe(
            switchMap((hasFiles) => {
                return timer(100).pipe(
                    map(() => false),
                    startWith(hasFiles),
                );
            }),
            distinctUntilChanged(),
        );
    }

    /**
     * Checks if at least one target is watching for dropped files.
     */
    protected get isExpectingDrop(): boolean {
        return this.targetsMap.size > 0;
    }

    /**
     * Converts a FileList to an array.
     * @param fileList - The FileList to convert.
     * @private
     *
     * @returns - The converted array.
     */
    private fileListToArray(fileList: FileList): File[] {
        const files: File[] = [];
        for (let i = 0; i < fileList.length; i++) {
            files.push(fileList[i]);
        }
        return files;
    }

    /**
     * Watches when a file is dropped on the document.
     *
     * @returns - An observable that emits the dropped files.
     */
    public watchDroppedFiles(): Observable<DroppedFileList> {
        return FileDropService.watchDocumentDragEvent('drop').pipe(
            map(event => event.dataTransfer?.files ?? null),
            filter((files): files is FileList => files !== null && this.isExpectingDrop),
            map(files =>
                ({
                    list: this.fileListToArray(files),
                    targets: this.targets,
                })),
        );
    }

    /**
     * Watch the first file dropped on the document.
     * @param title - The title of the target.
     * @param allowedMimeTypes - The allowed mime types for the target.
     * @param fileInput - If given, the files selected through the file input will be included.
     *
     * @returns - An observable that emits the dropped files.
     */
    public watch(
        title: string,
        allowedMimeTypes: Set<MimeType | Mime> | (MimeType | Mime)[],
        fileInput?: HTMLInputElement,
    ): Observable<File[]> {
        const target = new FileDropTarget(title, allowedMimeTypes);

        return new Observable(
            observer => {
                const id = this.lastId++;
                this.targetsMap.set(id, target);

                const subscription = target.droppedFile$.subscribe(
                    files => observer.next(files),
                );

                if (fileInput) {
                    subscription.add(
                        fromEvent(fileInput, 'change').pipe(
                            map(() => fileInput.files),
                            filter((files): files is FileList => files !== null),
                            map(files => this.fileListToArray(files)),
                            filter(files => files.length > 0),
                        ).subscribe(
                            files => target.drop(files),
                        ),
                    );
                }

                return {
                    unsubscribe: () => {
                        subscription.unsubscribe();
                        this.targetsMap.delete(id);
                    },
                };
            },
        );
    }
}
