<div class="form-group">
    <label for="event">Event</label>
    <input
        id="event"
        class="form-control"
        type="text"
        [formControl]="eventControl"
    />
</div>
<div class="form-group">
    <label for="tags">Tags</label>
    <div id="tags" class="d-flex flex-column">
        <div *ngFor="let tag of tags; let i = index" class="flex-grow-1 d-flex flex-row">
            <div class="d-flex flex-column flex-fill">
                <input class="form-control" type="text" [formControl]="tag"/>
                <span class="text-danger" *ngIf="tag.errors && tag.errors['required']">
                    This field is required
                </span>
            </div>
            <button class="btn btn-outline-danger" (click)="removeTag(i)">
                <fa-icon [icon]="iconDelete"></fa-icon>
            </button>
        </div>
        <button
            type="button"
            class="btn btn-brand-primary w-100"
            (click)="addTag('my-tag')"
        >
            <fa-icon [icon]="iconAdd"></fa-icon>
        </button>
    </div>
</div>
