import {Injectable} from '@angular/core';
import {VideoLiteService} from './video-lite.service';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
/**
 * Service for fetching video data from Vimeo
 */
export class VimeoLiteService implements VideoLiteService {
    /**
     * Constructor.
     * @param config The configuration for the service
     * @param http The HTTP client
     */
    constructor(
        private http: HttpClient
    ) {
    }

    /**
     * Get the embed URL for the given video ID.
     * @param id The video ID
     *
     * @returns The embed URL for the video
     */
    getEmbedUrlFromId(id: string): string {
        return `https://player.vimeo.com/video/${id}`;
    }
}
