import {AppError} from '@nirby/js-utils/errors';
import {InnerPartial} from "@nirby/js-utils/types";

export class TypeStrict {
    static toString(value: unknown): string | null {
        if (value === null || value === undefined) {
            return null;
        }
        if (typeof value === 'string') {
            return value;
        }
        return null;
    }

    static toInt(value: unknown): number | null {
        const parsed = TypeStrict.toFloat(value);
        return parsed !== null ? Math.round(parsed) : parsed;
    }

    static toFloat(value: unknown): number | null {
        if (value === null || value === undefined) {
            return null;
        }
        if (typeof value === 'number') {
            return value;
        }
        return null;
    }

    static toBool(value: unknown): boolean | null {
        if (value === null || value === undefined) {
            return null;
        }
        if (typeof value === 'boolean') {
            return value;
        }
        return null;
    }

    static toObject<T>(value: unknown): InnerPartial<T> | null {
        if (value === null || value === undefined) {
            return null;
        }
        if (typeof value === 'object') {
            return value;
        }
        return null;
    }
}

export class TypeStrictSafe {
    private static assertNonNull<T>(value: T | null, orElse?: T): T {
        if (value === null) {
            if (orElse !== undefined) {
                return orElse;
            }
            throw new AppError('Could not transform value');
        }
        return value;
    }

    static toString(value: unknown, orElse?: string): string {
        return this.assertNonNull(TypeStrict.toString(value), orElse);
    }

    static toInt(value: unknown, orElse?: number): number {
        return this.assertNonNull(
            TypeStrict.toInt(value),
            orElse ? Math.round(orElse) : undefined
        );
    }

    static toFloat(value: unknown, orElse?: number): number {
        return this.assertNonNull(TypeStrict.toFloat(value), orElse);
    }

    static toBool(value: unknown, orElse?: boolean): boolean {
        return this.assertNonNull(TypeStrict.toBool(value), orElse);
    }

    static toObject<T>(value: unknown, orElse?: T): InnerPartial<T> {
        return this.assertNonNull(TypeStrict.toObject<T>(value), orElse);
    }
}
