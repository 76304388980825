import { BaseShapeUnitDescription } from '../unit.model';
import { UnitAttributes } from './attributes.model';
import { Bounds, ShapeUnit } from './shape-unit';
import Konva from 'konva';
import canvasTxt from 'canvas-txt';
import { BlockContext, ShapeUnitRawDescription } from './index';
import { getFontSizeToFitInWidth, loadFont, tryGetFont } from '../../utils';

export type VerticalTextAlignment = 'bottom' | 'middle' | 'top';
export type TextAlignment = 'center' | 'left' | 'right' | 'justify';
export type FontStyle = 'italic' | 'bold' | 'normal';
export type FontDecoration = 'underline' | 'line-through' | '';

export interface TextUnitAttributes extends UnitAttributes {
    text: string;
    fontFamily: string | 'FontAwesome';
    fill: string;
    align: TextAlignment;
    verticalAlign: VerticalTextAlignment;
    fontStyle: FontStyle;
    textDecoration: FontDecoration;
    textBaseline: CanvasTextBaseline;
    fontSize: number | 'auto';
    lineHeight: number;
    debug: boolean;
    overflow: 'ellipsis' | 'none';
}

export type TextUnit = BaseShapeUnitDescription<'Text', TextUnitAttributes>;

/**
 * Text shape unit
 */
export class TextShapeUnit extends ShapeUnit<'Text'> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    /**
     * Constructor.
     * @param id The id of the shape unit.
     * @param context The context of the shape unit.
     * @param shape The shape of the shape unit.
     * @param children The children of the shape unit.
     * @param editable Whether the shape unit is editable.
     * @param initialDescription The initial description of the shape unit.
     */
    constructor(
        id: string,
        context: BlockContext,
        shape: Konva.Shape,
        children: ShapeUnit<'Text'>[],
        editable: boolean,
        initialDescription: ShapeUnitRawDescription<'Text'>
    ) {
        super(id, context, shape, children, editable, initialDescription);
        this.text = new Konva.Text({
            ellipsis: true,
        });
    }

    text: Konva.Text;

    defaultValues: TextUnitAttributes = {
        align: 'left',
        fill: 'black',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        verticalAlign: 'middle',
        lineHeight: 1.1,
        fontSize: 'auto',
        text: '',
        textDecoration: '',
        textBaseline: 'alphabetic',
        debug: false,
        cursor: 'ignore',
        overflow: 'none',
    };

    /**
     * Loads the canvas context for loading a text.
     * @param ctx The canvas context.
     */
    public static prepareContextForText(ctx: CanvasRenderingContext2D): void {
        const { fontStyle, fontVariant, fontWeight, fontSize, font } =
            canvasTxt;
        ctx.font = `${fontStyle} ${fontVariant} ${fontWeight} ${fontSize}px ${font}`;

        if (canvasTxt.align === 'right') {
            ctx.textAlign = 'right';
        } else if (canvasTxt.align === 'left') {
            ctx.textAlign = 'left';
        } else {
            ctx.textAlign = 'center';
        }
    }

    /**
     * Get the text auto size.
     * @param ctx The canvas context.
     * @param text The text.
     * @param maxWidth The max width.
     * @param maxFontSize The max font size.
     * @param reduceFactor The reduce factor.
     * @private
     *
     * @returns The text auto size.
     */
    private static getTextAutoHeight(
        ctx: CanvasRenderingContext2D,
        text: string,
        maxWidth: number,
        maxFontSize: number,
        reduceFactor = 0.9
    ): number {
        let fontSize: number = maxFontSize;
        let width: number;
        do {
            canvasTxt.fontSize = fontSize;
            this.prepareContextForText(ctx);

            width = ctx.measureText(text).width;
            const delta = Math.min(1, fontSize * (1 - reduceFactor));
            fontSize -= delta;
        } while (width >= maxWidth);

        return fontSize * reduceFactor;
    }

    /**
     * Creates a string that has a ellipsis at the end if the text is too long.
     * @param ctx The canvas context.
     * @param str The string.
     * @param maxWidth The max width.
     *
     * @returns The string with ellipsis.
     */
    static fittingString(
        ctx: CanvasRenderingContext2D,
        str: string,
        maxWidth: number
    ): string {
        const { fontStyle, fontVariant, fontWeight, fontSize, font } =
            canvasTxt;
        ctx.font = `${fontStyle} ${fontVariant} ${fontWeight} ${fontSize}px ${font}`;

        let width = ctx.measureText(str).width;
        const ellipsis = '…';
        const ellipsisWidth = ctx.measureText(ellipsis).width;
        if (width <= maxWidth || width <= ellipsisWidth) {
            return str;
        } else {
            let len = str.length;
            while (width >= maxWidth - ellipsisWidth && len-- > 0) {
                str = str.substring(0, len);
                width = ctx.measureText(str).width;
            }
            return str + ellipsis;
        }
    }

    /**
     * Adjusts the font size of the text to fit the text into the specified width.
     * @private
     */
    private adjustFontToBounds(): void {
        const newFontSize =
            this.attributes.fontSize === 'auto'
                ? getFontSizeToFitInWidth(
                      this.text.text(),
                      this.text.attrs,
                      this.parentBounds.height,
                      this.parentBounds.width
                  )
                : this.attributes.fontSize;
        this.text.setAttrs({
            ...this.parentBounds,
            fontSize: newFontSize,
        });
    }

    /**
     * Adjusts the unit to the parent bounds.
     * @param bounds The bounds.
     */
    public override adjustToParent(bounds: Bounds): void {
        super.adjustToParent(bounds);
        this.adjustFontToBounds();
    }
    /**
     * Draws a text inside bounds
     * @param ctx The canvas context
     * @param attributes The text attributes
     * @param bounds The bounds to draw the text in
     * @protected
     */
    protected draw(
        ctx: CanvasRenderingContext2D,
        attributes: TextUnitAttributes,
        bounds: Bounds
    ): void {
        ctx.textBaseline = attributes.textBaseline;
        canvasTxt.font = tryGetFont(attributes.fontFamily);
        if (attributes.fontStyle === 'bold') {
            canvasTxt.fontStyle = 'normal';
            canvasTxt.fontWeight = 'bold';
        } else {
            canvasTxt.fontStyle = attributes.fontStyle;
            canvasTxt.fontWeight = 'normal';
        }
        if (attributes.align === 'justify') {
            canvasTxt.align = 'left';
            canvasTxt.justify = true;
        } else {
            canvasTxt.align = attributes.align;
            canvasTxt.justify = false;
        }
        ctx.fillStyle = attributes.fill;
        canvasTxt.vAlign = attributes.verticalAlign;
        canvasTxt.debug = attributes.debug;

        let text = attributes.text;

        canvasTxt.fontSize =
            attributes.fontSize === 'auto'
                ? TextShapeUnit.getTextAutoHeight(
                      ctx,
                      text,
                      bounds.width,
                      bounds.height
                  )
                : attributes.fontSize;
        canvasTxt.lineHeight = attributes.lineHeight * canvasTxt.fontSize;

        // avoid infinite loop of canvas-txt
        if (text.length > 0) {
            TextShapeUnit.prepareContextForText(ctx);

            const widestChar = Array.from(text).reduce<string>(
                (wider, char) =>
                    ctx.measureText(char).width > ctx.measureText(wider).width
                        ? char
                        : wider,
                ''
            );
            const widestCharWidth =
                ctx.measureText(widestChar).width * (1 / 0.9);
            if (widestCharWidth >= bounds.width) {
                return;
            }
        }

        if (attributes.overflow === 'ellipsis') {
            text = TextShapeUnit.fittingString(ctx, text, bounds.width);
        }

        canvasTxt.drawText(
            ctx,
            text,
            bounds.x,
            bounds.y,
            bounds.width,
            bounds.height
        );
    }

    /**
     * Loads the font.
     * @param attrs The attributes.
     * @protected
     */
    protected override async onUpdate(attrs: TextUnitAttributes): Promise<void> {
        await loadFont(attrs.fontFamily, attrs.fontStyle);
    }
}
