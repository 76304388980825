<div
    ngbDropdown
    autoClose="outside"
    container="body"
    placement="auto"
    (openChange)="onOpenChange($event)"
>
    <input
        class="form-control"
        (click)="onTouched()"
        value="{{ start | pickerTimestamp }} → {{ end | pickerTimestamp }}"
        readonly
        ngbDropdownToggle
    />

    <div
        class="main-content p-3"
        ngbDropdownMenu
        aria-labelledby="dropdownForm1"
    >
        <span class="d-block label-info text-center">From:</span>
        <div class="form-group">
            <ngb-timepicker
                [disabled]="disabled"
                [ngModel]="start"
                (ngModelChange)="updateStart($event)"
                [seconds]="true"
            ></ngb-timepicker>
        </div>

        <span class="d-block label-info text-center">To:</span>
        <div class="form-group">
            <ngb-timepicker
                [disabled]="disabled"
                [ngModel]="end"
                (ngModelChange)="updateEnd($event)"
                [seconds]="true"
            ></ngb-timepicker>
        </div>
    </div>
</div>
