import { Component } from '@angular/core';
import { BlockStyleComponent } from '../../block-style.component';
import {ImageBlockStyle} from '@nirby/models/nirby-player';

@Component({
    selector: 'nirby-block-image-style',
    templateUrl: './block-image-style.component.html',
    styleUrls: ['./block-image-style.component.scss'],
})
export class BlockImageStyleComponent extends BlockStyleComponent<ImageBlockStyle> {
    imageStyle?: ImageBlockStyle;

    setData(style: any): void {
        this.imageStyle = style;
    }

    onFitChange(fit: Event): void {
        if (this.imageStyle) {
            const target: HTMLSelectElement = fit.target as HTMLSelectElement;
            this.sendData({
                fit: target.value,
                positioning: this.imageStyle.positioning,
            } as ImageBlockStyle);
        }
    }

    onPositioningChange(positioning: string): void {
        if (this.imageStyle) {
            this.sendData({
                fit: this.imageStyle.fit,
                positioning,
            } as ImageBlockStyle);
        }
    }
}
