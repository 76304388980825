import { Component } from '@angular/core';
import { RectangleBlockStyle } from '@nirby/models/nirby-player';
import { BlockStyleComponent } from '../../block-style.component';

@Component({
    selector: 'nirby-block-rectangle-style',
    templateUrl: './block-rectangle-style.component.html',
    styleUrls: ['./block-rectangle-style.component.scss'],
})
export class BlockRectangleStyleComponent extends BlockStyleComponent<RectangleBlockStyle> {
    bStyle: RectangleBlockStyle | null = null;

    setData(style: RectangleBlockStyle): void {
        this.bStyle = style;
    }

    updateCornerRadius(radius: number): void {
        this.sendData({
            borderRadius: radius,
        });
    }
}
