<div *ngIf="getEmbed() as embed; else noVideo">
    <div class="embed-responsive embed-responsive-16by9">
        <iframe
            class="embed-responsive-item"
            [src]="embed | safeUrl"
            frameborder="0"
            allowfullscreen
        ></iframe>
    </div>
</div>
<ng-template #noVideo>
    <span>No video</span>
</ng-template>
