import {Component, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DateTime} from 'luxon';

export type DateRange = [Date, Date | null];
export type DateTimeRange = [DateTime, DateTime | null];

@Component({
    selector: 'nirby-date-range-input',
    templateUrl: './date-range-input.component.html',
    styleUrls: ['./date-range-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: DateRangeInputComponent,
            multi: true
        }
    ]
})
export class DateRangeInputComponent implements ControlValueAccessor {
    @Input() styleClass = '';

    minDateValue: Date = null as unknown as Date;

    maxDateValue: Date = null as unknown as Date;

    @Input() set minDate(value: DateTime | null) {
        this.minDateValue = value ? value.toJSDate() : (null as unknown as Date);
    }

    @Input() set maxDate(value: DateTime | null) {
        this.maxDateValue = value ? value.toJSDate() : (null as unknown as Date);
    }

    disabled = false;

    get defaultValue(): DateTimeRange {
        return [
            DateTime.now().minus({days: 1}),
            null
        ];
    }

    value: DateRange = [
        this.defaultValue[0]?.toJSDate(),
        this.defaultValue[1]?.toJSDate() ?? null
    ];

    onChange: (value: DateTimeRange) => void = () => {
        return;
    };
    onTouched: () => void = () => {
        return;
    };

    registerOnChange(fn: (value: DateTimeRange) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: DateTimeRange | null): void {
        const value: DateTimeRange = obj ?? this.defaultValue;
        this.value = [
            value[0]?.toJSDate() ?? null,
            value[1]?.toJSDate() ?? null
        ];
    }

    onValueChange(range: DateRange | null): void {
        let from: Date = this.defaultValue[0]?.toJSDate();
        let to: Date | null = null;
        if (range) {
            from = range[0];
            to = range[1];
        }
        const dateTimeValue: DateTimeRange = [
            DateTime.fromJSDate(from).startOf('day'),
            to ? DateTime.fromJSDate(to).endOf('day') : null
        ];
        this.value = [
            dateTimeValue[0].toJSDate(),
            dateTimeValue[1]?.toJSDate() ?? null
        ];
        this.onChange(dateTimeValue);
    }
}
