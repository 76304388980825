import {Injectable} from '@angular/core';
import {WorkspaceService} from './workspace.service';
import {PublishedJson, PublishedRoute} from '@nirby/models/pop';
import {Database} from '@nirby/ngutils';
import {NotFoundError} from '@nirby/js-utils/errors';
import {RouteData, RouteDisplay} from '@nirby/models/editor';
import {COLLECTION_KEYS} from '@nirby/store/collections';
import {NirbyCollection} from '@nirby/store/base';

@Injectable({
    providedIn: 'root',
})
export class PublishedService {
    constructor(
        private readonly db: Database,
        private readonly widgets: WorkspaceService,
    ) {
    }

    protected readonly collection = this.db.collection(
        COLLECTION_KEYS.PUBLISHED,
    );

    private pops(workspaceId: string): NirbyCollection<PublishedJson> {
        return this.db.collection(
            COLLECTION_KEYS.PUBLISHED.doc(workspaceId),
            COLLECTION_KEYS.PUBLISHED_POPS,
        );
    }

    private routeDataToCompiledRoute(route: RouteData): PublishedRoute {
        return {
            campaigns: route.campaigns.map((r) => {
                const widgetId = r.ref.getParentDocId() ?? '';
                return this.pops(widgetId).ref(r.ref.id);
            }),
            path: route.route,
        };
    }

    updatePublished(widgetId: string): Promise<void> {
        return this.db.runTransaction(async (transaction) => {
            const widget = await this.widgets.collection
                .getTransaction(transaction, widgetId)
                .then((w) => w?.data);
            if (!widget) {
                throw new NotFoundError(`Widget ${widgetId} not found`);
            }
            const routePriority: RouteDisplay[] = widget.priorityList;
            const rawRoutes = await Promise.all(
                routePriority.map((r) => transaction.get(r.routePath)),
            ).then((rl) => rl.map((r) => r?.data));

            const routes: PublishedRoute[] = rawRoutes
                .filter((s) => !!s)
                .map((s) => this.routeDataToCompiledRoute(s as RouteData));
            await this.collection.setTransaction(
                transaction,
                {routes},
                widgetId,
            );
        });
    }
}
