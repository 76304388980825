import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
} from '@angular/core';

@Component({
    selector: 'nirby-image-url-input',
    templateUrl: './image-url-input.component.html',
    styleUrls: ['./image-url-input.component.scss'],
})
export class ImageUrlInputComponent implements OnInit, OnChanges {
    @Input() imageURL?: string;
    @Output() imageURLChange = new EventEmitter<string>();

    oldImageUrl?: string;

    errored: boolean | null = false;

    onNewContent(): void {
        this.oldImageUrl = this.imageURL;
    }

    onLoad(): void {
        this.errored = false;
        if (this.imageURL !== this.oldImageUrl) {
            this.oldImageUrl = this.imageURL;
            this.imageURLChange.emit(this.imageURL);
        }
    }

    ngOnInit(): void {
        this.onNewContent();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['imageUrl']) {
            this.onNewContent();
        }
    }
}
