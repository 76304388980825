import {z} from 'zod';
import {ZodSchemaBuild} from '@nirby/store/models';

export const CONTACT_ATTRIBUTE_TYPE_SCHEMA = z
    .union([z.literal('email'), z.literal('string')])
    .catch(() => 'string' as const);
export type ContactAttributeType = z.infer<
    typeof CONTACT_ATTRIBUTE_TYPE_SCHEMA
>;

export const FORM_FIELD_DECLARATION_SCHEMA = z.object({
    type: CONTACT_ATTRIBUTE_TYPE_SCHEMA,
    label: z.string(),
    id: z.string(),
    required: z.boolean(),
    store: z.boolean(),
});
export type FormFieldDeclaration = z.infer<
    typeof FORM_FIELD_DECLARATION_SCHEMA
>;

export const FORM_TYPE_SCHEMA = z
    .union([z.literal('question'), z.literal('contact')])
    .catch(() => 'contact' as const);
export type FormType = z.infer<typeof FORM_TYPE_SCHEMA>;

export type OpenFormSettings = {
    type: FormType;
    title: string;
    description: string | null;
    attributes: FormFieldDeclaration[];
};

export const OPEN_FORM_SETTINGS_SCHEMA: ZodSchemaBuild<OpenFormSettings> =
    z.object({
        type: FORM_TYPE_SCHEMA,
        title: z.string(),
        description: z.string().nullable(),
        attributes: z
            .array(FORM_FIELD_DECLARATION_SCHEMA)
            .catch(() => [] as FormFieldDeclaration[]),
    });
