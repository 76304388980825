import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cfThumbnail',
})
/**
 * Pipe that transform Cloudflare watch link to thumbnail
 */
export class CfThumbnailPipe implements PipeTransform {

    /**
     * Transform Cloudflare watch link to thumbnail
     * @param value Cloudflare watch link
     * @returns Thumbnail link
     */
    transform(value: string): string {
        return value.replace(/\/watch$/,'/thumbnails/thumbnail.gif?time=1s&height=200&duration=4s');
    }

}
