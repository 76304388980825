import {Injectable} from '@angular/core';
import {Database} from '@nirby/ngutils';
import {COLLECTION_KEYS} from '@nirby/store/collections';

@Injectable({
    providedIn: 'root',
})
/**
 * Service to manage the user collection
 */
export class UserService {
    public readonly collection = this.db.collection(COLLECTION_KEYS.USERS);

    /**
     * Constructor.
     * @param db The database proxy
     */
    public constructor(
        private readonly db: Database,
    ) {
    }
}
