<p-dialog
    appendTo="body"
    header="Variables"
    [draggable]="false"
    [modal]="true"
    [visible]="visible"
    (visibleChange)="visibleChange.emit($event)"
>
    <nirby-variables-dialog-form
        *ngIf="productRef"
        [productRef]="productRef"
    ></nirby-variables-dialog-form>
</p-dialog>
