<div *ngIf="false" class="form-group">
    <div
        class="color-preview"
        [ngStyle]="{ 'background-color': value ? value : '#ffffff' }"
    >
        <span *ngIf="!value">Auto</span>
    </div>
</div>
<div class="form-group d-flex flex-wrap">
    <div
        class="color-option"
        *ngIf="haveAutoOption"
        ngbTooltip="Automatic"
        placement="top-left"
    >
        <input
            type="radio"
            name="color"
            [value]="'auto'"
            [ngModel]="value === null ? 'auto' : value"
            (change)="onAutoChange($event)"
        />
        <div
            class="color-option-inner"
            [ngClass]="{ active: !value }"
            [ngStyle]="{ border: '1px solid #646a77' }"
        >
            <div class="icon">A</div>
        </div>
    </div>
    <div
        *ngFor="let option of colors"
        class="color-option"
        [ngbTooltip]="isTransparent(option) ? 'Transparent' : ''"
        placement="top-left"
    >
        <input
            type="radio"
            name="color"
            [value]="option"
            [ngModel]="value === null ? 'auto' : value"
            (change)="codeOpaque = option"
        />
        <div
            class="color-option-inner"
            [ngClass]="{ active: value === option }"
            [ngStyle]="{
                'background-color': option,
                border: needsBorder(option) ? '1px solid #646a77' : 'none'
            }"
        >
            <fa-icon
                *ngIf="isTransparent(option)"
                class="icon"
                [icon]="transparentIcon"
            ></fa-icon>
        </div>
    </div>
</div>
<div class="form-group">
    <button
        class="btn btn-dark w-100"
        [colorPicker]="value ? value : '#000000'"
        cpAlphaChannel="disabled"
        (colorPickerChange)="codeOpaque = $event"
        (click)="$event.preventDefault(); $event.stopPropagation()"
        [cpPosition]="position"
    >
        Choose a custom color...
    </button>
    <ng-container *ngIf="value !== null">
        <nirby-slider
            *ngIf="allowTransparency"
            [min]="0"
            [max]="1"
            [step]="0.01"
            [value]="opacity"
            (valueChange)="opacity = $event"
        ></nirby-slider>
        <div class="color-option">
            <div
                class="color-option-inner"
                [ngStyle]="{
                    'background-color': value,
                    border: needsBorder(value) ? '1px solid #646a77' : 'none'
                }"
            >
                <fa-icon
                    *ngIf="isTransparent(value)"
                    class="icon"
                    [icon]="transparentIcon"
                ></fa-icon>
            </div>
        </div>
    </ng-container>
</div>
