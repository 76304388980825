<div class="list-item-usage d-flex justify-content-between align-items-stretch mb-2"
     [ngStyle]="{'background-color': color}"
>
    <div class="annex px-4 py-2 d-flex flex-column justify-content-center">
        <ng-container *ngIf="maximumValue !== null && maximumValue !== undefined; else custom">
            <span class="d-block top">Up to</span>
            <span class="d-block num" id="plan-startup-usage-storage">{{maximumValue}}</span>
            <span class="d-block qty mt-2" *ngIf="unit">{{unit}}</span>
        </ng-container>
        <ng-template #custom>
            <span class="d-block title">Custom</span>
        </ng-template>
    </div>
    <div class="content px-4 py-2 flex-1 d-flex flex-column justify-content-center">
        <h3 class="title mb-0">{{label}}</h3>
    </div>
</div>
