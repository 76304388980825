<div class="form-group mb-0">
    <select
        class="form-control"
        [ngModel]="cleanedValue"
        (ngModelChange)="update($event)"
        [disabled]="disabled"
    >
        <option *ngIf="defaultValue" [ngValue]="null">
            {{ defaultValue.label }}
        </option>
        <option *ngFor="let option of options" [ngValue]="option.id">
            {{ option.label }}
        </option>
    </select>
</div>
