import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'nirby-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss'],
})
export class SliderComponent {

    @Input() label = '';
    @Input() value = 0;
    @Input() min = 0;
    @Input() max = 1;
    @Input() step = 0.1;

    @Output() valueChange = new EventEmitter<number>();

    onValueChange(valueStr: string | number): void {
        const value =
            typeof valueStr === 'string'
                ? Number.parseFloat(valueStr)
                : valueStr;
        if (value !== this.value) {
            this.value = value;
            this.valueChange.emit(value);
        }
    }
}
