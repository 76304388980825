import { Component } from '@angular/core';
import { BlockContentComponent } from '../../block-content.component';
import { VideoRecord } from '@nirby/media-models';
import { VideoBlockContent } from '@nirby/models/nirby-player';

@Component({
    selector: 'nirby-block-video-content',
    templateUrl: './block-video-content.component.html',
    styleUrls: ['./block-video-content.component.scss'],
})
export class BlockVideoContentComponent extends BlockContentComponent<VideoBlockContent> {
    video: VideoRecord | null = null;

    setData(content: VideoBlockContent | null): void {
        this.video = content?.video ?? null;
    }

    onVideoChange(video: VideoRecord | null): void {
        this.video = video;
        this.sendData({ video });
    }
}
