import {DB_CONFIG_PROVIDERS} from '@nirby/ngutils';
import {Provider} from '@angular/core';
import {IS_LOCALHOST, NirbyEnvironment} from '@nirby/environment/base';
import {MEDIA_TOKEN_PROVIDERS} from '@nirby/media/video-factory';

/**
 * The environment for the development environment.
 *
 * @returns - The environment
 */
export function environmentDev(): NirbyEnvironment {
    return {
        production: false,
        isLocalHost: IS_LOCALHOST,
        stripe: {
            publishableKey: 'pk_test_51LwUuaJjRDzwSVoinf6kGzybtcdevNkKlo0Ql5uBHbQqmjWbn7WtI8WOz7Ks9JFEVO3F0b9EUboDTkbyrG9ZOGQz00LCSBtblI',
        },
        firebase: {
            apiKey: 'AIzaSyB4ivv7dLE0f0_M-LmROhwcqy9PmS0aHos',
            authDomain: 'nirby-dev.firebaseapp.com',
            databaseURL: 'https://nirby-dev.firebaseio.com',
            projectId: 'nirby-dev',
            storageBucket: 'nirby-dev.appspot.com',
            messagingSenderId: '515419887477',
            appId: '1:515419887477:web:161951c76fe2c50e0ee53c',
            measurementId: 'G-JNS7WXJLS2',
            regionId: 'us-central1',
        },
        api: {
            host: IS_LOCALHOST
                ? 'http://localhost:5200'
                : 'https://nirby-api-gateway-6ks3oygl.uc.gateway.dev',
        },
        database: DB_CONFIG_PROVIDERS.dev,
        vimeo: {
            apiKey: '303c9759f9a6dff82c4cbab97e78daff',
        },
        unsplash: {
            accessKey:
                '4ceae8cb78b31b0478007ce739ea08d027e2ed320fbc0a4ee05d101634718a9b',
        },
        responsive: {
            baseResolution: [1920, 1080],
            baseFontSizePx: 13,
        },
        blocks: {
            button: {
                paddingRatio: 1.0,
            },
        },
        stories: {
            stopIfClickedFor: 10,
        },
        landpopsOrigin: 'http://localhost:4202',
        debug: [
            'DEBUG',
            'DB',
            'ROUTE-PARAMS',
            'BLOCK-EDITOR',
            'MIGRATION',
            'STORAGE',
            'EVENT',
            'ACTION-INPUT',
            'API',
            'VIEW',
        ],
        refreshTime: 10 * 1000,
        version: '0.14.8',
        origins: {
            primeEmbed: IS_LOCALHOST
                ? 'http://localhost:4203'
                : 'https://prime-embed-dev.web.app',
            primeLand: IS_LOCALHOST
                ? 'http://localhost:4204'
                : 'https://prime-land-dev.web.app',
        },
        useEmulators: IS_LOCALHOST,
        media: MEDIA_TOKEN_PROVIDERS.dev,
        firebaseFunctionsUrl: IS_LOCALHOST
            ? 'http://localhost:5001/nirby-dev/us-central1/api'
            : 'https://us-central1-nirby-dev.cloudfunctions.net/api',
        billingCustomerPortalUrl: 'https://billing.stripe.com/p/login/test_bIY8wP30r9dn6hG145',
    };
}

export const PROVIDER: Provider = {
    provide: 'Environment',
    useFactory: environmentDev,
};
