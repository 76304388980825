import { BlockHostDirective } from './block-host.directive';
import {
    ComponentFactoryResolver,
    Directive,
    forwardRef,
    Input,
    ViewContainerRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BlockController } from '../../../services/block-controller';
import { BlockStyleRegisterService } from '../../../services/registers/block-style-register.service';

@Directive({
    selector: '[nirbyBlockStyleHost]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BlockStyleHostDirective),
            multi: true,
        },
    ],
})
export class BlockStyleHostDirective<TMeta = unknown> extends BlockHostDirective<TMeta, 'style'> {
    blockKey = 'style' as const;

    /**
     * New block ID
     * @param value New block ID
     */
    @Input()
    override set controller(value: BlockController<TMeta> | null) {
        super.controller = value;
    }

    constructor(
        viewContainerRef: ViewContainerRef,
        componentFactoryResolver: ComponentFactoryResolver,
        register: BlockStyleRegisterService
    ) {
        super(viewContainerRef, componentFactoryResolver, register);
    }
}
