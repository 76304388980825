import {z} from 'zod';
import {ZodSchemaBuild} from '@nirby/store/models';

export const NIRBY_VARIABLE_SCHEMA = z.union([
    z.string(),
    z.number(),
    z.boolean(),
]);

export type NirbyVariable = z.infer<typeof NIRBY_VARIABLE_SCHEMA>;

export const NIRBY_VARIABLE_NULLABLE_SCHEMA = z.union([
    NIRBY_VARIABLE_SCHEMA,
    z.null(),
]);
export type NirbyVariableNullable = z.infer<
    typeof NIRBY_VARIABLE_NULLABLE_SCHEMA
>;
export type NirbyVariableType = 'string' | 'number' | 'boolean' | 'null';

export type NirbyVariableSource = 'private' | 'query';

export interface NirbyVariableDeclaration {
    type: NirbyVariableType; // 'undefined' | 'object' | 'function' | 'symbol' | 'bigint';
    source: NirbyVariableSource;
    initialValue: NirbyVariableNullable;
}

export const NIRBY_VARIABLE_SOURCE_SCHEMA: ZodSchemaBuild<NirbyVariableSource> =
    z.union([z.literal('private'), z.literal('query')]);

export const NIRBY_VARIABLE_DECLARATION_SCHEMA: ZodSchemaBuild<NirbyVariableDeclaration> =
    z.object({
        type: z.union([
            z.literal('string'),
            z.literal('number'),
            z.literal('boolean'),
            z.literal('null'),
        ]),
        source: NIRBY_VARIABLE_SOURCE_SCHEMA,
        initialValue: NIRBY_VARIABLE_NULLABLE_SCHEMA,
    });

export type NirbyVariableDeclarationSet = Record<
    string,
    NirbyVariableDeclaration
>;

export const NIRBY_VARIABLE_DECLARATION_SET_SCHEMA: ZodSchemaBuild<NirbyVariableDeclarationSet> =
    z.record(NIRBY_VARIABLE_DECLARATION_SCHEMA);
