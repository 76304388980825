<div class="container">
    <div class="nirby-card p-0 row">
        <ng-container *ngIf="authenticatedUser$ | async as user; else loading">
        </ng-container>
        <aside class="component-sidebar col-md-3 p-0">
            <div
                class="component-sidebar-inner d-flex flex-column justify-content-between h-100"
            >
                <header class="area-top p-3">
                    <h1 class="main-title">Preferences</h1>
                    <div class="main-tab" *ngIf="widgetId">
                        <a
                            class="main-tab-item me-2"
                            routerLinkActive="active"
                            [routerLink]="[
                                '/workspaces',
                                widgetId,
                                'settings',
                                'personal'
                            ]"
                            >Personal</a
                        >
                        <a
                            class="main-tab-item me-2"
                            routerLinkActive="active"
                            [routerLink]="[
                                '/workspaces',
                                widgetId,
                                'settings',
                                'widget'
                            ]"
                            >Workspace</a
                        >
                    </div>
                </header>

                <div class="area-middle flex-1">
                    <div class="list-group">
                        <a
                            [routerLink]="['.', 'general']"
                            [ngClass]="{
                                active: (activeTab$ | async) === 'general'
                            }"
                            class="list-group-item list-group-item-action border-0 d-flex justify-content-between align-items-center active"
                            (click)="$event.preventDefault()"
                            >General Information</a>
                        <a
                            [routerLink]="['.', 'script']"
                            [ngClass]="{
                                active: (activeTab$ | async) === 'script'
                            }"
                            class="list-group-item list-group-item-action border-0 d-flex justify-content-between align-items-center"
                            (click)="$event.preventDefault()"
                            >Installation Script</a>
                        <a
                            [routerLink]="['.', 'billing']"
                            [ngClass]="{
                                active: (activeTab$ | async) === 'billing'
                            }"
                            class="list-group-item list-group-item-action border-0 d-flex justify-content-between align-items-center"
                            (click)="$event.preventDefault()"
                        >Billing</a>
                    </div>
                </div>

                <footer class="area-bottom p-3">
                    <button
                        class="btn btn-danger btn-block mt-3 text-left d-flex justify-content-between align-items-center"
                        (click)="deleteWidget()"
                    >
                        Delete permanently
                        <fa-icon class="ms-2" [icon]="iconDelete"></fa-icon>
                    </button>
                </footer>
            </div>
        </aside>
        <div class="component-content col-md-9 p-0">
            <router-outlet></router-outlet>
        </div>

        <ng-template #loading>
            <div class="spinner-border text-primary"></div>
        </ng-template>
    </div>
</div>
