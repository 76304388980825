import {z} from 'zod';

export const VIDEO_SOURCE_SCHEMA = z.union([
    z.literal('youtube'),
    z.literal('vimeo'),
    z.literal('cloudflare-stream'),
]);
export type VideoSource = z.infer<typeof VIDEO_SOURCE_SCHEMA>;

export const VIDEO_RECORD_SCHEMA = z.object({
    type: VIDEO_SOURCE_SCHEMA,
    id: z.string(),
    thumbnail: z.string().nullable(),
    title: z.string().optional(),
    metadata: z.object({}),
});

export type VideoRecord = z.infer<typeof VIDEO_RECORD_SCHEMA>;
