<button
    *ngIf="item"
    class="btn btn-selector btn-sm m-2 d-flex justify-content-between align-items-center text-light"
    (click)="onClick(item, $event, overlayPanel, overlayPanelContent)"
    (mousedown)="onMouseDown($event)"
    (mouseleave)="onMouseLeave($event)"
    [pTooltip]="item.label"
>
    <fa-icon [icon]="item.icon"></fa-icon>
</button>
<p-overlayPanel #overlayPanel appendTo="body">
    <div #overlayPanelContent>
        <ng-content></ng-content>
    </div>
</p-overlayPanel>
