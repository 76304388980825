import {DateTime} from 'luxon';
import {z} from 'zod';
import {NirbyTimestamp, ZodSchemaBuild} from '@nirby/store/models';

/**
 * A zod schema that validates a NirbyTimestamp in any of the following formats:
 * - A DateTime instance
 * - A number representing the milliseconds since the Unix epoch
 * - A string in ISO 8601 format
 * - A Date instance
 * - The given {@link dbTimestampSchema} schema.
 * @param dbTimestampSchema A zod schema that validates a NirbyTimestamp from the database.
 *
 * @returns {z.ZodSchema<NirbyTimestamp>} A zod schema that validates a NirbyTimestamp
 */
export function buildTimestampSchema(dbTimestampSchema: ZodSchemaBuild<NirbyTimestamp>): ZodSchemaBuild<NirbyTimestamp> {
    return z.lazy(() =>
        z.union([
            dbTimestampSchema,
            z.date().transform((value) => DateTime.fromJSDate(value)),
            z.number().transform((value) => DateTime.fromMillis(value)),
            z
                .string()
                .transform((value) => DateTime.fromISO(value))
                .refine((value) => value.isValid, 'Invalid ISO 8601 date'),
            z.custom<DateTime>((value) => value instanceof DateTime),
        ]),
    );
}
