import {Component, Input} from '@angular/core';
import {IsActiveMatchOptions, QueryParamsHandling} from '@angular/router';

export interface TabButton {
    label: string;
    icon?: string;
    routerLink: string | string[];
    routerLinkActiveOptions?: IsActiveMatchOptions;
    queryParams: {
        [k: string]: unknown;
    };
    queryParamsHandling: QueryParamsHandling;
}

@Component({
    selector: 'nirby-tab-buttons',
    templateUrl: './tab-buttons.component.html',
    styleUrls: ['./tab-buttons.component.scss']
})
export class TabButtonsComponent {
    @Input() tabs: TabButton[] = [];
}
