<div class="d-flex flex-column w-100 justify-center align-items-center text-light">
    <img
        class="mb-2"
        src="assets/img/auxi/nby-logo-header.svg"
        alt="Nirby"
        title="Nirby"
        height="96"
    />
    <h2 class="mb-5">Welcome to Nirby!</h2>
    <div class="mb-5 flex flex-column" *ngIf="workspacePlan$ | async as plan">
        You are currently in plan: {{plan.planName}}
    </div>
    <div class="mb-5 d-flex flex-column align-items-center">
        <p>
            Start creating your interactive videos right now
        </p>
        <button class="btn btn-primary" [routerLink]="['/workspaces', workspaceId, 'primes']">
            Create your first Prime!
        </button>
    </div>
    <p>
        The following video will help you get started with Nirby.
    </p>
    <iframe
        class="tutorial-video"
        width="560"
        height="315"
        src="https://www.youtube.com/embed/T5Q1e_HSkcY"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
    ></iframe>
</div>
