import {Component} from '@angular/core';
import {FormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {AuthenticationService, RouteParametersService, UserService, WorkspaceService} from '@nirby/shared/database';
import {Workspace} from '@nirby/models/editor';
import {switchMap, tap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {AlertsService} from '@nirby/shared/alerts';
import {STANDARD_REGEX} from '@nirby/shared/widgets';
import {NirbyDocument} from '@nirby/store/base';

@Component({
    selector: 'nirby-settings-widget-general',
    templateUrl: './settings-widget-general.component.html',
    styleUrls: ['./settings-widget-general.component.scss'],
})
/**
 * Component to change the workspace settings.
 */
export class SettingsWidgetGeneralComponent {
    loading = false;

    name: UntypedFormControl = new UntypedFormControl('', Validators.required);
    domainName = new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(STANDARD_REGEX.domainName),
    ]);

    form = new FormGroup({
        title: this.name,
        domainName: this.domainName,
    });

    userOwnsWidget$: Observable<boolean>;

    widget$: Observable<NirbyDocument<Workspace>>;

    /**
     * The current workspace ID
     */
    get workspaceId(): string {
        return this.routeParams.workspaceId;
    }

    /**
     * Constructor.
     * @param service The workspace service.
     * @param user The user service.
     * @param auth The authentication service.
     * @param route The route service.
     * @param routeParams The route parameters service.
     * @param alerts The alert service.
     */
    constructor(
        private service: WorkspaceService,
        private user: UserService,
        public auth: AuthenticationService,
        private route: ActivatedRoute,
        private routeParams: RouteParametersService,
        private alerts: AlertsService,
    ) {
        this.widget$ = service.activeWidget$.pipe(
            tap((s) => {
                this.name.setValue(s.data.urlName);
                this.domainName.setValue(s.data.url);
            }),
        );
        this.userOwnsWidget$ = routeParams.widgetId$.pipe(
            switchMap((widgetId) => this.auth.ownsWidget(widgetId)),
        );
    }

    /**
     * Save the settings.
     */
    async save(): Promise<void> {
        this.loading = true;
        try {
            await this.service.update(this.workspaceId, {
                urlName: this.name.value,
                url: this.domainName.value,
            });
            this.alerts.showSuccess('Widget updated successfully');
        } catch (e) {
            this.alerts.showDanger('Error updating widget');
            console.error(e);
        } finally {
            this.loading = false;
        }
    }
}
