<div *ngIf="actions$ | async as actions">
    <section class="section section-action">
        <div
            class="section-header d-flex justify-content-between align-items-center mb-2"
        >
            <label class="title flex-1 mb-0">On Click/Tap</label>
            <fa-icon
                class="icon"
                [icon]="iconQuestion"
                pTooltip="Execute one or more Actions whenever visitors click or tap on this block."
            ></fa-icon>
        </div>
        <div *ngIf="actions.length > 0" class="w-100">
            <div
                class="d-flex flex-column"
                *ngFor="let _ of actions; let actionIdx = index; trackBy: trackByActionId"
            >
                <div class="section-body mb-2 flex justify-content-between align-items-stretch p-3">
                    <nirby-action-input
                        class="flex-1"
                        [variables]="variables"
                        [productType]="productType"
                        [availableVideos]="availableVideos"
                        [cardSiblings]="cardSiblings"
                        [controller]="controller$ | async"
                        [actionIndex]="actionIdx"
                        (closeRequest)="close()"
                    ></nirby-action-input>
                    <button
                        class="button-remove ms-3"
                        (click)="removeAction(actionIdx, 'click')"
                    >
                        <fa-icon [icon]="closeIcon"></fa-icon>
                    </button>
                </div>
                <button
                    *ngIf="actionIdx < actions.length - 1"
                    (click)="addActionToEvent('click', actionIdx + 1)"
                    class="mb-2 action-input-add-btn d-flex flex-row justify-content-center align-items-center">
                    <div class="action-input-add-btn-line"></div>
                    <fa-icon class="ms-2 me-2" [icon]="icons.add"></fa-icon>
                    <div class="action-input-add-btn-line"></div>
                </button>
            </div>
        </div>
    </section>

    <div class="main-actions">
        <button
            class="action-input-add-btn d-flex flex-row justify-content-center align-items-center"
            (click)="addActionToEvent('click')"
        >
            <div class="action-input-add-btn-line"></div>
            <fa-icon class="ms-2 me-2" [icon]="icons.add"></fa-icon>
            <div class="action-input-add-btn-line"></div>
        </button>
    </div>
</div>
