import {AnyBlockType, NirbyBlock} from '.';

const defaultBlockContent: {
    [T in AnyBlockType]: NirbyBlock<T>['content'];
} = {
    Question: {
        question: 'And you? What do you think?',
        successLabel: 'Answer submitted successfully!',
    },
    Slider: {
        color: 'red',
        title: 'Insert a question...',
        emoji: '❤',
        submitMessage: 'Answer submitted successfully!',
    },
    Text: {
        text: 'Default text...',
        fontFamily: 'Roboto',
        color: 'black',
        fontScale: 16,
        alignment: 'left',
        style: 'normal',
        decoration: '',
        lineHeight: 1.2,
        verticalAlign: 'top',
    },
    Image: {
        src: 'https://images.pexels.com/photos/814499/pexels-photo-814499.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    },
    Rectangle: {color: '#000000'},
    Button: {label: 'Click me!', icon: null},
    Icon: {icon: '\uf00c'},
    Video: {video: null},
};

export const defaultBlocks: {
    [T in AnyBlockType]: NirbyBlock<T>;
} = {
    Question: {
        actions: {click: []},
        content: defaultBlockContent.Question,
        style: {},
        position: [
            {x: 0, y: 0},
            {x: 164, y: 100},
        ],
        rotation: 0,
        type: 'Question',
        hash: 'default',
    },
    Slider: {
        actions: {click: []},
        content: defaultBlockContent.Slider,
        style: {},
        position: [
            {x: 0, y: 0},
            {x: 164, y: 100},
        ],
        rotation: 0,
        type: 'Slider',
        hash: 'default',
    },
    Text: {
        actions: {click: []},
        content: defaultBlockContent.Text,
        rotation: 0,
        position: [
            {x: 0, y: 0},
            {x: 215, y: 42},
        ],
        style: {},
        type: 'Text',
        hash: 'default',
    },
    Image: {
        actions: {click: []},
        content: defaultBlockContent.Image,
        rotation: 0,
        position: [
            {x: 0, y: 0},
            {x: 240, y: 124},
        ],
        style: {
            fit: 'cover',
            positioning: 'center center',
            clickable: 'true',
        },
        type: 'Image',
        hash: 'default',
    },
    Rectangle: {
        actions: {click: []},
        content: defaultBlockContent.Rectangle,
        style: {borderRadius: 0},
        type: 'Rectangle',
        rotation: 0,
        position: [
            {x: 0, y: 0},
            {x: 240, y: 124},
        ],
        hash: 'default',
    },
    Button: {
        type: 'Button',
        actions: {click: []},
        content: defaultBlockContent.Button,
        style: {
            color: '#000000',
            borderRadius: 5,
            borderWidth: 0.0,
            borderColor: null,
            alignment: 'between',
            fontColor: null,
            fontSize: 14,
            fontStyle: 'normal',
            fontFamily: 'Roboto',
        },
        rotation: 0,
        position: [
            {x: 0, y: 0},
            {x: 176, y: 48},
        ],
        hash: 'default',
    },
    Icon: {
        type: 'Icon',
        actions: {click: []},
        content: defaultBlockContent.Icon,
        style: {color: '#000000'},
        rotation: 0,
        position: [
            {x: 0, y: 0},
            {x: 64, y: 64},
        ],
        hash: 'default',
    },
    Video: {
        type: 'Video',
        actions: {click: []},
        content: defaultBlockContent.Video,
        style: {},
        rotation: 0,
        position: [
            {x: 0, y: 0},
            {x: 240, y: 124},
        ],
        hash: 'default',
    },
};

export default defaultBlocks;
