import {Component} from '@angular/core';
import {UntypedFormControl, FormGroup} from '@angular/forms';
import {BlockActionComponent} from '../../blocks/block-action.component';
import {GoToURLAction} from '@nirby/models/nirby-player';

@Component({
    selector: 'nirby-action-go-to-url',
    templateUrl: './action-go-to-url.component.html',
    styleUrls: ['./action-go-to-url.component.scss'],
})
export class ActionGoToUrlComponent extends BlockActionComponent<GoToURLAction> {
    urlController = new UntypedFormControl('');
    targetController = new UntypedFormControl('_blank');

    form = new FormGroup({
        href: this.urlController,
        target: this.targetController,
    });

    setData(content: GoToURLAction | null): void {
        if (content) {
            this.urlController.setValue(content.options.href);
            this.targetController.setValue(content.options.target);
        }
    }
}
