import { DesignableBlock } from '../block-designable';
import { DesignSet } from '../../designs';
import { ShapeUnitRawDescription } from '../../designs';
import { IconBlock } from '@nirby/models/nirby-player';

const defaultIconDesign: ShapeUnitRawDescription<'Text'> = {
    shape: 'Text',
    axis: {
        horizontal: 'stretch',
        vertical: 'stretch',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    attributes: {
        text: {
            method: 'GetBlockContent',
            arguments: ['icon'],
        },
        fontFamily: 'FontAwesome',
        fill: {
            method: 'GetBlockStyle',
            arguments: ['color'],
        },
        align: 'center',
        textBaseline: 'bottom',
        verticalAlign: 'middle',
        fontStyle: 'normal',
        textDecoration: '',
        fontSize: 'auto',
        lineHeight: 1,
    },
    children: [],
};

export class IconBlockDrawable extends DesignableBlock<IconBlock> {
    override MIN_WIDTH = 22;
    override MIN_HEIGHT = 22;
    designSet: DesignSet = {
        default: defaultIconDesign,
    };

    protected getCurrentDesignId(): string {
        return 'default';
    }
}
