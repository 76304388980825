import {Component} from '@angular/core';
import {DroppedFileList, FileDropService} from '../file-drop.service';
import {switchMap} from 'rxjs/operators';
import {Observable, of, Subject} from 'rxjs';
import {faUpload} from '@fortawesome/free-solid-svg-icons';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'nirby-file-drop-overlay',
    templateUrl: './file-drop-overlay.component.html',
    styleUrls: ['./file-drop-overlay.component.scss'],
    animations: [
        trigger('bounce', [
            transition(':enter', [
                style({opacity: 0}),
                animate('100ms ease-in', style({opacity: 1})),
            ]),
            transition(':leave', [
                animate('200ms ease-in', style({opacity: 0}))
            ])
        ])
    ]
})
export class FileDropOverlayComponent {

    /**
     * Constructor.
     * @param service - The file drag and drop service.
     *
     * @constructor
     */
    constructor(
        private service: FileDropService,
    ) {
    }
    public readonly filesHovering$: Observable<boolean> = this.service.watchHoveringFiles();

    public readonly filesDropped$: Observable<DroppedFileList | null> = this.service.watchDroppedFiles().pipe(
        switchMap(({list: files, targets}) => {
            if (targets.length === 0 || files.length === 0) {
                return of(null);
            }

            if (targets.length === 1) {
                targets[0].drop(files);
                return of(null);
            }

            // TODO: Implement multiple targets selection.
            targets[targets.length - 1].drop(files);
            return of(null);
        }),
    );

    private readonly closeSignal = new Subject<void>();
    icons = {
        upload: faUpload,
    };

    /**
     * Closes the drop window
     */
    public close(): void {
        this.closeSignal.next();
    }
}
