import {z} from 'zod';
import {ZodSchemaBuild} from '@nirby/store/models';
import {Migrator} from '@nirby/store/migrator';

export type WorkspaceMembershipRole = 'OWNER' | 'GUEST';

export interface WorkspaceMembership {
    userId: string;
    workspaceId: string;
    workspaceName: string,
    role: WorkspaceMembershipRole;
}

export const WORKSPACE_MEMBERSHIP_SCHEMA: ZodSchemaBuild<WorkspaceMembership> =
    z.object({
        userId: z.string(),
        workspaceId: z.string(),
        workspaceName: z.string().min(1).catch(() => 'Untitled Workspace'),
        role: z
            .union([z.literal('OWNER'), z.literal('GUEST')])
            .catch((): WorkspaceMembershipRole => 'GUEST'),
    }).describe('Workspace Membership');

export const WORKSPACE_MEMBERSHIP_MIGRATOR = new Migrator<WorkspaceMembership>(
    WORKSPACE_MEMBERSHIP_SCHEMA,
    () => ({
        workspaceId: '',
        workspaceName: '',
        userId: '',
        role: 'GUEST',
    }),
);

