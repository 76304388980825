import {Component, OnDestroy} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {CloudFlareStreamVideo, CloudFlareStreamVideoProcessedData,} from '@nirby/media-models';
import {VideosService, WorkspaceService} from '@nirby/shared/database';
import {NirbyDocument} from '@nirby/store/base';
import {
    BehaviorSubject,
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    map,
    Subject,
    switchMap,
    takeUntil,
} from 'rxjs';

@Component({
    selector: 'nirby-media-library-videos',
    templateUrl: './media-library-videos.component.html',
    styleUrls: ['./media-library-videos.component.scss'],
})
/**
 * Component that display cloudflare videos
 */
export class MediaLibraryVideosComponent implements OnDestroy {
    deletedVideos$ = new BehaviorSubject<string[]>([]);
    destroy$ = new Subject<boolean>();

    faDelete = faTrash;
    videos$ = this.workspace.workspaceId$.pipe(
        switchMap((workspaceId) =>
            this.videos.collection.query()
                .where('workspaceId', '==', workspaceId)
                .where('status', '==', 'ready')
                .get()
        )
    );

    formGroup$ = this.videos$.pipe(
        map((videos) => {
            const group: { [key: string]: FormControl<string | null> } = {};
            videos.forEach((video) => {
                group[video.id] = new FormControl<string>(
                    video.data.data ? video.data.data.title : ''
                );
                group[video.id].valueChanges
                    .pipe(
                        debounceTime(1000),
                        distinctUntilChanged(),
                        takeUntil(this.destroy$)
                    )
                    .subscribe((videoTitle) => {
                        if (videoTitle) {
                            this.videos.collection.update(video.id, {
                                data: {
                                    ...video.data.data,
                                    title: videoTitle,
                                } as CloudFlareStreamVideoProcessedData,
                            });
                        }
                    });
            });
            return new FormGroup(group);
        })
    );

    displayedVideos$ = combineLatest([
        this.videos$,
        this.deletedVideos$.asObservable(),
    ]).pipe(
        map(([videos, deleted]) =>
            videos.filter((v) => !deleted.find((d) => d === v.id))
        )
    );

    /**
     * Constructor
     */
    constructor(
        private videos: VideosService,
        private workspace: WorkspaceService
    ) {
    }

    /**
     * Logical delition of a video
     * @param media NirbyDocument of a CloudFlareStreamVideo
     * @returns A Promise
     */
    delete(media: NirbyDocument<CloudFlareStreamVideo>): Promise<void> {
        this.deletedVideos$.next([...this.deletedVideos$.value, media.id]);
        return this.videos.collection.update(media.id, {status: 'deleted'});
    }

    ngOnDestroy() {
        this.destroy$.next(true);
    }
}
