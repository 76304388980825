import {CollectionKey} from './keys';
import {firstValueFrom, map, Observable} from 'rxjs';
import {NirbyDocument} from './document';
import {NirbyCollectionReference, NirbyDocumentReference} from './reference';
import {NirbyTransaction} from './transaction';
import {QueryBuilder} from './query';
import type {UpdateData} from '@angular/fire/firestore';
import {MigratorLike} from '@nirby/store/migrator';


/**
 * A service for interacting with a Firestore collection.
 */
export abstract class NirbyCollection<T extends object> {
    abstract readonly path: string;
    abstract readonly migrator: MigratorLike<T>;

    /**
     * Gets a collection of documents.
     */
    abstract get collection(): NirbyCollectionReference<T>;

    /**
     * Gets a sub-collection object for querying or accessing its documents.
     *
     * @param docId Document ID
     * @param collectionKey The collection key.
     *
     * @returns The collection.
     */
    abstract subCollection<TSC extends object>(
        docId: string,
        collectionKey: CollectionKey<TSC>
    ): NirbyCollection<TSC>;

    /**
     * Queries a collection of documents.
     *
     * @returns A promise that resolves to the created document.
     */
    abstract query(): QueryBuilder<T>;

    /**
     * Watches a document.
     * @param id The document id.
     *
     * @returns A promise that resolves to the document.
     */
    abstract watchById(id: string): Observable<NirbyDocument<T> | null>;

    /**
     * Gets a document reference.
     * @param id The document ID.
     *
     * @returns The document reference.
     */
    abstract ref(id?: string): NirbyDocumentReference<T>;

    /**
     * Creates a document.
     * @param data The data to store in the document.
     * @param id The document ID.
     *
     * @returns A promise that resolves to the created document.
     */
    abstract add(data: T, id?: string): Promise<NirbyDocumentReference<T>>;

    /**
     * Sets a document by ID.
     * @param id The document ID.
     * @param data The data to store in the document.
     *
     * @returns A promise.
     */
    abstract set(id: string, data: T): Promise<NirbyDocumentReference<T>>;

    /**
     * Get a document by ID.
     * @param id The document ID.
     *
     * @returns A promise.
     */
    abstract get(id: string): Observable<NirbyDocument<T> | null>;

    /**
     * Deletes a document by ID.
     * @param id The document ID.
     *
     * @returns A promise.
     */
    abstract delete(id: string): Promise<void>;

    /**
     * Updates a document by ID.
     *
     * @param id The document ID.
     * @param data The data to store in the document.
     *
     * @returns A promise.
     */
    abstract update(id: string, data: Partial<T>): Promise<void>;

    /**
     * Update multiple documents by ID.
     * @param ids The document IDs.
     * @param update The update to apply.
     */
    async updateMany(ids: string[], update: UpdateData<T>): Promise<void> {
        if (ids.length === 0) {
            return;
        }
        if (ids.length === 1) {
            return await this.update(ids[0], update);
        }
        return await this.runTransaction<void>(
            async (transaction) => {
                for (const id of ids) {
                    await transaction.update(this.ref(id), update);
                }
            },
        );
    }

    /**
     * Runs a transaction
     * @param fn The function to run.
     *
     * @returns A promise.
     */
    abstract runTransaction<T>(
        fn: (transaction: NirbyTransaction) => Promise<T>
    ): Promise<T>;

    /**
     * Gets a document in a transaction.
     * @param transaction The transaction.
     * @param docId The document ID.
     *
     * @returns The document, or null if it doesn't exist.
     */
    getTransaction(
        transaction: NirbyTransaction,
        docId: string,
    ): Promise<NirbyDocument<T> | null> {
        return transaction.get(this.ref(docId));
    }

    /**
     * Updates a document in a transaction.
     * @param transaction The transaction.
     * @param docId The document ID.
     * @param update The update to apply.
     *
     * @returns The transaction.
     */
    updateTransaction(
        transaction: NirbyTransaction,
        docId: string,
        update: Partial<T>,
    ): NirbyTransaction {
        return transaction.update(this.ref(docId), update);
    }

    /**
     * Deletes a document in a transaction.
     * @param transaction The transaction.
     * @param docId The document ID.
     *
     * @returns The transaction.
     */
    deleteTransaction(
        transaction: NirbyTransaction,
        docId: string,
    ): NirbyTransaction {
        return transaction.delete(this.ref(docId));
    }

    /**
     * Sets a document in a transaction.
     * @param transaction The transaction.
     * @param data The data to store in the document.
     * @param docId The document ID.
     *
     * @returns The document reference.
     */
    setTransaction(
        transaction: NirbyTransaction,
        data: T,
        docId?: string,
    ): NirbyDocumentReference<T> {
        const ref = this.ref(docId);
        transaction.set(ref, data);
        return ref;
    }

    /**
     * Migrates a document in a transaction.
     * @param transaction The transaction.
     * @param workspace The document to migrate.
     *
     * @returns The transaction.
     */
    migrateTransaction(
        transaction: NirbyTransaction,
        workspace: NirbyDocument<T>,
    ): NirbyTransaction {
        const newWorkspace = this.migrator.migrate(workspace).migrated;
        return this.updateTransaction(transaction, workspace.id, newWorkspace);
    }

    /**
     * Migrates many documents in a transaction.
     * @param transaction The transaction.
     * @param workspaces The documents to migrate.
     *
     * @returns The transaction.
     */
    migrateManyTransaction(
        transaction: NirbyTransaction,
        workspaces: NirbyDocument<T>[],
    ): NirbyTransaction {
        for (const workspace of workspaces) {
            this.migrateTransaction(transaction, workspace);
        }
        return transaction;
    }

    /**
     * Checks if a document exists.
     * @param documentId The document ID.
     *
     * @returns A promise that resolves to true if the document exists.
     */
    exists(documentId: string): Promise<boolean> {
        return firstValueFrom(this.get(documentId).pipe(
            map((doc) => doc !== null),
        ));
    }
}
