import {Pop, POP_MIGRATOR} from '../pop';
import {Prime, PRIME_MIGRATOR} from '../prime';
import {z} from 'zod';
import {Card, CARD_MIGRATOR, CARD_SCHEMA} from '@nirby/models/nirby-player';
import {Migrator} from '@nirby/store/migrator';
import {ZodSchemaBuild} from '@nirby/store/models';
import {NirbyDocumentReference} from '@nirby/store/base';
import {buildUploadedReferenceSchema} from '@nirby/store/proxy';

export type AppCardBackReference =
    | NirbyDocumentReference<Pop>
    | NirbyDocumentReference<Prime>;

export const APP_CARD_BACK_REFERENCE_SCHEMA: ZodSchemaBuild<AppCardBackReference> =
    z.union([
        buildUploadedReferenceSchema<Pop>(() => POP_MIGRATOR),
        buildUploadedReferenceSchema<Prime>(() => PRIME_MIGRATOR),
    ]);

export interface AppCard {
    title: string;
    usedBy: AppCardBackReference | null;
    card: Card;
}

export const APP_CARD_SCHEMA = z.object({
    title: z.string().default('Untitled'),
    usedBy: APP_CARD_BACK_REFERENCE_SCHEMA.nullable().default(null),
    card: CARD_SCHEMA,
});

export const APP_CARD_MIGRATOR = new Migrator<AppCard>(APP_CARD_SCHEMA, () => {
    return {
        title: 'Untitled',
        usedBy: null,
        card: CARD_MIGRATOR.defaultModelFn(),
    };
});
