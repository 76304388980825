import {DesignableBlock} from '../../block-designable';
import {DesignSet, ShapeUnitRawDescription} from '../../../designs';
import {AnswerQuestionAction, WidgetSliderBlock} from '@nirby/models/nirby-player';
import {PickValueBlockEvent} from '../../../designs/unit.model';
import {Subscription} from 'rxjs';
import Konva from 'konva';

const H_MARGIN = 20;
const V_MARGIN = 10;
const SLIDER_HEIGHT = 9;
const INNER_SPACING = 7;
const HELP_TEXT_HEIGHT = 13;
const HELPER_ANTI_PADDING = 5;

const defaultDesign: ShapeUnitRawDescription<'Rect'> = {
    shape: 'Rect',
    children: [
        {
            shape: 'Rect',
            attributes: {
                backgroundColor: '#ffffff',
                borderRadius: 10,
                shadowColor: 'rgba(0,0,0,0.15)',
                shadowOffsetY: 4,
                shadowOffsetX: 0,
                shadowBlur: 16,
            },
            children: [
                // Title
                {
                    shape: 'Text',
                    axis: {
                        horizontal: 'stretch',
                        vertical: 'stretch',
                        left: H_MARGIN,
                        right: H_MARGIN,
                        top: V_MARGIN,
                        bottom:
                            V_MARGIN +
                            SLIDER_HEIGHT +
                            INNER_SPACING +
                            HELP_TEXT_HEIGHT +
                            INNER_SPACING,
                    },
                    attributes: {
                        fontStyle: 'bold',
                        verticalAlign: 'top',
                        fill: '#484B57',
                        text: {
                            method: 'GetBlockContent',
                            arguments: ['title'],
                        },
                        fontSize: 14,
                        align: 'center',
                    },
                    children: [],
                },
                // Slider
                {
                    shape: 'Rect',
                    axis: {
                        horizontal: 'stretch',
                        vertical: 'pin-bottom',
                        left: H_MARGIN,
                        right: H_MARGIN,
                        bottom: V_MARGIN + HELP_TEXT_HEIGHT + INNER_SPACING,
                        height: SLIDER_HEIGHT,
                    },
                    attributes: {
                        backgroundColor: '#CCD0E2',
                        borderColor: 'rgba(0,0,0,0)',
                        borderRadius: 25,
                    },
                    children: [
                        // Slider interior
                        {
                            shape: 'Draggable',
                            axis: {
                                horizontal: 'stretch',
                                vertical: 'stretch',
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                            },
                            attributes: {
                                disableOnDrag: true,
                                color: {
                                    method: 'GetBlockContent',
                                    arguments: ['color'],
                                },
                                question: {
                                    method: 'GetBlockId',
                                    arguments: [],
                                },
                                emoji: {
                                    method: 'GetBlockContent',
                                    arguments: ['emoji'],
                                },
                            },
                            children: [],
                        },
                    ],
                },
                // Helper Text
                {
                    shape: 'Text',
                    axis: {
                        horizontal: 'stretch',
                        vertical: 'pin-bottom',
                        left: H_MARGIN - HELPER_ANTI_PADDING,
                        right: H_MARGIN - HELPER_ANTI_PADDING,
                        bottom: V_MARGIN,
                        height: HELP_TEXT_HEIGHT,
                    },
                    attributes: {
                        overflow: 'ellipsis',
                        text: {
                            method: 'IfElseBlock',
                            arguments: [
                                'value',
                                {
                                    method: 'GetBlockContentOrElse',
                                    arguments: [
                                        'submitMessage',
                                        {
                                            method: 'Translate',
                                            arguments: [
                                                'BLOCKS.SLIDER.SUCCESS',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    method: 'Translate',
                                    arguments: [
                                        'BLOCKS.SLIDER.HELPER',
                                    ],
                                },
                            ],
                        },
                        fontSize: 10,
                        fill: '#8B8E9C',
                        align: 'center',
                    },
                    children: [],
                },
            ],
            axis: {
                horizontal: 'stretch',
                vertical: 'stretch',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
            },
        },
    ],
    axis: {
        horizontal: 'stretch',
        vertical: 'stretch',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
    attributes: {
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor: 'rgba(0,0,0,0)',
        borderRadius: 0,
    },
};

/**
 * Slider block
 */
export class SliderBlockDrawable<TMeta> extends DesignableBlock<TMeta, WidgetSliderBlock> {
    override MIN_WIDTH = 164;
    override MIN_HEIGHT = 100;
    override baseSize = {
        width: 180,
        height: 100,
    };

    override blockToActionTransformers = {
        PickValue: (event: PickValueBlockEvent): AnswerQuestionAction => {
            this.memory.set('value', event.properties.value);
            return {
                id: null,
                options: {
                    questionId: this.id,
                    question: this.blockProperties.title,
                    answer: event.properties.value,
                },
                type: 'answer-question',
            };
        },
    };

    designSet: DesignSet = {
        default: defaultDesign,
    };

    /**
     * The current design ID
     * @protected
     *
     * @returns {string} default
     */
    protected getCurrentDesignId(): string {
        return 'default';
    }

    /**
     * Preprocess the block before rendering
     * @param options - Options to pass to the block
     * @protected
     */
    protected override preprocess(options: WidgetSliderBlock): void {
        options.content.title = this.global.transform(options.content.title);
    }

    /**
     * Subscribes to the block's events and marks the slider value as persistent.
     * @param shape - The shape to subscribe to
     * @protected
     *
     * @returns - Subscription
     */
    protected override subscribeToEvents(shape: Konva.Group): Subscription {
        const subscription = super.subscribeToEvents(shape);
        this.context.markKeysAsPersistent(this.id + '.0.value');
        return subscription;
    }
}
