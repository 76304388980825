import { Injectable } from '@angular/core';
import { ImageRecord, ImageSource } from '@nirby/media-models';

@Injectable({
    providedIn: 'root',
})
export abstract class ImageService<TS extends ImageSource> {
    public abstract search(query: string): Promise<ImageRecord[]>;

    public abstract getImage(photoId: string): Promise<ImageRecord>;

    public abstract getRefreshedRecord(
        source: ImageRecord
    ): Promise<ImageRecord>;
}
