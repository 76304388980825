import {Migrator} from '@nirby/store/migrator';
import {z} from 'zod';
import {BaseBlock, buildBlockSchema} from './block.model';
import {ZodSchemaBuild} from '@nirby/store/models';

export type ImagePosition =
    | 'left top'
    | 'left center'
    | 'left bottom'
    | 'right top'
    | 'right center'
    | 'right bottom'
    | 'center top'
    | 'center center'
    | 'center bottom';

export const IMAGE_POSITION_SCHEMA = z.union([
    z.literal('left top'),
    z.literal('left center'),
    z.literal('left bottom'),
    z.literal('right top'),
    z.literal('right center'),
    z.literal('right bottom'),
    z.literal('center top'),
    z.literal('center center'),
    z.literal('center bottom'),
]);

export const IMAGE_FIT_SCHEMA = z.union([
    z.literal('contain'),
    z.literal('cover'),
]);

export type ImageFit = z.infer<typeof IMAGE_FIT_SCHEMA>;

export const IMAGE_BLOCK_CONTENT_SCHEMA = z.object({
    src: z.string(),
});

export const IMAGE_BLOCK_STYLE_SCHEMA = z.object({
    fit: IMAGE_FIT_SCHEMA,
    positioning: IMAGE_POSITION_SCHEMA,
    clickable: z.string(),
});

export type ImageBlock = BaseBlock<
    'Image',
    {
        src: string;
    },
    {
        fit: ImageFit;
        positioning: ImagePosition;
        clickable: string;
    }
>;
export type ImageBlockStyle = ImageBlock['style'];
export type ImageBlockContent = ImageBlock['content'];

export const IMAGE_BLOCK_SCHEMA: ZodSchemaBuild<ImageBlock> = buildBlockSchema(
    'Image' as const,
    IMAGE_BLOCK_CONTENT_SCHEMA,
    IMAGE_BLOCK_STYLE_SCHEMA,
);

export const IMAGE_BLOCK_MIGRATOR = new Migrator<ImageBlock>(
    IMAGE_BLOCK_SCHEMA,
    () => ({
        actions: {
            click: [],
        },
        content: {
            src: 'assets/img/bg/nby-pop-button.jpg',
        },
        hash: '',
        position: [
            {x: 0, y: 0},
            {x: 128, y: 128},
        ],
        rotation: 0,
        style: {
            clickable: 'true',
            positioning: 'center center',
            fit: 'cover',
        },
        type: 'Image',
    }),
);
