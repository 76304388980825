<div class="container create-widget-container">
    <div class="row">
        <div
            class="card col-md-6 offset-md-3 px-0"
            *ngIf="index !== 1; else formTemplate"
        >
            <div
                class="area-icon d-flex justify-content-center align-items-center"
            >
                <fa-icon
                    [icon]="cardContent[index].icon"
                    class="icon d-flex justify-content-center align-items-center"
                ></fa-icon>
            </div>
            <div class="featured-media">
                <nirby-videos-widget
                    [thumbSource]="cardContent[index].thumb"
                    [videoURL]="cardContent[index].videoURL"
                ></nirby-videos-widget>
            </div>
            <div class="card-body">
                <nirby-tutorial-widget
                    [titleCard]="cardContent[index].cardTitle"
                    [bodyCard]="cardContent[index].cardText"
                ></nirby-tutorial-widget>

                <div *ngIf="index === 2 && createdWidget">
                    <nirby-widget-script
                        [widgetId]="createdWidget.id"
                    ></nirby-widget-script>
                </div>
                <div *ngIf="index !== 2; else buttonTemplate" class="mt-5">
                    <button
                        class="btn btn-primary float-right"
                        (click)="onNewWidget(null)"
                    >
                        {{ cardContent[index].labelButton }}
                        <fa-icon class="icon ms-4" [icon]="arrowIcon"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <ng-template #formTemplate>
        <div class="card card-widget col-md-6 offset-md-3 px-0">
            <div class="card-header border-0 pt-5">
                <div
                    class="area-icon d-flex justify-content-center align-items-center"
                >
                    <fa-icon
                        [icon]="cardContent[index].icon"
                        class="icon d-flex justify-content-center align-items-center"
                    ></fa-icon>
                </div>

                <h2 class="title mb-0">New Workspace</h2>
            </div>

            <div class="card-body">
                <nirby-new-widget-form
                    [index]="index"
                    (widgetCreated)="onNewWidget($event)"
                ></nirby-new-widget-form>
            </div>
        </div>
    </ng-template>

    <ng-template #buttonTemplate>
        <button
            class="btn btn-primary float-right"
            *ngIf="createdWidget"
            [routerLink]="['/workspaces', createdWidget.id]"
        >
            {{ cardContent[index].labelButton }}
            <fa-icon class="icon ms-4" [icon]="arrowIcon"></fa-icon>
        </button>
    </ng-template>
</div>
