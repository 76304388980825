<div class="d-flex flex-row">
    <button
        *ngFor="let tab of tabs"
        [routerLink]="tab.routerLink"
        routerLinkActive="active"
        [routerLinkActiveOptions]="tab.routerLinkActiveOptions || { exact: true }"
        [queryParams]="tab.queryParams"
        [queryParamsHandling]="tab.queryParamsHandling"
    >
        {{tab.label}}
    </button>
</div>
