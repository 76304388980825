import {AfterViewInit, Component, OnInit} from '@angular/core';
import {combineLatest, firstValueFrom, from, Observable, Subscription} from 'rxjs';
import {CampaignDisplay, Pop, RouteData, Workspace} from '@nirby/models/editor';
import {faArrowLeft, faPlus} from '@fortawesome/free-solid-svg-icons';
import {ActivatedRoute, Router} from '@angular/router';
import {
    PopService,
    PublishedPopsService,
    RouteParametersService,
    RouteService,
    WorkspaceService,
} from '@nirby/shared/database';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {FormGroup, UntypedFormControl} from '@angular/forms';
import {AppError} from '@nirby/js-utils/errors';
import {shareReplaySafe} from '@nirby/ngutils';
import {AlertsService} from '@nirby/shared/alerts';
import {NirbyDocument} from '@nirby/store/base';

interface PopItem {
    pop: NirbyDocument<Pop>;
    isPublished: boolean;
}

@Component({
    selector: 'nirby-routes-form',
    templateUrl: './routes-form.component.html',
    styleUrls: ['./routes-form.component.scss'],
})
export class RoutesFormComponent implements OnInit, AfterViewInit {
    constructor(
        private activeRoute: ActivatedRoute,
        private routeParams: RouteParametersService,
        private widgetService: WorkspaceService,
        private routeService: RouteService,
        private campaignService: PopService,
        private published: PublishedPopsService,
        private toastService: AlertsService,
        private router: Router,
    ) {
        const widgetRoute = activeRoute.parent?.parent;
        const routeRoute = activeRoute;
        if (!widgetRoute) {
            throw new AppError('Widget route not found');
        }
        if (!routeRoute) {
            throw new AppError('Route route not found');
        }
        this.widgetRoute = widgetRoute;
        this.routeRoute = routeRoute;

        firstValueFrom(
            this.routeParams.widgetId$.pipe(
                switchMap((widgetId) =>
                    this.widgetService.collection.get(widgetId),
                ),
            ),
        ).then((data) => (this.widgetData = data?.data));

        const routeId$: Observable<string> = routeRoute.params.pipe(
            map((params) => params['routeId']),
        );
        this.widgetRouteData$ = combineLatest([
            this.routeParams.widgetId$,
            routeId$,
        ]).pipe(
            switchMap(([widgetId, routeId]) =>
                combineLatest([
                    this.widgetService.collection
                        .get(widgetId)
                        .pipe(map((data) => data?.toModeled() ?? null)),
                    this.routeService
                        .collection(widgetId)
                        .get(routeId)
                        .pipe(map((data) => data?.toModeled() ?? null)),
                ]).pipe(
                    map(([widget, route]) => {
                        if (!widget || !route) {
                            return null;
                        }
                        return [widget, route] as [Workspace, RouteData];
                    }),
                    startWith(null),
                ),
            ),
            catchError(() => {
                return from(
                    this.router.navigate([
                        '/workspaces',
                        this.widgetId,
                        'routes',
                    ]),
                ).pipe(map(() => null));
            }),
        );

        this.campaigns$ = this.routeParams.widgetId$.pipe(
            switchMap((widgetId) => {
                return firstValueFrom(
                    campaignService
                        .collection(widgetId)
                        .query().where('status', '==', 'active').get(),
                ).then((list) =>
                    Promise.all(
                        list.map((p) =>
                            published
                                .isPublishedOnce(this.widgetId, p.id)
                                .then((isPublished) => ({
                                    pop: p,
                                    isPublished,
                                })),
                        ),
                    ),
                );
            }),
            shareReplaySafe(),
        );
    }

    get widgetId(): string {
        return this.routeParams.workspaceId;
    }

    get routeId(): string {
        return this.routeParams.getParamSafe('routeId');
    }

    widgetRouteData$: Observable<[Workspace, RouteData] | null>;
    subscription = new Subscription();

    campaigns$: Observable<PopItem[] | null>;
    widgetData?: Workspace;

    plusIcon = faPlus;
    iconArrowLeft = faArrowLeft;
    addCampaigns: number[] = [];

    widgetRoute: ActivatedRoute;
    routeRoute: ActivatedRoute;

    fullUrl: string | null = null;

    routePopControl = new UntypedFormControl(null);
    routeTypeControl = new UntypedFormControl('match');
    routePathControl = new UntypedFormControl(null);

    routeForm = new FormGroup({
        pop: this.routePopControl,
        type: this.routeTypeControl,
        path: this.routePathControl,
    });
    routeId$ = this.routeParams.watchParam('routeId');

    ngAfterViewInit(): void {
        this.subscription.add(
            this.routeForm.valueChanges.subscribe(() => this.save()),
        );
    }

    ngOnInit(): void {
        this.subscription.add(
            this.widgetRouteData$.subscribe((widgetRoute) => {
                if (!widgetRoute) {
                    this.fullUrl = null;
                    this.routeForm.disable({emitEvent: false});
                    return;
                } else {
                    this.routeForm.enable({emitEvent: false});
                }

                const [widget, route] = widgetRoute;

                let baseUrl = widget.url;
                if (baseUrl && baseUrl.slice(0, 4) !== 'http') {
                    baseUrl = 'https://' + baseUrl;
                }

                const routePath =
                    typeof route.route === 'string'
                        ? route.route
                        : route.route.content;
                this.fullUrl = baseUrl + routePath;

                this.routeForm.setValue(
                    {
                        path: routePath,
                        pop: route.campaigns[0]?.ref?.id
                            ? route.campaigns[0].ref.id
                            : null,
                        type:
                            typeof route.route === 'string'
                                ? 'match'
                                : route.route.type,
                    },
                    {emitEvent: false},
                );
                this.routeForm.markAsUntouched();
            }),
        );
    }

    async save(): Promise<void> {
        this.routeForm.disable({emitEvent: false});

        // save data at the start of the method to avoid inconsistencies while moving between routes
        const [widgetId, routeId] = [this.widgetId, this.routeId];
        const {pop, type, path} = this.routeForm.value;

        // search pop data
        let campaignDisplay: CampaignDisplay | null = null;
        if (this.routePopControl.value) {
            const campaignRef = this.campaignService
                .collection(widgetId)
                .ref(pop);
            const isPublished = await this.published.isPublishedOnce(
                widgetId,
                campaignRef.id,
            );
            const campaign = await firstValueFrom(
                this.campaignService.collection(widgetId).get(pop),
            );
            if (campaign && isPublished) {
                campaignDisplay = {
                    ref: campaignRef,
                    title: campaign.data.title,
                };
            } else {
                this.routePopControl.setValue(null, {emitEvent: false});
                return;
            }
        }

        // update route
        await this.routeService.update(widgetId, routeId, {
            route: {
                type,
                content: path,
            },
            campaigns: campaignDisplay ? [campaignDisplay] : [],
        });

        this.routeForm.enable({emitEvent: false});
    }
}
