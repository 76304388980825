import {
    AfterViewInit,
    Component,
    ContentChildren,
    Input,
    OnDestroy,
    QueryList,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { FadingViewItemComponent } from '../fading-view-item/fading-view-item.component';
import { BehaviorSubject, of, Subscription, switchMap } from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    map,
    startWith,
    tap,
} from 'rxjs/operators';

@Component({
    selector: 'nirby-fading-view-set',
    templateUrl: './fading-view-set.component.html',
    styleUrls: ['./fading-view-set.component.scss'],
})
export class FadingViewSetComponent implements AfterViewInit, OnDestroy {
    @Input() set code(code: string | null) {
        this.currentCodeSubject.next(code);
    }

    get code(): string | null {
        return this.currentCodeSubject.value;
    }

    @ContentChildren(FadingViewItemComponent) fadingViews =
        new QueryList<FadingViewItemComponent>();

    readonly currentCodeSubject = new BehaviorSubject<string | null>(null);

    public readonly currentView$ = this.currentCodeSubject.pipe(
        distinctUntilChanged(),
        switchMap((code) =>
            this.fadingViews.changes.pipe(
                startWith(this.fadingViews),
                map(() => ({
                    views: this.fadingViews,
                    code,
                }))
            )
        ),
        map(
            ({ code, views }) =>
                views.find((view) => view.code === code) ?? null
        )
    );

    public readonly currentTemplate$ = this.currentView$.pipe(
        map((view) => (view ? view.template : null)),
        filter((view): view is TemplateRef<unknown> => !!view)
    );

    private readonly subscription = new Subscription();

    constructor(private readonly viewContainerRef: ViewContainerRef) {}

    ngAfterViewInit(): void {
        this.subscription.add(
            this.currentTemplate$.subscribe((template) => {
                this.viewContainerRef.clear();
                this.viewContainerRef.createEmbeddedView(template);
            })
        );
        this.subscription.add(
            this.currentView$
                .pipe(switchMap((view) => (view ? view.goToRequest : of(null))))
                .subscribe((code) => {
                    this.code = code;
                })
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
