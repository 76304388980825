import {Component, OnDestroy, OnInit} from '@angular/core';
import {BlockContentComponent} from '../../block-content.component';
import {FormGroup, UntypedFormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {defaults, WidgetSliderBlock} from '@nirby/models/nirby-player';

@Component({
    selector: 'nirby-slider-block-content',
    templateUrl: './slider-block-content.component.html',
    styleUrls: ['./slider-block-content.component.scss'],
})
export class SliderBlockContentComponent
    extends BlockContentComponent<WidgetSliderBlock['content']>
    implements OnInit, OnDestroy {
    colorControl = new UntypedFormControl(defaults.blocks.Slider.content.color);
    labelControl = new UntypedFormControl(defaults.blocks.Slider.content.title);
    emojiControl = new UntypedFormControl(defaults.blocks.Slider.content.emoji);
    submitMessageControl = new UntypedFormControl(
        defaults.blocks.Slider.content.submitMessage,
    );

    form = new FormGroup({
        color: this.colorControl,
        label: this.labelControl,
        emoji: this.emojiControl,
        submitMessage: this.submitMessageControl,
    });

    subscription = new Subscription();

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngOnInit(): void {
        this.subscription.add(
            this.form.valueChanges.subscribe((value) => {
                this.sendData({
                    title: value.label,
                    color: value.color,
                    emoji: value.emoji,
                    submitMessage: value.submitMessage,
                });
            }),
        );
    }

    public setData(content: WidgetSliderBlock['content'] | null): void {
        if (!content) {
            return;
        }
        this.colorControl.setValue(content.color, {emitEvent: false});
        this.labelControl.setValue(content.title, {emitEvent: false});
        this.emojiControl.setValue(content.emoji, {emitEvent: false});
        this.submitMessageControl.setValue(content.submitMessage, {emitEvent: false});
    }
}
