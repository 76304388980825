import {NIRBY_VARIABLE_SOURCE_SCHEMA, NirbyVariableDeclaration, NirbyVariableType} from '@nirby/runtimes/state';
import {z} from 'zod';
import {Prime, PRIME_MIGRATOR} from '../prime';
import {Pop, POP_MIGRATOR} from './pop.model';
import {Migrator} from '@nirby/store/migrator';
import {buildUploadedReferenceSchema} from '@nirby/store/proxy';
import {ZodSchemaBuild} from '@nirby/store/models';
import {NirbyDocumentReference} from '@nirby/store/base';

export type AppVariableBase<TVariable extends NirbyVariableType, TValue> = {
    type: TVariable;
    name: string;
    displayName: string;
    initialValue: TValue | null;
    source: NirbyVariableDeclaration['source'];
    usedBy: NirbyDocumentReference<Prime> | NirbyDocumentReference<Pop>;
};

export type AppVariable =
    | AppVariableBase<'string', string>
    | AppVariableBase<'number', number>
    | AppVariableBase<'boolean', boolean>;

const USED_BY_SCHEMA = z.union([
    buildUploadedReferenceSchema<Prime>(() => PRIME_MIGRATOR),
    buildUploadedReferenceSchema<Pop>(() => POP_MIGRATOR),
]);

export const APP_VARIABLE_STRING_SCHEMA: ZodSchemaBuild<
    AppVariable & { type: 'string' }
> = z.object({
    type: z.literal('string'),
    name: z.string(),
    displayName: z.string(),
    initialValue: z.string().nullable(),
    source: NIRBY_VARIABLE_SOURCE_SCHEMA,
    usedBy: USED_BY_SCHEMA,
});

export const APP_VARIABLE_NUMBER_SCHEMA: ZodSchemaBuild<
    AppVariable & { type: 'number' }
> = z.object({
    type: z.literal('number'),
    name: z.string(),
    displayName: z.string(),
    initialValue: z.number().nullable(),
    source: NIRBY_VARIABLE_SOURCE_SCHEMA,
    usedBy: USED_BY_SCHEMA,
});

export const APP_VARIABLE_BOOLEAN_SCHEMA: ZodSchemaBuild<
    AppVariable & { type: 'boolean' }
> = z.object({
    type: z.literal('boolean'),
    name: z.string(),
    displayName: z.string(),
    initialValue: z.boolean().nullable(),
    source: NIRBY_VARIABLE_SOURCE_SCHEMA,
    usedBy: USED_BY_SCHEMA,
});

export const APP_VARIABLE_SCHEMA: ZodSchemaBuild<AppVariable> = z.union([
    APP_VARIABLE_STRING_SCHEMA,
    APP_VARIABLE_NUMBER_SCHEMA,
    APP_VARIABLE_BOOLEAN_SCHEMA,
]);

export const APP_VARIABLE_MIGRATOR = new Migrator<AppVariable>(
    APP_VARIABLE_SCHEMA,
    (): AppVariable => ({
        type: 'string',
        source: 'private',
        initialValue: 'EMPTY',
        usedBy: null as never,
        displayName: '',
        name: '',
    }),
);
