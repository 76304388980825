import {
    ComponentFactoryResolver,
    Directive,
    forwardRef,
    Input,
    ViewContainerRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BlockHostDirective } from './block-host.directive';

import { BlockController } from '../../../services/block-controller';
import { BlockContentRegisterService } from '../../../services/registers/block-content-register.service';

@Directive({
    selector: '[nirbyBlockContentHost]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BlockContentHostDirective),
            multi: true,
        },
    ],
})
export class BlockContentHostDirective<TMeta> extends BlockHostDirective<TMeta, 'content'> {
    blockKey = 'content' as const;

    /**
     * New block ID
     * @param value New block ID
     */
    @Input()
    override set controller(value: BlockController<TMeta> | null) {
        super.controller = value;
    }

    constructor(
        viewContainerRef: ViewContainerRef,
        componentFactoryResolver: ComponentFactoryResolver,
        register: BlockContentRegisterService
    ) {
        super(viewContainerRef, componentFactoryResolver, register);
    }
}
