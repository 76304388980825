import {BaseShapeUnitDescription} from '../unit.model';
import {UnitAttributes} from './attributes.model';
import {Bounds, ShapeUnit} from './shape-unit';
import canvasTxt from 'canvas-txt';
import {clamp} from '@nirby/js-utils/math';
import {RectShapeUnit} from './rect.unit';
import {ShapeUnitListenableEvent} from './index';
import {mapTo, Observable, skipWhile, take, takeUntil} from 'rxjs';
import { tryParseColor } from '../../utils/color';
import { Logger } from '@nirby/logger';

export interface DraggableUnitAttributes extends UnitAttributes {
    emoji: number | string;
    color: string;
    emojiScale: number;
    disableOnDrag: boolean;
    question: string;
}

export type DraggableUnit = BaseShapeUnitDescription<
    'Draggable',
    DraggableUnitAttributes
>;

export class DraggableShapeUnit extends ShapeUnit<'Draggable'> {
    defaultValues: DraggableUnitAttributes = {
        color: '#3b14d3',
        emoji: '😻',
        emojiScale: 2.5,
        disableOnDrag: false,
        question: 'draggable',
        cursor: 'pointer',
    };

    override extraListenTo: ShapeUnitListenableEvent[] = ['stateChanges'];

    public override adjustToParent(bounds: Bounds): void {
        super.adjustToParent(bounds);
    }
    protected draw(
        ctx: CanvasRenderingContext2D,
        attributes: DraggableUnitAttributes,
        bounds: Bounds,
    ): void {
        canvasTxt.fontSize = bounds.height * attributes.emojiScale;
        const offset = canvasTxt.fontSize - bounds.height;
        const end = bounds.x - offset + this.value * bounds.width;

        // draw rect
        const color = tryParseColor(attributes.color);
        ctx.fillStyle = color.toString();
        RectShapeUnit.drawRoundedRect(
            ctx,
            bounds.x,
            bounds.y,
            end - 2,
            bounds.height,
            16,
        );
        ctx.fill();

        ctx.fillStyle = '#000000';
        ctx.textBaseline = 'bottom';

        canvasTxt.align = 'left';
        canvasTxt.vAlign = 'middle';
        canvasTxt.fontWeight = 'normal';
        canvasTxt.fontStyle = 'normal';
        canvasTxt.justify = false;
        canvasTxt.lineHeight = null;
        canvasTxt.font =
            '"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';

        // draw emoji
        canvasTxt.drawText(
            ctx,
            typeof attributes.emoji === 'number'
                ? String.fromCodePoint(attributes.emoji)
                : attributes.emoji,
            end,
            bounds.y,
            bounds.width,
            bounds.height,
        );
    }

    public get hasBeenDragged(): boolean {
        return this.memory.state.getInt('dragged') > 0;
    }

    public get value(): number {
        return this.memory.state.getFloat('value');
    }

    public set value(newValue: number) {
        this.memory.set('value', newValue);
    }

    private whilePressed(): void {
        const mouseX = this.cursorListener.mousePosition.x;
        const width = this.bounds.width;
        this.value = clamp(mouseX / width, 0, 1);
    }

    private onMouseUp(): void {
        Logger.logAt('UNIT:SLIDER', 'FINISHED DRAG');

        this.memory.disabled = this.attributes.disableOnDrag;
        this.memory.set('dragged', this.memory.state.getInt('dragged') + 1);
        this.attributes.disableOnDrag && this.hasBeenDragged;
        this.emit({
            type: 'PickValue',
            properties: {
                name: this.attributes.question,
                value: this.value,
            },
        });
    }

    override startPlayMode() {
        super.startPlayMode();

        const onDisable$: Observable<void> = this.memory.disabled$.pipe(
            skipWhile((disabled) => !disabled),
            take(1),
            mapTo(undefined),
        );
        this.subscriptions.add(
            this.cursorListener
                .pressed$(
                    () => this.memory.state.getFloatNullable('value') === null,
                )
                .pipe(takeUntil(onDisable$))
                .subscribe(this.whilePressed.bind(this)),
        );
        this.subscriptions.add(
            this.cursorListener.up$
                .pipe(takeUntil(onDisable$))
                .subscribe(this.onMouseUp.bind(this)),
        );
    }
}
