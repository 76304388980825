import {Conditional, CONDITIONAL_SCHEMA} from '@nirby/conditionals';
import {z} from 'zod';
import {ZodSchemaBuild} from '@nirby/store/models';

export type ActionOptionsOf<T extends GenericAction> = T extends GenericAction<
        string,
        infer TOptions
    >
    ? TOptions
    : never;

export type GenericAction<
    TActionType extends string = string,
    TOptions extends object | null = object | null
> = {
    id: string | null;
    type: TActionType;
    options: TOptions;
    if?: Conditional | null;
};

/**
 * Builds an action schema
 * @param type The action type
 * @param optionsSchema The options schema
 *
 * @returns The action schema
 */
export function buildActionSchema<Action extends GenericAction>(
    type: Action['type'],
    optionsSchema: ZodSchemaBuild<Action['options']>,
): ZodSchemaBuild<Action> {
    return z.object({
        id: z.string().nullable(),
        type: z.literal(type),
        options: optionsSchema,
        if: CONDITIONAL_SCHEMA.nullable().optional(),
    }) as ZodSchemaBuild<Action>;
}
