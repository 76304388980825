import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer, SafeHtml, SafeUrl} from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl'
})
/**
 * A pipe to sanitize URLs for use in the src attribute of an iframe.
 */
export class SafeUrlPipe implements PipeTransform {
    /**
     * Constructor.
     * @param sanitizer The sanitizer.
     */
    constructor(
        private readonly sanitizer: DomSanitizer
    ) {
    }

    /**
     * Transforms the value.
     * @param value The value.
     *
     * @returns The sanitized value.
     */
    transform(value: string): SafeUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
    }
}
