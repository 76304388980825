/* eslint-disable @typescript-eslint/no-explicit-any */
import {AsyncChannel} from '@nirby/js-utils/async';
import {BehaviorSubject, Observable} from 'rxjs';

/**
 * Helper to run a process only once at a time, and notifying if it's still running.
 */
export class ProcessSingleton<TProcess extends (...args: any[]) => Promise<void>> {
    private readonly channel = new AsyncChannel();

    private readonly runningSubject = new BehaviorSubject<boolean>(false);

    /**
     * Observable to watch if the process is running or not.
     */
    public get isRunning$(): Observable<boolean> {
        return this.runningSubject.asObservable();
    }

    /**
     * If the process is running.
     */
    public get isRunning(): boolean {
        return this.runningSubject.value;
    }

    /**
     * Constructor.
     * @param process The process to run.
     */
    constructor(
        private readonly process: TProcess,
    ) {
    }

    /**
     * Start the process.
     * @param args The arguments to pass to the process.
     *
     * @returns - A promise that resolves when the process is finished.
     */
    call(...args: Parameters<TProcess>): Promise<void> {
        return this.channel.wait(() => new Promise<void>((res) => {
            this.runningSubject.next(true);
            this.process(...args).then(() => {
                this.runningSubject.next(false);
                res();
            });
        }));
    }

    /**
     * Starts the process without waiting for it to finish.
     * @param args The arguments to pass to the process.
     */
    callDetached(...args: Parameters<TProcess>): void {
        this.call(...args).then();
    }
}
