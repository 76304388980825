import type {Type} from '@angular/core';
import {ShapeUnit} from './shape-unit';
import {TextShapeUnit, TextUnit} from './text.unit';
import Konva from 'konva';
import {AppError} from '@nirby/js-utils/errors';
import {RectShapeUnit, RectUnit} from './rect.unit';
import {AxisMixin, BlockEvent, RawBlockEvent} from '../unit.model';
import {Attributes, PartialRawAttributes} from './attributes.model';
import {DraggableShapeUnit, DraggableUnit} from './draggable.unit';
import {Observable} from 'rxjs';
import {NirbyContext, NirbyMemoryMask, StateManager} from '@nirby/runtimes/context';
import {ContainerShapeUnit, ContainerUnit} from './container.unit';
import {ImageShapeUnit, ImageUnit} from './image.unit';
import {AnyBlock} from '@nirby/models/nirby-player';
import {FunctionObjectOfReturn} from '../functions.model';

/**
 * Mixin to add shape unit children to a unit
 */
export type ParentUnitMixin = { children: ShapeUnitRawDescription[] };

export type ShapeUnitListenableEvent =
    | 'isMouseDown'
    | 'isHover'
    | 'stateChanges';
export type ListenToMixin = { listenTo?: ShapeUnitListenableEvent[] };
export type IfMixin = { if?: FunctionObjectOfReturn<boolean> };

type ShapeMixin = AxisMixin & ParentUnitMixin & ListenToMixin & IfMixin;

/**
 * All standard shape units
 */
type AnyShapeUnitDescription = Readonly<(TextUnit | RectUnit | DraggableUnit | ContainerUnit | ImageUnit) &
    ShapeMixin>;

/**
 * Shape unit names
 */
export type AnyShapeUnitName = AnyShapeUnitDescription['shape'];

/**
 * Pick a shape unit from the first parameter if it has the given shape unit name
 */
type PickShapeUnitByName<Shape extends AnyShapeUnitDescription,
    Name extends AnyShapeUnitName> = Shape extends { shape: Name } ? Shape : never;

type PickShapeUnitAttributesByName<Shape extends AnyShapeUnitDescription,
    Name extends AnyShapeUnitName> = Shape extends { shape: Name; attributes: infer Attributes }
    ? Attributes
    : never;

/**
 * Pick a shape unit from the shape unit name
 */
export type ShapeUnitDescription<Name extends AnyShapeUnitName = AnyShapeUnitName> = PickShapeUnitByName<AnyShapeUnitDescription, Name>;

/**
 * The shape unit description but with the attributes property made "raw"
 * @see RawAttributes
 */
export type RawDescription<Unit extends AnyShapeUnitDescription> =
    Unit extends {
            shape: infer Name;
            attributes: infer TA;
        }
        ? {
        shape: Name;
        attributes: PartialRawAttributes<TA extends Attributes ? TA : never>;
        events?: {
            click?: RawBlockEvent<BlockEvent>;
        };
    } & ShapeMixin
        : never;

export type ShapeUnitRawDescription<Name extends AnyShapeUnitName = AnyShapeUnitName> = RawDescription<ShapeUnitDescription<Name>>;

export type ShapeUnitAttributesOf<Name extends AnyShapeUnitName = AnyShapeUnitName> = PickShapeUnitAttributesByName<AnyShapeUnitDescription, Name>;

export type BlockContext<TB extends AnyBlock = AnyBlock,
    State extends StateManager = NirbyMemoryMask> = {
    block: TB;
    state: State;
    context: NirbyContext;
};

/**
 * Shapes registry
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const unitsRegistry: Record<AnyShapeUnitName, Type<ShapeUnit<any>>> = {
    Rect: RectShapeUnit,
    Text: TextShapeUnit,
    Draggable: DraggableShapeUnit,
    Container: ContainerShapeUnit,
    Image: ImageShapeUnit,
};

/**
 * Builds a design tree from a shape unit description
 * @param index The index of the shape unit in the design tree
 * @param context The block context
 * @param description The shape unit description
 * @param group The root node
 * @param root The root shape
 * @param events The subscription to subscribe the events
 * @param editable Whether the shape is editable
 *
 * @returns The shape unit
 */
export function generateDesignTree<T extends AnyShapeUnitName>(
    index: number,
    context: BlockContext,
    description: ShapeUnitRawDescription<T>,
    group: Konva.Group,
    root: Konva.Shape,
    events: Observable<BlockEvent>[],
    editable: boolean,
): ShapeUnit<T> {
    const Shape = unitsRegistry[description.shape];
    if (!Shape) {
        throw new AppError(`Unknown shape with name ${description.shape}`);
    }
    const childrenGroup = new Konva.Group({});

    const children = description.children.map((childDescription, index) =>
        generateDesignTree(
            index,
            context,
            childDescription,
            group,
            root,
            events,
            editable,
        ),
    );
    group.add(childrenGroup);
    const shape: ShapeUnit<T> = new Shape(
        index.toString(10),
        context,
        root,
        children,
        editable,
        description,
    );
    shape.onStart();
    events.push(shape.events$);
    return shape;
}

export * from './attributes.model';
export * from './draggable.unit';
export * from './konva-utils';
export * from './rect.unit';
export * from './shape-unit';
export * from './text.unit';
