import {z} from 'zod';
import {ZodSchemaBuild} from '@nirby/store/models';

export type AnalyticsRole = 'basic' | 'advanced';

export interface StripeProductMetadata {
    videoDeliveryQuota: string;
    videoStorage: string;
    videoUploadQuota: string;
    analyticsRole: AnalyticsRole;
    nirbyPlanGroup?: string;
    hasPrime: 'true' | string;
    hasPop: 'true' | string;
    hasRoutes: 'true' | string;
    hasContacts: 'true' | string;
    analyticsHistoryDays: string;
}

export const STRIPE_PRODUCT_METADATA_SCHEMA: ZodSchemaBuild<StripeProductMetadata> =
    z.object({
        videoDeliveryQuota: z.string(),
        videoStorage: z.string(),
        videoUploadQuota: z.string(),
        analyticsRole: z.union([z.literal('basic'), z.literal('advanced')]),
        nirbyPlanGroup: z.string().optional(),
        hasPrime: z.union([z.literal('true'), z.string()]),
        hasPop: z.string(),
        hasRoutes: z.string(),
        hasContacts: z.string(),
        analyticsHistoryDays: z.string(),
    });

type MetadataKey = `stripe_metadata_${keyof StripeProductMetadata}`;

export type StripeProduct = {
    [key in MetadataKey]: string | undefined;
} & {
    active: boolean;
    description: string | null;
    images: string[];
    metadata: Partial<StripeProductMetadata>;
    name: string;
    role: string | null;
};

interface StripeProductPriceRecurring {
    aggregate_usage: string | null;
    interval: 'day' | 'month' | 'week' | 'year';
    interval_count: number;
    trial_period_days: number | null;
    usage_type: 'licensed' | 'metered';
}

export interface StripeProductPrice {
    active: boolean;
    billing_scheme: 'per_unit' | 'tiered' | 'volume';
    currency: string;
    description: string | null;
    interval: 'day' | 'month' | 'week' | 'year';
    interval_count: number;
    metadata: object;
    product: string;
    recurring: StripeProductPriceRecurring | null;
    tax_behavior: 'exclusive' | 'inclusive' | 'unspecified';
    tiers_mode: 'graduated' | 'volume' | null;
    transform_quantity: object | null;
    trial_period_days: number | null;
    type: 'one_time' | 'recurring';
    unit_amount: number | null;
}

export interface StripeCustomer {
    id: string;
}
