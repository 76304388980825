import {NirbyDocument, NirbyTransaction, ReplaceDeep} from '@nirby/store/base';
import {DateTime} from 'luxon';
import {Migrator, MigratorLike} from '@nirby/store/migrator';
import {
    DocumentReference,
    DocumentSnapshot,
    FieldValue,
    QueryDocumentSnapshot,
    serverTimestamp,
} from '@angular/fire/firestore';
import {z} from 'zod';
import {FirestoreCollection} from './collection';
import {firstValueFrom} from 'rxjs';
import {FirestoreCollectionReference, FirestoreDocumentReference} from './reference';
import {ModelMetadata, Uploaded, ZodSchemaBuild} from '@nirby/store/models';

export type FirestoreModelReference<T extends object> = DocumentReference<
    Uploaded<T>
>;

/**
 * A zod schema that validates a DocumentReference
 * @param migratorFn A function that returns a migrator instance
 *
 * @returns {z.ZodSchema<FirestoreModelReference>} A zod schema that validates a DocumentReference
 */
export function buildFirestoreUploadedReferenceSchema<T extends object>(
    migratorFn: () => Migrator<T>,
): ZodSchemaBuild<FirestoreDocumentReference<T>> {
    return z.lazy(() =>
        z
            .custom<FirestoreModelReference<T> | FirestoreDocumentReference<T>>(
                (value: unknown): value is FirestoreModelReference<T> => {
                    return (
                        value instanceof DocumentReference ||
                        value instanceof FirestoreDocumentReference
                    );
                },
            )
            .transform<FirestoreDocumentReference<T>>((value) => {
                if (value instanceof FirestoreDocumentReference) {
                    return value;
                }
                return new FirestoreDocumentReference(value, migratorFn());
            }),
    );
}

/**
 * Representation of a document in the Firestore database with its data and metadata.
 */
export class FirestoreNirbyDocument<T extends object> extends NirbyDocument<T> {
    constructor(
        private readonly firestoreRef: FirestoreModelReference<T>,
        data: T,
        creationTime: DateTime,
        updateTime: DateTime,
        schemaVersion: number,
        migrator: MigratorLike<T>,
    ) {
        super(
            new FirestoreDocumentReference(firestoreRef, migrator),
            data,
            creationTime,
            updateTime,
            schemaVersion,
            migrator,
        );
    }

    get id(): string {
        return this.ref.id;
    }

    get parentId(): string {
        const parent = this.ref.parent.getParent(Migrator.any())?.id;
        if (!parent) {
            throw new Error('Document has no parent');
        }
        return parent;
    }

    /**
     * The collection object
     */
    get collectionRef(): FirestoreCollectionReference<T> {
        return new FirestoreCollectionReference<T>(
            this.firestoreRef.parent,
            this.migrator,
        );
    }

    get collection(): FirestoreCollection<T> {
        return new FirestoreCollection<T>(
            this.firestoreRef.firestore,
            this.firestoreRef.path,
            this.migrator,
        );
    }

    /**
     * Crates a new VSPDocument to store data of a document.
     * @param ref the reference to the document.
     * @param data The data to be stored.
     * @param migrator Object to migrate the data.
     *
     * @returns New VSPDocument
     */
    public static fromFirestoreRef<T extends object>(
        ref: DocumentReference<Uploaded<T>>,
        data: Uploaded<T>,
        migrator: MigratorLike<T>,
    ): FirestoreNirbyDocument<T> {
        const {_creationTime, _lastUpdate, _databaseVersion} = data;
        const cleaned: Partial<Uploaded<T>> = {...data};
        delete cleaned._creationTime;
        delete cleaned._lastUpdate;
        delete cleaned._databaseVersion;

        return new FirestoreNirbyDocument(
            ref,
            migrator.migrate(cleaned).migrated,
            _creationTime,
            _lastUpdate,
            _databaseVersion,
            migrator,
        );
    }

    public static fromFirestore<T extends object>(
        docSnap: QueryDocumentSnapshot<Uploaded<T>>,
        migrator: MigratorLike<T>
    ): FirestoreNirbyDocument<T>;
    public static fromFirestore<T extends object>(
        docSnap: DocumentSnapshot<Uploaded<T>>,
        migrator: MigratorLike<T>
    ): FirestoreNirbyDocument<T> | null;

    /**
     * Crates a new VSPDocument from a DocumentSnapshot.
     * @param docSnap The document snapshot.
     * @param migrator Object to migrate the data.
     *
     * @returns New VSPDocument
     */
    public static fromFirestore<T extends object>(
        docSnap: DocumentSnapshot<Uploaded<T>>,
        migrator: Migrator<T>,
    ): FirestoreNirbyDocument<T> | null {
        const ref = docSnap.ref;
        const data = docSnap.data();
        if (!data) {
            return null;
        }
        return this.fromFirestoreRef(ref, data, migrator);
    }

    /**
     * Creates an array of documents from a firestore snapshot.
     *
     * @param data The data to store in the document.
     * @param migrator Object to migrate the data.
     *
     * @returns Array of documents.
     */
    public static fromQuery<T extends object>(
        data: QueryDocumentSnapshot<Uploaded<T>>[],
        migrator: MigratorLike<T>,
    ): FirestoreNirbyDocument<T>[] {
        return Array.from(data.map((d) => this.fromFirestore(d, migrator)));
    }

    /**
     * Get new snapshot of the document and send data to observables
     * @param transaction Transaction
     */
    async refresh(transaction?: NirbyTransaction): Promise<void> {
        let doc: FirestoreNirbyDocument<T> | NirbyDocument<T> | null;
        if (transaction) {
            doc = await transaction.get(this.ref);
        } else {
            doc = await firstValueFrom(
                this.collection.get(this.firestoreRef.id),
            );
        }
        if (!doc) {
            return;
        }
        this.dataSubject.next(doc.data);
    }

    /**
     * Creates a new document ready to be uploaded to the server.
     * @param data The data to store in the document.
     * @param migrator Object to migrate the data.
     *
     * @returns New VSPDocument
     */
    public static newUpload<T extends object>(
        data: ReplaceDeep<T, DateTime, DateTime | FieldValue>,
        migrator: Migrator<T>,
    ): T & ReplaceDeep<ModelMetadata, DateTime, DateTime | FieldValue> {
        const meta: ReplaceDeep<
            ModelMetadata,
            DateTime,
            DateTime | FieldValue
        > = {
            _creationTime: serverTimestamp(),
            _lastUpdate: serverTimestamp(),
            _databaseVersion: migrator.currentVersion,
        };
        const newData = migrator.migrate(data).migrated;
        return {
            ...newData,
            ...meta,
        };
    }
}
