import {Injectable} from '@angular/core';
import {firstValueFrom, merge, Observable, of, timer} from 'rxjs';
import {Router} from '@angular/router';
import {filter, first, map, switchMap} from 'rxjs/operators';
import {UserService} from '../collections';

import {NirbyDocument, NirbyDocumentReference} from '@nirby/store/base';
import {AppError, NotFoundError} from '@nirby/js-utils/errors';
import {Database, shareReplaySafe} from '@nirby/ngutils';
import {UserData, WorkspaceRef} from '@nirby/models/editor';
import {LoadScreenService} from '@nirby/shared/load-screen';
import {AlertsService} from '@nirby/shared/alerts';
import {COLLECTION_KEYS} from '@nirby/store/collections';
import * as Sentry from '@sentry/angular';
import {
    Auth,
    authState,
    createUserWithEmailAndPassword,
    getRedirectResult,
    sendEmailVerification,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    User,
    UserCredential,
} from '@angular/fire/auth';
import {GoogleAuthProvider} from '@firebase/auth';
import {Modeled} from '@nirby/store/models';

@Injectable({
    providedIn: 'root',
})
/**
 * Authentication service
 */
export class AuthenticationService {
    /**
     * Constructor.
     * @param auth Angular fire Auth
     * @param db Angular fire Firestore
     * @param router Angular router
     * @param toasts Toast service
     * @param userService User service
     * @param loader Loader service
     */
    constructor(
        public auth: Auth,
        private db: Database,
        private router: Router,
        protected toasts: AlertsService,
        private userService: UserService,
        private loader: LoadScreenService,
    ) {
    }

    readonly user$: Observable<User | null> = authState(this.auth);

    readonly userSafe$: Observable<User> = this.user$.pipe(
        filter((user): user is User => !!user),
    );
    readonly authenticatedUser$: Observable<NirbyDocument<UserData> | null> =
        this.user$.pipe(
            switchMap((userAuth) => {
                if (!userAuth) {
                    return of(null);
                }
                return this.userService.collection
                    .get(userAuth.uid)
                    .pipe(shareReplaySafe());
            }),
        );
    readonly authenticatedUserModeled$: Observable<Modeled<UserData> | null> =
        this.authenticatedUser$.pipe(map((user) => user?.toModeled() ?? null));
    readonly authenticatedUserSafe$ = this.authenticatedUserModeled$.pipe(
        filter((user): user is Modeled<UserData> => !!user),
    );
    readonly isDeveloper$: Observable<boolean> =
        this.authenticatedUserSafe$.pipe(
            map((user) => user.isDeveloper ?? false),
        );
    readonly userId$: Observable<string> = this.authenticatedUserSafe$.pipe(
        map((user) => user.id),
    );

    async signInFromRedirect() {
        const user = await getRedirectResult(this.auth);
        if (user) {
            return user;
        }
        throw new AppError('No user authenticated');
    }

    /**
     * Signs in using a Google account.
     * @param redirect Redirect URL
     *
     * @returns True if the sign in was successful
     */
    async signInGoogle(redirect = true): Promise<boolean> {
        try {
            const result = await signInWithPopup(
                this.auth,
                new GoogleAuthProvider(),
            );
            const credential = GoogleAuthProvider.credentialFromResult(result);
            if (!credential) {
                this.toasts.error('Sign in failed');
                return false;
            }
            return this.finishLogin(result, redirect);
        } catch (e) {
            this.toasts.error('Sign in failed');
        }
        return false;
    }

    /**
     * Checks if an error is coded with a code key
     * @param e Error
     *
     * @returns True if the error is coded
     */
    errorHasCode(e: unknown): e is { code: string } {
        return typeof (e as { code: unknown }).code === 'string';
    }

    /**
     * Signs up using an e-mail and password.
     * @param email E-mail
     * @param password Password
     * @param redirect Redirect URL
     *
     * @returns True if the sign up was successful
     */
    async signUpEmail(
        email: string,
        password: string,
        redirect = true,
    ): Promise<boolean> {
        let result;
        try {
            result = await createUserWithEmailAndPassword(
                this.auth,
                email,
                password,
            );
        } catch (e) {
            if (this.errorHasCode(e)) {
                switch (e.code) {
                    case 'auth/email-already-in-use':
                        this.toasts.error('Email is already in use');
                        break;
                    default:
                        this.toasts.error(
                            'Registration failed. Try again later',
                        );
                        break;
                }
            }
            return false;
        }
        if (!result.user) {
            this.toasts.error('Registration failed. Try again later');
            return false;
        }
        await sendEmailVerification(result.user);
        return await this.finishLogin(result, redirect);
    }

    /**
     * Signs in using an e-mail and password.
     * @param email E-mail
     * @param password Password
     * @param redirect Redirect URL
     *
     * @returns True if the sign in was successful
     */
    async signInEmail(
        email: string,
        password: string,
        redirect = true,
    ): Promise<boolean> {
        let result;
        try {
            result = await signInWithEmailAndPassword(
                this.auth,
                email,
                password,
            );
        } catch (e) {
            if (this.errorHasCode(e)) {
                switch (e.code) {
                    case 'auth/wrong-password':
                        this.toasts.error('Credenciales incorrectas');
                        break;
                    case 'auth/user-not-found':
                        this.toasts.error('Credenciales incorrectas');
                        break;
                    default:
                        this.toasts.error('Sign in failed');
                        break;
                }
            }
            return false;
        }
        return await this.finishLogin(result, redirect);
    }

    /**
     * Using user credentials, signs in.
     * @param credentials User credentials
     * @param redirect Redirect URL
     * @private
     *
     * @returns True if the sign in was successful
     */
    protected async finishLogin(
        credentials: UserCredential,
        redirect = true,
    ): Promise<boolean> {
        if (!credentials.user) {
            this.toasts.error('Sign in failed');
            return false;
        }

        let user = await this.getStoredUser(credentials.user.uid).then(
            (doc) => doc?.data,
        );
        if (!user) {
            user = await this.updateStoredUser(credentials.user);
        }
        if (redirect) {
            await this.goToUserHome(user, true);
        }
        return true;
    }

    /**
     * Sends a password reset e-mail.
     * @param email E-mail
     *
     * @returns A promise that resolves when the e-mail is sent
     */
    async resetPassword(email: string): Promise<void> {
        await sendPasswordResetEmail(this.auth, email);
    }

    /**
     * Navigates to the current user's home route.
     * @param user User
     * @param skipMenu True if the workspace selector should be skipped
     *
     * @returns A promise that resolves when the navigation is complete
     */
    async goToUserHome(user: UserData, skipMenu = false): Promise<void> {
        await this.router.navigate(
            await this.getUserHomeCommands(user, skipMenu),
        );
    }

    /**
     * Gets the current user reference.
     *
     * @returns A promise that resolves with the current user reference
     */
    async getAuthenticatedUserReference(): Promise<
        NirbyDocumentReference<UserData>
    > {
        const user = await this.getCurrentUser();
        if (!user) {
            throw new AppError('No user authenticated');
        }
        return this.userService.collection.ref(user.uid);
    }

    /**
     * Gets the current authenticated user.
     *
     * @returns A promise that resolves with the current authenticated user or null if no user is authenticated
     */
    async getAuthenticatedUser(): Promise<NirbyDocument<UserData> | null> {
        const authState = await this.getCurrentUser();
        return authState
            ? firstValueFrom(this.userService.collection.get(authState.uid))
                .then(async (userDoc) => {
                    if (!userDoc) {
                        return this.signOut().then(() => null);
                    }
                    const user = userDoc.data;
                    if (!user.plan) {
                        user.plan = 'FREE';
                        await this.userService.collection.update(user.id, {
                            plan: user.plan,
                        });
                    }
                    return userDoc;
                })
                .catch((err) => {
                    Sentry.captureException(err);
                    return this.signOut().then(() => null);
                })
            : null;
    }

    /**
     * Gets an user data from the database.
     * @param id User ID
     *
     * @returns A promise that resolves with the current user data
     */
    async getStoredUser(id: string): Promise<NirbyDocument<UserData> | null> {
        return await firstValueFrom(this.userService.collection.get(id));
    }

    /**
     * Gets the current user data from the database.
     *
     * @returns A promise that resolves with the current user data
     */
    async getStoredAuthenticatedUser(): Promise<UserData | null> {
        const authUser = await this.getCurrentUser();
        if (!authUser) return null;
        return await firstValueFrom(
            this.userService.collection.get(authUser.uid),
        )
            .then((u) => u?.toModeled() ?? null)
            .catch((err) => {
                if (err instanceof NotFoundError) {
                    Sentry.captureException(err);
                    return null;
                }
                throw err;
            });
    }

    /**
     * Creates a user with the given firebase user.
     * @param user Firebase user
     *
     * @returns A promise that resolves with the created user
     */
    async updateStoredUser(user: User): Promise<UserData> {
        const userData: UserData = {
            id: user.uid,
            email: user.email,
            profilePicture: null,
            ownedPlugins: [],
        };
        await this.userService.collection.add(userData, user.uid);
        return userData;
    }

    /**
     * Signs out the current user.
     * @param redirect Redirect URL
     *
     * @returns A promise that resolves when the sign-out is complete
     */
    async signOut(redirect = true): Promise<void> {
        await this.loader.untilCompletion(this.auth.signOut(), 'block');
        Sentry.addBreadcrumb({
            message: 'SIGNING OUT',
            data: {
                redirect,
            },
        });
        if (redirect) {
            await this.router.navigate(['/auth/login']);
        }
    }

    /**
     * Gets the current user.
     */
    async getCurrentUser(): Promise<User | null> {
        return await firstValueFrom(this.user$.pipe(first()));
    }

    /**
     * Checks if the current user owns a widget.
     * @param widgetId Widget ID
     *
     * @returns A promise that resolves with true if the user owns the widget
     */
    ownsWidget(widgetId: string): Observable<boolean> {
        return this.authenticatedUserSafe$.pipe(
            map((user) => {
                const ownedWidgets: WorkspaceRef[] = user.widgets ?? [];
                return !!ownedWidgets.find((w) => w.ref.id === widgetId);
            }),
        );
    }

    /**
     * Gets the current user's ID.
     *
     * @returns A promise that resolves with the current user's ID
     */
    getUserId(): Promise<string> {
        return this.getAuthenticatedUserReference().then((ref) => ref.id);
    }

    /**
     * Gets the current user ID token.
     *
     * @returns A promise that resolves with the current user ID token
     */
    async getAccessToken(): Promise<string | null> {
        const user = await this.getCurrentUser();
        return (await user?.getIdToken()) ?? null;
    }

    /**
     * Get the user home route commands.
     * @param user User
     * @param skipMenu Whether to skip the workspace selector
     * @private
     *
     * @returns The user home route commands
     */
    public async getUserHomeCommands(
        user: UserData | null,
        skipMenu = false,
    ): Promise<string[]> {
        if (user && skipMenu) {
            // get the first workspace owned by the user
            const workspaceId$: Observable<string | null> = this.db
                .queryGroup(COLLECTION_KEYS.WORKSPACE_MEMBERSHIPS)
                .where('userId', '==', user.id)
                .getFirst()
                .pipe(
                    map((membership) => membership?.data.workspaceId ?? null),
                    first(),
                );

            const workspaceId = await firstValueFrom(
                merge(
                    // get the first workspaceId from the query
                    workspaceId$,
                    // if it takes too long, return null
                    timer(10 * 1000).pipe(map(() => null)),
                ),
            );
            if (workspaceId) {
                return ['/workspaces', workspaceId, 'settings', 'welcome'];
            }
        }
        return ['/workspaces'];
    }
}
