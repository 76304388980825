import {Component} from '@angular/core';
import {BlockActionComponent} from '../../blocks/block-action.component';
import {FormArray, UntypedFormControl, FormGroup, Validators, UntypedFormArray} from '@angular/forms';
import {faPlus, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {TrackEventAction} from '@nirby/models/nirby-player';

@Component({
    selector: 'nirby-action-track-event',
    templateUrl: './action-track-event.component.html',
    styleUrls: ['./action-track-event.component.scss'],
})
export class ActionTrackEventComponent extends BlockActionComponent<TrackEventAction> {
    eventControl = new UntypedFormControl();
    tagsControl = new UntypedFormArray([]);

    form = new FormGroup({
        event: this.eventControl,
        tags: this.tagsControl,
    });

    iconAdd = faPlus;
    iconDelete = faTrashAlt;

    setData(content: TrackEventAction | null): void {
        if (content) {
            this.eventControl.setValue(content.options.event);
            this.tagsControl.clear();
            let tag: string;
            for (tag of content.options.tags) {
                this.addTag(tag);
            }
        }
    }

    get tags(): UntypedFormControl[] {
        return this.tagsControl.controls.map((c) => c as UntypedFormControl);
    }

    addTag(tag: string): void {
        this.tagsControl.push(new UntypedFormControl(tag, [Validators.required]));
    }

    removeTag(tagIdx: number): void {
        this.tagsControl.removeAt(tagIdx);
    }
}
