<editable
    #editable
    [ngStyle]="{ cursor: disabled ? 'default' : 'pointer' }"
    (click)="$event.stopPropagation()"
    (save)="onChange()"
    closeBindingEvent="click"
    (cancel)="onCancel()"
    (modeChange)="onModeChange(editable, $event)"
>
    <ng-template viewMode>
        <span [ngClass]="labelStyleClasses">{{ displayValue }}</span>
        <fa-icon
            *ngIf="!disabled"
            [ngClass]="labelStyleClasses"
            class="icon ms-2"
            [icon]="editIcon"
        ></fa-icon>
    </ng-template>
    <ng-template editMode>
        <input
            editableFocusable
            editableOnEnter
            editableOnEscape
            [(ngModel)]="tempValue"
        />
    </ng-template>
</editable>
