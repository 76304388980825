import {Inject, Injectable} from '@angular/core';
import {LandpopsService} from './landpops.service';
import {Observable} from 'rxjs';
import {delay, map} from 'rxjs/operators';

import {NirbyDocument, NirbyTransaction} from '@nirby/store/base';
import {LandPop, LandPopContent, LandPopPublished} from '@nirby/models/pop';
import {WorkspaceService} from './workspace.service';
import {PopService} from './pop.service';
import {AlertsService} from '@nirby/shared/alerts';
import {CREATORS_ENVIRONMENT, NirbyEnvironment} from '@nirby/environment/base';
import {joinId} from '@nirby/runtimes/canvas';
import {Database} from '@nirby/ngutils';
import {COLLECTION_KEYS} from '@nirby/store/collections';

@Injectable({
    providedIn: 'root',
})
export class LandpopsPublishedService {
    private shouldAlert: boolean;

    constructor(
        @Inject(CREATORS_ENVIRONMENT)
        private readonly environment: NirbyEnvironment,
        private readonly firestore: Database,
        private readonly alerts: AlertsService,
        private readonly widgets: WorkspaceService,
        private readonly landpop: LandpopsService,
        private readonly popService: PopService,
    ) {
        this.shouldAlert = !environment.production;
    }

    public readonly collection = this.firestore.collection(
        COLLECTION_KEYS.PUBLISHED_LANDPOPS,
    );
    private readonly landpops = this.firestore.collection(
        COLLECTION_KEYS.LANDPOPS,
    );

    async unpublish(widgetId: string, popId: string): Promise<void> {
        await this.landpop.unpublish(widgetId, popId);

        // wait to avoid missing permissions error
        await delay(2000);
    }

    getPublished(
        widgetId: string,
        popId: string,
    ): Observable<NirbyDocument<LandPopPublished> | null> {
        return this.landpop.watchPublished(widgetId, popId);
    }

    isPublished(widgetId: string, popId: string): Observable<boolean> {
        return this.landpop
            .watchPublished(widgetId, popId)
            .pipe(map((landpops) => !!landpops));
    }

    async createDefaultTransaction(
        transaction: NirbyTransaction,
        widgetId: string,
        popId: string,
    ): Promise<LandPop> {
        const newContent: LandPopContent = {
            background: {
                type: 'image',
                src: null,
            },
            backgroundColor: 'rgba(0, 0, 0, 1)',
            veilColor: 'rgba(0, 0, 0, 0.3)',
            description: 'My new LandPop description!',
            logo: {
                src: null,
                size: 'md',
                fit: 'contain',
                position: 'center center',
            },
            title: 'My cool LandPop!',
        };

        const publishedId = '_' + this.firestore.generateId();

        const newLandpop: LandPop = {
            title: 'My cool LandPop',
            content: newContent,
            directory: null,
            emoji: null,
            path: publishedId,
            status: 'active',
            pop: popId ? this.popService.collection(widgetId).ref(popId) : null,
            widget: this.widgets.getReference(widgetId),
        };

        const landPopRef = await this.landpop.collection.setTransaction(
            transaction,
            newLandpop,
            joinId(widgetId, popId),
        );
        await this.collection.setTransaction(
            transaction,
            {
                ref: landPopRef,
                pop: null,
                json: null,
            },
            publishedId,
        );
        return newLandpop;
    }

    async createDefault(widgetId: string, popId: string): Promise<LandPop> {
        return await this.firestore.runTransaction<LandPop>(
            async (transaction) =>
                this.createDefaultTransaction(transaction, widgetId, popId),
        );
    }

    preview(widgetId: string, popId: string): void {
        window.open(
            `/workspaces/${widgetId}/campaigns/c/${popId}/landpop/preview`,
            '_blank',
        );
    }

    getPublicUrl(publishedPath: string): string {
        return this.environment.landpopsOrigin + '/' + publishedPath;
    }

    async share(publishedPath: string): Promise<void> {
        if (this.shouldAlert) {
            const answer = await this.alerts.askConfirmation(
                'Hey, developer!',
                'Make sure you\'re running the nby-landpop project first. If it\'s already running, you can dismiss this message.' +
                '\nThis message is only shown in development mode',
            );
            this.shouldAlert = false;
            if (!answer) {
                return;
            }
        }
        window.open(this.getPublicUrl(publishedPath), '_blank');
    }

    async sharePublicLink(widgetId: string, popId: string): Promise<void> {
        const published = await this.landpop.getPublished(widgetId, popId);
        if (published) {
            return this.share(published.id);
        }
    }
}
