import {Component, Input} from '@angular/core';

type InterfaceType = 'success' | 'info' | 'warning' | 'danger';

@Component({
    selector: 'nirby-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
/**
 * Component for displaying and alert
 */
export class AlertComponent {
    @Input() emoji: string | null = null;
    @Input() title: string | null = null;
    @Input() type: InterfaceType = 'info';
}
