<div class="videos-widget-container" *ngIf="thumbSource">
    <img alt="Thumbnail" [src]="thumbSource" />

    <div class="tools d-flex justify-content-end flex-column p-3">
        <a
            [href]="videoURL"
            target="_blank"
            class="tool d-flex justify-content-between align-items-center"
        >
            <span class="message text-right flex-1">Watch video</span>
            <div
                class="circle-play d-flex justify-content-center align-items-center ms-2"
            >
                <fa-icon class="icon" [icon]="iconPlay"></fa-icon>
            </div>
        </a>
    </div>
</div>
