import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {SafeUrlPipe} from './pipes';
import {
    CardViewComponent,
    ContactFormComponent,
    ContextModalsComponent,
} from './components';
import {
    AnalyticsService,
    ContactsService,
    FontSizerService,
} from './services';
import {
    defaultConfig,
    NirbyPlayerConfig,
} from '@nirby/models/nirby-player';
import {ArtBoardViewComponent} from './components';
import {HttpClientModule} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
import {SharedSanitizersModule} from '@nirby/shared/sanitizers';
import {NIRBY_PLAYER_CONFIG} from '@nirby/shared/player-config';
import { ApiService } from '@nirby/shared/api';
import {MEDIA_CONFIG, MEDIA_TOKEN_PROVIDERS} from '@nirby/media/video-factory';

@NgModule({
    declarations: [
        CardViewComponent,
        SafeUrlPipe,
        ArtBoardViewComponent,
        ContextModalsComponent,
        ContactFormComponent,
    ],
    providers: [FontSizerService],
    imports: [CommonModule, ReactiveFormsModule, HttpClientModule, TranslateModule, SharedSanitizersModule],
    exports: [
        CardViewComponent,
        ArtBoardViewComponent,
        SafeUrlPipe,
        ContextModalsComponent,
    ],
})
/**
 * Module for the Nirby Player
 */
export class NirbyPlayerModule {
    // eslint-disable-next-line valid-jsdoc
    /**
     * @param config Nirby Player configuration
     * @param production Whether the app is in production mode
     * @returns Module with providers
     */
    public static forRoot(
        config: Partial<NirbyPlayerConfig>,
        production: boolean,
    ): ModuleWithProviders<NirbyPlayerModule> {
        return {
            ngModule: NirbyPlayerModule,
            providers: [
                {
                    provide: NIRBY_PLAYER_CONFIG,
                    useValue: {
                        ...config,
                        ...defaultConfig,
                    },
                },
                {
                    provide: ApiService,
                    useClass: ApiService,
                },
                {
                    provide: ContactsService,
                    useClass: ContactsService,
                },
                {
                    provide: AnalyticsService,
                    useClass: AnalyticsService,
                },
                {
                    provide: FontSizerService,
                    useClass: FontSizerService,
                },
                {
                    provide: MEDIA_CONFIG,
                    useValue: production ? MEDIA_TOKEN_PROVIDERS.prod : MEDIA_TOKEN_PROVIDERS.dev,
                }
            ],
        };
    }
}
