// noinspection JSUnusedGlobalSymbols

import {MultiLevelBoardStore, SingleLevelBoardStore} from './board-store';
import {
    BehaviorSubject,
    combineLatest,
    distinctUntilChanged,
    lastValueFrom,
    Observable,
    of,
    shareReplay,
    startWith,
    Subscription,
    switchMap,
} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';
import Konva from 'konva';

import {AsyncChannel} from '@nirby/js-utils/async';
import {ArtBoard} from '../art-board';
import {Block, BlockFactory} from '../blocks';
import {
    ActionTrigger,
    AnyBlock,
    AnyBlockType,
    AnyCardAction,
    AnyCardActionType,
    BlockActions,
    NirbyPlayerConfig,
} from '@nirby/models/nirby-player';
import {ArtBoardEditorCanvas} from './board-canvas';
import {ArtBoardItem} from '../art-board-item';
import {
    ArtBoardItemFactory,
    ArtBoardItemState,
    NirbyBoardItemStandard,
    NirbyBoardStateNode,
} from '../art-board-item-factory';
import {NirbyContext} from '@nirby/runtimes/context';
import {v4} from 'uuid';
import {ClipboardCustom, CustomEncoder, Encoder, EncoderTranslator} from '@nirby/encoding';
import {deepCopyBlock, generateId} from '../utils';
import {TranslatorLike} from '@nirby/runtimes/i18n';
import {Logger} from '@nirby/logger';
import {ReactiveFilteredKeyValueStorage, ReactiveKeyValueStorage, ReactiveStorageFilter} from './storage';
import Vector2d = Konva.Vector2d;

export interface PositioningResult<TMeta> {
    position: Vector2d;
    size: Vector2d;
    item: ArtBoardItem<TMeta> | null;
}

/**
 * Controller in charge of the currently selected block(s)
 */
class SelectionController<TMeta = never> {
    private selectedItemIdController = new BehaviorSubject<string | null>(null);

    public readonly selectedItem$: Observable<NirbyBoardStateNode<TMeta> | null>;

    /**
     * Watches the selected item ID.
     */
    get selectedItemId$(): Observable<string | null> {
        return this.selectedItemIdController.asObservable();
    }

    /**
     * The currently selected block(s)
     */
    public get selectedItem(): NirbyBoardStateNode<TMeta> | null {
        const blockId = this.selectedBlockId;
        if (!blockId) {
            return null;
        }
        return this.editor.findStateById(blockId) ?? null;
    }

    /**
     * Get the selected item if it is of the given type.
     * @param type The type to check for.
     *
     * @returns - The selected item.
     */
    public getSelectedItemOfType<T extends ArtBoard<TMeta> | Block<TMeta>>(
        type: T['type'],
    ): NirbyBoardStateNode<TMeta, T> | null {
        const state = this.selectedItem;
        if (!state) {
            return null;
        }
        if (ArtBoardItemFactory.instance.isStateOfType<TMeta, T>(state, type)) {
            return state as NirbyBoardStateNode<TMeta, T>;
        }
        return null;
    }

    /**
     * The currently selected block(s) id
     */
    get selectedBlockId(): string | null {
        return this.selectedItemIdController.value;
    }

    /**
     * Constructor.
     * @param editor The editor master.
     */
    constructor(private editor: ArtBoardEditorMaster<TMeta>) {
        this.selectedItem$ = combineLatest([
            this.editor.currentLevelState$,
            this.selectedItemIdController.asObservable(),
        ]).pipe(
            map(([state, selectedBlockId]) => {
                if (!state || !selectedBlockId) {
                    return null;
                }
                return (
                    state.children.find((b) => b.id === selectedBlockId) ?? null
                );
            }),
            distinctUntilChanged((a, b) => b?.id === a?.id),
            tap((s) => {
                Logger.logAt(
                    'BLOCK-EDITOR:SELECTION',
                    s
                        ? `Selected block: ${s.id} (${s.type})`
                        : 'No block selected',
                );
            }),
            shareReplay({refCount: true, bufferSize: 1}),
        );
    }

    /**
     * The selected block data.
     */
    get blockData(): NirbyBoardStateNode<TMeta> | null {
        const selectedBlockId = this.selectedItemIdController.value;
        if (!selectedBlockId) {
            return null;
        }
        return this.editor.findStateById(selectedBlockId);
    }

    /**
     * Watch for the selected block or art-board data.
     * @param type The type to check for.
     *
     * @returns - The selected item.
     */
    watchSelectedOfType<T extends ArtBoard<TMeta> | Block<TMeta>>(
        type: T['type'],
    ): Observable<NirbyBoardStateNode<TMeta, T> | null> {
        return this.selectedItem$.pipe(
            map((state) => {
                if (!state) {
                    return null;
                }
                if (
                    ArtBoardItemFactory.instance.isStateOfType<TMeta, T>(state, type)
                ) {
                    return state as NirbyBoardStateNode<TMeta, T>;
                }
                return null;
            }),
            distinctUntilChanged(),
        );
    }

    /**
     * Selects the item with the given id.
     * @param itemId The item id to select.
     *
     * @returns - The selected item.
     */
    select(itemId: string | null): NirbyBoardStateNode<TMeta> | null {
        if (itemId) {
            // only select if draggable
            const item = this.editor.store.get(itemId);
            if (!item) {
                return null;
            }
            this.selectedItemIdController.next(itemId);
            return item?.value ?? null;
        } else {
            this.selectedItemIdController.next(null);
        }
        return null;
    }

    /**
     * Unselects the currently selected item.
     */
    unselect(): void {
        this.select(null);
    }

    /**
     * Checks if the given item ID is selected.
     * @param blockId The block id to check.
     *
     * @returns - True if the block is selected.
     */
    isSelected(blockId: string): boolean {
        return this.selectedBlockId === blockId;
    }
}

/**
 * Action set form
 */
export class ActionSetForm<TMeta> {
    /**
     * Constructor.
     * @param editor The editor master.
     */
    constructor(private editor: ArtBoardEditorMaster<TMeta>) {
    }

    /**
     * The selected block actions.
     */
    public get actions(): BlockActions | null {
        const item =
            this.editor.selection.getSelectedItemOfType<Block<TMeta>>('block');
        if (!item) {
            return null;
        }
        return item.properties.actions ?? null;
    }

    /**
     * Actions that stop a card
     */
    public static readonly ENDING_ACTIONS: AnyCardActionType[] = [
        'card-link',
        'close',
    ];

    /**
     * Selected card actions
     */
    public readonly actions$: Observable<BlockActions> = this.editor.selection
        .watchSelectedOfType<Block<TMeta>>('block')
        .pipe(
            map((a) => a?.properties.actions ?? null),
            filter((a): a is BlockActions => !!a),
        );

    /**
     * Receives 2 actions arrays, an old one, and a new one. For each action, the new one will only be used
     * if the new action type is different from before or if the index does not exist. Otherwise, the data
     * will be copied into the old object.
     *
     * This avoids reloading the view (and losing focus) because of a reference change on the action.
     *
     * The resulting array will have the same length as the new actions array.
     *
     * @param oldActions Old actions array
     * @param newActions New actions array
     *
     * @returns The resulting actions array
     */
    public static mergeAndKeepOldActions(
        oldActions: AnyCardAction[],
        newActions: AnyCardAction[],
    ): AnyCardAction[] {
        const merged = newActions.map((newAction, index) => {
            if (index >= oldActions.length) {
                return newAction;
            }
            const oldAction = oldActions[index];
            if (oldAction.type === newAction.type) {
                const oldOptions = oldAction.options;
                const options = newAction.options;
                if (!(typeof options === 'object') || options === null) {
                    return oldAction;
                }
                if (!(typeof oldOptions === 'object') || oldOptions === null) {
                    return oldAction;
                }

                let propertyKey: string;
                for (propertyKey in options) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (!options.hasOwnProperty(propertyKey)) {
                        continue;
                    }
                    const o1 = oldOptions as Record<string, unknown>;
                    const o2 = options as Record<string, unknown>;
                    o1[propertyKey] = o2[propertyKey];
                }
                return oldAction;
            }
            return newAction;
        });
        oldActions.splice(0, oldActions.length);
        oldActions.push(...merged);
        return oldActions;
    }

    /**
     * Check if an action trigger includes an ending action
     * @param trigger Trigger (e.g. click)
     *
     * @returns - True if the trigger includes an ending action
     */
    public watchHasEndingAction$(trigger: ActionTrigger): Observable<boolean> {
        return this.actions$.pipe(
            map((a) =>
                a[trigger].reduce<boolean>(
                    (prev, current) =>
                        prev ||
                        ActionSetForm.ENDING_ACTIONS.includes(current.type),
                    false,
                ),
            ),
        );
    }

    /**
     * Watch the action on a given index
     * @param trigger Trigger
     * @param index Index
     *
     * @returns - Observable of the action
     */
    public watchAt(
        trigger: ActionTrigger,
        index: number,
    ): Observable<AnyCardAction> {
        return this.actions$.pipe(
            map((a) => a[trigger]),
            filter((a) => index < a.length),
            map((a) => a[index]),
        );
    }

    /**
     * The selected block.
     */
    public get selectedBlock(): AnyBlock | null {
        const block =
            this.editor.selection.getSelectedItemOfType<Block<TMeta>>(
                'block',
            )?.properties;
        if (!block) {
            Logger.warnStyled(
                'ACTION-INPUT',
                'Tried to update selected block actions. But no block is selected',
            );
            return null;
        }
        return deepCopyBlock(block);
    }

    /**
     * Update the selected block action at the given index
     * @param trigger Action trigger
     * @param index Index
     * @param action Action
     */
    public updateAt(
        trigger: ActionTrigger,
        index: number,
        action: AnyCardAction,
    ): void {
        const actions = this.actions;
        if (!actions) {
            Logger.warnStyled(
                'ACTION-INPUT',
                'Tried to update selected block actions. But no actions ar found',
            );
            return;
        }
        const actionArray = actions[trigger];
        actionArray[index] = action;
        this.update(actions);
    }

    /**
     * Set the selected block actions
     * @param actions Actions
     */
    public update(actions: BlockActions): void {
        const block = this.selectedBlock;
        if (!block) {
            return;
        }
        block.actions = actions;
        this.editor.update(block);
    }

    /**
     * Add action at the end of a trigger array.
     * @param trigger Trigger
     * @param action Action
     */
    public addTo(trigger: ActionTrigger, action: AnyCardAction): void {
        const block = this.selectedBlock;
        if (!block) {
            Logger.warnStyled(
                'ACTION-INPUT',
                'Tried to update selected block actions. But no block is selected',
            );
            return;
        }
        block.actions[trigger].push(action);
        this.editor.update(block);
    }

    /**
     * Delete action at index
     * @param trigger Trigger
     * @param index Action index
     */
    public deleteAt(trigger: ActionTrigger, index: number): void {
        const block = this.selectedBlock;
        if (!block) {
            Logger.warnStyled(
                'ACTION-INPUT',
                'Tried to update selected block actions. But no block is selected',
            );
            return;
        }
        block.actions[trigger].splice(index, 1);
        this.editor.update(block);
    }
}

interface NirbyEditorSettings {
    fitToContainerOnUpdate: boolean;
}

/**
 * The master controller to implement an editor
 */
export class ArtBoardEditorMaster<TMeta = never> {
    public static readonly DEFAULT_SETTINGS: Readonly<NirbyEditorSettings> = {
        fitToContainerOnUpdate: false,
    };

    public readonly context: NirbyContext;
    public readonly canvas: ArtBoardEditorCanvas<TMeta>;
    public readonly settings: NirbyEditorSettings;

    public readonly currentLevel$: Observable<SingleLevelBoardStore<TMeta> | null>;
    public readonly currentLevelState$: Observable<NirbyBoardStateNode<TMeta, NirbyBoardItemStandard<TMeta>> | null>;
    public readonly stage: Konva.Stage;

    private readonly currentLevelIdSubject = new BehaviorSubject<string | null>(
        null,
    );
    private readonly containerObserver: ResizeObserver;

    /**
     * Get the current level ID
     * @private
     */
    private get currentLevelId(): string | null {
        return this.currentLevelIdSubject.value;
    }

    /**
     * Get the current level board store
     * @private
     */
    public get currentLevel(): SingleLevelBoardStore<TMeta> {
        const currentLevelId = this.currentLevelId;
        if (currentLevelId) {
            return this.store.get(currentLevelId) ?? this.store.root;
        }
        return this.store.root;
    }

    /**
     * Constructor.
     * @param rootBoardState Root board state
     * @param playerConfig Player Config
     * @param container Container
     * @param translator Context
     * @param settings Settings
     * @param metaCustomEncoder Encoder for the metadata of the nodes
     * @param metaPastePreprocessor Function to process the metadata before pasting
     */
    constructor(
        private readonly rootBoardState: NirbyBoardStateNode<TMeta, ArtBoard<TMeta>>,
        public readonly playerConfig: NirbyPlayerConfig,
        container: HTMLDivElement,
        translator: TranslatorLike,
        settings?: Partial<NirbyEditorSettings>,
        metaCustomEncoder?: EncoderTranslator<TMeta>,
        metaPastePreprocessor?: (meta: TMeta) => void | Promise<void>,
    ) {
        const metaEncoder: Encoder<TMeta | null> = metaCustomEncoder ?? new EncoderTranslator(
            'null',
            new CustomEncoder<TMeta | null, null>(
                () => null,
                () => null,
            ),
        );
        const encoder = new CustomEncoder<NirbyBoardStateNode<TMeta>, NirbyBoardStateNode<string>>(
            (node: NirbyBoardStateNode<TMeta>): NirbyBoardStateNode<string> => ({
                ...node,
                children: node.children.map<NirbyBoardStateNode<string>>((child) => encoder.reduce(child)),
                meta: node.meta ? metaEncoder.encode(node.meta) : null,
            }),
            (node: NirbyBoardStateNode<string>): NirbyBoardStateNode<TMeta> => ({
                ...node,
                children: node.children.map<NirbyBoardStateNode<TMeta>>((child) => encoder.expand(child)),
                meta: node.meta ? metaEncoder.decode(node.meta) : null,
            }),
        );
        this.clipboard = new ClipboardCustom(
            encoder,
            async (node: NirbyBoardStateNode<TMeta>): Promise<void> => {
                const newNodeId = this.store.addAt(this.rootBoardState.id, node);
                if (newNodeId !== null) {
                    await this.canvas.waitForId(newNodeId);
                    this.selection.select(newNodeId);
                }
            },
            node => {
                node.id = generateId();
                node.properties.position = [
                    {
                        x: node.properties.position[0].x + 10,
                        y: node.properties.position[0].y + 10,
                    },
                    {
                        x: node.properties.position[1].x + 10,
                        y: node.properties.position[1].y + 10,
                    },
                ];
                if (metaPastePreprocessor && node.meta) {
                    return metaPastePreprocessor(node.meta);
                }
            },
        );
        this.stage = new Konva.Stage({
            container,
            width: container.clientWidth,
            height: container.clientHeight,
        });
        this.settings = {
            ...ArtBoardEditorMaster.DEFAULT_SETTINGS,
            ...settings,
        };
        this.containerObserver = new ResizeObserver((entries) => {
            let entry: ResizeObserverEntry;
            for (entry of entries) {
                this.stage.width(entry.contentRect.width);
                this.stage.height(entry.contentRect.height);
            }
        });
        this.context = new NirbyContext(translator);
        this.store = new MultiLevelBoardStore<TMeta>(rootBoardState);
        this.currentLevel$ = this.currentLevelIdSubject.pipe(
            switchMap((id) =>
                id ? this.store.watchLevel(id) : of(this.store.root),
            ),
            distinctUntilChanged(),
        );
        this.currentLevelState$ = this.currentLevel$.pipe(
            switchMap((store) => {
                if (!store) {
                    return of(null);
                }
                return store.value$;
            }),
            shareReplay({refCount: true, bufferSize: 1}),
        );
        this.selection = new SelectionController<TMeta>(this);
        this.actions = new ActionSetForm<TMeta>(this);
        this.canvas = new ArtBoardEditorCanvas<TMeta>(
            this.context,
            this.stage,
            this.currentLevel$.pipe(
                map((level) => level?.value),
                filter((level): level is NirbyBoardStateNode<TMeta, ArtBoard<TMeta>> => !!level),
            ),
            this.rootBoardState,
            this.selection.selectedItem$.pipe(map((item) => item?.id ?? null)),
        );
    }

    /**
     * Get the container
     */
    public get container(): HTMLDivElement {
        return this.stage.container();
    }

    /**
     * Get the tree state
     */
    public get state(): ArtBoardItemState<TMeta> {
        return this.store.value;
    }

    public readonly selection: SelectionController<TMeta>;
    public readonly actions: ActionSetForm<TMeta>;

    public readonly store: MultiLevelBoardStore<TMeta>;

    private subscriptions: Subscription | null = null;

    private readonly updateChannel = new AsyncChannel();

    private readonly clipboard: ClipboardCustom<NirbyBoardStateNode<TMeta>>;

    /**
     * Find a konva shape by id
     * @param id Id
     *
     * @returns - Konva shape
     */
    findShapeById(id: string): ArtBoardItem<TMeta> | null {
        return this.canvas.getById(id);
    }

    /**
     * Get the selected Art Board
     */
    public get artBoard(): ArtBoard<TMeta> | null {
        return this.canvas?.board ?? null;
    }

    /**
     * Watches when an item is dragged outside the canvas.
     */
    public get onDragOut$(): Observable<ArtBoardItem<TMeta>> {
        return this.canvas.interactions.onDragOut$;
    }

    /**
     * Get the expected Z-index for the given item, when inside the canvas.
     * @private
     * @param storeItemId - The item ID on the store
     *
     * @returns - Z-index
     */
    private getZIndexFor(storeItemId: string): number {
        const siblings = this.canvas.findAll()
            .filter(item => item?.id !== storeItemId)
            .map((item) => this.store.get(item.id));
        const data = this.store.get(storeItemId);
        if (!data) {
            return -1;
        }
        siblings.push(data);
        siblings.sort((a, b) => {
            const aIndex = a?.index ?? Infinity;
            const bIndex = b?.index ?? Infinity;
            return aIndex - bIndex;
        });
        return siblings.indexOf(data);
    }

    /**
     * Initialize the editor. This will initialize the canvas and start listening for:
     * - Changes on the canvas and apply them to the store (dragging out, resizing, etc.)
     * - Changes on the store and apply them to the canvas
     * - Selection instruction from the canvas to select it on the controller
     * - Selected item(s) to add to transformer
     *
     * Remember to call this method before using the editor and {@link dispose} it when you're done.
     *
     * @param initialLevelId The initial level id
     * @param visibilityFilter The filter to apply to the visibility of the items
     * @param showSelectionRectangle Whether to show use the selection rectangle
     * @param removeOnDragOut Whether to remove the item when dragged out
     *
     * @returns - Promise that resolves when the editor is ready
     */
    public async init<TContext>(
        initialLevelId?: string,
        visibilityFilter?: {
            factory: {
                new(item: MultiLevelBoardStore<TMeta>): ReactiveStorageFilter<NirbyBoardStateNode<TMeta>, TContext>
            };
            initialContext: TContext;
            context: Observable<TContext>;
        },
        showSelectionRectangle = false,
        removeOnDragOut = false,
    ): Promise<void> {
        if (this.subscriptions) {
            this.dispose();
        }
        this.subscriptions = new Subscription();

        // update block on store when cardboard is updated by the user
        this.subscriptions.add(
            this.canvas.interactions.onUserChange$.subscribe((change) => {
                Logger.logStyled(
                    'BLOCK-EDITOR:[🎨 ➡ 🌐]',
                    `Received canvas change for ID and move index in "${change.relativeZIndex}" steps`,
                    change.id,
                );
                this.store.applyCanvasChange(change);
            }),
        );

        if (removeOnDragOut) {
            // delete block on cardboard when drawable is dragged outside
            this.subscriptions.add(
                this.onDragOut$.subscribe((block) => {
                    this.store.remove(block.id);
                }),
            );
        }

        let store: ReactiveKeyValueStorage<NirbyBoardStateNode<TMeta>> = this.store;
        if (visibilityFilter) {
            const filteredStorage = new ReactiveFilteredKeyValueStorage(
                new visibilityFilter.factory(this.store),
                visibilityFilter.initialContext,
            );
            this.subscriptions.add(
                visibilityFilter.context.subscribe((context) => {
                    filteredStorage.setContext(context);
                }),
            );
            store = filteredStorage;
        }

        // select a block in the controller when selected on the canvas
        this.subscriptions.add(
            this.canvas.onSelection$.subscribe((item) => {
                if (
                    item === null ||
                    Block.isBlock(item) ||
                    ArtBoard.isArtBoard(item)
                ) {
                    this.selection.select(item?.id ?? null);
                }
            }),
        );

        // add selected block to transformer when selected on the controller
        this.subscriptions.add(
            this.selection.selectedItemId$.subscribe((itemId) => {
                const item = itemId ? this.canvas.getById(itemId) : null;
                if (item) {
                    item.transformController.start(this.canvas.transformer);
                } else {
                    this.canvas.transformer.select(null);
                }
            }),
        );

        // initialize canvas
        await this.canvas.init(initialLevelId, showSelectionRectangle, !!visibilityFilter);

        // update canvas when store is updated
        this.subscriptions.add(
            store.watchChanges().subscribe(async (change) => {
                if (this.settings.fitToContainerOnUpdate) {
                    this.canvas.fitToContainer();
                }
                await this.updateChannel.wait(
                    () => (async () => {
                        switch (change.type) {
                            case 'delete': {
                                // remove
                                Logger.logStyled(
                                    'BLOCK-EDITOR:[🌐 ➡ 🎨]',
                                    'Received deletion',
                                    change.id,
                                );
                                this.canvas.deleteById(change.id);
                                return;
                            }
                            case 'insert': {
                                const data = change.newValue;
                                // add
                                Logger.logStyled(
                                    'BLOCK-EDITOR:[🌐 ➡ 🎨]',
                                    'Received creation',
                                    change.id,
                                    data?.type ?? '<<REMOVED>>',
                                );
                                await this.canvas.create(
                                    change.id,
                                    data.type,
                                    data.meta,
                                    data.properties,
                                    data.children,
                                    this.getZIndexFor(change.id),
                                );
                                return;
                            }
                            case 'update': {
                                const currentCanvas = this.canvas.getById(change.id);
                                const data = change.newValue;

                                if (currentCanvas) {
                                    // update
                                    Logger.logStyled(
                                        'BLOCK-EDITOR:[🌐 ➡ 🎨]',
                                        'Received memory update for',
                                        change.id,
                                        data?.type ?? '<<REMOVED>>',
                                        `at index ${this.store.indexOf(change.id)}`,
                                    );
                                    currentCanvas.set(data.properties);
                                    currentCanvas.shape?.zIndex(this.getZIndexFor(change.id));
                                }
                            }
                        }
                    })(),
                );
            }),
        );

        if (initialLevelId) {
            this.currentLevelIdSubject.next(initialLevelId);
        }

        const container = this.container;
        this.containerObserver.observe(container);
    }

    /**
     * Dispose this editor.
     */
    public dispose(): void {
        if (!this.subscriptions) {
            Logger.warn('Tried to dispose non-initialized editor');
            return;
        }
        this.subscriptions?.unsubscribe();
        this.subscriptions = null;
        this.containerObserver.unobserve(this.container);
        this.canvas.dispose();
    }

    /**
     * Undo the last change
     */
    public undo(): void {
        this.store.back();
    }

    /**
     * Redo the last undo
     */
    public redo(): void {
        this.store.forward();
    }

    /**
     * Find item state by ID
     * @param id item ID
     *
     * @returns - Item state or null if not found
     */
    public findStateById(id: string): NirbyBoardStateNode<TMeta> | null {
        return this.store.get(id)?.value ?? null;
    }

    /**
     * Find item by ID
     * @param id ID of the item
     *
     * @returns - Item or null if not found
     */
    public findItemById(id: string): ArtBoardItem<TMeta> | null {
        if (!this.canvas) {
            return null;
        }
        return this.canvas.getById(id);
    }

    /**
     * Find item by ID
     * @param id ID of the item
     *
     * @returns - Item or null if not found
     */
    public findBlockById(id: string): SingleLevelBoardStore<TMeta, Block<TMeta>> | null {
        const block = this.store.get(id);
        if (block?.type !== 'block') {
            return null;
        }
        return block as SingleLevelBoardStore<TMeta, Block<TMeta>>;
    }

    /**
     * Creates an empty block
     * @param type The new block's type
     * @param area The new block's position and size (area)
     * @param meta The new block's metadata
     *
     * @returns - The new block
     */
    public async createAt(
        type: AnyBlockType,
        area: [Vector2d, Vector2d],
        meta: TMeta | null,
    ): Promise<Block<TMeta> | null> {
        const levelId = this.currentLevel?.id;
        if (!this.canvas) {
            Logger.warnStyled(
                'BLOCK-EDITOR',
                'Editor has not been initialized',
            );
            return null;
        }
        if (!levelId) {
            Logger.warnStyled(
                'BLOCK-EDITOR',
                'Trying to create block when no level is selected',
            );
            return null;
        }
        const block = BlockFactory.generateBlockAtArea(type, area, this.playerConfig);
        block.hash = v4();
        this.store.addAt(levelId, Block.blockToState(block, meta));
        const item = await this.canvas.waitForBlockOfId(block.hash);
        this.selection.select(item.id);
        return item;
    }

    /**
     * Create a block with certain options
     * @param options The new block options
     * @param meta The new block's metadata
     *
     * @returns The created block
     */
    public async create(
        options: AnyBlock,
        meta: TMeta | null,
    ): Promise<NirbyBoardStateNode<TMeta, Block<TMeta>> | null> {
        const level = this.currentLevel;
        if (!level) {
            Logger.warnStyled(
                'BLOCK-EDITOR',
                'Trying to create block when no level is selected',
            );
            return null;
        }
        const block = BlockFactory.generateBlockAtArea(
            options.type,
            options.position,
            this.playerConfig,
        );
        block.hash = options.hash;
        block.position = [...options.position];
        block.content = {...options.content};
        block.style = {...options.style};
        block.rotation = options.rotation;
        block.actions = options.actions;

        const blockState = Block.blockToState<TMeta>(block, meta);
        const newId = this.store.addAt(level.id, blockState);
        if (!newId) {
            Logger.warn(
                `Failed to create block with type "${options.type}" and ID "${options.hash}"`,
            );
            return null;
        }
        blockState.id = newId;
        await this.canvas.waitForBlockOfId(newId);
        return this.findBlockById(newId)?.value ?? null;
    }

    /**
     * Delete a block by ID
     * @param blockId The block ID to be deleted
     */
    public delete(blockId: string): void {
        this.store.remove(blockId);
    }

    /**
     * Deletes the selected block
     */
    public deleteSelected(): void {
        const blockId = this.selection.selectedBlockId;
        if (!blockId) {
            return;
        }
        this.delete(blockId);
    }

    /**
     * Batch draw the stage
     */
    public batchDraw(): void {
        this.canvas.board?.batchDraw();
    }

    /**
     * Updates the block using the ID of the given block data
     * @param block The updated block data
     */
    update(block: AnyBlock): void {
        this.store.get(block.hash)?.update(block);
    }


    /**
     * Moves a block relative to its current position.
     * @param motion The motion to be applied to the block.
     */
    moveCurrentBlockRelative(motion: Vector2d): void {
        this.applyToSelected((b) => b.move(motion, true));
    }

    /**
     * Apply a method to the selected block if a block is selected
     * @param fn Method to be executed
     */
    applyToSelected(fn: (b: Block<TMeta>) => void): void {
        const selectedId = this.selection.selectedBlockId;
        if (!selectedId) {
            return;
        }
        const block = this.canvas.getBlockById(selectedId);
        if (!block) {
            return;
        }
        fn(block);
    }

    /**
     * Creates an observable that when subscribed will create a ghost with the size of the given item that'll
     * follow the cursor until the mouse is released. Then, the item will be created at the position of the ghost, and
     * the ghost will disappear.
     * @param item The item to be created
     * @param shouldInsert Whether the item should be inserted into the current level
     *
     * @returns - An observable that will create the item
     */
    public startPositioningItem(
        item: NirbyBoardStateNode<TMeta>,
        shouldInsert = true,
    ): Promise<PositioningResult<TMeta> | null> {
        const currentLevelId = this.currentLevel?.id;
        if (!currentLevelId) {
            Logger.warnStyled(
                'BLOCK-EDITOR',
                'Trying to create item when no level is selected',
            );
            return Promise.resolve(null);
        }
        return lastValueFrom(
            this.canvas.startPositioningItem(item).pipe(
                // wait for an item to be created
                filter(
                    (result): result is NirbyBoardStateNode<TMeta> => result !== null,
                ),
                // start insertion
                tap((itemState) => {
                    if (shouldInsert) {
                        this.store.insert(
                            itemState.id,
                            itemState,
                            currentLevelId,
                        );
                    }
                }),
                // wait for insertion
                switchMap(async (item) => {
                    const result: PositioningResult<TMeta> = {
                        position: item.properties.position[0],
                        size: {
                            x:
                                item.properties.position[1].x -
                                item.properties.position[0].x,
                            y:
                                item.properties.position[1].y -
                                item.properties.position[0].y,
                        },
                        item: null,
                    };
                    if (shouldInsert) {
                        result.item = await this.canvas.waitForBlockOfId(
                            item.id,
                        );
                    }
                    return result;
                }),
                // add empty element to avoid "No elements in sequence" error
                startWith(null),
            ),
        );
    }

    /**
     * Get the art-board current size
     */
    public get currentSize(): { width: number; height: number } | null {
        return this.canvas.board?.shape?.size() ?? null;
    }

    /**
     * Fit stage to container
     */
    public fitToContainer(): void {
        this.canvas.fitToContainer();
    }

    /**
     * Duplicates the given item by ID.
     * @param id The ID of the item to be duplicated
     *
     * @returns The item duplicate ID.
     */
    duplicate(id: string): Promise<string | null> {
        if (this.copy(id)) {
            return this.paste();
        }
        return Promise.resolve(null);
    }

    /**
     * Copies the given item by ID.
     * @param id The ID of the item to be copied
     *
     * @returns Whether the item was copied.
     */
    copy(id: string): boolean {
        const item = this.store.get(id)?.value;
        if (!item) {
            return false;
        }
        this.clipboard.copy(item);
        return true;
    }

    /**
     * Pastes the clipboard item into the current level.
     *
     * @returns The item ID of the pasted item.
     */
    async paste(): Promise<string | null> {
        const pasted = await this.clipboard.paste();
        return pasted?.id ?? null;
    }
}
