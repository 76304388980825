import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import { faPen } from '@fortawesome/free-solid-svg-icons';

export type EditInPlaceTag = 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

@Component({
    selector: 'nirby-edit-in-place',
    templateUrl: './edit-in-place.component.html',
    styleUrls: ['./edit-in-place.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: EditInPlaceComponent,
            multi: true
        }
    ]
})
/**
 * Component to edit a text in place.
 */
export class EditInPlaceComponent {
    @ViewChild('textInput', {static: true}) input: ElementRef<HTMLInputElement> | null = null;
    @Input() tag: EditInPlaceTag = 'span';
    @Input() placeholder = 'Write something...';
    @Input() styleClass: string | null = null;
    @Input() disableTouchToEdit = false;

    value: string | null = null;
    valueEdit: string | null = null;

    disabled = false;
    @Output() renameModeChange = new EventEmitter<boolean>();

    renaming = false;
    icons = {
        edit: faPen,
    };
    onChange: (action: string) => void = () => {
        return;
    };

    onTouched = () => {
        return;
    };

    @Input() set renameMode(value: boolean) {
        this.renaming = value;
        if (value) {
            this.valueEdit = this.value;
            setTimeout(() => {
                this.input?.nativeElement?.focus();
            });
        }
    }

    get renameMode(): boolean {
        return this.renaming;
    }

    /**
     * The message to display.
     */
    get preview(): string {
        if (!this.value) {
            return this.placeholder;
        }
        return this.value;
    }

    /**
     * The text HTML classes
     */
    get spanClass(): { [p: string]: boolean } {
        return {
            ...(this.styleClass ? {[this.styleClass]: true} : {}),
            'touch-to-edit': (!this.renameMode || this.disabled) && !this.disableTouchToEdit,
            'text-muted': this.value?.length === 0,
        };
    }

    /**
     * Write a new action to the model.
     * @param obj - Action to write.
     */
    writeValue(obj?: string): void {
        this.value = obj ?? null;
    }

    /**
     * Register a callback function that is called when the value has changed.
     * @param fn - Callback function.
     */
    registerOnChange(fn: (action: string) => void): void {
        this.onChange = fn;
    }

    /**
     * Register a callback function that is called when the control has been touched.
     * @param fn - Callback function.
     */
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    /**
     * Set the disabled state of the component.
     * @param isDisabled Whether the component should be disabled.
     */
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Notifies the control value accessor that a change is being made to the value.
     * @param value - New value.
     */
    update(value: string): void {
        this.value = value;
        this.setRenameMode(false);
        this.onChange(value);
    }

    cancelRename(): void {
        this.valueEdit = this.value;
        this.setRenameMode(false);
    }

    startRenameOnClick(input: HTMLInputElement): void {
        if (this.disableTouchToEdit) {
            return;
        }
        this.setRenameMode(true);
        input.focus();
    }

    setRenameMode(onOff: boolean): void {
        this.renameMode = onOff;
        this.renameModeChange.emit(onOff);
    }
}
