<div class="form-group d-flex flex-column">
    <label for="switch" *ngIf="label">
        {{label}}
    </label>
    <ng-toggle
        id="switch"
        [ngModel]="value"
        (ngModelChange)="update($event)"
        [disabled]="disabled"
        [color]="{ unchecked: '#939da2', checked: '#3b14d3' }"
    ></ng-toggle>
</div>
