import {Component} from '@angular/core';
import {faArrowRight, faCheck, faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons';
import {Workspace} from '@nirby/models/editor';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {NirbyDocumentReference} from '@nirby/store/base';

interface CardContent {
    cardTitle: string;
    cardText: string;
    labelButton: string;
    thumb: string | null;
    videoURL: string | null;
    icon: IconDefinition;
}

@Component({
    selector: 'nirby-create-widget',
    templateUrl: './create-widget.component.html',
    styleUrls: ['./create-widget.component.scss'],
})
export class CreateWidgetComponent {
    index = 0;

    arrowIcon = faArrowRight;

    createdWidget: NirbyDocumentReference<Workspace> | null = null;

    cardContent: CardContent[] = [
        {
            cardTitle: 'Let\'s start by creating your first workspace.',
            cardText:
                'In order to show a Nirby Pop on your website, you\'ll need to add it to your site. This is done by creating a new "Workspace" that will represent your website in our platform.',
            labelButton: 'Create Workspace',
            icon: faArrowRight,
            thumb: '/assets/img/tutorials/first-widget.jpg',
            videoURL: 'https://youtu.be/T5Q1e_HSkcY',
        },
        {
            cardTitle: 'We\'re all set',
            cardText:
                'You can officially start creating amazing interactive campaigns for your website. Just don\'t forget to set up your routes! *wink* *wink*',
            labelButton: 'Let\'s do this!',
            icon: faCheck,
            thumb: null,
            videoURL: null,
        },
        {
            cardTitle: 'Add workspace code to your website',
            cardText:
                'Follow the video instructions to install our code snippet into your website. After you\'ve done so, your campaigns will automatically appear on your site.\n Copy this code, and paste it right before the </body> closure tag.',
            labelButton: 'Validate Installation',
            icon: faExternalLinkAlt,
            thumb: '/assets/img/tutorials/insert-widget.jpg',
            videoURL: 'https://youtu.be/8U9Y_B6GvPI',
        },
    ];

    onNewWidget(newWidget: NirbyDocumentReference<Workspace> | null): void {
        this.index++;

        if (this.index >= 3) {
            this.index = 0;
        }

        this.createdWidget = newWidget;
    }
}
