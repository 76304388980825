<div
    class="component-container"
    [ngClass]="{ 'has-image-true': fileUrl, 'has-image-false': !fileUrl }"
>
    <div class="actions">
        <input
            id="file"
            type="file"
            (change)="upload($event)"
            accept=".png,.jpg"
        />
    </div>

    <div class="preview">
        <span class="placeholder" *ngIf="!fileUrl"></span>

        <img *ngIf="fileUrl" [src]="fileUrl" />
    </div>
    <p *ngIf="progress$ | async as progress">
        <ngb-progressbar
            *ngIf="progress < 1"
            type="success"
            [value]="progress * 100"
        ></ngb-progressbar>
    </p>
</div>
