import {NirbyMemory, NirbyMemoryMask, StateManager} from './nirby-memory';
import {map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {
    BehaviorSubject,
    combineLatest,
    distinctUntilChanged,
    filter,
    first,
    lastValueFrom,
    Observable,
    of,
    OperatorFunction,
    Subject,
} from 'rxjs';
import {FullProductId, NirbyEvent} from '@nirby/analytics-typings';
import {NirbyVariableDeclarationSet, NirbyVariableNullable} from '@nirby/runtimes/state';
import {Contact} from './contact';
import {ANY_CONVERTER} from './weak-typed-state';
import {AnyCardAction, OpenFormSettings} from '@nirby/models/nirby-player';
import {StorageService} from './storage.service';
import {ActionListener} from './actions';
import {TranslatorLike} from '@nirby/runtimes/i18n';
import {Logger} from '@nirby/logger';

export interface ContextModalsInterface {
    set context(value: NirbyContext);

    openForm(context: NirbyContext, settings: OpenFormSettings): Promise<void>;

    answerQuestion(
        productId: FullProductId,
        contact: Contact,
        questionId: string,
        answer: NirbyVariableNullable
    ): Promise<void>;

    updateContact(
        properties: Record<string, NirbyVariableNullable>
    ): Promise<void>;
}

/**
 * Nirby event type, tags and properties
 */
export type LiteEvent<TEvent extends NirbyEvent = NirbyEvent> = {
    type: TEvent['type'] | string;
    tags: string[];
    properties: TEvent['properties'];
};


/**
 * A translator that does nothing.
 */
class NonTranslator implements TranslatorLike {
    /**
     * Returns the key.
     * @param key The key.
     *
     * @returns The key.
     */
    translate(key: string): string {
        return key;
    }

    get language(): null {
        return null;
    }
}

/**
 * The heart of a Nirby's data
 *
 * Usage:
 * ```
 * const context = new NirbyContext();
 *
 * // set some initial data if needed
 * context.memory.set('foo', 'bar');
 * context.memory.get('foo'); // => 'bar'
 *
 * // load the contact from storage if already exists
 * const contact = Contact.loadFromStorage() ?? Contact.unauthenticated();
 *
 * // set-up the product ID to be used in analytics
 * const productId = new FullProductId('workspace-id', 'pop-id', 'pop');
 *
 * // initialize the context
 * context.init(contact, productId, {});
 *
 * // start tracking the events triggered by the context (analytics is an instance of {@link AnalyticsService})
 * const subscription = analytics.trackContext(
 *      context,
 *      false, // whether to keep track of the memory changes in the persistent storage
 *      false, // whether to keep track of the last contact in the persistent storage
 * );
 *
 * (...)
 *
 * // when the context is no longer needed, stop tracking the events
 * subscription.unsubscribe();
 * ```
 */
export class NirbyContext {
    public static PROPERTIES_SPLITTER = '__';
    private readonly contactSubject = new BehaviorSubject<Contact>(
        Contact.unauthenticated(),
    );
    private readonly productIdSubject =
        new BehaviorSubject<FullProductId | null>(null);
    private readonly eventsSubject = new Subject<LiteEvent>();

    public readonly memory$: Observable<NirbyContext> = this.memory.state$.pipe(
        map(() => this),
    );
    public readonly events$ = this.eventsSubject.asObservable();
    public readonly productId$ = this.productIdSubject.asObservable();

    public readonly contact$: Observable<Contact> = this.contactSubject.pipe(
        distinctUntilChanged((a, b) => a.token === b.token),
    );
    public readonly actionListener = new ActionListener();
    public readonly actions$ = this.actionListener.watchActions();

    /**
     * Subject that stores the keys from the memory that will be loaded into the persistent storage
     * @private
     */
    private readonly persistentStateKeysSubject = new BehaviorSubject<Set<string>>(new Set<string>());


    /**
     * The current workspace ID.
     */
    get workspaceId(): string | null {
        return this.productId?.workspaceId ?? null;
    }

    /**
     * The global mask of the memory
     */
    get global(): NirbyMemoryMask {
        return this.memory.mask('global');
    }

    /**
     * Get the current active product ID
     */
    get productId(): FullProductId | null {
        return this.productIdSubject.value;
    }

    /**
     * Set the active product ID
     * @param value New product Id
     */
    set productId(value: FullProductId | null) {
        this.productIdSubject.next(value);
    }

    /**
     * Get the current active contact
     */
    get contact(): Contact {
        return this.contactSubject.value;
    }

    /**
     * Set the active contact
     * @param value New contact
     */
    set contact(value: Contact) {
        Logger.logStyled('CONTEXT', 'Set new contact', value);
        const previousContact = this.contactSubject.value;
        this.contactSubject.next(value);
        this.global.set('@contact', value.originalAttributes);
        this.global.set('@contactJson', JSON.stringify(value.originalAttributes));
        this.global.set('@ua', value.uniqueAlias);
        if (previousContact.id !== value.id) {
            this.impress();
        }
    }

    public readonly translator: TranslatorLike;

    /**
     * A context for mock purposes
     *
     * @returns A mock context
     */
    public static mock(): NirbyContext {
        return new NirbyContext(new NonTranslator());
    }

    /**
     * Marks a key to be stored in the persistent storage
     * @param keys Keys to be stored in the persistent storage
     */
    public markKeysAsPersistent(...keys: string[]): void {
        const persistentStateKeys = new Set<string>(
            this.persistentStateKeysSubject.value,
        );
        const previousSize = persistentStateKeys.size;
        keys.forEach((key) => persistentStateKeys.add(key));
        if (previousSize !== persistentStateKeys.size) {
            Logger.logStyled('CONTEXT', 'The following keys are now persistent', [
                ...persistentStateKeys.values(),
            ]);
        }
        this.persistentStateKeysSubject.next(persistentStateKeys);
    }

    /**
     * Creates a Nirby context that holds data about the current contact and variables (stored in a {@link NirbyMemory} object)
     * @param translator An object to translate text
     * @param memory To initialize the context with some data set beforehand
     */
    constructor(
        translator: TranslatorLike,
        public readonly memory = new NirbyMemory<string>(),
    ) {
        this.translator = translator;
    }

    /**
     * Initializes the given memory variables with the initial values or loading them from the query params
     * @param variables Variable declarations
     * @param at The memory mask to load the query params and persistent storage into (answer will be loaded only
     * into the corresponding block's context)
     */
    public initializeVariables(
        variables?: NirbyVariableDeclarationSet,
        at = 'global',
    ): void {
        if (!variables) {
            variables = {};
        }
        const entries = Object.entries(variables);
        let memory: StateManager = this.memory;

        // If a mask is given, use it
        if (at) {
            memory = memory.mask(at);
        }

        // load initial values
        for (const [key, value] of entries) {
            memory.set(key, value.initialValue ?? '');
        }

        // load from query params
        const queryVariables = entries
            .filter((entry) => entry[1].source === 'query')
            .map((entry) => entry[0]);
        this.loadMemoryFromQueryParams(at, queryVariables);

        // load from persistent state into the top level memory
        const storage = this.getCurrentProductStorage();
        if (storage) {
            const persistentState = this.memory;
            const persistentData = storage.get('persistent');
            if (persistentData !== null && typeof persistentData === 'object') {
                for (const [key, value] of Object.entries(persistentData)) {
                    if (value === null || value === undefined) {
                        continue;
                    }
                    persistentState.set(key, value);
                }
                this.markKeysAsPersistent(...Object.keys(persistentData));
            }
        }
    }

    /**
     * Returns an observable that, when subscribed to, will store every change on the global state into
     * the product storage
     *
     * @returns - An observable that will store every change on the global state into the product storage
     */
    public storeInPersistentStorage(): Observable<void> {
        return this.persistentStateKeysSubject.pipe(
            switchMap((keys) => {
                return combineLatest(
                    [...keys.values()].map((key) =>
                        this.memory
                            .watch(key, ANY_CONVERTER)
                            .pipe(map((value) => ({key, value}))),
                    ),
                );
            }),
            map((entries) => ({
                storage: this.getCurrentProductStorage(),
                entries,
            })),
            tap(({entries, storage}) => {
                if (!storage) {
                    return;
                }
                const stateToSave: Record<string, NirbyVariableNullable> = {};
                entries.forEach(({key, value}) => {
                    stateToSave[key] = value;
                });
                storage.set('persistent', stateToSave);
            }),
            map(() => undefined),
        );
    }

    /**
     * Gets the storage service for the current product and contact
     *
     * @returns - The storage service for the current product and contact
     */
    public getCurrentProductStorage(): StorageService | null {
        const productId = this.productId;
        const contact = this.contact;
        if (productId && contact.isAuthenticated) {
            return new StorageService(
                'nirby',
                productId.uniqueId + '/' + this.contact.id,
            );
        } else {
            return null;
        }
    }

    /**
     * Gets the storage service for the current product, independent of the contact
     *
     * @returns - The storage service for the current product and contact
     */
    public getCurrentProductUnauthStorage(): StorageService | null {
        const productId = this.productId;
        if (productId) {
            return new StorageService(
                'nirby',
                productId.uniqueId,
            );
        } else {
            Logger.warnStyled('CONTEXT', 'No product ID set');
            return null;
        }
    }

    /**
     * Get the query params from the current URL and load its values into the context
     * @param at The memory mask to load the query params into
     * @param keys The keys to load from the query params, if not given, all the query params will be loaded
     */
    public loadMemoryFromQueryParams(at?: string, keys?: string[]): void {
        const queryParams = new URLSearchParams(window.location.search);
        const memory = at ? this.memory.mask(at) : this.memory;

        queryParams.forEach((value, key) => {
            if (keys && !keys.includes(key)) {
                return;
            }
            memory.set(key, value);
        });
    }

    /**
     * Initializes the Nirby context in a given workspace.
     *
     * This will set the context's contact and product ID. It'll also set-up the {@link actionListener} with the
     * following common action handlers:
     * - ``variable-update``: Will update the given variable with the given value
     * - ``track-event``: Will trigger the given event
     *
     * @param contact The contact to initialize the context with
     * @param productID The product ID to initialize the context with
     * @param variableDeclaration The variable declaration set to initialize the context with
     */
    public init(
        contact: Contact,
        productID: FullProductId,
        variableDeclaration: NirbyVariableDeclarationSet,
    ): void {
        if (this.productId !== null) {
            Logger.warnStyled('CONTEXT', 'Nirby context already initialized');
            return;
        }
        Logger.logStyled(
            'CONTEXT',
            'Initializing Nirby context',
            contact,
            productID,
            variableDeclaration,
        );
        this.productId = productID;
        this.contact = contact;
        this.actionListener.setListener('variable-update', (action) => {
            this.memory.handleAction(action);
        });
        this.actionListener.setListener('track-event', async (action) => {
            const tags = action.options.tags.map((tag) => this.transform(tag));
            this.triggerEvent({
                type: `custom:${action.options.event}`,
                tags,
                properties: {},
            });
        });
        this.initializeVariables(variableDeclaration);
    }

    /**
     * Receives a text content and replaces all the variables found on it with the content on this context memory
     * @param content The content to be transformed
     * @param mask The mask to be used to replace the variables
     *
     * @returns - The transformed content
     */
    public transform(content: string, mask?: string): string {
        let memory: StateManager = this.memory;
        if (mask) {
            memory = memory.mask(mask);
        }
        return memory.transform(content);
    }

    /**
     * Add an event to the {@link events$} observable
     * @param event Event
     */
    public triggerEvent<Event extends NirbyEvent>(
        event: LiteEvent<Event>,
    ): void {
        Logger.logStyled('CONTEXT', 'Triggering event', event.type, event.properties);
        this.eventsSubject.next(event);
    }

    /**
     * Add an action event to the {@link events$} observable and calls that action handler.
     * @param blockId Block ID
     * @param trigger Trigger event
     * @param action Action
     * @param cardId Card ID
     */
    public triggerAction(
        blockId: string | null,
        trigger: string,
        action: AnyCardAction,
        cardId: string | null = null,
    ): void {
        // check action condition
        const currentState = this.global.state;
        if (action.if !== undefined && action.if !== null && !currentState.evaluate(action.if)) {
            return;
        }

        // emit an action
        this.actionListener.execute(this.transformAction(action), blockId);

        // trigger an event
        this.eventsSubject.next({
            properties: {
                event: trigger,
                cardId,
                blockId,
                actionType: action.type,
            },
            tags: [],
            type: 'action',
        });
    }

    /**
     * Add many actions to the {@link events$} observable and calls that action handler.
     * @param blockId Block ID
     * @param trigger Trigger event
     * @param actions Actions
     * @param cardId Card ID
     */
    public triggerActionsMany(
        blockId: string | null,
        trigger: string,
        actions: AnyCardAction[],
        cardId: string | null = null,
    ) {
        for (const action of actions) {
            this.triggerAction(blockId, trigger, action, cardId);
        }
    }

    /**
     * Clears the memory completely. This includes variables and the active contact
     */
    public clear(): void {
        this.contactSubject.next(Contact.unauthenticated());
        this.memory.clear();
    }

    /**
     * An observable operator to transform a string received through an observable using the {@link transform} method.
     *
     * @see transform
     *
     * @param mask The mask to be used to replace the variables
     *
     * @returns - An observable operator that transforms the content
     */
    public mapTransform = (mask?: string): OperatorFunction<string, string> =>
        switchMap((c: string) => {
            return of(c).pipe(
                withLatestFrom(this.memory.state$),
                map(([content]) => this.transform(content, mask)),
            );
        });

    /**
     * Takes an action and transforms it replacing variables
     * @param action Action to be transformed
     * @private
     *
     * @returns - The transformed action
     */
    private transformAction(action: AnyCardAction): AnyCardAction {
        switch (action.type) {
            case 'open-embed': {
                return {
                    id: action.id,
                    type: 'open-embed',
                    options: {
                        ...action.options,
                        src: this.global.transform(action.options.src),
                    },
                };
            }
            case 'go-to-url': {
                return {
                    id: action.id,
                    type: 'go-to-url',
                    options: {
                        ...action.options,
                        href: this.global.transform(action.options.href),
                    },
                };
            }
            case 'track-event': {
                return {
                    id: action.id,
                    type: 'track-event',
                    options: {
                        ...action.options,
                        tags: action.options.tags.map((t) =>
                            this.global.transform(t),
                        ),
                    },
                };
            }
        }
        return action;
    }

    /**
     * Triggers an impression event if the current product has not been impressed in the current session
     */
    impress(): void {
        const storage = this.getCurrentProductStorage();
        if (!storage) {
            Logger.warnStyled('CONTEXT', 'No storage available for impression');
            return;
        }
        const impressed = storage.getBool('impressed') ?? false;
        if (impressed) {
            return;
        }
        storage.set('impressed', true);
        this.triggerEvent({
            properties: {},
            tags: [],
            type: 'impression',
        });
    }

    /**
     * Wait for an authenticated contact.
     *
     * @returns - A promise that resolves when the contact is authenticated.
     */
    waitForContact(): Promise<Contact> {
        return lastValueFrom(this.contact$.pipe(
            filter(contact => contact.isAuthenticated),
            first(),
        ));
    }
}
