import { Pair, PartialRecord, toEntries } from './object';
import {
    NirbyVariable,
    NirbyVariableDeclaration,
    NirbyVariableNullable,
    NirbyVariableType,
} from './variables';

export class Converter<T> {
    constructor(
        private fromNumber: (_: number) => T,
        private fromString: (_: string) => T,
        private fromBoolean: (_: boolean) => T,
        private defaultValue: (_: NirbyVariableNullable) => T
    ) {}

    public convert(value: NirbyVariableNullable): T {
        return this.convertNullable(value) ?? this.defaultValue(value);
    }

    public convertNullable(value: NirbyVariableNullable): T | null {
        switch (typeof value) {
            case 'boolean':
                return this.fromBoolean(value);
            case 'number':
                return this.fromNumber(value);
            case 'string':
                return this.fromString(value);
            default:
                return null;
        }
    }
}

export const FLOAT_CONVERTER = new Converter<number>(
    (v) => v,
    (v) => {
        const parsed = parseFloat(v);
        return isNaN(parsed) ? 0 : parsed;
    },
    (v) => (v ? 1 : 0),
    (_) => 0
);

export const INT_CONVERTER = new Converter<number>(
    (v) => Math.floor(v),
    (v) => Math.floor(FLOAT_CONVERTER.convert(v)),
    (v) => Math.floor(FLOAT_CONVERTER.convert(v)),
    (_) => 0
);

export const STRING_CONVERTER = new Converter<string>(
    (v) => v.toString(10),
    (v) => v,
    (v) => v.toString(),
    (_) => ''
);

export const BOOL_CONVERTER = new Converter<boolean>(
    (v) => !!v,
    (v) => !!INT_CONVERTER.convert(v),
    (v) => !!INT_CONVERTER.convert(v),
    (_) => false
);

export type WeakAttributes = {
    [key: string]: NirbyVariableNullable
};

export class WeakTypedState<TK extends string> {
    readonly value: WeakAttributes;

    public static variableTypeOf(
        value: NirbyVariableNullable
    ): NirbyVariableType {
        if (value === null) {
            return 'null';
        }
        switch (typeof value) {
            case 'boolean':
                return 'number';
            case 'number':
                return 'number';
            case 'string':
                return 'string';
            default:
                throw new Error(`Out of range type: ${typeof value}`);
        }
    }

    public get declaration(): PartialRecord<NirbyVariableDeclaration> {
        const declaration: PartialRecord<NirbyVariableDeclaration> = {};
        const entries = toEntries(this.value);
        let entry: Pair<TK | string, NirbyVariableNullable>;
        for (entry of entries) {
            declaration[entry[0]] = {
                type: WeakTypedState.variableTypeOf(entry[1]),
                initialValue: entry[1],
                source: 'private',
            };
        }
        return declaration;
    }

    /* GETTERS */

    public getBool(name: TK | string): boolean {
        const value = this.get<NirbyVariable>(name, false);
        return BOOL_CONVERTER.convert(value);
    }

    public getString(name: TK | string): string {
        const value = this.get<NirbyVariable>(name, '');
        return STRING_CONVERTER.convert(value);
    }

    public getInt(name: TK | string): number {
        const value = this.get<NirbyVariable | null>(name, 0);
        return INT_CONVERTER.convert(value);
    }

    public getFloat(name: TK | string): number {
        const value = this.get<NirbyVariable | null>(name, 0);
        return FLOAT_CONVERTER.convert(value);
    }

    /* GETTERS NULL */

    public getBoolNullable(name: TK | string): boolean | null {
        const value = this.get<NirbyVariable>(name, false);
        return BOOL_CONVERTER.convertNullable(value);
    }

    public getStringNullable(name: TK | string): string | null {
        const value = this.get<NirbyVariable>(name, '');
        return STRING_CONVERTER.convertNullable(value);
    }

    public getIntNullable(name: TK | string): number | null {
        const value = this.get<NirbyVariable | null>(name, 0);
        return INT_CONVERTER.convertNullable(value);
    }

    public getFloatNullable(name: TK | string): number | null {
        const value = this.get<NirbyVariable | null>(name, 0);
        return FLOAT_CONVERTER.convertNullable(value);
    }

    /* OPERATORS */

    private get<T extends NirbyVariable | null>(
        name: TK | string,
        defaultValue: T
    ): T {
        return (this.value[name] as unknown as T) ?? defaultValue;
    }

    public copyWith<T extends NirbyVariable>(
        name: TK | string,
        value: T
    ): WeakTypedState<TK> {
        const inMemory = this.value[name];

        const sourceType = typeof inMemory;
        const destinyType = typeof value;
        if (inMemory !== undefined && sourceType !== destinyType) {
            console.warn(
                `Replacing variable "${name}" of type "${sourceType}" to type "${destinyType}"`
            );
        }

        const newState = { ...this.value };
        newState[name] = value;
        return new WeakTypedState(newState);
    }

    constructor(value?: WeakAttributes) {
        this.value = value ?? {};
    }
}
