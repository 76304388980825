import {Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges,} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {VoidFunction} from '@nirby/js-utils/types';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {faQuestion, IconDefinition} from '@fortawesome/free-solid-svg-icons';

export interface SelectButtonOption<T> {
    fontColor: string | null;
    color: string | null;
    icon: IconDefinition | null;
    label: string;
    id: T | null;
}

@Component({
    selector: 'nirby-selector-toggle',
    templateUrl: './selector-toggle.component.html',
    styleUrls: ['./selector-toggle.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectorToggleComponent),
            multi: true,
        },
    ],
})
export class SelectorToggleComponent<T>
    implements OnChanges, ControlValueAccessor
{
    @Input() options: SelectButtonOption<T>[] = [];
    @Input() defaultValue: SelectButtonOption<T> = {
        icon: faQuestion,
        label: 'None',
        id: null,
        color: null,
        fontColor: null,
    };

    @Input() set value(value: T) {
        this.controller.next(value);
    }

    @Output() valueChange: EventEmitter<T | null> =
        new EventEmitter<T | null>();

    disabled = false;

    optionsSubject = new BehaviorSubject<SelectButtonOption<T>[]>([]);
    controller = new BehaviorSubject<T | null>(null);

    value$: Observable<T | null> = this.controller.asObservable();
    options$: Observable<SelectButtonOption<T>[]> =
        this.optionsSubject.asObservable();

    selectedOption$: Observable<SelectButtonOption<T>> = combineLatest([
        this.value$,
        this.options$,
    ]).pipe(
        map(
            ([value, options]) =>
                options.find((op) => op.id === value) ?? this.defaultValue
        )
    );

    get optionIndex(): number | null {
        const index = this.options.findIndex(
            (op) => op.id === this.controller.value
        );
        return index >= 0 ? index : null;
    }

    onChange: VoidFunction<T | null> = (value) => {
        this.valueChange.emit(value);
    };
    onTouched: VoidFunction<T | null> = () => {
        return;
    };

    registerOnChange(fn: VoidFunction<T | null>): void {
        this.onChange = (value) => {
            this.valueChange.emit(value);
            fn(value);
        };
    }

    registerOnTouched(fn: VoidFunction<T | null>): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: T): void {
        this.controller.next(obj);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['options']) {
            this.optionsSubject.next(this.options);
        }
    }

    toggle(): void {
        if (this.options.length === 0) {
            return;
        }
        const nextOption = ((this.optionIndex ?? -1) + 1) % this.options.length;
        const nextOptionId = this.options[nextOption].id;
        this.onChange(nextOptionId);
    }
}
