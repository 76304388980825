import { Component, EventEmitter, Output } from '@angular/core';
import { ImageFactoryService } from '@nirby/shared/database';
import { ImageRecord } from '@nirby/media-models';

@Component({
    selector: 'nirby-image-search',
    templateUrl: './image-search.component.html',
    styleUrls: ['./image-search.component.scss'],
})
export class ImageSearchComponent {
    query = '';
    results: ImageRecord[] | null = [];

    @Output() selectImage = new EventEmitter<ImageRecord>();

    constructor(private factory: ImageFactoryService) {}

    async search(): Promise<void> {
        this.results = null;
        this.results = await this.factory
            .getSourceService('unsplash')
            .search(this.query);
    }

    onSelect(image: ImageRecord): void {
        this.selectImage.emit(image);
    }
}
