import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MediaLibraryImagesComponent, MediaLibraryVideosComponent} from './components';

const routes: Routes = [
    {
        path: 'images',
        component: MediaLibraryImagesComponent,
    },
    {
        path: 'videos',
        component: MediaLibraryVideosComponent,
    },
    {
        path: '**',
        redirectTo: 'images',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class MediaLibraryRoutingModule {
}
