import {Injectable} from '@angular/core';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import rawPlan from './plans.json';
import {Plan} from '../models/plan.model';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {AuthenticationService, WorkspaceService} from '@nirby/shared/database';
import {shareReplaySafe} from '@nirby/ngutils';

@Injectable({
    providedIn: 'root',
})
export class PlansService {
    plans: Record<string, Plan> = rawPlan;

    constructor(
        private auth: AuthenticationService,
        private widgets: WorkspaceService,
    ) {
    }

    currentWidgetPlan$: Observable<Plan | null> = this.widgets.activeWidget$.pipe(
        switchMap((workspace) => {
            if (!workspace) {
                return of(null);
            }
            return workspace.data.ownerPlan ? this.get(workspace.data.ownerPlan) : of(null);
        }),
        shareReplaySafe(),
    );

    get(planId: string): Observable<Plan> {
        const plan = this.plans[planId];
        return of(plan).pipe(map((p) => p ?? this.plans['FREE']));
    }

    getAll(): Observable<Plan[]> {
        const plans = Object.values(this.plans);
        return of(plans).pipe(
            map((p) => {
                p.sort((a, b) => a.price - b.price);
                return p;
            }),
        );
    }
}
