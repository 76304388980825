import {Injectable} from '@angular/core';
import {CardsService, DirectoriesService, PopService, RouteService, WorkspaceService} from '@nirby/shared/database';
import {Logger} from '@nirby/logger';
import {Database} from '@nirby/ngutils';
import {firstValueFrom} from 'rxjs';
import {WORKSPACE_MIGRATOR} from '@nirby/models/editor';
import {MigratorLike} from '@nirby/store/migrator';

@Injectable({
    providedIn: 'root',
})
export class WidgetMigrationService {
    constructor(
        private db: Database,
        private workspaces: WorkspaceService,
        private routes: RouteService,
        private pops: PopService,
        private cards: CardsService,
        private directories: DirectoriesService,
    ) {
    }

    private assertMigratorsVersion(workspaceId: string): boolean {
        const migrators = [
            this.workspaces.collection,
            this.routes.collection(workspaceId),
            this.pops.collection(workspaceId),
            this.cards.collection(workspaceId),
            this.directories.collection(workspaceId),
        ]
            .map((service) => service.migrator);
        let migrator: MigratorLike<object> | undefined | null;
        for (migrator of migrators) {
            if (!migrator) {
                continue;
            }
            if (migrator.currentVersion < WORKSPACE_MIGRATOR.currentVersion) {
                return false;
            }
        }
        return true;
    }

    private filterNullish<T>(array: (T | null | undefined)[]): T[] {
        return array.filter((item) => !!item) as T[];
    }

    public async deepMigrate(widgetId: string): Promise<void> {
        const widgetData = await firstValueFrom(this.workspaces.collection.get(widgetId));
        if (
            !widgetData ||
            (widgetData.schemaVersion ?? 0) >=
            WORKSPACE_MIGRATOR.currentVersion
        ) {
            Logger.logAt(
                'MIGRATION:WIDGET',
                `Skipping migration of widget ${widgetId}`,
            );
            return;
        }

        if (!this.assertMigratorsVersion(widgetId)) {
            return;
        }

        const routesRefs = await firstValueFrom(this.routes.collection(widgetId).query().get()).then(
            (refs) => refs.map((ref) => ref.ref),
        );
        const popsRefs = await firstValueFrom(this.pops.collection(widgetId).query().get()).then(
            (refs) => refs.map((ref) => ref.ref),
        );
        const directoriesRefs = await firstValueFrom(this.directories.collection(widgetId).query().get()).then(
            (refs) => refs.map((ref) => ref.ref),
        );
        const cardsRefs = await firstValueFrom(this.cards.collection(widgetId).query().get()).then(
            (refs) => refs.map((ref) => ref.ref),
        );

        return await this.db.runTransaction(async (transaction) => {
            const widget = await this.workspaces.collection.getTransaction(
                transaction,
                widgetId,
            );
            if (!widget) {
                throw new Error(`Widget ${widgetId} not found`);
            }
            const routes = await this.db.getManyTransaction(transaction, routesRefs);
            const pops = await this.db.getManyTransaction(transaction, popsRefs);
            const directories = await this.db.getManyTransaction(transaction, directoriesRefs);
            const cards = await this.db.getManyTransaction(transaction, cardsRefs);

            await this.workspaces.collection.migrateTransaction(transaction, widget);
            await this.routes.collection(widgetId).migrateManyTransaction(transaction, this.filterNullish(routes));
            await this.pops.collection(widgetId).migrateManyTransaction(transaction, this.filterNullish(pops));
            await this.cards.collection(widgetId).migrateManyTransaction(transaction, this.filterNullish(cards));
            await this.directories.collection(widgetId).migrateManyTransaction(transaction, this.filterNullish(directories));
        });
    }
}
