import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {AuthenticationService, WorkspaceService} from '@nirby/shared/database';
import {Workspace, WorkspaceMembership} from '@nirby/models/editor';
import {PlansService} from '../../services/plans.service';
import {CREATORS_ENVIRONMENT, NirbyEnvironment} from '@nirby/environment/base';
import {NirbyDocument} from '@nirby/store/base';

@Component({
    selector: 'nirby-select-widget',
    templateUrl: './select-widget.component.html',
    styleUrls: ['./select-widget.component.scss'],
})
export class SelectWidgetComponent {
    @Input() widget: Workspace | null = null;
    @Output() widgetChange = new EventEmitter<NirbyDocument<WorkspaceMembership>>();

    widgets$: Observable<NirbyDocument<WorkspaceMembership>[]>;
    isDeveloper$: Observable<boolean>;
    currentWidgetId$ = this.widgets.workspaceId$;

    constructor(
        @Inject(CREATORS_ENVIRONMENT) private environment: NirbyEnvironment,
        private auth: AuthenticationService,
        private widgets: WorkspaceService,
        private plans: PlansService,
    ) {
        const user$ = auth.authenticatedUserModeled$;
        this.widgets$ = user$.pipe(
            switchMap((user) =>
                user ? this.widgets.getUserWorkspaces(user.id) : of([]),
            ),
        );
        this.isDeveloper$ = user$.pipe(
            map((user) => user?.isDeveloper ?? false),
        );
    }

    selectWidget(widget: NirbyDocument<WorkspaceMembership>): void {
        this.widgetChange.emit(widget);
    }
}
