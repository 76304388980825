import {Buffer} from 'buffer';
export * from './encoders';

/**
 * An encoder class for encoding data from utf8 format to another format.
 */
class Encoder {
    /**
     * Constructor.
     * @param encoder The underlying string encoder.
     * @param decoder The underlying string decoder.
     */
    constructor(
        private readonly encoder: (decoded: string) => string,
        private readonly decoder: (encoded: string) => string,
    ) {
    }

    /**
     * Encodes the given string.
     * @param decoded The string to encode.
     *
     * @returns The encoded string.
     */
    public encode(decoded: string): string {
        return this.encoder(decoded);
    }

    /**
     * Decodes the given string.
     * @param encoded The string to decode.
     *
     * @returns The decoded string.
     */
    public decode(encoded: string): string {
        return this.decoder(encoded);
    }
}

type EncodingType = 'base64' | 'hex' | 'base64url';

export const encoders: Record<EncodingType, Encoder> = {
    base64: new Encoder(
        (input: string) => Buffer.from(input).toString('base64'),
        (input: string) => Buffer.from(input, 'base64').toString(),
    ),
    hex: new Encoder(
        (input: string) => Buffer.from(input).toString('hex'),
        (input: string) => Buffer.from(input, 'hex').toString(),
    ),
    base64url: new Encoder(
        (input: string) => encoders.base64.encode(input)
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=+$/, ''),
        (input: string) => encoders.base64.decode(input)
            .replace(/-/g, '+')
            .replace(/_/g, '/'),
    ),
}
