import {Component, Input} from '@angular/core';
import {ConditionalEditorBaseComponent} from '../conditional-editor-base/conditional-editor-base.component';
import {ConditionalNegate} from '@nirby/conditionals';
import {NirbyVariableNullable} from '@nirby/runtimes/state';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {AppVariable} from '@nirby/models/editor';

@Component({
    selector: 'nirby-conditional-editor-is-not',
    templateUrl: './conditional-editor-is-not.component.html',
    styleUrls: ['./conditional-editor-is-not.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ConditionalEditorIsNotComponent,
            multi: true
        }
    ]
})
export class ConditionalEditorIsNotComponent extends ConditionalEditorBaseComponent<ConditionalNegate> {
    @Input() variables: AppVariable[] = [];
    @Input() variable: string | null = null;
    value: NirbyVariableNullable = '';

    fillForm(value: ConditionalNegate): void {
        const innerCondition = value.properties.condition;
        if (innerCondition.type === 'equals') {
            this.variable = innerCondition.properties.target;
            this.value = innerCondition.properties.value;
        } else {
            this.variable = null;
            this.value = '';
        }
    }

    getCurrentValue(): ConditionalNegate {
        return {
            type: 'negate',
            properties: {
                condition: {
                    type: 'equals',
                    properties: {
                        target: this.variable ?? '',
                        value: this.value
                    }
                }
            }
        }
    }

    getDefaultValue(): ConditionalNegate {
        return {
            type: 'negate',
            properties: {
                condition: {
                    type: 'equals',
                    properties: {
                        target: this.variable ?? '',
                        value: ''
                    }
                }
            }
        };
    }

    onValueChange(value: NirbyVariableNullable): void {
        this.value = value;
        this.emitChange();
    }
}
