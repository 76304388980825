import { Inject, Injectable } from '@angular/core';
import { VideoService } from './video.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { VideoRecord } from '@nirby/media-models';
import { MEDIA_CONFIG, MediaConfig } from './media-token';
import { lastValueFrom } from 'rxjs';

interface YoutubeIdGetResponse {
    items: unknown[];
}

@Injectable({
    providedIn: 'root',
})
/**
 * Service to get information from YouTube videos.
 */
export class YoutubeService implements VideoService {
    /**
     * Constructor.
     * @param config The configuration for the service.
     * @param http The HTTP client.
     */
    constructor(
        @Inject(MEDIA_CONFIG) private config: MediaConfig,
        private http: HttpClient
    ) {
    }

    /**
     * Get the API Key from the config.
     */
    get apiKey(): string {
        const apiKey = this.config.youtube.apiKey;
        if (!apiKey) {
            throw new Error('No Firebase API key provided');
        }
        return apiKey;
    }

    /**
     * Get the header with the API key.
     *
     * @returns The header with the API key.
     */
    getAuthorizedHeader(): object {
        return {
            Accept: 'application/json',
        };
    }

    /**
     * Gets the video data from an ID.
     * @param id The video ID.
     *
     * @returns The video data.
     */
    async getVideoFromId(id: string): Promise<VideoRecord | null> {
        const rawHeaders = { ...this.getAuthorizedHeader() };
        const headers = new HttpHeaders(rawHeaders);
        const response = await lastValueFrom(
            await this.http.get<YoutubeIdGetResponse>(
                'https://www.googleapis.com/youtube/v3/videos',
                {
                    headers,
                    params: { id, key: this.apiKey },
                }
            )
        );
        if (response.items.length === 0) {
            return null;
        }

        return {
            type: 'youtube',
            id,
            thumbnail: `https://img.youtube.com/vi/${id}/maxresdefault.jpg`,
            metadata: {},
        };
    }

    /**
     * Get the {@link VideoRecord} from a URL.
     * @param url The URL.
     *
     * @returns The video data.
     */
    async getVideoFromUrl(url: string): Promise<VideoRecord | null> {
        let parsed;
        try {
            parsed = new URL(url);
        } catch (e) {
            return null;
        }
        if (parsed.host !== 'www.youtube.com') {
            return null;
        }
        const params = new URLSearchParams(parsed.search);

        const v = params.get('v');
        return v ? await this.getVideoFromId(v) : null;
    }

    /**
     * Get the embed URL for a video.
     * @param id The video ID.
     *
     * @returns The embed URL.
     */
    getEmbedUrlFromId(id: string): string {
        return `https://www.youtube.com/embed/${id}`;
    }
}
