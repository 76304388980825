import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {UploadStatus, VideoRecorder, VideoUploadCloudflareService} from '@nirby/media/video-factory';
import {WorkspaceService} from '@nirby/shared/database';
import {VideoRecord} from '@nirby/media-models';
import {map, Observable, of} from 'rxjs';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {DateTime} from 'luxon';
import {faCircle, faPause, faPlay, faRepeat, faStop, faTimes, faUpload} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'nirby-video-record',
    templateUrl: './video-record.component.html',
    styleUrls: ['./video-record.component.scss'],
})
export class VideoRecordComponent implements OnInit, OnDestroy {
    @Output() selectVideo = new EventEmitter<VideoRecord | null>();

    public recorder: VideoRecorder | null = null;
    public initializing = false;
    public lastResultUrl$: Observable<SafeUrl | null> = of(null);
    public uploadStatus$: Observable<UploadStatus> = of('idle');
    icons = {
        resume: faPlay,
        pause: faPause,
        record: faCircle,
        stop: faStop,
        cancel: faTimes,
        upload: faUpload,
        restart: faRepeat,
    };

    /**
     * Constructor.
     * @param workspaces The workspace service.
     * @param uploadService The video upload service.
     * @param domSanitizer The dom sanitizer.
     */
    constructor(
        private readonly workspaces: WorkspaceService,
        private readonly uploadService: VideoUploadCloudflareService,
        private readonly domSanitizer: DomSanitizer,
    ) {
    }

    /**
     * Loads the recorder.
     */
    async loadRecorder(): Promise<void> {
        this.recorder = await VideoRecorder.record();
        this.lastResultUrl$ = this.recorder.watchLastResult().pipe(
            map((blob) => blob ? URL.createObjectURL(blob) : null),
            map((url) => url ? this.domSanitizer.bypassSecurityTrustUrl(url) : null),
        );
    }

    /**
     * Tries to load the recorder.
     */
    async ngOnInit(): Promise<void> {
        try {
            await this.loadRecorder();
        } finally {
            this.initializing = true;
        }
    }

    /**
     * Disposes the recorder.
     */
    ngOnDestroy(): void {
        this.recorder?.dispose();
    }

    /**
     * Uploads the last recorded video.
     */
    async uploadResult(): Promise<void> {
        if (!this.recorder) {
            return;
        }
        const workspaceId = this.workspaces.workspaceId;
        const result = this.recorder.lastResult;
        if (!result) {
            return;
        }
        const upload = await this.uploadService.uploadFile(workspaceId, result);
        this.uploadStatus$ = upload.status$;
        upload.start();
        const record = await this.uploadService.waitAndRecordUpload(upload, 'Recording ' + DateTime.local().toISO());
        this.selectVideo.emit(record);
    }
}
