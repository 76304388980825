import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {RouteParametersService} from '@nirby/shared/database';

@Component({
    selector: 'nirby-editor-layout',
    templateUrl: './editor-layout.component.html',
    styleUrls: ['./editor-layout.component.scss'],
})
export class EditorLayoutComponent {
    showToolbar$: Observable<boolean>;

    constructor(
        private route: ActivatedRoute,
        private routeParameters: RouteParametersService
    ) {
        this.showToolbar$ = this.routeParameters.getDeepestData<boolean>(
            this.route,
            'showToolbar',
            true
        );
    }
}
