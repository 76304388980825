import {ROUTE_CONDITION_SCHEMA, RouteCondition} from '@nirby/models/nirby-player';
import {RouteData, ROUTES_MIGRATOR} from './routing';
import {StripeProductMetadata, StripeProductPrice} from '@nirby/models/billing';
import {z} from 'zod';
import {Migrator} from '@nirby/store/migrator';
import {Logger} from '@nirby/logger';
import {NirbyTimestamp, ZodSchemaBuild} from '@nirby/store/models';
import {buildUploadedReferenceSchema, TIMESTAMP_SCHEMA} from '@nirby/store/proxy';
import {NirbyDocumentReference} from '@nirby/store/base';

export interface RouteDisplay {
    routePath: NirbyDocumentReference<RouteData>;
    route: string | RouteCondition;
}

export const ROUTE_DISPLAY_SCHEMA = z.object({
    routePath: buildUploadedReferenceSchema<RouteData>(() => ROUTES_MIGRATOR),
    route: z.union([z.string(), ROUTE_CONDITION_SCHEMA]).default('/'),
});

export interface WidgetFeaturesFull {
    'prime-points': {
        /**@deprecated**/
        version?: string;
        /**@deprecated**/
        options?: object;
    };
    vimeo: object;
}

export type WorkspaceFeatures = Partial<WidgetFeaturesFull>;

export interface WorkspaceSubscription {
    planId: string;
    planName: string;
    subscriptionId: string;
    billingPeriod: [NirbyTimestamp, NirbyTimestamp];
    anchorDay: number;
    productMetadata: Partial<StripeProductMetadata>;
    interval: StripeProductPrice['interval'];
    intervalCount: StripeProductPrice['interval_count'];
    currency: StripeProductPrice['currency'];
    unitAmount: StripeProductPrice['unit_amount'];
    priceId: string;
}

export const WORKSPACE_SUBSCRIPTION_SCHEMA: ZodSchemaBuild<WorkspaceSubscription> =
    z
        .object({
            planId: z.string(),
            planName: z.string(),
            subscriptionId: z.string(),
            billingPeriod: z.tuple([TIMESTAMP_SCHEMA, TIMESTAMP_SCHEMA]),
            anchorDay: z.number(),
            productMetadata: z.custom<Partial<StripeProductMetadata>>(),
            interval: z.union([
                z.literal('day'),
                z.literal('month'),
                z.literal('week'),
                z.literal('year'),
            ]),
            intervalCount: z.number(),
            currency: z.string(),
            unitAmount: z.number().nullable(),
            priceId: z.string(),
        })
        .describe('Workspace Subscription');

export interface WorkspaceLimits {
    videoStorageMinutes: number;
    videoUploadMinutes: number;
    videoDeliveryMinutes: number;
}

export interface Workspace {
    urlName: string;
    url: string | null;
    /**
     * Owner's plan
     * @deprecated Now plans are per workspace.
     */
    ownerPlan?: string;
    installedPlugins: WorkspaceFeatures;

    priorityList: RouteDisplay[];
    plan: WorkspaceSubscription | null;
    _deleted: boolean;
}

export const WORKSPACE_SCHEMA: ZodSchemaBuild<Workspace> = z
    .object({
        urlName: z.string().default('Untitled'),
        url: z.string().nullable().default(null),
        ownerPlan: z.string().optional(),
        installedPlugins: z.record(z.unknown()).default({}),
        priorityList: z.array(ROUTE_DISPLAY_SCHEMA).default([]),
        plan: WORKSPACE_SUBSCRIPTION_SCHEMA.nullable()
            .default(null)
            .catch(() => {
                Logger.warn('Workspace plan is not valid');
                return null;
            }),
        _deleted: z.boolean().default(false),
    })
    .describe('Workspace');

export const WORKSPACE_MIGRATOR = new Migrator<Workspace>(
    WORKSPACE_SCHEMA,
    () => ({
        installedPlugins: {},
        ownerPlan: 'FREE',
        priorityList: [],
        url: '',
        urlName: '',
        plan: null,
        _deleted: false,
    }),
);
