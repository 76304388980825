import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    ButtonContentEditorComponent,
} from './components/blocks/button/button-content-editor/button-content-editor.component';
import {ButtonStyleEditorComponent} from './components/blocks/button/button-style-editor/button-style-editor.component';
import {IconContentComponent} from './components/blocks/icon/icon-content/icon-content.component';
import {IconStyleComponent} from './components/blocks/icon/icon-style/icon-style.component';
import {BlockImageContentComponent} from './components/blocks/image/block-image-content/block-image-content.component';
import {BlockImageStyleComponent} from './components/blocks/image/block-image-style/block-image-style.component';
import {
    BlockQuestionContentComponent,
} from './components/blocks/question/block-question-content/block-question-content.component';
import {
    BlockRectangleContentComponent,
} from './components/blocks/rectangle/rectangle-content/block-rectangle-content.component';
import {
    BlockRectangleStyleComponent,
} from './components/blocks/rectangle/block-rectangle-style/block-rectangle-style.component';
import {
    SliderBlockContentComponent,
} from './components/blocks/slider/slider-block-content/slider-block-content.component';
import {BlockTextContentComponent} from './components/blocks/text/text-block-content/block-text-content.component';
import {TextBlockStyleComponent} from './components/blocks/text/text-block-style/text-block-style.component';
import {BlockVideoContentComponent} from './components/blocks/video/block-video-content/block-video-content.component';
import {HostDirective} from './components/block-editor/directives/host.directive';
import {BlockEditorComponent} from './components/block-editor/block-editor.component';
import {ActionTrackEventComponent} from './components/actions/action-track-event/action-track-event.component';
import {ActionInputComponent} from '../routes/editor-cards/components/action-input/action-input.component';
import {ActionGoToCardComponent} from './components/actions/action-go-to-card/action-go-to-card.component';
import {ActionTriggerDomComponent} from './components/actions/action-trigger-dom/action-trigger-dom.component';
import {
    ActionRequestContactInfoComponent,
} from './components/actions/action-request-contact-info/action-request-contact-info.component';
import {ActionVideoLinkComponent} from './components/actions/action-video-link/action-video-link.component';
import {ActionGoToUrlComponent} from './components/actions/action-go-to-url/action-go-to-url.component';
import {
    ActionVariableUpdateComponent,
} from './components/actions/action-variable-update/action-variable-update.component';
import {ActionGenericEditorComponent} from './components/action-generic-editor/action-generic-editor.component';
import {NgbNavModule, NgbTimepickerModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {BlockContentHostDirective} from './components/block-editor/directives/block-content-host.directive';
import {BlockStyleHostDirective} from './components/block-editor/directives/block-style-host.directive';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PositioningGridComponent} from './components/positioning-grid/positioning-grid.component';
import {NgxSelectModule} from 'ngx-select-ex';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {BlockSelectorComponent} from './components/block-selector/block-selector.component';
import {BlockSelectorItemSimpleComponent} from './components/block-selector-item/block-selector-item-simple.component';
import {TooltipModule} from 'primeng/tooltip';
import {BlockActionHostDirective} from './components/block-editor/directives/block-action-host.directive';
import {DropdownModule} from 'primeng/dropdown';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {BlockSelectorCardMenuComponent} from './components/block-selector-card-menu/block-selector-card-menu.component';
import {DialogModule} from 'primeng/dialog';
import {ActionGoToPrimeComponent} from './components/actions/action-go-to-prime/action-go-to-prime.component';
import {ActionGoBackComponent} from './components/actions/action-go-back/action-go-back.component';
import {DropdownFilterModule, NirbyCommonModule, PopCommonModule} from '@nirby/shared/widgets';
import {MediaVideoSelectModule} from '@nirby/media/video-select';
import {ConditionalEditorModule} from '@nirby/shared/conditional-editor';
import {MediaLibraryModule} from '../modules/media-library/media-library.module';
import {RouterLink} from '@angular/router';

@NgModule({
    declarations: [
        ButtonContentEditorComponent,
        ButtonStyleEditorComponent,
        IconContentComponent,
        IconStyleComponent,
        BlockEditorComponent,
        BlockImageContentComponent,
        BlockImageStyleComponent,
        BlockQuestionContentComponent,
        BlockRectangleContentComponent,
        BlockRectangleStyleComponent,
        SliderBlockContentComponent,
        BlockTextContentComponent,
        TextBlockStyleComponent,
        BlockVideoContentComponent,
        ActionVideoLinkComponent,
        ActionGenericEditorComponent,
        ActionInputComponent,
        ActionGoToUrlComponent,
        ActionGoToCardComponent,
        ActionTriggerDomComponent,
        ActionVariableUpdateComponent,
        ActionVideoLinkComponent,
        ActionRequestContactInfoComponent,
        ActionTrackEventComponent,
        HostDirective,
        PositioningGridComponent,
        BlockSelectorComponent,
        BlockSelectorItemSimpleComponent,
        BlockContentHostDirective,
        BlockStyleHostDirective,
        BlockActionHostDirective,
        BlockSelectorCardMenuComponent,
        ActionGoToPrimeComponent,
        ActionGoBackComponent,
    ],
    exports: [BlockEditorComponent, BlockSelectorComponent, BlockActionHostDirective],
    imports: [
        CommonModule,
        NgbNavModule,
        ReactiveFormsModule,
        FormsModule,
        PopCommonModule,
        NirbyCommonModule,
        NgxSelectModule,
        NgbTimepickerModule,
        FontAwesomeModule,
        TooltipModule,
        DropdownModule,
        OverlayPanelModule,
        DialogModule,
        NgbTooltipModule,
        DropdownFilterModule,
        ConditionalEditorModule,
        MediaVideoSelectModule,
        MediaLibraryModule,
        RouterLink,
    ],
})
/**
 * The editor module.
 */
export class EditorModule {
}
