<div class="range mb-3" *ngIf="availableOptions">
    <input
        type="range"
        class="form-range"
        [min]="0"
        [max]="availableOptions.length - 1"
        [step]="1"
        [ngModel]="selectedIndex"
        (ngModelChange)="update($event)"
    />
</div>
