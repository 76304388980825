import {Injectable, OnDestroy} from '@angular/core';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, startWith, switchMap} from 'rxjs/operators';
import {ActivatedRoute, Data, NavigationEnd, Params, Router} from '@angular/router';
import {distinctArray, shareReplaySafe} from '@nirby/ngutils';
import {AppError} from '@nirby/js-utils/errors';

@Injectable({
    providedIn: 'root',
})
export class RouteParametersService implements OnDestroy {
    get workspaceId(): string {
        return this.getAggregatedParamsSnap<{ widgetId: string }>(this.route)
            .widgetId;
    }

    get popId(): string {
        return this.getAggregatedParamsSnap<{ popId: string }>(this.route)
            .popId;
    }

    get primeId(): string {
        return this.getAggregatedParamsSnap<{ primeId: string }>(this.route)
            .primeId;
    }

    private subscription: Subscription = new Subscription();

    snapParams: Params = {};

    popId$: Observable<string>;

    get widgetId$(): Observable<string> {
        return this.watchParamSafe<string>('widgetId').pipe(
            distinctUntilChanged(),
        );
    }

    widgetPopId$: Observable<[string, string]>;

    url$: Observable<string>;

    aggregatedParams$: Observable<Params>;
    aggregatedData$: Observable<Data>;

    constructor(private router: Router, private route: ActivatedRoute) {
        this.url$ = this.router.events.pipe(
            startWith(
                new NavigationEnd(
                    -1,
                    this.route.snapshot.url.join('/'),
                    this.route.snapshot.url.join('/'),
                ),
            ),
            filter((event) => event instanceof NavigationEnd),
            map((evt) => (evt as NavigationEnd).urlAfterRedirects),
            shareReplaySafe(),
        );
        this.aggregatedParams$ = this.url$.pipe(
            switchMap(() => {
                const allRouteParams: Observable<Params>[] = [];

                let child: ActivatedRoute | null = this.route;
                while (child?.parent) {
                    child = child.parent;
                }

                do {
                    allRouteParams.push(child.params);
                    child = child.firstChild;
                } while (child);
                return combineLatest(allRouteParams);
            }),
            map((childrenData: Params[]) => {
                return Object.assign({}, ...childrenData);
            }),
        );
        this.aggregatedData$ = this.url$.pipe(
            switchMap(() => {
                const allRouteData: Observable<Data>[] = [];

                let child: ActivatedRoute | null = this.route;
                while (child?.parent) {
                    child = child.parent;
                }

                do {
                    allRouteData.push(child.data);
                    child = child.firstChild;
                } while (child);
                return combineLatest(allRouteData);
            }),
            map((childrenData: Data[]) => Object.assign({}, ...childrenData)),
        );
        this.popId$ = this.watchParamSafe<string>('popId');
        this.widgetPopId$ = this.watchMany(['widgetId', 'popId']).pipe(
            filter(([a, b]) => !!a && !!b),
            map((ids) => ids as [string, string]),
            shareReplaySafe(),
        );
        this.subscription.add(
            this.aggregatedParams$.subscribe((params) => {
                this.snapParams = params;
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getParam(key: string): string | null {
        return this.getAggregatedParamsSnap<any>(this.route)[key] ?? null;
    }

    getParamSafe(key: string): string {
        const param = this.getParam(key);
        if (!param) {
            throw new AppError(`No param with name: ${key}`);
        }
        return param;
    }

    watchManyObject(
        params: string[],
        data: string[] = [],
    ): Observable<Record<string, string | boolean | number | null>> {
        return combineLatest([
            ...params.map((key) => this.watchParam<string>(key)),
            ...data.map((key) => this.watchData<string>(key)),
        ]).pipe(
            distinctArray(),
            map<(string | null)[], Record<string, string | null>>((values) => {
                const keys: string[] = [...[...params, ...data]];
                const obj: Record<string, string | null> = {};
                for (let i = 0; i < keys.length; i++) {
                    const key: string = keys[i];
                    obj[key] = values[i];
                }
                return obj;
            }),
            debounceTime(100),
        );
    }

    watchMany(
        params: string[],
        data: string[] = [],
    ): Observable<(string | null)[]> {
        return combineLatest([
            ...params.map((key) => this.watchParam<string>(key)),
            ...data.map((key) => this.watchData<string>(key)),
        ]).pipe(distinctArray(), debounceTime(100));
    }

    watchParamSafe<T extends string>(key: string): Observable<T> {
        return this.watchParam<T>(key).pipe(
            filter((v) => !!v),
            map((v) => v as T),
        );
    }

    watchParam<T>(key: string): Observable<T | null> {
        return this.aggregatedParams$.pipe(
            map((params) => params[key] ?? null),
            debounceTime(10),
            distinctUntilChanged(),
        );
    }

    watchData<T>(key: string): Observable<T> {
        return this.aggregatedData$.pipe(
            map((data) => data[key] ?? null),
            debounceTime(10),
        );
    }

    allRoutesSnapshot(route: ActivatedRoute): ActivatedRoute[] {
        const allRouteData: ActivatedRoute[] = [];

        let child: ActivatedRoute | null = route;
        while (child?.parent) {
            child = child.parent;
        }

        do {
            allRouteData.push(child);
            child = child.firstChild;
        } while (child);
        return allRouteData;
    }

    allRoutes(route: ActivatedRoute): Observable<ActivatedRoute[]> {
        return this.url$.pipe(
            map(() => {
                const allRouteData: ActivatedRoute[] = [];

                let child: ActivatedRoute | null = route;
                while (child?.parent) {
                    child = child.parent;
                }

                do {
                    allRouteData.push(child);
                    child = child.firstChild;
                } while (child);
                return allRouteData;
            }),
        );
    }

    getAggregatedParamsSnap<T extends object>(_: ActivatedRoute): T {
        return this.snapParams as T;
    }

    getDeepestData<T>(
        _: ActivatedRoute,
        key: string,
        defaultValue: T,
    ): Observable<T> {
        return this.aggregatedData$.pipe(
            map((data) => data[key] ?? defaultValue),
        );
    }
}
