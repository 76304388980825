import {Component} from '@angular/core';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'nirby-routes-empty',
    templateUrl: './routes-empty.component.html',
    styleUrls: ['./routes-empty.component.scss'],
})
export class RoutesEmptyComponent {
    iconArrowLeft = faArrowLeft;
}
