import {Component, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {dateStampToMs, msToDateStamp, PickerTimestamp} from '@nirby/js-utils/math';
import {VoidFunction} from "@nirby/js-utils/types";

@Component({
    selector: 'nirby-video-timestamp-picker',
    templateUrl: './video-timestamp-picker.component.html',
    styleUrls: ['./video-timestamp-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => VideoTimestampPickerComponent),
            multi: true,
        },
    ],
})
export class VideoTimestampPickerComponent implements ControlValueAccessor {
    value: number | null = null;
    minute = 0;
    second = 0;
    disabled = false;

    picking = false;
    selectedMoment: Partial<PickerTimestamp> = {};

    onChange: VoidFunction<number> = () => {
        return;
    };
    onTouched: VoidFunction<number> = () => {
        return;
    };

    registerOnChange(fn: VoidFunction<number>): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: VoidFunction<number>): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: number | null): void {
        this.selectedMoment = msToDateStamp(obj ?? 0);
    }

    pick(date: PickerTimestamp): void {
        this.selectedMoment = date;
        this.onChange(dateStampToMs(this.selectedMoment));
    }
}
