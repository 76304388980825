import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
    dateStampToMs,
    msToDateStamp,
    PickerTimestamp,
} from '@nirby/js-utils/math';
import {VoidFunction} from "@nirby/js-utils/types";

@Component({
    selector: 'nirby-time-range-picker',
    templateUrl: './time-range-picker.component.html',
    styleUrls: ['./time-range-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimeRangePickerComponent),
            multi: true,
        },
    ],
})
export class TimeRangePickerComponent implements ControlValueAccessor {
    disabled = false;
    start: Partial<PickerTimestamp> = {};
    end: Partial<PickerTimestamp> = {};

    onChange: VoidFunction<[number, number]> = () => {
        return;
    };
    onTouched: VoidFunction<void> = () => {
        return;
    };

    registerOnChange(fn: VoidFunction<[number, number]>): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: VoidFunction<void>): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: [number, number] | null): void {
        obj = obj ?? [0, 0];
        this.start = msToDateStamp(obj[0]);
        this.end = msToDateStamp(obj[1]);
    }

    updateStart(value: Partial<PickerTimestamp>): void {
        const aMs = dateStampToMs(value);
        const bMs = dateStampToMs(this.end);
        if (bMs < aMs) {
            [this.start, this.end] = [value, value];
        } else {
            this.start = value;
        }
    }

    updateEnd(value: Partial<PickerTimestamp>): void {
        const aMs = dateStampToMs(this.start);
        const bMs = dateStampToMs(value);
        if (bMs < aMs) {
            [this.start, this.end] = [value, value];
        } else {
            this.end = value;
        }
    }

    onOpenChange(event: boolean): void {
        if (!event) {
            const start = dateStampToMs(this.start);
            const end = dateStampToMs(this.end);
            this.onChange([Math.min(start, end), Math.max(start, end)]);
        }
    }
}
