<div class="form-group mb-2">
    <label for="action-type" class="d-block">Select an Action</label>
    <select
        id="action-type"
        class="form-control"
        [ngModel]="value ? value.type : null"
        (ngModelChange)="onChangeType($event)"
        [disabled]="disabled"
    >
        <option *ngFor="let action of availableActions" [ngValue]="action.type">
            {{ action.label }}
        </option>
    </select>
    <div class="form-group-option pl-3 mt-2">
        <ng-template
            nirbyBlockActionHost
            [ngModel]="value"
            (ngModelChange)="value = $event; onChange($event)"
            (closeRequest)="close()"
        ></ng-template>
    </div>
</div>
