import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoUploadTargetComponent } from './video-upload-target/video-upload-target.component';
import {FormsModule} from '@angular/forms';

@NgModule({
    declarations: [
        VideoUploadTargetComponent,
    ],
    imports: [CommonModule, FormsModule],
    exports: [
        VideoUploadTargetComponent
    ]
})
export class VideoUploadModule {}
