import {Component, EventEmitter, Output} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {AppCard, Pop, Prime} from '@nirby/models/editor';
import {CardsService, ProductsListService} from '@nirby/shared/database';
import {map, startWith} from 'rxjs/operators';
import {faEye, faFile, faLayerGroup, faPlus} from '@fortawesome/free-solid-svg-icons';
import {BlockSelectorService} from '../../services/block-selector.service';
import {NirbyDocument, NirbyDocumentReference} from '@nirby/store/base';
import {SelectItemGroup} from 'primeng/api';
import {UntypedFormControl} from '@angular/forms';
import {Card} from '@nirby/models/nirby-player';

@Component({
    selector: 'nirby-block-selector-card-menu',
    templateUrl: './block-selector-card-menu.component.html',
    styleUrls: ['./block-selector-card-menu.component.scss'],
})
/**
 * Menu for card selection. Has two options:
 * - New: Will create a new card "From Scratch"
 * - Library: Will open the library of cards to select from
 */
export class BlockSelectorCardMenuComponent {
    cardPreviewUrl: string | null = null;

    public readonly products$: Observable<SelectItemGroup[]> =
        this.products.workspaceProducts$.pipe(
            map((products): SelectItemGroup[] => [
                {
                    label: 'Pop',
                    items: products.pop.map((product) => ({
                        label: product.data.emoji
                            ? `${product.data.emoji} ${product.data.title}`
                            : product.data.title,
                        value: product.ref,
                    })),
                },
                {
                    label: 'Prime',
                    items: products.prime.map((product) => ({
                        label: product.data.name,
                        value: product.ref,
                    })),
                },
            ]),
        );

    showModal = false;
    icons = {
        fromScratch: faFile,
        fromLibrary: faLayerGroup,
        add: faPlus,
        preview: faEye,
    };
    productControl = new UntypedFormControl(null);

    private readonly productRef$: Observable<
        NirbyDocumentReference<Prime | Pop>
    > = this.productControl.valueChanges.pipe(
        startWith(this.productControl.value),
    );

    public readonly cards$: Observable<NirbyDocument<AppCard>[]> =
        combineLatest([
            this.cards.currentWorkspaceCards$,
            this.productRef$,
        ]).pipe(
            map(([cards, productRef]) =>
                cards.filter(
                    (card) => card.data.usedBy?.path === productRef?.path,
                ),
            ),
        );

    @Output() public readonly selectCard = new EventEmitter<Card>();

    /**
     * Constructor.
     * @param products - Products service
     * @param cards - Cards service
     * @param blockSelector - Block selector service
     */
    constructor(
        private readonly products: ProductsListService,
        private readonly cards: CardsService,
        private readonly blockSelector: BlockSelectorService,
    ) {
    }

    /**
     * Creates a new card from scratch.
     */
    createFromScratch(): void {
        this.blockSelector.selectInstant('art-board');
    }

    /**
     * Previews a card
     * @param card The card to preview
     */
    async preview(card: Card): Promise<void> {
        this.cardPreviewUrl = await this.cards.createPreview(card);
    }

    /**
     * Updates a card title
     * @param newTitle New title
     * @param card Card to update
     */
    async updateCardTitle(
        newTitle: string,
        card: NirbyDocument<AppCard>,
    ): Promise<void> {
        await this.cards.collection(card.parentId).update(card.id, {
            title: newTitle,
        });
    }

    /**
     * Emits a signal to use the selected card.
     * @param originalCard The card to use.
     */
    use(originalCard: Card): void {
        const card = CardsService.copyCard(originalCard);
        this.blockSelector.selectInstant('art-board', card);
        this.showModal = false;
    }

    /**
     * Opens the card library to select a card.
     */
    openModal(): void {
        this.showModal = true;
        this.cardPreviewUrl = null;
        this.productControl.setValue(null);
    }
}
