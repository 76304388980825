import {Injectable} from '@angular/core';
import {catchError, tap} from 'rxjs/operators';
import {MonoTypeOperatorFunction, throwError} from 'rxjs';
import {NotFoundError} from '@nirby/js-utils/errors';
import {Logger} from '@nirby/logger';

export type LogTag =
    | 'DB'
    | 'ROUTE-PARAMS'
    | 'BLOCK-EDITOR'
    | 'ACTION-INPUT'
    | 'LOGGER'
    | 'MIGRATION'
    | 'CAMPAIGN'
    | string;

@Injectable({
    providedIn: 'root',
})
export class LoggerService {
    public static errorStyled(tag: LogTag, ...message: unknown[]): void {
        Logger.errorStyled(tag, ...message);
    }

    public static warnStyled(tag: LogTag, ...message: unknown[]): void {
        Logger.warnStyled(tag, ...message);
    }

    public static logStyled(tag: LogTag, ...message: unknown[]): void {
        Logger.logStyled(tag, ...message);
    }

    public static errorOp(category: string, method: string, data?: any): any {
        return catchError<any, any>((err) => {
            if (!(err instanceof NotFoundError)) {
                Logger.errorStandard(category, method, data);
                Logger.error(err);
            } else {
                Logger.logAt(category + ':NOT-FOUND', method, data);
            }
            return throwError(err);
        });
    }

    public static logOp<T>(
        category: string,
        log: string
    ): MonoTypeOperatorFunction<T> {
        return tap<T>((s) => Logger.logAt(category, log, s));
    }

    public log(...message: any[]): void {
        Logger.log(...message);
    }

    public logAt(category: LogTag, ...message: any[]): void {
        Logger.logAt(category, ...message);
    }

    public warn(...message: any[]): void {
        Logger.warn(...message);
    }

    public errorStandard(category: string, method: string, data?: any): void {
        Logger.errorStandard(category, method, data);
    }

    public error(...message: any[]): void {
        Logger.error(...message);
    }
}
