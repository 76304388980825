import {ZodSchemaBuild} from '@nirby/store/models';

export type NirbyFieldValue = string | number | boolean | Date | null | object;


export type Constructable = { new(...args: unknown[]): unknown };
export type IgnoreReplace = ((...args: unknown[]) => unknown) | Constructable;

export type ReplaceDeep<T, Target, Replacement> =
    T extends Target ? Replacement :
        T extends IgnoreReplace ?
            T :
            T extends object ?
                { [K in keyof T]: ReplaceDeep<T[K], Target, Replacement> } :
                T;


/**
 * Will replace in the {@link source} all the values that match the {@link conditionSchema} and replace them with the
 * return value of the {@link conditionSchema}.
 *
 * @param source The source object to replace the values in.
 * @param conditionSchema The schema that will be used to match the values to replace.
 *
 * @returns The source object with the replaced values.
 */
export function replaceDeep<Target, Replacement, T>(
    source: T,
    conditionSchema: ZodSchemaBuild<Replacement, Target>,
): ReplaceDeep<T, Target, Replacement> {
    if (typeof source !== 'string' && Array.isArray(source)) {
        return source.map((v) => replaceDeep(v, conditionSchema)) as ReplaceDeep<T, Target, Replacement>;
    }
    // check if object comes from a class
    if (typeof source === 'object' && source !== null) {
        if (!source || !source.constructor || source.constructor.name === 'Object') {
            return Object.entries(source).reduce(
                (acc, [key, value]) => ({
                    ...acc,
                    [key]: replaceDeep(value, conditionSchema),
                }), {},
            ) as ReplaceDeep<T, Target, Replacement>;
        }
    }
    // check if object is a value that matches the conditionSchema
    const parsed = conditionSchema.safeParse(source);
    if (parsed.success) {
        return parsed.data as ReplaceDeep<T, Target, Replacement>;
    }
    // return the value as is
    return source as ReplaceDeep<T, Target, Replacement>;
}

