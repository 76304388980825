<div
    *ngIf="{ variables: variables$ | async, form: form$ | async } as data"
    class="d-flex flex-column w-100"
>
    <form [formGroup]="form.form" *ngIf="data.form as form">
        <div *ngFor="let control of form.controls" class="flex-column mb-2">
            <div class="variable-item mb-1 d-flex flex-row align-items-center">
                <span class="flex-1">
                    {{ control.label }}
                </span>
                <span class="badge badge-light ms-2">
                    <code
                        >{{ '{' + '{' }}{{ control.label }}{{ '}' + '}' }}</code
                    >
                </span>
                <button
                    class="btn p-0 text-light pl-2 pr-2"
                    (click)="confirmDeletion($event, control.doc.ref)"
                >
                    <fa-icon [icon]="icons.delete"></fa-icon>
                </button>
            </div>
            <div class="d-flex flex-row">
                <div>
                    <p-inputSwitch
                        [formControl]="control.controlEnabled"
                        (ngModelChange)="onEnabledChange(control)"
                    >
                        ></p-inputSwitch
                    >
                </div>
                <div class="form-group flex-fill">
                    <label for="variable-initial-value" class="sr-only">
                        Initial value
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        id="variable-initial-value"
                        placeholder="Initial value"
                        [formControl]="control.control"
                        (ngModelChange)="onValueChange(control)"
                    />
                </div>
            </div>
        </div>
    </form>

    <small class="mt-1 text-muted">Create new variable</small>
    <span class="p-input-icon-right variable-item variable-item-input">
        <i class="pi pi-plus"></i>
        <input
            type="text"
            pInputText
            placeholder="Type and press Enter..."
            [formControl]="newVariableControl"
            #input
            (beforeinput)="beforeInput($event, input)"
        />
    </span>

    <p-divider></p-divider>

    <div class="text-muted mt-2">
        <small>
            Some considerations:
            <ul>
                <li>
                    Only <b>alphanumeric</b> characters, <b>underscores</b> and
                    <b>dashes</b> are allowed.
                </li>
                <li>The first character must be a <b>letter</b>.</li>
                <li>The name cannot be the same as an existing variable.</li>
                <li>
                    Variable names are
                    <a
                        href="https://www.computerhope.com/jargon/c/casesens.htm"
                        target="_blank"
                        >case-sensitive</a
                    >.
                </li>
            </ul>
        </small>
    </div>
</div>
