import {Injectable} from '@angular/core';

export interface Icon {
    label: string;
    search: {
        terms?: string[];
    };
    unicode: string;
    free: string[];
}

@Injectable({
    providedIn: 'root',
})
export class IconService {

    async search(query: string, limit = 10): Promise<Icon[]> {
        let iconName;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const icons: Record<string, Icon> = await import('../forms/assets/icons.json');
        const re = new RegExp(query);
        const results: Icon[] = [];
        for (iconName in icons) {
            const icon = icons[iconName];
            let term;
            if (icon.search.terms) {
                for (term of icon.search.terms) {
                    if (term.search(re) > -1) {
                        results.push(icon);
                        break;
                    }
                }
            }
            if (results.length >= limit) {
                break;
            }
        }
        return results.map((result) => ({
            label: result.label,
            search: {
                terms: result.search.terms,
            },
            unicode: String.fromCharCode(parseInt(result.unicode, 16)),
            free: result.free.map((f) => f),
        }));
    }
}
