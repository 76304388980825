<div class="record-target d-flex flex-column justify-content-center align-items-center p-2"
     *ngIf="uploadStatus$ | async as status">
    <ng-container *ngIf="initializing">
        <ng-container *ngIf="!recorder">
            <p class="text-center">
                Please allow access to your microphone and camera to start recording.
            </p>
            <button (click)="loadRecorder()">
                Load
            </button>
        </ng-container>
        <ng-container *ngIf="recorder">
            <ng-container *ngIf="lastResultUrl$ | async as previewUrl; else view">
                <video [src]="previewUrl" controls></video>
                <div class="d-flex flex-row" *ngIf="status === 'idle' || status === 'error'">
                    <button class="btn" (click)="recorder.clear()">
                        <span class="me-2">Redo</span>
                        <fa-icon [icon]="icons.restart"></fa-icon>
                    </button>
                    <button class="btn" (click)="uploadResult()">
                        <span class="me-2">Upload</span>
                        <fa-icon [icon]="icons.upload"></fa-icon>
                    </button>
                </div>
                <p *ngIf="status === 'progress'">
                    Uploading...
                </p>
                <p *ngIf="status === 'success'">
                    Uploaded!
                </p>
                <p *ngIf="status === 'error'">
                    Something went wrong
                </p>
            </ng-container>
            <ng-template #view>
                <video class="w-100 mb-2 record-preview" autoplay [srcObject]="recorder.videoOnlyStream">
                </video>
                <div class="d-flex flex-column align-items-stretch">
                    <ng-container *ngIf="!recorder.inProgress">
                        <button class="btn" (click)="recorder.start()" [disabled]="status === 'progress'">
                            <span class="me-2">Start</span>
                            <fa-icon [icon]="icons.record"></fa-icon>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="recorder.inProgress">
                        <div class="d-flex flex-row justify-content-center">
                            <button class="btn" (click)="recorder.stop()"
                                    [disabled]="status === 'progress'">
                                <span class="me-2">Finish</span>
                                <fa-icon [icon]="icons.stop"></fa-icon>
                            </button>
                            <button class="btn" (click)="recorder.pause()" *ngIf="!recorder.paused">
                                <span class="me-2">Pause</span>
                                <fa-icon [icon]="icons.pause"></fa-icon>
                            </button>
                            <button class="btn" (click)="recorder.resume()" *ngIf="recorder.paused">
                                <span class="me-2">Resume</span>
                                <fa-icon [icon]="icons.resume"></fa-icon>
                            </button>
                        </div>
                    </ng-container>
                    <div class="d-flex flex-row justify-content-center">
                        <button class="btn" (click)="selectVideo.emit(null)" [disabled]="status === 'progress'">
                            <span class="me-2">Cancel</span>
                            <fa-icon [icon]="icons.cancel"></fa-icon>
                        </button>
                    </div>
                    <span class="recording-label text-center" [ngClass]="{blinking: recorder.recording}">
                        <ng-container *ngIf="recorder.recording">Recording</ng-container>&nbsp;
                        <ng-container *ngIf="recorder.paused" >Paused</ng-container>&nbsp;
                    </span>
                </div>
            </ng-template>
        </ng-container>
    </ng-container>
</div>
