<ng-container *ngIf="{currentPlan: currentPlan$ | async, currentSubscription: currentSubscription$ | async} as billing">
    <p *ngIf="billing.currentPlan">
        Current plan: {{ billing.currentPlan.data.name }}
    </p>
    <div class="d-flex flex-column w-100 align-items-stretch">
        <h2>Plans</h2>
        <div class="d-flex flex-row justify-content-center mb-3">
            <div class="btn-group btn-group-pricing">
                <button class="btn btn-light billing-interval-btn" [ngClass]="{active: interval === 'month'}"
                        (click)="interval = 'month'">
                    <strong>Monthly</strong>
                </button>
                <button class="btn btn-light billing-interval-btn" [ngClass]="{active: interval === 'year'}"
                        (click)="interval = 'year'">
                    <strong>Annual</strong> (20% OFF)
                </button>
            </div>
        </div>
        <div class="d-flex flex-row plan-table justify-content-around">
            <article
                class="list-item list-item-plan mx-3 d-flex flex-column justify-content-stretch mb-4 mb-lg-0 flex-1"
                *ngFor="let group of groups"
            >
                <nirby-plan-table-item
                    [interval]="interval"
                    [planGroup]="group.nirbyPlanGroup"
                    [label]="group.label"
                    [features]="group.features"
                    [tableStyle]="group.style"
                    [currentSubscription]="billing.currentSubscription"
                ></nirby-plan-table-item>
            </article>
        </div>
    </div>
</ng-container>
