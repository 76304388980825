<div class="form-group">
    <label for="icon-search">Icon</label>
    <input
        [autocomplete]="'off'"
        placeholder="Search icon..."
        id="icon-search"
        class="form-control"
        name="query"
        (input)="onQueryChange($event.target)"
    />
    <div class="icon-preview" *ngIf="icon">
        <span class="fa-icon">{{ icon }}</span>
    </div>
</div>
<div class="form-group">
    <button
        class="icon-container btn btn-link btn-sm"
        *ngFor="let icon of searchResults"
        (click)="selectIcon(icon)"
    >
        <span class="fa-icon">{{ icon.unicode }}</span>
    </button>
</div>
