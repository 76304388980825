import {Component} from '@angular/core';
import {LoadScreenService} from '../load-screen.service';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'nirby-load-toast',
    templateUrl: './load-toast.component.html',
    styleUrls: ['./load-toast.component.scss'],
    animations: [
        trigger('bounce', [
            transition(':enter', [
                style({transform: 'translateY(100%)', opacity: 0}),
                animate('100ms ease-in', style({transform: 'translateY(0%) scale(1)', opacity: 1})),
                // bounce
                animate('50ms ease-in', style({transform: 'translateY(-5%) scale(1.05, 0.95)', opacity: 1})),
                animate('50ms ease-in', style({transform: 'translateY(0%) scale(1)'}))
            ]),
            transition(':leave', [
                animate('200ms ease-in', style({transform: 'translateY(100%)', opacity: 0}))
            ])
        ])
    ]
})
/**
 * Load screen toast component
 */
export class LoadToastComponent {
    public readonly label$ = this.service.watchLoadingLabel('toast');

    /**
     * Constructor.
     * @param service The service to manage loading screens.
     */
    constructor(private service: LoadScreenService) {
    }
}
