/* eslint-disable */
import { Bounds, ShapeUnit } from './shape-unit';
import Konva from 'konva';
import { BlockContext, CursorType, ShapeUnitRawDescription } from './index';
import { BaseShapeUnitDescription } from '../unit.model';

export type ContainerUnit = BaseShapeUnitDescription<
    'Container',
    { cursor: CursorType }
>;

export class ContainerShapeUnit extends ShapeUnit<'Container'> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(
        id: string,
        context: BlockContext,
        rootGroup: Konva.Shape,
        children: ShapeUnit<any>[],
        editable: boolean,
        initialDescription: ShapeUnitRawDescription<'Container'>
    ) {
        super(id, context, rootGroup, children, editable, initialDescription);
    }

    defaultValues = {
        cursor: 'ignore',
    };

    override async onUpdate(): Promise<void> {}

    protected draw(
        ctx: CanvasRenderingContext2D,
        attributes: {},
        bounds: Bounds
    ): void {}
}
