import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'nirby-tutorial-widget',
    templateUrl: './tutorial-widget.component.html',
    styleUrls: ['./tutorial-widget.component.scss'],
})
export class TutorialWidgetComponent {
    @Input() bodyCard = '';
    @Input() titleCard = '';




}
