import {NirbyCollection, NirbyDocument, QueryBuilder} from '@nirby/store/base';
import {firstValueFrom} from 'rxjs';
import {BatchedUpdateTransaction} from './sync/batched-transaction';

/**
 * Chunks an array into smaller arrays.
 * @param array The array to chunk.
 * @param size The size of the chunks.
 *
 * @returns The chunks.
 */
export function chunkArray<T>(array: T[], size: number): T[][] {
    const chunks = [];
    for (let i = 0; i < array.length; i += size) {
        chunks.push(array.slice(i, i + size));
    }
    return chunks;
}


/**
 * Pulls the documents from the query into the destination collection.
 * @param query The query to pull from.
 * @param destination The destination collection.
 * @param transaction The transaction to use.
 *
 * @returns A promise with the pulled documents.
 */
export async function loadQueryIntoCollection<T extends object>(
    query: QueryBuilder<T> | NirbyCollection<T>,
    destination: NirbyCollection<T>,
    transaction: BatchedUpdateTransaction,
): Promise<NirbyDocument<T>[]> {
    if (query instanceof NirbyCollection) {
        query = query.query();
    }
    const documents = await firstValueFrom(query.get());
    const chunks = chunkArray(documents, 500);
    for (const chunk of chunks) {
        for (const document of chunk) {
            transaction.set(destination.ref(document.id), document.raw);
        }
    }
    return documents;
}
