<div class="p-3">
    <div
        class="images-grid"
        *ngIf="mediaItems$ | async as items; else loading"
    >
        <div class="alert alert-info flex-1 w-100" *ngIf="items.length === 0">
            <p class="mb-0">You haven't uploaded any file yet.</p>
        </div>

        <ng-container *ngFor="let media of items">
            <div
                class="item mb-2"
                (click)="picked.emit(media.content)"
            >
                <div class="thumb">
                    <img
                        [src]="media.content.lastUrl"
                        [alt]="media.path"
                        *ngIf="media.content.lastUrl"
                    />
                </div>
                <div class="actions">
                    <button
                        class="btn btn-link text-white"
                        (click)="
                        delete(media);
                        $event.preventDefault();
                        $event.stopPropagation()
                    "
                    >
                        <fa-icon [icon]="faDelete"></fa-icon>
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
    <ng-template #loading>
        <div class="spinner-border text-primary"></div>
    </ng-template>
</div>
