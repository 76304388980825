import {Inject, Injectable, Provider} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Auth, authState} from '@angular/fire/auth';
import {firstValueFrom, lastValueFrom} from 'rxjs';
import {NirbyPlayerConfig} from '@nirby/models/nirby-player';
import {first} from 'rxjs/operators';
import {CREATORS_ENVIRONMENT, NirbyEnvironment} from '@nirby/environment/base';
import {ApiService} from '@nirby/shared/api';
import {NIRBY_PLAYER_CONFIG} from '@nirby/shared/player-config';

export interface DecodedUserToken {
    aud: string,
    auth_time: number,
    user_id: string,
    sub: string,
}

@Injectable({
    providedIn: 'root',
})
/**
 * {@link ApiService} implementation that uses Firebase Auth to authenticate with the API.
 */
export class ApiJwtService extends ApiService {
    /**
     * Use on an Angular module to replace the API service with this one.
     *
     * @returns The provider for the API service.
     */
    public static provide(): Provider {
        return {
            provide: ApiService,
            useClass: ApiJwtService,
            deps: [CREATORS_ENVIRONMENT, HttpClient, NIRBY_PLAYER_CONFIG, Auth],
        };
    }

    /**
     * Gets the current JWT token.
     * @param forceRefresh Whether to force a refresh of the token.
     * @returns The current JWT token.
     */
    public async getJWT(forceRefresh = false): Promise<string | null> {
        const user = await firstValueFrom(authState(this.auth));
        if (!user) {
            return null;
        }
        return await user.getIdToken(forceRefresh);
    }

    /**
     * Constructor.
     * @param environment The environment.
     * @param http The HTTP client.
     * @param config The configuration for the service.
     * @param auth The Firebase Auth service.
     */
    constructor(
        @Inject(CREATORS_ENVIRONMENT) private environment: NirbyEnvironment,
        http: HttpClient,
        @Inject(NIRBY_PLAYER_CONFIG) config: NirbyPlayerConfig,
        private auth: Auth,
    ) {
        super(config, http);
    }

    /**
     * Gets the header with the JWT token.
     * @param key The key to use for the header.
     *
     * @returns The header with the JWT token.
     */
    override async getAuthorizationHeader(
        key: string | null = null,
    ): Promise<{ [key: string]: string }> {
        if (!key) {
            key = (await this.getJWT()) ?? null;
        } else {
            return {
                Authorization: key,
            };
        }
        if (!key) {
            return {};
        }
        if (!this.environment.production && this.environment.isLocalHost) {
            const user: DecodedUserToken | null = await lastValueFrom(authState(this.auth).pipe(first())).then(
                (user) => {
                    return user ? {
                        aud: this.environment.firebase.appId,
                        auth_time: 0,
                        sub: user.uid,
                        user_id: user.uid,
                    } : null;
                },
            );
            return {
                'X-Apigateway-Api-Userinfo': btoa(JSON.stringify(user)),
            };
        }
        return {
            Authorization: `Bearer ${key}`,
        };
    }
}
