import { Pipe, PipeTransform } from '@angular/core';
import { msToDateStamp, PickerTimestamp } from '@nirby/js-utils/math';

@Pipe({
    name: 'pickerTimestamp',
})
export class PickerTimestampPipe implements PipeTransform {
    transform(value: Partial<PickerTimestamp> | number | null): string {
        if (!value) {
            return '00:00';
        }
        if (typeof value === 'number') {
            value = msToDateStamp(value);
        }
        const hour = value.hour?.toString().padStart(2, '0') ?? '00';
        const minute = value.minute?.toString().padStart(2, '0') ?? '00';
        const second = value.second?.toString().padStart(2, '0') ?? '00';
        return `${hour}:${minute}:${second}`;
    }
}
