import {MigratorLike} from '@nirby/store/migrator';
import {QueryBuilder} from './query';
import {DatabaseService} from './database';

export const SCHEMA_VERSION = 7;

export interface LocalCollectionInfo<T extends object, SyncContext extends object = object> {
    dbSource: 'root' | 'workspace';
    collectionName: string;
    syncToRemote: boolean;
    syncGroup: boolean;
    mergeFn?: (local: T, remote: T) => T,
    syncQueryFn?: (query: QueryBuilder<T>, ctx: SyncContext, db: DatabaseService) => QueryBuilder<T>;
    transformPath?: (path: string, ctx: SyncContext) => string;
}

/**
 * An instruction to sync a collection.
 */
export class SyncInstruction<TSource extends object, SyncContext extends object = object> {
    /**
     * Constructor.
     * @param key The key for the collection
     * @param ctx The sync context
     */
    constructor(
        public readonly key: CollectionKey<TSource, SyncContext>,
        public readonly ctx: SyncContext,
    ) {
        if (!this.key.localCollectionInfo) {
            throw new Error(`Cannot sync collection ${this.key.remoteCollectionName} because it is not local.`);
        }
    }
}

/**
 * A key for a collection.
 */
export class CollectionKey<
    TSource extends object,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    SyncContext extends object = any
> {
    /**
     * Constructor.
     * @param remoteCollectionName The name of the collection
     * @param migrator The migrator for the collection
     * @param localCollectionInfo The name of the local collection
     */
    constructor(
        public readonly remoteCollectionName: string,
        public readonly migrator: MigratorLike<TSource>,
        public readonly localCollectionInfo: LocalCollectionInfo<TSource, SyncContext> | null = null,
    ) {
    }

    /**
     * The collection key for the local version of the collection.
     */
    public get local(): CollectionKey<TSource, SyncContext> | null {
        if (!this.localCollectionInfo) {
            return null;
        }
        return new CollectionKey<TSource, SyncContext>(
            this.localCollectionInfo.collectionName,
            this.migrator,
            this.localCollectionInfo,
        );
    }

    public prependDoc(doc: DocumentKey<object>): CollectionKey<TSource, SyncContext> {
        return new CollectionKey<TSource, SyncContext>(
            `${doc.path}/${this.remoteCollectionName}`,
            this.migrator,
            this.localCollectionInfo,
        );
    }

    /**
     * The collection key for the remote version of the collection.
     */
    public get remote(): CollectionKey<TSource, SyncContext> {
        return this;
    }

    /**
     * Gets a root collection key.
     * @param name The name of the collection.
     * @param migrator The migrator for the collection.
     * @param localName The name of the local collection. If null, the local collection is the same as the remote
     * @param syncQueryFn A function that takes a query builder and returns a query builder. This is used to add
     * additional filters to the query.
     * @param mergeFn A function that takes a local and remote document and returns a merged document.
     * @param syncGroup Whether or not to sync all the collection that share the same collection key.
     * @param syncToRemote Whether or not to sync the collection to the remote database.
     *
     * @returns The collection key.
     */
    public static root<T extends object, SyncContext extends object = object>(
        name: string,
        migrator: MigratorLike<T>,
        localName: string | null = null,
        syncQueryFn?: LocalCollectionInfo<T, SyncContext>['syncQueryFn'],
        mergeFn?: LocalCollectionInfo<T, SyncContext>['mergeFn'],
        syncGroup = false,
        syncToRemote = true,
    ): CollectionKey<T, SyncContext> {
        return new CollectionKey<T, SyncContext>(name, migrator, {
            dbSource: 'root',
            collectionName: localName ?? name,
            syncToRemote,
            syncGroup,
            syncQueryFn,
            mergeFn,
        });
    }

    /**
     * Gets a workspace collection key.
     * @param name The name of the collection.
     * @param migrator The migrator for the collection.
     * @param localName The name of the local collection. If null, the local collection is the same as the remote
     * collection.
     * @param syncQueryFn A function that takes a query builder and returns a query builder. This is used to add
     * additional filters to the query when syncing.
     * @param mergeFn A function that takes a local and remote document and returns a merged document.
     * @param syncGroup Whether or not to sync all the collection that share the same collection key.
     * @param syncToRemote Whether or not to sync the collection to the remote database.
     *
     * @returns The collection key.
     */
    public static workspace<T extends object, SyncContext extends object = object>(
        name: string,
        migrator: MigratorLike<T>,
        localName: string | null = null,
        syncQueryFn?: LocalCollectionInfo<T, SyncContext>['syncQueryFn'],
        mergeFn?: LocalCollectionInfo<T, SyncContext>['mergeFn'],
        syncGroup = false,
        syncToRemote = true,
    ): CollectionKey<T, SyncContext> {
        return new CollectionKey<T, SyncContext>(name, migrator, {
            dbSource: 'workspace',
            collectionName: localName ?? name,
            syncToRemote,
            syncQueryFn,
            mergeFn,
            syncGroup,
        });
    }

    /**
     * Gets a remote collection key.
     * @param name The name of the collection.
     * @param migrator The migrator for the collection.
     *
     * @returns The collection key.
     */
    public static remote<T extends object>(
        name: string,
        migrator: MigratorLike<T>,
    ): CollectionKey<T> {
        return new CollectionKey<T>(name, migrator);
    }

    /**
     * Gets a document key.
     * @param documentId The document ID.
     *
     * @returns The document key.
     */
    doc(documentId: string): DocumentKey<TSource, SyncContext> {
        return new DocumentKey<TSource, SyncContext>(this, documentId);
    }

    /**
     * Gets a subcollection key
     * @param keys The keys tp the subcollection.
     *
     * @returns The subcollection key.
     */
    public static subcollection<T extends object, SyncContext extends object>(
        ...keys: [...DocumentKey<object>[], CollectionKey<T, SyncContext>]
    ): CollectionKey<T, SyncContext> {
        let lastKey: DocumentKey<object> | null = null;
        for (const key of keys) {
            if (key instanceof CollectionKey) {
                if (!lastKey) {
                    return key;
                }
                return lastKey.subCollection(key);
            } else {
                if (!lastKey) {
                    lastKey = key;
                } else {
                    lastKey = lastKey
                        .subCollection(key.collectionKey)
                        .doc(key.documentId);
                }
            }
        }
        throw new Error('No collection key found');
    }

    /**
     * Gets a sub-collection key.
     * @param collectionKey The collection key.
     * @param documentId The document ID.
     *
     * @returns The sub-collection key.
     */
    subCollection<TSC extends object, SyncContext extends object>(
        collectionKey: CollectionKey<TSC, SyncContext>,
        documentId: string,
    ): CollectionKey<TSC, SyncContext> {
        return new CollectionKey<TSC, SyncContext>(
            `${this.remoteCollectionName}/${documentId}/${collectionKey.remoteCollectionName}`,
            collectionKey.migrator,
            collectionKey.localCollectionInfo,
        );
    }

    /**
     * Creates a collection key from a path.
     * @param path The path.
     * @param key The collection key.
     *
     * @returns The collection key.
     */
    public static fromPath<T extends object, SyncContext extends object = object>(
        path: string,
        key: CollectionKey<T, SyncContext>,
    ): CollectionKey<T, SyncContext> {
        return new CollectionKey<T, SyncContext>(
            path,
            key.migrator,
            key.localCollectionInfo,
        );
    }

    /**
     * Gets the path of the collection.
     *
     * @returns The path.
     */
    get path(): string {
        return this.remoteCollectionName;
    }

    /**
     * Creates a sync instruction with the given context.
     * @param ctx The context.
     *
     * @returns The sync instruction.
     */
    withContext(ctx: SyncContext): SyncInstruction<TSource, SyncContext> {
        return new SyncInstruction<TSource, SyncContext>(this, ctx);
    }
}

/**
 * A key for a Nirby document ref.
 */
export class DocumentKey<TSource extends object, SyncContext extends object = object> {
    /**
     * Gets the path of the document.
     */
    get path(): string {
        return `${this.collectionKey.path}/${this.documentId}`;
    }

    /**
     * Constructor.
     * @param collectionKey The collection key.
     * @param documentId The document ID.
     */
    constructor(
        public readonly collectionKey: CollectionKey<TSource, SyncContext>,
        public readonly documentId: string,
    ) {
    }

    /**
     * Gets a sub-collection key.
     * @param collectionKey The collection key.
     *
     * @returns The sub-collection key.
     */
    subCollection<TSC extends object, SyncContext extends object>(
        collectionKey: CollectionKey<TSC, SyncContext>,
    ): CollectionKey<TSC, SyncContext> {
        return this.collectionKey.subCollection(collectionKey, this.documentId);
    }

    /**
     * Creates a document key from a path.
     * @param path The path.
     * @param key The collection key.
     *
     * @returns The document key.
     */
    public static fromPath<T extends object, SyncContext extends object = object>(
        path: string,
        key: CollectionKey<T, SyncContext>,
    ): DocumentKey<T, SyncContext> {
        const parts = path.split('/');
        if (parts.length < 2 || parts.length % 2 !== 0) {
            throw new Error(`Invalid path: ${path}`);
        }
        const collectionPath = parts.slice(0, parts.length - 1).join('/');
        const collectionKey = CollectionKey.fromPath<T, SyncContext>(collectionPath, key);
        const documentId = parts[parts.length - 1];
        return collectionKey.doc(documentId);
    }
}
