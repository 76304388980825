<div [formGroup]="form">
    <div class="form-group">
        <label for="color">Color</label>
        <nirby-color-picker
            id="color"
            [formControl]="colorControl"
        ></nirby-color-picker>
    </div>
    <div class="form-group">
        <label for="label">Label</label>
        <input id="label" class="form-control" [formControl]="labelControl"/>
    </div>
    <div class="form-group">
        <label for="submitMessage">Submit Message</label>
        <input
            id="submitMessage"
            class="form-control"
            [formControl]="submitMessageControl"
        />
    </div>
    <div class="form-group">
        <label for="emoji">Emoji</label>
        <nirby-emoji-picker
            id="emoji"
            [value]="emojiControl.value"
            (valueChange)="emojiControl.setValue($event)"
        ></nirby-emoji-picker>
    </div>
</div>
