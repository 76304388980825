import {Card} from '../card.model';

export const defaultCard: Card = {
    blocks: [],
    hash: '',
    style: {
        fillColor: '#ffffff',
        borderRadius: 6,
        strokeColor: 'rgba(0,0,0,0)',
    },
    title: '',
};

export const defaultVideoCard = {
    card: defaultCard,
    duration: 10000,
    position: [
        { x: 0, y: 0 },
        { x: 2, y: 3 },
    ],
    start: -1,
    type: 'story',
};
