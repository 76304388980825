import {FilteredKeyValueStorage} from './filtered-key-value-storage';
import {ReactiveKeyValueStorage, StorageChange} from './reactive-key-value-storage';
import {concat, filter, from, merge, Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

/**
 * Object to check if a value filter is affected by a context change.
 */
export interface ReactiveStorageFilter<TValue, TContext> {
    readonly storage: ReactiveKeyValueStorage<TValue>;

    getContextAffectedIds(oldContext: TContext, newContext: TContext): Iterable<string>;

    filter(value: TValue, context: TContext): boolean;
}

export interface ContextStorageChange<TValue, TContext> {
    readonly previousContext: TContext;
    readonly newContext: TContext;
    readonly internalChange: StorageChange<TValue>;
}


/**
 * A key-value storage that filters the values without changing the underlying storage using a context to validate
 * and notifying all changes when an item is added, removed, updated, filtered or unfiltered.
 */
export class ReactiveFilteredKeyValueStorage<TValue, TContext>
    extends FilteredKeyValueStorage<TValue, TContext>
    implements ReactiveKeyValueStorage<TValue> {

    private readonly affectedByContext = new Subject<ContextStorageChange<TValue, TContext>>();

    /**
     * Constructor.
     * @param reactiveInternal The storage to filter.
     * @param initialContext The initial context to use to filter the values.
     */
    constructor(
        private readonly reactiveInternal: ReactiveStorageFilter<TValue, TContext>,
        initialContext: TContext,
    ) {
        super(reactiveInternal, initialContext);
    }

    /**
     * Watch the IDs of the items that are changed.
     *
     * @returns Observable of the IDs of the items that are changed.
     */
    watchChanges(): Observable<StorageChange<TValue>> {
        return concat(
            // Initial values
            from(
                [...this.entries()]
                    .filter(
                        ([, value]) => this.reactiveInternal.filter(value, this.context),
                    )
                    .map(([id, value]): StorageChange<TValue> => {
                        return {
                            id,
                            newValue: value,
                            type: 'insert',
                        };
                    })
                    .map((change): ContextStorageChange<TValue, TContext> => {
                        return {
                            internalChange: change,
                            newContext: this.context,
                            previousContext: this.context,
                        };
                    }),
            ),
            // Changes
            merge(
                this.affectedByContext.asObservable(),
                this.reactiveInternal.storage.watchChanges().pipe(
                    map((change): ContextStorageChange<TValue, TContext> => ({
                        internalChange: change,
                        previousContext: this.context,
                        newContext: this.context,
                    })),
                ),
            ),
        ).pipe(
            map((change: ContextStorageChange<TValue, TContext>): StorageChange<TValue> | null => {
                switch (change.internalChange.type) {
                    case 'insert': {
                        if (this.reactiveInternal.filter(change.internalChange.newValue, change.newContext)) {
                            return change.internalChange;
                        }
                        break;
                    }
                    case 'update': {
                        const wasIn = this.reactiveInternal.filter(change.internalChange.previousValue, change.previousContext);
                        const isIn = this.reactiveInternal.filter(change.internalChange.newValue, change.newContext);
                        if (wasIn && isIn) {
                            return change.internalChange;
                        }
                        if (wasIn && !isIn) {
                            return {
                                type: 'delete',
                                id: change.internalChange.id,
                                previousValue: change.internalChange.previousValue,
                            };
                        }
                        if (!wasIn && isIn) {
                            return {
                                type: 'insert',
                                id: change.internalChange.id,
                                newValue: change.internalChange.newValue,
                            };
                        }
                        break;
                    }
                    case 'delete': {
                        if (this.reactiveInternal.filter(change.internalChange.previousValue, change.previousContext)) {
                            return change.internalChange;
                        }
                        break;
                    }
                }
                return null;
            }),
            filter((change: StorageChange<TValue> | null): change is StorageChange<TValue> => change !== null),
        );
    }

    /**
     * Set the context to use to filter the values.
     * @param context The context to use to filter the values.
     */
    override setContext(context: TContext): void {
        // calculate affected IDs by the context change
        const oldContext = this.context;
        const affectedIds = this.reactiveInternal.getContextAffectedIds(oldContext, context);

        // create the changes
        const changes: ContextStorageChange<TValue, TContext>[] = [];
        for (const id of affectedIds) {
            const value = this.reactiveInternal.storage.get(id);
            if (value === undefined) {
                continue;
            }
            changes.push({
                internalChange: {
                    type: 'update',
                    id,
                    newValue: value,
                    previousValue: value,
                },
                previousContext: oldContext,
                newContext: context,
            });
        }

        // apply the changes
        super.setContext(context);

        // notify the changes
        for (const change of changes) {
            this.affectedByContext.next(change);
        }
    }
}
