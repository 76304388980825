import {z} from 'zod';
import {ANY_BLOCK_SCHEMA} from './blocks';
import {Migrator} from '@nirby/store/migrator';
import {v4} from 'uuid';
import {ZodSchemaBuild} from '@nirby/store/models';

export interface CardStyle {
    fillColor: string;
    borderRadius: number;
    strokeColor: string;
}

export const CARD_STYLE_SCHEMA: ZodSchemaBuild<CardStyle> = z.object({
    fillColor: z.string().default('#ffffff'),
    borderRadius: z.number().default(6),
    strokeColor: z.string().default('rgba(0,0,0,0)'),
});

export const CARD_SCHEMA = z.object({
    hash: z.string(),
    title: z.string(),
    blocks: z.array(ANY_BLOCK_SCHEMA),
    style: CARD_STYLE_SCHEMA,
});

export type FixedCard = z.infer<typeof CARD_SCHEMA>;
export type CanvasBackground = FixedCard['style'];

export type Card = FixedCard;

export const CARD_MIGRATOR = new Migrator<Card>(
    z.object({
        hash: z
            .string()
            .min(1)
            .catch(() => v4()),
        title: z.string(),
        style: CARD_STYLE_SCHEMA,
        blocks: z.array(ANY_BLOCK_SCHEMA),
    }),
    () => ({
        hash: '',
        style: {
            fillColor: '#ffffff',
            borderRadius: 6,
            strokeColor: 'rgba(0,0,0,0)',
        },
        title: 'title',
        blocks: [],
    }),
);
