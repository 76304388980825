import { Component } from '@angular/core';
import { UntypedFormControl, FormGroup } from '@angular/forms';
import { BlockActionComponent } from '../../blocks/block-action.component';
import {TriggerDomAction} from '@nirby/models/nirby-player';

@Component({
    selector: 'nirby-action-trigger-dom',
    templateUrl: './action-trigger-dom.component.html',
    styleUrls: ['./action-trigger-dom.component.scss'],
})
export class ActionTriggerDomComponent extends BlockActionComponent<TriggerDomAction> {
    selectorControl = new UntypedFormControl('');
    eventControl = new UntypedFormControl('click');

    form = new FormGroup({
        selector: this.selectorControl,
        event: this.eventControl,
    });

    setData(content: TriggerDomAction | null): void {
        if (content) {
            this.selectorControl.setValue(content.options.selector);
            this.eventControl.setValue(content.options.event);
        }
    }
}
