import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ToolbarComponent} from './toolbar.component';
import {RouterLinkActive, RouterLink} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgbDropdownModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';


@NgModule({
    declarations: [
        ToolbarComponent,
    ],
    exports: [
        ToolbarComponent
    ],
    imports: [
        CommonModule,
        RouterLink,
        RouterLinkActive,
        FontAwesomeModule,
        NgbDropdownModule,
        NgbTooltipModule
    ]
})
/**
 * Toolbar module
 */
export class ToolbarModule {
}
