import {Injectable} from '@angular/core';
import {DirectoriesService} from './directories.service';
import {AppCard, Directory, Pop, RouteData} from '@nirby/models/editor';
import {RouteParametersService} from '../route-parameters.service';
import {firstValueFrom, Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {NirbyCollection, NirbyDocument, NirbyDocumentReference, NirbyTransaction} from '@nirby/store/base';

import {Database, shareReplaySafe} from '@nirby/ngutils';
import {CompiledPop, LandPop, LandPopPublished} from '@nirby/models/pop';
import {AppError} from '@nirby/js-utils/errors';
import {Card} from '@nirby/models/nirby-player';
import {COLLECTION_KEYS} from '@nirby/store/collections';
import {generateId} from '@nirby/runtimes/canvas';
import {Logger} from '@nirby/logger';
import {DateTime} from 'luxon';
import {Modeled, Uploaded} from '@nirby/store/models';

@Injectable({
    providedIn: 'root',
})
export class PopService {
    constructor(
        private readonly db: Database,
        private routeParams: RouteParametersService,
        private directoryService: DirectoriesService,
    ) {
    }

    get popId(): string {
        return this.routeParams.popId;
    }

    popId$ = this.routeParams.popId$;
    popSiblings$: Observable<NirbyDocument<Pop>[]> =
        this.routeParams.widgetId$.pipe(
            switchMap((widgetId) =>
                this.db
                    .collection(
                        COLLECTION_KEYS.WORKSPACES.doc(widgetId),
                        COLLECTION_KEYS.POPS,
                    )
                    .query()
                    .watch(),
            ),
            shareReplaySafe(),
        );

    async create(
        data: Pop,
        widgetId: string,
        id?: string,
    ): Promise<NirbyDocumentReference<Pop>> {
        const popId = id ?? this.db.generateId();
        const pops = this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(widgetId),
            COLLECTION_KEYS.POPS,
        );
        const cards = this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(widgetId),
            COLLECTION_KEYS.CARDS,
        );

        return await this.db.runTransaction(async (transaction) => {
            // create pop
            pops.setTransaction(transaction, data, popId);

            // create first card
            const cardRef = cards.setTransaction(transaction, {
                title: 'My first amazing card',
                usedBy: null,
                card: {
                    hash: generateId(),
                    title: 'My first amazing card',
                    blocks: [],
                    style: {
                        fillColor: '#ffffff',
                        borderRadius: 6,
                        strokeColor: 'rgba(0,0,0,0)',
                    },
                },
            });

            // add first card to pop
            pops.updateTransaction(transaction, popId, {
                initialCard: this.db
                    .collection(
                        COLLECTION_KEYS.WORKSPACES.doc(widgetId),
                        COLLECTION_KEYS.CARDS,
                    )
                    .ref(cardRef.id),
            });

            return pops.ref(popId);
        });
    }

    async archive(widgetId: string, popId: string): Promise<void> {
        await this.db.runTransaction(async (transaction) => {
            await this.db
                .collection(
                    COLLECTION_KEYS.WORKSPACES.doc(widgetId),
                    COLLECTION_KEYS.POPS,
                )
                .updateTransaction(transaction, popId, {status: 'archived'});
            await this.unAssignFromAll(transaction, widgetId, popId);
        });
    }

    async delete(widgetId: string, popId: string): Promise<void> {
        await this.db
            .collection(
                COLLECTION_KEYS.WORKSPACES.doc(widgetId),
                COLLECTION_KEYS.POPS,
            )
            .delete(popId);
    }

    async createFromCompiled(
        widgetId: string,
        compiled: CompiledPop,
        transaction: NirbyTransaction,
    ): Promise<NirbyDocumentReference<Pop>> {
        const cards = compiled.cards;
        const campaign: Pop = {
            directory: null,
            emoji: null,
            hash: '',
            status: 'active',
            initialCard: null,
            welcome: {
                openingStyle: 'bottom-right',
                ...compiled.welcome,
            },
            image: null,
            title: compiled.title,
        };
        const popId = this.db.generateId();
        const cardsCollection = this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(widgetId),
            COLLECTION_KEYS.CARDS,
        );
        const popsCollection = this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(widgetId),
            COLLECTION_KEYS.POPS,
        );

        let cardId: string;
        for (cardId in cards) {
            // eslint-disable-next-line no-prototype-builtins
            if (!cards.hasOwnProperty(cardId)) {
                continue;
            }
            const sourceCard = cards[cardId];
            cardId = this.db
                .collection(
                    COLLECTION_KEYS.WORKSPACES.doc(widgetId),
                    COLLECTION_KEYS.CARDS,
                )
                .setTransaction(
                    transaction,
                    {
                        title: sourceCard.title,
                        usedBy: popsCollection.ref(popId),
                        card: sourceCard,
                    },
                    cardId,
                ).id;
            if (cardId === compiled.firstCardKey) {
                campaign.initialCard = cardsCollection.ref(cardId);
            }
        }
        return this.db
            .collection(
                COLLECTION_KEYS.WORKSPACES.doc(widgetId),
                COLLECTION_KEYS.POPS,
            )
            .setTransaction(transaction, campaign, popId);
    }

    public collection(workspaceId: string): NirbyCollection<Pop> {
        return this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(workspaceId),
            COLLECTION_KEYS.POPS,
        );
    }

    private collectionLandPops(workspaceId: string): NirbyCollection<LandPop> {
        return this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(workspaceId),
            COLLECTION_KEYS.LANDPOPS,
        );
    }

    private collectionLandPopsPublished(
        workspaceId: string,
    ): NirbyCollection<LandPopPublished> {
        return this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(workspaceId),
            COLLECTION_KEYS.PUBLISHED_LANDPOPS,
        );
    }

    private collectionCards(workspaceId: string): NirbyCollection<AppCard> {
        return this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(workspaceId),
            COLLECTION_KEYS.CARDS,
        );
    }

    async getAssigned(
        widgetId: string,
        popId: string,
    ): Promise<{
        publishedLandpops: NirbyDocument<LandPopPublished>[];
        landpops: NirbyDocument<LandPop>[];
        routes: NirbyDocument<RouteData>[];
    }> {
        const ref = this.collection(widgetId).ref(popId);
        const landpopsAsync = firstValueFrom(
            this.collectionLandPops(widgetId)
                .query()
                .where('pop', '==', ref)
                .get(),
        );
        const publishedLandpopsAsync = firstValueFrom(
            this.collectionLandPopsPublished(widgetId)
                .query()
                .where('pop', '==', ref)
                .get(),
        );
        const routesAsync = firstValueFrom(
            this.db
                .collection(
                    COLLECTION_KEYS.WORKSPACES.doc(widgetId),
                    COLLECTION_KEYS.ROUTES,
                )
                .query()
                .get(),
        );
        const [publishedLandpops, landpops, routes] = await Promise.all([
            publishedLandpopsAsync,
            landpopsAsync,
            routesAsync,
        ]);

        return {
            publishedLandpops,
            landpops,
            routes: routes.filter((r) =>
                r.data.campaigns.reduce<boolean>(
                    (prev, c) => c.ref.id === ref.id || prev,
                    false,
                ),
            ),
        };
    }

    /**
     * Unassigns all landpops from a pop
     * @param transaction The firestore transaction
     * @param workspaceId The workspace id
     * @param popId The pop id
     */
    async unAssignFromAll(
        transaction: NirbyTransaction,
        workspaceId: string,
        popId: string,
    ): Promise<void> {
        const assigned = await this.getAssigned(workspaceId, popId);
        Logger.logAt(
            'DB:POPS',
            `Found ${assigned.routes.length} routes, ${assigned.landpops.length} landpops and ${assigned.publishedLandpops.length} published landpops with pop ${workspaceId}/${popId} assigned`,
        );

        // unpublish landpops
        let landpopPublished: NirbyDocument<LandPopPublished>;
        for (landpopPublished of assigned.publishedLandpops) {
            const docId = landpopPublished.id;
            if (!docId) {
                continue;
            }
            await this.collectionLandPopsPublished(
                workspaceId,
            ).updateTransaction(transaction, docId, {pop: null, json: null});
            Logger.logAt(
                'DB:POPS',
                `unassigned pop ${workspaceId}/${popId} from landpop ${docId}`,
            );
        }

        // un-reference from landpops
        let landpop: NirbyDocument<LandPop>;
        for (landpop of assigned.landpops) {
            const docId = landpop.id;
            if (!docId) {
                continue;
            }
            await this.collectionLandPops(workspaceId).updateTransaction(
                transaction,
                docId,
                {pop: null},
            );
            Logger.logAt(
                'DB:POPS',
                `unassigned pop ${workspaceId}/${popId} from landpop ${docId}`,
            );
        }

        // un-reference from routes
        let route: NirbyDocument<RouteData>;
        for (route of assigned.routes) {
            const docId = route.id;
            if (!docId) {
                continue;
            }
            const popRef = this.collection(workspaceId).ref(popId);
            await this.db
                .collection(
                    COLLECTION_KEYS.WORKSPACES.doc(workspaceId),
                    COLLECTION_KEYS.ROUTES,
                )
                .updateTransaction(transaction, docId, {
                    campaigns: route.data.campaigns.filter(
                        (c) => c.ref.id !== popRef.id,
                    ),
                });
            Logger.logAt(
                'DB:POPS',
                `unassigned pop ${workspaceId}/${popId} from route ${docId}`,
            );
        }
    }

    /**
     * Updates the directory count for the directory of a Pop.
     * @param workspaceId The workspace id.
     * @param popId The pop id.
     * @private
     */
    private async updateDirectoryCountForCampaign(
        workspaceId: string,
        popId: string,
    ): Promise<void> {
        const campaign = await firstValueFrom(
            this.collection(workspaceId).get(popId),
        );
        if (!campaign?.data.directory) {
            return;
        }
        // const directoryId = campaign.directory.ref.id;
        // await this.updateDirectoryCount(workspaceId, [directoryId]);
    }

    /**
     * Unarchive a Pop
     * @param workspaceId The workspace id
     * @param popId The pop id
     *
     * @returns A promise that resolves when the pop is unarchived
     */
    async unarchive(workspaceId: string, popId: string): Promise<void> {
        await this.collection(workspaceId).update(popId, {status: 'active'});
        await this.updateDirectoryCountForCampaign(workspaceId, popId);
    }

    /**
     * Copy a pop inside a workspace
     * @param workspaceId The workspace id
     * @param popId The Pop id
     * @param deleteSource If true, the source directory will be deleted
     * @param targetDirectoryId The target directory id
     */
    async copyTo(
        workspaceId: string,
        popId: string,
        deleteSource = false,
        targetDirectoryId?: string,
    ): Promise<void> {
        await this.copyToWidget(
            popId,
            workspaceId,
            workspaceId,
            deleteSource,
            targetDirectoryId,
        );
    }

    /**
     * Get the Pop data
     * @param workspaceId The workspace id
     * @param popId The Pop id
     */
    async getCardReferences(
        workspaceId: string,
        popId: string,
    ): Promise<NirbyDocumentReference<AppCard>[]> {
        const cards = await firstValueFrom(
            this.collectionCards(workspaceId)
                .query()
                .where('usedBy', '==', this.collection(workspaceId).ref(popId))
                .get(),
        );
        return cards.map((card) =>
            this.collectionCards(workspaceId).ref(card.id),
        );
    }

    /**
     * Copies a pop between widgets or directories.
     * @param sourcePopId The ID of the pop to copy.
     * @param sourceWidgetId The ID of the widget the pop is in.
     * @param destinyWidgetId The ID of the widget to copy the pop to.
     * @param deleteSource Whether to delete the source pop.
     * @param destinyDirectoryId The ID of the directory to copy the pop to.
     */
    async copyToWidget(
        sourcePopId: string,
        sourceWidgetId: string,
        destinyWidgetId: string,
        deleteSource = false,
        destinyDirectoryId?: string,
    ): Promise<void> {
        const sourceCampaignRef =
            this.collection(sourceWidgetId).ref(sourcePopId);

        const isMovingInSameWidget =
            deleteSource && sourceWidgetId === destinyWidgetId;
        const destinyCampaignRef = isMovingInSameWidget
            ? sourceCampaignRef
            : this.collection(destinyWidgetId).ref();

        const sourceCards = await this.getCardReferences(
            sourceWidgetId,
            sourcePopId,
        );

        const cardReferences: Record<
            string,
            {
                source: NirbyDocumentReference<AppCard>;
                destiny: NirbyDocumentReference<AppCard>;
            }
        > = {};
        sourceCards.forEach(
            (ref) =>
                (cardReferences[ref.id] = {
                    source: ref,
                    destiny: isMovingInSameWidget
                        ? ref
                        : this.collectionCards(destinyWidgetId).ref(),
                }),
        );

        return await this.db.runTransaction<void>(async (transaction) => {
            // get data
            let sourceData: {
                cards: Record<string, Uploaded<Card>>;
                campaign: Uploaded<Pop>;
            } | null = null;

            // only get campaign data if moving between different widgets for later duplication
            if (!isMovingInSameWidget) {
                sourceData = await this.getPopDataTransaction(
                    transaction,
                    sourceWidgetId,
                    sourcePopId,
                    Object.values(cardReferences).map((ref) => ref.source),
                );
            }

            // get target directory data
            let directory: Directory | null = null;
            if (destinyDirectoryId) {
                directory =
                    (
                        await this.directoryService.getTransaction(
                            transaction,
                            destinyWidgetId,
                            destinyDirectoryId,
                        )
                    )?.data ?? null;
            }

            // get new directory
            let newDirectory: Pop['directory'];
            if (directory && destinyDirectoryId) {
                newDirectory = {
                    name: directory.name,
                    ref: this.directoryService.ref(
                        destinyWidgetId,
                        destinyDirectoryId,
                    ),
                };
            } else {
                newDirectory = null;
            }

            if (isMovingInSameWidget) {
                // only update directory of the campaign
                const newPop: Partial<Pop> = {
                    directory: newDirectory,
                    _lastUpdate: new Date(),
                } as Partial<Pop>;
                transaction.update(destinyCampaignRef, newPop);
                Logger.logAt(
                    'CAMPAIGN',
                    `Moved campaign to directory ${newDirectory?.name}`,
                );
            } else if (sourceData) {
                // copy campaign
                sourceData.campaign.directory = newDirectory;

                const ids = Object.keys(sourceData.cards);

                const campaignCopy = {...sourceData.campaign};
                if (!deleteSource) {
                    campaignCopy.title = 'Copy of ' + campaignCopy.title;
                }
                transaction.set(destinyCampaignRef, campaignCopy);

                const oldCards = sourceData.cards;
                ids.forEach((cardId) => {
                    const dbVersion = oldCards[cardId]._databaseVersion;
                    const data: Uploaded<AppCard> = {
                        title: oldCards[cardId].title,
                        usedBy: destinyCampaignRef,
                        card: oldCards[cardId],
                        _databaseVersion: dbVersion,
                        _creationTime: DateTime.now(),
                        _lastUpdate: DateTime.now(),
                    };
                    transaction.set(cardReferences[cardId].destiny, data);
                });
                Logger.logAt(
                    'CAMPAIGN',
                    `Duplicated campaign to directory ${newDirectory?.name}`,
                );
            } else {
                throw new AppError('Nothing updated 🤔 in move/copy operation');
            }

            if (
                deleteSource &&
                (sourceWidgetId !== destinyWidgetId ||
                    sourceCampaignRef.id !== destinyCampaignRef.id)
            ) {
                // delete source
                transaction.delete(sourceCampaignRef);
                sourceCards.forEach((ref) => transaction.delete(ref));
            }

            Logger.logAt(
                'CAMPAIGN',
                `${deleteSource ? 'Moved' : 'Copied'} campaign from ${
                    sourceCampaignRef.path
                } to ${destinyCampaignRef.path}`,
            );
        });
    }

    public async getPopReferences(
        workspaceId: string,
        popId: string,
    ): Promise<{
        cards: NirbyDocumentReference<AppCard>[];
        campaign: NirbyDocumentReference<Pop>;
    }> {
        const campaignRef = this.collection(workspaceId).ref(popId);

        const cards = await firstValueFrom(
            this.collectionCards(workspaceId)
                .query()
                .orderBy('_lastUpdate', 'desc')
                .get(),
        );
        const cardsReferences: NirbyDocumentReference<AppCard>[] = cards.map(
            (card) => card.ref,
        );
        return {
            cards: cardsReferences,
            campaign: campaignRef,
        };
    }

    public async compile(
        widgetId: string,
        popId: string,
    ): Promise<CompiledPop> {
        Logger.logAt('CAMPAIGN', `Compiling campaign ${widgetId}/${popId}`);
        const references = await this.getPopReferences(widgetId, popId);

        return await this.db.runTransaction<CompiledPop>(
            async (transaction) => {
                return await this.compilePopInTransaction(
                    transaction,
                    widgetId,
                    popId,
                    references.cards,
                );
            },
        );
    }

    public async compilePopInTransaction(
        transaction: NirbyTransaction,
        workspaceId: string,
        popId: string,
        cardsReferences: NirbyDocumentReference<AppCard>[],
    ): Promise<CompiledPop> {
        const {campaign, cards} = await this.getPopDataTransaction(
            transaction,
            workspaceId,
            popId,
            cardsReferences,
        );
        if (!campaign.initialCard) {
            throw new AppError('Trying to compile Pop with no initial card');
        }
        return {
            cards: {...cards},
            firstCardKey: campaign.initialCard.id,
            title: campaign.title,
            welcome: {...campaign.welcome},
            plugins: {},
        };
    }

    private async getPopDataTransaction(
        transaction: NirbyTransaction,
        workspaceId: string,
        popId: string,
        cardsReferences: NirbyDocumentReference<AppCard>[],
    ): Promise<{
        cards: Record<string, Modeled<Card>>;
        campaign: Modeled<Pop>;
    }> {
        const rawPop = await this.collection(workspaceId).getTransaction(
            transaction,
            popId,
        );
        if (!rawPop) {
            throw new AppError(`Pop ${workspaceId}/${popId} not found`);
        }
        const now = DateTime.now();
        const pop: Modeled<Pop> = rawPop.toModeled();
        const migratedCampaign: Modeled<Pop> = {
            ...pop,
            _databaseVersion: pop._databaseVersion,
            _creationTime: now,
            _lastUpdate: now,
        };

        Logger.logAt('CAMPAIGN', 'Got pop', popId, migratedCampaign);

        const cards: Record<string, Modeled<Card>> = {};
        await Promise.all(
            cardsReferences.map((ref) =>
                transaction.get(ref).then((doc) => {
                    if (!doc) {
                        return;
                    }
                    cards[doc.id] = {
                        ...doc.data.card,
                        _databaseVersion: doc.schemaVersion,
                        _creationTime: now,
                        _lastUpdate: now,
                        _docId: doc.id,
                    };
                }),
            ),
        );

        return {
            cards,
            campaign: migratedCampaign,
        };
    }
}
