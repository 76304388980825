import { AbstractControl, ValidatorFn } from '@angular/forms';

export const CustomValidators = {
    /**
     * Creates a validator that checks a control before running the validation.
     * @param control The form control to validate.
     * @param conditionControl The form control that stores the condition.
     * @param validator A function that returns a boolean value.
     *
     * @returns A validator function that returns an error map if the condition is false.
     */
    onlyIf(
        control: AbstractControl,
        conditionControl: AbstractControl,
        validator: ValidatorFn
    ): ValidatorFn {
        return CustomValidators.onlyIfFn(
            control,
            () => !!conditionControl.value,
            validator
        );
    },
    /**
     * Creates a validator that checks a condition before running the validation.
     * @param control The form control to validate.
     * @param condition A function that returns a boolean value.
     * @param validator A function that returns a boolean value.
     *
     * @returns A validator function that returns an error map if the condition is false.
     */
    onlyIfFn(
        control: AbstractControl,
        condition: () => boolean,
        validator: ValidatorFn
    ): ValidatorFn {
        return () => (condition() ? validator(control) : null);
    },
};
