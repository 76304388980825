import {Component} from '@angular/core';
import {map, switchMap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {Pop, Workspace} from '@nirby/models/editor';
import {PopService, RouteParametersService, WorkspaceService} from '@nirby/shared/database';
import {NirbyDocument} from '@nirby/store/base';

@Component({
    selector: 'nirby-project-home',
    templateUrl: './project-home.component.html',
    styleUrls: ['./project-home.component.scss'],
})
export class ProjectHomeComponent {
    widget$: Observable<NirbyDocument<Workspace> | null>;
    campaigns$: Observable<Pop[] | null>;

    constructor(
        private route: ActivatedRoute,
        private routeParams: RouteParametersService,
        private widgetService: WorkspaceService,
        private campaignService: PopService,
    ) {
        this.widget$ = widgetService.activeWidget$;

        this.campaigns$ = routeParams.widgetId$.pipe(
            switchMap((widgetId) => this.campaignService.collection(widgetId).query().watch()),
            map((items) => items.map((item) => item.toModeled())),
        );
    }
}
