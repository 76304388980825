<ng-template #contactForm>
    <nirby-contact-form
        *ngIf="formOptions"
        [context]="contextPrivate"
        [options]="formOptions"
        (submitForm)="updateContact(contextPrivate, $event)"
    ></nirby-contact-form>
</ng-template>
<ng-template #lightbox let-c="close" let-d="dismiss">
    <div class="embed-responsive embed-responsive-16by9">
        <iframe
            *ngIf="iframeSrc"
            [src]="iframeSrc | safeUrl"
            class="embed-responsive-item"
            width="560"
            height="315"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
        ></iframe>
    </div>
</ng-template>
