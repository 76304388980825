import {Injectable} from '@angular/core';
import {Database, shareReplaySafe} from '@nirby/ngutils';
import {COLLECTION_KEYS} from '@nirby/store/collections';
import {AppVariable, Pop, Prime, Workspace, WORKSPACE_MIGRATOR} from '@nirby/models/editor';
import {Observable, of, switchMap} from 'rxjs';
import {ProductReference, ProductsListService} from '../services/products-list.service';
import {NirbyCollection, NirbyDocument, NirbyDocumentReference} from '@nirby/store/base';

@Injectable({
    providedIn: 'root',
})
/**
 * Service for managing the Nirby Variables
 */
export class NirbyVariablesService {
    public readonly availableVariables$: Observable<
        NirbyDocument<AppVariable>[]
    > = this.products.productRef$.pipe(
        switchMap((productRef) => {
            if (!productRef) {
                return of([]);
            }
            return this.collection(productRef.workspaceRef.id)
                .query()
                .where('usedBy', '==', productRef.ref)
                .orderBy('_creationTime')
                .get();
        }),
        shareReplaySafe({refCount: true, bufferSize: 1}),
    );

    /**
     * Gets the product workspace reference.
     * @param productRef The product reference.
     * @private
     *
     * @returns - The product workspace reference.
     */
    private static getProductWorkspaceRef(
        productRef: NirbyDocumentReference<Pop> | NirbyDocumentReference<Prime>,
    ): NirbyDocumentReference<Workspace> {
        return productRef.getParentDoc(WORKSPACE_MIGRATOR, false);
    }

    /**
     * Gets the variables collection for a workspace.
     * @param workspaceId The workspace ID.
     *
     * @returns - The variables collection.
     */
    public collection(workspaceId: string): NirbyCollection<AppVariable> {
        return this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(workspaceId),
            COLLECTION_KEYS.VARIABLES,
        );
    }

    /**
     * The current product reference.
     */
    get productRef(): ProductReference | null {
        return this.products.productRef;
    }

    /**
     * Constructor.
     * @param db The Firestore service.
     * @param products The products service.
     */
    constructor(
        private readonly db: Database,
        private readonly products: ProductsListService,
    ) {
    }

    /**
     * Lists the variables for the given product.
     * @param productRef The product reference.
     *
     * @returns - The variables.
     */
    public listFromProduct(
        productRef: AppVariable['usedBy'],
    ): Observable<NirbyDocument<AppVariable>[]> {
        const workspaceId = productRef?.getParentDocId();
        if (!workspaceId) {
            return of([]);
        }
        return this.collection(workspaceId)
            .query()
            .where('usedBy', '==', productRef)
            .orderBy('_creationTime')
            .get();
    }

    /**
     * Watch list of variables for the given product.
     * @param productRef The product reference.
     *
     * @returns - The variables.
     */
    watchListFromProduct(
        productRef: AppVariable['usedBy'],
    ): Observable<NirbyDocument<AppVariable>[]> {
        const workspaceId = productRef?.getParentDocId();
        if (!workspaceId) {
            return of([]);
        }
        return this.collection(workspaceId)
            .query()
            .where('usedBy', '==', productRef)
            .orderBy('_creationTime')
            .watch();
    }

    /**
     * Creates a new variable inside a product.
     * @param variableName The variable name.
     * @param productRef The product reference.
     *
     * @returns - The created variable.
     */
    async create(
        variableName: string,
        productRef: AppVariable['usedBy'],
    ): Promise<NirbyDocumentReference<AppVariable>> {
        return this.collection(
            NirbyVariablesService.getProductWorkspaceRef(productRef).id,
        ).add({
            name: variableName,
            displayName: variableName,
            initialValue: null,
            usedBy: productRef,
            source: 'query',
            type: 'string',
        });
    }

    /**
     * Deletes a variable
     * @param variableReference The variable reference.
     *
     * @returns - The deleted variable.
     */
    async delete(
        variableReference: NirbyDocumentReference<AppVariable>,
    ): Promise<void> {
        const parentDocId = variableReference.getParentDocId();
        if (!parentDocId) {
            return;
        }
        return this.collection(parentDocId).delete(variableReference.id);
    }
}
