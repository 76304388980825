import {Component, Input} from '@angular/core';
import {
    faArrowRight, faChessBoard,
    faFont,
    faIcons,
    faImage,
    faQuestion,
    faSlidersH,
    faSquare,
    faVideo,
} from '@fortawesome/free-solid-svg-icons';
import {CardEditor} from '../../../routes/editor-cards/services/card-editor';
import {BlockSelectorItem,} from '../block-selector-item/block-selector-item-simple.component';


@Component({
    selector: 'nirby-block-selector',
    templateUrl: './block-selector.component.html',
    styleUrls: ['./block-selector.component.scss'],
})
/**
 * Component for selecting a block type or a card to be inserted into an editor.
 */
export class BlockSelectorComponent {
    @Input() editor: CardEditor | null = null;

    /**
     * Whether to include the "Card" option.
     */
    @Input() includeCard = false;

    baseItems: BlockSelectorItem[] = [
        {
            icon: faFont,
            label: 'Text',
            value: 'Text',
        },
        {
            icon: faImage,
            label: 'Image',
            value: 'Image',
        },
        {
            icon: faSquare,
            label: 'Rectangle',
            value: 'Rectangle',
        },
        {
            icon: faArrowRight,
            label: 'Button',
            value: 'Button',
        },
        {
            icon: faIcons,
            label: 'Icon',
            value: 'Icon',
        },
        {
            icon: faVideo,
            label: 'Video',
            value: 'Video',
        },
        {
            icon: faQuestion,
            label: 'Question',
            value: 'Question',
        },
        {
            icon: faSlidersH,
            label: 'Slider',
            value: 'Slider',
        },
    ];
    cardItem: BlockSelectorItem = {
        icon: faChessBoard,
        label: 'Card',
        value: 'art-board',
    };
}
