import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Icon, IconService } from '../../../services/icon.service';

@Component({
    selector: 'nirby-icon-picker',
    templateUrl: './icon-picker.component.html',
    styleUrls: ['./icon-picker.component.scss'],
})
export class IconPickerComponent implements OnInit {
    @Input() icon: string | null = '\uf0e7';
    @Output() iconChange = new EventEmitter<string>();
    searchResults: Icon[] = [];

    constructor(private iconService: IconService) {}

    ngOnInit(): void {
        this.search('');
    }

    selectIcon(icon: Icon): void {
        this.icon = icon.unicode;
        this.iconChange.emit(icon.unicode);
    }

    async search(query: string): Promise<void> {
        this.searchResults = await this.iconService.search(query, 50);
    }

    async onQueryChange(target: any): Promise<void> {
        await this.search(target.value);
    }

    iconFromUnicode(icon: string): string {
        return icon;
    }
}
