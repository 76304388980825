<div class="form-group">
    <label>Design</label>
    <select class="form-control" [value]="design" (change)="setDesign($event)">
        <option value="label">Only Label</option>
        <option value="icon">Only Icon</option>
        <option value="icon-label">Label & Icon</option>
    </select>
</div>
<div class="form-group" *ngIf="label || label?.length === 0">
    <label>Label</label>
    <textarea
        class="form-control"
        [ngModel]="label"
        (ngModelChange)="onLabelChange($event)"
    ></textarea>
</div>
<div class="form-group" *ngIf="icon">
    <nirby-icon-picker
        [icon]="icon"
        (iconChange)="onIconChange($event)"
    ></nirby-icon-picker>
</div>
