import {Component, OnInit} from '@angular/core';
import {MaintenanceService} from './services/maintenance.service';
import {filter, first, map} from 'rxjs/operators';
import {DateTime} from 'luxon';
import {isNowAfter} from '@nirby/shared/widgets';
import {TranslateService} from '@ngx-translate/core';
import {RouteConfigLoadEnd, RouteConfigLoadStart, Router,} from '@angular/router';
import {lastValueFrom} from 'rxjs';
import {LoadScreenService} from '@nirby/shared/load-screen';

@Component({
    selector: 'nirby-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
/**
 * The main App component
 */
export class AppComponent implements OnInit {
    title = 'nirby-pop';
    hideMessages = false;

    /**
     * Constructor.
     * @param maintenance Service to get the maintenance messages.
     * @param translate Service to translate the messages.
     * @param router Router service.
     * @param loader Load screen service.
     */
    constructor(
        private maintenance: MaintenanceService,
        private translate: TranslateService,
        private router: Router,
        private loader: LoadScreenService
    ) {}

    nextMaintenance$ = this.maintenance.nextMaintenance$.pipe(
        map((m) =>
            !m || isNowAfter(m.start)
                ? null
                : {
                      start: m.start.toLocaleString(
                          DateTime.DATETIME_SHORT_WITH_SECONDS
                      ),
                      end: m.end.toLocaleString(
                          DateTime.DATETIME_SHORT_WITH_SECONDS
                      ),
                  }
        )
    );

    /**
     * Initializes the language module
     */
    ngOnInit(): void {
        this.translate.setDefaultLang('en');
        const language = this.translate.getBrowserLang();
        if (language) {
            this.translate.use(language);
        }

        // show loading screen until route is loaded
        this.router.events
            .pipe(
                filter((event): event is RouteConfigLoadStart => event instanceof RouteConfigLoadStart)
            )
            .subscribe(async () => {
                await this.loader.untilCompletion(() =>
                    lastValueFrom(
                        this.router.events.pipe(
                            filter((e) => e instanceof RouteConfigLoadEnd),
                            first()
                        )
                    )
                );
            });
    }

    /**
     * Hides messages
     */
    onClose(): void {
        this.hideMessages = true;
    }
}
