import {firstValueFrom, Observable} from 'rxjs';
import {MimeType} from '@nirby/media/file-detector';
import {VideoRecord} from '@nirby/media-models';
import {VideoService} from './video.service';
import {TusUploadInstance} from './tus-uploader';

/**
 * A service to upload videos to the API.
 */
export abstract class VideoUploadService implements VideoService {
    public static UPLOADABLE_VIDEO_TYPES: MimeType[] = [
        'video/mp4',
        'video/mpeg',
        'video/x-msvideo',
        'video/webm',
        'video/quicktime',
        'video/x-m4v',
    ];

    /**
     * Creates a URL to upload a video to.
     * @param workspaceId The ID of the workspace.
     * @private
     *
     * @returns The URL to upload to.
     */
    protected abstract prepareUploadUrl(workspaceId: string): Observable<string>;

    /**
     * Get the headers to use on the Tus request.
     * @param workspaceId The ID of the workspace.
     * @private
     *
     * @returns The headers to use.
     */
    protected abstract getExtraHeaders(workspaceId: string): Observable<{ [key: string]: string }>;

    /**
     * Patches an Uppy instance to use Tus for uploading.
     *
     * @param workspaceId The ID of the workspace.
     * @param file The file to upload.
     *
     * @returns An observable that emits the Uppy instance.
     */
    public async uploadFile(workspaceId: string, file: File | Blob): Promise<TusUploadInstance> {
        const [duration, uploadUrl, headers] = await Promise.all([
            this.getLocalVideoDurationSeconds(file),
            firstValueFrom(this.prepareUploadUrl(workspaceId)),
            firstValueFrom(this.getExtraHeaders(workspaceId)),
        ]);
        return new TusUploadInstance(
            file,
            headers,
            uploadUrl,
            duration,
        );
    }

    /**
     * Creates a video record when an upload is complete.
     * @param upload - The upload instance.
     * @param title - The title of the video.
     *
     * @returns The video record.
     */
    public async waitAndRecordUpload(upload: TusUploadInstance, title?: string): Promise<VideoRecord> {
        const url = await upload.result;
        const fileUid = url.split('/').pop()?.split('?')[0];
        if (!fileUid) {
            throw new Error('No file UID found in upload URL');
        }
        return {
            id: fileUid,
            metadata: {},
            thumbnail: await this.getVideoThumbnail(fileUid),
            title: title ?? fileUid,
            type: 'cloudflare-stream'
        };
    }

    /**
     * Gets the thumbnail for a video.
     * @param videoUid - The video UID.
     * @protected
     *
     * @returns The thumbnail URL.
     */
    protected abstract getVideoThumbnail(videoUid: string): Promise<string>;

    abstract getVideoFromId(id: string): Promise<VideoRecord | null>;

    abstract getVideoFromUrl(url: string): Promise<VideoRecord | null>;

    abstract getEmbedUrlFromId(id: string): string;

    /**
     * Gets the duration of a local File in seconds.
     * @param file The file to get the duration of.
     *
     * @returns The duration of the file.
     */
    public getLocalVideoDurationSeconds(file: File | Blob): Promise<number> {
        if (file instanceof Blob) {
            return this.getBlobVideoDurationSeconds(file);
        }
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            video.preload = 'metadata';
            video.onloadedmetadata = () => {
                window.URL.revokeObjectURL(video.src);
                resolve(video.duration);
            };
            video.onerror = err => {
                reject(err);
            }
            video.src = URL.createObjectURL(file);
        });
    }

    /**
     * Gets the duration of a local Blob in seconds.
     * @param file The file to get the duration of.
     * @private
     *
     * @returns The duration of the file.
     */
    private async getBlobVideoDurationSeconds(file: Blob): Promise<number> {
        const video = document.createElement('video');
        video.src = URL.createObjectURL(file);
        video.onerror = err => {
            throw err;
        };
        while (video.duration === Infinity || isNaN(video.duration)) {
            await new Promise(resolve => setTimeout(resolve, 100));
            video.currentTime = 1000000 * Math.random();
        }
        return video.duration;
    }
}
