<div class="container">
    <div class="row">
        <div class="card">
            <div class="container">
                <div class="row">
                    <div class="col-4">
                        <nirby-select-tutorial-panel></nirby-select-tutorial-panel>
                    </div>
                    <div class="col-8">
                        <nirby-tutorial-panel></nirby-tutorial-panel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
