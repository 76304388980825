import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AppError} from '@nirby/js-utils/errors';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {DirectoriesService, WorkspaceService} from '@nirby/shared/database';
import {Directory} from '@nirby/models/editor';
import {NirbyDocument} from '@nirby/store/base';

@Component({
    selector: 'nirby-select-directories',
    templateUrl: './select-directories.component.html',
    styleUrls: ['./select-directories.component.scss'],
})
export class SelectDirectoriesComponent implements OnInit {
    @Output() directoryChange = new EventEmitter<NirbyDocument<Directory>>();
    @Input() directory: NirbyDocument<Directory> | null = null;

    public get widgetId(): string {
        return this.widgets.workspaceId;
    }

    fireDirectories$: Observable<NirbyDocument<Directory>[]> = of([]);
    snapDirectories?: Record<string, NirbyDocument<Directory>>;

    constructor(
        private readonly widgets: WorkspaceService,
        private readonly directoryService: DirectoriesService,
    ) {
    }

    ngOnInit(): void {
        this.getAllDirectories();
    }

    getAllDirectories(): void {
        this.fireDirectories$ = this.directoryService
            .collection(this.widgetId)
            .query()
            .watch()
            .pipe(
                tap((dir) => {
                    this.snapDirectories = {};
                    for (const d of dir) {
                        this.snapDirectories[d.id] = d;
                    }
                }),
            );
    }

    isDirectoryDefault(): boolean {
        // TODO: Handle when a directory is called 'No directory'
        return !this.directory;
    }

    getSelectDirectory(directory: string | null): void {
        if (!this.snapDirectories) {
            throw new AppError('Cache of directories undefined');
        }

        this.directoryChange.emit(
            directory ? this.snapDirectories[directory] : undefined,
        );
    }
}
