import {Observable, Subject, Subscription} from 'rxjs';
import {AsyncChannel} from '@nirby/js-utils/async';
import {AnyCardAction, AnyCardActionType, PickStandardAction} from '@nirby/models/nirby-player';
import {Logger} from '@nirby/logger';
import {map} from 'rxjs/operators';

export type ActionHandlerResponse = AnyCardAction | void;
export type ActionHandler<TActionType extends AnyCardActionType> = (
    action: PickStandardAction<TActionType>,
    blockId: string | null,
) => Promise<ActionHandlerResponse> | ActionHandlerResponse;

interface ActionTriggerInstruction {
    action: AnyCardAction;
    blockId: string | null;
}

/**
 * A helper to listen for actions and dispatch them to the correct handler.
 */
export class ActionListener {
    /**
     * The action channel.
     */
    channel = new AsyncChannel();

    /**
     * The action handlers
     * @private
     */
    private methods: {
        [TActionType in AnyCardActionType]?: ActionHandler<TActionType>;
    } = {};

    private globalMethod: ActionHandler<AnyCardActionType> | null = null;

    private readonly actionController = new Subject<ActionTriggerInstruction>();

    /**
     * Watches the executed actions.
     *
     * @returns {Observable<AnyCardAction>} The observable.
     */
    public watchActions(): Observable<AnyCardAction> {
        return this.actionController.pipe(
            map((instruction) => instruction.action),
        );
    }

    /**
     * Triggers and executes a new action.
     * @param action Action to emit
     * @param blockId The ID of the block that emitted the action
     */
    public execute(action: AnyCardAction, blockId: string | null): void {
        this.actionController.next({action, blockId});
    }

    /**
     * Set a listener for every action type.
     * @param listener The listener to set.
     */
    public setGlobalListener(listener: ActionHandler<AnyCardActionType>): void {
        this.globalMethod = listener;
    }

    /**
     * Sets a listener for a specific action type.
     *
     * @param ofType The action type to listen for.
     * @param handler The handler to call when the action is received. If the handler returns a value, it'll be executed
     */
    public setListener<TActionType extends AnyCardActionType>(
        ofType: TActionType,
        handler: ActionHandler<TActionType>,
    ): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.methods[ofType] = handler as any;
    }

    /**
     * Starts listening for emitted actions.
     *
     * Returns a subscription that must be unsubscribed for avoiding memory leaks.
     *
     * @returns {Subscription} The subscription.
     */
    public subscribe(): Subscription {
        return this.actionController.subscribe(async ({action, blockId}) => {
            // get the handler
            const handler = this.methods[action.type] as
                | ActionHandler<AnyCardActionType>
                | undefined;
            if (handler === undefined) {
                return;
            }

            // call the handler
            let response: ActionHandlerResponse;
            try {
                response = await this.channel.wait(async () => await handler(action, blockId));
            } catch (e) {
                Logger.error(e);
                return;
            }

            // if the handler returns undefined, we don't send anything
            if (response === undefined) {
                return;
            }

            // otherwise, emit result as another action
            this.execute(response, blockId);
        });
    }
}
