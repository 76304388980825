<div class="form-group mb-0 d-flex flex-row align-items-center">
    <nirby-variable-selector [variables]="variables" [ngModel]="variable" (ngModelChange)="onVariableChange($event)">
    </nirby-variable-selector>
    <select class="form-control" name="category" id="category" [ngModel]="currentType" (ngModelChange)="onTypeChange($event)">
        <option [ngValue]="'is'">Is</option>
        <option [ngValue]="'is-not'">Is not</option>
    </select>
    <ng-container [ngSwitch]="currentType">
        <nirby-conditional-editor-is
            *ngSwitchCase="'is'"
            name="is"
            [variable]="variable"
            [ngModel]="value"
            (ngModelChange)="onValueChange($event)"
        ></nirby-conditional-editor-is>
        <nirby-conditional-editor-is-not
            *ngSwitchCase="'is-not'"
            name="is-not"
            [variable]="variable"
            [ngModel]="value"
            (ngModelChange)="onValueChange($event)"
        ></nirby-conditional-editor-is-not>
    </ng-container>
</div>
