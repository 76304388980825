import { Logger } from '@nirby/logger';
import { NirbyVariableNullable } from '@nirby/runtimes/state';
import {FLOAT_CONVERTER, BOOL_CONVERTER, INT_CONVERTER, STRING_CONVERTER} from './weak-typed-state';

/**
 * Handles weakly typed session storage, which is used to store the state of the application. Every value is stored as
 * a string (JSON.stringify). And then can be retrieved as any type you need using the corresponding method.
 */
export class StorageService {
    /**
     * Constructor.
     * @param appName The name of the application (will be used as part of the full prefix in the storage).
     * @param prefix The prefix to be used for the storage.
     * @private
     */
    constructor(
        private readonly appName: string,
        private readonly prefix: string,
    ) {}

    /**
     * The key to use for the storage, its format is: "@<appName>/<prefix>/<key>".
     * @param key The key to use for the storage.
     * @private
     *
     * @returns - The full key to use for the storage.
     */
    private keyFor(key: string): string {
        return `@${this.appName}/${this.prefix}/${key}`;
    }

    /**
     * Get the value for the given key as a {@link NirbyVariableNullable} or null if not found.
     * @param key The key to use for the storage.
     * @private
     *
     * @returns - The value for the given key.
     */
    private getRaw(key: string): NirbyVariableNullable | null {
        const result = sessionStorage.getItem(this.keyFor(key));
        if (result === null) {
            return null;
        }
        return JSON.parse(result) ?? null;
    }

    /**
     * Gets the value from the storage as a string.
     * @param name The name of the value to get.
     *
     * @returns - The value for the given key.
     */
    getBool(name: string): boolean | null {
        return BOOL_CONVERTER.convertNullable(this.getRaw(name));
    }

    /**
     * Gets the value from the storage as a float.
     * @param name The name of the value to get.
     *
     * @returns - The value for the given key.
     */
    getFloat(name: string): number | null {
        return FLOAT_CONVERTER.convertNullable(this.getRaw(name));
    }

    /**
     * Gets the value from the storage as an integer.
     * @param name The name of the value to get.
     *
     * @returns - The value for the given key.
     */
    getInt(name: string): number | null {
        return INT_CONVERTER.convertNullable(this.getRaw(name));
    }

    /**
     * Gets the value from the storage as a string.
     * @param name The name of the value to get.
     *
     * @returns - The value for the given key.
     */
    getString(name: string): string | null {
        return STRING_CONVERTER.convertNullable(this.getRaw(name));
    }

    /**
     * Sets the value for the given key.
     * @param name The name of the value to set.
     * @param value The value to set.
     */
    set<T extends NirbyVariableNullable | object>(
        name: string,
        value: T,
    ): void {
        Logger.logStyled('STORAGE', 'Set new storage value', this.keyFor(name));
        sessionStorage.setItem(this.keyFor(name), JSON.stringify(value));
    }

    /**
     * Removes the value for the given key.
     * @param name The name of the value to remove.
     */
    remove(name: string): void {
        sessionStorage.removeItem(this.keyFor(name));
    }

    /**
     * Get a value as-is from the storage.
     * @param name The name of the value to get.
     *
     * @returns - The value for the given key.
     */
    get(
        name: string,
    ): NirbyVariableNullable | Record<string, NirbyVariableNullable> {
        const raw = this.getRaw(name);
        if (raw === null) {
            return null;
        }
        return raw;
    }
}
