import {Migrator} from '@nirby/store/migrator';
import {BaseBlock, buildBlockSchema} from '../block.model';
import {z} from 'zod';
import {ZodSchemaBuild} from '@nirby/store/models';

export type WidgetQuestionBlock = BaseBlock<
    'Question',
    {
        question: string;
        successLabel: string;
    }
>;

export const QUESTION_BLOCK_CONTENT_SCHEMA: ZodSchemaBuild<
    WidgetQuestionBlock['content']
> = z.object({
    question: z.string().default(''),
    successLabel: z.string().default(''),
});
export const QUESTION_BLOCK_SCHEMA = buildBlockSchema<WidgetQuestionBlock>(
    'Question' as const,
    QUESTION_BLOCK_CONTENT_SCHEMA,
    z.object({}),
);

export const QUESTION_BLOCK_MIGRATOR = new Migrator<WidgetQuestionBlock>(
    QUESTION_BLOCK_SCHEMA,
    () => ({
        hash: '',
        position: [
            {x: 0, y: 0},
            {x: 128, y: 128},
        ],
        rotation: 0,
        content: {
            question: 'Insert text here...',
            successLabel: 'Answer submitted successfully!',
        },
        style: {},
        actions: {
            click: [],
        },
        type: 'Question',
    }),
);
