<button
    *ngIf="status$ | async as status"
    class="mt-5 w-100 d-flex flex-column align-items-center upload-target"
    [disabled]="status === 'progress'"
    (click)="fileInput.click()"
>
    <div class="flex-1 upload-target-content">
        <ng-container [ngSwitch]="status">
            <div class="text-center" *ngSwitchCase="'idle'">
                Drop a video or click here to upload...
            </div>
            <div class="text-center" *ngSwitchCase="'error'">
                Oh no! Something went wrong. Please try again.
            </div>
            <div class="text-center" *ngSwitchCase="'success'">
                Video uploaded successfully!
            </div>
            <div class="text-center" *ngSwitchCase="'progress'">
                <div class="spinner-border"></div>
            </div>
        </ng-container>
    </div>
    <div
        *ngIf="progress$ | async as progress"
        class="progress-bar w-100"
        [ngStyle]="{'transform': 'scaleX(' + progress + ')'}"
    ></div>
</button>
<input type="file" hidden #fileInput>
