import {Component, forwardRef, Input, OnChanges, SimpleChanges,} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {faCloudUploadAlt, faTrash} from '@fortawesome/free-solid-svg-icons';
import {fillDefault, InnerPartial, InnerRequired, VoidFunction,} from '@nirby/js-utils/types';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ImageRecord} from '@nirby/media-models';

export type MediaLibraryFilters = 'all' | 'favorites' | 'directories';

export interface ImageSelectConfigLibrary {
    filterSelectorShow: boolean;
    filterDefault: MediaLibraryFilters;
    filters: MediaLibraryFilters[];
    mediaList: boolean;
    upload: boolean;
}

export interface ImageSelectConfigSearch {
    unsplash: boolean;
    giphy: boolean;
}

interface ImageSelectConfigNormal {
    display: {
        label: string;
        showClearButton: boolean;
        showPreview: boolean;
        useModal: boolean;
    };
    sources: {
        search?: Partial<ImageSelectConfigSearch>;
        library?: Partial<ImageSelectConfigLibrary>;
        favorites?: boolean;
        upload?: boolean;
        url?: boolean;
    };
}

export type ImageSelectConfig = InnerPartial<ImageSelectConfigNormal>;
type FilledImageSelectConfig = InnerRequired<ImageSelectConfigNormal>;

@Component({
    selector: 'nirby-image-selector',
    templateUrl: './image-selector.component.html',
    styleUrls: ['./image-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ImageSelectorComponent),
            multi: true,
        },
    ],
})
export class ImageSelectorComponent implements OnChanges, ControlValueAccessor {
    constructor(private modal: NgbModal) {
        this.endConfig = Object.assign({}, this.DEFAULT_CONFIG);
    }

    @Input() config?: InnerPartial<ImageSelectConfig>;

    disabled = false;
    image: ImageRecord | null = null;

    private readonly DEFAULT_CONFIG: FilledImageSelectConfig = {
        display: {
            label: 'Select Image',
            showClearButton: true,
            showPreview: false,
            useModal: false,
        },
        sources: {
            search: {
                unsplash: true,
                giphy: true,
            },
            library: {
                filterSelectorShow: true,
                filterDefault: 'all',
                filters: ['all', 'favorites', 'directories'],
                mediaList: true,
                upload: true,
            },
            favorites: true,
            upload: true,
            url: true,
        },
    };
    endConfig: ImageSelectConfigNormal;

    iconCloudUpload = faCloudUploadAlt;
    iconTrash = faTrash;

    modalReference: NgbModalRef | null = null;
    onChange: VoidFunction<ImageRecord | null> = () => {
        return;
    };
    onTouched: VoidFunction<void> = () => {
        return;
    };

    writeValue(obj: ImageRecord | null): void {
        this.image = obj;
    }

    registerOnChange(fn: VoidFunction<ImageRecord | null>): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: VoidFunction<void>): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onImageSelect(image: ImageRecord | null): void {
        this.onChange(image);
        this.image = image;
        if (image && this.endConfig.display.useModal) {
            this.modalReference?.close();
        }
    }

    onURLSelect(url: string): void {
        this.onImageSelect({
            id: '',
            metadata: {},
            source: 'link',
            lastUrl: url,
        });
    }

    clear(): void {
        this.onImageSelect(null);
        this.modalReference?.close();
    }

    openSelectModal(content: any): void {
        this.modalReference = this.modal.open(content, {keyboard: false});
    }

    /**
     * Updates image selector config changes
     * @param changes - changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['config']) {
            if (!this.config) {
                this.endConfig = Object.assign({}, this.DEFAULT_CONFIG);
                return;
            }
            const defaultSources = this.DEFAULT_CONFIG.sources;
            const sources = this.DEFAULT_CONFIG.sources;
            this.endConfig = {
                display: fillDefault(
                    this.config.display,
                    this.DEFAULT_CONFIG.display
                ),
                sources: {
                    search: fillDefault(sources.search, defaultSources.search),
                    library: fillDefault(
                        sources.library,
                        defaultSources.library
                    ),
                    favorites: defaultSources.favorites,
                    upload: defaultSources.upload,
                    url: defaultSources.url,
                },
            };
        }
    }
}
