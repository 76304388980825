import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {WorkspaceUsage} from '@nirby/models/billing';
import {BillingService} from '../../../../services/billing.service';
import {AuthenticationService, WorkspaceService} from '@nirby/shared/database';

@Component({
  selector: 'nirby-settings-widget-billing',
  templateUrl: './settings-widget-billing.component.html',
  styleUrls: ['./settings-widget-billing.component.scss']
})
/**
 * Component to see the plan usage.
 */
export class SettingsWidgetBillingComponent implements OnInit {
    public readonly billingUsage$: Observable<WorkspaceUsage | null> = this.billing.watchBillingUsage();

    public refreshed: boolean | null = null;

    /**
     * The current workspace ID.
     */
    get workspaceId(): string {
        return this.workspaces.workspaceId;
    }

    /**
     * Constructor.
     * @param workspaces Workspaces service.
     * @param billing Billing service.
     * @param auth Authentication service.
     */
    constructor(
        private readonly workspaces: WorkspaceService,
        private readonly billing: BillingService,
        private readonly auth: AuthenticationService,
    ) {
    }

    /**
     * Watches if the usage has been refreshed.
     */
    async ngOnInit(): Promise<void> {
        this.refreshed = await this.billing.refreshBillingUsage(this.workspaces.workspaceId);
    }
}
