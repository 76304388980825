<div
    *ngIf="{widget: widget$ | async} as state"
    class="toolbar-container d-flex justify-content-between align-items-center w-100"
>
    <!--[ngClass]="{'toolbar-container-no-plan': state.widget}"-->
    <div class="d-flex flex-row align-items-center">
        <a
            [routerLink]="workspaceId ? ['/workspaces', workspaceId, 'settings','welcome'] :''"
            queryParamsHandling="merge"
            class="header-dropdown dropdown d-flex flex-row align-items-center p-3 text-white"
        >
            <div class="logo d-flex justify-content-center align-items-center">
                <img
                    src="assets/img/auxi/logo.svg"
                    class="logo-img"
                    alt="Nirby"
                    title="Nirby"
                />
            </div>
        </a>
        <a
            *ngIf="state.widget as widget"
            [routerLink]="['/workspaces']"
            queryParamsHandling="merge"
            class="header-dropdown dropdown d-flex flex-row align-items-center p-3 text-white"
        >
            <div class="me-4 header-btn">
                <fa-icon [icon]="icons.back"></fa-icon>
            </div>
            <span class="workspace-name">{{ widget.data.urlName }}</span>
        </a>
    </div>

    <div
        *ngIf="state.widget as widget"
        class="toolbar-menu d-flex justify-content-center"
        id="header-main-menu"
    >
        <ng-container>
            <ng-container *ngIf="{hasAccess: billing.hasDashboard$ | async} as info">
                <a
                    class="d-flex p-3"
                    [ngStyle]="{opacity: info.hasAccess ? null : 0.4}"
                    [routerLink]="info.hasAccess ? ['dashboard'] : null"
                    routerLinkActive="active current"
                    [ngbTooltip]="info.hasAccess ? null : 'Upgrade to access this feature'"
                >
                    <div
                        class="icon d-flex justify-content-center align-items-center me-1"
                    >
                        <fa-icon [icon]="iconDashboard"></fa-icon>
                    </div>
                    <span class="text d-none d-md-block">Dashboard</span>
                </a>
            </ng-container>
           <!--  <ng-container *ngIf="{hasAccess: billing.hasAccessToPop$ | async} as info">
                <a
                    class="d-flex p-3"
                    [ngStyle]="{opacity: info.hasAccess ? null : 0.4}"
                    [routerLink]="info.hasAccess ? ['campaigns'] : null"
                    routerLinkActive="active current"
                    [ngbTooltip]="info.hasAccess ? null : 'Upgrade to access this feature'"
                >
                    <div
                        class="icon d-flex justify-content-center align-items-center me-1"
                    >
                        <fa-icon [icon]="iconPops"></fa-icon>
                    </div>
                    <span class="text d-none d-md-block">Pops</span>
                </a>
            </ng-container> -->
           <!--  <ng-container *ngIf="{hasAccess: billing.hasAccessToContacts$ | async} as info">
                <a
                    class="d-flex p-3"
                    [ngStyle]="{opacity: info.hasAccess ? null : 0.4}"
                    [routerLink]="info.hasAccess ? ['contacts'] : null"
                    routerLinkActive="active current"
                    [ngbTooltip]="info.hasAccess ? null : 'Upgrade to access this feature'"
                >
                    <div
                        class="icon d-flex justify-content-center align-items-center me-1"
                    >
                        <fa-icon [icon]="iconContacts"></fa-icon>
                    </div>
                    <span class="text d-none d-md-block">Contacts</span>
                </a>
            </ng-container> -->
            <!-- <ng-container *ngIf="{hasAccess: billing.hasAccessToRoutes$ | async} as info">
                <a
                    class="d-flex p-3"
                    [ngStyle]="{opacity: info.hasAccess ? null : 0.4}"
                    [routerLink]="info.hasAccess ? ['routes'] : null"
                    routerLinkActive="active current"
                    [ngbTooltip]="info.hasAccess ? null : 'Upgrade to access this feature'"
                >
                    <div
                        class="icon d-flex justify-content-center align-items-center me-1"
                    >
                        <fa-icon [icon]="iconRoutes"></fa-icon>
                    </div>
                    <span class="text d-none d-md-block">Routes</span>
                </a>
            </ng-container> -->
            <ng-container *ngIf="{hasAccess: billing.hasAccessToPrime$ | async} as info">
                <a
                    class="d-flex p-3"
                    [ngStyle]="{opacity: info.hasAccess ? null : 0.4}"
                    [routerLink]="info.hasAccess ? ['primes'] : null"
                    routerLinkActive="active current"
                    [ngbTooltip]="info.hasAccess ? null : 'Upgrade to access this feature'"
                >
                    <div
                        class="icon d-flex justify-content-center align-items-center me-1"
                    >
                        <fa-icon [icon]="iconPrimes"></fa-icon>
                    </div>
                    <span class="text d-none d-md-block">Primes</span>
                </a>
            </ng-container>
            <ng-container *ngIf="{hasAccess: billing.hasAccessToAnalytics$ | async} as info">
                <a
                    class="d-flex p-3"
                    [ngStyle]="{opacity: info.hasAccess ? null : 0.4}"
                    [routerLink]="info.hasAccess ? ['analytics'] : null"
                    routerLinkActive="active current"
                    [ngbTooltip]="info.hasAccess ? null : 'Upgrade to access this feature'"
                >
                    <div
                        class="icon d-flex justify-content-center align-items-center me-1"
                    >
                        <fa-icon [icon]="iconAnalytics"></fa-icon>
                    </div>
                    <span class="text d-none d-md-block">Analytics</span>
                </a>
            </ng-container>
        </ng-container>
    </div>

    <div class="toolbar-tools d-flex" id="header-tools">
        <div ngbDropdown container="body">
            <button
                class="tool-item no-caret d-flex p-3"
                ngbDropdownToggle
                (click)="$event.preventDefault()"
            >
                <fa-icon [icon]="questionIcon"></fa-icon>
            </button>
            <!-- Dropdown Options.-->
            <ul ngbDropdownMenu>
                <li>
                    <a
                        ngbDropdownItem
                        href="https://fixecl.notion.site/Nirby-Handbook-2218a6776d254040bec71979575b6cac"
                        target="_blank"
                    >
                        Handbook (Tutorials)
                    </a>
                </li>
                <li>
                    <a
                        ngbDropdownItem
                        href="https://forms.gle/VuSBBhrDxmZYeL598"
                        target="_blank"
                    >
                        Give Us Feedback
                    </a>
                </li>
                <li>
                    <a
                        ngbDropdownItem
                        href="https://discord.gg/hpPqnTsYfH"
                        target="_blank"
                    >
                        Join the Discord
                    </a>
                </li>
                <li>
                    <a
                        ngbDropdownItem
                        href="https://forms.gle/cftMoqYan1xAq3jR7"
                        target="_blank"
                    >
                        Contact Us
                    </a>
                </li>
            </ul>
        </div>
        <div ngbDropdown container="body">
            <button
                class="tool-item no-caret d-flex p-3"
                ngbDropdownToggle
                (click)="$event.preventDefault()"
            >
                <fa-icon [icon]="settingsIcon"></fa-icon>
            </button>
            <!-- Dropdown Options.-->
            <ul ngbDropdownMenu *ngIf="state.widget as widget">
                <li>
                    <a
                        ngbDropdownItem
                        routerLinkActive="active current"
                        [routerLink]="['/workspaces', widget.id, 'settings', 'personal']"
                    >
                        Personal
                    </a>
                </li>
                <li>
                    <a
                        ngbDropdownItem
                        routerLinkActive="active current"
                        [routerLink]="['/workspaces', widget.id, 'settings', 'widget']"
                    >
                        Workspace
                    </a>
                </li>
                <!-- Separator -->
                <li role="separator" class="dropdown-divider"></li>
                <li>
                    <a
                        ngbDropdownItem
                        routerLinkActive="active current"
                        [routerLink]="['/workspaces', widget.id, 'settings', 'welcome']"
                    >
                        Welcome
                    </a>
                </li>
                <li>
                    <a
                        *ngIf="false"
                        ngbDropdownItem
                        routerLinkActive="active current"
                        [routerLink]="['/workspaces', widget.id, 'plans']"
                    >
                        Plan Selection
                    </a>
                </li>
                <li>
                    <a
                        ngbDropdownItem
                        routerLinkActive="active current"
                        [routerLink]="['/workspaces', widget.id, 'media-library']"
                    >
                        Media Library
                    </a>
                </li>
                <li>
                    <a
                        ngbDropdownItem
                        routerLinkActive="active current"
                        [routerLink]="['contacts']"
                        >
                        Contacts
                    </a>
                </li>
            </ul>
        </div>
        <button class="tool-item d-flex p-3" (click)="signOut()">
            <fa-icon [icon]="signOutIcon"></fa-icon>
        </button>
    </div>
</div>
