import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    Output,
    ViewChild,
} from '@angular/core';
import {NirbyContext} from '@nirby/runtimes/context';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ArtBoard, ArtBoardItemFactory, NirbyBoardStateNode, scaleStageToFitShape} from '@nirby/runtimes/canvas';
import Konva from 'konva';
import {NirbyPlayerConfig} from '@nirby/models/nirby-player';
import {Logger} from '@nirby/logger';
import {NgTranslator} from '../../services';
import {NIRBY_PLAYER_CONFIG} from '@nirby/shared/player-config';

@Component({
    selector: 'nirby-art-board-view',
    templateUrl: './art-board-view.component.html',
    styleUrls: ['./art-board-view.component.scss'],
})
/**
 * Receives an art board and displays it.
 */
export class ArtBoardViewComponent implements AfterViewInit, OnDestroy {
    @ViewChild('container') containerRef?: ElementRef;

    /**
     * The card to display
     * @param value The card to display
     */
    @Input() set state(value: NirbyBoardStateNode<never, ArtBoard<never>> | null) {
        this.stateSubject.next(value ?? null);
    }

    @Input() context = new NirbyContext(this.translator);

    private readonly stateSubject =
        new BehaviorSubject<NirbyBoardStateNode<never, ArtBoard<never>> | null>(null);
    private readonly state$ = this.stateSubject.asObservable();

    private artBoard: ArtBoard<never> | null = null;

    @Output() loaded = new EventEmitter<void>();

    private subscriptions: Subscription = new Subscription();

    displayStage?: Konva.Stage;

    /**
     * Constructor.
     * @param config The Nirby player configuration
     * @param translator The translator
     */
    constructor(
        @Inject(NIRBY_PLAYER_CONFIG) private readonly config: NirbyPlayerConfig,
        private readonly translator: NgTranslator,
    ) {
    }

    /**
     * Initialize the display stage
     */
    async ngAfterViewInit(): Promise<void> {
        if (!this.container) {
            Logger.warnStyled(
                'CardViewComponent',
                'No container element found',
            );
            return;
        }
        this.displayStage = new Konva.Stage({
            container: this.container,
            width: this.container.clientWidth,
            height: this.container.clientHeight,
        });
        const layer = new Konva.Layer();
        this.displayStage.add(layer);

        this.subscriptions.add(
            this.state$.subscribe(async (state) => {
                this.artBoard?.dispose();
                this.artBoard = null;
                if (state) {
                    const artBoard =
                        ArtBoardItemFactory.instance.create<never, ArtBoard<never>>(
                            state,
                            this.context,
                            false,
                            state.children,
                        );
                    if (!artBoard) {
                        return;
                    }
                    await artBoard.init(layer);
                    this.artBoard = artBoard;
                    this.onResize();
                }
            }),
        );
    }

    /**
     * Dispose the art board
     */
    async ngOnDestroy(): Promise<void> {
        this.artBoard?.dispose();
        this.subscriptions.unsubscribe();
    }

    /**
     * Handle the window resize event to update the stage size
     */
    @HostListener('window:resize')
    onResize(): void {
        if (this.artBoard) {
            scaleStageToFitShape(this.artBoard.shape);
        }
    }

    /**
     * The art board container
     */
    get container(): HTMLDivElement | null {
        return this.containerRef?.nativeElement ?? null;
    }
}
