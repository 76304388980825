import {Component, Input} from '@angular/core';

@Component({
  selector: 'nirby-plan-table-item-usage-item',
  templateUrl: './plan-table-item-usage-item.component.html',
  styleUrls: ['./plan-table-item-usage-item.component.scss']
})
export class PlanTableItemUsageItemComponent {
    @Input() label = '';
    @Input() maximumValue: string | number | null | undefined = null;
    @Input() unit: string | null = null;
    @Input() color = 'rgb(77, 50, 231)';
}
