import Konva from 'konva';
import {Block} from '../block';
import {RectangleBlock} from '@nirby/models/nirby-player';
import {Subscription} from 'rxjs';
import Color from 'color';

export class RectangleBlockDrawable<TMeta> extends Block<TMeta, RectangleBlock, Konva.Rect> {
    async generateShape(): Promise<Konva.Rect> {
        return new Konva.Rect({
            x: this.configPosition.x,
            y: this.configPosition.y,
            width: this.configSize.x,
            height: this.configSize.y,
            rotation: this.properties.rotation,
            cornerRadius:
                this.blockProperties.borderRadius *
                Math.min(this.configSize.x, this.configSize.y) *
                0.5,
            fill: this.blockProperties.color,
        });
    }

    protected override preprocess(options: RectangleBlock): void {
        super.preprocess(options);
    }

    get maxDimension(): number {
        return Math.min(this.shape?.width() ?? 0, this.shape?.height() ?? 0);
    }

    protected async updateShapeFromBlock(): Promise<void> {
        const shape = this.shape;
        if (!shape) {
            return;
        }
        shape.fill(this.blockProperties.color);
        if (this.editable) {
            const isTransparent = new Color(shape.fill()).alpha() < 0.01;
            if (isTransparent) {
                shape.stroke('rgba(49,168,255,0.2)');
            } else {
                shape.stroke('rgba(0,0,0,0)');
            }
        }
        shape.cornerRadius(
            this.blockProperties.borderRadius * this.maxDimension * 0.5
        );
    }

    protected override subscribeToEvents(shape: Konva.Rect): Subscription {
        const subscription = super.subscribeToEvents(shape);
        if (this.editable) {
            subscription.add(
                this.transformController.resizer.postResize$.subscribe(() => {
                    shape.cornerRadius(
                        this.blockProperties.borderRadius *
                        this.maxDimension *
                        0.5
                    );
                })
            );
        }
        return subscription;
    }
}
