import { Type } from '@angular/core';
import { Hostable } from './hostable';

export abstract class ComponentRegisterService<TK, TD> {
    protected abstract register: Map<TK, Type<Hostable<TD>>>;

    public has(type: TK): boolean {
        return this.register.has(type);
    }

    public get<T extends TD>(type: TK): Type<Hostable<T>> | undefined {
        return this.register.get(type) as Type<Hostable<T>> | undefined;
    }
}
