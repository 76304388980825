import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderSelectComponent } from './slider-select/slider-select.component';
import {FormsModule} from '@angular/forms';

@NgModule({
    imports: [CommonModule, FormsModule],
    declarations: [
        SliderSelectComponent
    ],
    exports: [
        SliderSelectComponent
    ]
})
/**
 * The shared slider select module.
 */
export class SharedSliderSelectModule {}
