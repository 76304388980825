import {Encoder} from './encoder';

/**
 * Class to encode and decode custom objects.
 *
 * You can assign a reducer function to apply to the object before encoding. This is useful when you need to serialize
 * an object cannot be serialized using {@link JSON.stringify} like with circular references or just to reduce the size.
 */
export class CustomEncoder<TSource, TEncoded> implements Encoder<TSource> {
    /**
     * Serializes the source object.
     * @param source The source object.
     *
     * @returns The serialized object.
     */
    public static serialize(source: unknown): string {
        return JSON.stringify(source);
    }

    /**
     * Deserializes the source object.
     * @param source The source object.
     *
     * @returns The deserialized object.
     */
    public static deserialize<T>(source: string): T {
        return JSON.parse(source);
    }

    /**
     * Creates an encoder without a reducer. This encoder will not be registered.
     * @returns The encoder.
     */
    public static direct<TSource>(): CustomEncoder<TSource, TSource> {
        return new CustomEncoder<TSource, TSource>(
            (source) => source,
            (destiny) => destiny
        );
    }

    /**
     * Constructor.
     * @param reducer Function to reduce the object before encoding.
     * @param expander Function to unroll the object after decoding (inverse of reducer).
     */
    public constructor(
        private readonly reducer: (source: TSource) => TEncoded,
        private readonly expander: (destiny: TEncoded) => TSource
    ) {
    }

    /**
     * Encodes the source object.
     * @param source The source object.
     * @returns The encoded object.
     */
    public encode(source: TSource): string {
        return CustomEncoder.serialize(this.reduce(source));
    }

    /**
     * Decodes the source object.
     * @param source The source object.
     * @returns The decoded object.
     */
    public decode(source: string): TSource {
        return this.expand(CustomEncoder.deserialize(source));
    }

    /**
     * Reduces the source object.
     * @param source The source object.
     *
     * @returns The reduced object.
     */
    public reduce(source: TSource): TEncoded {
        return this.reducer(source);
    }

    /**
     * Unrolls the encoded object.
     * @param destiny The encoded object.
     *
     * @returns The unrolled object.
     */
    public expand(destiny: TEncoded): TSource {
        return this.expander(destiny);
    }
}
