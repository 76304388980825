import {Component} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {UserData} from '@nirby/models/editor';
import {AuthenticationService, RouteParametersService, WorkspaceService} from '@nirby/shared/database';
import {ActivatedRoute, Router} from '@angular/router';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {filter, map, switchMap} from 'rxjs/operators';
import {AppError} from '@nirby/js-utils/errors';
import {AlertsService} from '@nirby/shared/alerts';
import {User} from '@angular/fire/auth';

@Component({
    selector: 'nirby-settings-widget',
    templateUrl: './settings-widget.component.html',
    styleUrls: ['./settings-widget.component.scss'],
})
export class SettingsWidgetComponent {
    authenticatedUser$: Observable<User | null>;
    authenticatedStoredUser$: Observable<UserData | null>;

    iconDelete = faTrash;
    activeTab$: Observable<string>;

    constructor(
        private auth: AuthenticationService,
        private router: Router,
        private routeParams: RouteParametersService,
        private widgetsService: WorkspaceService,
        private route: ActivatedRoute,
        private parameters: RouteParametersService,
        private toastsService: AlertsService,
    ) {
        const widgetRoute = route.parent?.parent;
        if (!widgetRoute) {
            throw new AppError('Can\'t access widget ID in this route');
        }
        this.authenticatedUser$ = auth.user$;
        this.authenticatedStoredUser$ = auth.authenticatedUserModeled$;

        // get last path in current route
        this.activeTab$ = this.parameters.allRoutes(route).pipe(
            switchMap((r) => combineLatest(r.map((a) => a.url))),
            map((r) => r[r.length - 1]),
            filter((r) => !!r),
            map((r) => r[r.length - 1]?.path ?? ''),
        );
    }

    get widgetId(): string {
        return this.routeParams.workspaceId;
    }

    async deleteWidget(): Promise<void> {
        const answer = await this.toastsService.askConfirmation(
            '¿Are you sure you want to delete this widget?',
            'This action cannot be undone',
        );
        if (answer) {
            try {
                await this.widgetsService.delete(this.widgetId);
            } catch (e) {
                await this.toastsService.showDanger(
                    'An error occurred while deleting widget',
                );
                throw e;
            }
            await this.toastsService.showSuccess('Widget deleted successfully');
            await this.router.navigate(['/']);
        }
    }
}
