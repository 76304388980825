import {Injectable} from '@angular/core';
import {Workspace, WorkspaceFeatures, WorkspaceMembership} from '@nirby/models/editor';
import {firstValueFrom, lastValueFrom, Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {filter, map, switchMap} from 'rxjs/operators';
import {AuthenticationService} from '../auth';
import {UserService} from './user.service';
import {PopService} from './pop.service';
import {RouteParametersService} from '../route-parameters.service';
import {NirbyCollection, NirbyDocument, NirbyDocumentReference, NirbyTransaction} from '@nirby/store/base';

import {COLLECTION_KEYS} from '@nirby/store/collections';
import {Database} from '@nirby/ngutils';

@Injectable({
    providedIn: 'root',
})
/**
 * Service for managing workspaces.
 */
export class WorkspaceService {
    public readonly workspaceId$ = this.routeParams.widgetId$;

    collection = this.db.collection(COLLECTION_KEYS.WORKSPACES);

    users = this.db.collection(COLLECTION_KEYS.USERS);

    public readonly activeWidget$: Observable<NirbyDocument<Workspace>> =
        this.workspaceId$.pipe(
            switchMap((widgetId) => this.collection.get(widgetId)),
            filter((w): w is NirbyDocument<Workspace> => !!w),
        );

    /**
     * Gets the collection of workspaces.
     * @param workspaceId The workspace ID.
     *
     * @returns - The collection of workspaces.
     */
    memberships(workspaceId: string): NirbyCollection<WorkspaceMembership> {
        return this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(workspaceId),
            COLLECTION_KEYS.WORKSPACE_MEMBERSHIPS,
        );
    }

    /**
     * Get the current workspace ID.
     */
    get workspaceId(): string {
        return this.routeParams.workspaceId;
    }

    /**
     * Get the current workspace.
     */
    async getActiveWidget(): Promise<NirbyDocument<Workspace> | null> {
        return await firstValueFrom(
            this.collection.get(this.routeParams.workspaceId),
        );
    }

    /**
     * Constructor.
     * @param http The HTTP client.
     * @param db The Database service.
     * @param routeParams The route parameters service.
     * @param auth The authentication service.
     * @param usersService The users service.
     * @param pops The pop service.
     */
    constructor(
        private http: HttpClient,
        public db: Database,
        private routeParams: RouteParametersService,
        private auth: AuthenticationService,
        private usersService: UserService,
        private pops: PopService,
    ) {
    }

    /**
     * Get the script to
     * @param workspaceId The workspace ID.
     * @param config The arguments for the script
     *
     * @returns - The script.
     */
    getWorkspaceScript(
        workspaceId: string,
        config?: object,
    ): Observable<string> {
        const scriptPath = 'assets/script-template.txt';
        const args = config ? ', ' + JSON.stringify(config) : '';
        return this.http
            .get(scriptPath, {responseType: 'text'})
            .pipe(
                map((s) =>
                    s
                        .replace('{{widgetId}}', `'${workspaceId}'`)
                        .replace('{{args}}', args),
                ),
            );
    }

    /**
     * Get the workspace script with some data overridden.
     * @param workspaceId The workspace ID.
     * @param overrideOrigin The origin to override.
     *
     * @returns - The script.
     */
    getOverrideWidgetScript(
        workspaceId: string,
        overrideOrigin: string,
    ): Observable<string | null> {
        return this.auth.authenticatedUserModeled$.pipe(
            switchMap((user) =>
                user?.isDeveloper
                    ? this.getWorkspaceScript(workspaceId, {overrideOrigin})
                    : of(null),
            ),
            map(
                (s) =>
                    s?.replace('https://popappplay.nir.by', overrideOrigin) ??
                    null,
            ),
        );
    }

    /**
     * Deletes a workspace.
     * @param id The workspace ID.
     */
    async delete(id: string): Promise<void> {
        const widgetRef = this.collection.ref(id);
        const widget = await firstValueFrom(this.collection.get(id));
        if (!widget) {
            return;
        }
        const users = await firstValueFrom(
            this.users
                .query()
                .where('widgets', 'array-contains', {
                    name: widget?.data?.urlName,
                    ref: widgetRef,
                })
                .get(),
        );
        await this.db.runTransaction(async (transaction) => {
            await transaction.update(widgetRef, {
                _deleted: true,
            });
            for (const user of users) {
                await transaction.update(user.ref, {
                    widgets:
                        user.data.widgets?.filter((w) => w.ref.id !== id) ??
                        this.db.deleteField(),
                });
            }
            await this.unpublishAllTransaction(transaction, id);
        });
    }

    // TODO: Move to Published service somehow (circular dependency problem)
    /**
     * Unpublish all Pops for a workspace.
     * @param transaction The transaction.
     * @param workspaceId The workspace ID.
     *
     * @returns - The promise.
     */
    async unpublishAllTransaction(
        transaction: NirbyTransaction,
        workspaceId: string,
    ): Promise<void> {
        const campaignsQuery = await lastValueFrom(
            this.pops.collection(workspaceId).query().get(),
        );
        await Promise.all(
            campaignsQuery
                .map((c) =>
                    c.id
                        ? transaction.delete(
                            this.db
                                .collection(
                                    COLLECTION_KEYS.PUBLISHED.doc(
                                        workspaceId,
                                    ),
                                    COLLECTION_KEYS.PUBLISHED_POPS,
                                )
                                .ref(c.id),
                        )
                        : new Promise((res) => res(null)),
                )
                .filter((s) => !!s),
        );
    }

    /**
     * Gets a workspace reference.
     * @param workspaceId The workspace ID.
     *
     * @returns - The reference.
     */
    getReference(workspaceId: string): NirbyDocumentReference<Workspace> {
        return this.collection.ref(workspaceId);
    }

    /**
     * Updates a workspace.
     * @param workspaceId The workspace ID.
     * @param data The data to update.
     *
     * @returns - A promise.
     */
    async update(workspaceId: string, data: Partial<Workspace>): Promise<void> {
        return await this.collection.update(workspaceId, data);
    }

    /**
     * Creates a workspace.
     * @param workspace The workspace data.
     * @param ownerUserId The owner user ID.
     *
     * @returns - Promise that resolves when the workspace has been created.
     */
    async create(
        workspace: Workspace,
        ownerUserId: string,
    ): Promise<NirbyDocumentReference<Workspace>> {
        return this.collection.runTransaction(async (transaction) => {
            const workspaceRef = this.collection.ref(this.db.generateId());
            transaction.set<Workspace>(workspaceRef, {...workspace});
            transaction.set<WorkspaceMembership>(
                this.memberships(workspaceRef.id).ref(this.db.generateId()),
                {
                    role: 'OWNER',
                    workspaceId: workspaceRef.id,
                    workspaceName: workspace.urlName,
                    userId: ownerUserId,
                },
            );
            return workspaceRef;
        });
    }

    /**
     * Upgrades the old membership system of a user where memberships were stored on the user to the new system where
     * memberships are separate documents.
     * @param userId The user ID.
     *
     * @returns - The promise.
     */
    async upgradeMembership(userId: string): Promise<void> {
        return this.collection.runTransaction(async (transaction) => {
            const user = await transaction.get(this.users.ref(userId));
            const userMemberships = user?.data?.widgets;
            if (!userMemberships) {
                return;
            }
            for (const legacyMembership of userMemberships) {
                const membership: WorkspaceMembership = {
                    role: 'OWNER',
                    workspaceId: legacyMembership.ref.id,
                    workspaceName: legacyMembership.name,
                    userId,
                };
                const membershipRef = this.memberships(
                    legacyMembership.ref.id,
                ).ref(userId);
                transaction.set(membershipRef, membership);
            }
            transaction.update(this.users.ref(userId), {
                widgets: this.db.deleteField(),
            });
        });
    }

    /**
     * Batches an array in chunks of a given size.
     * @param array The array.
     * @param chunkSize The chunk size.
     *
     * @returns - The array of chunks.
     */
    batchArray<T>(array: T[], chunkSize: number): T[][] {
        const chunkedArray = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunkedArray.push(array.slice(i, i + chunkSize));
        }
        return chunkedArray;
    }

    /**
     * Gets the workspace memberships for a user.
     * @param userId The user ID.
     *
     * @returns - The workspaces.
     */
    getUserWorkspaces(userId: string): Observable<NirbyDocument<WorkspaceMembership>[]> {
        return this.db
            .queryGroup(COLLECTION_KEYS.WORKSPACE_MEMBERSHIPS)
            .where('userId', '==', userId)
            .get()
            .pipe(
                map((workspaces) =>
                    workspaces
                        .flat()
                        .sort(
                            (a, b) =>
                                a.updateTime.toMillis() -
                                b.updateTime.toMillis(),
                        ),
                ),
            );
    }

    /**
     * Creates an empty workspace.
     * @param name The workspace name.
     * @param ownerUserId The owner user ID.
     *
     * @returns - The workspace reference.
     */
    async createEmpty(
        name: string,
        ownerUserId: string,
    ): Promise<NirbyDocumentReference<Workspace>> {
        return this.db.runTransaction(async (transaction) => {
            const workspaceRef = this.collection.setTransaction(transaction, {
                installedPlugins: {},
                priorityList: [],
                url: null,
                urlName: name,
                _deleted: false,
                plan: null,
            });
            this.memberships(workspaceRef.id).setTransaction(transaction, {
                role: 'OWNER',
                workspaceId: workspaceRef.id,
                workspaceName: name,
                userId: ownerUserId,
            }, ownerUserId);
            return workspaceRef;
        });
    }

    /**
     * Watches if the current workspace has the given feature.
     * @param feature The feature.
     *
     * @returns - The observable.
     */
    hasFeature<K extends keyof WorkspaceFeatures>(
        feature: K,
    ): Observable<WorkspaceFeatures[K] | null> {
        return this.activeWidget$.pipe(
            map((w) => {
                const features = w?.data?.installedPlugins ?? {};
                return features[feature] ?? null;
            }),
        );
    }

    /**
     * Watches a workspace by its ID.
     * @param workspaceId The workspace ID.
     *
     * @returns - The observable.
     */
    watchById(
        workspaceId: string,
    ): Observable<NirbyDocument<Workspace> | null> {
        return this.collection.watchById(workspaceId);
    }

    /**
     * Checks if a user belongs to a workspace.
     * @param userId The user ID.
     * @param workspaceId The workspace ID.
     *
     * @returns - The promise.
     */
    belongsToUser(userId: string, workspaceId: string): Promise<boolean> {
        return this.memberships(workspaceId).exists(userId);
    }

    /**
     * Watches the workspaces of a user.
     * @param userId The user ID.
     *
     * @returns - The observable.
     */
    watchUserWorkspaces(userId: string): Observable<NirbyDocument<WorkspaceMembership>[]> {
        return this.db
            .queryGroup(COLLECTION_KEYS.WORKSPACE_MEMBERSHIPS)
            .where('userId', '==', userId)
            .watch()
            .pipe(
                map((workspaces) =>
                    workspaces
                        .sort(
                            (a, b) =>
                                b.updateTime.toMillis() - a.updateTime.toMillis(),
                        ),
                ),
            );
    }
}
