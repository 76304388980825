import {Component, OnInit} from '@angular/core';
import {AuthenticationService, WorkspaceService} from '@nirby/shared/database';
import {AlertsService} from '@nirby/shared/alerts';
import {of, switchMap} from 'rxjs';
import {NirbyDocumentReference} from '@nirby/store/base';
import {Workspace} from '@nirby/models/editor';
import {map} from 'rxjs/operators';

/**
 * Displays the workspaces the current user has access to.
 */
@Component({
    selector: 'nirby-workspaces-list',
    templateUrl: './workspaces-list.component.html',
    styleUrls: ['./workspaces-list.component.scss'],
})
export class WorkspacesListComponent implements OnInit {
    constructor(
        private readonly auth: AuthenticationService,
        private readonly workspaces: WorkspaceService,
        private readonly alerts: AlertsService,
    ) {
    }

    workspaces$ = this.auth.authenticatedUser$.pipe(
        switchMap((user) => user ? this.workspaces.watchUserWorkspaces(user.id) : of([])),
    );
    creating = false;
    userIsDeveloper$ = this.auth.authenticatedUser$.pipe(
        map((user) => user?.data.isDeveloper ?? false),
    );

    async ngOnInit(): Promise<void> {
        const user = await this.auth.getCurrentUser();
        if (!user) {
            this.alerts.error('You must be logged in to access this page.');
            return;
        }
        await this.workspaces.upgradeMembership(user.uid);
    }

    /**
     * Creates a workspace with the user's email on it.
     */
    async createWorkspace(): Promise<NirbyDocumentReference<Workspace> | null> {
        const user = await this.auth.getCurrentUser();
        if (!user) {
            this.alerts.error('You must be logged in to create a workspace.');
            return null;
        }
        this.creating = true;
        try {
            return await this.workspaces.createEmpty(`${user.email ?? user.uid}'s workspace`, user.uid);
        } catch (e) {
            this.alerts.error('Failed to create workspace.');
        } finally {
            this.creating = false;
        }
        return null;
    }
}
