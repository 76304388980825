<div *ngIf="false">
    <form [formGroup]="form">
        <div
            class="form-group"
            *ngFor="let attrGroup of attributes.controls; let idx = index"
        >
            <div
                class="alert alert-info"
                *ngIf="declarationGroupToControls(attrGroup) as controls"
            >
                <span class="text-muted" *ngIf="!productionMode">{{
                    controls.id.value
                }}</span>
                <input class="form-control" [formControl]="controls.label" />
                <button class="btn btn-outline-danger" (click)="remove(idx)">
                    <fa-icon [icon]="iconDelete"></fa-icon>
                </button>
            </div>
        </div>
        <button class="w-100 btn btn-outline-info" (click)="add()">
            <fa-icon [icon]="iconAdd"></fa-icon>
        </button>
    </form>
</div>
