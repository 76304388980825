import {Inject, Injectable} from '@angular/core';
import {firstValueFrom, Observable, of, switchMap} from 'rxjs';
import {Database} from '@nirby/ngutils';
import {WorkspaceService} from '@nirby/shared/database';

import {Workspace, WorkspaceSubscription} from '@nirby/models/editor';
import {DateTime} from 'luxon';
import {ApiJwtService} from '@nirby/services/api-jwt-service';
import {
    AnalyticsRole,
    StripeCheckoutSession,
    StripeProduct,
    StripeProductMetadata,
    StripeProductPrice,
    WorkspaceUsage,
} from '@nirby/models/billing';
import {NirbyDocument} from '@nirby/store/base';
import {filter, map} from 'rxjs/operators';
import {CREATORS_ENVIRONMENT, NirbyEnvironment} from '@nirby/environment/base';
import {COLLECTION_KEYS} from '@nirby/store/collections';

export type PlanComparison = 'upgrade' | 'downgrade' | 'current' | 'change';

export type ProductMetadataParsed = {
    [Key in keyof StripeProductMetadata]: number;
};

@Injectable({
    providedIn: 'root',
})
/**
 * A service that provides access to the billing usage.
 */
export class BillingService {
    plans = this.db.collection(COLLECTION_KEYS.STRIPE_PRODUCTS);

    public readonly hasAccessToAnalytics$ = this.workspaces.activeWidget$.pipe(
        map((widget) => {
            if (!widget) {
                return null;
            }
            return widget.data.plan?.productMetadata.analyticsRole ?? 'basic';
        }),
        map((role) => role === 'advanced'),
        map(() => true), // TODO: remove this
    );
    public readonly hasAccessToPrime$ = this.workspaces.activeWidget$.pipe(
        map((widget) => widget?.data?.plan?.productMetadata.hasPrime === 'true'),
        map(() => true), // TODO: remove this
    );

    public readonly hasAccessToPop$ = this.workspaces.activeWidget$.pipe(
        map((widget) => widget?.data?.plan?.productMetadata.hasPop === 'true'),
        map(() => true), // TODO: remove this
    );
    public readonly hasAccessToRoutes$ = this.workspaces.activeWidget$.pipe(
        map((widget) => widget?.data?.plan?.productMetadata.hasRoutes === 'true'),
        map(() => true), // TODO: remove this
    );
    public readonly hasAccessToContacts$ = this.workspaces.activeWidget$.pipe(
        map((widget) => widget?.data?.plan?.productMetadata.hasContacts === 'true'),
        map(() => true), // TODO: remove this
    );
    public readonly hasDashboard$ = this.workspaces.activeWidget$.pipe(
        map(
            (widget) =>
                !!widget?.data?.plan?.productMetadata.analyticsHistoryDays ?? false,
        ),
        map(() => true), // TODO: remove this
    );

    /**
     * The workspace usage collection
     * @param workspaceId The workspace id.
     *
     * @returns The collection.
     */
    usage(workspaceId: string) {
        return this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(workspaceId),
            COLLECTION_KEYS.WORKSPACE_USAGE,
        );
    }

    prices(productId: string) {
        return this.db.collection(
            COLLECTION_KEYS.STRIPE_PRODUCTS.doc(productId),
            COLLECTION_KEYS.STRIPE_PRICES,
        );
    }

    checkoutSessions(userId: string) {
        return this.db.collection(
            COLLECTION_KEYS.USERS.doc(userId),
            COLLECTION_KEYS.STRIPE_CHECKOUT_SESSIONS,
        );
    }

    /**
     * Constructor.
     * @param api The API service.
     * @param db The firestore service.
     * @param environment The environment.
     * @param workspaces The workspaces.
     */
    constructor(
        private readonly api: ApiJwtService,
        private readonly db: Database,
        @Inject(CREATORS_ENVIRONMENT)
        private readonly environment: NirbyEnvironment,
        private readonly workspaces: WorkspaceService,
    ) {
    }

    /**
     * Gets the billing usage.
     * @returns The billing usage.
     */
    public watchBillingUsage(): Observable<WorkspaceUsage | null> {
        return this.workspaces.workspaceId$.pipe(
            switchMap((workspaceId) =>
                this.workspaces.collection.get(workspaceId),
            ),
            switchMap((workspaceDoc) => {
                if (!workspaceDoc) {
                    return of(null);
                }
                const workspace = workspaceDoc.data;
                const subscription = workspace.plan;
                if (!subscription) {
                    return of(null);
                }
                if (!workspace.plan) {
                    return of(null);
                }
                const today = DateTime.utc();
                if (today.day < workspace.plan.anchorDay) {
                    today.minus({month: 1});
                }
                return this.usage(workspaceDoc.id).watchById(
                    today.toFormat('yyyy-MM'),
                );
            }),
            map((usage) => usage?.data ?? null),
        );
    }

    /**
     * Refreshes the billing usage for a workspace.
     * @param workspaceId The workspace id.
     *
     * @returns A promise that resolves when the billing usage has been refreshed.
     */
    public refreshBillingUsage(workspaceId: string): Promise<boolean> {
        return this.api
            .post(`/workspaces/${workspaceId}/billing/usage/refresh`, {})
            .then(() => true)
            .catch((err) => {
                if (err.status === 400) {
                    return false;
                }
                throw err;
            });
    }

    /**
     * Changes a subscription.
     * @param workspaceId The workspace id.
     * @param newPriceId The new price id.
     *
     * @returns A promise that resolves when the subscription has been upgraded.
     */
    public async changeSubscription(
        workspaceId: string,
        newPriceId: string,
    ): Promise<boolean> {
        try {
            await this.api.post(
                `/workspaces/${workspaceId}/billing/subscription/change`,
                {
                    priceId: newPriceId,
                },
            );
            return true;
        } catch (err) {
            if ((err as { status?: number }).status === 400) {
                return false;
            }
            throw err;
        }
    }

    /**
     * Get the current workspace plan.
     * @param workspaceId The workspace id.
     * @param wait Whether to wait for a new plan to be available.
     *
     * @returns The workspace plan.
     */
    public getWorkspacePlan(
        workspaceId: string,
        wait = false,
    ): Observable<NonNullable<Workspace['plan']> | null> {
        return this.workspaces.watchById(workspaceId).pipe(
            map((widget) => widget?.data.plan ?? null),
            filter((plan) => !!plan || !wait),
        );
    }

    /**
     * Gets a workspace plan.
     * @param workspace The workspace.
     *
     * @returns The workspace plan.
     */
    public getWorkspaceStripeProduct(
        workspace: Workspace,
    ): Observable<NirbyDocument<StripeProduct> | null> {
        const planId = workspace.plan?.planId;
        if (!planId) {
            return of(null);
        }
        return this.plans.get(planId);
    }

    /**
     * Gets all the available plans.
     * @param group The group the plans belong to.
     *
     * @returns The available plans.
     */
    getAllPlansOfGroup(
        group: string,
    ): Observable<NirbyDocument<StripeProduct>[]> {
        return this.plans
            .query()
            .where('active', '==', true)
            .where('metadata.nirbyPlanGroup', '==', group)
            .get();
    }

    /**
     * Get a plan's prices for a given currency.
     * @param planId The plan id.
     * @param interval The interval (month or year).
     * @param currency The currency.
     *
     * @returns An observable that emits the plan's prices.
     */
    public getPlanPrice(
        planId: string,
        interval: StripeProductPrice['interval'],
        currency = 'usd',
    ): Observable<NirbyDocument<StripeProductPrice> | null> {
        return this.prices(planId)
            .query()
            .orderBy('unit_amount')
            .where('currency', '==', currency)
            .where('active', '==', true)
            .where('interval', '==', interval)
            .limit(1)
            .get()
            .pipe(map((prices) => prices[0] ?? null));
    }

    /**
     * Creates a checkout session to upgrade the workspace.
     * @param priceId The price ID to subscribe the workspace to.
     * @param userId The user ID.
     * @param workspaceId The workspace ID.
     *
     * @returns The checkout session URL.
     */
    async createCheckoutSessionForSubscription(
        priceId: string,
        userId: string,
        workspaceId: string,
    ): Promise<string> {
        const data: StripeCheckoutSession = {
            allow_promotion_codes: false,
            cancel_url: window.location.origin,
            price: priceId,
            success_url: `${window.location.protocol}//${window.location.host}/workspaces/${workspaceId}/settings/welcome?session_id={CHECKOUT_SESSION_ID}`,
            metadata: {
                workspaceId,
            },
        };
        const ref = await this.checkoutSessions(userId).add(data);
        return await firstValueFrom(
            this.checkoutSessions(userId)
                .watchById(ref.id)
                .pipe(
                    filter(
                        (
                            session,
                        ): session is NirbyDocument<StripeCheckoutSession> =>
                            !!session,
                    ),
                    map((session) => {
                        if (session.data.error) {
                            throw new Error(session.data.error.message);
                        }
                        return session.data.url;
                    }),
                    filter((sessionUrl): sessionUrl is string => !!sessionUrl),
                ),
        );
    }

    private getAnalyticsRoleValue(role: AnalyticsRole | unknown): number {
        const rolesOrder: (AnalyticsRole | unknown)[] = ['basic', 'advanced'];
        const value = rolesOrder.indexOf(role);
        if (value === -1) {
            return 0;
        }
        return value;
    }

    private getBooleanValue(value: 'true' | unknown): number {
        return value === 'true' ? 1 : 0;
    }

    private getIntegerValue(value: string | unknown): number {
        if (typeof value === 'string') {
            return parseInt(value, 10);
        }
        return 0;
    }

    private parseMetadata(
        metadata: Partial<StripeProductMetadata>,
    ): ProductMetadataParsed {
        return {
            hasContacts: this.getBooleanValue(metadata.hasContacts),
            hasPop: this.getBooleanValue(metadata.hasPop),
            hasPrime: this.getBooleanValue(metadata.hasPrime),
            hasRoutes: this.getBooleanValue(metadata.hasRoutes),
            videoDeliveryQuota: this.getIntegerValue(
                metadata.videoDeliveryQuota,
            ),
            videoUploadQuota: this.getIntegerValue(metadata.videoUploadQuota),
            videoStorage: this.getIntegerValue(metadata.videoStorage),
            analyticsHistoryDays: this.getIntegerValue(
                metadata.analyticsHistoryDays,
            ),
            analyticsRole: this.getAnalyticsRoleValue(metadata.analyticsRole),
        };
    }

    private comparePlans(
        a: Partial<StripeProductMetadata>,
        b: Partial<StripeProductMetadata>,
    ): PlanComparison {
        const aValue = Object.values(this.parseMetadata(a));
        const bValue = Object.values(this.parseMetadata(b));

        const isSame = aValue.every((value, index) => value === bValue[index]);
        if (isSame) {
            return 'current';
        }
        const isUpgrade = aValue.every(
            (value, index) => value <= bValue[index],
        );
        if (isUpgrade) {
            return 'upgrade';
        }
        const isDowngrade = aValue.every(
            (value, index) => value >= bValue[index],
        );
        if (isDowngrade) {
            return 'downgrade';
        }
        return 'change';
    }

    comparePrice(
        currentSubscription: WorkspaceSubscription,
        productMetadata: Partial<StripeProductMetadata>,
        price: NirbyDocument<StripeProductPrice>,
    ): PlanComparison {
        if (currentSubscription.priceId === price.id) {
            return 'current';
        }
        const directComparison = this.comparePlans(
            currentSubscription.productMetadata,
            productMetadata,
        );
        switch (directComparison) {
            case 'upgrade':
                return 'upgrade';
            case 'downgrade':
                return 'downgrade';
            case 'current': {
                if (price.data.interval !== currentSubscription.interval) {
                    if (
                        price.data.interval === 'month' &&
                        currentSubscription.interval === 'year'
                    ) {
                        return 'downgrade';
                    }
                    if (
                        price.data.interval === 'year' &&
                        currentSubscription.interval === 'month'
                    ) {
                        return 'upgrade';
                    }
                    return 'change';
                }
                return 'current';
            }
        }
        return 'change';
    }
}
