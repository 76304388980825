<ng-container *ngIf="{isLastAction: isLastAction$ | async} as data">
    <div class="form-group mb-0" *ngIf="data.isLastAction !== null">
        <label for="action-type" class="d-block">Select an Action</label>
        <select
            id="action-type"
            class="form-control"
            [formControl]="typeController"
        >
            <option *ngIf="productType === 'pop'" [ngValue]="'card-link'">
                Go to card
            </option>
            <option [ngValue]="'go-to-url'">Go to link</option>
            <option *ngIf="productType === 'pop'" [ngValue]="'close'">
                Close Pop
            </option>
            <option *ngIf="productType === 'pop'" [ngValue]="'trigger-dom'">Trigger DOM</option>
            <option [ngValue]="'open-form'">Request contact info</option>
            <option [ngValue]="'track-event'">Track event</option>
            <option *ngIf="productType === 'prime'" [ngValue]="'video-link'">
                Go To Source
            </option>
            <option *ngIf="productType === 'prime'" [ngValue]="'go-to-prime'">
                Go To Prime
            </option>
            <option *ngIf="productType === 'prime'" [ngValue]="'go-back'">
                Go Back
            </option>
            <option [ngValue]="'variable-update'">Update variable</option>
        </select>
        <div class="form-group-option pl-3 mt-2" *ngIf="actionControl.valid">
            <ng-template
                nirbyBlockActionHost
                [formControl]="actionControl"
                (closeRequest)="close()"
            ></ng-template>
        </div>
        <div class="d-flex flex-column">
            <nirby-switch-control
                [formControl]="enabledConditionControl"
                label="Conditionals"
            ></nirby-switch-control>
            <nirby-conditional-editor-group
                [hidden]="enabledConditionControl.value !== true"
                [variables]="variables"
                [formControl]="conditionalControl"
            ></nirby-conditional-editor-group>
        </div>
    </div>
</ng-container>
