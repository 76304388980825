import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {FileDropService} from '@nirby/shared/file-drop';
import { MediaLibraryService } from '../../modules/media-library/services/media-library.service';

@Component({
    selector: 'nirby-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent implements OnInit, OnDestroy {
    @Input() isPrivate = false;
    @Input() fileUrl: string | null = null;
    @Output() fileUrlChange = new EventEmitter<string | null>();

    progress$?: Observable<number>;

    private readonly subscription = new Subscription();

    constructor(
        private library: MediaLibraryService,
        private fileDrop: FileDropService,
    ) {}

    ngOnInit(): void {
        this.subscription.add(
            this.fileDrop.watch('Upload to library', FileDropService.UPLOADABLE_IMAGE_TYPES).subscribe(
                async (files) => {
                    if (files.length > 0) {
                        await this.upload({target: {files}});
                    }
                }
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    async upload($event: any): Promise<void> {
        const files: File[] = $event.target.files;
        if (files.length > 0) {
            const file: File = $event.target.files[0];
            this.fileUrl = null;
            this.fileUrlChange.emit(null);
            const task = await this.library.upload(file);
            this.progress$ = task.progress$;
            this.fileUrl = await task.file.then((f) => f.url);
            this.fileUrlChange.emit(this.fileUrl);
        }
    }
}
