import Timeout = NodeJS.Timeout;
import {Logger} from '@nirby/logger';

interface Execution {
    executionTime: number;
    timeout: Timeout;
}

/**
 * Executes a given function only if the given key time has passed.
 */
export class ProcessWaiter {
    private readonly executions: {[key: string]: Execution} = {};

    /**
     * Constructor.
     * @param delay The delay in milliseconds.
     */
    constructor(private readonly delay: number) {}

    /**
     * Executes a given function only if the given key time has passed.
     * @param key The key to check.
     * @param func The function to execute.
     */
    public execute(key: string, func: () => void): void {
        const lastExecution: Execution | undefined = this.executions[key];
        if (lastExecution) {
            clearTimeout(this.executions[key].timeout);
        }

        Logger.logAt('PROCESS-WAITER', `Scheduled process in ${this.delay}ms.`);
        this.executions[key] = {
            executionTime: Date.now(),
            timeout: setTimeout(() => {
                func();
                delete this.executions[key];
            }, this.delay),
        };
    }
}
