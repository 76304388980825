<div class="logo mt-5 mb-3 text-center">
    <img
        src="assets/img/auxi/nbypop-logo-horizontal-white.png"
        alt="Nirby Pop"
        title="Nirby Pop"
        height="120"
    />
</div>
<div class="container mb-5">
    <div class="nirby-card">
        <div class="jumbotron">
            <h1 class="display-4">We'll be back soon! 🤓</h1>
            <p class="lead">
                Nirby Pop is down for scheduled maintenance. Also... Our cute
                little electric hamsters need a well deserved rest before they
                can continue powering our servers. We'll be back online in about
                an hour, after their carrot-rich meal, and bathroom break.
            </p>
            <p class="lead mb-0">🐹🖥</p>
        </div>

        <p class="alert alert-warning">
            While you wait, here's a very important video...
        </p>

        <div class="embed-responsive embed-responsive-16by9">
            <iframe
                class="embed-responsive-item"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/s-I_dV5oY8c"
                title="YouTube video player"
                style="border: 0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            ></iframe>
        </div>
    </div>
</div>
