import {Component, Input, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {fromEvent, merge, Observable, Subject, Subscription, switchMap,} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {OverlayPanel} from 'primeng/overlaypanel';
import {BlockSelectorItemType, BlockSelectorService} from '../../services/block-selector.service';


export interface BlockSelectorItem {
    icon: IconDefinition;
    label: string;
    value: BlockSelectorItemType;
}

@Component({
    selector: 'nirby-block-selector-item',
    templateUrl: './block-selector-item-simple.component.html',
    styleUrls: ['./block-selector-item-simple.component.scss'],
})
/**
 * An item for the block selector, with the ability to insert a sub-menu through content projection. When selected,
 * will emit the selected block through the {@link BlockSelectorService}.
 *
 * @see {@link BlockSelectorService}
 */
export class BlockSelectorItemSimpleComponent implements OnInit, OnDestroy {
    @ViewChild(OverlayPanel) overlayPanel?: OverlayPanel;

    @Input() item: BlockSelectorItem | null = null;

    private readonly subscriptions = new Subscription();

    private readonly mouseDownSubject = new Subject<MouseEvent>();
    private readonly mouseLeaveSubject = new Subject<MouseEvent>();

    /**
     * Constructor.
     * @param service The block selector service.
     */
    constructor(private readonly service: BlockSelectorService) {
    }

    public readonly dragStart$: Observable<MouseEvent | null> = this.mouseDownSubject.pipe(
        switchMap(() =>
            merge(
                // when mouse is pressed and then leaves the element while dragging, we want to start dragging
                this.mouseLeaveSubject,
                // when mouse is pressed and mouse is un-pressed before leaving the element, we want to not start dragging
                fromEvent(window, 'mouseup').pipe(map(() => null))
            ).pipe(first())
        )
    );

    /**
     * Will subscribe to:
     * - Block selections to hide the panel when a block is selected.
     * - Mouse events to handle the drag and drop.
     */
    ngOnInit(): void {
        this.subscriptions.add(
            this.service.selectedBlock$.subscribe(selectedBlock => {
                if (selectedBlock.type === this.item?.value) {
                    this.overlayPanel?.hide();
                }
            })
        )
        this.subscriptions.add(
            this.dragStart$.subscribe((started) => {
                if (!started) {
                    return;
                }
                const value = this.item?.value;
                if (!value) {
                    return;
                }
                if (value === 'art-board') {
                    this.service.selectDrag(value);
                } else {
                    this.service.selectDrag(value);
                }
            })
        );
    }

    /**
     * Will unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * Emits the block selection when clicked or, if a content panel is present, will show the panel.
     * @param item The item to select.
     * @param event The event that triggered the selection.
     * @param overlayPanel The overlay panel to show.
     * @param overlayContainer The overlay container.
     */
    onClick(item: BlockSelectorItem, event: MouseEvent, overlayPanel: OverlayPanel, overlayContainer: HTMLDivElement): void {
        if (overlayContainer.children.length > 0) {
            overlayPanel.show(event);
        } else {
            if (item.value === 'art-board') {
                this.service.selectInstant(item.value);
            } else {
                this.service.selectInstant(item.value);
            }
        }
    }

    /**
     * Will emit the mouse down event.
     * @param $event The mouse down event.
     */
    onMouseDown($event: MouseEvent) {
        this.mouseDownSubject.next($event);
    }

    /**
     * Will emit the mouse leave event.
     * @param $event The mouse leave event.
     */
    onMouseLeave($event: MouseEvent) {
        this.mouseLeaveSubject.next($event);
    }
}
