/**
 * Groups an iterable of objects by a given function.
 * @param array The array to group.
 * @param groupFn The function to group by.
 *
 * @returns The grouped array.
 */
export function groupByIter<T, K extends string | number | symbol | null>(
    array: Iterable<T>,
    groupFn: (item: T) => K
): Map<K, T[]> {
    const map = new Map<K, T[]>();
    for (const item of array) {
        const key = groupFn(item);
        const group = map.get(key) ?? [];
        group.push(item);
        map.set(key, group);
    }
    return map;
}

/**
 * Maps an iterable of objects to an array of objects.}
 * @param iterable The iterable to map.
 * @param mapFn The function to map with.
 */
export function* mapIter<T, U>(
    iterable: Iterable<T>,
    mapFn: (item: T) => U
): Iterable<U> {
    for (const item of iterable) {
        yield mapFn(item);
    }
}
