import {Injectable} from '@angular/core';
import {NirbyCollection, NirbyDocument, QueryBuilder} from '@nirby/store/base';
import {AppCard, PREPARERS} from '@nirby/models/editor';
import {combineLatest, firstValueFrom, Observable, of, switchMap} from 'rxjs';
import {NirbyProductType} from '@nirby/analytics-typings';
import {Router} from '@angular/router';

import {map} from 'rxjs/operators';
import {COLLECTION_KEYS} from '@nirby/store/collections';
import {RouteParametersService} from '../route-parameters.service';
import {WorkspaceService} from './workspace.service';
import {AnyBlock, AnyCardAction, Card, defaultConfig} from '@nirby/models/nirby-player';

import Konva from 'konva';
import {v4} from 'uuid';
import {ArtBoard, ArtBoardItemFactory} from '@nirby/runtimes/canvas';
import {NirbyContext} from '@nirby/runtimes/context';
import {Database} from '@nirby/ngutils';
import {DocumentLike} from '@nirby/store/models';

@Injectable({
    providedIn: 'root',
})
/**
 * Service for managing cards.
 */
export class CardsService {
    public readonly cardRef$: Observable<[string, string]> = combineLatest([
        this.params.widgetId$,
        this.params.watchParamSafe('cardId'),
    ]);

    /**
     * Copies a card, replacing the card and block ids with new ones.
     * @param card The card to copy.
     *
     * @returns - The copied card.
     */
    static copyCard(card: Card): Card {
        // noinspection JSDeprecatedSymbols
        return {
            title: card.title,
            hash: v4(),
            style: {
                fillColor: '#ffffff',
                borderRadius: 6,
                strokeColor: 'rgba(0,0,0,0)',
            },
            blocks: card.blocks.map(
                (block): AnyBlock =>
                    ({
                        ...block,
                        content: {
                            ...block.content,
                        },
                        style: {
                            ...block.style,
                        },
                        position: [
                            {...block.position[0]},
                            {...block.position[1]},
                        ],
                        actions: {
                            click:
                                block.actions['click']?.map(
                                    (action): AnyCardAction => ({...action}),
                                ) ?? [],
                        },
                        hash: v4(),
                    } as AnyBlock),
            ),
        };
    }

    /**
     * Gets the cards' collection.
     * @param workspaceId - The workspace id.
     * @private
     *
     * @returns - The cards collection.
     */
    public collection(workspaceId: string): NirbyCollection<AppCard> {
        return this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(workspaceId),
            COLLECTION_KEYS.CARDS,
        );
    }

    /**
     * Watch siblings cards of the current card.
     */
    public get cardSiblings$(): Observable<NirbyDocument<AppCard>[]> {
        return this.cardRef$.pipe(
            switchMap(([widgetId, cardId]) =>
                this.collection(widgetId).watchById(cardId),
            ),
            switchMap((card) => {
                if (!card) {
                    return of([]);
                }
                return this.listCardSiblings(card);
            }),
        );
    }

    /**
     * Constructor.
     * @param db - The firestore service.
     * @param router - The router service.
     * @param params - The route parameters service.
     * @param workspaces - The workspaces service.
     * @protected
     */
    protected constructor(
        private readonly db: Database,
        private router: Router,
        private params: RouteParametersService,
        private readonly workspaces: WorkspaceService,
    ) {
    }

    /**
     * Watch all cards used by a product
     * @param workspaceId The workspace id
     * @param productRef The product reference
     * @param queryFn The query builder
     *
     * @returns - The observable of cards
     */
    watchAllUsedBy(
        workspaceId: string,
        productRef: AppCard['usedBy'],
        queryFn?: (q: QueryBuilder<AppCard>) => QueryBuilder<AppCard>,
    ): Observable<NirbyDocument<AppCard>[]> {
        let query = this.collection(workspaceId).query();
        if (queryFn) {
            query = queryFn(query);
        }
        return query
            .where('usedBy', '==', productRef)
            .watch();
    }

    /**
     * Watch all cards in use by a given product
     * @param workspaceId The workspace id
     * @param productRef The product reference
     * @param queryFn The query builder
     *
     * @returns - The observable of cards
     */
    getAllUsedBy(
        workspaceId: string,
        productRef: AppCard['usedBy'],
        queryFn?: (ref: QueryBuilder<AppCard>) => QueryBuilder<AppCard>,
    ): Promise<NirbyDocument<AppCard>[]> {
        let query = this.collection(workspaceId).query();
        if (queryFn) {
            query = queryFn(query);
        }
        return firstValueFrom(
            query
                .where('usedBy', '==', productRef)
                .get(),
        );
    }

    /**
     * Gets at which product a card belongs to
     * @param ref The card back reference
     *
     * @returns - The product type ID or null if not found
     */
    getReferenceProductType(
        ref: AppCard['usedBy'],
    ): { type: NirbyProductType; id: string } | null {
        if (!ref) {
            return null;
        }
        switch (ref.parent.id) {
            case 'primes':
                return {
                    type: 'prime',
                    id: ref.id,
                };
            case 'campaigns':
                return {
                    type: 'pop',
                    id: ref.id,
                };
            default:
                return null;
        }
    }

    /**
     * Go to the card editor
     * @param workspaceId The workspace id
     * @param cardId The card id
     */
    async goToEditor(workspaceId: string, cardId: string): Promise<boolean> {
        return this.router.navigate(
            ['/workspaces', workspaceId, 'cards', cardId],
            {
                queryParamsHandling: 'merge',
            },
        );
    }

    /**
     * Lists a card's siblings
     * @param card The card ID
     *
     * @returns - An observable of the cards
     */
    public listCardSiblings(
        card: NirbyDocument<AppCard>,
    ): Observable<NirbyDocument<AppCard>[]> {
        const usedBy = card.data.usedBy;
        if (!usedBy) {
            return of([]);
        }
        const workspaceId = usedBy.getParentDocId();
        if (!workspaceId) {
            return of([]);
        }
        return this.collection(workspaceId)
            .query()
            .where('usedBy', '==', usedBy)
            .watch()
            .pipe(map((cards) => cards.filter((c) => c.id !== card.id)));
    }

    /**
     * Get the product type a card belongs to
     * @param ref The card back reference
     *
     * @returns - The product type ID or null if not found
     */
    getProductTypeOf(ref: AppCard['usedBy']): NirbyProductType | null {
        if (!ref) {
            return null;
        }
        return ref.parent.id === 'primes' ? 'prime' : 'pop';
    }

    /**
     * Watch for the current workspace cards.
     */
    get currentWorkspaceCards$(): Observable<NirbyDocument<AppCard>[]> {
        return this.workspaces.workspaceId$.pipe(
            switchMap((workspaceId) =>
                this.collection(workspaceId).query()
                    .orderBy('title', 'asc')
                    .watch(),
            ),
        );
    }

    /**
     * Creates a preview data URL for a card.
     * @param card The card
     *
     * @returns - A promise that resolver the data URL
     */
    async createPreview(card: Card): Promise<string | null> {
        const container = document.createElement('div');
        const cardState = ArtBoard.cardState<unknown>(
            card,
            defaultConfig.cardSize,
            null,
        );
        const artBoard = new ArtBoardItemFactory().create(
            cardState,
            NirbyContext.mock(),
            false,
            cardState.children,
        );
        if (!artBoard) {
            return null;
        }
        const layer = new Konva.Layer();
        const stage = new Konva.Stage({container});
        stage.add(layer);
        layer.setSize({
            width:
                cardState.properties.position[1].x -
                cardState.properties.position[0].x,
            height:
                cardState.properties.position[1].y -
                cardState.properties.position[0].y,
        });
        await artBoard.init(layer);
        const url = layer.toDataURL();
        artBoard.dispose();
        return url;
    }

    /**
     * Get card by ID
     * @param workspaceId The workspace id
     * @param id The card id
     *
     * @returns - The card
     */
    getById(workspaceId: string, id: string): Promise<DocumentLike<AppCard>> {
        return firstValueFrom(this.collection(workspaceId).get(id)).then(
            (card) => {
                if (!card) {
                    throw new Error(`Card ${id} not found`);
                }

                return card
                    .map((c) => {
                        c.card.blocks = c.card.blocks.map(
                            PREPARERS.prepareBlock,
                        );
                        return c;
                    });
            },
        );
    }
}
