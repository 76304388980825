import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HintComponent } from './hint/hint.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {TooltipModule} from 'primeng/tooltip';



@NgModule({
    declarations: [
        HintComponent
    ],
    exports: [
        HintComponent
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        TooltipModule
    ]
})
/**
 * Module to display a hint icon with a tooltip.
 */
export class HintModule { }
