import {
    ComponentFactoryResolver,
    Directive,
    ViewContainerRef,
} from '@angular/core';
import { Hostable } from '../../../services/hostable';
import { ComponentRegisterService } from '../../../services/component-register';

interface KeyComponent<TK, TD> {
    key: TK;
    component: TD;
}

@Directive({
    selector: '[nirbyHost]',
})
export class HostDirective<TK, TD> {
    /**
     * Constructor.
     * @param viewContainerRef View container reference.
     * @param componentFactoryResolver Component factory resolver.
     * @param register Register service.
     */
    constructor(
        private viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private register: ComponentRegisterService<TK, TD>
    ) {}

    private last: KeyComponent<TK, Hostable<TD>> | null = null;

    host(key: TK): Hostable<TD> | null {
        if (this.last && this.last.key === key) {
            return this.last.component;
        }
        this.viewContainerRef.clear();
        const compCls = this.register.get(key);
        if (!compCls) {
            return null;
        }
        const factory =
            this.componentFactoryResolver.resolveComponentFactory(compCls);

        const injector = this.viewContainerRef.injector;
        const compRef = factory.create(injector);
        this.last = {
            component: compRef.instance,
            key,
        };
        this.viewContainerRef.insert(compRef.hostView);
        return this.last.component;
    }

    fillComponent(data: TD): void {
        if (this.last) {
            this.last.component.fill_(data);
        }
    }
}
