import {Inject, Injectable} from '@angular/core';
import {from, map, Observable, of} from 'rxjs';
import {VideoRecord} from '@nirby/media-models';
import {VideoUploadService} from './video-upload.service';
import {ApiJwtService} from '@nirby/services/api-jwt-service';
import {MEDIA_CONFIG, MediaConfig} from './media-token';

@Injectable({
    providedIn: 'root'
})
/**
 * A service to upload videos to the API through Cloudflare Stream.
 */
export class VideoUploadCloudflareService extends VideoUploadService {
    getVideoFromId(id: string): Promise<VideoRecord | null> {
        throw new Error('Method not implemented.');
    }

    getVideoFromUrl(url: string): Promise<VideoRecord | null> {
        throw new Error('Method not implemented.');
    }

    /**
     * Constructor.
     * @param api The API service.
     * @param config The environment.
     */
    constructor(
        private readonly api: ApiJwtService,
        @Inject(MEDIA_CONFIG) private readonly config: MediaConfig,
    ) {
        super();
    }

    /**
     * Creates a URL to upload a video to.
     * @param workspaceId The ID of the workspace.
     * @private
     *
     * @returns The URL to upload to.
     */
    protected override prepareUploadUrl(workspaceId: string): Observable<string> {
        return of(this.api.getPath(`/workspaces/${workspaceId}/videos/prepare-upload`));
    }


    /**
     * Gets the URL to embed a video from Cloudflare Stream
     * @param id The ID of the video.
     *
     * @returns The URL to embed the video.
     */
    getEmbedUrlFromId(id: string): string {
        const customerCode = this.config.cloudflareCustomerCode;
        return `https://customer-${customerCode}.cloudflarestream.com/${id}/iframe`;
    }

    /**
     * Get the headers to use on the Tus request.
     * @protected
     *
     * @returns The headers to use.
     */
    protected getExtraHeaders(): Observable<{ [p: string]: string }> {
        return from(this.api.getJWT(true)).pipe(map((jwt) => ({Authorization: `Bearer ${jwt}`})));
    }

    /**
     * Gets a video thumbnail.
     * @param videoUid The video UID.
     *
     * @returns The URL to the thumbnail.
     */
    async getVideoThumbnail(videoUid: string): Promise<string> {
        const customerCode = this.config.cloudflareCustomerCode;
        return `https://customer-${customerCode}.cloudflarestream.com/${videoUid}/thumbnails/thumbnail.jpg?time=1s&fit=fill`;
    }
}
