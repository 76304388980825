import {Logger} from '@nirby/logger';
import {NirbyApi} from './nirby-api';

/**
 * Mock API for Nirby.
 *
 * This is used for testing.
 */
export class NirbyApiMock extends NirbyApi {
    /**
     * Constructor.
     */
    constructor() {
        super('');
    }

    /**
     * Mocks a request
     *
     * @returns A promise that resolves with the response.
     */
    override async handleRequest<ResBody>(): Promise<ResBody> {
        return {} as unknown as ResBody;
    }

    /**
     * Mocks a PUT request.
     * @param path The path to the resource.
     * @param body The body of the request.
     *
     * @returns A promise that resolves with the response.
     */
    override async put<ResBody, ReqBody = object>(path: string, body: ReqBody): Promise<ResBody> {
        Logger.logStyled('API:PUT', path, body);
        return this.handleRequest();
    }

    /**
     * Creates a POST request.
     * @param path The path to the resource.
     * @param body The body of the request.
     */
    override async post<ResBody, ReqBody = object>(path: string, body: ReqBody): Promise<ResBody> {
        Logger.logStyled('API:POST', path, body);
        return await this.handleRequest();
    }

    /**
     * Mocks a GET request.
     * @param path The path to the resource.
     * @param queryParams The query parameters to use for the request.
     *
     * @returns A promise that resolves with the response.
     */
    override async get<ResBody>(
        path: string,
        queryParams: { [key: string]: string | number | boolean | undefined },
    ): Promise<ResBody> {
        Logger.logStyled('API:GET', path, queryParams);
        return await this.handleRequest();
    }

    /**
     * Mocks a beacon send to the API.
     * @param path The path to the resource.
     * @param data The data to send.
     *
     * @returns The send beacon response.
     */
    override sendBeacon(path: string, data: object): boolean {
        Logger.logStyled('API:BEACON', path, data);
        return true;
    }
}
