import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {WorkspacesListComponent} from './workspaces-list/workspaces-list.component';

const routes: Routes = [
    {
        path: '',
        component: WorkspacesListComponent,
    },
    {
        path: ':widgetId',
        loadChildren: () => import('../../workspace.module').then(m => m.WorkspaceModule),
    }
]


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forChild(routes)
    ]
})
export class WorkspaceCreationRoutingModule {
}
