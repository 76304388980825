import { Migrator } from '@nirby/store/migrator';
import { z } from 'zod';

export interface Directory {
    name: string;
    color: string | null;
}

export const DIRECTORY_SCHEMA = z.object({
    name: z.string().default('Untitled'),
    color: z.string().nullable().default(null),
});

export const DIRECTORY_MIGRATOR = new Migrator<Directory>(
    DIRECTORY_SCHEMA,
    (): Directory => ({
        name: 'Directory',
        color: null,
    })
);
