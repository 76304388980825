<header class="component-content-header p-3">
    <h1 class="main-title mb-0">Billing Settings</h1>
</header>
<div class="component-content-inner p-3">
    <p>
        Check your subscription details from the customer portal
    </p>
    <a class="btn btn-primary" [href]="billingCustomerPortalUrl" target="_blank">
        Go to customer portal
    </a>
</div>
