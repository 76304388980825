import { BaseShapeUnitDescription } from '../unit.model';
import { UnitAttributes } from './attributes.model';
import { Bounds, ShapeUnit } from './shape-unit';
import Konva from 'konva';
import { BlockContext, ShapeUnitRawDescription } from './index';
import { clamp } from '@nirby/js-utils/math';

export type BorderRadiusMode = 'absolute' | 'relative';

export interface RectUnitAttributes extends UnitAttributes {
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
    borderRadius: number;
    borderRadiusMode: BorderRadiusMode;
    shadowColor: string;
    shadowBlur: number;
    shadowOffsetX: number;
    shadowOffsetY: number;
}

export type RectUnit = BaseShapeUnitDescription<'Rect', RectUnitAttributes>;

export class RectShapeUnit extends ShapeUnit<'Rect'> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(
        id: string,
        context: BlockContext,
        rootGroup: Konva.Shape,
        children: ShapeUnit<any>[],
        editable: boolean,
        initialDescription: ShapeUnitRawDescription<'Rect'>,
    ) {
        super(id, context, rootGroup, children, editable, initialDescription);
    }

    defaultValues: RectUnitAttributes = {
        backgroundColor: '#ffffff',
        borderColor: 'rgba(0,0,0,0)',
        borderRadius: 0,
        borderRadiusMode: 'absolute',
        borderWidth: 0,
        shadowBlur: 0,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        shadowColor: 'rgba(0,0,0,0.15)',
        cursor: 'ignore',
    };

    public static drawRoundedRect(
        ctx: CanvasRenderingContext2D,
        x: number,
        y: number,
        width: number,
        height: number,
        radius: { tl: number; tr: number; bl: number; br: number } | number,
        minSize = 0.5,
        mode: BorderRadiusMode = 'absolute',
    ): void {
        if (typeof radius === 'number') {
            radius = { tl: radius, tr: radius, bl: radius, br: radius };
        }
        if (mode === 'relative') {
            const minDimension = Math.min(width, height) * 0.5;
            radius = {
                tl: clamp(radius.tl, 0, 1) * minDimension,
                tr: clamp(radius.tr, 0, 1) * minDimension,
                bl: clamp(radius.bl, 0, 1) * minDimension,
                br: clamp(radius.br, 0, 1) * minDimension,
            };
        }
        width = Math.max(minSize, width);
        height = Math.max(minSize, height);
        const maxRadius = Math.max(radius.br, radius.bl, radius.tr, radius.tl);
        if (maxRadius > width / 2 || maxRadius > height / 2) {
            const r = Math.min(width, height) / 2;
            radius.tr = r;
            radius.tl = r;
            radius.bl = r;
            radius.br = r;
        }

        ctx.beginPath();
        ctx.moveTo(x + radius.tl, y);
        ctx.lineTo(x + width - radius.tr, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
        ctx.lineTo(x + width, y + height - radius.br);
        ctx.quadraticCurveTo(
            x + width,
            y + height,
            x + width - radius.br,
            y + height,
        );
        ctx.lineTo(x + radius.bl, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
        ctx.lineTo(x, y + radius.tl);
        ctx.quadraticCurveTo(x, y, x + radius.tl, y);
        ctx.closePath();
    }

    protected draw(
        ctx: CanvasRenderingContext2D,
        attributes: RectUnitAttributes,
        bounds: Bounds,
    ): void {
        const { x, y, width, height } = bounds;
        RectShapeUnit.drawRoundedRect(
            ctx,
            x,
            y,
            width,
            height,
            {
                tl: attributes.borderRadius,
                tr: attributes.borderRadius,
                bl: attributes.borderRadius,
                br: attributes.borderRadius,
            },
            0.5,
            attributes.borderRadiusMode,
        );

        ctx.fillStyle = attributes.backgroundColor;

        // Shadow
        ctx.shadowColor = attributes.shadowColor;
        ctx.shadowBlur = attributes.shadowBlur;
        ctx.shadowOffsetX = attributes.shadowOffsetX;
        ctx.shadowOffsetY = attributes.shadowOffsetY;

        if (attributes.borderWidth !== 0) {
            ctx.strokeStyle = attributes.borderColor;
            ctx.lineWidth = attributes.borderWidth;
            ctx.stroke();
        }

        ctx.fill();

        ctx.shadowColor = '';
        ctx.shadowBlur = 0;
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
    }
}
