import {InjectionToken} from '@angular/core';

export interface MediaConfig {
    cloudflareCustomerCode: string;
    vimeo: {
        apiKey: string;
    },
    youtube: {
        apiKey: string;
    }
}

export const MEDIA_CONFIG = new InjectionToken<MediaConfig>('media-token');

export const MEDIA_TOKEN_PROVIDERS = {
    dev: {
        provide: MEDIA_CONFIG,
        useValue: {
            vimeo: {
                apiKey: '303c9759f9a6dff82c4cbab97e78daff',
            },
            youtube: {
                apiKey: 'AIzaSyB4ivv7dLE0f0_M-LmROhwcqy9PmS0aHos',
            },
            cloudflareCustomerCode: 'eis1b1clphaqvjac',
        } as MediaConfig,
    },
    prod: {
        provide: MEDIA_CONFIG,
        useValue: {
            vimeo: {
                apiKey: '303c9759f9a6dff82c4cbab97e78daff',
            },
            youtube: {
                apiKey: 'AIzaSyDznDD4y4f3toPEwuAlGMEXB8RIWfELTW4',
            },
            cloudflareCustomerCode: 'eis1b1clphaqvjac',
        }
    }
};

