import {Encoder} from './encoder';

/**
 * Class to handle clipboard operations on custom objects. Before pasting, the clipboard is encoded using a custom
 * encoder.
 */
export class ClipboardCustom<T> {
    public static clipboardKey = 'clipboard';

    /**
     * The block on the clipboard.
     * @param value The block to set.
     * @private
     */
    protected set clipboard(value: T | null) {
        if (!value) {
            localStorage.removeItem(ClipboardCustom.clipboardKey);
            return;
        }
        localStorage.setItem(ClipboardCustom.clipboardKey, this.encoder.encode(value));
    }

    /**
     * The block on the clipboard.
     * @private
     */
    protected get clipboard(): T | null {
        const value = localStorage.getItem(ClipboardCustom.clipboardKey);
        if (!value) {
            return null;
        }
        return this.encoder.decode(value);
    }

    /**
     * Constructor.
     * @param encoder The encoder to use for storing and retrieving the clipboard value.
     * @param pasteLogic The logic to use for pasting.
     * @param preprocessor A function to mutate the given object before pasting. The result will be passed to the
     * pasteLogic and stored in the clipboard.
     */
    constructor(
        private readonly encoder: Encoder<T>,
        private readonly pasteLogic: (item: T) => unknown | Promise<unknown>,
        private readonly preprocessor?: (item: T) => void | Promise<void>,
    ) {
    }

    /**
     * Stores the given block in the clipboard.
     * @param item The block id to store.
     */
    public copy(item: T): void {
        this.clipboard = item;
    }

    /**
     * Pastes the block from the clipboard using the paste logic.
     * @param overridePaste If given, this block will be pasted instead of the one on the clipboard.
     */
    public async paste(overridePaste?: T): Promise<T | null> {
        let copied: T | null = overridePaste ?? null;
        if (!copied) {
            copied = this.clipboard;
            if (!copied) {
                return null;
            }
        }
        if (this.preprocessor !== undefined) {
            await this.preprocessor(copied);
            if (!overridePaste) {
                this.clipboard = copied;
            }
        }
        await this.pasteLogic(copied);
        return copied;
    }

    /**
     * Clears the clipboard.
     */
    public clear(): void {
        this.clipboard = null;
    }
}
