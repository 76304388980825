import {lastValueFrom, timer} from 'rxjs';

/**
 * Waits the specified amount of milliseconds before resolving the promise.
 * @param ms The amount of milliseconds to wait.
 *
 * @returns A promise that resolves after the specified amount of milliseconds.
 */
export function wait(ms: number): Promise<void> {
    return lastValueFrom(timer(ms)).then();
}
