import { Component } from '@angular/core';
import { BlockContentComponent } from '../../block-content.component';
import {ImageBlockContent} from '@nirby/models/nirby-player';

@Component({
    selector: 'nirby-block-image-content',
    templateUrl: './block-image-content.component.html',
    styleUrls: ['./block-image-content.component.scss'],
})
export class BlockImageContentComponent extends BlockContentComponent<ImageBlockContent> {
    content?: ImageBlockContent;

    setData(content: ImageBlockContent | null): void {
        if (content && content.src !== this.content?.src) {
            this.content = content;
        }
    }

    onNewUrl(url?: string | null): void {
        if (!url) {
            return;
        }
        this.sendData({
            src: url,
        });
    }
}
