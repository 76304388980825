<button
    class="btn btn-dark w-100"
    [fontPicker]="font"
    (fontPickerChange)="selectFont($event)"
    [fpSizeSelect]="false"
    [fpStyleSelect]="false"
    [fpWidth]="'320px'"
    [fpPosition]="'bottom'"
>
    Click to open the font picker ({{ font.family | titlecase }})
</button>
