import { Component, Input } from '@angular/core';
import { AppVariable } from '@nirby/models/editor';
import { BehaviorSubject, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { NirbyDocument } from '@nirby/store/base';
import {
    AbstractControl,
    FormGroup,
    UntypedFormControl,
    Validators,
} from '@angular/forms';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { AlertsService } from '@nirby/shared/alerts';
import { shareReplay, startWith } from 'rxjs/operators';
import { NirbyVariableNullable } from '@nirby/runtimes/state';
import { ProcessWaiter } from '../../../utils';
import { NirbyVariablesService } from '@nirby/shared/database';
import { NirbyDocumentReference } from '@nirby/store/base';

/**
 * A control container for an app variable.
 */
class LabeledFormControl<T extends object> {
    /**
     * Constructor.
     * @param doc The document to use.
     * @param label The label of this control.
     * @param control The control of the value.
     * @param controlEnabled The enabled state of the control.
     */
    constructor(
        public readonly doc: NirbyDocument<T>,
        public readonly label: string,
        public readonly control: UntypedFormControl,
        public readonly controlEnabled: UntypedFormControl
    ) {}

    /**
     * The value of this control.
     */
    get value(): NirbyVariableNullable {
        return this.controlEnabled.value ? this.control.value : null;
    }
}

@Component({
    selector: 'nirby-variables-dialog-form',
    templateUrl: './variables-dialog-form.component.html',
    styleUrls: ['./variables-dialog-form.component.scss'],
})
/**
 * Variables form component for the given product.
 */
export class VariablesDialogFormComponent {
    private readonly waiter = new ProcessWaiter(2000);

    /**
     * Constructor.
     * @param variables The variables service.
     * @param alerts The alerts service.
     */
    constructor(
        private readonly variables: NirbyVariablesService,
        private readonly alerts: AlertsService
    ) {}

    /**
     * The selected product reference.
     * @param value The product reference.
     */
    @Input() set productRef(value: AppVariable['usedBy']) {
        this.productRefSubject.next(value);
    }

    private readonly productRefSubject = new BehaviorSubject<
        AppVariable['usedBy'] | null
    >(null);

    private variablesNames: string[] = [];

    private readonly updateSubject = new Subject<void>();

    public readonly variables$: Observable<NirbyDocument<AppVariable>[]> =
        this.productRefSubject.pipe(
            switchMap((ref) =>
                ref
                    ? this.updateSubject.pipe(
                          startWith(null),
                          switchMap(() => this.variables.listFromProduct(ref))
                      )
                    : of([])
            ),
            tap((variables) => {
                return (this.variablesNames = variables.map(
                    (variable) => variable.data.name
                ));
            }),
            shareReplay({ refCount: true, bufferSize: 1 })
        );

    public readonly form$: Observable<{
        form: FormGroup;
        controls: LabeledFormControl<AppVariable>[];
    }> = this.variables$.pipe(
        switchMap((variables) => {
            const form = new FormGroup({});
            const controls: LabeledFormControl<AppVariable>[] = variables.map(
                (variable) => {
                    const enabled = variable.data.initialValue !== null;
                    const control = new LabeledFormControl(
                        variable,
                        variable.data.name,
                        new UntypedFormControl(
                            variable.data.initialValue ?? '',
                            [Validators.required]
                        ),
                        new UntypedFormControl(enabled, [Validators.required])
                    );
                    form.addControl(control.label, control.control);
                    form.addControl(
                        control.label + '-toggle',
                        control.controlEnabled
                    );
                    if (!enabled) {
                        control.control.disable();
                    }
                    return control;
                }
            );
            return of({ form, controls });
        })
    );
    /**
     * New variable form control
     */
    newVariableControl = new UntypedFormControl('', [
        Validators.required,
        this.variableValidators.bind(this),
    ]);

    icons = {
        add: faPlus,
        delete: faTimes,
    };

    private readonly ALLOWED_CHARACTERS_INITIAL = /^[a-zA-Z]+$/;

    private readonly ALLOWED_CHARACTERS = /^[a-zA-Z0-9_-]+$/;

    /**
     * Validates the variable name is not already used.
     * @param control The control to validate.
     *
     * @returns - The validation result.
     */
    private variableValidators(
        control: AbstractControl
    ): { variableExists: { valid: boolean } } | null {
        return this.variablesNames.includes(control.value)
            ? {
                  variableExists: {
                      valid: false,
                  },
              }
            : null;
    }

    /**
     * Ask confirmation to delete a variable
     * @param event Mouse event
     * @param variableRef Variable to delete
     */
    async confirmDeletion(
        event: MouseEvent,
        variableRef: NirbyDocumentReference<AppVariable>
    ): Promise<void> {
        const answer = await this.alerts.askConfirmation(
            'Are you sure?',
            'The references on the actions will not be removed.'
        );
        if (answer) {
            await this.deleteVariable(variableRef);
        }
    }

    /**
     * Validates variable name from input and input event
     * @param event Input event
     * @param input Input value
     *
     * @returns - Promise
     */
    async beforeInput(
        event: InputEvent,
        input: HTMLInputElement
    ): Promise<void> {
        const inputData = event.data;
        let previousValue = this.newVariableControl.value;

        if (inputData) {
            // check if character is allowed
            for (const char of inputData.split('')) {
                const isFirstCharacter = previousValue.length === 0;
                if (char) {
                    if (isFirstCharacter) {
                        if (this.ALLOWED_CHARACTERS_INITIAL.test(char)) {
                            previousValue += char;
                        }
                    } else {
                        if (inputData === ' ') {
                            previousValue += '-';
                        } else if (this.ALLOWED_CHARACTERS.test(char)) {
                            previousValue += char;
                        }
                    }
                }
            }
            this.newVariableControl.setValue(previousValue);
            event.preventDefault();
        }
        if (event.inputType === 'insertLineBreak') {
            await this.addVariable(previousValue);
            input.focus();
            event.preventDefault();
        }
    }

    /**
     * Creates a variable with the given name.
     * @param variableName Name of the variable
     *
     * @returns - Promise that resolves when the variable is created
     */
    async addVariable(variableName: string): Promise<void> {
        if (this.newVariableControl.invalid) {
            return;
        }
        const productRef = this.productRefSubject.value;
        if (!productRef) {
            return;
        }
        this.newVariableControl.disable();
        await this.variables.create(variableName, productRef);
        this.newVariableControl.setValue('');
        this.newVariableControl.enable();
        this.updateSubject.next();
    }

    /**
     * Deletes a variable
     * @param variableRef Variable to delete
     *
     * @returns Promise<void> Promise that resolves when the variable is deleted
     */
    async deleteVariable(
        variableRef: NirbyDocumentReference<AppVariable>
    ): Promise<void> {
        await this.variables.delete(variableRef);
        this.updateSubject.next();
    }

    /**
     * Disables/enables the value control of a variable
     * @param control Variable control
     */
    onEnabledChange(control: LabeledFormControl<AppVariable>): void {
        if (control.controlEnabled.value) {
            control.control.enable();
        } else {
            control.control.disable();
        }
        this.onValueChange(control);
    }

    /**
     * Updates the value of a variable
     * @param control Variable control
     */
    onValueChange(control: LabeledFormControl<AppVariable>): void {
        this.waiter.execute(control.label, () =>
            this.variables
                .collection(control.doc.parentId)
                .update(control.doc.id, {
                    initialValue: control.value,
                })
        );
    }
}
