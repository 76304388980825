/* eslint-disable @typescript-eslint/no-explicit-any */
import {z, ZodType} from 'zod';
import {
    ACTION_EVENT_PROPS_SCHEMA,
    CLOSE_EVENT_PRIME_PROPS_SCHEMA,
    EventPropertiesSchemaOf,
    GenericNirbyEvent,
    NirbyEvent,
    NirbyEventPropertiesOf,
    NirbyEventTypeOfProduct,
    NirbyProductType,
    PRIME_COMPLETION_EVENT_PROPS_SCHEMA,
    PropertyMap,
    VIEW_CARD_EVENT_PROPS_SCHEMA,
    VIEW_VIDEO_EVENT_PROPS_SCHEMA
} from '../typings';

type ProductSchemas<TProductType extends NirbyProductType> = {
    [TEventType in NirbyEventTypeOfProduct<TProductType>]: EventPropertiesSchemaOf<NirbyEvent<TProductType, TEventType>>
};

const EVENT_SCHEMAS: {
    [TProductType in NirbyProductType]: ProductSchemas<TProductType> | Record<string, undefined>
} = {
    pop: {
        'view-card': VIEW_CARD_EVENT_PROPS_SCHEMA,
        action: ACTION_EVENT_PROPS_SCHEMA,
        close: z.object({}).strict(),
        conversion: z.object({}).strict(),
        impression: z.object({}).strict(),
        view: z.object({}).strict(),
        opportunity: z.object({}).strict(),
        'view-effective': z.object({}).strict()
    },
    prime: {
        'view-video': VIEW_VIDEO_EVENT_PROPS_SCHEMA,
        'view-card': VIEW_CARD_EVENT_PROPS_SCHEMA,
        action: ACTION_EVENT_PROPS_SCHEMA,
        close: CLOSE_EVENT_PRIME_PROPS_SCHEMA,
        impression: z.object({}).strict(),
        view: z.object({}).strict(),
        opportunity: z.object({}).strict(),
        'view-effective': z.object({}).strict(),
        'completion': PRIME_COMPLETION_EVENT_PROPS_SCHEMA
    }
};

/* Validator */

/**
 * Checks if the given properties are valid for the given event and product type.
 * @param productType The product type.
 * @param eventType The event type.
 * @param properties The properties to check.
 *
 * @returns - `true` if the properties are valid, `false` otherwise.
 */
export function validateEventProperties<TP extends NirbyProductType, TT extends NirbyEventTypeOfProduct<TP> | string>(
    productType: TP,
    eventType: TT,
    properties: unknown
): properties is NirbyEventPropertiesOf<GenericNirbyEvent<TP, TT>> {
    const productSchemas = EVENT_SCHEMAS[productType] as ProductSchemas<TP> | Record<string, undefined>;
    const eventSchema = productSchemas[eventType] as EventPropertiesSchemaOf<GenericNirbyEvent<TP, TT>> | undefined;

    let schema: ZodType<PropertyMap>;
    if (eventSchema) {
        schema = eventSchema;
    } else {
        // check that event type has type "custom:<event-name>"
        if (!eventType.match(/^custom:([a-zA-Z0-9-]+)$/)) {
            return false;
        }
        const split = eventType.split(':');
        if (split.length < 2) {
            return false;
        }
        if (split[0] !== 'custom') {
            return false;
        }
        schema = z.object({});
    }

    return schema.safeParse(properties).success;
}
