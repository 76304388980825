import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConditionalEditorSimpleComponent} from './conditional-editor-simple/conditional-editor-simple.component';
import {ConditionalEditorGroupComponent} from './conditional-editor-group/conditional-editor-group.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
    ConditionalEditorIsComponent
} from './conditional-editors/conditional-editor-is/conditional-editor-is.component';
import {
    ConditionalEditorIsNotComponent
} from './conditional-editors/conditional-editor-is-not/conditional-editor-is-not.component';
import {VariableSelectorModule} from '@nirby/shared/variable-selector';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, VariableSelectorModule, FontAwesomeModule],
    declarations: [
        ConditionalEditorSimpleComponent,
        ConditionalEditorGroupComponent,
        ConditionalEditorIsComponent,
        ConditionalEditorIsNotComponent
    ],
    exports: [
        ConditionalEditorGroupComponent,
        ConditionalEditorSimpleComponent,
    ]
})
export class ConditionalEditorModule {}
