import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {WidgetScriptComponent} from './components/widget-script/widget-script.component';
import {HighlightModule} from 'ngx-highlightjs';
import {ClipboardModule} from 'ngx-clipboard';
import {TimestampPipe} from './pipes/timestamp.pipe';
import {PricePipe} from './pipes/price.pipe';
import {ColorPickerComponent} from './components/color-picker/color-picker.component';
import {ButtonCheckboxesComponent} from './components/button-checkboxes/button-checkboxes.component';
import {ButtonSelectComponent} from './components/button-select/button-select.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ColorPickerModule} from 'ngx-color-picker';
import {NirbyCommonModule} from './forms/common.module';

@NgModule({
    declarations: [
        WidgetScriptComponent,
        ColorPickerComponent,
        ButtonCheckboxesComponent,
        ButtonSelectComponent,
        TimestampPipe,
        PricePipe
    ],
    imports: [
        CommonModule,
        HighlightModule,
        ClipboardModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        FormsModule,
        NgbTooltipModule,
        ColorPickerModule,
        NirbyCommonModule
    ],
    exports: [
        WidgetScriptComponent,
        TimestampPipe,
        ColorPickerComponent,
        ButtonCheckboxesComponent,
        ButtonSelectComponent,
        PricePipe
    ],
    providers: [DatePipe],
})
/**
 * Module for the Pop common modules.
 */
export class PopCommonModule {
}

export {ButtonDisplay} from './components/button-select'
