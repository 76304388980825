import Konva from 'konva';
import TextConfig = Konva.TextConfig;

const DECREASE_FACTOR = 0.99;

export function getTextWidth(text: string, dummyText: Konva.Text): number {
    return dummyText._getTextWidth(text);
}

export function getFontSizeToFitInWidth(
    text: string,
    config: TextConfig,
    maxFontSize: number,
    width: number
): number {
    if (text.length === 0) {
        return 1;
    }
    const dummyText = new Konva.Text();
    dummyText.setAttrs({ ...config });

    let fontSize = maxFontSize;
    do {
        dummyText.fontSize(fontSize);
        fontSize *= DECREASE_FACTOR;
    } while (fontSize > 1 && getTextWidth(text, dummyText) > width);
    return fontSize * 0.85;
}
