import {Injectable} from '@angular/core';
import type {VideoService} from './video.service';
import {YoutubeService} from './youtube.service';
import {VimeoService} from './vimeo.service';
import type {VideoSource} from '@nirby/media-models';
import {AppError} from '@nirby/js-utils/errors';
import {VideoUploadCloudflareService} from './video-upload-cloudflare.service';

@Injectable({
    providedIn: 'root',
})
export class VideoFactoryService {
    private services: Record<VideoSource, VideoService | null> = {
        youtube: null,
        vimeo: null,
        'cloudflare-stream': null,
    };

    constructor(
        private youtube: YoutubeService,
        private vimeo: VimeoService,
        private upload: VideoUploadCloudflareService,
    ) {
        this.services.youtube = youtube;
        this.services.vimeo = vimeo;
        this.services['cloudflare-stream'] = upload;
    }

    public getSourceService(source: VideoSource): VideoService {
        const service = this.services[source];
        if (!service) {
            throw new AppError(`No image service for source: ${source}`);
        }
        return service;
    }
}
