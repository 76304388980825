<div class="container-fluid text-center">
    <div class="row grid">
        <div *ngFor="let position of positioning">
            <button
                [disabled]="value === position.value"
                (click)="input.emit(position.value); $event.preventDefault()"
            >
                {{ position.label }}
            </button>
        </div>
    </div>
</div>
