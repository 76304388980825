<form (ngSubmit)="onSubmit()">
    <div class="form-group">
        <label>Vimeo URL</label>
        <div class="input-group">
            <input
                type="text"
                class="form-control"
                [formControl]="urlControl"
                placeholder="i.e. https://vimeo.com/123456789"
                required
            />
            <span
                class="input-group-text"
                *ngIf="validUrl !== null"
                [ngClass]="
                    validUrl === null
                        ? ''
                        : validUrl
                        ? 'text-success'
                        : 'text-danger'
                "
                id="basic-addon2"
            >
                <fa-icon [icon]="validUrl ? okIcon : badIcon"></fa-icon>
            </span>
        </div>
    </div>
    <div class="form-group">
        <div
            class="alert alert-danger mb-2"
            *ngIf="urlControl?.touched && urlControl?.invalid"
        >
            <div *ngIf="(urlControl?.errors)!['required']">A URL is required.</div>
        </div>
        <button
            type="submit"
            [disabled]="loading || urlControl.invalid"
            class="btn btn-brand-primary btn-block"
        >
            Validate & Insert
        </button>
    </div>
    <div class="preview" *ngIf="showPreview">
        <nirby-video-view [video]="video"></nirby-video-view>
    </div>
</form>
