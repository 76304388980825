import { Pipe, PipeTransform } from '@angular/core';
import {Logger} from '@nirby/logger';

@Pipe({
    name: 'price',
})
export class PricePipe implements PipeTransform {
    transform(value: number, decimals = 2): string {
        if (value === 0) {
            return 'Free';
        }
        const rounded = Math.ceil(value);
        if (rounded !== value) {
            Logger.warnStyled(
                'PricePipe',
                `rounded price (${rounded}) is different than the real price (${rounded})`,
            );
        }
        const tag = rounded.toString(10).padStart(decimals, '0');
        const intPart =
            tag.length > decimals ? tag.slice(0, tag.length - decimals) : '0';
        const decimalsPart = tag.slice(tag.length - decimals, tag.length);
        return `${intPart}.${decimalsPart}$`;
    }
}
