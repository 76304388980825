<div class="container">
    <div class="row">
        <div class="col-lg-6 offset-lg-3">
            <ng-container *ngIf="migrationStatus !== 'finished'">
                <div class="area-icon mb-3">
                    <div class="icon-circle text-primary">
                        <fa-icon [icon]="iconClock"></fa-icon>
                    </div>
                </div>
                <h2 class="main-title">We're Updating your Pops!</h2>
                <p class="main-body">
                    Our cute little Working Hamsters are updating your Pops and
                    doing some Behind the Scenes Magic 🐹✨, so everything works
                    perfectly! Please sit tight, this will only take a few
                    seconds (they work pretty fast).
                </p>

                <div class="actions mt-4">
                    <button
                        *ngIf="migrationStatus === 'ready'"
                        class="btn btn-brand-primary"
                        (click)="startMigration()"
                    >
                        Let's do this! 😁
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="migrationStatus === 'finished'">
                <div class="area-icon mb-3">
                    <div class="icon-circle text-success">
                        <fa-icon [icon]="iconCheck"></fa-icon>
                    </div>
                </div>
                <h2 class="main-title">You're all set!</h2>
                <p class="main-body">
                    Our Working Hamsters just finished updating your Pops to the
                    latest version of Nirby 🐹💙. Feel free to
                    <a href="https://discord.gg/hpPqnTsYfH" target="_blank"
                        >reach out</a
                    >
                    if you have any question.
                </p>
                <div class="actions mt-4">
                    <a
                        href="https://fixecl.notion.site/0b680712721a48489072b5d2f1108c13?v=6c081ddee6ae412aa494fee2e270bffa"
                        target="_blank"
                        class="btn btn-outline-light me-2"
                        >What's New?</a
                    >
                    <button
                        class="btn btn-brand-primary"
                        [routerLink]="['/workspaces', widgetId]"
                    >
                        Go To My Pops
                    </button>
                </div>
            </ng-container>

            <ng-container *ngIf="migrationStatus !== 'ready'">
                <div
                    class="alert alert-info d-flex justify-content-space-between align-items-center"
                    *ngIf="migrationStatus === 'migrating'"
                >
                    <div
                        class="spinner-border spinner-border-sm text-color-grey-white me-2"
                    ></div>
                    <p class="mb-0">Migrating your Pops &amp; Data 🐹⚡️🖥...</p>
                </div>
            </ng-container>
        </div>
    </div>
</div>
