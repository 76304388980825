<div class="m-3">
    <div class="nby-modal-header">
        <!--
        <div
            class="d-flex justify-content-center align-items-center"
            [ngSwitch]="options.type"
        >
            <span
                class="nby-modal-type badge badge-primary d-block"
                *ngSwitchCase="'question'"
                >Question</span
            >
            <span
                class="nby-modal-type badge badge-dark d-block"
                *ngSwitchCase="'contact'"
                >Contact Form</span
            >
        </div>
        -->
        <h3 class="nby-modal-title text-center mt-2 mb-4">
            {{ options.title }}
        </h3>
        <p
            class="nby-modal-description text-center"
            *ngIf="options.description"
        >
            {{ options.description }}
        </p>
    </div>

    <div class="nby-modal-body">
        <form [formGroup]="form" (ngSubmit)="sendSubmit()">
            <ng-container [ngSwitch]="options.type">
                <ng-container *ngSwitchCase="'contact'">
                    <div class="form-group" *ngFor="let attr of attributes">
                        <input
                            class="form-control"
                            [id]="attr.declaration.id"
                            [formControl]="attr.control"
                            [autocomplete]="
                                attr.description.autocomplete ?? 'off'
                            "
                            [placeholder]="
                                attr.description.hint
                                    ? attr.description.hint
                                    : (DEFAULT_PLACEHOLDER | translate)
                            "
                        />
                        <div
                            class="error-group"
                            *ngIf="
                                getControlErrorMessage(attr.control) as error
                            "
                        >
                            <span
                                class="text-danger modal-text-danger"
                                *ngIf="error"
                                >{{ error }}</span
                            >
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'question'">
                    <ng-container *ngIf="attributes[0] as attr">
                        <textarea
                            class="form-control"
                            (beforeinput)="onBeforeInput($event, submitButton)"
                            [id]="attr.declaration.id"
                            [formControl]="attr.control"
                            [placeholder]="
                                attr.description.hint
                                    ? attr.description.hint
                                    : (DEFAULT_PLACEHOLDER | translate)
                            "
                            rows="4"
                        ></textarea>
                    </ng-container>
                </ng-container>
            </ng-container>

            <div class="actions mt-4">
                <button
                    #submitButton
                    class="btn btn-primary btn-block"
                    type="submit"
                    [disabled]="form.invalid || form.disabled"
                >
                    {{'SUBMIT' | translate}}
                </button>
            </div>
            <div
                class="alert alert-danger modal-text-danger mt-2"
                *ngIf="errored"
            >
                Huh... something happened... 🤨
            </div>
        </form>
    </div>
</div>
