import {Component, Input, OnInit} from '@angular/core';
import {IClipboardResponse} from 'ngx-clipboard';
import {AlertsService} from '@nirby/shared/alerts';
import {Observable, of} from 'rxjs';
import {WorkspaceService} from '@nirby/shared/database';

@Component({
    selector: 'nirby-widget-script',
    templateUrl: './widget-script.component.html',
    styleUrls: ['./widget-script.component.scss'],
})
/**
 * Component to copy the script for a widget
 */
export class WidgetScriptComponent implements OnInit {
    @Input() widgetId?: string;

    generatedScript$: Observable<string | null> = of(null);
    generatedLocalScript$: Observable<string | null> = of(null);
    generatedStagingScript$: Observable<string | null> = of(null);

    /**
     * Constructor.
     * @param toastsService Alert service.
     * @param widgetService Widget service.
     */
    constructor(
        private toastsService: AlertsService,
        private widgetService: WorkspaceService
    ) {
    }

    /**
     * Calls the {@link updateScript} method.
     */
    ngOnInit(): void {
        this.updateScript();
    }

    /**
     * Update the script data
     */
    updateScript(): void {
        if (this.widgetId) {
            this.generatedScript$ = this.widgetService.getWorkspaceScript(
                this.widgetId
            );
            this.generatedLocalScript$ =
                this.widgetService.getOverrideWidgetScript(
                    this.widgetId,
                    'http://localhost:4201'
                );
            this.generatedStagingScript$ =
                this.widgetService.getOverrideWidgetScript(
                    this.widgetId,
                    'https://nirby-staging-pop-player.web.app'
                );
        } else {
            this.generatedScript$ = of(null);
            this.generatedLocalScript$ = of(null);
            this.generatedStagingScript$ = of(null);
        }
    }

    /**
     * Show a toast when the script is copied to clipboard
     * @param response the response from the clipboard
     */
    onCopyResponse(response: IClipboardResponse): void {
        if (response.isSuccess) {
            this.toastsService.showSuccess('Copied to clipboard');
        }
    }

    /**
     * Called when copy fails
     * @param error The error
     */
    onCopyError(error: unknown): void {
        console.error(error);
    }
}
