<div
    class="nby-alert mb-0 mt-2 p-2 d-flex flex-column justify-content-center align-items-center"
    [ngClass]="['nby-alert-' + type]"
>
    <p *ngIf="emoji" class="nby-alert-emoji">{{emoji}}</p>
    <p *ngIf="title" class="nby-alert-title">{{title}}</p>
    <div class="nby-alert-content">
        <ng-content></ng-content>
    </div>
</div>
