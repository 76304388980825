import {Component, EventEmitter, Input, Output, TemplateRef, ViewChild,} from '@angular/core';
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'nirby-fading-view-item',
    templateUrl: './fading-view-item.component.html',
    styleUrls: ['./fading-view-item.component.scss'],
})
/**
 * An item of a tab view.
 */
export class FadingViewItemComponent {
    @Input() code: string | null = null;
    @Input() backTo: string | null = null;
    @Input() header: string | null = null;

    @Output() goToRequest = new EventEmitter<string>();

    @ViewChild('templateRef', { read: TemplateRef })
    template: TemplateRef<unknown> | null = null;
    readonly icons = {
        back: faChevronLeft,
    };

    /**
     * Go to a tab.
     * @param destinyCode Item ID.
     */
    goTo(destinyCode: string) {
        this.goToRequest.emit(destinyCode);
    }
}
