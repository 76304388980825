import {
    Directive,
    HostBinding,
    Output,
    EventEmitter,
    HostListener,
    Input,
} from '@angular/core';

@Directive({
    selector: '[nirbyUpload]',
})
export class UploadDirective {
    @Input() isPublic = false;
    @Output() fileDropped = new EventEmitter<FileList>();
    @HostBinding('style.background-color') public background = '#fff';
    @HostBinding('style.opacity') public opacity = '1';
    @Input() validTypes: string[] | null = null;

    @HostListener('dragover', ['$event'])
    public onDragOver(evt: DragEvent): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#9ecbec';
        this.opacity = '0.8';
    }

    @HostListener('dragleave', ['$event'])
    public onDragLeave(evt: DragEvent): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#fff';
        this.opacity = '1';
    }

    @HostListener('drop', ['$event'])
    public ondrop(evt: DragEvent): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#f5fcff';
        this.opacity = '1';
        if (!evt.dataTransfer) {
            return;
        }
        this.fileDropped.emit(evt.dataTransfer.files);
    }
}
