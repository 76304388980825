import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {VideoViewComponent} from './components/video-view/video-view.component';
import {SharedSanitizersModule} from '@nirby/shared/sanitizers';

@NgModule({
    declarations: [
        VideoViewComponent,
    ],
    imports: [CommonModule, SharedSanitizersModule],
    exports: [
        VideoViewComponent
    ]
})
export class MediaVideoViewModule {}
