import {
    ComponentFactoryResolver,
    Directive,
    Inject,
    InjectionToken,
    OnDestroy,
    OnInit,
    ViewContainerRef,
} from '@angular/core';
import {
    BehaviorSubject,
    NEVER,
    Observable,
    Subscription,
    switchMap,
} from 'rxjs';
import {
    distinctUntilChanged,
    distinctUntilKeyChanged,
    filter,
    map,
} from 'rxjs/operators';
import { HostDirective } from './host.directive';
import { BlockController } from '../../../services/block-controller';
import { ComponentRegisterService } from '../../../services/component-register';
import {AnyBlock, AnyBlockType} from '@nirby/models/nirby-player';

@Directive()
/**
 * Base directive to host a block editor component.
 */
export abstract class BlockHostDirective<TMeta, TBK extends keyof AnyBlock>
    extends HostDirective<AnyBlockType, AnyBlock[TBK]>
    implements OnInit, OnDestroy
{
    private readonly blockFormSubject =
        new BehaviorSubject<BlockController<TMeta> | null>(null);

    protected readonly blockForm$: Observable<BlockController<TMeta> | null> =
        this.blockFormSubject.pipe(filter((s): s is BlockController<TMeta> => !!s));

    public get controller(): BlockController<TMeta> | null {
        return this.blockFormSubject.value;
    }

    public set controller(value: BlockController<TMeta> | null) {
        this.blockFormSubject.next(value);
    }

    /**
     * Get the selected block value
     * @private
     */
    private get value(): AnyBlock[TBK] | null {
        const block = this.controller?.value;
        if (!block) {
            return null;
        }
        return block[this.blockKey];
    }

    /**
     * Constructor.
     * @param viewContainerRef The view container ref
     * @param componentFactoryResolver The component factory resolver
     * @param register The component register
     * @protected
     */
    protected constructor(
        viewContainerRef: ViewContainerRef,
        componentFactoryResolver: ComponentFactoryResolver,
        @Inject(DUMMY_TOKEN)
        register: ComponentRegisterService<AnyBlockType, AnyBlock[TBK]>
    ) {
        super(viewContainerRef, componentFactoryResolver, register);
    }

    /**
     * The key which will be updated at the block
     */
    abstract blockKey: TBK;

    subscription = new Subscription();
    componentSubscription = new Subscription();

    block$ = this.blockForm$.pipe(switchMap((form) => form?.value$ ?? NEVER));
    key$: Observable<AnyBlockType> = this.block$.pipe(
        map((b) => b?.type ?? null),
        filter((b): b is AnyBlockType => !!b),
        distinctUntilChanged()
    );
    data$: Observable<AnyBlock[TBK]> = this.block$.pipe(
        filter((b): b is AnyBlock => !!b),
        distinctUntilKeyChanged('hash'),
        map((b) => b[this.blockKey])
    );

    /**
     * Subscribes to changes on data of the block to update the component
     */
    ngOnInit(): void {
        this.subscription.add(
            this.key$.subscribe((key) => this.createComponent(key))
        );
        this.subscription.add(
            this.data$.subscribe((data) => this.fillComponent(data))
        );
    }

    /**
     * Creates the component for the current block type
     * @param blockType The block type
     */
    createComponent(blockType: AnyBlockType): void {
        const component = this.host(blockType);
        if (component) {
            this.componentSubscription.unsubscribe();
            this.componentSubscription = new Subscription();
            this.componentSubscription.add(
                this.blockForm$
                    .pipe(
                        filter((s): s is BlockController<TMeta> => !!s),
                        switchMap((form) =>
                            component.dataChange.pipe(
                                map((data) => ({ data, form }))
                            )
                        )
                    )
                    .subscribe(({ data, form }) => {
                        form.update({
                            [this.blockKey]: { ...(data as object) },
                        });
                    })
            );
        }
        const value = this.value;
        if (value) {
            this.fillComponent(value);
        }
    }

    /**
     * Unsubscribes from everything
     */
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}

const DUMMY_TOKEN = new InjectionToken('', undefined);
