import {DateTime} from 'luxon';
import {z, ZodSchema} from 'zod';
import {NirbyDocumentReference} from './reference';
import {LocalCollectionInfo} from './keys';


export type Serialized<T extends object> = {
    [K in keyof T]: T[K] extends DateTime ? Date : T[K] extends object ? Serialized<T[K]> : T[K];
}


/**
 * Serializes data, converting all DateTime objects to Date objects.
 *
 * @param data The data to flatten.
 * @param dbType The database type.
 *
 * @returns The flattened data.
 */
export function serializeData<T extends object>(data: T, dbType: LocalCollectionInfo<object>['dbSource']): Serialized<T> {
    const serializedSchema: ZodSchema<Serialized<object>> = z.record(
        z.string(),
        z.lazy(
            () => z.union([
                z.string(),
                z.number(),
                z.boolean(),
                z.null(),
                z.array(serializedSchema),
                z
                    .custom<DateTime>((value) => value instanceof DateTime)
                    .transform((value) => value.toJSDate()),
                z
                    .custom<NirbyDocumentReference<object>>((value) => value instanceof NirbyDocumentReference)
                    .transform((value) => ({path: value.path, dbType})),
                z.record(z.string(), serializedSchema),
            ]),
        ),
    );
    return serializedSchema.parse(data) as Serialized<T>;
}
