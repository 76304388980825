import { ImagePosition } from '@nirby/models/nirby-player';

interface CropData {
    cropX: number;
    cropY: number;
    cropWidth: number;
    cropHeight: number;
}

export function getImageClip(
    imageSize: {width: number, height: number},
    size: { width: number; height: number },
    clipPosition: ImagePosition
): CropData {
    const width = size.width;
    const height = size.height;
    const aspectRatio = Math.abs(width / height);

    let newWidth;
    let newHeight;

    const imageRatio = Math.abs(imageSize.width / imageSize.height);

    if (aspectRatio >= imageRatio) {
        newWidth = imageSize.width;
        newHeight = imageSize.width / aspectRatio;
    } else {
        newWidth = imageSize.height * aspectRatio;
        newHeight = imageSize.height;
    }

    const [horizontalClip, verticalClip] = clipPosition.split(' ') as [
        'left' | 'center' | 'right',
        'top' | 'center' | 'bottom'
    ];

    let x = 0;
    let y = 0;

    switch (horizontalClip) {
        case 'left':
            x = 0;
            break;
        case 'center':
            x = (imageSize.width - newWidth) / 2;
            break;
        case 'right':
            x = imageSize.width - newWidth;
            break;
    }

    switch (verticalClip) {
        case 'top':
            y = 0;
            break;
        case 'center':
            y = (imageSize.height - newHeight) / 2;
            break;
        case 'bottom':
            y = imageSize.height - newHeight;
            break;
    }

    return {
        cropX: x,
        cropY: y,
        cropWidth: newWidth,
        cropHeight: newHeight,
    };
}
