import { ElementRef, Injectable, Type } from '@angular/core';
import {
    NgbModal,
    NgbModalOptions,
    NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';

type ModalOverride<T> = {
    [TK in keyof T]?: T[TK];
};

type ModalOptions = NgbModalOptions &
    Partial<{
        dismissAll: boolean;
    }>;

@Injectable({
    providedIn: 'root',
})
export class NirbyModalService {
    constructor(private service: NgbModal) {}

    dismissAll(): void {
        this.service.dismissAll();
    }

    openReference<T>(ref: ElementRef<T>, options?: ModalOptions): NgbModalRef {
        return this.service.open(ref, options);
    }

    openComponent<T>(
        component: Type<T>,
        options?: ModalOptions & { override?: ModalOverride<T> }
    ): NgbModalRef {
        if (options?.dismissAll) {
            this.service.dismissAll();
        }

        const ref = this.service.open(component, options);
        const instance: T = ref.componentInstance;

        const o = options?.override ?? {};

        let overrideKey: string;
        for (overrideKey in o) {
            // eslint-disable-next-line no-prototype-builtins
            if (!o.hasOwnProperty(overrideKey)) {
                continue;
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (instance as any)[overrideKey] = (o as any)[overrideKey];
        }
        return ref;
    }

    async open<TR = unknown, T = any>(
        element: ElementRef<T> | Type<T>,
        options?: ModalOptions & { override?: ModalOverride<T> }
    ): Promise<TR | undefined> {
        let ref: NgbModalRef;
        if (element instanceof ElementRef) {
            ref = this.openReference(element, options);
        } else {
            ref = this.openComponent(element, options);
        }
        return await (ref.result as Promise<TR>).catch(() => undefined);
    }
}
