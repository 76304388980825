import {BaseBlock, buildBlockSchema} from './block.model';
import {z} from 'zod';
import {Migrator} from '@nirby/store/migrator';
import {ZodSchemaBuild} from '@nirby/store/models';

export type RectangleBlockContent = {
    color: string;
};

export const RECTANGLE_BLOCK_CONTENT_SCHEMA: ZodSchemaBuild<RectangleBlockContent> =
    z.object({
        color: z.string().default('#ffffff'),
    });

export type RectangleBlockStyle = {
    borderRadius: number;
};
export const RECTANGLE_BLOCK_STYLE_SCHEMA: ZodSchemaBuild<RectangleBlockStyle> =
    z.object({
        borderRadius: z
            .number()
            .min(0)
            .default(0)
            .catch(() => 0),
    });

export type RectangleBlock = BaseBlock<
    'Rectangle',
    RectangleBlockContent,
    RectangleBlockStyle
>;
export const RECTANGLE_BLOCK_SCHEMA = buildBlockSchema<RectangleBlock>(
    'Rectangle',
    RECTANGLE_BLOCK_CONTENT_SCHEMA,
    RECTANGLE_BLOCK_STYLE_SCHEMA,
);

export const RECTANGLE_BLOCK_MIGRATOR = new Migrator<RectangleBlock>(
    RECTANGLE_BLOCK_SCHEMA,
    () => ({
        actions: {
            click: [],
        },
        content: {
            color: 'rgb(0,0,0)',
        },
        hash: '',
        position: [
            {x: 0, y: 0},
            {x: 128, y: 128},
        ],
        rotation: 0,
        style: {
            borderRadius: 0.5,
        },
        type: 'Rectangle',
    }),
);
