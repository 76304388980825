<div class="sidebar p-0 d-flex flex-column justify-content-between h-100">
    <header class="area-top p-3">
        <h1 class="main-title">List of Routes</h1>
    </header>
    <div class="area-middle flex-1">
        <div
            cdkDropList
            class="list-group"
            id="routes-list"
            role="tablist"
            (cdkDropListDropped)="drop($event)"
            *ngIf="priorityList$ | async as priorityList; else loading"
        >
            <!--NgClass to selected route, draggable list of custom routes. -->
            <div
                class="list-group-item list-group-item-action border-0 d-flex justify-content-between align-items-center"
                *ngFor="let route of priorityList"
                [ngClass]="{
                    active:
                        route &&
                        route.routePath.id === (activatedRouteId$ | async)
                }"
                [cdkDragData]="route"
                [routerLink]="['.', route.routePath.id]"
                cdkDragBoundary=".route-panel"
                cdkDragLockAxis="y"
                cdkDrag
            >
                <!-- Can only by dragged using this icon -->
                <i
                    class="me-3"
                    [ngClass]="{
                        'drag-handle':
                            normalizeRoute(route.route).content !== '/'
                    }"
                    cdkDragHandle
                    [cdkDragHandleDisabled]="
                        normalizeRoute(route.route).content === '/'
                    "
                >
                    <fa-icon
                        [icon]="
                            normalizeRoute(route.route).content !== '/'
                                ? barsIcon
                                : lockIcon
                        "
                    ></fa-icon>
                </i>

                <div class="col d-block p-0">
                    <p class="name mb-0">
                        {{ getRouteDisplayName(route.route) }}
                        <small
                            class="route-secondary-info"
                            *ngIf="normalizeRoute(route.route).content === '/'"
                            >(Home)</small
                        >
                    </p>
                    <span class="badge badge-secondary">{{
                        getRouteTypeDisplayName(route.route)
                    }}</span>
                </div>

                <!-- Dropdown menu. -->
                <div
                    class="actions"
                    ngbDropdown
                    *ngIf="normalizeRoute(route.route).content !== '/'"
                >
                    <button
                        class="btn link p-0"
                        (click)="$event.stopPropagation()"
                        ngbDropdownToggle
                    >
                        <fa-icon [icon]="ellipsisIcon"></fa-icon>
                    </button>
                    <!-- Dropdown Options.-->
                    <div ngbDropdownMenu>
                        <button
                            class="btn btn-primary"
                            (click)="removeRoute(route)"
                            ngbDropdownItem
                        >
                            <fa-icon class="me-2" [icon]="iconTrash"></fa-icon>
                            Remove route
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Loading -->
        <ng-template #loading>
            <div class="row justify-content-center">
                <div class="spinner-border text-primary"></div>
            </div>
        </ng-template>
    </div>

    <!-- Link to open modal for create custom route. -->
    <footer class="area-bottom p-3 d-flex flex-column">
        <div
            [ngbTooltip]="
                (canAddRoute$ | async)
                    ? null
                    : 'You must upgrade your account to create more Routes'
            "
        >
            <button
                [disabled]="(canAddRoute$ | async) !== true"
                type="button"
                class="btn btn-secondary btn-block"
                (click)="openModal()"
            >
                New Route
            </button>
        </div>
        <button
            class="btn btn-brand-primary mt-2"
            (click)="save()"
            [disabled]="isLoading"
        >
            Save changes
        </button>
    </footer>
</div>
