import {Component} from '@angular/core';

@Component({
  selector: 'nirby-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
/**
 * The component for the pricing page.
 */
export class PricingComponent {
}
