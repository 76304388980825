const MIME_NAME_IMAGE = [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'bmp',
    'webp',
    'tiff',
    'vnd.microsoft.icon',
    'svg+xml',
];

export type MimeNameImage = (typeof MIME_NAME_IMAGE)[number];

const MIME_NAME_AUDIO = [
    'mpeg',
    'ogg',
    'wav',
    'webm',
    'aac',
    'flac',
    'midi'
];

export type MimeNameAudio = (typeof MIME_NAME_AUDIO)[number];

const MIME_NAME_VIDEO = [
    'mp4',
    'ogg',
    'webm',
    '3gpp',
    '3gpp2',
    'x-msvideo',
    'x-ms-wmv',
    'mpeg',
    'quicktime',
    'x-m4v',
];

export type MimeNameVideo = (typeof MIME_NAME_VIDEO)[number];

const MIME_TYPE_NAMES = [
    'image',
    'audio',
    'video',
]
export type MimeTypeName = (typeof MIME_TYPE_NAMES)[number];
export type MimeName = MimeNameImage | MimeNameAudio | MimeNameVideo;

export type MimeType = `image/${MimeNameImage}` | `audio/${MimeNameAudio}` | `video/${MimeNameVideo}`;

/**
 * An object to identify a mime type.
 */
export class Mime {
    /**
     * Constructor.
     * @param mediaType - The mime type (e.g. 'image', 'video').
     * @param name - The mime name (e.g. 'jpeg', 'png').
     */
    private constructor(
        public readonly mediaType: MimeTypeName,
        public readonly name: MimeName | null,
    ) {
    }

    /**
     * Checks if a MimeTypeName is valid
     * @param name - The mime type name.
     *
     * @returns True if the mime type name is valid, false otherwise.
     */
    public static isValidType(name: string): name is MimeTypeName {
        return MIME_TYPE_NAMES.includes(name as MimeTypeName);
    }

    /**
     * Checks if a MimeName is for an image.
     *
     * @param name - The mime name.
     *
     * @returns True if the mime name is for an image, false otherwise.
     */
    public static isImage(name: string): name is MimeNameImage {
        return MIME_NAME_IMAGE.includes(name as MimeNameImage);
    }

    /**
     * Checks if a MimeName is for an audio.
     *
     * @param name - The mime name.
     *
     * @returns True if the mime name is for an audio, false otherwise.
     */
    public static isAudio(name: string): name is MimeNameAudio {
        return MIME_NAME_AUDIO.includes(name as MimeNameAudio);
    }

    /**
     * Checks if a MimeName is for a video.
     *
     * @param name - The mime name.
     *
     * @returns True if the mime name is for a video, false otherwise.
     */
    public static isVideo(name: string): name is MimeNameVideo {
        return MIME_NAME_VIDEO.includes(name as MimeNameVideo);
    }

    /**
     * Creates a mime object from a string.
     * @param mime - The mime string.
     *
     * @returns The mime object, or null if the string is not a valid mime.
     */
    public static fromString(mime: string): Mime | null {
        const parts = mime.split('/');
        if (parts.length !== 2) {
            return null;
        }
        const type = parts[0];
        let name: MimeName | null = parts[1];
        name = name === '*' ? null : name;

        if (type === 'image' && (name === null || Mime.isImage(name))) {
            return new Mime(type, name);
        } else if (type === 'audio' && (name === null || Mime.isAudio(name))) {
            return new Mime(type, name);
        } else if (type === 'video' && (name === null || Mime.isVideo(name))) {
            return new Mime(type, name);
        }
        return null;
    }

    /**
     * Compares two mime objects.
     * @param other - The other mime object.
     *
     * @returns True if the two mimes are equal, false otherwise.
     */
    public equals(other: Mime): boolean {
        return this.mediaType === other.mediaType && this.name === other.name;
    }

    /**
     * Returns the mime as a string.
     *
     * @returns The mime as a string.
     */
    public toString(): MimeType {
        return `${this.mediaType}/${this.name ?? '*'}` as MimeType;
    }
}
