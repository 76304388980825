<div class="mb-3">
    <label class="text-dark">Directory</label>
    <select
        *ngIf="fireDirectories$ | async as directories"
        name="customDirectories"
        class="custom-select"
        [ngModel]="directory ? directory.id : null"
        (ngModelChange)="getSelectDirectory($event)"
    >
        <option [selected]="!directory" [ngValue]="null">No directory</option>
        <option
            [selected]="directory?.id === dir?.id"
            *ngFor="let dir of directories"
            [ngValue]="dir.id"
        >
            {{ dir.data.name }}
        </option>
    </select>
</div>
