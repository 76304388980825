import {ContactData} from './contact.model';
import {WeakAttributes, WeakTypedState} from './weak-typed-state';
import jwt_decode from 'jwt-decode';
import {StorageService} from './storage.service';
import {v4} from 'uuid';
import {NirbyVariableNullable} from '@nirby/runtimes/state';
import {Logger} from '@nirby/logger';

interface DecodedContactToken {
    contactId: string;
    contact: ContactData;
    exp: number;
}

/**
 * A session stores the data of the current user of a given product.
 */
export class Contact {

    private static storage_: StorageService | null = null;

    public readonly attributes: WeakTypedState;

    /**
     * The decoded token.
     * @private
     */
    private get decoded(): DecodedContactToken {
        if (!this.token) {
            return {
                contactId: v4(),
                contact: {
                    workspaceId: '',
                    uniqueAlias: 'unauthenticated',
                    group: null,
                    properties: {},
                },
                exp: 0,
            };
        }
        return jwt_decode(this.token);
    }

    /**
     * The unique alias that identifies this contact.
     */
    get uniqueAlias(): string {
        return this.decoded.contact.uniqueAlias;
    }

    /**
     * The workspace ID the user is currently in.
     */
    public get workspaceId(): string {
        return this.decoded.contact.workspaceId;
    }

    /**
     * The properties on the token;
     */
    public get originalAttributes(): WeakAttributes {
        return this.decoded.contact.properties;
    }

    /**
     * The ID of this session
     */
    public get id(): string {
        return this.decoded.contactId;
    }

    /**
     * The group this session is in.
     */
    public get group(): string | null {
        return this.decoded.contact.group;
    }

    public readonly storage = new StorageService('nirby', this.id);

    /**
     * Constructor.
     * @param token The contact token to use for this session.
     */
    constructor(public readonly token?: string) {
        this.attributes = new WeakTypedState(this.originalAttributes);
    }

    /**
     * If the current session token exists and is valid.
     */
    public get isAuthenticated(): boolean {
        return !!this.token && Date.now() < this.decoded.exp * 1000;
    }

    /**
     * Creates an unauthenticated session.
     *
     * @returns - The session.
     */
    static unauthenticated(): Contact {
        return new Contact();
    }

    /**
     * Loads the session from the storage.
     * @param workspaceId - The workspace ID to load the session for.
     *
     * @returns - The session.
     */
    public static loadFromStorage(workspaceId: string): Contact | null {
        const token = Contact.workspaceStorage(workspaceId).getString('token');
        if (!token) {
            return null;
        }
        const contact = new Contact(token);
        if (contact.isAuthenticated) {
            return contact;
        }
        return null;
    }

    /**
     * The session storage of the current workspace.
     * @param workspaceId - The workspace ID to load the session for.
     *
     * @returns - The storage.
     */
    private static workspaceStorage(workspaceId: string): StorageService {
        this.storage_ = this.storage_ ?? new StorageService('nirby', workspaceId + '/contact');
        return this.storage_;
    }

    /**
     * Loads the properties from the query parameters.
     *
     * @returns - The properties.
     */
    static loadPropertiesFromQuery(): { [key: string]: NirbyVariableNullable } | null {
        const propertiesRaw = new URLSearchParams(window.location.search).get('_contact');

        let properties: { [key: string]: string } | null = null;
        try {
            properties = propertiesRaw ? JSON.parse(decodeURIComponent(propertiesRaw)) : null;
        } catch (e) {
            Logger.errorStyled('CONTACT', e);
            return null;
        }
        return properties;
    }

    /**
     * Loads the unique alias of a contact from the query parameters.
     *
     * @returns - The unique alias.
     */
    static loadUniqueAliasFromQuery(): string | null {
        const uniqueAliasRaw = new URLSearchParams(window.location.search).get('_ua');
        return uniqueAliasRaw ? decodeURIComponent(uniqueAliasRaw) : null;
    }

    /**
     * Saves this session to the workspace storage.
     */
    public saveToStorage(): void {
        if (this.token && this.workspaceId.length > 0) {
            Contact.workspaceStorage(this.workspaceId).set('token', this.token);
        }
    }
}
