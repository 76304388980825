import Konva from 'konva';
import { BlocksUserInteractions } from './editor';
import { Observable, Subject } from 'rxjs';
import { ArtBoardItemKonvaNode } from './art-board-item';
import Rect = Konva.Rect;
import Vector2d = Konva.Vector2d;

export interface SelectRectOptions {
    draggable: false;
    minWidth: number;
    minHeight: number;
    fill: string;
    stroke: string;
    strokeWidth: number;
}

export class SelectRect {
    private creationArea: Subject<[Vector2d, Vector2d]>;
    public readonly creationArea$: Observable<[Vector2d, Vector2d]>;

    private posA: Vector2d | null = null;
    private posB: Vector2d | null = null;

    private borders: ArtBoardItemKonvaNode | null = null;

    set visible(value: boolean) {
        this.shape.visible(value);
    }

    get visible(): boolean {
        return this.shape.visible();
    }

    get stage(): Konva.Stage | null {
        return this.layer.getStage() ?? null;
    }

    constructor(
        private readonly layer: Konva.Layer,
        private readonly minSize: Vector2d
    ) {
        this.layer.add(this.shape);
        this.creationArea = new Subject<[Vector2d, Vector2d]>();
        this.creationArea$ = this.creationArea.asObservable();
    }

    private readonly shape = new Rect({
        width: 0,
        height: 0,
        rotation: 0,
        x: 0,
        y: 0,
        visible: false,
        draggable: false,
        cornerRadius: 2,
        fill: 'rgba(119,193,255,0.2)',
        stroke: 'rgba(119,193,255,0.5)',
        strokeWidth: 1,
    });

    get pointerPosition(): Vector2d | null {
        const stage = this.stage;
        if (!stage) {
            return null;
        }
        const position = this.canvasPointerPosition;
        if (!position) {
            return null;
        }
        return BlocksUserInteractions.canvas2localPosition(stage, position);
    }

    get canvasPointerPosition(): Vector2d | null {
        return this.stage?.getPointerPosition() ?? null;
    }

    set pointA(value: Vector2d | null) {
        this.posA = value;
    }

    get pointA(): Vector2d | null {
        return this.posA;
    }

    start(boundedBy: ArtBoardItemKonvaNode) {
        this.borders = boundedBy;
        this.posA = this.pointerPosition;
        this.updatePositions();
    }

    private relativePositionInArtBoard(
        position: Vector2d,
        margin = 0
    ): {
        isLeft: boolean;
        isRight: boolean;
        isBelow: boolean;
        isAbove: boolean;
    } | null {
        const back = this.borders;
        if (!back) {
            return null;
        }
        const cardPosition = back.position();

        const isLeft = position.x < cardPosition.x + margin;
        const isRight = position.x > cardPosition.x + back.width() - margin;

        const isBelow = position.y < cardPosition.y + margin;
        const isAbove = position.y > cardPosition.y + back.height() - margin;

        return {
            isLeft,
            isRight,
            isBelow,
            isAbove,
        };
    }

    private isAreaInsideBoard(
        posA: Vector2d,
        posB: Vector2d,
        margin = 0
    ): boolean {
        const a = this.relativePositionInArtBoard(posA, margin);
        if (!a) {
            return false;
        }
        const b = this.relativePositionInArtBoard(posB, margin);
        if (!b) {
            return false;
        }
        return (
            !(a.isAbove && b.isAbove) &&
            !(a.isBelow && b.isBelow) &&
            !(a.isLeft && b.isLeft) &&
            !(a.isRight && b.isRight)
        );
    }

    public finish(): void {
        const area = this.selectedArea;
        if (
            this.borders &&
            area &&
            this.isAreaInsideBoard(area[0], area[1], 10) &&
            area[1].x - area[0].x > 6 &&
            area[1].y - area[0].y > 6
        ) {
            area[0].x -= this.borders.x();
            area[0].y -= this.borders.y();

            area[1].x -= this.borders.x();
            area[1].y -= this.borders.y();

            const size = {
                width: area[1].x - area[0].x,
                height: area[1].y - area[0].y,
            };
            if (size.width >= this.minSize.x || size.height >= this.minSize.y) {
                this.creationArea.next(area);
            }
        }
        this.pointA = null;
        this.pointB = null;
    }

    get pointB(): Vector2d | null {
        return this.posB;
    }

    set pointB(position: Vector2d | null) {
        this.posB = position;
        this.updatePositions();
    }

    private updatePositions(): void {
        const a = this.posA;
        const b = this.posB;

        if (!a || !b || !this.shape) {
            this.visible = false;
            return;
        }

        const start = {
            x: Math.min(a.x, b.x),
            y: Math.min(a.y, b.y),
        };

        const end = {
            x: Math.max(a.x, b.x),
            y: Math.max(a.y, b.y),
        };

        const size = {
            width: end.x - start.x,
            height: end.y - start.y,
        };

        this.visible = true; // size.width >= this.minSize.x && size.height >= this.minSize.y;

        this.shape.position(start);
        this.shape.width(size.width);
        this.shape.height(size.height);
        this.shape.moveToTop();
        this.stage?.batchDraw();
    }

    public get selectedArea(): [Vector2d, Vector2d] | null {
        const a = this.pointA;
        const b = this.pointB;
        if (!a || !b || (a.x === b.x && a.y === b.y)) return null;
        const start = {
            x: Math.min(a.x, b.x),
            y: Math.min(a.y, b.y),
        };

        const end = {
            x: Math.max(a.x, b.x),
            y: Math.max(a.y, b.y),
        };
        return [start, end];
    }

    update(): void {
        if (this.pointA) {
            this.pointB = this.pointerPosition;
        }
    }
}
