import {Component} from '@angular/core';
import {faChevronRight, faHeart} from '@fortawesome/free-solid-svg-icons';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import firstSteps from '../tutorial.json';

interface TutorialCard {
    cardTitle: string;
    cardVideo: string | null;
    cardImage: string | null;
    cardBody: string;
    cardIcon: string;

    cardNumber: number;
}

@Component({
    selector: 'nirby-tutorial-panel',
    templateUrl: './tutorial-panel.component.html',
    styleUrls: ['./tutorial-panel.component.scss'],
})
export class TutorialPanelComponent {
    index = 0;
    tutorial: TutorialCard[] = firstSteps;

    chevronIcon = faChevronRight;
    heartIcon = faHeart;





    nextCard(): void {
        this.index += 1;
    }
}
