import {
    BehaviorSubject,
    combineLatest,
    distinctUntilChanged,
    finalize,
    firstValueFrom,
    fromEvent,
    last,
    merge,
    NEVER,
    Observable,
    of,
    pairwise,
    startWith,
    Subject,
    Subscription,
    takeUntil,
} from 'rxjs';
import Konva from 'konva';
import {filter, first, map, switchMap} from 'rxjs/operators';
import {Logger} from '@nirby/logger';

import {Block} from '../blocks';
import {ArtBoard} from '../art-board';
import {ArtBoardItem, ArtBoardItemChange, ArtBoardItemKonvaNode} from '../art-board-item';
import {SelectRect} from '../select-rect';
import {ArtBoardTransformer} from '../transformer';
import {NirbyBoardItemStandard, NirbyBoardStateNode} from '../art-board-item-factory';
import {KonvaEventObject} from 'konva/lib/Node';
import {NirbyContext} from '@nirby/runtimes/context';
import {scaleStageToFitShape} from '../art-board-fitter';
import {UnitCursorListener} from '../unit-cursor-listener';
import Vector2d = Konva.Vector2d;

export interface ArtBoardEvent<TMeta, TName extends CardboardEventName = CardboardEventName,
    TTarget = Block<TMeta> | null> {
    event: CardboardEventOf<TName>;
    block: TTarget;
    item: ArtBoardItem<TMeta> | null;
}

export type AnyCardboardEvent = {
    // down
    mousedown: MouseEvent;
    touchstart: MouseEvent;
    // up
    mouseup: MouseEvent;
    touchend: MouseEvent;
    // move
    mousemove: MouseEvent;
    touchmove: TouchEvent;
    // double click
    dblclick: MouseEvent;
    dbltap: MouseEvent;
    // click/tap
    click: MouseEvent;
    tap: MouseEvent;
    // mouse wheel
    wheel: WheelEvent;
    // context menu
    contextmenu: MouseEvent;
};

type CardboardEventName = keyof AnyCardboardEvent;

export type CardboardEventOf<TName extends CardboardEventName> =
    AnyCardboardEvent[TName];
export type CardboardEventFilterFn<TName extends CardboardEventName> = (
    event: KonvaEventObject<CardboardEventOf<TName>>
) => boolean;

/**
 * The selection controller.
 */
class SelectionController<TMeta> {
    private readonly rect = new SelectRect(this.board.UI_LAYER, {
        x: 24,
        y: 24,
    });

    // noinspection JSUnusedGlobalSymbols
    public readonly onSelectedArea$ = this.rect.creationArea$;

    /**
     * Constructor.
     * @param board - The board.
     */
    constructor(private readonly board: ArtBoardEditorCanvas<TMeta>) {
    }

    /**
     * Update the end position of the selection rectangle.
     */
    updateEnd(): void {
        this.rect.update();
    }

    /**
     * Starts selecting an area.
     */
    start(): void {
        if (this.board.boardShape) {
            this.rect.start(this.board.boardShape);
        }
    }

    /**
     * Finishes selecting an area.
     */
    finish(): void {
        this.rect.finish();
    }
}

/**
 * Where card blocks are drawn
 */
export class BlocksUserInteractions<TMeta> {
    /**
     * Constructor.
     * @param items$ - Observable of blocks
     */
    constructor(private items$: Observable<ArtBoardItem<TMeta>[]>) {
    }

    /**
     * Watches for changes triggered by a change.
     */
    public get onUserChange$(): Observable<ArtBoardItemChange> {
        return this.items$.pipe(
            switchMap((blocks) =>
                merge(
                    ...blocks.map(
                        (block) => block.eventsController.updateSignal$,
                    ),
                ),
            ),
        );
    }

    /**
     * Watches when an item is dragged outside the board.
     */
    public get onDragOut$(): Observable<ArtBoardItem<TMeta>> {
        return this.items$.pipe(
            switchMap((blocks) => {
                return merge(
                    ...blocks.map((block) =>
                        block.transformController.dragger.onDragOut$.pipe(
                            map(() => block),
                        ),
                    ),
                );
            }),
        );
    }

    /**
     * Transforms a position from the canvas position to the board position.
     * @param stage - The stage.
     * @param position - The position.
     *
     * @returns - The board position.
     */
    static canvas2localPosition(
        stage: Konva.Stage,
        position: Vector2d,
    ): Vector2d {
        return {
            x: (position.x - stage.x()) / stage.scaleX(),
            y: (position.y - stage.y()) / stage.scaleY(),
        };
    }
}

/**
 * Emitter to be used by the RxJS {@link fromEvent} operator
 */
export interface JQueryStyleEventEmitter<TContext, T> {
    on(
        eventName: string,
        handler: (this: TContext, t: T, ...args: unknown[]) => unknown
    ): void;

    off(
        eventName: string,
        handler: (this: TContext, t: T, ...args: unknown[]) => unknown
    ): void;
}

/**
 * Controller that handles the events triggered on the canvas
 */
export class ArtBoardCanvasEvents<TMeta> {
    /**
     * Constructor.
     * @param board - The board to listen to.
     */
    constructor(private readonly board: ArtBoardEditorCanvas<TMeta>) {
    }

    /**
     * Watch events on the canvas
     * @param name event name
     * @param filterFn filter function to filter the event
     * @param debug Whether to log the event
     *
     * @returns - Observable that emits the event
     */
    public watchEvents<TName extends CardboardEventName>(
        name: TName,
        filterFn?: CardboardEventFilterFn<TName>,
        debug = false,
    ): Observable<ArtBoardEvent<TMeta, TName>> {
        type ThisEvent = CardboardEventOf<TName>;
        const fakeEmitter: JQueryStyleEventEmitter<unknown,
            KonvaEventObject<ThisEvent>> = {
            off: (eventName: TName, handler): void => {
                this.board.stage.off(eventName, handler);
            },
            on: (eventName: TName, handler): void => {
                this.board.stage.on(eventName, handler);
            },
        };
        return fromEvent<KonvaEventObject<ThisEvent>>(fakeEmitter, name).pipe(
            // ignore transformer events
            filter((s) => !(s.target.parent instanceof Konva.Transformer)),
            filter((evt) => !filterFn || filterFn(evt)),
            map((s) => {
                const item = this.getParentItem(s.target, undefined, debug);
                return {
                    event: s.evt,
                    block: this.getParentBlock(s.target, debug),
                    item,
                };
            }),
        );
    }

    /**
     * Get the parent item of the event target
     * @param shape - The shape.
     * @param filterFn - The filter function.
     * @param debug - Whether to log the event.
     * @private
     *
     * @returns - The parent item.
     */
    private getParentItem<T extends ArtBoardItem<TMeta> = ArtBoardItem<TMeta>>(
        shape: Konva.Node,
        filterFn?: (item: ArtBoardItem<TMeta>) => item is T,
        debug = false,
    ): T | null {
        let target: Konva.Node | null = shape;
        if (target.id() === 'card-canvas') {
            if (debug) {
                Logger.log('mousedown on canvas');
            }
            return null;
        }
        do {
            const item = this.board.getById(target.id());
            if (item) {
                if (!filterFn) {
                    return item as T;
                }
                if (filterFn(item)) {
                    return item;
                }
                if (debug) {
                    Logger.log('Skipped item', item.id, item.constructor.name);
                }
            }
            target = target.parent;
        } while (target);
        return null;
    }

    /**
     * Get the closest block to the given target in the tree
     * @param shape the shape to start from
     * @param debug whether to log the search
     * @private
     *
     * @returns - The closest block to the given target
     */
    private getParentBlock(shape: Konva.Node, debug = false): Block<TMeta> | null {
        return this.getParentItem<Block<TMeta>>(
            shape,
            (item): item is Block<TMeta> => Block.isBlock(item),
            debug,
        );
    }

    /**
     * Watch events on background
     * @param name event name
     * @param filterFn filter function to filter the event
     *
     * @returns - Observable of event
     */
    public watchBackgroundEvents<TName extends CardboardEventName>(
        name: TName,
        filterFn?: CardboardEventFilterFn<TName>,
    ): Observable<ArtBoardEvent<TMeta, TName, null>> {
        return this.watchEvents(name, filterFn).pipe(
            filter((s): s is ArtBoardEvent<TMeta, TName, null> => !s.block),
        );
    }

    /**
     * Watch events on blocks
     * @param name event name
     * @param filterFn filter function to filter the event
     *
     * @returns - Observable of events
     */
    public watchBlockEvents<TName extends CardboardEventName>(
        name: TName,
        filterFn?: CardboardEventFilterFn<TName>,
    ): Observable<ArtBoardEvent<TMeta, TName, Block<TMeta>>> {
        return this.watchEvents(name, filterFn).pipe(
            filter((s): s is ArtBoardEvent<TMeta, TName, Block<TMeta>> => !!s.block),
        );
    }
}

/**
 * Controller that handles the border shown on the hovered blocks
 */
export class BlockHoverController<TMeta> {
    private readonly DEFAULT_STYLE: HoverStyle = {
        color: 'rgba(75,39,255,0.78)',
        width: 4,
    };
    hoverShape = new Konva.Rect({
        id: 'hover-rect',
        name: 'ui',
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        fill: 'transparent',
        stroke: this.DEFAULT_STYLE.color,
        cornerRadius: 2,
        strokeScaleEnabled: false,
        strokeWidth: this.DEFAULT_STYLE.width,
        hitFunc: () => {
            return;
        },
    });

    container: HTMLDivElement;

    /**
     * Constructor.
     * @param layer - The layer to add the hover shape to.
     * @param board - The board to listen to.
     */
    constructor(
        private readonly layer: Konva.Layer,
        private readonly board: ArtBoardEditorCanvas<TMeta>,
    ) {
        this.board.UI_LAYER.add(this.hoverShape);
        const container = this.hoverShape.getStage()?.container();
        if (!container) {
            throw new Error('No container found');
        }
        this.container = container;
    }

    private selectedShapeId: string | null = null;

    /**
     * Show the hover shape
     * @param shape - The shape to show the hover shape on
     * @param style - The style to use
     */
    show(shape: ArtBoardItemKonvaNode, style?: HoverStyle): void {
        style = style || this.DEFAULT_STYLE;
        const rect = shape.getClientRect({
            skipTransform: true,
            skipShadow: true,
            skipStroke: true,
        });
        this.hoverShape.absolutePosition(shape.getAbsolutePosition());
        this.hoverShape.size({
            height: rect.height * shape.scaleY(),
            width: rect.width * shape.scaleX(),
        });
        this.hoverShape.stroke(style.color);
        this.hoverShape.strokeWidth(style.width);
        this.hoverShape.rotation(shape.rotation());
        this.hoverShape.show();
        this.selectedShapeId = shape.id();
        this.container.style.cursor = 'pointer';
        this.layer.batchDraw();
    }

    /**
     * Hide the hover shape
     */
    hide(): void {
        this.hoverShape.hide();
        this.selectedShapeId = null;
        this.container.style.cursor = 'default';
        this.layer.batchDraw();
    }
}


/**
 * Controller to preview the selected block, and add it to the board when it is dropped
 */
export class GhostCreationController<TMeta> {
    private readonly ghost: Konva.Rect;

    /**
     * Constructor.
     * @param layer - The layer to add the ghost to.
     * @param margin - The margin to allow the ghost to be dragged outside the board.
     */
    constructor(
        private readonly layer: Konva.Layer,
        private readonly margin = 5,
    ) {
        this.ghost = new Konva.Rect({
            visible: false,
            fill: 'rgba(0,0,0,0.5)',
        });
        layer.add(this.ghost);
    }

    /**
     * Check if cursor is over the container of the canvas
     *
     * @param cursor - The event to check
     *
     * @returns - True if the cursor is over the container
     */
    private isCursorOverContainer(cursor: MouseEvent): boolean {
        const container = this.layer.getStage()?.container();
        if (!container) {
            return false;
        }
        const rect = container.getBoundingClientRect();
        return (
            cursor.clientX >= rect.left &&
            cursor.clientX <= rect.right &&
            cursor.clientY >= rect.top &&
            cursor.clientY <= rect.bottom
        );
    }

    /**
     * Checks if, considering the ``item`` size and the current mouse position, the ghost would be visible
     * on the parent group.
     *
     * @param cursor - The mouse event
     * @param itemSize - The item size
     * @param parent - The parent group
     * @private
     *
     * @returns - True if the ghost would be visible
     */
    private isGhostOverGroup(
        cursor: MouseEvent,
        itemSize: { width: number; height: number },
        parent: Konva.Group,
    ): boolean {
        const container = this.layer.getStage()?.container();
        if (!container) {
            return false;
        }
        const rect = container.getBoundingClientRect();
        const parentRelativeRect = parent.getClientRect();
        const parentRect = {
            x: rect.x + parentRelativeRect.x,
            y: rect.y + parentRelativeRect.y,
            width: parentRelativeRect.width,
            height: parentRelativeRect.height,
        };

        // only a part of the item is visible
        const isTooLeft =
            cursor.clientX + itemSize.width - parentRect.x < this.margin;
        const isTooRight =
            parentRect.x + parentRect.width - cursor.clientX < this.margin;
        const isTooTop =
            cursor.clientY + itemSize.height - parentRect.y < this.margin;
        const isTooBottom =
            parentRect.y + parentRect.height - cursor.clientY < this.margin;

        return !(isTooLeft || isTooRight || isTooTop || isTooBottom);
    }

    /**
     * Starts showing the ghost following the cursor with the size of the given shape
     * @param item - The state to show the ghost for
     * @param referenceParent - The reference point to use when calculating the end position
     *
     * @returns - A subscription to the ghost
     */
    startFollowing(
        item: NirbyBoardStateNode<TMeta>,
        referenceParent?: Konva.Group,
    ): Observable<NirbyBoardStateNode<TMeta> | null> {
        // initialize rect attributes
        this.ghost.size({
            width:
                item.properties.position[1].x - item.properties.position[0].x,
            height:
                item.properties.position[1].y - item.properties.position[0].y,
        });
        this.ghost.show();
        this.layer.batchDraw();

        const layer = this.layer;
        const stage = this.layer.getStage();

        // listen to mouse move
        return fromEvent<MouseEvent>(document, 'mousemove').pipe(
            // stop listening when raising the left mouse button
            takeUntil(
                fromEvent<MouseEvent>(document, 'mouseup').pipe(
                    filter((e) => e.button === 0),
                ),
            ),
            // processes the event to check if the result is valid and update the ghost position
            map<MouseEvent, NirbyBoardStateNode<TMeta> | null>((event) => {
                const arePointersInitialized =
                    UnitCursorListener.stagePointersAreInitialized(stage);
                if (!arePointersInitialized) {
                    return null;
                }

                const isCursorOverContainer = this.isCursorOverContainer(event);
                const cursorPosition = layer.getRelativePointerPosition();

                const scale = layer.getAbsoluteScale();
                const isGhostOverParent =
                    !referenceParent ||
                    this.isGhostOverGroup(
                        event,
                        {
                            width:
                                item.properties.position[1].x -
                                item.properties.position[0].x,
                            height:
                                item.properties.position[1].y -
                                item.properties.position[0].y,
                        },
                        referenceParent,
                    );

                if (!cursorPosition || !scale) {
                    return null;
                }
                if (isCursorOverContainer && isGhostOverParent) {
                    this.ghost.show();
                    this.ghost.position(cursorPosition);
                    layer.batchDraw();
                } else {
                    this.ghost.hide();
                    layer.batchDraw();
                    return null;
                }

                const previousParent = this.ghost.parent;
                if (referenceParent) {
                    referenceParent.add(this.ghost);
                }
                const position = this.ghost.position();
                const newItem: NirbyBoardStateNode<TMeta> = {
                    ...item,
                    properties: {
                        ...item.properties,
                        position: [
                            position,
                            {
                                x: position.x + this.ghost.width(),
                                y: position.y + this.ghost.height(),
                            },
                        ],
                    },
                } as NirbyBoardStateNode<TMeta>;
                if (referenceParent && previousParent) {
                    previousParent.add(this.ghost);
                }
                return newItem;
            }),
            startWith(null),
            // pick only the result when the left mouse button is released
            last(),
            // hide the ghost when finished
            finalize(() => {
                this.ghost.hide();
                this.layer.batchDraw();
            }),
        );
    }
}

interface HoverStyle {
    color: string;
    width: number;
}

interface HoveredItem<TMeta> {
    item: ArtBoardItem<TMeta>;
    style: HoverStyle;
}

/**
 * Controller for the visualization of the editor
 *
 * How to use:
 * 1. Create a new instance of this class giving it: A NirbyContext, a Konva.Stage, an observable of an ArtBoard
 * that will be used to update the stage, the root ArtBoard state that describes the full tree of items
 * 2. Call the `init` method to start listening to events.
 * 3. Call the `dispose` method when you don't need the controller anymore to avoid memory leaks.
 */
export class ArtBoardEditorCanvas<TMeta> {
    public readonly UI_LAYER = new Konva.Layer();
    public readonly CANVAS_LAYER = new Konva.Layer();
    public readonly OVERLAY_LAYER = new Konva.Layer();

    private readonly boardSubject = new BehaviorSubject<ArtBoard<TMeta> | null>(null);
    private readonly rootBoard: ArtBoard<TMeta>;

    public readonly interactions: BlocksUserInteractions<TMeta>;
    public readonly transformer: ArtBoardTransformer<TMeta>;

    public readonly events: ArtBoardCanvasEvents<TMeta>;
    public readonly selector = new SelectionController(this);
    public readonly hover: BlockHoverController<TMeta>;
    public readonly selectionSignal = new Subject<ArtBoardItem<TMeta> | null>();
    public readonly creator = new GhostCreationController<TMeta>(this.UI_LAYER);

    public readonly onSelection$ = this.selectionSignal.asObservable();
    public readonly selectedBlock$: Observable<Block<TMeta> | null>;

    private readonly hoverIntentionIdSubject = new BehaviorSubject<string | null>(null);

    public readonly hoverIntention$: Observable<ArtBoardItem<TMeta> | null>;

    private subscriptions: Subscription | null = null;

    /**
     * Watches the hovered item.
     *
     * @returns - An observable of the hovered item
     */
    private watchHovered$(): Observable<HoveredItem<TMeta> | null> {
        return combineLatest([
            this.events.watchEvents('mousemove').pipe(
                startWith(null),
            ),
            this.hoverIntention$,
        ]).pipe(
            map(([evt, hoverIntention]): HoveredItem<TMeta> | null => {
                const item = evt?.item ?? hoverIntention;
                if (!item) {
                    return null;
                }
                const isHighlight = item === hoverIntention;
                const color = isHighlight ? 'rgba(75,39,255,0.78)' : 'rgba(101,180,227,0.81)';
                const width = 2;
                return {
                    item,
                    style: {
                        color,
                        width,
                    },
                };
            }),
            distinctUntilChanged((a, b) => a?.item.id === b?.item.id),
            startWith<HoveredItem<TMeta> | null>(null),
        );
    }

    /**
     * Watches the hovered item.
     *
     * @returns - An observable of the hovered item
     */
    public watchHoveredItem$(): Observable<ArtBoardItem<TMeta> | null> {
        return this.watchHovered$().pipe(
            map((hovered) => hovered?.item ?? null),
        );
    }


    /**
     * Watches the hovered item.
     *
     * @returns - An observable of the hovered item
     */
    public watchSelectedItem$(): Observable<ArtBoardItem<TMeta> | null> {
        return this.selectedBlock$.pipe(
            map((selected) => selected ?? null),
        );
    }

    /**
     * Constructor.
     * @param context - The context to use for the canvas.
     * @param stage - The stage to use for the canvas.
     * @param level$ - The level to use for the canvas.
     * @param rootState - The root state to use for the canvas.
     * @param selectedBlockId$ - An observable for the selected block id. Will be used to update the selection view.
     */
    constructor(
        public readonly context: NirbyContext,
        public readonly stage: Konva.Stage,
        private readonly level$: Observable<NirbyBoardStateNode<TMeta, ArtBoard<TMeta>> | null>,
        private readonly rootState: NirbyBoardStateNode<TMeta, ArtBoard<TMeta>>,
        private readonly selectedBlockId$: Observable<string | null>,
    ) {
        this.stage.add(this.CANVAS_LAYER);
        this.stage.add(this.UI_LAYER);
        this.stage.add(this.OVERLAY_LAYER);
        this.transformer = new ArtBoardTransformer(this.UI_LAYER);
        this.rootBoard = new ArtBoard<TMeta>(
            rootState.id,
            null,
            context,
            true,
            rootState.properties,
            rootState.children,
        );
        this.events = new ArtBoardCanvasEvents(this);
        this.interactions = new BlocksUserInteractions(this.levelItems$);
        this.hover = new BlockHoverController(this.UI_LAYER, this);
        this.selectedBlock$ = this.selectedBlockId$.pipe(
            switchMap((id) => {
                if (!id) {
                    return of(null);
                }
                return this.rootBoard
                    .watchChildById(id)
                    .pipe(
                        map((child) => (Block.isBlock<TMeta>(child) ? child : null)),
                    );
            }),
        );
        this.hoverIntention$ = this.hoverIntentionIdSubject.pipe(
            switchMap((id) => {
                if (!id) {
                    return of(null);
                }
                return this.rootBoard.watchChildById(id);
            }),
        );
    }

    /**
     * Sets a block ID to be hovered when cursor is not over a block.
     * @param id - The block ID to be hovered.
     */
    tryToHover(id: string | null): void {
        this.hoverIntentionIdSubject.next(id);
    }

    /**
     * Initializes the canvas. This method draws the full tree of items and starts listening for:
     * - Mouse down events to send select instructions
     * - Mouse down and up background events to start creating a new block
     *
     * Remember to call this method before using the editor and {@link dispose} it when you're done.
     *
     * @param levelId - The level id to edit on the canvas.
     * @param showSelectionRectangle - Whether to show the selection rectangle.
     * @param startEmpty - Whether to start with an empty canvas.
     *
     * @returns - A promise that resolves when the canvas is ready.
     */
    public async init(
        levelId: string | undefined,
        showSelectionRectangle: boolean,
        startEmpty = false,
    ): Promise<void> {
        if (!this.rootBoard.initialized) {
            await this.rootBoard.init(this.CANVAS_LAYER);
        }
        const newLevelBoard = levelId
            ? this.rootBoard.getDeepMapById().get(levelId)
            : this.rootBoard;
        if (!ArtBoard.isArtBoard<TMeta>(newLevelBoard)) {
            Logger.warn(
                'Canvas initialization failed',
                `ArtBoard with id "${levelId}" not found`,
            );
            return;
        }
        if (this.subscriptions) {
            Logger.warn('Canvas initialization failed', 'Already initialized');
            return;
        }
        if (startEmpty) {
            newLevelBoard.children = [];
            await newLevelBoard.nextItems();
        }
        this.subscriptions = new Subscription();

        // mark current level children as draggable
        this.subscriptions.add(
            this.levelItems$.pipe(
                pairwise(),
            ).subscribe(([previous, next]) => {
                previous.forEach((bs) => bs.shape?.draggable(false));
                next.forEach((bs) => bs.shape?.draggable(true));
            }),
        );

        this.subscriptions.add(
            this.events
                .watchEvents('mousedown', (evt) => evt.evt.button === 0)
                .pipe(map((s) => s.item))
                .subscribe((b) => {
                    this.selectionSignal.next(b);
                }),
        );

        if (showSelectionRectangle) {
            // start creation rect on mouse down
            this.subscriptions.add(
                this.events
                    .watchBackgroundEvents(
                        'mousedown',
                        (evt) => evt.evt.button === 0,
                    )
                    .subscribe(() => {
                        this.selector.start();
                    }),
            );

            // create event when creation rectangle is enabled
            this.subscriptions.add(
                this.events
                    .watchEvents('mouseup', (evt) => evt.evt.button === 0)
                    .subscribe(() => {
                        this.selector.finish();
                    }),
            );

            // update creation rectangle on mouse move
            this.subscriptions.add(
                this.events.watchEvents('mousemove').subscribe(() => {
                    this.selector.updateEnd();
                }),
            );
        }

        // hover animations when moving the cursor
        this.subscriptions.add(
            this.watchHovered$().subscribe((block) => {
                if (!block) {
                    this.hover.hide();
                    return;
                }

                const shape = block.item.shape;
                if (!shape) {
                    this.hover.hide();
                    return;
                }

                this.hover.show(shape, block.style);
            }),
        );

        // batch draw while transforming
        this.transformer.selected$
            .pipe(
                switchMap((selected) => {
                    if (!selected) {
                        return NEVER;
                    }
                    return selected.transformController.transform$.pipe(
                        map(() => selected),
                    );
                }),
            )
            .subscribe(() => {
                this.CANVAS_LAYER.batchDraw();
            });

        this.boardSubject.next(newLevelBoard);
        this.fitToContainer();
    }

    /**
     * The selected ArtBoard.
     */
    public get board$(): Observable<ArtBoard<TMeta> | null> {
        return this.boardSubject.asObservable();
    }

    /**
     * Get a block by ID
     * @param id ID of the block
     *
     * @returns Block or null if not found
     */
    public getById(id: string): ArtBoardItem<TMeta> | null {
        const board = this.board;
        if (!board) {
            return null;
        }
        return board.items.find((i) => i.id === id) ?? null;
    }

    /**
     * Get all items in the canvas.
     *
     * @returns Array of all items.
     */
    findAll(): ArtBoardItem<TMeta>[] {
        return this.board?.items ?? [];
    }

    /**
     * Get Block by ID
     * @param id ID of the block
     *
     * @returns - Block or null if not found
     */
    public getBlockById(id: string): Block<TMeta> | null {
        const item = this.getById(id);
        if (item && Block.isBlock<TMeta>(item)) {
            return item;
        }
        return null;
    }

    /**
     * Wait for an item to be created.
     * @param id - The ID of the item to wait for.
     *
     * @returns - A promise that resolves when the item is created.
     */
    async waitForId(id: string): Promise<ArtBoardItem<TMeta>> {
        return await firstValueFrom(
            this.levelItems$.pipe(
                map((d) => d.find((a) => a.id === id)),
                filter((s): s is ArtBoardItem<TMeta> => s !== undefined),
                first(),
            ),
        );
    }

    /**
     * Wait asynchronously for a block of the given ID to exist and return that block
     * @param id Block ID to wait
     */
    public async waitForBlockOfId(id: string): Promise<Block<TMeta>> {
        return await firstValueFrom(
            this.blocks$.pipe(
                map((d) => d.find((a) => a.id === id)),
                filter((s): s is Block<TMeta> => Block.isBlock(s)),
                first(),
            ),
        );
    }

    /**
     * Wait asynchronously for an art-board of the given ID to exist and return that block
     * @param id Block ID to wait
     */
    public async waitForArtBoardOfId(id: string): Promise<ArtBoard<TMeta>> {
        return await firstValueFrom(
            this.levelItems$.pipe(
                map((d) => d.find((a) => a.id === id)),
                filter((s): s is ArtBoard<TMeta> => ArtBoard.isArtBoard(s)),
                first(),
            ),
        );
    }

    /**
     * Get current art board
     */
    public get board(): ArtBoard<TMeta> | null {
        return this.boardSubject.value;
    }

    /**
     * The current board shape
     */
    get boardShape(): ArtBoardItemKonvaNode | null {
        return this.boardSubject.value?.shape ?? null;
    }

    /**
     * The items on the current board
     */
    public get levelItems$(): Observable<ArtBoardItem<TMeta>[]> {
        return this.board$.pipe(switchMap((b) => b?.items$ ?? of([])));
    }

    /**
     * The blocks on the current board
     */
    public get blocks$(): Observable<Block<TMeta>[]> {
        return this.levelItems$.pipe(
            map((s) => {
                if (!s) {
                    return [];
                }
                return s.filter<Block<TMeta>>((i): i is Block<TMeta> => Block.isBlock(i));
            }),
        );
    }

    /**
     * Delete child by ID
     * @param id ID of the child to delete
     */
    deleteById(id: string): void {
        const board = this.board;
        if (!board) {
            return;
        }
        // unselect
        if (this.transformer.selected?.id === id) {
            this.transformer.selected = null;
            this.selectionSignal.next(null);
        }
        board.removeChildById(id);
        this.rootBoard.batchDraw();
    }

    /**
     * Create a new block
     * @param id ID of the block
     * @param type Type of the block
     * @param meta Metadata of the block
     * @param properties Properties of the block
     * @param children Children of the block
     * @param index Index of the block
     */
    async create<TItem extends NirbyBoardItemStandard<TMeta>>(
        id: string,
        type: TItem['type'],
        meta: TMeta | null,
        properties: NirbyBoardStateNode<TMeta, TItem>['properties'],
        children?: NirbyBoardStateNode<TMeta, TItem>['children'],
        index?: number | null,
    ): Promise<void> {
        const state: NirbyBoardStateNode<TMeta, TItem> = {
            id,
            properties,
            type,
            children: children ?? [],
            meta,
        };
        const board = this.board;
        if (!board) {
            return;
        }
        await board.addChild(state, index);
    }

    /**
     * Disposes the board
     */
    dispose(): void {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
            Logger.warn('ArtBoardEditorCanvas.init: already disposed');
        }
        this.rootBoard.dispose();
        this.rootBoard.batchDraw();
    }

    /**
     * Start positioning item on the board
     * @param item Item to position
     *
     * @returns - A promise that resolves when the item is positioned
     */
    public startPositioningItem(
        item: NirbyBoardStateNode<TMeta>,
    ): Observable<NirbyBoardStateNode<TMeta> | null> {
        return this.board$.pipe(
            map((b) => b?.shape),
            filter((b): b is Konva.Group => !!b),
            first(),
            switchMap((level) => {
                this.fitToContainer();
                return this.creator.startFollowing(item, level);
            }),
        );
    }

    /**
     * Scales the stage to fit the current board into the container
     */
    public fitToContainer(): void {
        if (!this.rootBoard) {
            return;
        }
        scaleStageToFitShape(this.rootBoard.shape);
    }
}
