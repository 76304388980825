<div class="p-3">
    <form (ngSubmit)="search()">
        <div class="form-group">
            <label class="d-block" for="query">Unsplash</label>
            <div class="d-flex">
                <input
                    id="query"
                    class="form-control form-control-sm me-2 flex-1"
                    placeholder="Search Image"
                    name="query"
                    [(ngModel)]="query"
                />
                <button type="submit" class="btn btn-primary btn-sm">
                    Search
                </button>
            </div>
        </div>
    </form>
    <div class="results d-lg-flex flex-wrap" *ngIf="results; else loading">
        <div
            class="search-result mt-2"
            (click)="onSelect(result)"
            [ngStyle]="{ backgroundImage: 'url(' + result.lastUrl + ')' }"
            *ngFor="let result of results"
        ></div>
    </div>
    <ng-template #loading>
        <div class="row text-center">
            <div
                class="text-center spinner-border text-primary"
                role="status"
            ></div>
        </div>
    </ng-template>
</div>
