import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    docData,
    Firestore,
    getDoc,
    PartialWithFieldValue,
    runTransaction,
    serverTimestamp,
    setDoc,
    UpdateData,
    updateDoc,
} from '@angular/fire/firestore';
import {from, NEVER, Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {CollectionKey, NirbyCollection, NirbyTransaction, SCHEMA_VERSION} from '@nirby/store/base';
import {Logger} from '@nirby/logger';
import {StandardConverter} from './converter';
import {FirestoreNirbyDocument} from './document';
import {FirestoreTransaction} from './transaction';
import {DateTime} from 'luxon';
import {FirestoreCollectionReference, FirestoreDocumentReference} from './reference';
import {FirestoreQueryBuilder} from './query';
import {MigratorLike} from '@nirby/store/migrator';
import {Uploaded} from '@nirby/store/models';


/**
 * A service for interacting with a Firestore collection.
 */
export class FirestoreCollection<T extends object> extends NirbyCollection<T> {
    private CONVERTER = StandardConverter.for<T>(this.migrator);

    /**
     * Constructor.
     * @param firestore The Firestore service.
     * @param path The path to the collection.
     * @param migrator The migrator for the collection
     */
    constructor(
        protected firestore: Firestore,
        public readonly path: string,
        public readonly migrator: MigratorLike<T>,
    ) {
        super();
    }

    /**
     * Creates a collection from a collection key.
     * @param firestore The Firestore service.
     * @param collectionKey The collection key.
     *
     * @returns The collection.
     */
    static fromKey<T extends object, SyncContext extends object>(
        firestore: Firestore,
        collectionKey: CollectionKey<T, SyncContext>,
    ): FirestoreCollection<T> {
        return new FirestoreCollection<T>(
            firestore,
            collectionKey.remoteCollectionName,
            collectionKey.migrator,
        );
    }

    /**
     * Queries the collection.
     *
     * @returns The query builder.
     */
    query(): FirestoreQueryBuilder<T> {
        return new FirestoreQueryBuilder<T>(this);
    }

    /**
     * Gets a collection of documents.
     */
    get collection(): FirestoreCollectionReference<T> {
        return new FirestoreCollectionReference<T>(
            collection(this.firestore, this.path).withConverter(this.CONVERTER),
            this.migrator,
        );
    }

    /**
     * Gets a sub-collection object for querying or accessing its documents.
     *
     * @param docId Document ID
     * @param collectionKey The collection key.
     *
     * @returns The collection.
     */
    subCollection<TSC extends object>(
        docId: string,
        collectionKey: CollectionKey<TSC>,
    ): FirestoreCollection<TSC> {
        const path = `${this.path}/${docId}/${collectionKey.remoteCollectionName}`;
        return new FirestoreCollection<TSC>(
            this.firestore,
            path,
            collectionKey.migrator,
        );
    }

    /**
     * Watches a document.
     * @param id The document id.
     *
     * @returns A promise that resolves to the document.
     */
    watchById(id: string): Observable<FirestoreNirbyDocument<T> | null> {
        const ref = this.ref(id).ref;
        return docData(ref).pipe(
            map((data: Uploaded<T>) =>
                data
                    ? FirestoreNirbyDocument.fromFirestoreRef(
                        ref,
                        data,
                        this.migrator,
                    )
                    : null,
            ),
            catchError((err) => {
                Logger.error(`Query ${this.path} failed: ${err.message}`);
                return NEVER;
            }),
        );
    }

    /**
     * Gets a document reference.
     * @param id The document ID.
     *
     * @returns The document reference.
     */
    ref(id?: string): FirestoreDocumentReference<T> {
        return new FirestoreDocumentReference(
            id ? doc(this.collection.ref, id) : doc(this.collection.ref),
            this.migrator,
        );
    }

    /**
     * Creates a document.
     * @param data The data to store in the document.
     * @param id The document ID.
     *
     * @returns A promise that resolves to the created document.
     */
    async add(data: T, id?: string): Promise<FirestoreDocumentReference<T>> {
        const identifiedData: Uploaded<T> = {
            ...data,
            _creationTime: serverTimestamp() as unknown as DateTime,
            _lastUpdate: serverTimestamp() as unknown as DateTime,
            _databaseVersion: SCHEMA_VERSION,
        };
        Logger.log('DB:ADD', `Adding document at ${this.path}`);
        if (id) {
            const ref = doc(this.collection.ref, id);
            await setDoc(ref, identifiedData);
            return new FirestoreDocumentReference<T>(ref, this.migrator);
        }
        return new FirestoreDocumentReference(
            await addDoc(this.collection.ref, identifiedData),
            this.migrator,
        );
    }

    /**
     * Sets a document by ID.
     * @param id The document ID.
     * @param data The data to store in the document.
     *
     * @returns A promise.
     */
    async set(id: string, data: T): Promise<FirestoreDocumentReference<T>> {
        const identifiedData: PartialWithFieldValue<Uploaded<T>> = {
            ...data,
            _lastUpdate: serverTimestamp(),
            _databaseVersion: SCHEMA_VERSION,
        };
        const ref = this.ref(id).ref;
        Logger.log('DB:ADD', `Setting document at ${ref.path}`);
        await setDoc(ref, identifiedData, {merge: true}).catch((err) => {
            Logger.error(err);
            throw err;
        });
        return new FirestoreDocumentReference<T>(ref, this.migrator);
    }

    /**
     * Get a document by ID.
     * @param id The document ID.
     *
     * @returns A promise.
     */
    get(id: string): Observable<FirestoreNirbyDocument<T> | null> {
        return from(getDoc(this.ref(id).ref)).pipe(
            map((d) => {
                const data = d.data();
                if (!data) {
                    return null;
                }
                return FirestoreNirbyDocument.fromFirestore(d, this.migrator);
            }),
            catchError((err) => {
                Logger.error(`Query ${this.path} failed: ${err.message}`);
                return NEVER;
            }),
        );
    }

    /**
     * Deletes a document by ID.
     * @param id The document ID.
     *
     * @returns A promise.
     */
    delete(id: string): Promise<void> {
        return deleteDoc(this.ref(id).ref);
    }

    /**
     * Updates a document by ID.
     *
     * @param id The document ID.
     * @param data The data to store in the document.
     *
     * @returns A promise.
     */
    update(id: string, data: UpdateData<T> | Partial<T>): Promise<void> {
        Logger.log(
            'DB:UPDATE',
            `Updating document at ${this.path}/${id}`,
            Object.keys(data),
            data,
        );
        return updateDoc(this.ref(id).ref, data as UpdateData<T>);
    }

    /**
     * Runs a transaction
     * @param fn The function to run.
     *
     * @returns A promise.
     */
    public runTransaction<T>(
        fn: (transaction: NirbyTransaction) => Promise<T>,
    ): Promise<T> {
        return runTransaction(this.firestore, transaction => {
            return fn(new FirestoreTransaction(transaction));
        });
    }
}
