import { ArtBoardItemKonvaNode } from './art-board-item';
import {Logger} from '@nirby/logger';

/**
 * Scales the stage of the given shape to fit exactly into the stage.
 * @param shape The shape to fit.
 */
export function scaleStageToFitShape(shape: ArtBoardItemKonvaNode): void {
    const stage = shape.getStage();
    const container = stage?.container();
    if (!shape) {
        Logger.warnStyled('BLOCK-EDITOR', 'Can\'t fit, no board found');
        return;
    }
    if (!container || !stage) {
        Logger.warnStyled(
            'BLOCK-EDITOR',
            'Can\'t fit, no container or stage found',
        );
        return;
    }

    const frameSize = {
        height: container.clientHeight,
        width: container.clientWidth,
    };
    const contentSize = shape.size();
    if (isNaN(contentSize.width) || isNaN(contentSize.height)) {
        Logger.warnStyled(
            'BLOCK-EDITOR',
            'Can\'t fit, content size is NaN',
        );
        return;
    }

    const scale = Math.min(
        frameSize.height / contentSize.height,
        frameSize.width / contentSize.width,
    );
    stage.scale({ x: scale, y: scale });
    stage.size(frameSize);

    // position stage
    const contentScale = shape.getAbsoluteScale();
    const boardViewSize = {
        width: contentSize.width * contentScale.x,
        height: contentSize.height * contentScale.y,
    };
    stage.absolutePosition({
        x: frameSize.width / 2 - boardViewSize.width / 2,
        y: frameSize.height / 2 - boardViewSize.height / 2,
    });
    stage.batchDraw();
}
