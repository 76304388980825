<div class="modal-body">
    <div class="card border-0">
        <div class="card-title">
            <div class="container-fluid">
                <div class="row">
                    <h3 class="title-text">{{ tutorial[index].cardTitle }}</h3>
                    <p>
                        <ngb-progressbar
                            type="info"
                            [value]="tutorial[index].cardNumber"
                            [max]="tutorial.length"
                            [striped]="true"
                            [animated]="true"
                            ><b
                                >{{ tutorial[index].cardNumber }} of
                                {{ tutorial.length }}</b
                            >
                        </ngb-progressbar>
                    </p>
                    <p class="title-text">
                        {{ index + 1 }} of {{ tutorial.length }}
                    </p>
                </div>
            </div>
        </div>
        <div
            class="card-img-top"
            *ngIf="tutorial[index].cardVideo !== null; else isImage"
        >
            <!-- If media content is a video. -->
            <video class="media-content" controls>
                <source
                    src="{{ tutorial[index].cardVideo }}"
                    style="width: 100%; height: 800px"
                    type="video/mp4"
                />
            </video>
        </div>
        <div class="card-body">
            <div class="card-title">
                <h2 class="title-text">¿ Cómo instalar el widget ?</h2>
            </div>

            <div class="content border-0">
                <p class="content-text">{{ tutorial[index].cardBody }}</p>
            </div>
        </div>

        <!-- Button to change card. -->
        <div
            class="footer"
            *ngIf="
                tutorial.length > tutorial[index].cardNumber;
                else congratsButton
            "
        >
            <button
                class="btn btn-primary btn-lg float-right rounded-circle"
                (click)="nextCard()"
            >
                <fa-icon [icon]="chevronIcon"></fa-icon>
            </button>
        </div>
    </div>
</div>
<ng-template #congratsButton>
    <div class="modal-footer">
        <button class="btn btn-primary btn-lg float-right rounded-circle">
            <fa-icon [icon]="heartIcon"></fa-icon>
        </button>
    </div>
</ng-template>

<ng-template #isImage>
    <!-- If media content is a imagen. -->
    <img
        class="media-content"
        *ngIf="tutorial[index].cardImage !== null"
        src="{{ tutorial[index].cardImage }}"
        alt=""
    />
</ng-template>
