import {AnyBlock, GenericAction} from '@nirby/models/nirby-player';
import {v4} from 'uuid';
import {AnyAppPrimeTrack} from './prime';
import {AppCard} from './cards';

export * from './cards';
export * from './pop';
export * from './droplet';
export * from './prime';
export * from './routing';
export * from './user.model';
export * from './workspace.model';
export * from './workspace-membership';

export const PREPARERS = {
    /**
     * Prepares action with necessary migrations.
     * * Adds `id` property to action.
     * @param action Action to prepare.
     *
     * @returns Prepared action.
     */
    prepareAction<T extends GenericAction>(action: T): T {
        action.id = action.id ?? v4();
        return action;
    },
    /**
     * Prepares multiple action with necessary migrations:
     * * Prepare every action in the array.
     *
     * @param actions The action object to migrate.
     *
     * @returns The action object with an ID.
     */
    prepareActions<T extends GenericAction>(actions: T[]): T[] {
        return actions.map(this.prepareAction);
    },
    /**
     * Prepares block with necessary migrations:
     * * Prepare every action. {@see prepareAction}
     *
     * @param block The block object to migrate.
     *
     * @returns The migrated block object.
     */
    prepareBlock(block: AnyBlock): AnyBlock {
        if (block.actions) {
            block.actions['click'] = PREPARERS.prepareActions(block.actions['click'] ?? []);
        }
        return block;
    },
    /**
     * Prepares track with necessary migrations:
     * * Prepare every block. {@see prepareBlock}
     * * Prepare every action. {@see prepareAction}
     *
     * @param track The track object to migrate.
     *
     * @returns The migrated track object.
     */
    prepareTrack<T extends AnyAppPrimeTrack>(track: T): T {
        track.actions.appear = PREPARERS.prepareActions(track.actions.appear ?? []);
        track.actions.disappear = PREPARERS.prepareActions(track.actions.disappear ?? []);
        if (track.type === 'block') {
            track.properties.block = PREPARERS.prepareBlock(track.properties.block);
        }
        return track;
    },
    /**
     * Prepares card with necessary migrations:
     * * Prepare every track. {@see prepareTrack}
     *
     * @param card The card object to migrate.
     *
     * @returns The migrated card object.
     */
    prepareCard<T extends AppCard>(card: T): T {
        card.card.blocks = card.card.blocks.map(PREPARERS.prepareBlock);
        return card;
    }
}
