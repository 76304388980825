import {NIRBY_VARIABLE_NULLABLE_SCHEMA, NirbyVariableNullable} from '@nirby/runtimes/state';
import {z} from 'zod';

/**
 * Builds a conditional schema
 * @param type The conditional type
 * @param propertiesSchema The properties schema
 *
 * @returns The conditional schema
 */
export function buildConditionalSchema<TType extends string, TProperties extends object>(
    type: TType,
    propertiesSchema: z.ZodSchema<TProperties>,
) {
    return z.object({
        type: z.literal(type),
        properties: propertiesSchema,
    });
}

type BaseConditional<TType extends string, TProperties extends object> = z.infer<ReturnType<typeof buildConditionalSchema<TType, TProperties>>>;

export const CONDITIONAL_EVALUATE_SCHEMA = buildConditionalSchema('evaluate', z.object({
    target: z.string(),
}));
export type ConditionalEvaluate = z.infer<typeof CONDITIONAL_EVALUATE_SCHEMA>;

export const CONDITIONAL_EQUALS_SCHEMA = buildConditionalSchema('equals', z.object({
    target: z.string(),
    value: NIRBY_VARIABLE_NULLABLE_SCHEMA,
}));
export type ConditionalEquals = z.infer<typeof CONDITIONAL_EQUALS_SCHEMA>;

export const CONDITIONAL_EXISTS_SCHEMA = buildConditionalSchema('exists', z.object({
    target: z.string(),
}));
export type ConditionalExists = z.infer<typeof CONDITIONAL_EXISTS_SCHEMA>;

export const CONDITIONAL_NEGATE_SCHEMA = buildConditionalSchema('negate', z.object({
    condition: z.any(), // conditional
}));
export type ConditionalNegate = z.infer<typeof CONDITIONAL_NEGATE_SCHEMA>;

export const CONDITIONAL_AND_SCHEMA: z.ZodSchema<BaseConditional<'and', {
    conditions: Conditional[];
}>> = buildConditionalSchema('and', z.object({
    conditions: z.lazy(() => z.array(CONDITIONAL_SCHEMA)),
}));
export type ConditionalAnd = z.infer<typeof CONDITIONAL_AND_SCHEMA>;

export const CONDITIONAL_OR_SCHEMA: z.ZodSchema<BaseConditional<'or', {
    conditions: Conditional[];
}>> = buildConditionalSchema('or', z.object({
    conditions: z.lazy(() => z.array(CONDITIONAL_SCHEMA)),
}));
export type ConditionalOr = z.infer<typeof CONDITIONAL_OR_SCHEMA>;

export const CONDITIONAL_TRUE_SCHEMA = buildConditionalSchema('true', z.object({}));
export type ConditionalTrue = z.infer<typeof CONDITIONAL_TRUE_SCHEMA>;

/**
 * Conditional categories
 */
export const CONDITIONAL_GROUP_SCHEMA = z.union([
    CONDITIONAL_AND_SCHEMA,
    CONDITIONAL_OR_SCHEMA,
]);
export type ConditionalGroup = ConditionalAnd | ConditionalOr;

export const CONDITIONAL_SIMPLE_SCHEMA = z.union([
    CONDITIONAL_EVALUATE_SCHEMA,
    CONDITIONAL_EXISTS_SCHEMA,
    CONDITIONAL_NEGATE_SCHEMA,
    CONDITIONAL_EQUALS_SCHEMA,
    CONDITIONAL_TRUE_SCHEMA,
]);
export type ConditionalSimple = z.infer<typeof CONDITIONAL_SIMPLE_SCHEMA>;

export const CONDITIONAL_SCHEMA = z.union([
    CONDITIONAL_SIMPLE_SCHEMA,
    CONDITIONAL_GROUP_SCHEMA,
]);
export type Conditional = z.infer<typeof CONDITIONAL_SCHEMA>;

/**
 * Evaluates a compiled conditional expression.
 * @param expression The compiled conditional expression to evaluate.
 * @param context The context to evaluate the expression in.
 *
 * @returns Whether the expression is true.
 */
export function evaluateConditional(expression: Conditional, context: {
    [key: string]: NirbyVariableNullable | undefined;
} = {}): boolean {
    switch (expression.type) {
        case 'true':
            return true;
        case 'evaluate':
            return !!context[expression.properties.target];
        case 'equals':
            return context[expression.properties.target] === expression.properties.value;
        case 'exists':
            return expression.properties.target in context;
        case 'and':
            return expression.properties.conditions.every(c => evaluateConditional(c, context));
        case 'or':
            return expression.properties.conditions.some(c => evaluateConditional(c, context));
        case 'negate':
            return !evaluateConditional(expression.properties.condition, context);
    }
}

/**
 * Simplifies a conditional expression.
 * @param expression The conditional expression to simplify.
 *
 * @returns The simplified conditional expression.
 */
export function simplifyConditional(expression: Conditional): Conditional {
    switch (expression.type) {
        case 'and':
            if (expression.properties.conditions.length === 0) {
                return {
                    type: 'true',
                    properties: {},
                };
            }
            if (expression.properties.conditions.length === 1) {
                return simplifyConditional(expression.properties.conditions[0]);
            }
            return {
                type: 'and',
                properties: {
                    conditions: expression.properties.conditions.map(simplifyConditional),
                },
            };
        case 'or':
            if (expression.properties.conditions.length === 0) {
                return {
                    type: 'true',
                    properties: {},
                };
            }
            if (expression.properties.conditions.length === 1) {
                return simplifyConditional(expression.properties.conditions[0]);
            }
            return {
                type: 'or',
                properties: {
                    conditions: expression.properties.conditions.map(simplifyConditional),
                },
            };
        case 'negate':
            return {
                type: 'negate',
                properties: {
                    condition: simplifyConditional(expression.properties.condition),
                },
            };
        default:
            return expression;
    }
}
