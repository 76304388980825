import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, firstValueFrom, from, Observable} from 'rxjs';
import {Logger} from '@nirby/logger';
import {NirbyPlayerConfig} from '@nirby/models/nirby-player';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {NIRBY_PLAYER_CONFIG} from '@nirby/shared/player-config';
import {ApiError} from '@nirby/runtimes/analytics';


/**
 * A helper to request multiple pages from a query.
 */
export class Query<T = unknown> {
    private readonly pageController = new BehaviorSubject<number>(0);

    /**
     * Constructor.
     * @param requester The function to request a page.
     */
    constructor(
        private readonly requester: (page: number) => Promise<T>,
    ) {
    }

    /**
     * The results of the query for the current page.
     */
    public readonly results$: Observable<T> = this.pageController.pipe(
        switchMap(page => from(this.requester(page))),
        shareReplay({refCount: true, bufferSize: 1}),
    );

    /**
     * The current page
     */
    public readonly page$: Observable<number> = this.pageController.asObservable();

    /**
     * Watches the loading state of the query.
     */
    public readonly loading$: Observable<boolean> = this.results$.pipe(
        map(result => result === null),
    );

    /**
     * Load the next page.
     */
    next(): void {
        this.pageController.next(this.pageController.value + 1);
    }

    /**
     * Load the previous page.
     */
    previous(): void {
        this.pageController.next(this.pageController.value - 1);
    }

    /**
     * Go to page
     * @param page The page to go to.
     */
    goTo(page: number): void {
        this.pageController.next(page);
    }

    /**
     * Reload the current page
     */
    reload(): void {
        this.pageController.next(this.pageController.value);
    }
}

export interface QueryResponse<T> {
    items: T[];
    count: number;
}

@Injectable({
    providedIn: 'root',
})
/**
 * A service to request data from the API.
 */
export class ApiService {
    /**
     * Constructor.
     * @param config The configuration of the player.
     * @param http The HTTP client
     */
    constructor(
        @Inject(NIRBY_PLAYER_CONFIG) private config: NirbyPlayerConfig,
        private http: HttpClient,
    ) {
    }

    /**
     * Get a path to a resource in the API.
     * @param path The path to the resource.
     *
     * @returns The path to the resource.
     */
    public getPath(path: string): string {
        return `${this.config.api.host}${path}`;
    }

    /**
     * Gets a request and creates a promise from it.
     * @param obs The observable to get the request from.
     *
     * @returns A promise that resolves with the response.
     */
    async handleRequest<ResBody>(obs: Observable<ResBody>): Promise<ResBody> {
        try {
            return await firstValueFrom(obs);
        } catch (e) {
            if (e instanceof HttpErrorResponse) {
                throw new ApiError(e.status, e.error);
            } else {
                throw e;
            }
        }
    }

    /**
     * Creates a PUT request.
     * @param path The path to the resource.
     * @param body The body of the request.
     * @param key The key to use for the request.
     *
     * @returns A promise that resolves with the response.
     */
    async put<ResBody, ReqBody = object>(
        path: string,
        body: ReqBody,
        key?: string,
    ): Promise<ResBody> {
        Logger.logStyled('API:PUT', path, body);
        return await this.handleRequest(
            this.http.put<ResBody>(this.getPath(path), body, {
                headers: await this.getAuthorizationHeader(key),
            }),
        );
    }

    /**
     * Creates a POST request.
     * @param path The path to the resource.
     * @param body The body of the request.
     * @param key The key to use for the request.
     */
    async post<ResBody, ReqBody = object>(
        path: string,
        body: ReqBody,
        key?: string,
    ): Promise<ResBody> {
        Logger.logStyled('API:POST', path, body);
        return await this.handleRequest(
            this.http.post<ResBody>(this.getPath(path), body, {
                headers: await this.getAuthorizationHeader(key),
            }),
        );
    }

    /**
     * Gets authorized header with the given key.
     * @param key The key to use for the request.
     *
     * @returns The authorized header.
     */
    async getAuthorizationHeader(
        key?: string,
    ): Promise<{ Authorization: string } | { [key: string]: string }> {
        return key
            ? {
                Authorization: `APIKEY ${key}`,
            }
            : {};
    }

    /**
     * Creates a GET request.
     * @param path The path to the resource.
     * @param queryParams The query parameters to use for the request.
     * @param key The key to use for the request.
     *
     * @returns A promise that resolves with the response.
     */
    async get<ResBody>(
        path: string,
        queryParams: {[key: string]: string | number | boolean | undefined},
        key?: string,
    ): Promise<ResBody> {
        Logger.logStyled('API:GET', path, queryParams);
        const params = Object.entries(queryParams)
            .filter(([, value]) => value !== undefined)
            .reduce((acc, [key, value]) => {
                if (value !== undefined) {
                    acc[key] = value;
                }
                return acc;
            }, {} as {[key: string]: string | number | boolean});

        return await this.handleRequest(
            this.http.get<ResBody>(this.getPath(path), {
                headers: await this.getAuthorizationHeader(key),
                params,
            }),
        );
    }

    /**
     * Sends a beacon to the API.
     * @param path The path to the resource.
     * @param data The data to send.
     *
     * @returns The send beacon response.
     */
    sendBeacon(path: string, data: object): boolean {
        Logger.logStyled('API:BEACON', path, data);
        const blob = new Blob([JSON.stringify(data)], {
            type: 'application/json',
        });
        return navigator.sendBeacon(this.getPath(path), blob);
    }
}
