import {Injectable} from '@angular/core';
import {WorkspaceService} from './workspace.service';
import {RouteData, RouteDisplay} from '@nirby/models/editor';
import {RouteCondition} from '@nirby/models/nirby-player';
import {NirbyCollection} from '@nirby/store/base';
import {COLLECTION_KEYS} from '@nirby/store/collections';
import {Database} from '@nirby/ngutils';

@Injectable({
    providedIn: 'root',
})
/**
 * The service that manages the routes collection
 */
export class RouteService {
    /**
     * Get the collection of routes for a workspace
     * @param workspaceId The workspace ID
     *
     * @returns The collection of routes
     */
    collection(workspaceId: string): NirbyCollection<RouteData> {
        return this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(workspaceId),
            COLLECTION_KEYS.ROUTES,
        );
    }

    /**
     * Constructor.
     * @param db The Firestore service
     * @param widgets The workspace service
     * @protected
     */
    protected constructor(
        private readonly db: Database,
        private widgets: WorkspaceService,
    ) {
    }

    /**
     * Updates a route
     * @param workspaceId The workspace ID
     * @param id The route ID
     * @param data The data to update
     *
     * @returns A promise that resolves when the route is updated
     */
    async update(
        workspaceId: string,
        id: string,
        data: Partial<RouteData>,
    ): Promise<void> {
        await this.db.runTransaction(async (transaction) => {
            // get
            const widget = await this.widgets.collection.getTransaction(
                transaction,
                workspaceId,
            );
            if (!widget) {
                return;
            }
            const route = await this.collection(workspaceId).getTransaction(
                transaction,
                id,
            );
            if (!route) {
                return;
            }

            // update route
            this.collection(workspaceId).updateTransaction(
                transaction,
                id,
                data,
            );

            // update corresponding value on widget priority list
            const priorityList: RouteDisplay[] = widget.data.priorityList.map(
                (r) => {
                    const condition: RouteCondition =
                        typeof r.route === 'string'
                            ? {
                                content: r.route,
                                type: 'match',
                            }
                            : {...r.route};
                    return r.routePath.path ===
                    this.collection(workspaceId).ref(id).path
                        ? {
                            ...r,
                            ...data,
                        }
                        : {
                            routePath: r.routePath,
                            route: condition,
                        };
                },
            );
            await this.widgets.collection.updateTransaction(
                transaction,
                workspaceId,
                {priorityList},
            );
        });
    }
}
