/* eslint-disable @typescript-eslint/no-unused-vars */
import Konva from 'konva';
import {BehaviorSubject, combineLatest, EMPTY, Observable, Subscription, switchMap} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {Logger} from '@nirby/logger';
import {NirbyVariableNullable} from '@nirby/runtimes/state';
import {ArtBoardItem, ArtBoardItemKonvaNode, ArtBoardItemKonvaParent} from '../art-board-item';
import {ArtBoardItemTransformController} from './controllers/transform';
import {ShapeCursorListener} from '../shape-cursor-listener';
import {BlockEvent, BlockEventOfName} from '../designs/unit.model';
import {AnyBlock, AnyCardAction, BlockActions} from '@nirby/models/nirby-player';
import {BlockShapeController} from './controllers/shape';
import {generateId} from '../utils';
import {BlockEventController} from './controllers/events';
import {NirbyBoardStateNode} from '../art-board-item-factory';
import {NirbyContext, NirbyMemoryMask, StateManager} from '@nirby/runtimes/context';
import Vector2d = Konva.Vector2d;

type BlockToActionTransformer<K extends BlockEvent> = (
    e: K
) => AnyCardAction | null;
type BlockToActionTransformers = {
    [EventType in BlockEvent['type']]?: BlockToActionTransformer<
        BlockEventOfName<EventType>
    >;
};

interface BlockOptions<TB extends AnyBlock> {
    style: TB['style'];
    content: TB['content'];
    actions: BlockActions;
}

/**
 * A block that can be drawn on the canvas.
 */
export abstract class Block<
    TMeta,
    TB extends AnyBlock = AnyBlock,
    TS extends ArtBoardItemKonvaNode = ArtBoardItemKonvaNode
> implements ArtBoardItem<TMeta, TS, TB> {
    public static readonly GLOBAL_MASK_NAME = 'global';
    public readonly type = 'block' as const;
    public readonly id: string;

    public readonly memory: NirbyMemoryMask;
    readonly MIN_WIDTH: number = 2;
    readonly MIN_HEIGHT: number = 2;

    private subscriptions = new Subscription();
    private readonly shapeSubject = new BehaviorSubject<TS | null>(null);

    private readonly layerSubject = new BehaviorSubject<Konva.Layer | null>(
        null,
    );

    /**
     * Observable to watch the current shape of the block.
     */
    public get shape$(): Observable<TS | null> {
        return this.shapeSubject.asObservable();
    }

    private readonly contentSubject: BehaviorSubject<TB['content']>;
    private readonly styleSubject: BehaviorSubject<TB['style']>;

    private readonly actionsSubject: BehaviorSubject<BlockActions>;
    readonly KEEP_ASPECT_RATIO = false;
    public readonly ANCHORS: ReadonlyArray<string> = [
        'top-left',
        'top-center',
        'top-right',
        'middle-left',
        'middle-right',
        'bottom-left',
        'bottom-center',
        'bottom-right',
    ];
    public readonly transformController: ArtBoardItemTransformController<TMeta>;

    public readonly eventsController: BlockEventController<TMeta>;
    public readonly controllerCursor: ShapeCursorListener<TMeta>;

    public readonly controllerShape: BlockShapeController<TMeta>;

    public blockToActionTransformers: BlockToActionTransformers = {};

    /**
     * Get the block's layer.
     * @private
     */
    private get layer(): Konva.Layer | null {
        return this.layerSubject.value;
    }

    /**
     * Get the shape's parent group, if any.
     */
    public get parent(): Konva.Group | null {
        const shape = this.shape;
        if (!shape || !(shape.parent instanceof Konva.Group)) {
            return null;
        }
        return shape.parent;
    }

    protected eventsObservable: Observable<BlockEvent> = EMPTY;

    public options$: Observable<BlockOptions<TB>>;

    protected shapeNames = 'block';

    setting: Promise<void> | null = null;

    private readonly globalMemory$ = this.context.memory$.pipe(
        switchMap((memory) => memory.memory$),
        map((context) => context.memory.mask(Block.GLOBAL_MASK_NAME)),
    );

    /**
     * Checks if an ArtBoardItem is a block
     * @param item Item to check
     *
     * @returns - True if the item is a block
     */
    public static isBlock<TMeta>(item: ArtBoardItem<TMeta> | unknown): item is Block<TMeta> {
        // noinspection SuspiciousTypeOfGuard
        return item instanceof Block;
    }

    /**
     * Checks if an ArtBoardStateNode is a block state
     * @param item Item to check
     *
     * @returns - True if the item is a block
     */
    static isBlockState<TMeta>(item: NirbyBoardStateNode<TMeta>): item is NirbyBoardStateNode<TMeta, Block<TMeta>> {
        return item.type === 'block';
    }

    /**
     * Gets a block as a {@link NirbyBoardStateNode}
     * @param block - Block to get
     * @param meta - Metadata to attach to the node
     *
     * @returns - A {@link NirbyBoardStateNode}
     */
    public static blockToState<TMeta>(block: AnyBlock, meta: TMeta | null): NirbyBoardStateNode<TMeta, Block<TMeta>> {
        return {
            id: block.hash,
            type: 'block',
            properties: block,
            children: [],
            meta,
        };
    }

    /**
     * Moves the block to the given position in the canvas.
     * @param motion The new position of the block.
     * @param emitUpdate Whether to emit an update event.
     */
    public move(motion: Vector2d, emitUpdate = false): void {
        if (!this.shape) {
            return;
        }
        const newPosition: Vector2d = {
            x: this.shape.x() + motion.x,
            y: this.shape.y() + motion.y,
        };
        this.controllerShape.moveTo(newPosition, emitUpdate);
    }

    /**
     * Receives a configuration and applies changes to it before sending it to the block.
     *
     * This is a virtual method that might be overridden by subclasses if needed.
     *
     * @param options The configuration to apply.
     * @protected
     */
    protected preprocess(options: TB): void {
        return;
    }

    /**
     * Event observable to watch for events emitted by this block.
     */
    public get events$(): Observable<BlockEvent> {
        return this.eventsObservable;
    }

    /**
     * Get the global state manager.
     * @protected
     */
    protected get global(): StateManager {
        return this.context.global;
    }

    /**
     * An observable to watch the options (description) of this drawable
     */
    public get config$(): Observable<TB> {
        return combineLatest([
            this.globalMemory$.pipe(
                // If in editor mode, we want to update the options from the memory only once
                debounceTime(this.editable ? 100 : 0),
                map((v) => v.state),
            ),
            this.options$,
        ]).pipe(
            map(([, o]) => {
                const block: TB = {
                    actions: o.actions,
                    hash: this.id,
                    position: this.positioning,
                    type: this.initialOptions.type,
                    content: {...o.content},
                    style: {...o.style},
                    scale: this.scale,
                    rotation: this.rotation,
                } as TB;
                if (!this.editable) {
                    this.preprocess(block);
                }
                return block;
            }),
        );
    }

    /**
     * Constructor.
     * @param context The context of the canvas.
     * @param initialOptions The initial options of this drawable.
     * @param editable Whether this drawable is editable.
     * @param meta The metadata of this drawable.
     */
    public constructor(
        public readonly context: NirbyContext,
        private readonly initialOptions: TB,
        public readonly editable: boolean,
        public readonly meta: TMeta | null,
    ) {
        this.id =
            initialOptions.hash.length > 0 ? initialOptions.hash : generateId();
        this.contentSubject = new BehaviorSubject<TB['content']>(
            initialOptions.content,
        );
        this.styleSubject = new BehaviorSubject<TB['style']>(
            initialOptions.style,
        );
        this.actionsSubject = new BehaviorSubject<BlockActions>(
            initialOptions.actions,
        );
        this.options$ = combineLatest([
            this.styleSubject.asObservable(),
            this.contentSubject.asObservable(),
            this.actionsSubject.asObservable(),
        ]).pipe(
            map(([style, content, actions]) => {
                return {
                    content,
                    style,
                    actions,
                };
            }),
        );
        const events = (this.eventsController = new BlockEventController<TMeta, this>(this));
        this.transformController = new ArtBoardItemTransformController(events);
        this.controllerCursor = new ShapeCursorListener(this);
        this.controllerShape = new BlockShapeController(events);
        this.memory = context.memory.mask(this.id);
    }

    /**
     * The current position of the block.
     */
    public get position(): Vector2d {
        return {
            x: this.shape?.x() ?? this.initialOptions.position[0].x,
            y: this.shape?.y() ?? this.initialOptions.position[0].y,
        };
    }

    /**
     * The current size of the block.
     */
    public get size(): Vector2d {
        return {
            x:
                this.shape?.width() ??
                this.initialOptions.position[1].x -
                this.initialOptions.position[0].x,
            y:
                this.shape?.height() ??
                this.initialOptions.position[1].y -
                this.initialOptions.position[0].y,
        };
    }

    /**
     * The current start and ending positions of the block.
     */
    public get positioning(): [Vector2d, Vector2d] {
        const position = this.position;
        const size = this.size;
        return [
            position,
            {
                x: position.x + size.x,
                y: position.y + size.y,
            },
        ];
    }

    /**
     * The current scale of the block.
     */
    public get scale(): Vector2d {
        return {
            x: this.shape?.scaleX() ?? 1,
            y: this.shape?.scaleY() ?? 1,
        };
    }

    /**
     * The current style of the block.
     */
    public get style(): TB['style'] {
        return {...this.styleSubject.value};
    }

    /**
     * The current content of the block.
     */
    public get content(): TB['content'] {
        return {...this.contentSubject.value};
    }

    /**
     * The current actions of the block.
     */
    public get actions(): BlockActions {
        return {...this.actionsSubject.value};
    }

    /**
     * Get the description before the pre-processing.
     */
    public get rawOptions(): TB {
        // deepCopyBlock(this.contentSubject.value)
        return {
            type: this.initialOptions.type,
            content: this.content,
            style: this.style,
            hash: this.id,
            position: this.positioning,
            rotation: this.shape?.rotation() ?? this.initialOptions.rotation,
            scale: this.scale,
            actions: this.actionsSubject.value,
        } as TB;
    }

    /**
     * Set the description.
     *
     * @param value - The new description.
     */
    public set rawOptions(value: TB) {
        this.shape?.position(value.position[0]);
        this.shape?.rotation(value.rotation);
        this.shape?.size({
            width: value.position[1].x - value.position[0].x,
            height: value.position[1].y - value.position[0].y,
        });
        this.contentSubject.next(value.content);
        this.styleSubject.next(value.style);
        this.actionsSubject.next(value.actions);
    }

    /**
     * Get the shape being used to display this block.
     * @private
     */
    private get storedShape(): TS | null {
        return this.shapeSubject.value;
    }

    /**
     * Sets the shape to be used to display this block.
     * @param value The new shape.
     * @private
     */
    private set storedShape(value: TS | null) {
        this.shapeSubject.next(value);
    }

    /**
     * Get the shape being used to display this block.
     *
     * @public
     */
    public get shape(): TS | null {
        return this.storedShape;
    }

    /**
     * The current shape rotation.
     */
    public get rotation(): number {
        return this.shape?.rotation() ?? 0;
    }

    /**
     * Sets the current shape rotation.
     * @param value The new rotation.
     */
    public set rotation(value: number) {
        this.shape?.rotation(value);
    }

    /**
     * Sets whether this block is visible or not.
     * @param value The new visibility.
     */
    public set visible(value: boolean) {
        this.shape?.visible(value);
    }

    /**
     * Whether this block is visible or not.
     */
    public get visible(): boolean {
        return this.shape?.visible() ?? false;
    }

    /**
     * Gets the current stage where this block is being displayed
     */
    public get stage(): Konva.Stage | null {
        return this.shape?.getStage() ?? null;
    }

    /**
     * Method to batch draw the block's layer.
     * @protected
     */
    protected batchDraw(): void {
        if (!Konva.autoDrawEnabled) {
            this.layer?.batchDraw();
        }
    }

    /**
     * Method to destroy the block and dispose it.
     */
    public dispose(): void {
        if (!this.shape) {
            Logger.warnStyled(
                'BLOCK-EDITOR:BLOCK',
                `Trying to destroy uninitialized drawable: ${this.id}`,
            );
            return;
        }
        Logger.logStyled('BLOCK-EDITOR:BLOCK', `Destroying block ${this.id}`);
        this.transformController.currentTransformer?.select(null);
        this.shape.destroy();
        this.storedShape = null;
        this.subscriptions.unsubscribe();
        this.subscriptions = new Subscription();
    }

    protected abstract generateShape(): Promise<TS>;

    /**
     * Which cursor to use when hovering over this block.
     */
    public get cursorHover(): string | null {
        return this.properties.actions['click'].length > 0 ? 'pointer' : null;
    }

    /**
     * Sets the cursor for the current stage
     * @param cursor The cursor to set
     */
    public setCursor(cursor: string): void {
        if (!this.stage) {
            Logger.warnStyled(
                'BLOCK',
                'Tried to set cursor but no stage was found',
            );
            return;
        }
        this.stage.container().style.cursor = cursor;
    }

    /**
     * Sets this block's description.
     * @param block The block description to use.
     */
    public set(block: TB): void {
        this.rawOptions = block;
    }

    /**
     * Subscribe to event handlers
     * @param shape The shape that emits the events to subscribe to
     * @private
     *
     * @returns - The subscription
     */
    protected subscribeToEvents(shape: TS): Subscription {
        const subscriptions = new Subscription();
        if (this.editable) {
            // editor handlers
            subscriptions.add(
                this.transformController.dragger.dragStart$.subscribe(() => {
                    this.controllerShape.moveToTop();
                }),
            );
            subscriptions.add(
                this.transformController.transformationEnd$.subscribe((type) => {
                    this.transformController.onTransformationEnd(
                        undefined,
                        type === 'dragend' ? 'top' : 0,
                    );
                }),
            );
            subscriptions.add(
                this.transformController.dragger.dragChange$.subscribe(
                    (newPosition) => {
                        if (newPosition) {
                            this.shape?.absolutePosition(newPosition);
                        }
                    },
                ),
            );
            subscriptions.add(
                this.transformController.resizer.onSizeChange$.subscribe(
                    (resize) => {
                        this.adjustScaleAndPosition(resize);
                    },
                ),
            );
            subscriptions.add(
                this.transformController.dragger.isDraggingOutside$.subscribe(
                    (isDraggingOutside) => {
                        if (!this.shape) {
                            return;
                        }
                        this.shape.opacity(isDraggingOutside ? 0.5 : 1.0);
                        this.batchDraw();
                    },
                ),
            );
        } else {
            // player handlers
            subscriptions.add(
                this.controllerCursor.enter$.subscribe(() => {
                    this.setCursor(
                        this.editable
                            ? 'pointer'
                            : this.cursorHover ?? 'default',
                    );
                }),
            );
            subscriptions.add(
                this.controllerCursor.click$.subscribe(() => {
                    this.trigger('click', this.actions['click']);
                }),
            );
        }
        // player + editor handlers
        subscriptions.add(
            this.controllerCursor.leave$.subscribe(() => {
                if (!this.stage) {
                    Logger.warnStyled(
                        'BLOCK',
                        'Tried to set cursor but no stage was found',
                    );
                    return;
                }
                this.setCursor('default');
            }),
        );
        return subscriptions;
    }

    /**
     * Adjusts scale and position after resize
     * @param resize The new resize
     */
    protected adjustScaleAndPosition(resize: {
        x: number;
        y: number;
        width: number;
        height: number;
    }): void {
        if (!this.shape) {
            return;
        }
        this.shape.setAttrs({
            scaleX: 1,
            scaleY: 1,
            width: resize.width,
            height: resize.height,
            absolutePosition: {
                x: resize.x,
                y: resize.y,
            },
        });
    }

    /**
     * Emit an array of actions to be executed
     * @param trigger What triggered the action
     * @param actions Array of actions to be executed
     */
    public trigger(trigger: string, actions: AnyCardAction[]): void {
        let action: AnyCardAction;
        for (action of actions) {
            this.context.triggerAction(this.id, trigger, action);
        }
    }

    /**
     * Whether this shape is draggable or not
     */
    get draggable(): boolean {
        return this.shape?.draggable() ?? false;
    }

    /**
     * Whether this shape is draggable or not
     * @param value Whether this shape is draggable or not
     */
    set draggable(value: boolean) {
        this.shape?.draggable(value);
    }

    /**
     * Emit a block event as an action
     * @param event Event to convert to a trigger
     */
    protected triggerBlockEvent<Event extends BlockEvent>(event: Event): void {
        const type: Event['type'] = event.type;
        const transformer = this.blockToActionTransformers[type] as
            | BlockToActionTransformer<Event>
            | undefined;
        if (transformer === undefined) {
            return;
        }
        const action = transformer(event);
        if (action === null) {
            return;
        }
        this.trigger('Block.' + event.type, [action]);
    }

    /**
     * Initialize the shape
     * @param parent The generated shape's parent
     *
     * @returns - Generated shape promise
     */
    public async init(parent?: ArtBoardItemKonvaParent): Promise<TS> {
        if (this.shape) {
            return this.shape;
        }

        if (parent) {
            const oldShapes = parent.find('#' + this.id);
            oldShapes.map((oldShape) => {
                Logger.warnStyled('BLOCK', `Removing old shape ${this.id}`);
                oldShape.destroy();
            });
        }

        const shape = await this.generateShape();
        shape.id(this.id);
        shape.x(this.properties.position[0].x);
        shape.y(this.properties.position[0].y);
        shape.width(this.configSize.x);
        shape.height(this.configSize.y);
        shape.rotation(this.properties.rotation);
        shape.addName(this.shapeNames);

        this.storedShape = shape;
        this.subscriptions.add(this.subscribeToEvents(shape));

        this.subscriptions.add(
            this.config$.subscribe(
                async () => await this.updateShapeFromConfiguration(),
            ),
        );

        if (parent) {
            shape.moveTo(parent);
        }
        this.layerSubject.next(shape.getLayer());
        return shape;
    }

    /**
     * Custom method to update the shape from the configuration
     * @protected
     */
    protected abstract updateShapeFromBlock(): Promise<void>;

    /**
     * Update the shape from the configuration
     * @private
     */
    private async updateShapeFromConfiguration(): Promise<void> {
        if (!this.shape) {
            Logger.warn('Trying to update uninitialized drawable');
            return;
        }
        const shape = this.shape;
        const block = this.rawOptions;

        // update shape position, rotation and size from block data
        shape.rotation(block.rotation);
        shape.position(block.position[0]);
        if (block.scale) {
            shape.scale(
                typeof block.scale === 'number'
                    ? {x: block.scale, y: block.scale}
                    : block.scale,
            );
        }

        const size = this.configSize;
        shape.size({
            width: size.x,
            height: size.y,
        });
        shape.id(this.id);

        // update block drawing from block data
        await this.updateShapeFromBlock();
        this.batchDraw();
    }

    /**
     * Get this block's description
     */
    get blockProperties(): TB['content'] & TB['style'] {
        return {
            ...this.propertiesPost.content,
            ...this.propertiesPost.style,
        };
    }

    /**
     * Get this block's description
     */
    get properties(): TB {
        return this.rawOptions;
    }

    /**
     * Gets a variable value
     * @param name Name of the variable
     * @private
     *
     * @returns - Variable value
     */
    protected var(name: string): NirbyVariableNullable {
        return this.global.get(name) ?? ('{{' + name + '}}');
    }

    /**
     * Properties of the block after processing. Will do nothing if the block is in edit mode.
     */
    get propertiesPost(): TB {
        const options = this.rawOptions;
        if (!this.editable) {
            this.preprocess(options);
        }
        return options;
    }

    /**
     * This block's position according to the current configuration
     */
    get configPosition(): Vector2d {
        const block = this.properties;
        return {
            x: block.position[0].x,
            y: block.position[0].y,
        };
    }

    /**
     * This block's size according to the current configuration
     */
    get configSize(): Vector2d {
        const block = this.properties;
        return {
            x: block.position[1].x - block.position[0].x,
            y: block.position[1].y - block.position[0].y,
        };
    }

    /**
     * Children of this block are empty
     */
    get children(): NirbyBoardStateNode<TMeta>[] {
        return [];
    }

    /**
     * Preload the block.
     *
     * @returns - Promise that resolves when the block is loaded
     */
    preload(): Promise<void> {
        return Promise.resolve();
    }
}
