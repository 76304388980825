import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';
import {TranslatorLike} from '@nirby/runtimes/i18n';


@Injectable({
    providedIn: 'root',
})
/**
 * A translator that uses the Angular Translate service.
 */
export class NgTranslator implements TranslatorLike {
    /**
     * Constructor.
     * @param service The translate service.
     */
    constructor(private readonly service: TranslateService) {
    }

    /**
     * Translates the given key.
     * @param key The key.
     *
     * @returns The translated key.
     */
    translate(key: string): string {
        return this.service.instant(key);
    }

    get language(): string | null {
        return this.service.currentLang;
    }
}
