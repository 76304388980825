import {Inject, Injectable} from '@angular/core';
import {VideoLiteService} from './video-lite.service';
import {MEDIA_CONFIG, MediaConfig} from '../media-token';

@Injectable({
    providedIn: 'root'
})
/**
 * A service to upload videos to the API through Cloudflare Stream.
 */
export class CloudflareLiteService implements VideoLiteService {

    /**
     * Constructor.
     * @param config The environment.
     */
    constructor(
        @Inject(MEDIA_CONFIG) private readonly config: MediaConfig,
    ) {
    }

    /**
     * Gets the URL to embed a video from Cloudflare Stream
     * @param id The ID of the video.
     *
     * @returns The URL to embed the video.
     */
    getEmbedUrlFromId(id: string): string {
        const customerCode = this.config.cloudflareCustomerCode;
        return `https://customer-${customerCode}.cloudflarestream.com/${id}/iframe`;
    }
}
