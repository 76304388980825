import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {NgbNav} from '@ng-bootstrap/ng-bootstrap';
import {BehaviorSubject, combineLatest, scan, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {ArtBoardEditorMaster} from '@nirby/runtimes/canvas';
import {BlockContentRegisterService} from '../../services/registers/block-content-register.service';
import {BlockStyleRegisterService} from '../../services/registers/block-style-register.service';
import {BlockController} from '../../services/block-controller';
import {NirbyProductType} from '@nirby/analytics-typings';
import {AnyPrimeSource, AppCard, AppVariable} from '@nirby/models/editor';
import {NirbyDocument} from '@nirby/store/base';

interface Tab {
    id: string;
    active: boolean;
}

@Component({
    selector: 'nirby-block-editor',
    templateUrl: './block-editor.component.html',
    styleUrls: ['./block-editor.component.scss'],
})
export class BlockEditorComponent<TMeta = unknown> implements OnInit, OnDestroy {
    private readonly blockIdSubject = new BehaviorSubject<string | null>(null);
    private readonly controllerSubject =
        new BehaviorSubject<ArtBoardEditorMaster<TMeta> | null>(null);
    @Output() public readonly closeRequest = new EventEmitter<void>();

    @Input() set blockId(value: string | null) {
        this.blockIdSubject.next(value);
    }

    @Input() productType: NirbyProductType | null = null;
    @Input() cardSiblings: NirbyDocument<AppCard>[] = [];
    @Input() availableVideos: NirbyDocument<AnyPrimeSource>[] = [];
    @Input() variables: AppVariable[] = [];

    @Input() set master(value: ArtBoardEditorMaster<TMeta> | null) {
        this.controllerSubject.next(value);
    }

    public readonly master$ = this.controllerSubject.pipe(
        filter((master): master is ArtBoardEditorMaster<TMeta> => !!master),
    );

    constructor(
        private contentRegister: BlockContentRegisterService,
        private styleRegister: BlockStyleRegisterService,
        private cdr: ChangeDetectorRef,
    ) {
    }

    private readonly subscription = new Subscription();

    @ViewChild(NgbNav) private nav?: NgbNav;

    public readonly blockController$ = combineLatest([
        this.master$,
        this.blockIdSubject,
    ]).pipe(
        map(([master, blockId]) => ({master, blockId})),
        scan<
            { master: ArtBoardEditorMaster<TMeta>; blockId: string | null },
            BlockController<TMeta> | null
        >((previous, {master, blockId}) => {
            if (previous) {
                this.subscription.remove(previous);
                previous.dispose();
            }
            const newController = blockId
                ? new BlockController(blockId, master)
                : null;
            if (newController) {
                this.subscription.add(newController);
            }
            return newController;
        }, null),
    );
    @Input() disabled = false;

    ngOnInit(): void {
        this.subscription.add(
            this.blockController$.subscribe((block) => {
                if (block) {
                    this.updateNavTab(block);
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public hasStyle(controller: BlockController<TMeta>): boolean {
        const type = controller.type;
        if (!type) {
            return false;
        }
        return this.styleRegister.has(type);
    }

    public hasContent(controller: BlockController<TMeta>): boolean {
        const type = controller.type;
        if (!type) {
            return false;
        }
        return this.contentRegister.has(type);
    }

    updateNavTab(block: BlockController<TMeta>): void {
        const nav = this.nav;
        if (block && nav) {
            const tabs: Tab[] = [
                {
                    id: 'content',
                    active: this.hasContent(block),
                },
                {
                    id: 'style',
                    active: this.hasContent(block),
                },
                {
                    id: 'actions',
                    active: true,
                },
            ];

            const isTabActive = !!tabs.find(
                (t) => t.id === nav.activeId && t.active,
            );
            if (!isTabActive) {
                let tab: Tab;
                for (tab of tabs) {
                    if (tab.active) {
                        nav.select(tab.id);
                        break;
                    }
                }
            }
        }
        this.cdr.detectChanges();
    }

    /**
     * Requests the editor to be closed.
     */
    close(): void {
        this.closeRequest.emit();
    }
}
