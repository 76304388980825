import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, UrlTree} from '@angular/router';
import {AuthenticationService} from '@nirby/shared/database';
import {LoadScreenService} from '@nirby/shared/load-screen';

@Injectable({
    providedIn: 'root',
})
/**
 * A guard to prevent the user from accessing a page if they are not logged in.
 */
export class IsLoggedInGuard implements CanActivate {
    /**
     * Constructor.
     * @param auth Authentication service.
     * @param route Router service.
     * @param loader Load screen service.
     */
    constructor(
        private auth: AuthenticationService,
        private route: Router,
        private loader: LoadScreenService,
    ) {
    }

    /**
     * Whether the user is logged in.
     * @param route The route.
     */
    async canActivate(route: ActivatedRouteSnapshot): Promise<true | UrlTree> {
        // check if user is logged in
        const currentUser = await this.loader.untilCompletion(() => this.auth.getAuthenticatedUser(), 'block');
        if (!currentUser) {
            return this.route.createUrlTree(['/auth/login']);
        }

        // check if route requires admin access and if user is admin
        const requiresAdminAccess = route.data['requiresAdminAccess'];
        const hasEnoughPermissions =
            !requiresAdminAccess || currentUser.data.isDeveloper;

        if (!hasEnoughPermissions) {
            // UN-SATISFY THE INTRUDER
            window.open('https://vimeo.com/189919038', '_blank');
            return this.route.createUrlTree(
                await this.auth.getUserHomeCommands(currentUser.data),
            );
        }
        return true;
    }
}
