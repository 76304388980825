// noinspection JSDeprecatedSymbols

import {CardPosition} from './card-position';
import {AnyBlock, AnyCardAction, Card, NirbyPlayerConfig, Vector2d} from '@nirby/models/nirby-player';

export type VideoTrackEvent = 'disappear' | 'appear';
export type TrackPlayType = 'loop' | 'story';

/**
 * Older version of the card track.
 * @deprecated Use {@link VideoTrackCard} instead.
 */
export interface VideoCard {
    trackType: undefined;
    card: Card;
    start: number;
    type: TrackPlayType;
    duration: number;
    position: CardPosition;
    actions: Partial<Record<VideoTrackEvent, AnyCardAction[]>>;
}

/**
 * The base for a video track.
 */
export interface VideoTrackBase<TType extends string,
    TProperties extends object> {
    // properties
    id: string;
    trackType: TType;
    properties: TProperties;
    actions: Partial<Record<VideoTrackEvent, AnyCardAction[]>>;
    // positioning
    position: Vector2d;
    rotation: number;
    size: Vector2d;
    scale: Vector2d;
    // time
    start: number;
    duration: number;
    type: TrackPlayType;
}

/**
 * A card on the video
 */
export type VideoTrackCard = VideoTrackBase<'card',
    {
        card: Card;
    }>;

/**
 * A block on the video
 */
export type VideoTrackBlock = VideoTrackBase<'block',
    {
        block: AnyBlock;
    }>;

/**
 * All updated video tracks.
 */
export type AnyVideoTrack = VideoTrackCard | VideoTrackBlock;

/**
 * All compatible video tracks.
 */
export type AnyVideoTrackWithLegacy = VideoCard | AnyVideoTrack;

/**
 * Get the position of a card made with the legacy track data.
 * @param legacyPosition The original position.
 * @param aspectRatio The aspect ratio of the video.
 * @param config The configuration of the player.
 *
 * @returns - The position of the card.
 */
function getLegacyCardPositionAndSize(
    legacyPosition: CardPosition,
    aspectRatio: number,
    config: NirbyPlayerConfig,
): { position: Vector2d; size: Vector2d } {
    // FIXME: this will work only for horizontal videos
    const frameSize = {
        x: config.responsive.baseDimension * aspectRatio,
        y: config.responsive.baseDimension,
    };

    const MARGIN = 16;

    const position: Vector2d = {
        x: MARGIN,
        y: MARGIN,
    };
    const size: Vector2d = {
        x: config.cardSize.x,
        y: config.cardSize.y,
    };
    switch (legacyPosition) {
        case 'top-left':
            position.x = MARGIN;
            position.y = MARGIN;
            break;
        case 'top-right':
            position.x = frameSize.x - size.x - MARGIN;
            position.y = MARGIN;
            break;
        case 'bottom-left':
            position.x = MARGIN;
            position.y = frameSize.y - size.y - MARGIN;
            break;
        case 'bottom-right':
            position.x = frameSize.x - size.x - MARGIN;
            position.y = frameSize.y - size.y - MARGIN;
            break;
        case 'middle-left':
            position.x = MARGIN;
            position.y = (frameSize.y - size.y) / 2;
            break;
        case 'middle-right':
            position.x = frameSize.x - size.x - MARGIN;
            position.y = (frameSize.y - size.y) / 2;
            break;
    }

    return {position, size};
}

/**
 * Upgrades a legacy video track to the new format.
 * @param track The legacy track.
 * @param aspectRatio The aspect ratio of the video (width / height).
 * @param config The player configuration.
 *
 * @returns - The new track.
 */
function upgradeLegacyCardTrack(
    track: VideoCard,
    aspectRatio: number,
    config: NirbyPlayerConfig,
): VideoTrackCard {
    const {position, size} = getLegacyCardPositionAndSize(
        track.position,
        aspectRatio,
        config,
    );
    return {
        // properties
        id: track.card.hash,
        trackType: 'card',
        properties: {
            card: track.card,
        },
        actions: track.actions,
        // positioning
        position,
        rotation: 0,
        size,
        scale: {x: 1, y: 1},
        // time
        type: track.type,
        start: track.start,
        duration: track.duration,
    };
}

/**
 * Upgrades an array of tracks to the new format.
 * @param tracks The legacy tracks.
 * @param aspectRatio The aspect ratio of the video (width / height).
 * @param config The player configuration.
 *
 * @returns - The upgraded tracks.
 */
export function upgradeTracks(
    tracks: AnyVideoTrackWithLegacy[],
    aspectRatio: number,
    config: NirbyPlayerConfig,
): AnyVideoTrack[] {
    return tracks.map((track) => {
        if (!track.trackType) {
            return upgradeLegacyCardTrack(track, aspectRatio, config);
        } else {
            return track;
        }
    });
}

/**
 * A moment in a video.
 */
export interface VideoTimestamp {
    key: string;
    time: number;
}

/**
 * A video source for a Vimeo video.
 */
export interface SourceData {
    src: string;
}

/**
 * A Prime source description
 */
export interface PrimeSource {
    aggroPower?: number;
    title: string;
    source: SourceData;
    // TODO: rename 'cards' to 'tracks'
    cards: AnyVideoTrackWithLegacy[];
    nextVideo: VideoTimestamp | string | null;
    actions?: {
        start: AnyCardAction[];
        finish: AnyCardAction[];
    };
    stopBeforeEndMs?: number | null;
}

/**
 * @deprecated Prefer using {@link PrimeSource}
 */
export type Video = PrimeSource;
