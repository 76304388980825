import {Component, TrackByFunction} from '@angular/core';
import {Observable} from 'rxjs';
import {FormGroup, UntypedFormControl} from '@angular/forms';
import {DataDisplay} from '@nirby/player';
import {BlockActionComponent} from '../../blocks/block-action.component';
import {CardsService} from '@nirby/shared/database';
import {CardLinkAction} from '@nirby/models/nirby-player';
import {map} from 'rxjs/operators';
import {AppCard} from '@nirby/models/editor';
import {NirbyDocumentReference} from '@nirby/store/base';

@Component({
    selector: 'nirby-action-go-to-card',
    templateUrl: './action-go-to-card.component.html',
    styleUrls: ['./action-go-to-card.component.scss'],
})
export class ActionGoToCardComponent extends BlockActionComponent<CardLinkAction> {
    constructor(private cards: CardsService) {
        super();
        this.goToOptions$ = this.cards.cardSiblings$.pipe(
            // merge the arrays, to keep the objects of the cards with the same IDs
            map((incoming) => [
                {
                    id: null,
                    name: 'Select a card...',
                    ref: null,
                },
                ...incoming.map((sibling) => ({
                    id: sibling.id,
                    name: sibling.data.title,
                    ref: sibling.ref,
                })),
            ]),
        );
    }

    public readonly goToOptions$: Observable<
        DataDisplay<NirbyDocumentReference<AppCard>>[]
    >;
    public readonly selectedCardController = new UntypedFormControl(null);

    form = new FormGroup({
        key: this.selectedCardController,
    });

    trackByFn: TrackByFunction<DataDisplay<NirbyDocumentReference<AppCard>>> = (
        index,
        item: DataDisplay<NirbyDocumentReference<AppCard>>,
    ): string | null => {
        return item?.id ?? null;
    };

    setData(content: CardLinkAction | null): void {
        if (!content) {
            return;
        }
        this.selectedCardController.setValue(content.options.key);
    }
}
