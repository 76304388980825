import {Component, EventEmitter, forwardRef, Input, Output,} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl,} from '@angular/forms';
import {faCheck, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {VideoRecord} from '@nirby/media-models';
import {VoidFunction} from '@nirby/js-utils/types';
import {VimeoService} from '@nirby/media/video-factory';

@Component({
    selector: 'nirby-video-vimeo-url',
    templateUrl: './video-vimeo-url.component.html',
    styleUrls: ['./video-vimeo-url.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => VideoVimeoUrlComponent),
            multi: true,
        },
    ],
})
export class VideoVimeoUrlComponent implements ControlValueAccessor {
    constructor(private vimeo: VimeoService) {}
    isDisabled = false;

    @Input() showPreview = false;
    @Output() private selectVideo = new EventEmitter<VideoRecord>();

    urlControl = new UntypedFormControl('');
    validUrl: boolean | null = null;

    badIcon = faTimesCircle;
    okIcon = faCheck;

    loading = false;
    video: VideoRecord | null = null;

    onChange: VoidFunction<VideoRecord | null> = () => {
        return;
    };
    onTouched: VoidFunction<VideoRecord | null> = () => {
        return;
    };

    registerOnChange(fn: VoidFunction<VideoRecord | null>): void {
        this.onChange = (v) => {
            this.video = v;
            return fn(v);
        };
    }

    registerOnTouched(fn: VoidFunction<VideoRecord | null>): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (!isDisabled) {
            this.urlControl.enable();
        } else {
            this.urlControl.disable();
        }
    }

    writeValue(obj: VideoRecord | null): void {
        this.onChange(obj);
    }

    async onSubmit(): Promise<void> {
        this.loading = true;
        this.urlControl.disable();
        const video = await this.vimeo.getVideoFromUrl(this.urlControl.value);
        if (video) {
            this.onChange(video);
            this.selectVideo.emit(video);
            this.urlControl.setValue('');

            this.validUrl = true;
        } else {
            this.validUrl = false;
        }
        this.urlControl.enable();
        this.loading = false;
    }
}
