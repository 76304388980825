<label for="img-src">Image URL</label>
<div class="spinner-border" *ngIf="errored === null"></div>
<input
    type="url"
    [ngClass]="{ 'is-invalid': !!errored }"
    class="form-control"
    id="img-src"
    name="src"
    [(ngModel)]="imageURL"
    (input)="errored = null"
/>
<div class="text-danger" *ngIf="errored">This image URL is invalid</div>
<img
    [src]="imageURL"
    [hidden]="true"
    (error)="errored = true"
    (load)="onLoad()"
    alt="validation-element"
/>
