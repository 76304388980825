import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormArray, FormGroup, NG_VALUE_ACCESSOR, UntypedFormControl} from '@angular/forms';
import {faPlus, faTimes} from '@fortawesome/free-solid-svg-icons';
import {AnyCardAction, AnyCardActionType} from '@nirby/models/nirby-player';
import {Subscription} from 'rxjs';

@Component({
    selector: 'nirby-action-form-control-array',
    templateUrl: './action-form-control-array.component.html',
    styleUrls: ['./action-form-control-array.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ActionFormControlArrayComponent),
            multi: true
        }
    ]
})
/**
 * Form control for an array of actions.
 */
export class ActionFormControlArrayComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() actions: AnyCardActionType[] | null = null;

    subscriptions = new Subscription();
    disabled = false;

    formArray = new FormArray([
        new UntypedFormControl(null)
    ])

    form = new FormGroup({
        actions: this.formArray
    });

    icons = {
        add: faPlus,
        close: faTimes
    };
    @Output() public readonly closeRequest = new EventEmitter<void>();

    /**
     * Starts listening for changes in the form to notify the parent component.
     */
    ngOnInit(): void {
        this.subscriptions.add(this.form.valueChanges.subscribe(() => {
            this.onChange(this.formArray.value.filter((action: AnyCardAction | null) => !!action));
        }));
    }

    /**
     * Stops listening for changes in the form.
     */
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * The current array controls.
     */
    get controls(): UntypedFormControl[] {
        return this.formArray.controls as UntypedFormControl[];
    }

    onChange: (value: AnyCardAction[]) => void = () => {
        return;
    };

    onTouched: () => void = () => {
        return;
    };

    /**
     * Registers a callback function that is called when the value changes.
     * @param fn The callback function.
     */
    registerOnChange(fn: (value: AnyCardAction[]) => void): void {
        this.onChange = fn;
    }

    /**
     * Registers a callback function that is called when the control is touched.
     * @param fn The callback function.
     */
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    /**
     * Sets the disabled state of the control.
     * @param isDisabled Whether the control should be disabled.
     */
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Sets the value of the control.
     * @param obj The value to set.
     */
    writeValue(obj: AnyCardAction[] | null): void {
        this.formArray.clear();

        const value = obj || [];
        value.forEach((action: AnyCardAction) => {
            this.formArray.push(new UntypedFormControl(action));
        });
    }

    /**
     * Add an action to the form
     */
    add(): void {
        this.formArray.push(new UntypedFormControl(null));
    }

    /**
     * Remove an action from the form
     * @param index The index of the action to remove.
     */
    removeAction(index: number): void {
        this.formArray.removeAt(index);
    }

    /**
     * Requests the editor to be closed
     */
    close(): void {
        this.closeRequest.emit();
    }
}
