/**
 * Implements an object that can be used to represent a server-side path in a document. Mainly used for queries and
 * updates e.g. the document ID.
 *
 * When implementing a new database, the {@link QueryBuilder} must implement a method that converts a
 * {@link NirbyFieldPathSpecial} to the database's native path representation.
 *
 * @example
 * ```ts
 * db.collection('users').query({
 *     [NirbyFieldPath.documentId]: '123'
 * });
 * ```
 */

export type NirbyFieldPathName = 'documentId';

/**
 * Implements an object that can be used to represent a server-side path in a document. Mainly used for queries and
 * updates e.g. the document ID.
 */
export class NirbyFieldPathSpecial {
    /**
     * Constructor.
     * @param path The path.
     * @private
     */
    private constructor(
        public readonly path: NirbyFieldPathName,
    ) {
    }

    /**
     * The document ID of the document.
     *
     * @returns A field that represents the document ID.
     */
    static documentId(): NirbyFieldPathSpecial {
        return new NirbyFieldPathSpecial('documentId');
    }
}

/**
 * Implements an object that can be used to represent a server-side path in a document. Mainly used for queries and
 */
export class FieldPathMap<Path> {
    /**
     * Constructor.
     * @param map The map.
     * @private
     */
    private constructor(
        private readonly map: Record<NirbyFieldPathName, (path: NirbyFieldPathSpecial) => Path>,
    ) {
    }

    /**
     * Creates a new field path map.
     * @param documentId The document ID map function.
     *
     * @returns A new field path map.
     */
    public static create<Path>(
        documentId: (path: NirbyFieldPathSpecial) => Path,
    ): FieldPathMap<Path> {
        return new FieldPathMap<Path>({
            documentId,
        });
    }

    /**
     * Returns the path for the given field path name.
     * @param path The field path.
     *
     * @returns The path for the given field path name.
     */
    get(path: NirbyFieldPathSpecial): Path {
        return this.map[path.path](path);
    }
}
