import {Component, EventEmitter, Input, Output} from '@angular/core';
import {VideoRecord, VideoSource} from '@nirby/media-models';

@Component({
    selector: 'nirby-video-select-content',
    templateUrl: './video-select-content.component.html',
    styleUrls: ['./video-select-content.component.scss']
})
export class VideoSelectContentComponent {
    @Input() allowedSource: VideoSource[] = ['cloudflare-stream', 'youtube', 'vimeo'];
    @Output() videoSelect = new EventEmitter<VideoRecord | null>();


    /**
     * Emits the selected video.
     * @param videoRecord The video record.
     */
    public onVideoSelect(videoRecord: VideoRecord | null): void {
        this.videoSelect.emit(videoRecord);
    }
}
