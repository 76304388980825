import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export type MergeFn<TK, TV> = (oldValue: TV, newValue: TV, key: TK) => TV;
export type MergeFnAsync<TK, TV> = (
    oldValue: TV,
    newValue: TV,
    key: TK
) => Promise<TV>;
export type EntryArray<TK, TV> = [TK, TV][];

export class MapUtils {
    /**
     * Iterates for each map entry:
     *
     * If entry is not on the current map:
     *      Adds new entry
     *
     * If entry is already on the current map:
     *      Merge the values using the updateFn function
     *
     * @param source update from this map
     * @param from Map from which entries will be taken
     * @param mergeFn Function which will merge entries that are already on the map
     *
     * @returns - Map with updated entries
     */
    static update<TK, TV>(
        source: Map<TK, TV>,
        from: Map<TK, TV>,
        mergeFn: MergeFn<TK, TV>
    ): {
        added: EntryArray<TK, TV>;
        updated: [TK, TV, [TV, TV]][];
        result: Map<TK, TV>;
    } {
        const added: EntryArray<TK, TV> = [];
        const updated: [TK, TV, [TV, TV]][] = [];

        const result = new Map<TK, TV>(source.entries());

        let entry: [TK, TV];
        for (entry of from.entries()) {
            const key = entry[0];
            const oldValue = source.get(key);

            let newValue = entry[1];
            if (oldValue === undefined) {
                added.push([key, newValue]);
            } else {
                const newest = newValue;
                newValue = mergeFn(oldValue, newValue, key);
                updated.push([key, newValue, [oldValue, newest]]);
            }
            result.set(key, newValue);
        }
        return { added, updated, result };
    }

    /**
     * Same update but async 🧨
     *
     * @param source Map from which entries will be taken
     * @param from Map from which entries will be taken
     * @param mergeFn Function which will merge entries that are already on the map
     */
    static async updateAsync<TK, TV>(
        source: Map<TK, TV>,
        from: Map<TK, TV>,
        mergeFn: MergeFnAsync<TK, TV>
    ): Promise<void> {
        let entry: [TK, TV];
        for (entry of source.entries()) {
            const key = entry[0];
            const oldValue = source.get(key);

            let newValue = entry[1];
            newValue =
                oldValue === undefined
                    ? newValue
                    : await mergeFn(oldValue, newValue, key);
            source.set(key, newValue);
        }
    }

    static deleteInstructions<TK, TV>(
        source: Map<TK, TV>,
        keepKeys: TK[]
    ): [TK, TV][] {
        const deleted: [TK, TV][] = [];

        let key: TK;
        for (key of source.keys()) {
            if (keepKeys.find((k) => k === key)) {
                continue;
            }
            const toDelete = source.get(key);
            if (toDelete !== undefined) {
                deleted.push([key, toDelete]);
                source.delete(key);
            }
        }
        return deleted;
    }
}

export class MapSubject<TK, TV> {
    private subject: BehaviorSubject<Map<TK, TV>>;

    public readonly map$: Observable<Map<TK, TV>>;
    public readonly values$: Observable<TV[]>;
    public readonly keys$: Observable<TK[]>;

    constructor(entries?: [TK, TV][]) {
        this.subject = new BehaviorSubject<Map<TK, TV>>(
            new Map<TK, TV>(entries)
        );
        this.map$ = this.subject.asObservable();
        this.values$ = this.map$.pipe(map((m) => Array.from(m.values())));
        this.keys$ = this.map$.pipe(map((m) => Array.from(m.keys())));
    }

    asObservable(): Observable<Map<TK, TV>> {
        return this.map$;
    }

    get value(): Map<TK, TV> {
        return new Map<TK, TV>(this.subject.value.entries());
    }

    next(value: Map<TK, TV>): void {
        return this.subject.next(value);
    }

    has(key: TK): boolean {
        return this.subject.value.has(key);
    }

    get(key: TK): TV | null {
        return this.subject.value.get(key) ?? null;
    }

    set(key: TK, value: TV): void {
        const m = this.subject.value;
        m.set(key, value);
        this.next(m);
    }

    remove(key: TK): void {
        const m = this.subject.value;
        m.delete(key);
        this.next(m);
    }

    clear(): void {
        this.next(new Map<TK, TV>());
    }

    emit(): void {
        this.next(this.subject.value);
    }

    /**
     * Watches a single entry
     * @param id ID of the entry
     *
     * @returns Observable that emits the value of the entry
     */
    watch(id: TK): Observable<TV | null> {
        return this.subject.pipe(map((m) => m.get(id) ?? null));
    }
}
