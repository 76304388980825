import { NirbyMemoryMask, WeakTypedState } from '@nirby/runtimes/context';
import { Observable } from 'rxjs';
import { NirbyVariable } from '@nirby/runtimes/state';

/**
 * A shape unit memory interface for easy handling
 */
export class UnitMemoryInterface {
    /**
     * @param memory The shape unit memory
     */
    constructor(public readonly memory: NirbyMemoryMask) {}

    public state$: Observable<WeakTypedState> =
        this.memory.stateOfLevel$;
    public blockState$: Observable<WeakTypedState> =
        this.memory.parent.stateOfLevel$;
    public disabled$: Observable<boolean> = this.memory.watchBool('disabled');

    /**
     * Gets the current state of the shape unit
     */
    public get state(): WeakTypedState {
        return this.memory.state;
    }

    /**
     * Gets the current state of the block
     */
    public get blockState(): WeakTypedState {
        return this.memory.parent.state;
    }

    public get disabled(): boolean {
        return this.memory.getBool('disabled');
    }

    public set disabled(value: boolean) {
        this.memory.set('disabled', value);
    }

    set<T extends NirbyVariable>(key: string, value: T) {
        this.memory.set(key, value);
    }

    setBlock<T extends NirbyVariable>(key: string, value: T) {
        this.memory.parent.set(key, value);
    }
}
