import {Injectable} from '@angular/core';
import {RouteParametersService} from '../route-parameters.service';
import {filter, map} from 'rxjs/operators';
import {combineLatest, lastValueFrom, NEVER, Observable, of, switchMap} from 'rxjs';
import {Database} from '@nirby/ngutils';
import {NirbyDocument, NirbyDocumentReference} from '@nirby/store/base';
import {AnyPrimeSource, Prime} from '@nirby/models/editor';
import {ActivatedRoute, Router} from '@angular/router';
import {WorkspaceService} from './workspace.service';
import {COLLECTION_KEYS} from '@nirby/store/collections';

@Injectable({
    providedIn: 'root',
})
/**
 * Service for retrieving prime sources from the database.
 */
export class PrimeSourcesService {
    /**
     * Gets the prime sources collection for a workspace.
     * @param workspaceId The workspace ID.
     *
     * @returns - The prime sources collection.
     */
    public collection(workspaceId: string) {
        return this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(workspaceId),
            COLLECTION_KEYS.PRIME_SOURCES,
        );
    }

    /**
     * Gets the Prime collection for a workspace.
     * @param workspaceId The workspace ID.
     *
     * @returns - The Prime collection.
     */
    public primes(workspaceId: string) {
        return this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(workspaceId),
            COLLECTION_KEYS.PRIMES,
        );
    }

    /**
     * Gets the cards collection for a workspace.
     * @param workspaceId The workspace ID.
     * @private
     *
     * @returns - The cards collection.
     */
    private cards(workspaceId: string) {
        return this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(workspaceId),
            COLLECTION_KEYS.CARDS,
        );
    }

    /**
     * Get all available sources for the current Prime
     */
    public get availableVideos$(): Observable<NirbyDocument<AnyPrimeSource>[]> {
        return combineLatest([
            this.params.widgetId$,
            this.params.watchParam<string>('primeId'),
            this.params.watchParam<string>('cardId'),
        ]).pipe(
            switchMap(([workspaceId, primeId, cardId]) => {
                if (primeId) {
                    return of<[string, string]>([workspaceId, primeId]);
                }
                if (cardId) {
                    return this.cards(workspaceId)
                        .watchById(cardId)
                        .pipe(
                            map((card) => card?.data?.usedBy),
                            filter(
                                (
                                    usedBy,
                                ): usedBy is NirbyDocumentReference<Prime> =>
                                    !!usedBy,
                            ),
                            map((usedBy) => [workspaceId, usedBy.id]),
                        );
                }
                return NEVER;
            }),
            switchMap((ref) => this.listAvailableVideos(ref[0], ref[1])),
        );
    }

    /**
     * Lists available Prime sources where blocks here can go to
     * @param workspaceId The workspace ID
     * @param primeId The Prime ID
     *
     * @returns - An observable of the cards
     */
    listAvailableVideos(
        workspaceId: string,
        primeId: string,
    ): Observable<NirbyDocument<AnyPrimeSource>[]> {
        return this.collection(workspaceId)
            .query()
            .where('usedBy', '==', this.primes(workspaceId).ref(primeId))
            .watch();
    }

    /**
     * Gets the video ID from the route
     */
    get videoId(): string {
        return this.params.getParamSafe('videoId');
    }

    /**
     * Constructor.
     * @param router The router
     * @param route Activated route
     * @param params Route parameters service
     * @param workspaces Workspace service
     * @param db Firestore service
     */
    constructor(
        private router: Router,
        private readonly route: ActivatedRoute,
        private params: RouteParametersService,
        private workspaces: WorkspaceService,
        private readonly db: Database,
    ) {
    }

    /**
     * Gets the sources at a given prime
     * @param workspaceId Workspace ID
     * @param primeId Prime ID
     *
     * @returns - Prime sources
     */
    async getSourcesForPrime(
        workspaceId: string,
        primeId: string,
    ): Promise<NirbyDocument<AnyPrimeSource>[]> {
        return lastValueFrom(
            this.collection(workspaceId)
                .query()
                .orderBy('name')
                .where('usedBy', '==', this.primes(workspaceId).ref(primeId))
                .get(),
        );
    }
}
