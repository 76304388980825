import {Injectable} from '@angular/core';
import {defer, from, lastValueFrom, Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';

export interface EmojiData {
    emoji: string;
    name: string;
    group: string;
    sub_group: string;
    codepoints: string;
}

export interface EmojiGroup {
    name: string;
    emojis: EmojiData[];
}

@Injectable({
    providedIn: 'root',
})
export class EmojiService {
    searchEmojis(query: string, limit = 100, emojis: EmojiData[]): EmojiData[] {
        const re = new RegExp(query);
        let count = 0;
        return emojis
            .filter((emoji) => {
                if (count > limit) {
                    return false;
                }
                const found = emoji.name.search(re) > -1;
                if (found) {
                    count++;
                }
                return found;
            });
    }
    searchGroupedEmojis(query: string): Observable<EmojiGroup[]> {
        return query.length === 0
            ? this.getAllGrouped()
            : this.getAll().pipe(
                map((emojis) => this.groupEmojis(this.searchEmojis(query, 10, emojis))
            ));
    }

    getAll(): Observable<EmojiData[]> {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return from(import('../forms/assets/emojis.json')).pipe(
            map((dataset) => Object.values(dataset) as EmojiData[])
        );
    }

    getAllGrouped(): Observable<EmojiGroup[]> {
        return this.getAll().pipe(map((es) => this.groupEmojis(es)));
    }

    groupEmojis(allEmojis: EmojiData[]): EmojiGroup[] {
        const groups: Record<string, EmojiData[]> = {};
        allEmojis.forEach(
            (e) => (groups[e.group] = [e, ...(groups[e.group] ?? [])])
        );
        return Object.entries(groups).map(([name, emojis]) => ({
            name,
            emojis,
        }));
    }

    async getRandom(): Promise<EmojiData> {
        const allEmojis = await lastValueFrom(this.getAll())
        const randomIndex = Math.floor(Math.random() * allEmojis.length);
        return allEmojis[randomIndex];
    }
}
