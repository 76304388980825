/* eslint-disable @typescript-eslint/no-explicit-any */
import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges,} from '@angular/core';
import {ButtonDisplay} from '../button-select';
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, UntypedFormControl,} from '@angular/forms';
import {AppError} from '@nirby/js-utils/errors';

@Component({
    selector: 'nirby-button-checkboxes',
    templateUrl: './button-checkboxes.component.html',
    styleUrls: ['./button-checkboxes.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ButtonCheckboxesComponent),
            multi: true,
        },
    ],
})
export class ButtonCheckboxesComponent
    implements ControlValueAccessor, OnInit, OnChanges
{
    disabled = false;
    @Input() options: ButtonDisplay<any>[] = [];
    groupForm?: FormGroup;

    onChange: (value: any) => void = () => {
        return;
    };
    onTouched: () => void = () => {
        return;
    };

    registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(newValue?: any[]): void {
        if (newValue) {
            this.value = newValue;
        }
    }

    set value(newValue: any[]) {
        this.options.forEach((option) => {
            const control = this.groupForm?.get(option.value);
            if (!control) {
                throw new AppError(`No control for option: ${option.value}`);
            }
            control.setValue(option.value in newValue);
        });
    }

    get value(): any[] {
        const value: any[] = [];
        this.options.forEach((option) => {
            const control = this.getControl(option.value);
            if (!control) {
                return;
            }
            if (control.value) {
                value.push(option.value);
            }
        });
        return value;
    }

    constructor(private formBuilder: FormBuilder) {}

    ngOnInit(): void {
        this.buildForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['options']) {
            this.buildForm();
        }
    }

    getControl(val: any): UntypedFormControl {
        return this.groupForm?.get(val) as UntypedFormControl;
    }

    buildForm(): void {
        const controls: { [key: string]: boolean } = {};
        this.options.forEach(
            (option) => (controls[option.value] = option.value in this.value)
        );
        this.groupForm = this.formBuilder.group(controls);
    }

    update(): void {
        this.onChange(this.value);
    }
}
