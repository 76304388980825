import {Injectable} from '@angular/core';
import {Directory, Prime} from '@nirby/models/editor';
import {COLLECTION_KEYS} from '@nirby/store/collections';

import {Observable} from 'rxjs';
import {Database} from '@nirby/ngutils';
import {NirbyCollection, NirbyDocument, NirbyDocumentReference, NirbyTransaction} from '@nirby/store/base';

@Injectable({
    providedIn: 'root',
})
export class DirectoriesService {
    collection(workspaceId: string): NirbyCollection<Directory> {
        return this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(workspaceId),
            COLLECTION_KEYS.DIRECTORIES,
        );
    }

    public pops(workspaceId: string): NirbyCollection<Directory> {
        return this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(workspaceId),
            COLLECTION_KEYS.DIRECTORIES,
        );
    }

    public primes(workspaceId: string): NirbyCollection<Prime> {
        return this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(workspaceId),
            COLLECTION_KEYS.PRIMES,
        );
    }

    constructor(private readonly db: Database) {
    }

    ref(
        workspaceId: string,
        directoryId: string,
    ): NirbyDocumentReference<Directory> {
        return this.collection(workspaceId).ref(directoryId);
    }

    getTransaction(
        transaction: NirbyTransaction,
        workspaceId: string,
        directoryId: string,
    ): Promise<NirbyDocument<Directory> | null> {
        return this.collection(workspaceId).getTransaction(
            transaction,
            directoryId,
        );
    }

    queryAll(workspaceId: string): Observable<NirbyDocument<Directory>[]> {
        return this.collection(workspaceId)
            .query()
            .orderBy('name', 'asc')
            .watch();
    }
}
