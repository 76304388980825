<div class="form-group mb-0">
    <div class="form-group">
        <label class="text-dark" for="variable">Variable to update</label>
        <select
            id="variable"
            class="form-control form-control-sm"
            [formControl]="variableControl"
            *ngIf="variables$ | async as variables"
        >
            <option [ngValue]="null" disabled>
                No variable selected
            </option>
            <option *ngFor="let variable of variables" [ngValue]="'global.' + variable.data.name">
                {{ variable.data.displayName }}
            </option>
        </select>
    </div>
    <div class="form-group">
        <label class="text-dark" for="operation-type">Operation</label>
        <select
            id="operation-type"
            class="form-control form-control-sm"
            [formControl]="operationControl"
        >
            <option *ngFor="let op of operations" [ngValue]="op.value">
                {{ op.label }}
            </option>
        </select>
    </div>
    <div class="form-group" *ngIf="{selected: selectedVariable$ | async, operation: selectedOperation$ | async} as data">
        <ng-container [ngSwitch]="data.operation?.value" *ngIf="data.selected">
            <ng-container *ngSwitchCase="'set'">
                <label class="text-dark" for="amount-set">Value</label>
                <input
                    id="amount-set"
                    [type]="data.selected.data.type === 'number' ? 'number' : 'text'"
                    class="form-control"
                    [formControl]="amountControl"
                />
            </ng-container>
            <ng-container *ngSwitchCase="'add'">
                <label class="text-dark" for="amount-add">Amount</label>
                <input
                    id="amount-add"
                    type="number"
                    class="form-control"
                    [formControl]="amountControl"
                />
            </ng-container>
            <ng-container *ngSwitchCase="'subtract'">
                <label class="text-dark" for="amount-subtract">Amount</label>
                <input
                    id="amount-subtract"
                    type="number"
                    class="form-control"
                    [formControl]="amountControl"
                />
            </ng-container>
        </ng-container>
    </div>
</div>
