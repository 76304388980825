<div class="d-flex flex-column p-3">
    <h2>Billing</h2>
    <ng-container *ngIf="refreshed">
        <div class="billing-usage d-flex flex-column p-4" *ngIf="billingUsage$ | async as usage">
            <div class="billing-usage-item">
                <p class="billing-usage-item-title mb-2">
                    Video Delivery
                </p>
                <p class="billing-usage-item-value">
                    {{ usage.videoDeliveryMinutesUsage | number: '1.2' }} minutes
                    / {{ usage.videoDeliveryMinutesLimit | number: '1.2' }} minutes
                </p>
                <div class="billing-usage-item-slider">
                    <div class="billing-usage-item-slider-fill"
                         [ngStyle]="{width: (100 * usage.videoDeliveryMinutesUsage / usage.videoDeliveryMinutesLimit) + '%'}">
                    </div>
                </div>
            </div>
            <div class="billing-usage-item">
                <p class="billing-usage-item-title mb-2">
                    Video Storage
                </p>
                <p class="billing-usage-item-value">
                    {{ usage.videoStorageMinutesUsage | number: '1.2' }} minutes
                    / {{ usage.videoStorageMinutesLimit | number: '1.2' }} minutes
                </p>
                <div class="billing-usage-item-slider">
                    <div class="billing-usage-item-slider-fill"
                         [ngStyle]="{width: (100 * usage.videoStorageMinutesUsage / usage.videoStorageMinutesLimit) + '%'}">
                    </div>
                </div>
            </div>
            <div class="billing-usage-item">
                <p class="billing-usage-item-title mb-2">
                    Video Upload
                </p>
                <p class="billing-usage-item-value">
                    {{ usage.videoUploadMinutesUsage | number: '1.2' }} minutes
                    / {{ usage.videoUploadMinutesLimit | number: '1.2' }} minutes
                </p>
                <div class="billing-usage-item-slider">
                    <div class="billing-usage-item-slider-fill"
                         [ngStyle]="{width: (100 * usage.videoUploadMinutesUsage / usage.videoUploadMinutesLimit) + '%'}">
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row justify-content-end mt-2">
                <small class="text-muted">
                    From <b>{{ usage.period.from | timestamp: 'dd-MM-yyyy H:mm' }}hrs</b>
                    to <b>{{ usage.period.to | timestamp: 'dd-MM-yyyy H:mm' }}hrs</b>
                </small>
            </div>
        </div>
        <div class="my-2">
            <a
                class="btn btn-brand-primary"
                [routerLink]="['/workspaces', workspaceId, 'plans']"
            >
                Change Plan
            </a>
        </div>
    </ng-container>
    <ng-container *ngIf="refreshed === false">
        <p>
            This workspace does not have an associated plan.
        </p>
        <p>
            If you think this is an error, please
            <a href="https://forms.gle/gVata2muWrxVyMhj7" class="font-weight-bold" target="_blank">contact us</a>
        </p>
    </ng-container>
    <ng-container *ngIf="refreshed === null">
        <p>
            Loading...
        </p>
    </ng-container>
</div>
