import {inject, InjectionToken} from '@angular/core';
import {NirbyPlayerConfig} from '@nirby/models/nirby-player';
import {NirbyApi} from '@nirby/runtimes/analytics';

export const NIRBY_PLAYER_CONFIG = new InjectionToken<NirbyPlayerConfig>('NIRBY_PLAYER_CONFIG');
export const NIRBY_API = new InjectionToken<NirbyApi>('NIRBY_API', {
    providedIn: 'root',
    factory: () => {
        const config = inject(NIRBY_PLAYER_CONFIG);
        return new NirbyApi(config.api.host);
    },
});
