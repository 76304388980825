import {PrimeSource} from './video.model';
import {ImageRecord, VideoRecord} from '@nirby/media-models';
import {NirbyVariableDeclarationSet} from '@nirby/runtimes/state';
import {z, ZodSchema} from 'zod';
import {ZodSchemaBuild} from '@nirby/store/models';

interface PrimeExperienceSettings {
    firstVideoKey: string;
    videos: {
        [key: string]: PrimeSource;
    };
    defaultLanguage: string | null;
    thumbnailUrl: ImageRecord | null;
}

export interface PrimeExperienceLegacy extends PrimeExperienceSettings {
    design: undefined;
    id: string;
    title: string;
    excerpt: string;
    estimatedLength: string;
}

export type PrimeExperienceControlStyleType = 'none' | 'classic' | 'floating';
export type PrimeAutoPlayMode = 'muted' | 'sound' | 'no';

export const PRIME_AUTOPLAY_MODE_SCHEMA: ZodSchema<PrimeAutoPlayMode, z.ZodTypeDef, unknown> = z.enum(['muted', 'sound', 'no'])
    .default('no')
    .catch(() => 'no' as const);

export type PrimeControlsFloatingAnchor =
    'left-top'
    | 'center-top'
    | 'right-top'
    | 'left-middle'
    | 'right-middle'
    | 'left-bottom'
    | 'center-bottom'
    | 'right-bottom';

export interface PrimeControlsFloatingOptions {
    /**
     * Whether to show the controls horizontally or vertically.
     */
    orientation: 'horizontal' | 'vertical';
    /**
     * Which side of the video to anchor the video controls to.
     */
    anchor: PrimeControlsFloatingAnchor;
    /**
     * Margin from the edge of the video in pixels.
     */
    marginPx: number;
    /**
     * How much to translate the control in the horizontal axis in pixels.
     */
    xTranslatePx: number;
    /**
     * How much to translate the control in the vertical axis in pixels.
     */
    yTranslatePx: number;
}

export interface PrimeControlsClassicOptions {
    /**
     * Show controls to navigate between videos.
     */
    showNavigationControls: boolean;
}

export interface PrimeExperienceControlStyle {
    type: PrimeExperienceControlStyleType;
    classic: PrimeControlsClassicOptions;
    floating: PrimeControlsFloatingOptions;
}

export const PRIME_CONTROLS_CLASSIC_OPTIONS_SCHEMA: ZodSchemaBuild<PrimeControlsClassicOptions> =
    z.object({
        showNavigationControls: z.boolean().default(true),
    });

export const PRIME_CONTROLS_FLOATING_ANCHORS_SCHEMA: ZodSchemaBuild<PrimeControlsFloatingOptions['anchor']> = z.enum([
    'left-top',
    'center-top',
    'right-top',
    'left-middle',
    'right-middle',
    'left-bottom',
    'center-bottom',
    'right-bottom',
]).default('left-top').catch((): PrimeControlsFloatingOptions['anchor'] => 'left-top');

export const PRIME_CONTROLS_FLOATING_OPTIONS_SCHEMA: ZodSchemaBuild<PrimeControlsFloatingOptions> =
    z.object({
        orientation: z.enum(['horizontal', 'vertical']).default('horizontal'),
        anchor: PRIME_CONTROLS_FLOATING_ANCHORS_SCHEMA,
        marginPx: z.number().default(10),
        xTranslatePx: z.number().default(0),
        yTranslatePx: z.number().default(0),
    });

export const PRIME_EXPERIENCE_CONTROL_STYLE_SCHEMA: ZodSchemaBuild<PrimeExperienceControlStyle> =
    z.union([
        z.object({
            type: z.enum([
                'none',
                'classic',
                'floating',
            ]),
            classic: PRIME_CONTROLS_CLASSIC_OPTIONS_SCHEMA,
            floating: PRIME_CONTROLS_FLOATING_OPTIONS_SCHEMA,
        }),
        z.boolean().transform<PrimeExperienceControlStyle>(
            (value) => {
                if (value) {
                    return {
                        type: 'classic',
                        classic: {
                            showNavigationControls: true,
                        },
                        floating: getDefaultFloatingControlsOptions(),
                    };
                } else {
                    return {
                        type: 'none',
                        classic: {
                            showNavigationControls: false,
                        },
                        floating: getDefaultFloatingControlsOptions(),
                    };
                }
            },
        ),
    ]).catch((): PrimeExperienceControlStyle => ({
        type: 'classic',
        classic: getDefaultClassicControlsOptions(),
        floating: getDefaultFloatingControlsOptions(),
    }));

export interface PrimeExperienceClean extends PrimeExperienceSettings {
    logoHeight?: number;
    backgroundPosition?: string;
    videoSourceType?: VideoRecord['type'];
    design: 'clean';
    id: string;
    title: string;
    background: ImageRecord | null;
    logo: ImageRecord | null;
    variables: NirbyVariableDeclarationSet;
    allowedParentOrigins: string[];
    showNavigationControls: boolean | PrimeExperienceControlStyle;
    startAutoplay?: boolean;
    startMuted?: boolean;
    relatedExperiencesIds?: string[];
}

export type PrimeExperience = PrimeExperienceLegacy | PrimeExperienceClean;


export function getDefaultFloatingControlsOptions(): PrimeControlsFloatingOptions {
    return {
        orientation: 'horizontal',
        anchor: 'center-bottom',
        marginPx: 12,
        xTranslatePx: 0,
        yTranslatePx: 0,
    };
}

export function getDefaultClassicControlsOptions(): PrimeControlsClassicOptions {
    return {
        showNavigationControls: true,
    };
}

/**
 * Upgrades an old {@link PrimeExperienceLegacy} to a new {@link PrimeExperienceClean}.
 * @param experience The legacy experience to upgrade.
 *
 * @returns - The upgraded experience.
 */
export function upgradePrimeExperience(
    experience: PrimeExperience,
): PrimeExperienceClean {
    switch (experience.design) {
        case 'clean':
            return experience;
        default:
            return {
                design: 'clean',
                id: experience.id,
                title: experience.title,
                background: null,
                backgroundPosition: 'center',
                logo: null,
                variables: {},
                firstVideoKey: experience.firstVideoKey,
                videos: experience.videos,
                defaultLanguage: experience.defaultLanguage,
                thumbnailUrl: experience.thumbnailUrl,
                allowedParentOrigins: [],
                showNavigationControls: {
                    type: 'classic',
                    classic: {
                        showNavigationControls: true,
                    },
                    floating: getDefaultFloatingControlsOptions(),
                },
                relatedExperiencesIds: [],
                videoSourceType: 'vimeo',
                startAutoplay: false,
                startMuted: false,
            };
    }
}
