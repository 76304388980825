<header class="component-content-header p-3">
    <h1 class="main-title mb-0">General Settings</h1>
</header>
<div class="component-content-inner p-3">
    <form
        [formGroup]="form"
        (ngSubmit)="save()"
        *ngIf="widget$ | async; else loadingContainer"
    >
        <div class="form-group">
            <label for="title">Name</label>
            <input
                autocomplete="off"
                [formControl]="name"
                type="text"
                placeholder="My cool workspace"
                id="title"
                class="form-control"
            />
            <div
                *ngIf="name.touched && name.invalid"
                class="alert alert-danger"
            >
                A name for this widget is required
            </div>
        </div>
        <div class="form-group">
            <label for="domain-name">Domain name</label>
            <input
                autocomplete="off"
                [formControl]="domainName"
                type="text"
                placeholder="fixe.cl"
                id="domain-name"
                class="form-control"
            />
            <div
                *ngIf="domainName.touched && domainName.invalid"
                class="alert alert-danger"
            >
                A domain name is required
            </div>
        </div>
        <div class="actions mt-4">
            <button
                type="submit"
                class="btn btn-primary"
                [disabled]="!form.valid || loading"
            >
                Save changes
            </button>
        </div>
    </form>
    <ng-template #loadingContainer>
        <div class="spinner-border text-primary"></div>
    </ng-template>
</div>
