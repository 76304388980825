import {Component} from '@angular/core';
import {AbstractControl, FormGroup, UntypedFormControl, ValidatorFn, Validators} from '@angular/forms';
import {AuthenticationService, WorkspaceService} from '@nirby/shared/database';

@Component({
    selector: 'nirby-user-registration',
    templateUrl: './user-registration.component.html',
    styleUrls: ['./user-registration.component.scss'],
})
/**
 * A component for registering a new user.
 */
export class UserRegistrationComponent {
    emailControl = new UntypedFormControl('', [
        Validators.required,
    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
]);
    passwordControl = new UntypedFormControl('', Validators.required)
    passwordConfirmControl = new UntypedFormControl('', Validators.required);

    newUserForm = new FormGroup({
        email: this.emailControl,
        password: this.passwordControl,
        confirmPassword: this.passwordConfirmControl,
    }, UserRegistrationComponent.passwordMatchValidator);

    loading = false;

    /**
     * Checks if the password and confirm password fields match
     * @param form The form to check
     *
     * @returns null if the passwords match, otherwise an object with the error
     */
    private static passwordMatchValidator: ValidatorFn = (form: AbstractControl): { [key: string]: boolean } | null => {
        const password = form.get('password');
        const confirmPassword = form.get('confirmPassword');
        if (password?.value !== confirmPassword?.value) {
            return { passwordMismatch: true };
        }
        return null;
    }

    /**
     * Constructor.
     * @param auth The authentication service
     * @param workspaces The workspace service
     */
    constructor(
        private auth: AuthenticationService,
        private workspaces: WorkspaceService,
    ) {}

    /**
     * Signs up a new user with email and password
     */
    async signUp(): Promise<void> {
        this.loading = true;
        try {
            await this.auth.signUpEmail(this.emailControl.value, this.passwordControl.value);
        } finally {
            this.loading = false;
        }
    }
}
