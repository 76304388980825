import {Inject, Injectable, InjectionToken, Provider} from '@angular/core';
import 'firebase/firestore';
import {collection, doc, Firestore, QueryConstraint, runTransaction} from '@angular/fire/firestore';
import {
    CollectionKey,
    DatabaseService,
    DocumentKey,
    NirbyDocumentReference,
    NirbyTransaction,
    QueryBuilder,
} from '@nirby/store/base';
import {
    FirestoreCollection,
    FirestoreCollectionGroupQueryBuilder,
    FirestoreDocumentReference,
    FirestoreNirbyDocument,
    FirestoreTransaction,
} from '@nirby/store/firebase-web';

export interface DatabaseConfig {
    production: boolean;
}

export const DB_CONFIG_TOKEN = new InjectionToken<DatabaseConfig>('DB_CONFIG');

export const DB_CONFIG_PROVIDERS: Record<'dev' | 'prod' | 'tests', Provider> = {
    dev: {
        provide: DB_CONFIG_TOKEN,
        useValue: {
            production: false,
        },
    },
    prod: {
        provide: DB_CONFIG_TOKEN,
        useValue: {
            production: true,
        },
    },
    tests: {
        provide: DB_CONFIG_TOKEN,
        useValue: {
            production: true,
        },
    },
};

@Injectable({
    providedIn: 'root',
})
/**
 * Wrapper for Firestore
 */
export class FirestoreService implements DatabaseService<QueryConstraint> {
    /**
     * Constructor.
     * @param firestore The Firestore instance.
     * @param config The database configuration.
     * @protected
     */
    protected constructor(
        protected firestore: Firestore,
        @Inject(DB_CONFIG_TOKEN) protected readonly config: DatabaseConfig,
    ) {
    }

    /**
     * Gets a subcollection.
     * @param keys The keys for the subcollection.
     *
     * @returns The subcollection.
     */
    public collection<T extends object, SyncContext extends object = object>(
        ...keys: [...DocumentKey<object>[], CollectionKey<T, SyncContext>]
    ): FirestoreCollection<T> {
        const key = CollectionKey.subcollection(...keys);
        return FirestoreCollection.fromKey(this.firestore, key);
    }

    public docFromPath<T extends object>(
        path: string,
        key: CollectionKey<T>,
    ): FirestoreDocumentReference<T> {
        const documentKey = DocumentKey.fromPath(path, key);
        return FirestoreCollection.fromKey(
            this.firestore,
            documentKey.collectionKey,
        ).ref(documentKey.documentId);
    }

    /**
     * Generates an ID.
     *
     * @returns The ID.
     */
    generateId(): string {
        return doc(collection(this.firestore, 'temp')).id;
    }

    /**
     * Runs a transaction.
     * @param fn The function to run.
     *
     * @returns The result of the function.
     */
    runTransaction<T = void>(
        fn: (transaction: FirestoreTransaction) => Promise<T>,
    ): Promise<T> {
        return runTransaction(this.firestore, (transaction) => {
            return fn(new FirestoreTransaction(transaction));
        });
    }

    /**
     * Queries a collection group.
     * @param key The key for the collection group.
     *
     * @returns The query.
     */
    queryGroup<T extends object>(
        key: CollectionKey<T>,
    ): QueryBuilder<T, QueryConstraint> {
        return new FirestoreCollectionGroupQueryBuilder(this.firestore, key);
    }

    async getManyTransaction<T extends object>(
        transaction: NirbyTransaction,
        references: NirbyDocumentReference<T>[],
    ): Promise<FirestoreNirbyDocument<T>[]> {
        const routes = await Promise.all(
            references.map((ref) => transaction.get(ref)),
        );
        return routes.filter(
            (route): route is FirestoreNirbyDocument<T> => !!route,
        );
    }
}
