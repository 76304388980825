import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownFilterComponent } from './dropdown-filter/dropdown-filter.component';
import {DropdownModule} from 'primeng/dropdown';
import {FormsModule} from '@angular/forms';
import {HintModule} from '../hint/hint.module';



@NgModule({
    declarations: [
        DropdownFilterComponent
    ],
    exports: [
        DropdownFilterComponent
    ],
    imports: [
        CommonModule,
        DropdownModule,
        FormsModule,
        HintModule
    ]
})
/**
 * Dropdown filter module
 */
export class DropdownFilterModule { }

export {DropdownItem} from './dropdown-filter/dropdown-filter.component';
