import {AbstractControl, UntypedFormControl, FormGroup} from '@angular/forms';
import {defer, Observable} from 'rxjs';
import {startWith} from 'rxjs/operators';

/**
 * @deprecated Prefer storing the form control in the component's state.
 * Gets a form control from a form group by name. If the form control does not exist, throws an error.
 * @param name The name of the form control.
 * @param form The form group.
 *
 * @returns The form control.
 */
export function getFormControlOrThrow(
    name: string,
    form: FormGroup
): UntypedFormControl {
    const control = form.get(name);
    if (!control) {
        throw new Error('Trying to access view on uninitialized view');
    }
    if (control instanceof UntypedFormControl) {
        return control;
    } else {
        throw new Error('Control is not UntypedFormControl');
    }
}

type FormValueControls<T extends object> = {
    [P in keyof T]: AbstractControl;
}

/**
 * Class to watch form value changes.
 */
export class Form<T extends object> {
    /**
     * Constructor.
     * @param form The form data.
     */
    constructor(
        public readonly form: FormGroup,
    ) {
    }

    /**
     * Object with the group controls.
     */
    get controls(): FormValueControls<T> {
        return this.form.controls as FormValueControls<T>;
    }

    /**
     * Creates a form group.
     * @param initialValue The initial value.
     *
     * @returns The form group.
     */
    static group<T extends object>(initialValue: T): Form<T> {
        const entries = Object.entries(initialValue) as [keyof T, T[keyof T]][];

        const formControls = entries.reduce<Partial<FormValueControls<T>>>((acc, [key, value]) => {
            acc[key] = new UntypedFormControl(value);
            return acc;
        }, {}) as FormValueControls<T>;

        return new Form<T>(new FormGroup(formControls));
    }

    /**
     * Watches an {@link AbstractControl} value.
     * @param control The control to watch.
     * @param skipCurrentValue If true, the current value will be skipped, and only value changes will be emitted.
     *
     * @returns The observable.
     */
    static watch<T = unknown>(control: AbstractControl, skipCurrentValue = false): Observable<T> {
        return defer(
            () => skipCurrentValue ? control.valueChanges : control.valueChanges.pipe(
                startWith(control.value)
            )
        );
    }

    /**
     * Watches the form value.
     *
     * @returns The form value.
     */
    get value$(): Observable<T> {
        return Form.watch(this.form);
    }

    /**
     * Gets the form value.
     */
    get value(): T {
        return this.form.value;
    }

    /**
     * Sets the form value.
     * @param value The value to set.
     */
    set value(value: T) {
        this.setValue(value, true);
    }

    /**
     * Sets the form value.
     * @param value The value to set.
     * @param emitEvent If true, the value change event will be emitted.
     */
    setValue(value: T, emitEvent = true): void {
        this.form.setValue(value, {emitEvent});
    }

    /**
     * Gets a form control.
     * @param key The key.
     *
     * @returns The form control.
     */
    getFormControl(key: keyof T): UntypedFormControl {
        const control = this.controls[key];
        if (control instanceof UntypedFormControl) {
            return control;
        }
        throw new Error('Control is not UntypedFormControl');
    }

    /**
     * Get a form control.
     * @param key The key.
     *
     * @returns The form control.
     */
    getControl<K extends keyof T>(key: K): AbstractControl {
        return this.controls[key];
    }

    /**
     * Watches a value
     * @param key The key.
     *
     * @returns The observable.
     */
    watch<K extends keyof T>(key: K): Observable<T[K]> {
        return Form.watch(this.getControl(key));
    }

    /**
     * Sets a value
     * @param key The key.
     * @param value The value.
     */
    set<K extends keyof T>(key: K, value: T[K]): void {
        this.getControl(key).setValue(value);
    }

    /**
     * Gets a value.
     * @param key The key.
     *
     * @returns The value.
     */
    get<K extends keyof T>(key: K): T[K] {
        return this.getControl(key).value;
    }
}
