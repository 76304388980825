/*
 * Public API Surface of @nirby/player
 */
export * from './lib/pipes';

export * from './lib/services';
export * from './lib/components';
export * from './lib/validators';
export * from './lib/nirby-player.module';


import Konva from 'konva';

Konva.autoDrawEnabled = false;

/**
 * Nirby-specific
 */

export * from './lib/models';
export * from './lib/components';
