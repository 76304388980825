import {Injectable} from '@angular/core';

import {WorkspaceService} from './workspace.service';
import {PopService} from './pop.service';
import {firstValueFrom, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Database} from '@nirby/ngutils';
import {CompiledLandPop, CompiledPop, LandPop, PublishedJson} from '@nirby/models/pop';
import {NirbyCollection, NirbyDocumentReference} from '@nirby/store/base';
import {AppError} from '@nirby/js-utils/errors';
import {LandpopsPublishedService} from './landpops-published.service';
import {AppCard} from '@nirby/models/editor';
import {joinId} from '@nirby/runtimes/canvas';
import {Logger} from '@nirby/logger';
import {COLLECTION_KEYS} from '@nirby/store/collections';
import {CARD_MIGRATOR} from '@nirby/models/nirby-player';
import {LandpopsService} from './landpops.service';

@Injectable({
    providedIn: 'root',
})
export class PublishedPopsService {
    constructor(
        private readonly db: Database,
        private workspaces: WorkspaceService,
        private pops: PopService,
        private landpops: LandpopsService,
        private landpopPublisher: LandpopsPublishedService,
    ) {
    }

    public collection(workspaceId: string): NirbyCollection<PublishedJson> {
        return this.db.collection(
            COLLECTION_KEYS.PUBLISHED.doc(workspaceId),
            COLLECTION_KEYS.PUBLISHED_POPS,
        );
    }

    public cards(workspaceId: string): NirbyCollection<AppCard> {
        return this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(workspaceId),
            COLLECTION_KEYS.CARDS,
        );
    }

    async publish(workspaceId: string, popId: string): Promise<CompiledPop> {
        Logger.logAt('DB', 'publish', [workspaceId, popId]);
        const campaignRef = this.pops.collection(workspaceId).ref(popId);
        const newCampaignRef = this.collection(workspaceId).ref(popId);

        const cards = await firstValueFrom(
            this.cards(workspaceId)
                .query()
                .orderBy('_lastUpdate', 'desc')
                .where('usedBy', '==', campaignRef)
                .get(),
        );
        const cardsReferences: NirbyDocumentReference<AppCard>[] = cards.map(
            (card) => card.ref,
        );

        return await this.db.runTransaction<CompiledPop>(
            async (transaction) => {
                // get widget
                const campaignSnapshot = await transaction.get(campaignRef);
                const campaign = campaignSnapshot?.data;
                if (!campaign) {
                    throw new AppError(
                        `Campaign not found: ${workspaceId}/${popId}`,
                    );
                }
                if (!campaign.initialCard) {
                    throw new AppError('Campaign has no initial card');
                }

                // get landpop
                const landpopDoc =
                    await this.landpops.collection.getTransaction(
                        transaction,
                        joinId(workspaceId, popId),
                    );
                const landpop: LandPop | undefined = landpopDoc?.data;

                const fullCampaign: CompiledPop = {
                    cards: {},
                    firstCardKey: campaign.initialCard.id,
                    title: campaign.title,
                    welcome: {...campaign.welcome},
                    plugins: {},
                };
                let cardRef;
                for (cardRef of cardsReferences) {
                    const cardSnapshot = await transaction.get(cardRef);
                    const card = cardSnapshot?.data;
                    if (!card) {
                        throw new AppError(`Card ${cardRef.id} not found`);
                    }
                    fullCampaign.cards[cardRef.id] = CARD_MIGRATOR.migrate({
                        ...card.card,
                        _docId: cardRef.id,
                    }).migrated;
                }

                let finalLandpop: LandPop;
                if (!landpop) {
                    finalLandpop =
                        await this.landpopPublisher.createDefaultTransaction(
                            transaction,
                            workspaceId,
                            popId,
                        );
                } else {
                    finalLandpop = {...landpop};
                }

                await transaction.set(newCampaignRef, {
                    json: JSON.stringify(fullCampaign),
                });
                const compiledLandPop: CompiledLandPop = {
                    campaign: fullCampaign,
                    content: finalLandpop.content,
                    popId,
                    workspaceId,
                };
                await this.landpopPublisher.collection.setTransaction(
                    transaction,
                    {
                        json: JSON.stringify(compiledLandPop),
                        ref: this.landpops.getReference(workspaceId, popId),
                        pop: finalLandpop.pop,
                    },
                    finalLandpop.path,
                );
                return fullCampaign;
            },
        );
    }

    async unpublish(widgetId: string, popId: string): Promise<void> {
        await this.collection(widgetId).delete(popId);
        await this.landpopPublisher.unpublish(widgetId, popId);
    }

    isPublished(widgetId: string, popId: string): Observable<boolean> {
        return this.collection(widgetId)
            .watchById(popId)
            .pipe(map((doc) => !!doc));
    }

    async isPublishedOnce(widgetId: string, popId: string): Promise<boolean> {
        const snap = await firstValueFrom(this.collection(widgetId).get(popId));
        return !!snap;
    }
}
