import {NirbyCollectionReference, NirbyDocumentReference, NirbyFieldValue} from '@nirby/store/base';
import {CollectionReference, doc, Firestore} from '@angular/fire/firestore';
import {Migrator, MigratorLike} from '@nirby/store/migrator';
import {StandardConverter} from './converter';
import {FirestoreModelReference} from './document';
import {Uploaded} from '@nirby/store/models';

export class FirestoreDocumentReference<
    T extends object
> extends NirbyDocumentReference<T> {
    public readonly ref: FirestoreModelReference<T>;

    constructor(ref: FirestoreModelReference<T>, migrator: MigratorLike<T>) {
        super(migrator);
        this.ref = ref.withConverter(StandardConverter.for<T>(migrator));
    }

    get id(): string {
        return this.ref.id;
    }

    get path(): string {
        return this.ref.path;
    }

    get parent(): FirestoreCollectionReference<T> {
        return new FirestoreCollectionReference(this.ref.parent, this.migrator);
    }

    get firestore(): Firestore {
        return this.ref.firestore;
    }

    override toFieldValue(): NirbyFieldValue {
        return this.ref;
    }

    static fromPath<T extends object>(
        firestore: Firestore,
        path: string,
        migrator: Migrator<T>,
    ): FirestoreDocumentReference<T> {
        return new FirestoreDocumentReference<T>(
            doc(firestore, path).withConverter(
                StandardConverter.for<T>(migrator),
            ),
            migrator,
        );
    }
}

export class FirestoreCollectionReference<
    T extends object
> extends NirbyCollectionReference<T> {
    constructor(
        public readonly ref: CollectionReference<Uploaded<T>>,
        migrator: MigratorLike<T>,
    ) {
        super(migrator, true);
    }

    doc(id: string | undefined): FirestoreDocumentReference<T> {
        return new FirestoreDocumentReference<T>(
            doc(this.ref, id),
            this.migrator,
        );
    }

    get id(): string {
        return '';
    }

    get path(): string {
        return '';
    }

    get firestore(): Firestore {
        return this.ref.firestore;
    }

    getParent<U extends object>(
        migrator: Migrator<U>,
    ): NirbyDocumentReference<U> | null {
        const parent = this.ref.parent;
        if (!parent) {
            return null;
        }
        return new FirestoreDocumentReference<U>(
            parent.withConverter(StandardConverter.for<U>(migrator)),
            migrator,
        );
    }
}
