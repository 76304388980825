<div class="container-fluid" *ngIf="imageStyle">
    <!--<label for="object-fit">Object fit</label>
  <select id="object-fit" name="object-fit" [value]="imageStyle.fit" class="form-control" (input)="onFitChange($event)">
    <option value="contain">Contain</option>
    <option value="cover">Cover</option>
  </select>-->
    <label for="positioning">Positioning</label>
    <nirby-positioning-grid
        id="positioning"
        [value]="imageStyle.positioning"
        (input)="onPositioningChange($event)"
    ></nirby-positioning-grid>
</div>
