import Color from 'color';

/**
 * Try to parse a color from a string.
 * @param name The color name.
 *
 * @returns The color if successful, otherwise it returns black.
 */
export function tryParseColor(name: string): Color {
    try {
        return new Color(name);
    } catch (e) {
        return new Color('#000');
    }
}
