import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActionFormControlComponent} from './action-form-control/action-form-control.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ActionFormControlArrayComponent} from './action-form-control-array/action-form-control-array.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {EditorModule} from '../../editor/editor.module';


@NgModule({
    declarations: [
        ActionFormControlComponent,
        ActionFormControlArrayComponent
    ],
    exports: [
        ActionFormControlComponent,
        ActionFormControlArrayComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        EditorModule,
        FontAwesomeModule
    ]
})
export class ActionFormControlModule {
}
