import {Injectable} from '@angular/core';
import {firstValueFrom, Observable} from 'rxjs';
import {Database} from '@nirby/ngutils';
import {NirbyDocument, NirbyDocumentReference, NirbyTransaction} from '@nirby/store/base';
import {LandPop, LandPopPublished} from '@nirby/models/pop';
import {AlertsService} from '@nirby/shared/alerts';
import {joinId} from '@nirby/runtimes/canvas';
import {COLLECTION_KEYS} from '@nirby/store/collections';

@Injectable({
    providedIn: 'root',
})
export class LandpopsService {
    constructor(
        private readonly db: Database,
        private alerts: AlertsService,
    ) {
    }

    collection = this.db.collection(COLLECTION_KEYS.LANDPOPS);

    public publishedCollection = this.db.collection(
        COLLECTION_KEYS.PUBLISHED_LANDPOPS,
    );

    cleanPath(path: string): string {
        return path
            .toLowerCase()
            .normalize('NFKD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/( )+/g, '-')
            .replace(/[^A-Za-z0-9-]+/g, '');
    }

    async archive(widgetId: string, popId: string): Promise<void> {
        await this.db.runTransaction(async (transaction) => {
            await this.collection.updateTransaction(
                transaction,
                joinId(popId),
                {status: 'archived'},
            );
            await this.unpublishTransaction(transaction, widgetId, popId);
        });
    }

    private async unpublishTransaction(
        transaction: NirbyTransaction,
        widgetId: string,
        popId: string,
    ): Promise<void> {
        const published = await this.getPublished(widgetId, popId);

        if (published?.id) {
            await this.publishedCollection.updateTransaction(
                transaction,
                published.id,
                {json: null},
            );
        }
    }

    async getPublished(
        widgetId: string,
        popId: string,
    ): Promise<NirbyDocument<LandPopPublished> | null> {
        const landpopRef = this.collection.ref(joinId(widgetId, popId));
        return await firstValueFrom(
            this.publishedCollection
                .query()
                .where('ref', '==', landpopRef)
                .getFirst(),
        );
    }

    watchPublished(
        widgetId: string,
        popId: string,
    ): Observable<NirbyDocument<LandPopPublished> | null> {
        const landpopRef = this.collection.ref(joinId(widgetId, popId));
        return this.publishedCollection
            .query()
            .where('ref', '==', landpopRef)
            .where('json', '!=', null)
            .watchFirst();
    }

    async unpublish(widgetId: string, popId: string): Promise<void> {
        return await this.db.runTransaction(async (transaction) => {
            await this.unpublishTransaction(transaction, widgetId, popId);
        });
    }

    getLandPopId(widgetId: string, popId: string): string {
        return joinId(widgetId, popId);
    }

    async update(id: string, data: Partial<LandPop>): Promise<void> {
        const newData = {...data};
        if (newData.path) {
            const path = newData.path;
            newData.path = this.cleanPath(path);
        }
        if (newData.path?.length === 0) {
            this.alerts.error('LandPop path cannot be empty');
            return;
        }
        return await this.collection.update(id, newData);
    }

    async unarchive(landpopId: string): Promise<void> {
        await this.update(landpopId, {status: 'active'});
    }

    getReference(
        workspaceId: string,
        popId: string,
    ): NirbyDocumentReference<LandPop> {
        return this.collection.ref(joinId(workspaceId, popId));
    }
}
