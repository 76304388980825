import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VideoSelectComponent} from './components/video-select';
import {VideoYoutubeUrlComponent} from './components/video-youtube-url';
import {VideoVimeoUrlComponent} from './components/vimeo-youtube-url';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MediaVideoViewModule} from '@nirby/media/video-view';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgbNavModule} from '@ng-bootstrap/ng-bootstrap';
import {VideoUploadModule} from '@nirby/media-video-upload';
import {MediaVideoRecordModule} from '@nirby/media/video-record';
import { VideoSelectContentComponent } from './components/video-select-content/video-select-content.component';

@NgModule({
    declarations: [
        VideoSelectComponent,
        VideoYoutubeUrlComponent,
        VideoVimeoUrlComponent,
        VideoSelectContentComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MediaVideoViewModule,
        FontAwesomeModule,
        NgbNavModule,
        VideoUploadModule,
        MediaVideoRecordModule,
    ],
    exports: [
        VideoSelectComponent,
        VideoYoutubeUrlComponent,
        VideoVimeoUrlComponent,
    ]
})
export class MediaVideoSelectModule {}
