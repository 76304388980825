import {Logger} from '@nirby/logger';

/**
 * An error generated by the API
 */
export class ApiError<T> extends Error {
    // noinspection JSUnusedLocalSymbols
    /**
     * The error code
     * @param status The status code
     * @param error The error message
     */
    constructor(private status: number, private error: T) {
        super();
    }
}

/**
 * API for Nirby
 */
export class NirbyApi {
    /**
     * Constructor.
     */
    constructor(
        private readonly apiHost: string,
    ) {
    }

    /**
     * Get a path to a resource in the API.
     * @param path The path to the resource.
     *
     * @returns The path to the resource.
     */
    public getPath(path: string): string {
        return `${this.apiHost}${path}`;
    }

    /**
     * Gets a request and creates a promise from it.
     * @param obs The observable to get the request from.
     *
     * @returns A promise that resolves with the response.
     */
    async handleRequest<ResBody>(obs: Promise<Response>): Promise<ResBody> {
        const result = await obs;
        if (!result.ok) {
            throw new ApiError(result.status, result.statusText);
        }
        return await result.json();
    }

    /**
     * Creates a PUT request.
     * @param path The path to the resource.
     * @param body The body of the request.
     * @param key The key to use for the request.
     *
     * @returns A promise that resolves with the response.
     */
    async put<ResBody, ReqBody = object>(
        path: string,
        body: ReqBody,
        key?: string,
    ): Promise<ResBody> {
        Logger.logStyled('API:PUT', path, body);
        return await this.handleRequest(
            fetch(this.getPath(path), {
                method: 'PUT',
                headers: await this.getAuthorizationHeader(key),
                body: JSON.stringify(body),
            }),
        );
    }

    /**
     * Creates a POST request.
     * @param path The path to the resource.
     * @param body The body of the request.
     * @param key The key to use for the request.
     */
    async post<ResBody, ReqBody = object>(
        path: string,
        body: ReqBody,
        key?: string,
    ): Promise<ResBody> {
        Logger.logStyled('API:POST', path, body);
        const headers: Record<string, string> = await this.getAuthorizationHeader(key);
        headers['Content-Type'] = 'application/json';
        return await this.handleRequest(
            fetch(this.getPath(path), {
                method: 'POST',
                headers,
                body: JSON.stringify(body),
            }),
        );
    }

    /**
     * Gets authorized header with the given key.
     * @param key The key to use for the request.
     *
     * @returns The authorized header.
     */
    async getAuthorizationHeader(
        key?: string,
    ): Promise<{ Authorization: string } | { [key: string]: string }> {
        return key
            ? {
                Authorization: `APIKEY ${key}`,
            }
            : {};
    }

    /**
     * Creates a GET request.
     * @param path The path to the resource.
     * @param queryParams The query parameters to use for the request.
     * @param key The key to use for the request.
     *
     * @returns A promise that resolves with the response.
     */
    async get<ResBody>(
        path: string,
        queryParams: { [key: string]: string | number | boolean | undefined },
        key?: string,
    ): Promise<ResBody> {
        Logger.logStyled('API:GET', path, queryParams);
        const params = Object.entries(queryParams)
            .filter(([, value]) => value !== undefined)
            .reduce((acc, [key, value]) => {
                if (value !== undefined) {
                    acc[key] = value?.toString();
                }
                return acc;
            }, {} as { [key: string]: string });

        const pathWithParams = new URL(this.getPath(path));
        pathWithParams.search = new URLSearchParams(params).toString();
        return await this.handleRequest(
            fetch(pathWithParams.toString(), {
                method: 'GET',
                headers: await this.getAuthorizationHeader(key),
            }),
        );
    }

    /**
     * Sends a beacon to the API.
     * @param path The path to the resource.
     * @param data The data to send.
     *
     * @returns The send beacon response.
     */
    sendBeacon(path: string, data: object): boolean {
        Logger.logStyled('API:BEACON', path, data);
        const blob = new Blob([JSON.stringify(data)], {
            type: 'application/json',
        });
        return navigator.sendBeacon(this.getPath(path), blob);
    }
}
