import { z } from 'zod';

export const CARD_POSITION_SCHEMA = z.union([
    z.literal('top-left'),
    z.literal('top-right'),
    z.literal('bottom-left'),
    z.literal('bottom-right'),
    z.literal('middle-left'),
    z.literal('middle-right'),
]);

export type CardPosition = z.infer<typeof CARD_POSITION_SCHEMA>;
