import { Injectable, Type } from '@angular/core';
import { SliderBlockContentComponent } from '../../components/blocks/slider/slider-block-content/slider-block-content.component';
import { BlockImageContentComponent } from '../../components/blocks/image/block-image-content/block-image-content.component';
import { BlockRectangleContentComponent } from '../../components/blocks/rectangle/rectangle-content/block-rectangle-content.component';
import { BlockQuestionContentComponent } from '../../components/blocks/question/block-question-content/block-question-content.component';
import { ButtonContentEditorComponent } from '../../components/blocks/button/button-content-editor/button-content-editor.component';
import { IconContentComponent } from '../../components/blocks/icon/icon-content/icon-content.component';
import { ComponentRegisterService } from '../component-register';
import { BlockVideoContentComponent } from '../../components/blocks/video/block-video-content/block-video-content.component';
import { BlockContentComponent } from '../../components/blocks/block-content.component';
import { BlockTextContentComponent } from '../../components/blocks/text/text-block-content/block-text-content.component';
import { AnyBlockType } from '@nirby/models/nirby-player';

@Injectable({
    providedIn: 'root',
})
export class BlockContentRegisterService extends ComponentRegisterService<
    AnyBlockType,
    BlockContentComponent<any>
> {
    protected register = new Map<
        AnyBlockType,
        Type<BlockContentComponent<any>>
    >([
        ['Text', BlockTextContentComponent],
        ['Image', BlockImageContentComponent],
        ['Rectangle', BlockRectangleContentComponent],
        ['Button', ButtonContentEditorComponent],
        ['Icon', IconContentComponent],
        ['Video', BlockVideoContentComponent],
        ['Slider', SliderBlockContentComponent],
        ['Question', BlockQuestionContentComponent],
    ]);
}
