import { MonoTypeOperatorFunction, Observable, OperatorFunction } from 'rxjs';
import { filter, map, shareReplay, tap } from 'rxjs/operators';
import {Logger} from '@nirby/logger';

export interface ShareReplayConfig {
    bufferSize?: number;
    windowTime?: number;
    refCount: boolean;
    scheduler?: any;
}

export function wait(ms: number): Promise<void> {
    ms = Math.max(ms, 0);
    return new Promise((res) => {
        setTimeout(() => res(), ms);
    });
}

export function arrayEquals(a: any[], b: any[]): boolean {
    if (a.length !== b.length) return false;

    let i;
    for (i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
            return false;
        }
    }
    return true;
}

export function distinctArray<T>(): MonoTypeOperatorFunction<T[]> {
    let last: any[];
    return filter((all: T[]) => {
        if (last && arrayEquals(all, last)) {
            return false;
        }
        last = [...all];
        return true;
    });
}

export const shareReplaySafe = <T>(
    config?: ShareReplayConfig,
): MonoTypeOperatorFunction<T> =>
    shareReplay({
        bufferSize: 1,
        refCount: true,
        ...config,
    });

export const debug = <T>(tag = 'DEBUG:RXJS') =>
    tap<T>((s: T) => Logger.logStyled(tag, s));

export function filterTruthy<T>(): OperatorFunction<T | undefined | null, T> {
    return function filterTruthyOperation(
        source: Observable<T | undefined | null>,
    ): Observable<T> {
        return source.pipe(
            filter((s) => !!s),
            map((s) => s as T),
        );
    };
}

export const cast = <S, T>() => map<S, T>((d) => d as unknown as T);
