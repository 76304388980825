import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Workspace} from '@nirby/models/editor';
import {RouteParametersService, WorkspaceService} from '@nirby/shared/database';
import {WidgetMigrationService} from '../../services/widget-migration.service';
import {faCheck, faClock} from '@fortawesome/free-solid-svg-icons';
import {NirbyDocument} from '@nirby/store/base';


type MigrationStatus = 'ready' | 'migrating' | 'finished';

@Component({
    selector: 'nirby-widget-migration',
    templateUrl: './widget-migration.component.html',
    styleUrls: ['./widget-migration.component.scss'],
})
export class WidgetMigrationComponent implements OnInit {
    widget$: Observable<NirbyDocument<Workspace>>;
    migrationStatus: MigrationStatus = 'ready';

    iconCheck = faCheck;
    iconClock = faClock;

    get widgetId(): string {
        return this.routeParams.workspaceId;
    }

    constructor(
        private routeParams: RouteParametersService,
        private migration: WidgetMigrationService,
        private widgets: WorkspaceService,
    ) {
        this.widget$ = this.widgets.activeWidget$;
    }

    ngOnInit(): void {
        this.startMigration()
            .then(() => (this.migrationStatus = 'finished'))
            .catch(() => (this.migrationStatus = 'ready'));
    }

    async startMigration(): Promise<void> {
        this.migrationStatus = 'migrating';
        try {
            await this.migration.deepMigrate(this.widgetId);
        } catch (e) {
            this.migrationStatus = 'ready';
            return;
        }
        this.migrationStatus = 'finished';
    }
}
