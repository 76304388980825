<div class="form-group mb-2">
    <label class="text-dark" for="dom-selector">DOM Selector</label>
    <input
        id="dom-selector"
        class="form-control form-control-sm"
        [formControl]="selectorControl"
        placeholder="I.e. #id > a.link"
    />
</div>
<div class="form-group mb-0">
    <label class="text-dark">On Event</label>
    <select class="form-control form-control-sm" [formControl]="eventControl">
        <option value="click">Click</option>
        <option value="dblclick">Double Click</option>
        <option value="swipeleft">Swipe Left</option>
        <option value="swiperight">Swipe Right</option>
        <option value="swipeup">Swipe Up</option>
        <option value="swipedown">Swipe Down</option>
    </select>
</div>
