import {DesignableBlock} from '../../block-designable';
import {DesignSet, ShapeUnitRawDescription} from '../../../designs';
import {AskBlockEvent} from '../../../designs/unit.model';
import {OpenFormAction, WidgetQuestionBlock} from '@nirby/models/nirby-player';
import {Subscription} from 'rxjs';
import Konva from 'konva';

const H_MARGIN = 15;
const V_MARGIN = 10;
const BOX_HEIGHT = 24;
const LABEL_HEIGHT = 12;
const INNER_SPACING = 12;
const HELPER_ANTI_PADDING = 5;

const defaultDesign: ShapeUnitRawDescription<'Rect'> = {
    shape: 'Rect',
    attributes: {
        backgroundColor: '#ffffff',
        borderRadius: 10,
        shadowColor: 'rgba(0,0,0,0.15)',
        shadowOffsetY: 4,
        shadowOffsetX: 0,
        shadowBlur: 16,
    },
    children: [
        // Title
        {
            shape: 'Text',
            axis: {
                horizontal: 'stretch',
                vertical: 'stretch',
                left: H_MARGIN,
                right: H_MARGIN,
                top: V_MARGIN,
                bottom: V_MARGIN + BOX_HEIGHT + INNER_SPACING,
            },
            attributes: {
                fontStyle: 'bold',
                verticalAlign: 'top',
                fill: '#484B57',
                text: {
                    method: 'GetBlockContent',
                    arguments: ['question'],
                },
                fontSize: 14,
                align: 'center',
            },
            children: [],
        },
        // Answer Box
        {
            shape: 'Rect',
            axis: {
                horizontal: 'stretch',
                vertical: 'pin-bottom',
                left: H_MARGIN,
                right: H_MARGIN,
                bottom: V_MARGIN + LABEL_HEIGHT,
                height: BOX_HEIGHT,
            },
            listenTo: ['isHover'],
            attributes: {
                backgroundColor: {
                    method: 'IfElseUnit',
                    arguments: [
                        'isHover',
                        {
                            method: 'IfElseBlock',
                            arguments: ['answer', '#e5e7ec', '#d2d2d9'],
                        },
                        '#e5e7ec',
                    ],
                },
                borderColor: 'rgba(0,0,0,0)',
                borderRadius: 3,
                cursor: 'pointer',
            },
            children: [
                // answer
                {
                    shape: 'Text',
                    axis: {
                        horizontal: 'stretch',
                        vertical: 'stretch',
                        left: 5,
                        right: 5,
                        top: 5,
                        bottom: 5,
                    },
                    attributes: {
                        text: {
                            method: 'IfElseBlock',
                            arguments: [
                                'answer',
                                {
                                    method: 'GetState',
                                    arguments: ['answer', 'string'],
                                },
                                {
                                    method: 'Translate',
                                    arguments: ['BLOCKS.QUESTION.ANSWER_PLACEHOLDER'],
                                },
                            ],
                        },
                        align: 'center',
                        verticalAlign: {
                            method: 'IfElseBlock',
                            arguments: ['answer', 'top', 'middle'],
                        },
                        overflow: 'ellipsis',
                        fill: '#8B8E9C',
                        fontSize: 10,
                        textBaseline: 'bottom',
                        lineHeight: 1.2,
                        cursor: 'ignore',
                    },
                    events: {
                        click: {
                            type: 'Ask',
                            properties: {
                                question: {
                                    method: 'GetBlockContent',
                                    arguments: ['question'],
                                },
                                type: 'string',
                            },
                        },
                    },
                    children: [],
                },
            ],
        },
        // Instruction label
        {
            shape: 'Text',
            axis: {
                horizontal: 'stretch',
                vertical: 'pin-bottom',
                left: H_MARGIN - HELPER_ANTI_PADDING,
                right: H_MARGIN - HELPER_ANTI_PADDING,
                bottom: V_MARGIN,
                height: LABEL_HEIGHT,
            },
            attributes: {
                overflow: 'ellipsis',
                text: {
                    method: 'IfElseBlock',
                    arguments: [
                        'answer',
                        {
                            method: 'GetBlockContentOrElse',
                            arguments: [
                                'successLabel',
                                {
                                    method: 'Translate',
                                    arguments: ['BLOCKS.QUESTION.SUCCESS'],
                                },
                            ],
                        },
                        {
                            method: 'Translate',
                            arguments: ['BLOCKS.QUESTION.INSTRUCTION'],
                        },
                    ],
                },
                fontSize: 10,
                fill: '#8B8E9C',
                align: 'center',
                verticalAlign: 'bottom',
            },
            children: [],
        },
    ],
    axis: {
        horizontal: 'stretch',
        vertical: 'stretch',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
};

/**
 * Question block.
 */
export class QuestionBlockDrawable<TMeta> extends DesignableBlock<TMeta, WidgetQuestionBlock> {
    override MIN_WIDTH = 164;
    override MIN_HEIGHT = 100;

    override baseSize = {
        width: 180,
        height: 100,
    };

    override blockToActionTransformers = {
        Ask: (event: AskBlockEvent): OpenFormAction | null => {
            if (this.answered) {
                return null;
            }
            return {
                id: null,
                options: {
                    title: this.blockProperties.question,
                    description: null,
                    type: 'question',
                    attributes: [
                        {
                            id: this.id + '.answer',
                            label: event.properties.question,
                            type: 'string',
                            required: true,
                            store: true,
                        },
                    ],
                },
                type: 'open-form',
            };
        },
    };

    designSet: DesignSet = {
        default: defaultDesign,
    };

    /**
     * The answer to this question
     * @private
     */
    private get answer(): string | null {
        const a = this.memory.state.value['answer'];
        return a ? a.toString() : null;
    }

    /**
     * If this question has been answered
     * @private
     */
    private get answered(): boolean {
        return this.answer !== null;
    }

    /**
     * The current design ID.
     * @protected
     *
     * @returns 'default'
     */
    protected getCurrentDesignId(): string {
        return 'default';
    }

    /**
     * Preprocess the block.
     * @param options - Block preprocess options.
     * @protected
     */
    protected override preprocess(options: WidgetQuestionBlock): void {
        options.content.question = this.global.transform(
            options.content.question,
        );
    }

    /**
     * Subscribes to the block's events, and marks the question answer as persistent.
     * @param shape - The shape of the block.
     * @protected
     *
     * @returns - Subscription
     */
    protected override subscribeToEvents(shape: Konva.Group): Subscription {
        const subscription = super.subscribeToEvents(shape);
        this.context.markKeysAsPersistent(this.id + '.answer');
        return subscription;
    }
}
