import {Injectable} from '@angular/core';
import {EditorModule} from '../editor.module';
import {Observable, Subject, merge} from 'rxjs';
import {AnyBlockType, Card} from '@nirby/models/nirby-player';

export type BlockSelectorItemType = AnyBlockType | 'art-board';

interface BlockSelectorSelectionBase<
    TType extends BlockSelectorItemType,
    TSettings extends object = object
    > {
    type: TType;
    settings?: TSettings;
}

export type BlockSelectorSelection =
    BlockSelectorSelectionBase<AnyBlockType> |
    BlockSelectorSelectionBase<'art-board', {
        cardData: Card | null;
    }>;

@Injectable({
  providedIn: 'any' // EditorModule
})
/**
 * Service for managing block selections.
 */
export class BlockSelectorService {
    private readonly selectedBlockInstantSubject = new Subject<BlockSelectorSelection>();
    private readonly selectedBlockDragSubject = new Subject<BlockSelectorSelection>();

    public readonly selectedBlockInstant$: Observable<BlockSelectorSelection> = this.selectedBlockInstantSubject.asObservable();
    public readonly selectedBlockDrag$: Observable<BlockSelectorSelection> = this.selectedBlockDragSubject.asObservable();
    public readonly selectedBlock$ = merge(this.selectedBlockInstant$, this.selectedBlockDrag$);

    /**
     * Emits a block selection event through the given subject.
     * @param blockType The type of the block to select.
     * @param subject The subject to emit the selection event through.
     * @private
     */
    private static selectBlock(blockType: AnyBlockType, subject: Subject<BlockSelectorSelection>): void {
        subject.next({
            type: blockType,
            settings: {}
        });
    }

    /**
     * Emits a block selection event through the given subject.
     * @param cardData The card to use as a base
     * @param subject The subject to emit the selection event through.
     * @private
     */
    private static selectCard(cardData: Card | null, subject: Subject<BlockSelectorSelection>): void {
        subject.next({
            type: 'art-board',
            settings: {
                cardData: cardData
            }
        });
    }

    public selectInstant(type: AnyBlockType): void;
    public selectInstant(type: 'art-board', baseCard?: Card): void;

    /**
     * Select a block instantly
     * @param blockType The block type to select
     * @param baseCard The card to use as the base for the art board
     */
    public selectInstant(blockType: AnyBlockType | 'art-board', baseCard?: Card): void {
        if (blockType === 'art-board') {
            BlockSelectorService.selectCard(baseCard ?? null, this.selectedBlockInstantSubject);
        } else {
            BlockSelectorService.selectBlock(blockType, this.selectedBlockInstantSubject);
        }
    }

    public selectDrag(type: AnyBlockType): void;
    public selectDrag(type: 'art-board', baseCard?: Card): void;

    /**
     * Select a block for dragging
     * @param blockType The block type to select
     * @param baseCard The card to use as the base for the art board
     */
    public selectDrag(blockType: AnyBlockType | 'art-board', baseCard?: Card): void {
        if (blockType === 'art-board') {
            BlockSelectorService.selectCard(baseCard ?? null, this.selectedBlockDragSubject);
        } else {
            BlockSelectorService.selectBlock(blockType, this.selectedBlockDragSubject);
        }
    }
}
