import { Inject, Injectable } from '@angular/core';
import { VideoService } from './video.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { firstValueFrom, of } from 'rxjs';
import { VideoRecord } from '@nirby/media-models';
import { MEDIA_CONFIG, MediaConfig } from './media-token';

interface VimeoGetResponse {
    pictures: {
        sizes: { link: string }[];
    };
    name: string;
}

@Injectable({
    providedIn: 'root',
})
/**
 * Service for fetching video data from Vimeo
 */
export class VimeoService implements VideoService {
    /**
     * Constructor.
     * @param config The configuration for the service
     * @param http The HTTP client
     */
    constructor(
        @Inject(MEDIA_CONFIG) private config: MediaConfig,
        private http: HttpClient
    ) {
    }

    /**
     * The API key for Vimeo
     */
    get apiKey(): string {
        return this.config.vimeo.apiKey;
    }

    /**
     * Get the header for the Vimeo API
     *
     * @returns The header for the Vimeo API
     */
    getAuthorizedHeader(): object {
        return {
            Authorization: `Bearer ${this.apiKey}`,
            Accept: 'application/json',
        };
    }

    /**
     * Get the embed URL for the given video ID.
     * @param id The video ID
     *
     * @returns The embed URL for the video
     */
    getEmbedUrlFromId(id: string): string {
        return `https://player.vimeo.com/video/${id}`;
    }

    /**
     * Get the {@link VideoRecord} from the given video ID.
     * @param id The video ID
     *
     * @returns The {@link VideoRecord} for the video
     */
    async getVideoFromId(id: string): Promise<VideoRecord | null> {
        const rawHeaders = { ...this.getAuthorizedHeader() };
        const headers = new HttpHeaders(rawHeaders);

        const response: VimeoGetResponse | null = await firstValueFrom(
            this.http
                .get(`https://api.vimeo.com/videos/${id}`, { headers })
                .pipe(
                    map((r) => r as VimeoGetResponse),
                    catchError(() => of(null))
                )
        );

        if (!response) {
            return null;
        }

        return {
            type: 'vimeo',
            id,
            thumbnail:
                response.pictures.sizes[response.pictures.sizes.length - 1]
                    ?.link,
            metadata: {},
            title: response.name,
        };
    }

    /**
     * Get the {@link VideoRecord} from the given URL.
     * @param url The URL
     *
     * @returns The {@link VideoRecord} for the video
     */
    async getVideoFromUrl(url: string): Promise<VideoRecord | null> {
        let parsed;
        try {
            parsed = new URL(url);
        } catch (e) {
            return null;
        }
        if (parsed.host !== 'vimeo.com') {
            return null;
        }
        const id = parsed.pathname.split('/')[1];
        return await this.getVideoFromId(id);
    }
}
