// noinspection JSUnusedGlobalSymbols

import {distinctUntilChanged, Observable, Subject, Subscription, Unsubscribable} from 'rxjs';
import {Logger} from '@nirby/logger';
import {v4} from 'uuid';
import {MoveOperation} from '../../';
import {debounceTime} from 'rxjs/operators';

export type TreeChangeInsertRequest<T> = {
    id: string;
    type: 'insert';
    parentId: string | null;
    data: T;
    destinyIndex: number | null;
};

export type TreeChangeInsert<T> = {
    id: string;
    type: 'insert';
    parentId: string | null;
    currentValue: T;
    destinyIndex: number;
};

export type TreeChangeRemove<T> = {
    id: string;
    type: 'remove';
    previousValue: T;
};

export type TreeChangeRemoveRequest = {
    id: string;
    type: 'remove';
};

export type TreeChangeUpdateRequest<T> = {
    id: string;
    type: 'update';
    data: T | null;
    destinyIndex?: number;
};

export type TreeChangeUpdate<T> = {
    id: string;
    type: 'update';
    newValue: T;
    previousValue: T;
    destinyIndex: number;
};

export type TreeChangeRequest<T> =
    | TreeChangeInsertRequest<T>
    | TreeChangeRemoveRequest
    | TreeChangeUpdateRequest<T>;

export type TreeChange<T> =
    | TreeChangeInsertRequest<T>
    | TreeChangeRemove<T>
    | TreeChangeUpdate<T>;

export interface TreeItem<T> {
    id: string;
    data: T;
    parentId: string | null;
    children: TreeItem<T>[];
}

/**
 * An iterator that goes from the start number (inclusive) to the end number (exclusive).
 * @param start The start number.
 * @param end The end number.
 *
 * @generator
 */
function* range(start: number, end: number): IterableIterator<number> {
    for (let i = start; i < end; i++) {
        yield i;
    }
}

/**
 * Class to register how to apply changes to a tree.
 */
export class TreeChangeCRUD<T> {
    private readonly changesSubject = new Subject<TreeChange<T>>();

    /**
     * Emits the changes to the tree.
     */
    public get changes$(): Observable<TreeChange<T>> {
        return this.changesSubject.asObservable();
    }

    /**
     * Constructor.
     * @param find Function to find an item in the tree.
     * @param insert Function to insert an item in the tree.
     * @param remove Function to remove an item in the tree.
     * @param update Function to update an item in the tree.
     */
    constructor(
        private readonly find: (id: string) => TreeItem<T> | null,
        private readonly insert: (
            id: string,
            data: T,
            parentId: string | null,
            index: number | null
        ) => void,
        private readonly remove: (id: string) => void,
        private readonly update: (
            id: string,
            data: T | null,
            destinyIndex?: number
        ) => MoveOperation<TreeItem<T>> | null,
    ) {
    }

    /**
     * Find an item in the tree.
     * @param id Id of the item to find.
     *
     * @returns - The item found or null if not found.
     */
    findById(id: string): T | null {
        return this.find(id)?.data ?? null;
    }

    /**
     * Finds an item in the tree and returns itself and some extra data of its position.
     * @param id Id of the item to find.
     *
     * @returns - The item found or null if not found.
     */
    findItem(id: string): TreeItem<T> | null {
        return this.find(id);
    }

    /**
     * Finds an item using its on index on its parent's children array.
     * @param parentId Id of the parent item.
     * @param index Index of the item to find.
     *
     * @returns - The item found or null if not found.
     */
    findAt(parentId: string, index: number): TreeItem<T> | null {
        const parent = this.findItem(parentId);
        if (!parent) {
            return null;
        }
        return parent.children[index] ?? null;
    }

    /**
     * Finds an item's parent
     * @param id Id of the item to find.
     *
     * @returns - The parent item or null if not found.
     */
    findParentOf(id: string): TreeItem<T> | null {
        const parentId = this.findItem(id)?.parentId;
        if (!parentId) {
            return null;
        }
        return this.findItem(parentId);
    }

    /**
     * Finds an item's siblings.
     * @param id Id of the item to find.
     *
     * @returns - The siblings of the item including itself.
     */
    findSiblings(id: string): TreeItem<T>[] {
        return this.findParentOf(id)?.children ?? [];
    }

    /**
     * Applies an insert operation to the tree.
     * @param change The insert operation.
     * @private
     *
     * @returns - The changes that were applied.
     */
    private applyInsert(change: TreeChangeInsertRequest<T>): TreeChange<T>[] {
        this.insert(change.id, change.data, change.parentId, change.destinyIndex);
        const index = this.indexOf(change.id);
        const updatedData = this.findById(change.id);
        if (index === -1 || !updatedData) {
            Logger.warnStyled(
                'BLOCK-EDITOR-STORE',
                `Could not find inserted item with ID ${change.id} (index: ${index}): ${updatedData}`,
            );
            return [];
        }
        return [
            {
                parentId: change.parentId,
                id: change.id,
                data: updatedData,
                destinyIndex: index,
                type: 'insert',
            },
        ];
    }

    /**
     * Applies a remove operation to the tree.
     * @param change The remove operation.
     * @private
     *
     * @returns - The changes that were applied.
     */
    private applyRemove(change: TreeChangeRemoveRequest): TreeChange<T>[] {
        const previousValue = this.findById(change.id);
        if (previousValue === null) {
            Logger.warnStyled(
                'BLOCK-EDITOR-STORE',
                `Could not find item to remove with ID ${change.id}`,
            );
            return [];
        }

        let sideAffected: TreeChangeUpdate<T>[] = [];

        const parent = this.findParentOf(change.id);
        if (parent) {
            const itemIndex = this.indexOf(change.id);
            if (itemIndex !== -1) {
                sideAffected = parent.children.slice(itemIndex + 1).map((child, index): TreeChangeUpdate<T> => ({
                    type: 'update',
                    id: child.id,
                    newValue: child.data,
                    previousValue: child.data,
                    destinyIndex: itemIndex + index,
                }));
            }
        }

        this.remove(change.id);
        this.changesSubject.next({
            id: change.id,
            type: 'remove',
            previousValue,
        });

        return [
            {
                id: change.id,
                type: 'remove',
                previousValue,
            },
            ...sideAffected,
        ];
    }

    /**
     * Applies an update operation to the tree.
     * @param change The update operation.
     * @private
     *
     * @returns - The changes that were applied.
     */
    private applyUpdate(change: TreeChangeUpdateRequest<T>): TreeChange<T>[] {
        const previousValue = this.findById(change.id);
        if (!previousValue) {
            Logger.warnStyled(
                'BLOCK-EDITOR-STORE',
                `Could not find item to update with ID ${change.id}`,
            );
            return [];
        }
        const operation = this.update(
            change.id,
            change.data,
            change.destinyIndex,
        );
        const index = this.indexOf(change.id);
        const updatedData = this.findById(change.id);
        if (index === -1 || !updatedData) {
            Logger.warnStyled(
                'BLOCK-EDITOR-STORE',
                `Could not find updated item with ID ${change.id}`,
            );
            return [];
        }
        if (!operation) {
            return [
                {
                    id: change.id,
                    newValue: updatedData,
                    previousValue,
                    destinyIndex: index,
                    type: 'update',
                },
            ];
        }
        const start = Math.min(operation.from, operation.to);
        const end = Math.max(operation.from, operation.to);
        return Array.from(
            range(start, end),
            i => {
                const item = operation.result[i];
                return {
                    id: item.id,
                    newValue: item.data,
                    previousValue: item.data,
                    destinyIndex: i,
                    type: 'update',
                };
            },
        );
    }

    /**
     * Apply a change to the tree using the operation functions.
     * @param change The change to apply.
     *
     * @returns - The affected items.
     */
    apply(change: TreeChangeRequest<T>): TreeChange<T>[] {
        Logger.logStyled(
            'BLOCK-EDITOR-STORE',
            `Applying change (${change.type}):`,
            change,
        );
        let affected: TreeChange<T>[] = [];
        switch (change.type) {
            case 'insert':
                affected = this.applyInsert(change);
                break;
            case 'remove':
                affected = this.applyRemove(change);
                break;
            case 'update':
                affected = this.applyUpdate(change);
                break;
        }
        for (const affectedElement of affected) {
            this.changesSubject.next(affectedElement);
        }
        return affected;
    }

    /**
     * Get the index of an item respective to its siblings.
     * @param id The ID of the item.
     *
     * @returns - The index of the item.
     */
    indexOf(id: string): number {
        return this.findSiblings(id)
            .map((v) => v.id)
            .indexOf(id);
    }
}

export interface AffectedItem<T> {
    id: string;
    data: T | null;
    index: number;
    type: TreeChange<T>['type'];
}

/**
 * Helper class to handle fast update and register only one change for all those updates.
 *
 * Usage:
 * ```ts
 * const store = new FastUpdateStore<T>(history);
 * store.update(id, data1);
 * store.update(id, data2);
 * store.update(id, data3);
 * // after 500ms without updates, the store will register a single change with the last data
 * // this can be done multiple times. You can configure the time interval to be used in the constructor.
 * ```
 * Will register only one change with all the updates.
 *
 * To avoid memory leaks, the store must be disposed after using the `dispose()` method.
 */
export class QuickUpdater<T> implements Unsubscribable {
    private readonly subscription: Subscription;
    private readonly updatesSubject = new Subject<T>();

    public readonly committedUpdates$ = this.updatesSubject.pipe(
        debounceTime(500),
        distinctUntilChanged(),
    );

    /**
     * Constructor.
     * @param itemId: ID of the item to update
     * @param history The history to register the changes.
     * @param restTime The time to wait before registering the changes.
     */
    constructor(
        private readonly itemId: string,
        private readonly history: TreeChangeHistory<T>,
        private readonly restTime = 500,
    ) {
        this.subscription = this.committedUpdates$.subscribe((data) =>
            this.history.update(this.itemId, data),
        );
    }

    /**
     * Disposes the updater. Implementation done for RxJS {@link Subscription} compatibility. e.g.
     * ```ts
     * const subscription = new Subscription();
     * subscription.add(anObservable$.subscribe(...));
     *
     * const updater = new FastUpdater<T>(id, history);
     * subscription.add(updater);
     *
     * ...
     * subscription.unsubscribe(); // this will dispose the updater and unsubscribe from the observable
     * ```
     *
     * Equivalent to calling `dispose()`
     */
    unsubscribe(): void {
        this.dispose();
    }

    /**
     * Updates the data of an item without registering a change on the history yet.
     * @param data The new data.
     */
    update(data: T): void {
        this.history.update(this.itemId, data, undefined, false);
        this.updatesSubject.next(data);
    }

    /**
     * Disposes the updater.
     */
    dispose(): void {
        if (this.subscription.closed) {
            Logger.warnStyled('FastUpdater already disposed');
            return;
        }
        this.subscription.unsubscribe();
    }
}

/**
 * Class to store and apply changes to a Tree-based structure.
 */
export class TreeChangeHistory<T> {

    /**
     * Watches changes in the tree.
     */
    public get changes$(): Observable<TreeChange<T>> {
        return this.crud.changes$;
    }

    private undo: TreeChangeRequest<T>[] = []; // past-est (past[0]) to present-est (past.pop())
    private redo: TreeChangeRequest<T>[] = []; // future-est (future[0]) to present-est (future.pop())

    /**
     * Creates a change that reverses the given change.
     *
     * @param change Change
     *
     * @returns - Revers change
     */
    private reversed(change: TreeChangeRequest<T>): TreeChangeRequest<T> | null {
        switch (change.type) {
            case 'insert':
                return {
                    id: change.id,
                    type: 'remove',
                };
            case 'update': {
                const item = this.crud.findItem(change.id);
                if (!item) {
                    return null;
                }
                const parent =
                    item && item.parentId
                        ? this.crud.findItem(item.parentId)
                        : null;
                let destinyIndex = 0;
                if (parent) {
                    // the destiny index is the index of the item before the move
                    destinyIndex = parent.children
                        .map((c) => c.id)
                        .indexOf(item.id);
                }
                return {
                    id: change.id,
                    type: 'update',
                    data: item.data,
                    destinyIndex: destinyIndex ?? 0,
                };
            }
            case 'remove': {
                const data = this.crud.findItem(change.id);
                const destinyIndex = this.crud.indexOf(change.id);
                if (!data || destinyIndex === -1) {
                    return null;
                }
                return {
                    id: change.id,
                    type: 'insert',
                    parentId: data.parentId,
                    data: data.data,
                    destinyIndex,
                };
            }
        }
    }

    /**
     * Constructor.
     * @param crud The CRUD to register the changes.
     */
    constructor(public readonly crud: TreeChangeCRUD<T>) {
    }

    /**
     * Applies a change
     * @param newValue New value
     * @param saveHistory Whether to save the change in the history
     * @private
     */
    private next(newValue: TreeChangeRequest<T>, saveHistory = true): void {
        Logger.logStyled(
            'BLOCK-EDITOR-STORE:' + newValue.type.toUpperCase(),
            newValue.id,
            newValue.type !== 'remove' ? newValue.data : '<<REMOVED>>',
        );
        if (!saveHistory) {
            this.crud.apply(newValue);
            return;
        }
        const reverseChange = this.reversed(newValue);
        if (saveHistory) {
            if (reverseChange) {
                this.undo.push(reverseChange);
            } else {
                Logger.warn('Cannot get data of ID', newValue.id, 'to reverse');
            }
            this.redo = [];
        }
        this.crud.apply(newValue);
    }

    /**
     * Clears the full history.
     */
    public clearHistory(): void {
        this.undo = [];
        this.redo = [];
    }

    /**
     * If it's possible to undo
     */
    public get canGoBack(): boolean {
        return this.undo.length > 0;
    }

    /**
     * If it's possible to redo
     */
    public get canGoForward(): boolean {
        return this.redo.length > 0;
    }

    /**
     * Removes an item from the tree.
     * @param id ID of the item to remove
     * @param saveHistory Whether to save the change in the history
     */
    public remove(id: string, saveHistory = true): void {
        this.next(
            {
                id,
                type: 'remove',
            },
            saveHistory,
        );
    }

    /**
     * Inserts a new item
     * @param id ID of the new item
     * @param data Data of the new item
     * @param parentId ID of the parent item
     * @param index Index where to insert the new item. If null, the item is inserted at the end.
     * @param saveHistory Whether to save the change in the history
     *
     * @returns - The new ID
     */
    public insert(
        id: string,
        data: T,
        parentId: string | null,
        index: number | null = null,
        saveHistory = true,
    ): string {
        if (this.crud.findItem(id)) {
            const newId = v4();
            Logger.warn(
                `ID "${id}" already exists, creating a new one:`,
                newId,
            );
            id = newId;
        }
        this.next(
            {
                id,
                type: 'insert',
                data,
                parentId,
                destinyIndex: index,
            },
            saveHistory,
        );
        return id;
    }

    /**
     * Updates an item
     * @param id ID of the item
     * @param data New data
     * @param newIndex The new index on the siblings array
     * @param saveHistory Whether to save the change in the history
     */
    public update(
        id: string,
        data: T | null,
        newIndex?: number,
        saveHistory = true,
    ): void {
        this.next(
            {
                id,
                type: 'update',
                destinyIndex: newIndex,
                data,
            },
            saveHistory,
        );
    }

    /**
     * Starts a fast update process.
     *
     * Usage:
     * ```
     * const updater = new FastUpdater('an-id', history);
     * updater.update(newData1);
     * updater.update(newData2);
     * updater.update(newData3);
     * // after a while, a change will be registered in the history
     * updater.dispose();
     * ```
     * @param id ID of the item to update
     * @param restTime The time to wait before registering the change
     *
     * @returns - A {@link QuickUpdater} instance to handle fast updates in an optimized way.
     *
     * @see QuickUpdater
     */
    public startQuickUpdate(id: string, restTime = 500): QuickUpdater<T> {
        return new QuickUpdater(id, this, restTime);
    }

    /**
     * Go some steps forward on the ``forwardArray`` and register backwards steps on the ``backwardArray``.
     * @param nTimes Number of steps to go forward
     * @param forwardArray Array to store forward steps
     * @param backwardsArray Array to store backward steps
     * @private
     */
    private goSomeDirection(
        nTimes = 1,
        forwardArray: TreeChangeRequest<T>[],
        backwardsArray: TreeChangeRequest<T>[],
    ): void {
        for (let count = 0; count < nTimes; count++) {
            const change = forwardArray.pop();
            if (!change) break;
            const reverseChange = this.reversed(change);
            if (!reverseChange) {
                Logger.warn('Cannot get data of ID', change.id);
                return;
            }
            backwardsArray.push(change);
            this.crud.apply(change);
        }
    }

    /**
     * Undo
     * @param nTimes Number of steps to go back
     */
    public goBack(nTimes = 1): void {
        this.goSomeDirection(nTimes, this.undo, this.redo);
    }

    /**
     * Redo
     * @param nTimes Number of steps to go forward
     */
    public goForward(nTimes = 1): void {
        this.goSomeDirection(nTimes, this.redo, this.undo);
    }
}
