import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Pop, Prime } from '@nirby/models/editor';
import { NirbyDocumentReference } from '@nirby/store/base';

@Component({
    selector: 'nirby-variables-dialog',
    templateUrl: './variables-dialog.component.html',
    styleUrls: ['./variables-dialog.component.scss'],
})
export class VariablesDialogComponent {
    @Input() visible = false;
    @Output() visibleChange = new EventEmitter<boolean>();

    @Input() productRef:
        | NirbyDocumentReference<Prime>
        | NirbyDocumentReference<Pop>
        | null = null;
}
