import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { EditableComponent } from '@ngneat/edit-in-place/lib/editable.component';
import { Mode } from '@ngneat/edit-in-place/lib/mode';

@Component({
    selector: 'nirby-touch-to-edit',
    templateUrl: './touch-to-edit.component.html',
    styleUrls: ['./touch-to-edit.component.scss'],
})
export class TouchToEditComponent implements OnChanges {
    @Input() suffix = '';
    @Input() disabled: boolean | null = null;
    @Input() value: string | undefined = '';
    @Input() labelStyleClasses: string[] = [];
    @Input() maxLength = Infinity;

    /**
     * The display value, which is the value cropped to the maximum length.
     */
    get displayValue(): string {
        const string = this.value ?? '';
        if (string.length > this.maxLength) {
            return string.substring(0, this.maxLength) + '...';
        }
        return string + this.suffix;
    }

    @Output() valueChange = new EventEmitter<string>();
    @Output() cancel = new EventEmitter<void>();
    editIcon = faEdit;

    tempValue = '';

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['value']) {
            this.tempValue = this.value ?? '';
        }
    }

    onChange(): void {
        this.valueChange.emit(this.tempValue);
    }

    onCancel(): void {
        this.tempValue = this.value ?? '';
        this.cancel.emit();
    }

    onModeChange(editable: EditableComponent, mode: Mode): void {
        if (mode === 'edit') {
            if (this.disabled) editable.cancelEdit();
        }
    }
}
