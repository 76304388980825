<div class="d-flex flex-column form-group-container">
    <div class="w-100 form-group-action p-2 d-flex flex-row" *ngFor="let action of controls; let i = index"
         [formGroup]="form">
        <div class="d-flex flex-column flex-grow-1" formArrayName="actions">
            <nirby-action-form-control
                [formControlName]="i"
                [actions]="actions"
                [disabled]="disabled"
                (closeRequest)="close()"
            ></nirby-action-form-control>
        </div>
        <button class="btn btn-outline-light btn-close ms-1" (click)="removeAction(i)">
            <fa-icon [icon]="icons.close"></fa-icon>
        </button>
    </div>
    <button class="btn btn-block d-flex flex-row justify-content-between" (click)="add()">
        <fa-icon [icon]="icons.add"></fa-icon>
        <span>Add action</span>
    </button>
</div>
