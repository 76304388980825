import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
/**
 * Service to handle windows
 */
export class WindowService {
    /**
     * Open window
     * @param url URL to open
     */
    async open(url: string): Promise<void> {
        window.open(url, '_blank');
    }
}
