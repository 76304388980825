import {BehaviorSubject, filter, firstValueFrom, merge, Observable, switchMap, throwError} from 'rxjs';
import * as tus from 'tus-js-client';


export type UploadStatus = 'error' | 'success' | 'progress' | 'idle';


/**
 * A Tus instance that can be used to upload a video.
 */
export class TusUploadInstance {
    tus: tus.Upload;

    private readonly progressSubject = new BehaviorSubject<number>(0);
    private readonly resultSubject = new BehaviorSubject<string | null>(null);
    private readonly errorSubject = new BehaviorSubject<Error | null>(null);
    private readonly statusSubject = new BehaviorSubject<UploadStatus>('idle');

    /**
     * Watches the status of the upload.
     */
    public get status$(): Observable<UploadStatus> {
        return this.statusSubject.asObservable();
    }

    /**
     * Watches the progress of the upload.
     */
    public get progress$(): Observable<number> {
        return this.progressSubject.asObservable();
    }

    /**
     * Watches the result of the upload.
     */
    public get result$(): Observable<string | null> {
        return this.resultSubject.asObservable();
    }

    /**
     * The upload video result.
     */
    public get result(): Promise<string> {
        const resultError$ = merge(
            this.result$.pipe(
                filter((value): value is string => value !== null),
            ),
            this.errorSubject.pipe(
                filter((value): value is Error => value !== null),
                switchMap(err => throwError(err))
            )
        );
        return firstValueFrom(resultError$);
    }

    /**
     * Constructor.
     * @param file The file or stream to upload.
     * @param headers The headers to use on the request.
     * @param url The URL to upload to.
     * @param durationSeconds The duration of the video in seconds.
     */
    public constructor(
        private readonly file: File | Blob,
        headers: Record<string, string>,
        url: string,
        durationSeconds: number,
    ) {
        this.tus = new tus.Upload(file, {
            endpoint: url,
            retryDelays: [0, 3000, 5000, 10000, 20000],
            headers,
            metadata: {
                maxDurationSeconds: Math.ceil(durationSeconds).toString(10),
            },
            chunkSize: 1024 * 1024 * 50,
            onError: (error) => {
                this.errorSubject.next(error);
                this.statusSubject.next('error');
            },
            onProgress: (bytesUploaded, bytesTotal) => {
                this.progressSubject.next(bytesUploaded / bytesTotal);
            },
            onSuccess: () => {
                this.progressSubject.next(1);
                this.resultSubject.next(this.tus.url);
                this.statusSubject.next('success');
            },
            onAfterResponse: () => {
                if (this.tus.options.headers) {
                    // after the first request, we don't need the authorization header anymore (as it would
                    // cause CORS issues)
                    delete this.tus.options.headers['Authorization'];
                }
            }
        });
    }

    /**
     * Starts the upload.
     */
    public start(): void {
        this.statusSubject.next('progress');
        this.tus.start();
    }

    /**
     * Aborts the upload.
     */
    public async abort(): Promise<void> {
        await this.tus.abort();
        this.statusSubject.next('idle');
    }
}
