import {Component} from '@angular/core';
import {BillingService} from '../../services/billing.service';
import {of, switchMap} from 'rxjs';
import {AuthenticationService, WorkspaceService} from '@nirby/shared/database';
import {StripeProductPrice} from '@nirby/models/billing';
import {PlanItemFeatureGroup, PlanTableItemStyle} from '../plan-table-item/plan-table-item.component';
import {map} from 'rxjs/operators';

interface PlanTableItem {
    label: string;
    nirbyPlanGroup: string | null;
    features: PlanItemFeatureGroup[];
    style: PlanTableItemStyle;
}

@Component({
    selector: 'nirby-plan-table',
    templateUrl: './plan-table.component.html',
    styleUrls: ['./plan-table.component.scss'],
})
/**
 * The component for the plan table.
 */
export class PlanTableComponent {
    public readonly currentPlan$ = this.workspaces.activeWidget$.pipe(
        switchMap(workspace => workspace ? this.billing.getWorkspaceStripeProduct(workspace.data) : of(null)),
    );
    public readonly currentSubscription$ = this.workspaces.activeWidget$.pipe(
        map(({data: {plan}}) => plan ?? null),
    );
    groups: PlanTableItem[] = [
        {
            label: 'Startup',
            nirbyPlanGroup: 'startup',
            style: {
                primaryColor: 'rgb(77, 50, 231)',
                secondaryColor: 'rgb(77, 50, 231)',
            },
            features: [
                {
                    title: null,
                    features: [
                        'Access to all features',
                    ],
                },
                {
                    title: 'Advanced Features',
                    features: [
                        'Basic Analytics',
                        '30 Days Metrics History',
                    ],
                },
                {
                    title: 'Support and Others',
                    features: [
                        'Standard Support',
                    ],
                },
            ],
        },
        {
            label: 'Enterprise',
            nirbyPlanGroup: null,
            style: {
                primaryColor: 'rgb(0, 0, 0)',
                secondaryColor: 'rgb(14, 9, 74)',
            },
            features: [
                {
                    title: null,
                    features: [
                        'Everything in Startup Plan plus...',
                    ],
                },
                {
                    title: 'Advanced Features',
                    features: [
                        'Advanced Analytics',
                        'Unlimited Metrics History',
                    ],
                },
                {
                    title: 'Support and Others',
                    features: [
                        'Premium & Priority Support',
                        'Custom contract & invoicing',
                        'Dedicated Customer Success Manager',
                        'Access to "Media On Demand"',
                    ],
                },
            ],
        },
    ];
    interval: StripeProductPrice['interval'] = 'month';

    /**
     * Constructor.
     * @param workspaces Workspaces service.
     * @param billing Billing service.
     * @param auth Authentication service.
     */
    constructor(
        private readonly workspaces: WorkspaceService,
        private readonly billing: BillingService,
        private readonly auth: AuthenticationService,
    ) {
    }
}
