<form class="d-flex flex-row">
    <div class="d-flex flex-column justify-content-center me-2 left-column">
        <div class="box-item">
            Where
        </div>
        <div class="box-item">
            <select
                class="form-control"
                id="group"
                name="group-type"
                [ngModel]="groupType"
                (ngModelChange)="onGroupTypeChange($event)"
            >
                <option [ngValue]="'and'">And</option>
                <option [ngValue]="'or'">Or</option>
            </select>
        </div>
    </div>
    <div class="d-flex flex-column justify-content-start">
        <div class="box-item py-2" *ngFor="let item of items; let i = index; trackBy: trackByFn">
            <div class="d-flex flex-row align-items-center">
                <nirby-conditional-editor-simple
                    *ngIf="item.type === 'negate' && item.properties.condition.type === 'equals'"
                    [variables]="variables"
                    [variable]="item.properties.condition.properties.target"
                    [name]="'item-' + i"
                    [ngModel]="item"
                    (ngModelChange)="onItemChange($event, i)"
                ></nirby-conditional-editor-simple>
                <nirby-conditional-editor-simple
                    *ngIf="item.type === 'equals'"
                    [variables]="variables"
                    [variable]="item.properties.target"
                    [name]="'item-' + i"
                    [ngModel]="item"
                    (ngModelChange)="onItemChange($event, i)"
                ></nirby-conditional-editor-simple>
                <button class="btn btn-danger" (click)="delete(i)" *ngIf="items.length > 1">
                    <fa-icon [icon]="icons.close"></fa-icon>
                </button>
            </div>
        </div>
        <div class="box-item">
            <button class="btn btn-block btn-primary" (click)="addItem()">
                Add
            </button>
        </div>
    </div>
</form>
