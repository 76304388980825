import {Component, EventEmitter, Output} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {ImageRecord, MediaItemImage} from '@nirby/media-models';
import {MediaLibraryService} from '../../services/media-library.service';
import {Modeled} from '@nirby/store/models';

@Component({
    selector: 'nirby-media-library-images',
    templateUrl: './media-library-images.component.html',
    styleUrls: ['./media-library-images.component.scss'],
})
/**
 * Component to display Images available
 */
export class MediaLibraryImagesComponent {
    @Output() picked = new EventEmitter<ImageRecord>();

    deletedPaths$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(
        [],
    );
    mediaItems$: Observable<Modeled<MediaItemImage>[]> = combineLatest([
        this.library.queryAllFiles<MediaItemImage>('image'),
        this.deletedPaths$.asObservable(),
    ]).pipe(
        map(
            ([files, deleted]) =>
                files.filter(
                    (f) => !deleted.find((d) => d === f.path),
                ),
        ),
    );
    faDelete = faTrash;

    /**
     * Constructor
     */
    constructor(private library: MediaLibraryService) {
    }

    async delete(media: Modeled<MediaItemImage>): Promise<void> {
        if (media.content.source === 'upload') {
            this.deletedPaths$.next([...this.deletedPaths$.value, media.path]);
            if (media._docId) {
                await this.library.delete(
                    media.content.metadata.uploaderUserId,
                    media._docId,
                );
            } else {
                await this.library.deleteByPath(media.path);
            }
        }
    }
}
