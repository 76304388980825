import { Logger } from '@nirby/logger';

/**
 * Creates image that's a solid color.
 * @param color Color of the image.
 *
 * @returns Image that's a solid color.
 */
function createSolidColorImage(color: string): Promise<HTMLImageElement> {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
        throw new Error('Could not get canvas context');
    }
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, 64, 64);
    return waitImageLoad(canvas.toDataURL('image/png'));
}

export const IMAGES: {
    [src: string]: Promise<HTMLImageElement> | undefined;
} = {};

export const EMPTY_IMAGE: HTMLImageElement = new Image();
export const ERROR_IMAGE = createSolidColorImage('rgba(0,0,0,0.3)');

/**
 * Waits for an image to load. If an error occurs, the default image is loaded.
 * @param src The image source.
 *
 * @returns - The image.
 */
export function waitImageLoad(src: string): Promise<HTMLImageElement> {
    const image = IMAGES[src] ?? new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
            Logger.logStyled('BLOCK', `Image loaded: ${src}`);
            resolve(img);
        };
        img.onerror = () => {
            resolve(ERROR_IMAGE);
        };
        img.src = src;
    });
    IMAGES[src] = image;
    return image;
}
