import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ClipboardInputComponent} from './clipboard-input/clipboard-input.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {InputSwitchModule} from 'primeng/inputswitch';


@NgModule({
    declarations: [
        ClipboardInputComponent
    ],
    exports: [
        ClipboardInputComponent
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        InputTextModule,
        ButtonModule,
        InputSwitchModule
    ]
})
/**
 * ClipboardInputModule
 */
export class ClipboardInputModule {
}
