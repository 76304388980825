import {NirbyProductType, TrackData} from './analytics';
import {ProductModel} from './base';
import {z, ZodType} from 'zod';

export type MakeOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type PropertyValueType = string | number | boolean | null;
export type PropertyMap = {
    [key in string]: PropertyValueType
};

/**
 * Generic Nirby Event
 */
export interface GenericNirbyEvent<ProductType extends NirbyProductType | null = NirbyProductType,
    EventType extends string = string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    EventProperties extends PropertyMap = {}> extends ProductModel {
    eventId: string;
    contactId: string | null;
    contactUniqueAlias: string | null;
    productType: ProductType,
    type: EventType;
    tags: string[];
    track: TrackData | null;
    properties: EventProperties & Record<string, PropertyValueType>;
}

/* Events */

/***************************************************************************************************
 * General Events
 */

/**
 * Product is shown but not necessarily opened
 */
export type ImpressionEvent =
    GenericNirbyEvent<'pop', 'impression'>
    | GenericNirbyEvent<'prime', 'impression'>;

/**
 * Product is viewed
 */
export type ViewEvent =
    GenericNirbyEvent<'pop', 'view'>
    | GenericNirbyEvent<'prime', 'view'>;

/**
 * Product is viewed effectively
 */
export type EffectiveViewEvent =
    GenericNirbyEvent<'pop', 'view-effective'>
    | GenericNirbyEvent<'prime', 'view-effective'>;

/**
 * Product is closed
 */
export type CloseEvent = CloseEventPop | CloseEventPrime;

export type CloseEventPop = GenericNirbyEvent<'pop', 'close'>;
export const CLOSE_EVENT_PRIME_PROPS_SCHEMA = z.object({
    playTime: z.number(),
}).strict();
export type CloseEventPrime = GenericNirbyEvent<'prime', 'close', z.infer<typeof CLOSE_EVENT_PRIME_PROPS_SCHEMA>>;

/**
 * A new opportunity (contact updates its info)
 */
export type OpportunityEvent =
    GenericNirbyEvent<'pop', 'opportunity'>
    | GenericNirbyEvent<'prime', 'opportunity'>;

/**
 * An action is executed properties
 */
export const ACTION_EVENT_PROPS_SCHEMA = z.object({
    target: z.optional(z.string()),
    blockId: z.nullable(z.string()),
    cardId: z.nullable(z.string()),
    event: z.union([z.enum(['click']), z.string()]),
    actionType: z.string(),
}).strict();

export type ActionEventProperties = MakeOptional<z.infer<typeof ACTION_EVENT_PROPS_SCHEMA>, 'actionType'>;

/**
 * An action is executed
 */
export type ActionEvent =
    GenericNirbyEvent<'pop', 'action', ActionEventProperties>
    | GenericNirbyEvent<'prime', 'action', ActionEventProperties>;

/**
 * A card is viewed properties
 */
export const VIEW_CARD_EVENT_PROPS_SCHEMA = z.object({
    cardId: z.string(),
}).strict();
type ViewCardEventProperties = z.infer<typeof VIEW_CARD_EVENT_PROPS_SCHEMA>;

/**
 * A card is viewed event
 */
export type ViewCardEvent =
    GenericNirbyEvent<'pop', 'view-card', ViewCardEventProperties>
    | GenericNirbyEvent<'prime', 'view-card', ViewCardEventProperties>;

/***************************************************************************************************
 * Pop Events
 */

/**
 * A conversion is registered (TBD)
 */
export type ConversionEvent = GenericNirbyEvent<'pop', 'conversion'>;

/***************************************************************************************************
 * Prime Events
 */

/**
 * A prime video was played
 */
export const PRIME_COMPLETION_EVENT_PROPS_SCHEMA = z.object({
    /** Last seen video **/
    videoId: z.string(),
    /** Total prime play time **/
    playTime: z.number(),
}).strict();
export type PrimeCompletionEvent = GenericNirbyEvent<'prime', 'completion', z.infer<typeof PRIME_COMPLETION_EVENT_PROPS_SCHEMA>>;

/**
 * A prime video was played
 */
export const VIEW_VIDEO_EVENT_PROPS_SCHEMA = z.object({
    sourceVideoId: z.string().nullable(),
    sourcePrimeId: z.string().nullable(),
    sourceVideoTime: z.number().nullable(),

    destinyVideoId: z.string(),
    destinyPrimeId: z.string(),
    destinyVideoTime: z.number(),

    reason: z.enum([
        'video-end',
        'action:click',
        'start',
        'skip',
        'back',
    ]),

    triggererBlockId: z.string().nullable(),
}).strict();
export type ViewVideoEvent = GenericNirbyEvent<'prime', 'view-video', z.infer<typeof VIEW_VIDEO_EVENT_PROPS_SCHEMA>>;

// Standard events

/**
 * Standard Nirby Events
 */
export type NirbyStandardEvent =
    ImpressionEvent
    | ViewEvent
    | CloseEvent
    | ActionEvent
    | ViewCardEvent
    | ViewVideoEvent
    | ConversionEvent
    | OpportunityEvent
    | EffectiveViewEvent
    | PrimeCompletionEvent;

// Custom events

/**
 * A custom Nirby Event
 */
export type CustomEvent = GenericNirbyEvent<'pop'> | GenericNirbyEvent<'prime'>;

/**
 * A Nirby standard event or custom event
 */
export type AnyNirbyEvent = NirbyStandardEvent | CustomEvent;

// Utilities

/**
 * Infers a Nirby Event type
 */
export type NirbyEventTypeOf<T extends GenericNirbyEvent> = T extends { type: infer Type } ? Type : never;

/**
 * Picks Nirby Events from a union
 */
type PickNirbyEventByTypes<A extends GenericNirbyEvent, TProductType extends NirbyProductType, TEventType extends NirbyEventTypeOf<NirbyStandardEvent>> = A extends {
    productType: TProductType,
    type: TEventType
} ? A : never;

/**
 * Picks a Nirby Standard Event
 */
export type NirbyEvent<TProductType extends NirbyProductType = NirbyProductType,
    TEventType extends NirbyEventType = NirbyEventType> =
    PickNirbyEventByTypes<NirbyStandardEvent, TProductType, TEventType>;

/**
 * Infers a Nirby Event properties type
 */
export type NirbyEventPropertiesOf<T extends GenericNirbyEvent> = T['properties'];

/**
 * Available Nirby Event types
 */
export type NirbyEventType = NirbyEventTypeOf<NirbyStandardEvent>;

/**
 * Available Nirby Event types for a product type
 */
export type NirbyEventTypeOfProduct<TProductType extends NirbyProductType> = NirbyEventTypeOf<NirbyEvent<TProductType>>;


/**
 * Zod Schema of an event properties
 */
export type EventPropertiesSchemaOf<T extends GenericNirbyEvent> = ZodType<AnyNirbyEvent['properties']>;
