<div [formGroup]="form">
    <div class="form-group">
        <label for="question">Question</label>
        <input
            id="question"
            class="form-control"
            [formControl]="questionControl"
        />
    </div>
    <div class="form-group">
        <label for="successLabel">Submit Message</label>
        <input
            id="successLabel"
            class="form-control"
            [formControl]="successLabelControl"
        />
    </div>
</div>
