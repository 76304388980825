import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WorkspaceCreationRoutingModule} from './workspace-creation-routing.module';
import {CreateWidgetComponent} from './create-widget/create-widget.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {RouterModule} from '@angular/router';
import {VideosWidgetComponent} from './videos-widget/videos-widget.component';
import {TutorialWidgetComponent} from './tutorial-widget/tutorial-widget.component';
import {PopCommonModule} from '@nirby/shared/widgets';
import {NewWidgetFormComponent} from './create-widget-form/new-widget-form.component';
import {ReactiveFormsModule} from '@angular/forms';
import { WorkspacesListComponent } from './workspaces-list/workspaces-list.component';
import {ToolbarModule} from '../toolbar/toolbar.module';


@NgModule({
    declarations: [
        CreateWidgetComponent,
        VideosWidgetComponent,
        TutorialWidgetComponent,
        NewWidgetFormComponent,
        WorkspacesListComponent
    ],
    imports: [
        CommonModule,
        WorkspaceCreationRoutingModule,
        FontAwesomeModule,
        RouterModule,
        PopCommonModule,
        ReactiveFormsModule,
        ToolbarModule
    ]
})
export class WorkspaceListModule {
}
