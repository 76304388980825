import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthenticationService, WorkspaceService} from '@nirby/shared/database';
import {AppError} from '@nirby/js-utils/errors';
import {firstValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HasWidgetsGuard implements CanActivate {
    constructor(
        private auth: AuthenticationService,
        private router: Router,
        private workspace: WorkspaceService,
    ) {
    }

    async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Promise<true | UrlTree> {
        const userData = await this.auth.getAuthenticatedUser();
        if (!userData) {
            throw new AppError('No user logged');
        }

        const workspaces = await firstValueFrom(this.workspace.getUserWorkspaces(userData.id));
        if (workspaces.length === 0) {
            return this.router.parseUrl('/workspaces');
        }
        return true;
    }
}
