<div class="input-group mb-3">
    <label for="clipboard" *ngIf="label">{{label}}</label>
    <div class="p-inputgroup">
        <input id="clipboard" pInputText type="text" [value]="value" readonly #input/>
        <button
            pButton
            (click)="copy(input)"
        >
            <fa-icon [icon]="icon"></fa-icon>
        </button>
    </div>
</div>
