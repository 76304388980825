import {ZodSchemaBuild} from '@nirby/store/models';
import {Workspace, WORKSPACE_MIGRATOR} from './workspace.model';
import {z} from 'zod';
import {Migrator} from '@nirby/store/migrator';
import {buildUploadedReferenceSchema} from '@nirby/store/proxy';
import {NirbyDocumentReference} from '@nirby/store/base';

export interface WorkspaceRef {
    ref: NirbyDocumentReference<Workspace>;
    name: string;
}

export interface UserData {
    id: string;
    email: string | null;
    profilePicture: string | null;
    isDeveloper?: boolean;
    /**@deprecated*/
    widgets?: WorkspaceRef[];
    plan?: string;
    displayName?: string;
    /**@deprecated*/
    ownedPlugins?: unknown[];
    /**@deprecated*/
}

export const USER_SCHEMA: ZodSchemaBuild<UserData> = z
    .object({
        id: z.string(),
        email: z.string().nullable().default(null),
        profilePicture: z.string().nullable().default(null),
        isDeveloper: z.boolean().default(false),
        widgets: z
            .array(
                z.object({
                    ref: buildUploadedReferenceSchema<Workspace>(
                        () => WORKSPACE_MIGRATOR,
                    ),
                    name: z.string(),
                }),
            )
            .optional(),
        plan: z.string().catch(() => 'FREE'),
        displayName: z.string().catch(() => 'Unnamed User'),
        ownedPlugins: z.array(z.unknown()).optional(),
    })
    .describe('User Data');

export const USER_MIGRATOR = new Migrator<UserData>(USER_SCHEMA, () => ({
    id: '',
    email: null,
    profilePicture: null,
    isDeveloper: false,
    widgets: [],
    plan: 'FREE',
}));
