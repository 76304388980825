import {Injectable} from '@angular/core';
import {Directory, Pop, Prime, Workspace} from '@nirby/models/editor';
import {combineLatest, Observable, of, switchMap} from 'rxjs';
import {DirectoriesService, RouteParametersService} from '..';
import {map} from 'rxjs/operators';
import {Database} from '@nirby/ngutils';
import {WorkspaceService} from '../collections';
import {NirbyProductType, ProductId} from '@nirby/analytics-typings';
import {SelectItemGroup} from 'primeng/api';
import {SelectItem} from 'primeng/api/selectitem';
import {groupByIter, mapIter} from '../utils/groupByIter';
import {NirbyCollection, NirbyDocument, NirbyDocumentReference} from '@nirby/store/base';
import {COLLECTION_KEYS} from '@nirby/store/collections';

export type ProductReference =
    | {
    type: 'pop';
    ref: NirbyDocumentReference<Pop>;
    workspaceRef: NirbyDocumentReference<Workspace>;
}
    | {
    type: 'prime';
    ref: NirbyDocumentReference<Prime>;
    workspaceRef: NirbyDocumentReference<Workspace>;
};

interface ProductListing {
    pop: NirbyDocument<Pop>[];
    prime: NirbyDocument<Prime>[];
}

@Injectable({
    providedIn: 'root',
})
/**
 * Service for managing Nirby products (Pops and Primes)
 */
export class ProductsListService {
    /**
     * The current product's reference
     */
    public readonly productRef$: Observable<ProductReference | null> =
        combineLatest([
            this.workspaces.workspaceId$,
            this.params.watchParam<string>('popId'),
            this.params.watchParam<string>('primeId'),
        ]).pipe(
            map(([workspaceId, popId, primeId]) => {
                if (popId) {
                    return {
                        type: 'pop',
                        ref: this.pops(workspaceId).ref(popId),
                        workspaceRef: this.workspaces.getReference(workspaceId),
                    };
                }
                if (primeId) {
                    return {
                        type: 'prime',
                        ref: this.primes(workspaceId).ref(primeId),
                        workspaceRef: this.workspaces.getReference(workspaceId),
                    };
                }
                return null;
            }),
        );

    /**
     * Pops collection
     * @param workspaceId The workspace id
     * @private
     *
     * @returns The collection
     */
    private pops(workspaceId: string): NirbyCollection<Pop> {
        return this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(workspaceId),
            COLLECTION_KEYS.POPS,
        );
    }

    /**
     * Primes collection
     * @param workspaceId The workspace id
     * @private
     *
     * @returns The collection
     */
    private primes(workspaceId: string): NirbyCollection<Prime> {
        return this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(workspaceId),
            COLLECTION_KEYS.PRIMES,
        );
    }

    /**
     * Watches the directories of the current workspace.
     * @private
     *
     * @returns An observable of the directories.
     */
    private getWorkspaceDirectories$(): Observable<NirbyDocument<Directory>[]> {
        return this.workspaces.workspaceId$.pipe(
            switchMap((workspaceId) =>
                this.directories.collection(workspaceId).query().watch(),
            ),
        );
    }

    /**
     * Lists the products of the current workspace grouped by type.
     */
    get workspaceProducts$(): Observable<ProductListing> {
        return this.workspaces.workspaceId$.pipe(
            switchMap((workspaceId) =>
                combineLatest([
                    this.primes(workspaceId).query().get(),
                    this.pops(workspaceId).query().get(),
                ]).pipe(
                    map(([primes, pops]) => ({
                        pop: pops,
                        prime: primes,
                    })),
                ),
            ),
        );
    }

    /**
     * Gets all the products grouped by directory or by type.
     * @param byDirectory If true, the products will be grouped by directory.
     * @param allowedTypes The product types to be included.
     *
     * @returns An observable of the products grouped by directory or by type.
     */
    getWorkspaceProductsDisplayGrouped$(
        byDirectory = true,
        allowedTypes: NirbyProductType[] = ['pop', 'prime'],
    ): Observable<SelectItemGroup[]> {
        return combineLatest([
            this.workspaceProducts$,
            byDirectory
                ? this.getWorkspaceDirectories$().pipe(
                    map(
                        (directories): Map<string, Directory> =>
                            new Map(
                                directories.map((directory) => [
                                    directory.id,
                                    directory.data,
                                ]),
                            ),
                    ),
                )
                : of(null),
        ]).pipe(
            map(([products, directories]): SelectItemGroup[] => {
                if (!directories) {
                    const primes: SelectItem<ProductId>[] = products.prime.map(
                        (prime) => ({
                            label: prime.data.name,
                            value: new ProductId(prime.id, 'prime'),
                        }),
                    );
                    const pops: SelectItem<ProductId>[] = products.pop.map(
                        (pop) => ({
                            label: pop.data.title,
                            value: new ProductId(pop.id, 'pop'),
                        }),
                    );
                    return [
                        {
                            label: 'Prime',
                            items: primes,
                        },
                        {
                            label: 'Pop',
                            items: pops,
                        },
                    ];
                }
                const map = groupByIter(
                    [
                        ...(allowedTypes.includes('prime')
                            ? products.prime.map((prime) => ({
                                label: prime.data.name,
                                value: new ProductId(prime.id, 'prime'),
                                directoryId: prime.data.parent?.id ?? null,
                            }))
                            : []),
                        ...(allowedTypes.includes('pop')
                            ? products.pop.map((pop) => ({
                                label: pop.data.title,
                                value: new ProductId(pop.id, 'pop'),
                                directoryId:
                                    pop.data.directory?.ref.id ?? null,
                            }))
                            : []),
                    ],
                    (item) => item.directoryId,
                );
                return Array.from(
                    mapIter(
                        map.entries(),
                        ([directoryId, items]): SelectItemGroup => {
                            const directory = directoryId
                                ? directories.get(directoryId) ?? null
                                : null;
                            return {
                                label: directory?.name ?? 'No directory',
                                items,
                            };
                        },
                    ),
                ).sort((a, b) => a.label.localeCompare(b.label));
            }),
        );
    }

    /**
     * Constructor.
     * @param db The angular firestore service.
     * @param params The route parameters service.
     * @param workspaces The workspace service.
     * @param directories The directories service.
     */
    constructor(
        private readonly db: Database,
        private readonly params: RouteParametersService,
        private readonly workspaces: WorkspaceService,
        private readonly directories: DirectoriesService,
    ) {
    }

    /**
     * Returns the current product's reference
     */
    public get productRef(): ProductReference | null {
        const workspaceId = this.workspaces.workspaceId;
        const popId = this.params.getParam('popId');
        const primeId = this.params.getParam('primeId');
        if (popId) {
            return {
                type: 'pop',
                ref: this.pops(workspaceId).ref(popId),
                workspaceRef: this.workspaces.getReference(workspaceId),
            };
        }
        if (primeId) {
            return {
                type: 'prime',
                ref: this.primes(workspaceId).ref(primeId),
                workspaceRef: this.workspaces.getReference(workspaceId),
            };
        }
        return null;
    }
}
