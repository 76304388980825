import {
    ComponentFactoryResolver,
    Directive,
    EventEmitter,
    forwardRef,
    Input,
    OnDestroy,
    Output,
    ViewContainerRef,
} from '@angular/core';
import {HostDirective} from './host.directive';
import {BehaviorSubject, Subscription} from 'rxjs';
import {BlockActionComponent} from '../../blocks/block-action.component';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {LoggerService} from '@nirby/ngutils';
import {BlockActionRegisterService} from '../../../services/registers/block-action-register.service';
import {AnyCardAction, AnyCardActionType} from '@nirby/models/nirby-player';
import {AnyPrimeSource, AppCard} from '@nirby/models/editor';
import {v4} from 'uuid';
import {NirbyDocument} from '@nirby/store/base';

@Directive({
    selector: '[nirbyBlockActionHost]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BlockActionHostDirective),
            multi: true,
        },
    ],
})
/**
 * Directive that injects an action form into the host.
 */
export class BlockActionHostDirective
    extends HostDirective<AnyCardActionType, AnyCardAction>
    implements ControlValueAccessor, OnDestroy {
    private readonly cardSiblingsSubject = new BehaviorSubject<
        NirbyDocument<AppCard>[]
    >([]);
    private readonly availableVideosSubject = new BehaviorSubject<
        NirbyDocument<AnyPrimeSource>[]
    >([]);

    /**
     * Cards where this card con go to
     * @param value - Cards where this card con go to
     */
    @Input() set cardSiblings(value: NirbyDocument<AppCard>[]) {
        this.cardSiblingsSubject.next(value);
    }

    /**
     * Videos where this card con go to
     * @param value - Videos where this card con go to
     */
    @Input() set availableVideos(value: NirbyDocument<AnyPrimeSource>[]) {
        this.availableVideosSubject.next(value);
    }

    @Output() closeRequest = new EventEmitter<void>();

    /**
     * Constructor.
     * @param viewContainerRef - View container reference.
     * @param componentFactoryResolver - Component factory resolver.
     * @param register - Block action register. Which contains all the available action forms.
     */
    constructor(
        viewContainerRef: ViewContainerRef,
        componentFactoryResolver: ComponentFactoryResolver,
        register: BlockActionRegisterService,
    ) {
        super(viewContainerRef, componentFactoryResolver, register);
    }

    subscriptions = new Subscription();

    private id: string | null = null;
    private lastType: AnyCardActionType | null = null;

    onChange: (action: AnyCardAction) => void = () => {
        return;
    };
    onTouched = () => {
        return;
    };

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * Write a new action to the model.
     * @param obj - Action to write.
     */
    writeValue(obj?: AnyCardAction): void {
        if (!obj) {
            return;
        }

        if (obj.type === this.lastType) {
            return;
        }

        const component = this.host(obj.type) as BlockActionComponent<AnyCardAction>;
        LoggerService.logStyled(
            'ACTION-INPUT',
            'Set host component to',
            component,
        );

        this.id = obj.id;
        this.lastType = obj.type;

        if (!component) {
            return;
        }
        this.subscriptions.unsubscribe();
        this.subscriptions = new Subscription();
        this.subscriptions.add(
            component.dataChange.subscribe((d) => this.onChange(d)),
        );
        this.subscriptions.add(
            component.closeRequest.subscribe(() => this.closeRequest.emit()),
        );
        LoggerService.logStyled(
            'ACTION-INPUT',
            'Filled host with action',
            obj.type,
            obj.options,
        );
        component.fill_(obj);
    }

    /**
     * Register a callback function that is called when the value has changed.
     * @param fn - Callback function.
     */
    registerOnChange(fn: (action: AnyCardAction) => void): void {
        this.onChange = action => fn({
            ...action,
            id: action.id ?? this.id ?? v4(),
        });
    }

    /**
     * Register a callback function that is called when the control has been touched.
     * @param fn - Callback function.
     */
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
}
