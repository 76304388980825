import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DateRangeInputComponent} from './date-range-input/date-range-input.component';
import {CalendarModule} from 'primeng/calendar';
import {FormsModule} from '@angular/forms';


@NgModule({
    declarations: [
        DateRangeInputComponent
    ],
    imports: [
        CommonModule,
        CalendarModule,
        FormsModule
    ],
    exports: [
        DateRangeInputComponent
    ]
})
/**
 * Module for the Date Range input module.
 */
export class DateRangeInputModule {
}

export {DateTimeRange, DateRange} from './date-range-input/date-range-input.component';
