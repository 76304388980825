import {Component} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {DirectoriesService, WorkspaceService} from '@nirby/shared/database';
import {Directory} from '@nirby/models/editor';
import {Logger} from '@nirby/logger';

@Component({
    selector: 'nirby-modal-directories',
    templateUrl: './modal-directories.component.html',
    styleUrls: ['./modal-directories.component.scss'],
})
/**
 * Form to create a new directory
 */
export class ModalDirectoriesComponent {
    /**
     * Constructor.
     * @param workspaces Workspace service
     * @param directories Directories service
     * @param modal Modal service
     */
    constructor(
        private workspaces: WorkspaceService,
        private directories: DirectoriesService,
        public modal: NgbModal,
    ) {
    }

    directory = new UntypedFormControl('', Validators.required);

    newDirectoryForm = new FormGroup({
        directory: this.directory,
    });

    directoryName = '';

    /**
     * The current workspace ID.
     */
    public get widgetId(): string {
        return this.workspaces.workspaceId;
    }

    loading = false;

    /**
     * Creates a new directory from the form.
     *
     * @returns - A promise that resolves when the directory is created and the modal is dismissed.
     */
    async addDirectory(): Promise<void> {
        this.loading = true;
        const newDirectory: Directory = {
            name: this.directory.value,
            color: null,
        };

        try {
            await this.directories.collection(this.widgetId).add(newDirectory);
            if (newDirectory) {
                this.modal.dismissAll();
            }
        } catch (e) {
            Logger.error(e);
        } finally {
            this.loading = false;
        }
    }
}
