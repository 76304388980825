import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {Logger} from '@nirby/logger';

Logger.setUp(environment.production, ['PRIME', 'INPUT', 'CONTEXT', 'ROUTER', 'DB', 'BLOCK-EDITOR-STORE'], {
    PRIME: ['#ffffff', '#3428e0'],
    CONTEXT: ['#ffffff', '#654110'],
    ROUTER: ['#ffffff', '#3c92d9'],
});

// eslint-disable-next-line
(window as any).LOGGER = Logger;

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));

