import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoRecordComponent } from './video-record/video-record.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

@NgModule({
    imports: [CommonModule, FontAwesomeModule],
    declarations: [
        VideoRecordComponent
    ],
    exports: [
        VideoRecordComponent
    ]
})
export class MediaVideoRecordModule {}
