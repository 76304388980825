import {Component, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
    faChevronLeft,
    faCloudUploadAlt,
    faCog,
    faFolder,
    faHouse,
    faLineChart,
    faLink,
    faPeopleArrows,
    faQuestionCircle,
    faShareSquare,
    faSignOutAlt,
    faTint,
    faVideo,
} from '@fortawesome/free-solid-svg-icons';
import {Observable} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
    AuthenticationService,
    PublishedPopsService,
    RouteParametersService,
    WorkspaceService,
} from '@nirby/shared/database';
import {Workspace} from '@nirby/models/editor';
import {AlertsService} from '@nirby/shared/alerts';
import {BillingService} from '../../services/billing.service';
import {NirbyDocument} from '@nirby/store/base';

@Component({
    selector: 'nirby-toolbar',
    templateUrl: './toolbar.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./toolbar.component.scss'],
})
/**
 * Top Toolbar of the application
 */
export class ToolbarComponent {
    /**
     * Creates an instance of ToolbarComponent.
     * @param auth Authentication Service
     * @param toasts Alert Service
     * @param publisher Published Pops Service
     * @param service Workspace Service
     * @param route Activated Route
     * @param router Router
     * @param routeParams Route Parameters Service
     * @param modal Modal Service
     * @param billing Billing Service
     */
    constructor(
        private readonly auth: AuthenticationService,
        private readonly toasts: AlertsService,
        private readonly publisher: PublishedPopsService,
        private readonly service: WorkspaceService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly routeParams: RouteParametersService,
        private readonly modal: NgbModal,
        public readonly billing: BillingService,
    ) {
    }

    /**
     * Checks if the back button should be displayed
     */
    get shouldShowBackButton(): boolean {
        if (!this.route.firstChild?.snapshot) {
            return false;
        }
        return !!this.route.firstChild.snapshot.data['backCommands'];
    }

    questionIcon = faQuestionCircle;
    shareIcon = faShareSquare;
    signOutIcon = faSignOutAlt;
    settingsIcon = faCog;
    publishIcon = faCloudUploadAlt;

    iconDashboard = faHouse;
    iconPops = faFolder;
    iconContacts = faPeopleArrows;
    iconRoutes = faLink;
    iconDroplets = faTint;
    iconPrimes = faVideo;
    iconAnalytics = faLineChart;

    widget$: Observable<NirbyDocument<Workspace> | null> = this.service.activeWidget$;
    loading = false;
    icons = {
        back: faChevronLeft,
    };

    /**
     * Workspace ID
     */
    get workspaceId(): string {
        return this.routeParams.workspaceId;
    }

    /**
     * Signs out the user
     */
    async signOut(): Promise<void> {
        await this.auth.signOut();
    }

    /**
     * Goes back to the previous route
     *
     * @returns If the route was changed
     */
    back(): Promise<boolean> {
        if (!this.route.firstChild?.snapshot) {
            return new Promise<boolean>((res) => res(false));
        }
        return this.router.navigate(
            this.route.firstChild.snapshot.data['backCommands'],
            {relativeTo: this.route},
        );
    }
}
