import {SingleLevelBoardStore} from './board-store';
import {Block} from '../blocks';
import {NirbyBoardStateNode} from '../art-board-item-factory';

/**
 * Utilities to manage the state of a Block.
 */
export class BoardBlockStore<TMeta, TBlock extends Block<TMeta>> {
    constructor(
        public readonly store: SingleLevelBoardStore<TMeta, TBlock>,
    ) {
    }

    public get value(): NirbyBoardStateNode<TMeta, TBlock> {
        return this.store.value;
    }

    set content(value: TBlock['properties']['content']) {
        const block = this.store.properties;
        this.store.update({
            ...block,
            content: value,
        } as TBlock['properties']);
    }

    set style(value: TBlock['properties']['style']) {
        const block = this.store.properties;
        this.store.update({
            ...block,
            style: value,
        } as TBlock['properties']);
    }
}
