import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VariableSelectorComponent } from './variable-selector/variable-selector.component';
import {DropdownFilterModule} from '@nirby/shared/widgets';
import {FormsModule} from '@angular/forms';

@NgModule({
    imports: [CommonModule, DropdownFilterModule, FormsModule],
    declarations: [
        VariableSelectorComponent
    ],
    exports: [
        VariableSelectorComponent
    ]
})
export class VariableSelectorModule {}
