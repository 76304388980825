import {z} from 'zod';
import {ZodSchemaBuild} from '@nirby/store/models';

export type VerticalTextAlignment = 'bottom' | 'middle' | 'top';
export const VERTICAL_TEXT_ALIGNMENT_SCHEMA: ZodSchemaBuild<VerticalTextAlignment> =
    z.union([z.literal('bottom'), z.literal('middle'), z.literal('top')]);

export const TEXT_ALIGNMENT_SCHEMA = z.union([
    z.literal('center'),
    z.literal('left'),
    z.literal('right'),
    z.literal('justify'),
]);
export type TextAlignment = z.infer<typeof TEXT_ALIGNMENT_SCHEMA>;

export const FONT_STYLE_SCHEMA = z.union([
    z.literal('italic'),
    z.literal('bold'),
    z.literal('normal'),
]);
export type FontStyle = z.infer<typeof FONT_STYLE_SCHEMA>;

export const FONT_DECORATION_SCHEMA = z.union([
    z.literal('underline'),
    z.literal('line-through'),
    z.literal(''),
]);
export type FontDecoration = z.infer<typeof FONT_DECORATION_SCHEMA>;
