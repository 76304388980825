import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FileDropService} from '@nirby/shared/file-drop';
import {BehaviorSubject, combineLatest, Observable, of, Subject, Subscription, switchMap} from 'rxjs';
import {WorkspaceService} from '@nirby/shared/database';
import {VideoRecord} from '@nirby/media-models';
import {UploadStatus, VideoUploadCloudflareService, VideoUploadService} from '@nirby/media/video-factory';
import {LoadScreenService} from '@nirby/shared/load-screen';
import {AlertsService} from '@nirby/shared/alerts';

interface TusError {
    originalRequest: Request;
    originalResponse: {
        getBody(): unknown;
    };
    causingError: Error;
}

interface TusErrorDetails {
    code: string;
    error: string;
}

@Component({
    selector: 'nirby-video-upload-target',
    templateUrl: './video-upload-target.component.html',
    styleUrls: ['./video-upload-target.component.scss'],
})
/**
 * A component that allows to upload videos to a workspace.
 */
export class VideoUploadTargetComponent implements OnInit, OnDestroy {
    @Output() selectVideo = new EventEmitter<VideoRecord>();

    private readonly startSignal = new Subject<void>();

    /**
     * Watches the input element.
     * @param value The input element.
     */
    @ViewChild('fileInput', {static: true})
    public set fileInput(value: ElementRef<HTMLInputElement> | null) {
        this.fileInputSubject.next(value?.nativeElement ?? null);
    }

    /**
     * The file input element.
     */
    public get input(): HTMLInputElement | null {
        return this.fileInputSubject.value;
    }

    private readonly fileInputSubject = new BehaviorSubject<HTMLInputElement | null>(null);

    /**
     * Constructor.
     * @param workspaces The workspace service.
     * @param file The file drop service.
     * @param videoUpload The video upload service.
     * @param loader The load screen service.
     * @param alerts The alerts service.
     */
    constructor(
        private readonly workspaces: WorkspaceService,
        private readonly file: FileDropService,
        private readonly videoUpload: VideoUploadCloudflareService,
        private readonly loader: LoadScreenService,
        private readonly alerts: AlertsService,
    ) {
    }

    private readonly subscription = new Subscription();
    public status$: Observable<UploadStatus> = of('idle');

    progress$ = of(0);

    /**
     * Subscribes to dropped files to upload them.
     */
    ngOnInit(): void {
        this.subscription.add(
            combineLatest([
                this.watchDroppedFiles(),
                this.workspaces.workspaceId$,
            ])
                .subscribe(async ([files, workspaceId]) => {
                    if (!files || files.length === 0) {
                        return;
                    }
                    const file = files[0];
                    const upload = await this.videoUpload.uploadFile(workspaceId, file);
                    this.status$ = upload.status$;
                    upload.start();
                    this.progress$ = upload.progress$;
                    const video = await this.loader.untilCompletion(
                        () => this.videoUpload.waitAndRecordUpload(upload, file.name),
                        'block',
                        'Uploading video...'
                    ).catch(async err => {
                        const tusError = {...err} as TusError;
                        const body = tusError.originalResponse.getBody();
                        if (typeof body === 'string') {
                            const errorDetails: TusErrorDetails = JSON.parse(body);
                            this.alerts.showDanger(errorDetails.error);
                        } else {
                            this.alerts.showDanger('Video upload failed');
                        }
                        return null;
                    })
                        .catch(() => null);
                    if (video) {
                        this.selectVideo.emit(video);
                        this.startSignal.next();
                    }
                    this.progress$ = of(0);
                })
        );
    }

    /**
     * Unsubscribes from all subscriptions.
     */
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Watches for dropped files.
     *
     * @returns An observable that emits the dropped files.
     */
    watchDroppedFiles(): Observable<File[]> {
        return this.fileInputSubject.pipe(
            switchMap((input) => {
                return this.file.watch(
                    'Upload video',
                    VideoUploadService.UPLOADABLE_VIDEO_TYPES,
                    input ?? undefined
                );
            })
        );
    }
}
