import {FormGroup} from '@angular/forms';
import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {Logger} from '@nirby/logger';
import {Hostable} from '../../services/hostable';
import {ActionOptionsOf, GenericAction} from '@nirby/models/nirby-player';
import {Form} from '@nirby/shared/widgets';

@Component({
    template: '',
})
/**
 * Base component for the form to edit an action's options.
 */
export abstract class BlockActionComponent<
        T extends GenericAction = GenericAction
    >
    extends Hostable<T>
    implements OnInit, OnDestroy
{
    abstract form: FormGroup;

    private formSubscription = new Subscription();
    public readonly closeRequest = new EventEmitter<void>();

    /**
     * Subscribes to action form changes.
     */
    ngOnInit(): void {
        this.formSubscription.add(
            Form.watch<object>(this.form, true)
                .pipe(filter(() => this.form.valid))
                .subscribe((value) => {
                    Logger.logStyled('ACTION-INPUT', 'Updated action', value);
                    this.sendOptions({
                        ...value,
                    } as ActionOptionsOf<T>);
                }),
        );
    }

    /**
     * Destroys the component.
     */
    ngOnDestroy(): void {
        this.formSubscription.unsubscribe();
    }

    /**
     * Sends the action options to the parent component.
     * @param options The action options.
     * @private
     */
    private sendOptions(options: ActionOptionsOf<T>): void {
        const lastData = this.lastSetData;
        if (lastData) {
            this.sendData({
                type: lastData.type,
                options,
            } as T);
        }
    }
}
