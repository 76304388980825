import {Inject, Injectable} from '@angular/core';
import {RouteParametersService} from '../route-parameters.service';
import {distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {WorkspaceService} from './workspace.service';
import {Observable} from 'rxjs';
import {Database, DatabaseConfig, DB_CONFIG_TOKEN} from '@nirby/ngutils';
import {COLLECTION_KEYS} from '@nirby/store/collections';
import {NirbyCollection, NirbyDocument} from '@nirby/store/base';
import {Prime} from '@nirby/models/editor';
import {HttpClient} from '@angular/common/http';
import {PopperDesign} from '@nirby/js-utils/embed';

@Injectable({
    providedIn: 'root',
})
/**
 * Primes service.
 */
export class PrimesService {
    /**
     * Gets a Prime collection for a workspace
     * @param workspaceId Workspace ID
     *
     * @returns - The Prime collection
     */
    public collection(workspaceId: string): NirbyCollection<Prime> {
        return this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(workspaceId),
            COLLECTION_KEYS.PRIMES,
        );
    }

    /**
     * Constructor.
     * @param widgets Widgets service.
     * @param routeParams Route parameters service.
     * @param db Firestore service.
     * @param config Database config
     * @param http The HTTP client
     */
    constructor(
        private widgets: WorkspaceService,
        private routeParams: RouteParametersService,
        public readonly db: Database,
        @Inject(DB_CONFIG_TOKEN) private readonly config: DatabaseConfig,
        private readonly http: HttpClient,
    ) {
    }

    /**
     * Prime reference
     */
    get primeRef$(): Observable<[string, string] | null> {
        return this.routeParams.watchMany(['widgetId', 'primeId']).pipe(
            map((s): [string, string] | null => {
                return s.length === 2 &&
                typeof s[0] === 'string' &&
                typeof s[1] === 'string'
                    ? (s as [string, string])
                    : null;
            }),
        );
    }

    /**
     * Watches the Primes available in the current workspace.
     */
    get workspacePrime$(): Observable<NirbyDocument<Prime>[]> {
        return this.routeParams.widgetId$.pipe(
            distinctUntilChanged(),
            switchMap((widgetId) =>
                this.collection(widgetId).query().orderBy('name').watch(),
            ),
        );
    }

    /**
     * Prime ID
     */
    get primeId(): string {
        return this.routeParams.primeId;
    }

    /**
     * Builds a shareable link for the current prime as a Popover
     * @param workspaceId Workspace ID
     * @param primeId Prime ID
     * @param body The body of the Popper
     * @param buttonLabel The label of the button
     * @param backgroundImage The background image of the Popper
     * @param marginX The horizontal margin of the Popper
     * @param marginY The vertical margin of the Popper
     * @param design The design of the Popper
     * @param locale The locale of the Popper
     *
     * @returns - The shareable link
     */
    public buildShareableLinkPopover(
        workspaceId: string,
        primeId: string,
        body: string,
        buttonLabel: string,
        backgroundImage: string,
        marginX: number,
        marginY: number,
        design: PopperDesign,
        locale?: string,
    ): Observable<string> {
        const script = this.http.get(
            '/assets/scripts/prime-popover-script.txt',
            {responseType: 'text'},
        );
        return script.pipe(
            map((s) =>
                s
                    .replace('{{WORKSPACE_ID}}', workspaceId)
                    .replace('{{PRIME_ID}}', primeId)
                    .replace('{{BODY}}', body)
                    .replace('{{BUTTON_LABEL}}', buttonLabel)
                    .replace(
                        '{{BACKGROUND_IMAGE}}',
                        backgroundImage ? `'${backgroundImage}'` : 'undefined',
                    )
                    .replace('{{MARGIN_X}}', marginX.toString())
                    .replace('{{MARGIN_Y}}', marginY.toString())
                    .replace('{{DESIGN}}', design)
                    .replace('{{LOCALE}}', locale ? `'${locale}'` : 'undefined'),
            ),
        );
    }
}
