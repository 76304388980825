export type Pair<TA, TB> = [TA, TB];

export type PartialRecord<V> = {
    [K in string]?: V
};

export function fromEntries<TV>(entries: [string, TV][]): {
    [key: string]: TV;
} {
    const obj: { [key: string]: TV } = {};

    let k: string;
    let v: TV;
    for ([k, v] of entries) {
        obj[k] = v;
    }
    return obj;
}

export function toEntries<TK extends string, TV>(
    value: {
        [key: string]: TV
    }
): Pair<TK, TV>[] {
    return Object.entries(value) as Pair<TK, TV>[];
}
