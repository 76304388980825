import { Component, forwardRef, OnInit } from '@angular/core';
import { SelectorBoxComponent } from '../selector-box/selector-box.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectorToggleComponent } from '../selector-toggle/selector-toggle.component';

@Component({
    selector: 'nirby-selector-box-tag',
    templateUrl: './selector-toggle-tag.component.html',
    styleUrls: ['./selector-toggle-tag.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectorBoxComponent),
            multi: true,
        },
    ],
})
export class SelectorToggleTagComponent<
    T = unknown
> extends SelectorToggleComponent<T> {}
