import { ControlValueAccessor } from '@angular/forms';
import {ConditionalSimple} from '@nirby/conditionals';

export abstract class ConditionalEditorBaseComponent<TConditional extends ConditionalSimple> implements ControlValueAccessor {
    #disabled = false;

    get disabled(): boolean {
        return this.#disabled;
    }

    abstract getDefaultValue(): TConditional;
    abstract fillForm(value: TConditional): void;
    abstract getCurrentValue(): TConditional;

    onChange: (v: TConditional) => void = () => {
        return;
    }

    onTouched: () => void = () => {
        return;
    }

    registerOnChange(fn: (v: TConditional) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.#disabled = isDisabled;
    }

    writeValue(obj: TConditional | undefined | null): void {
        this.fillForm(obj ?? this.getDefaultValue());
    }

    emitChange(): void {
        this.onChange(this.getCurrentValue());
    }
}
