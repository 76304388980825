import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AlertComponent} from './alert/alert.component';
import {DividerModule} from 'primeng/divider';


@NgModule({
    declarations: [
        AlertComponent
    ],
    imports: [
        CommonModule,
        DividerModule
    ],
    exports: [
        AlertComponent
    ]
})
/**
 * Module for alerts
 */
export class NirbyAlertModule {
}
