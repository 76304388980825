import {Injectable} from '@angular/core';
import {ConfirmationService, Message, MessageService} from 'primeng/api';

export class ToastBuilder implements Message {
    public static new() {
        return new ToastBuilder();
    }

    private constructor(
        public readonly severity?: string,
        public readonly summary?: string,
        public readonly detail?: string,
        public readonly life?: number,
    ) {
    }

    withSeverity(severity: string): ToastBuilder {
        return new ToastBuilder(severity, this.summary, this.detail);
    }

    withTitle(title: string): ToastBuilder {
        return new ToastBuilder(this.severity, title, this.detail);
    }

    withDetail(detail: string): ToastBuilder {
        return new ToastBuilder(this.severity, this.summary, detail);
    }

    withDelay(life: number): ToastBuilder {
        return new ToastBuilder(this.severity, this.summary, this.detail, life);
    }
}

@Injectable({
    providedIn: 'root',
})
export class AlertsService {
    constructor(
        private readonly confirmation: ConfirmationService,
        private readonly messages: MessageService,
    ) {}

    show(toast: Message): void {
        this.messages.add(toast);
    }

    /**
     * Shows an error toast
     * @param text Text to show
     * @param delay Delay in ms
     *
     * @returns - void
     */
    error(text: string, delay = 8000): void {
        return this.show(
            ToastBuilder.new()
                .withTitle(text)
                .withSeverity('error')
                .withDelay(delay)
        );
    }

    /**
     * Shows an error toast
     * @deprecated Use {@link error} instead
     * @param text Text to show
     * @param delay Delay in ms
     *
     * @returns - void
     */
    showDanger(text: string, delay = 8000): void {
        return this.error(text, delay);
    }

    /**
     * Shows a success toast
     * @param text Text to show
     * @param delay Delay in ms
     *
     * @returns - void
     */
    success(text: string, delay = 8000): void {
        return this.show(
            ToastBuilder.new()
                .withTitle(text)
                .withSeverity('success')
                .withDelay(delay)
        );
    }

    /**
     * Shows a success toast
     * @deprecated Use {@link success} instead
     * @param text Text to show
     * @param delay Delay in ms
     *
     * @returns - void
     */
    showSuccess(text: string, delay = 8000): void {
        return this.success(text, delay);
    }

    showWarning(text: string, delay = 8000): void {
        return this.show(
            ToastBuilder.new()
                .withTitle(text)
                .withSeverity('warning')
                .withDelay(delay)
        );
    }

    async askConfirmation(
        title: string,
        body: string,
        confirmLabel = 'Confirm',
        target?: EventTarget | null
    ): Promise<boolean> {
        return new Promise((resolve) => {
            this.confirmation.confirm({
                message: body,
                header: title,
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: confirmLabel,
                rejectLabel: 'Cancel',
                accept: () => {
                    resolve(true);
                },
                reject: () => {
                    resolve(false);
                },
                ...(target ? { target } : {}),
            });
        });
    }
}

/**
 * @deprecated Use {@link AlertsService} instead
 */
export const AlertService = AlertsService;
