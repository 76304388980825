<div
    class="component-container"
    [ngClass]="{
        'use-modal': endConfig.display.useModal,
        'has-image-true': image,
        'has-image-false': !image
    }"
>
    <div *ngIf="endConfig.display.showPreview" class="preview">
        <span class="placeholder" *ngIf="!image"></span>
        <nirby-image-record-view
            *ngIf="image"
            [image]="image"
        ></nirby-image-record-view>
    </div>

    <div class="actions" *ngIf="endConfig.display.useModal">
        <button
            [disabled]="disabled"
            class="btn btn-outline-light btn-sm d-inline-flex justify-content-between align-items-center"
            (click)="openSelectModal(selectorModal); $event.preventDefault()"
        >
            <span class="text">
                {{ image ? 'Change' : 'Select Image' }}
            </span>
            <fa-icon class="icon ms-2" [icon]="iconCloudUpload"></fa-icon>
        </button>

        <button
            [disabled]="disabled"
            class="btn btn-outline-light btn-sm ms-2 d-inline-flex justify-content-between align-items-center"
            *ngIf="endConfig.display.showClearButton && image"
            (click)="clear(); $event.preventDefault()"
        >
            <span class="text"> Clear </span>
            <fa-icon class="icon ms-2" [icon]="iconTrash"></fa-icon>
        </button>
    </div>

    <div *ngIf="!endConfig.display.useModal">
        <ng-container *ngTemplateOutlet="selector"></ng-container>
    </div>
</div>

<ng-template #selector>
    <ul ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false">
        <li ngbNavItem [hidden]="!endConfig.sources.search">
            <a ngbNavLink>Search</a>
            <ng-template ngbNavContent>
                <nirby-image-search
                    (selectImage)="onImageSelect($event)"
                ></nirby-image-search>
            </ng-template>
        </li>
        <li ngbNavItem [hidden]="true || !endConfig.sources.favorites">
            <a ngbNavLink>Favorites</a>
            <ng-template ngbNavContent> Favorites </ng-template>
        </li>
        <li ngbNavItem [hidden]="!endConfig.sources.library">
            <a ngbNavLink>Media Library</a>
            <ng-template ngbNavContent>
                <nirby-media-library-images
                    (picked)="onImageSelect($event)"
                ></nirby-media-library-images>
            </ng-template>
        </li>
        <li ngbNavItem [hidden]="!endConfig.sources.upload">
            <a ngbNavLink>Upload</a>
            <ng-template ngbNavContent>
                <nirby-image-upload
                    (uploadImage)="onImageSelect($event)"
                ></nirby-image-upload>
            </ng-template>
        </li>
        <li ngbNavItem [hidden]="!endConfig.sources.url">
            <a ngbNavLink>URL</a>
            <ng-template ngbNavContent>
                <nirby-image-url-input
                    (imageURLChange)="onURLSelect($event)"
                ></nirby-image-url-input>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
</ng-template>

<ng-template #selectorModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Select Image</h4>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-container *ngTemplateOutlet="selector"></ng-container>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-outline-dark"
            (click)="modal.close()"
        >
            Close
        </button>
    </div>
</ng-template>
