<p-calendar
    selectionMode="range"
    [styleClass]="styleClass"
    [ngModel]="value"
    (ngModelChange)="onValueChange($event)"
    [minDate]="minDateValue"
    [maxDate]="maxDateValue"
    [showButtonBar]="true"
    dateFormat="dd MM yy"
    appendTo="body"
></p-calendar>
