import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree,} from '@angular/router';
import {AuthenticationService} from '@nirby/shared/database';

@Injectable({
    providedIn: 'root',
})
export class IsUnauthenticatedGuard implements CanActivate {
    constructor(private auth: AuthenticationService, private router: Router) {}
    async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean | UrlTree> {
        const currentUser = await this.auth.getStoredAuthenticatedUser();
        if (currentUser) {
            return this.router.createUrlTree(
                await this.auth.getUserHomeCommands(currentUser)
            );
        }
        return true;
    }
}
