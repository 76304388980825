<ul ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="true">
    <li ngbNavItem *ngIf="allowedSource.includes('youtube')">
        <a ngbNavLink>YouTube</a>
        <ng-template ngbNavContent>
            <nirby-video-youtube-url
                (selectVideo)="onVideoSelect($event)"
            ></nirby-video-youtube-url>
        </ng-template>
    </li>
    <li ngbNavItem *ngIf="allowedSource.includes('vimeo')">
        <a ngbNavLink>Vimeo</a>
        <ng-template ngbNavContent>
            <nirby-video-vimeo-url
                (selectVideo)="onVideoSelect($event)"
            ></nirby-video-vimeo-url>
        </ng-template>
    </li>
    <li ngbNavItem *ngIf="allowedSource.includes('cloudflare-stream')">
        <a ngbNavLink>Upload</a>
        <ng-template ngbNavContent>
            <nirby-video-upload-target
                (selectVideo)="onVideoSelect($event)"
            ></nirby-video-upload-target>
        </ng-template>
    </li>
    <li ngbNavItem *ngIf="allowedSource.includes('cloudflare-stream')">
        <a ngbNavLink>Record</a>
        <ng-template ngbNavContent>
            <nirby-video-record
                (selectVideo)="onVideoSelect($event)"
            ></nirby-video-record>
        </ng-template>
    </li>
</ul>
<div [ngbNavOutlet]="nav"></div>
