import {Injectable} from '@angular/core';
import {FirestoreService} from '@nirby/ngutils';
import {map, Observable} from 'rxjs';
import {CloudFlareStreamVideo} from '@nirby/media-models';
import {COLLECTION_KEYS} from '@nirby/store/collections';

@Injectable({
    providedIn: 'root',
})
/**
 * Service to access cloudflare-stream-videos document in firebase
 */
export class VideosService {
    public collection = this.firestore.collection(COLLECTION_KEYS.CF_STREAM_VIDEOS);

    /**
     * Constructor.
     * @param firestore The Firestore service.
     */
    constructor(
        private readonly firestore: FirestoreService,
    ) {
    }

    /**
     * Watches the current status of a video.
     * @param id The ID of the video.
     *
     * @returns An observable that emits the current status of the video.
     */
    public watchStatus(id: string): Observable<CloudFlareStreamVideo['status'] | null> {
        return this.collection.get(id).pipe(
            map(video => video?.data?.status ?? null),
        );
    }
}
