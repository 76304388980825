<div class="container">
    <div class="nirby-card p-0 row">
        <div class="col-md-3 p-0">
            <nirby-routes-panel></nirby-routes-panel>
        </div>
        <div class="col-md-9 p-0">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
