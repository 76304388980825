import {Component, Input} from '@angular/core';
import {faClipboard} from '@fortawesome/free-solid-svg-icons';
import {AlertsService} from '@nirby/shared/alerts';

@Component({
    selector: 'nirby-clipboard-input',
    templateUrl: './clipboard-input.component.html',
    styleUrls: ['./clipboard-input.component.scss'],
})
/**
 * Component for an input with a button to copy the input value to the clipboard.
 */
export class ClipboardInputComponent {
    @Input() value = '';
    @Input() label: string | null = null;

    icon = faClipboard;

    /**
     * Constructor.
     * @param alerts Alert service.
     */
    constructor(private alerts: AlertsService) {
    }

    /**
     * Copies the input value to the clipboard.
     * @param input Input element.
     */
    copy(input: HTMLInputElement): void {
        input.select();
        document.execCommand('copy');
        input.setSelectionRange(0, 0);
        this.alerts.showSuccess('Copied to clipboard');
    }
}
