import {DateTime} from 'luxon';
import {BehaviorSubject, Observable} from 'rxjs';
import {NirbyCollectionReference, NirbyDocumentReference} from './reference';
import {NirbyCollection} from './collection';
import {DocumentLike, Modeled, Uploaded} from '@nirby/store/models';
import {MigratorLike} from '@nirby/store/migrator';

type SerializableField = string | number | boolean | null;

interface SerializableObject {
    [key: string]:
        | SerializableField
        | SerializableObject
        | SerializableObject[];
}

/**
 * Representation of a document in the database with its data and metadata.
 */
export abstract class NirbyDocument<T extends object>
    implements DocumentLike<T> {
    protected readonly dataSubject: BehaviorSubject<Readonly<T>>;

    /**
     * Data of this document
     */
    get data(): T {
        return {
            ...this.dataSubject.value,
        };
    }

    /**
     * Raw data of this document
     */
    get raw(): Uploaded<T> {
        return {
            ...this.dataSubject.value,
            _databaseVersion: this.schemaVersion,
            _creationTime: this.creationTime,
            _lastUpdate: this.updateTime,
        };
    }

    /**
     * Observable to the data of this document
     */
    get data$(): Observable<T> {
        return this.dataSubject.asObservable();
    }

    /**
     * The document's parent ID.
     */
    abstract get parentId(): string;

    /**
     * Constructor
     * @param ref The reference to the document.
     * @param data The data to store in the document.
     * @param creationTime The date the document was created.
     * @param updateTime The date the document was last updated.
     * @param schemaVersion The database version.
     * @param migrator Object to migrate the data.
     */
    protected constructor(
        public readonly ref: NirbyDocumentReference<T>,
        data: T,
        public readonly creationTime: DateTime,
        public readonly updateTime: DateTime,
        public readonly schemaVersion: number,
        protected readonly migrator: MigratorLike<T>,
    ) {
        this.dataSubject = new BehaviorSubject<Readonly<T>>(
            migrator.migrate(data).migrated,
        );
    }

    /**
     * The document ID
     */
    abstract get id(): string;

    /**
     * Get an object with the data and metadata of the document.
     */
    get uploaded(): Uploaded<T> {
        return {
            ...this.data,
            _creationTime: this.creationTime,
            _lastUpdate: this.updateTime,
            _databaseVersion: this.migrator.currentVersion,
        };
    }

    /**
     * The collection object
     */
    abstract get collectionRef(): NirbyCollectionReference<T>;

    abstract get collection(): NirbyCollection<T>;

    /**
     * Maps this document to a new type.
     * @param mapper The mapper to use.
     * @param idMapper Maps the ID of the document.
     *
     * @example
     * ```typescript
     * const doc = new VSPDocument(...);
     * const newDoc = doc.map(doc => ({
     *    ...doc,
     *    newField: 'new value'
     * }));
     * ```
     *
     * @returns - The new document.
     */
    public map<U extends object>(
        mapper: (data: T) => U,
        idMapper?: (data: T) => string,
    ): NirbyDocumentMap<U, T> {
        return new NirbyDocumentMap<U, T>(
            this,
            mapper(this.data),
            idMapper ? idMapper(this.data) : undefined,
        );
    }

    /**
     * Updates the document with the given data locally (no server interaction).
     * @param data The data to update the document with.
     */
    localUpdate(data: Partial<T>): void {
        this.dataSubject.next({
            ...this.data,
            ...data,
        });
    }

    /**
     * Transforms an object to {@link Modeled}
     *
     * @returns The modeled object.
     */
    toModeled(): Modeled<T> {
        return {
            ...this.data,
            _creationTime: this.creationTime,
            _lastUpdate: this.updateTime,
            _databaseVersion: this.schemaVersion,
            _docId: this.id,
        };
    }

    /**
     * For typing purposes, transform a document with a union type into a union of documents.
     *
     * @returns The transformed document.
     */
    toUnionType<A extends T, B extends T>():
        | NirbyDocument<A>
        | NirbyDocument<B> {
        return this as unknown as NirbyDocument<B>;
    }

    /**
     * Creates a local Nirby Document
     * @param data The data to store in the document.
     * @param collection The collection the document belongs to.
     *
     * @returns The new document.
     */
    static createLocal<T extends object>(
        data: T,
        collection: NirbyCollection<T>,
    ): DocumentLike<T> {
        const id = collection.ref().id;
        const now = DateTime.now();
        return {
            creationTime: now,
            data: collection.migrator.migrate(data).migrated,
            id,
            schemaVersion: collection.migrator.currentVersion,
            updateTime: now,
            toModeled: () => ({
                ...data,
                _creationTime: now,
                _lastUpdate: now,
                _databaseVersion: collection.migrator.currentVersion,
                _docId: id,
            }),
        };
    }
}

/**
 * A map of the data inside a document.
 */
export class NirbyDocumentMap<T extends object, Src extends object>
    implements DocumentLike<T> {
    /**
     * Constructor
     */
    constructor(
        public readonly doc: NirbyDocument<Src>,
        public readonly data: T,
        private readonly mappedId: string = doc.id,
    ) {
    }

    /**
     * The ID of the document.
     */
    public get id(): string {
        return this.mappedId;
    }

    /**
     * The ID of the source document.
     */
    public get originalId(): string {
        return this.doc.id;
    }

    /**
     * The date the document was created.
     */
    get creationTime(): DateTime {
        return this.doc.creationTime;
    }

    /**
     * The date the document was last updated.
     */
    get updateTime(): DateTime {
        return this.doc.updateTime;
    }

    /**
     * The database version of the document.
     */
    get schemaVersion(): number {
        return this.doc.schemaVersion;
    }

    /**
     * Models the document into a plain object.
     *
     * @returns The modeled object.
     */
    toModeled(): Modeled<T> {
        return {
            ...this.data,
            _creationTime: this.creationTime,
            _lastUpdate: this.updateTime,
            _databaseVersion: this.schemaVersion,
            _docId: this.id,
        };
    }
}
