<div
    class="routes-container p-3 flex-1 d-flex flex-column justify-content-between h-100"
    *ngIf="routeId$ | async; else emptyPanel"
>
    <header
        class="component-header mb-4 d-flex justify-content-between align-items-center"
        *ngIf="fullUrl"
    >
        <h1 class="main-title mb-0">
            Route for:
            <a [href]="fullUrl" target="_blank"> {{ fullUrl }}</a>
        </h1>

        <div class="actions">
            <div class="tools" ngbDropdown container="body">
                <a
                    href=""
                    class="tool link"
                    ngbDropdownToggle
                    (click)="$event.stopPropagation(); $event.preventDefault()"
                >
                    Options
                </a>
                <!-- Dropdown Options -->
                <ul ngbDropdownMenu>
                    <li>
                        <a
                            href="https://fixecl.notion.site/Configurando-tus-Routes-20df056e196f419abc5e65d10b75aa4e"
                            target="_blank"
                            ngbDropdownItem
                        >
                            What's a Route? 😅
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </header>

    <div class="component-body flex-1">
        <!-- Template to select campaign. -->
        <ng-container *ngTemplateOutlet="campaignSelector"></ng-container>

        <div class="extra-campaigns" *ngFor="let campaigns of addCampaigns">
            <ng-container *ngTemplateOutlet="campaignSelector"></ng-container>
        </div>
    </div>

    <footer class="component-footer">
        <p class="tip-message small text-muted mb-0">
            <strong>Pro tip:</strong> you can have many Routes linked to
            different Pops, in order to create a segment-triggered flow within
            your website. <span class="separator mx-2">–</span
        ><a
            href="https://fixecl.notion.site/Configurando-tus-Routes-20df056e196f419abc5e65d10b75aa4e"
            target="_blank"
            class="link"
        >Read more</a
        >
        </p>
        <!--<button (click)="save()"></button>-->
    </footer>
</div>

<ng-template #campaignSelector>
    <ng-container *ngIf="campaigns$ | async as campaign; else loading">
        <form [formGroup]="routeForm">
            <div class="form-group">
                <label class="campaigns-label" for="campaign-select"
                >Select Pop</label
                >
                <div class="input-group mb-1">
                    <select
                        id="campaign-select"
                        class="custom-select"
                        [formControl]="routePopControl"
                    >
                        <option [ngValue]="null" class="font-italic">
                            No Pop selected
                        </option>
                        <option
                            *ngFor="let item of campaigns$ | async"
                            [ngValue]="item.pop.id"
                            [disabled]="!item.isPublished"
                        >
                            {{ item.pop.data.title
                            }}{{ item.pop.data.emoji ? ' - ' + item.pop.data.emoji : ''
                            }}{{ item.isPublished ? '' : ' (not published)' }}
                        </option>
                    </select>
                    <div class="input-group-append" *ngIf="false">
                        <ui-switch color="#007bff"></ui-switch>
                        <p class="campaign-selector-label">
                            Has a logical condition?
                        </p>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="campaigns-label" for="campaign-select"
                >Match type</label
                >
                <select class="form-control" [formControl]="routeTypeControl">
                    <option [ngValue]="'match'">Exact match</option>
                    <option [ngValue]="'begins-with'">Contains</option>
                </select>
            </div>
            <div class="area-help">
                <p class="small text-muted text-right mb-0">
                    <strong>Tip:</strong> Can't find a Pop on the list above?
                    Try publishing it first.
                </p>
            </div>
        </form>
    </ng-container>
    <ng-template #loading>
        <div class="row justify-content-center">
            <div class="spinner-border text-primary"></div>
        </div>
    </ng-template>
</ng-template>

<!-- Template to empty panel. -->
<ng-template #emptyPanel>
    <ng-template #loading>
        <div class="row justify-content-center">
            <div class="spinner-border text-primary"></div>
        </div>
    </ng-template>
</ng-template>
