import { BaseBlock, buildBlockSchema } from './block.model';
import { FONT_STYLE_SCHEMA, FontStyle } from '../models/text';
import { z } from 'zod';
import { Migrator } from '@nirby/store/migrator';

export const BUTTON_ALIGNMENT_SCHEMA = z.union([
    z.literal('between'),
    z.literal('center'),
    z.literal('icon-right'),
    z.literal('icon-left'),
]);
export type ButtonAlignment = z.infer<typeof BUTTON_ALIGNMENT_SCHEMA>;

export interface ButtonBlockContent {
    label: string | null;
    icon: string | null;
}

export interface ButtonBlockStyle {
    color: string;
    borderRadius: number;
    borderWidth: number;
    borderColor: string | null;
    alignment: ButtonAlignment;
    fontColor: string | null;
    fontSize: number;
    fontFamily: string | null;
    fontStyle: FontStyle;
}

export type ButtonBlock = BaseBlock<
    'Button',
    ButtonBlockContent,
    ButtonBlockStyle
>;

export const BUTTON_BLOCK_SCHEMA = buildBlockSchema<ButtonBlock>(
    'Button' as const,
    z.object({
        label: z.string().nullable(),
        icon: z.string().nullable(),
    }),
    z.object({
        color: z.string().default('#ffffff'),
        borderRadius: z.number().min(0).default(0),
        borderWidth: z.number().min(0).default(0),
        borderColor: z.string().nullable(),
        alignment: BUTTON_ALIGNMENT_SCHEMA.default('center'),
        fontColor: z.string().nullable(),
        fontSize: z.number().min(0).default(18),
        fontFamily: z.string().nullable().default(null),
        fontStyle: FONT_STYLE_SCHEMA,
    })
);

export const BUTTON_BLOCK_MIGRATOR = new Migrator<ButtonBlock>(
    BUTTON_BLOCK_SCHEMA,
    () => ({
        actions: { click: [] },
        content: {
            label: 'Label',
            icon: null,
        },
        hash: '',
        position: [
            { x: 0, y: 0 },
            { x: 128, y: 128 },
        ],
        rotation: 0,
        style: {
            color: 'rgb(0,0,0)',
            borderRadius: 0.5,
            borderWidth: 0,
            borderColor: 'rgb(0,0)',
            alignment: 'center',
            fontColor: 'rgb(255,255,255)',
            fontSize: 100,
            fontFamily: 'Roboto',
            fontStyle: 'normal',
        },
        type: 'Button',
    })
);
