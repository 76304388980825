import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoadScreenComponent} from './load-screen/load-screen.component';
import {LoadToastComponent} from './load-toast/load-toast.component';
import {LoadBlockComponent} from './load-block/load-block.component';


@NgModule({
    declarations: [
        LoadScreenComponent,
        LoadToastComponent,
        LoadBlockComponent
    ],
    exports: [
        LoadScreenComponent
    ],
    imports: [
        CommonModule
    ]
})
/**
 * Module for showing a loading screen or toast.
 */
export class LoadScreenModule { }

export {LoadScreenService} from './load-screen.service';
