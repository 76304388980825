import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {
    DownloadModalComponent,
    EmojiPickerComponent,
    FadingViewItemComponent,
    FadingViewSetComponent,
    FontPickerComponent,
    IconPickerComponent,
    ImageRecordViewComponent,
    NumberInputComponent,
    SelectorBoxComponent,
    SelectorToggleComponent,
    SelectorToggleTagComponent,
    ShareHtmlComponent,
    SliderComponent,
    SwitchControlComponent,
    TimeRangePickerComponent,
    TouchToEditComponent,
    VariablesDialogComponent,
    VariablesDialogFormComponent,
    VideoTimestampPickerComponent,
} from './components';
import {FontPickerModule,} from 'ngx-font-picker';
import {UploadDirective} from './directives/upload.directive';
import {NgbDropdownModule, NgbNavModule, NgbTimepickerModule, NgbTooltipModule,} from '@ng-bootstrap/ng-bootstrap';
import {PickerTimestampPipe} from './pipes/picker-timestamp.pipe';
import {EditableModule} from '@ngneat/edit-in-place';
import {NgToggleModule} from 'ng-toggle-button';
import {SafeUrlPipe} from './pipes/safe-url.pipe';
import {DividerModule} from 'primeng/divider';
import {RouterModule} from '@angular/router';
import {DialogModule} from 'primeng/dialog';
import {InputTextModule} from 'primeng/inputtext';
import {InputSwitchModule} from 'primeng/inputswitch';

@NgModule({
    declarations: [
        FontPickerComponent,
        UploadDirective,
        ImageRecordViewComponent,
        NumberInputComponent,
        IconPickerComponent,
        EmojiPickerComponent,
        SliderComponent,
        VideoTimestampPickerComponent,
        PickerTimestampPipe,
        TimeRangePickerComponent,
        SelectorToggleComponent,
        SelectorBoxComponent,
        DownloadModalComponent,
        SwitchControlComponent,
        TouchToEditComponent,
        SafeUrlPipe,
        SelectorToggleTagComponent,
        FadingViewSetComponent,
        FadingViewItemComponent,
        ShareHtmlComponent,
        VariablesDialogComponent,
        VariablesDialogFormComponent,
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        FontPickerModule,
        NgbNavModule,
        NgbDropdownModule,
        NgbTooltipModule,
        NgbTimepickerModule,
        NgToggleModule,
        EditableModule,
        DividerModule,
        RouterModule,
        DialogModule,
        InputTextModule,
        InputSwitchModule,
    ],
    exports: [
        FontPickerComponent,
        ImageRecordViewComponent,
        NumberInputComponent,
        IconPickerComponent,
        EmojiPickerComponent,
        SliderComponent,
        VideoTimestampPickerComponent,
        TimeRangePickerComponent,
        SelectorToggleComponent,
        SelectorBoxComponent,
        SwitchControlComponent,
        TouchToEditComponent,
        SafeUrlPipe,
        SelectorToggleTagComponent,
        FadingViewSetComponent,
        FadingViewItemComponent,
        ShareHtmlComponent,
        VariablesDialogComponent,
        UploadDirective,
    ],
})
/**
 * Module for shared components
 */
export class NirbyCommonModule {}
