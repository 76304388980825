import { Component } from '@angular/core';
import { BlockStyleComponent } from '../../block-style.component';
import Color from 'color';
import { faBold, faFont, faItalic } from '@fortawesome/free-solid-svg-icons';
import { FontStyle } from '@nirby/runtimes/canvas';
import { FontSource } from '@nirby/media-models';
import {
    ButtonAlignment,
    ButtonBlockStyle,
    constants,
} from '@nirby/models/nirby-player';
import { ButtonDisplay } from '@nirby/shared/widgets';

@Component({
    selector: 'nirby-button-style-editor',
    templateUrl: './button-style-editor.component.html',
    styleUrls: ['./button-style-editor.component.scss'],
})
export class ButtonStyleEditorComponent extends BlockStyleComponent<ButtonBlockStyle> {
    color = '#ffffff';
    borderRadius = 0;
    borderColor: string | null = null;
    borderWidth = 0;
    fontColor: string | null = null;
    alignment: ButtonAlignment = 'between';
    paddingX: number | null = null;
    paddingY: number | null = null;
    iconScale: number | null = null;
    fontSize = 14.0;
    fontFamily = 'Roboto';
    fontStyle: FontStyle = 'normal';

    public fontStyleOptions: ButtonDisplay<FontStyle>[] = [
        {
            value: 'normal',
            icon: faFont,
        },
        {
            value: 'bold',
            icon: faBold,
        },
        {
            value: 'italic',
            icon: faItalic,
        },
    ];

    get colorContrast(): string {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const contrast = require('get-contrast');
        if (!this.color || !this.fontColor) {
            return 'Auto';
        }
        if (this.isTransparent(this.color)) {
            return 'Transparent';
        }
        return contrast.score(this.color, this.fontColor, {
            ignoreAlpha: true,
        });
    }

    isTransparent(colorName: string): boolean {
        const color = new Color(colorName);
        return color.alpha() < 0.1;
    }

    setData(style: ButtonBlockStyle): void {
        this.color = style.color;
        this.borderRadius = style.borderRadius;
        this.borderColor = style.borderColor;
        this.borderWidth = style.borderWidth;
        this.alignment = style.alignment;
        this.fontColor = style.fontColor;
        this.fontSize = style.fontSize ?? 15;
        this.fontStyle = style.fontStyle;
        this.fontFamily = style.fontFamily ?? 'Roboto';
    }

    onColorChange(color: string | null): void {
        this.color = color ?? '#ffffff';
        this.updateAllStyles();
    }

    onFontColorChange(color: string | null): void {
        this.fontColor = color;
        this.updateAllStyles();
    }

    onRadiusChange(radius: number): void {
        this.borderRadius = radius;
        this.updateAllStyles();
    }

    onBorderColorChange(color: string | null): void {
        this.borderColor = color;
        this.updateAllStyles();
    }

    onBorderWidthChange(width: number): void {
        this.borderWidth = width;
        this.updateAllStyles();
    }

    onAlignmentChange(evt: Event): void {
        this.alignment = (evt.target as any).value;
        this.updateAllStyles();
    }

    updateAllStyles(): void {
        this.sendData({
            color: this.color,
            borderRadius: this.borderRadius,
            borderWidth: this.borderWidth,
            borderColor: this.borderColor,
            alignment: this.alignment,
            fontColor: this.fontColor,
            fontSize: this.fontSize,
            fontFamily: this.fontFamily,
            fontStyle: this.fontStyle,
        });
    }

    onPaddingChange(padding: number): void {
        this.paddingX = padding;
        this.paddingY = padding * constants.blocks.button.paddingRatio;
        this.updateAllStyles();
    }

    onFontScaleChange(scale: number): void {
        this.fontSize = scale;
        this.updateAllStyles();
    }

    onIconScaleChange(scale: number | null): void {
        this.iconScale = scale;
        this.updateAllStyles();
    }

    onFontChange(font: FontSource): void {
        this.fontFamily = font.name;
        this.updateAllStyles();
    }

    onFontStyleChange(style: FontStyle): void {
        this.fontStyle = style;
        this.updateAllStyles();
    }
}
