import { BaseBlock, buildBlockSchema } from './block.model';
import {
    FONT_DECORATION_SCHEMA,
    FONT_STYLE_SCHEMA,
    FontDecoration,
    FontStyle,
    TEXT_ALIGNMENT_SCHEMA,
    TextAlignment,
    VERTICAL_TEXT_ALIGNMENT_SCHEMA,
    VerticalTextAlignment,
} from '../models/text';
import { z } from 'zod';
import { Migrator } from '@nirby/store/migrator';

export type TextBlock = BaseBlock<
    'Text',
    {
        text: string;
        verticalAlign: VerticalTextAlignment;
        alignment: TextAlignment;
        fontScale: number;
        color: string;
        decoration: FontDecoration;
        style: FontStyle;
        fontFamily: string;
        lineHeight: number;
    }
>;
export type TextBlockContent = TextBlock['content'];

export const TEXT_BLOCK_SCHEMA = buildBlockSchema<TextBlock>(
    'Text' as const,
    z.object({
        text: z.string().default(''),
        verticalAlign: VERTICAL_TEXT_ALIGNMENT_SCHEMA,
        alignment: TEXT_ALIGNMENT_SCHEMA,
        fontScale: z
            .number()
            .min(0)
            .catch(() => 1),
        color: z.string().default('#ffffff'),
        decoration: FONT_DECORATION_SCHEMA,
        style: FONT_STYLE_SCHEMA,
        fontFamily: z.string(),
        lineHeight: z
            .number()
            .min(0)
            .max(3)
            .catch(() => 1.1),
    }),
    z.object({})
);

export const TEXT_BLOCK_MIGRATOR = new Migrator<TextBlock>(
    TEXT_BLOCK_SCHEMA,
    () => ({
        actions: { click: [] },
        content: {
            text: 'Default text...',
            verticalAlign: 'middle',
            alignment: 'left',
            fontScale: 18,
            color: 'rgb(0,0,0)',
            decoration: '',
            style: 'normal',
            fontFamily: 'Roboto',
            lineHeight: 1.0,
        },
        hash: '',
        position: [
            { x: 0, y: 0 },
            { x: 127, y: 128 },
        ],
        rotation: 0,
        style: {},
        type: 'Text',
    })
);
