<div>
    <div class="d-flex justify-content-between">
        <label for="slider">
            {{ label }}
        </label>
        <nirby-touch-to-edit
            [value]="value.toString(10)"
            (valueChange)="onValueChange($event)"
        ></nirby-touch-to-edit>
    </div>
    <input
        id="slider"
        class="form-control"
        type="range"
        [min]="min"
        [max]="max"
        [step]="step"
        [ngModel]="value"
        (ngModelChange)="onValueChange($event)"
    />
    <div class="d-flex justify-content-between">
        <span>{{ min }}</span>
        <span>{{ max }}</span>
    </div>
</div>
