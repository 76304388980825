import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AlertsService} from '@nirby/shared/alerts';
import {UploadTask} from '@nirby/shared/database';
import {ImageRecord, MediaItem} from '@nirby/media-models';
import {MediaError} from '@nirby/js-utils/errors';
import {LoadScreenService} from '@nirby/shared/load-screen';
import {Subscription} from 'rxjs';
import {FileDropService} from '@nirby/shared/file-drop';
import {MediaLibraryService} from '../../services/media-library.service';

@Component({
    selector: 'nirby-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.scss'],
})
/**
 * Image upload component.
 */
export class ImageUploadComponent implements OnInit, OnDestroy {
    @ViewChild('fileInput') fileInput: ElementRef<HTMLElement> | null = null;

    @Input() uploadPath = '/';
    @Input() loading = false;
    @Output() uploadImage = new EventEmitter<ImageRecord | null>();

    private readonly subscription = new Subscription();

    ngOnInit(): void {
        this.subscription.add(
            this.fileDrop
                .watch('Upload to library', FileDropService.UPLOADABLE_IMAGE_TYPES)
                .subscribe(async (files) => {
                    if (files.length > 0) {
                        await this.onFileUpload(files);
                    }
                }),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Constructor.
     * @param library Media library service.
     * @param alerts Alert service.
     * @param loader Load screen service.
     * @param fileDrop File drag and drop service.
     */
    constructor(
        private library: MediaLibraryService,
        private alerts: AlertsService,
        private loader: LoadScreenService,
        private fileDrop: FileDropService,
    ) {
    }

    /**
     * Uploads a file from an upload event.
     * @param fileList File list.
     *
     * @returns Promise<void> Promise that resolves when the upload is complete.
     */
    async onFileSelected(fileList: Event): Promise<void> {
        const list = (fileList.target as HTMLInputElement).files;
        if (!list) {
            return;
        }
        await this.onFileListSelected(list);
    }

    /**
     * Uploads a file list.
     * @param list File list.
     *
     * @returns Promise<void> Promise that resolves when the upload is complete.
     */
    async onFileListSelected(list: FileList): Promise<void> {
        const arr: File[] = [];
        for (let fileIdx = 0; fileIdx < list.length; fileIdx++) {
            const f = list.item(fileIdx);
            if (f) {
                arr.push(f);
            }
        }
        await this.onFileUpload(arr);
    }

    /**
     * Uploads multiple files.
     * @param fileList File list.
     *
     * @returns Promise<void> Promise that resolves when the upload is complete.
     */
    onFileUpload(fileList: File[]): Promise<void> {
        return this.loader.untilCompletion(
            async () => {
                const file = fileList.filter(
                    (f) => MediaLibraryService.getFileMediaType(f) === 'image',
                )[0];
                if (file) {
                    this.loading = true;
                    let item: UploadTask<MediaItem | null>;
                    try {
                        item = await this.library.upload(file);
                    } catch (e) {
                        if (e instanceof MediaError) {
                            this.alerts.error('File is too big');
                            this.loading = false;
                            return;
                        }
                        throw e;
                    }
                    const metadata = await item.metadata;
                    if (!!metadata && metadata.type === 'image') {
                        this.uploadImage.emit(metadata.content);
                    }
                    this.loading = false;
                } else {
                    this.alerts.error('Selected file is not compatible');
                }
            },
            'block',
            'Uploading image...',
        );
    }

    /**
     * Opens the file input.
     */
    openFileUploader(): void {
        this.fileInput?.nativeElement.click();
    }
}
