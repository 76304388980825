import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {VoidFunction} from '@nirby/js-utils/types';

@Component({
    selector: 'nirby-switch-control',
    templateUrl: './switch-control.component.html',
    styleUrls: ['./switch-control.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SwitchControlComponent),
            multi: true,
        },
    ],
})
export class SwitchControlComponent implements ControlValueAccessor {
    @Input() label: string | null = null;

    value = false;
    disabled = false;

    onChange: VoidFunction<boolean> = () => {
        return;
    };
    onTouched: VoidFunction<void> = () => {
        return;
    };

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    registerOnChange(fn: VoidFunction<boolean>): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: VoidFunction<void>): void {
        this.onTouched = fn;
    }

    writeValue(obj: boolean | null): void {
        this.value = obj ?? false;
        this.onChange(this.value);
    }

    update(evt: boolean): void {
        this.value = evt;
        this.onChange(evt);
    }
}
