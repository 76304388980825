import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    Output,
    ViewChild,
} from '@angular/core';
import Konva from 'konva';
import {BehaviorSubject, NEVER, Subscription, switchMap} from 'rxjs';
import {NirbyContext} from '@nirby/runtimes/context';
import {Card, NirbyPlayerConfig} from '@nirby/models/nirby-player';
import {ArtBoard, NirbyBoardStateNode, Block, scaleStageToFitShape} from '@nirby/runtimes/canvas';
import {Logger} from '@nirby/logger';
import {map} from 'rxjs/operators';
import {NgTranslator} from '../../services';
import {NIRBY_PLAYER_CONFIG} from '@nirby/shared/player-config';

@Component({
    selector: 'nirby-card-view',
    templateUrl: './card-view.component.html',
    styleUrls: ['./card-view.component.scss'],
})
/**
 * Component to display a card
 */
export class CardViewComponent implements AfterViewInit, OnDestroy {
    @ViewChild('container') containerRef?: ElementRef;

    /**
     * The card to display
     * @param value The card to display
     */
    @Input() set card(value: Card | null) {
        this.cardSubject.next(value ?? null);
    }

    @Input() context = new NirbyContext(this.translator);

    private readonly cardSubject = new BehaviorSubject<Card | null>(null);
    private readonly card$ = this.cardSubject.asObservable();

    private artBoard: ArtBoard<never> | null = null;
    public cardBoard: ArtBoard<never> | null = null;

    @Output() loaded = new EventEmitter<void>();

    private subscriptions: Subscription = new Subscription();

    displayStage?: Konva.Stage;

    /**
     * Constructor.
     * @param config The Nirby player configuration
     * @param translator The translator
     */
    constructor(
        @Inject(NIRBY_PLAYER_CONFIG) private readonly config: NirbyPlayerConfig,
        private translator: NgTranslator,
    ) {}

    /**
     * Initialize the display stage
     */
    async ngAfterViewInit(): Promise<void> {
        if (!this.container) {
            Logger.warnStyled(
                'CardViewComponent',
                'No container element found',
            );
            return;
        }
        this.displayStage = new Konva.Stage({
            container: this.container,
            width: this.container.clientWidth,
            height: this.container.clientHeight,
        });
        const layer = new Konva.Layer();
        this.displayStage.add(layer);

        const cardBoardState: NirbyBoardStateNode<never, ArtBoard<never>> = {
            id: 'card-board',
            type: 'art-board',
            properties: {
                mask: true,
                background: {
                    fillColor: 'rgb(255,255,255)',
                    borderRadius: this.config.cardBorderRadius,
                },
                position: [
                    {
                        x: 0,
                        y: 0,
                    },
                    {
                        x: this.config.cardSize.x,
                        y: this.config.cardSize.y,
                    },
                ],
                rotation: 0,
            },
            children: [],
            meta: null,
        };

        const artBoard = (this.artBoard = new ArtBoard<never>(
            '__card-board__',
            null,
            this.context,
            false,
        ));
        await artBoard.init(layer);
        this.cardBoard = await artBoard.addChild(cardBoardState);

        this.subscriptions.add(
            this.artBoard
                .watchChildById('card-board')
                .pipe(
                    switchMap((cardBoard) =>
                        cardBoard
                            ? this.card$.pipe(
                                  map((card) => ({ card, cardBoard })),
                              )
                            : NEVER,
                    ),
                )
                .subscribe(async ({ card, cardBoard }) => {
                    cardBoard.children = card?.blocks.map((block) =>
                        Block.blockToState<never>(block, null),
                    ) ?? [];
                    if (cardBoard instanceof ArtBoard) {
                        await cardBoard.nextItems();
                        this.loaded.emit();
                    }
                }),
        );
        this.onResize();
        this.loaded.emit();
    }

    /**
     * Dispose the art board
     */
    async ngOnDestroy(): Promise<void> {
        this.artBoard?.dispose();
    }

    /**
     * Handle the window resize event to update the stage size
     */
    @HostListener('window:resize')
    onResize(): void {
        const shape = this.cardBoard?.shape;
        if (!shape) {
            return;
        }
        scaleStageToFitShape(shape);
    }

    /**
     * The art board shape
     */
    get cardNode(): Konva.Group | null {
        return this.artBoard?.shape ?? null;
    }

    /**
     * The art board container
     */
    get container(): HTMLDivElement | null {
        return this.containerRef?.nativeElement ?? null;
    }
}
