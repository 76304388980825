import {NirbyDocumentReference} from '@nirby/store/base';
import {IMAGE_RECORD_SCHEMA, ImageRecord} from '@nirby/media-models';
import {ZodSchemaBuild} from '@nirby/store/models';
import {z} from 'zod';
import {buildUploadedReferenceSchema} from '@nirby/store/proxy';
import {Migrator} from '@nirby/store/migrator';
import {USER_MIGRATOR, UserData} from './user.model';

export type DropletPrivacySetting = 'public' | 'private';

export interface Droplet {
    compiledPop: string;
    featured: boolean;
    creator: NirbyDocumentReference<UserData> | null;
    name: string;
    description: string;
    thumbnail: ImageRecord | null;
    tags: string[];
    price: number;
    used: number;
    appVersion: string;
    privacy: DropletPrivacySetting;
}

export const DROPLET_SCHEMA: ZodSchemaBuild<Droplet> = z.object({
    compiledPop: z.string(),
    featured: z.boolean(),
    creator: buildUploadedReferenceSchema<UserData>(() => USER_MIGRATOR),
    name: z.string(),
    description: z.string(),
    thumbnail: IMAGE_RECORD_SCHEMA.nullable().default(null),
    tags: z.array(z.string()).default([]),
    price: z.number().default(0),
    used: z.number().default(0),
    appVersion: z.string().default('1.0.0'),
    privacy: z.union([z.literal('public'), z.literal('private')]),
});

export const DROPLET_MIGRATOR = new Migrator<Droplet>(DROPLET_SCHEMA, () => ({
    appVersion: '',
    compiledPop: 'null',
    creator: null,
    featured: false,
    name: 'Name',
    description: '',
    thumbnail: null,
    price: 0,
    tags: [],
    used: 0,
    privacy: 'private',
}));
