/* eslint-disable @typescript-eslint/no-explicit-any */
import {BlockContext} from './shapes';
import {AppError} from '@nirby/js-utils/errors';
import {WeakTypedState} from '@nirby/runtimes/context';
import Color from 'color';
import {AttributeValue, NirbyBlock} from '@nirby/models/nirby-player';
import {tryParseColor} from '../utils/color';
import {clamp} from '../utils';
import {NirbyVariable} from '@nirby/runtimes/state';
import {Logger} from '@nirby/logger';

/**
 * An attribute that can be normal or returned by a function
 */
export type Attribute<T extends AttributeValue> = T | FunctionObjectOfReturn<T>;

type ContextFunction = (...args: any[]) => any;

type FunctionObjectArguments = Array<| AttributeValue
    | null
    | ContextFunction
    | Array<AttributeValue | null>
    | BaseFunctionObject>;

interface BaseFunctionObject<Method extends string = string,
    Arguments extends FunctionObjectArguments = FunctionObjectArguments,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ReturnType extends AttributeValue = AttributeValue> {
    method: Method;
    arguments: Arguments;
}

export type SubFunctionObject = BaseFunctionObject<'Sub', [string], string>;
export type GetBlockHeight = BaseFunctionObject<'GetBlockHeight', []>;
export type GetBlockContentFunctionObject = BaseFunctionObject<'GetBlockContent',
    [string | BaseFunctionObject]>;
export type GetBlockStyleFunctionObject = BaseFunctionObject<'GetBlockStyle',
    [string]>;
export type GetStateFunctionObject<Type extends NirbyVariable = NirbyVariable> =
    BaseFunctionObject<'GetState', [string, TypeNameOf<Type>], Type>;
export type GetBlockIdFunctionObject = BaseFunctionObject<'GetBlockId',
    [],
    string>;
export type SwitchFunctionObject<Type extends NirbyVariable = NirbyVariable> =
    BaseFunctionObject<'Switch',
        [string, TypeNameOf<Type>, Type, ...[string, Type][]],
        string>;
export type IfElseFunctionObject<Type extends NirbyVariable | BaseFunctionObject = NirbyVariable> = BaseFunctionObject<'IfElseBlock',
    [string, Type | BaseFunctionObject, Type | BaseFunctionObject],
    string>;
export type IfElseShapeFunctionObject<Type extends NirbyVariable | BaseFunctionObject = NirbyVariable> = BaseFunctionObject<'IfElseUnit',
    [string, Type | BaseFunctionObject, Type | BaseFunctionObject],
    string>;
export type GetBlockContentOrElseFunctionObject = BaseFunctionObject<'GetBlockContentOrElse',
    [string | BaseFunctionObject, NirbyVariable],
    NirbyVariable
>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type CustomFunctionObject<T = NirbyVariable> = BaseFunctionObject<'Custom',
    [ContextFunction],
    string>;
export type ChangeColorLightness = BaseFunctionObject<'ChangeColorLightness',
    [string | BaseFunctionObject, number, string | BaseFunctionObject | null],
    string>;
export type GetHighlightColorFor = BaseFunctionObject<'GetHighlightColorFor',
    [string | BaseFunctionObject, string | BaseFunctionObject | null],
    string>;
export type Multiply = BaseFunctionObject<'Multiply',
    [number | BaseFunctionObject, number | BaseFunctionObject],
    string>;
export type Translate = BaseFunctionObject<'Translate',
    [string | BaseFunctionObject],
    string>;

export type FunctionObject =
    | SubFunctionObject
    | GetBlockContentFunctionObject
    | GetStateFunctionObject
    | GetBlockStyleFunctionObject
    | GetBlockIdFunctionObject
    | CustomFunctionObject
    | SwitchFunctionObject
    | IfElseFunctionObject
    | IfElseShapeFunctionObject
    | ChangeColorLightness
    | GetBlockHeight
    | GetHighlightColorFor
    | Multiply
    | Translate
    | GetBlockContentOrElseFunctionObject;

export type FunctionObjectOfReturn<ReturnType extends AttributeValue> =
    PickFunctionOfReturnType<FunctionObject, ReturnType>;

type PickFunctionOfName<Fn extends FunctionObject,
    Name extends FunctionObject['method']> = Fn extends { method: Name } ? Fn : never;
type PickFunctionOfReturnType<Fn extends FunctionObject,
    ReturnType extends AttributeValue> = Fn extends BaseFunctionObject<any, any, ReturnType> ? Fn : never;

type FunctionObjectCallable<Name extends FunctionObject['method']> = (
    context: BlockContext,
    unitState: WeakTypedState<string>,
    args: PickFunctionOfName<FunctionObject, Name>['arguments']
) => InferFunctionObjectReturnType<PickFunctionOfName<FunctionObject, Name>>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type InferFunctionObjectReturnType<Fn extends FunctionObject> =
    FunctionObject extends BaseFunctionObject<any, any, infer ReturnType>
        ? ReturnType
        : never;

type TypeNameOf<T> = T extends string
    ? 'string'
    : T extends number
        ? 'number'
        : T extends boolean
            ? 'boolean'
            : never;

/**
 * @param key The key of the state to get
 * @param context The context of the block
 * @param type The type of the variable in the state to get
 *
 * @returns The value of the state
 */
function getState<Type extends NirbyVariable = NirbyVariable>(
    key: string,
    context: BlockContext,
    type: TypeNameOf<Type>,
): Type {
    switch (type) {
        case 'string':
            return context.state.getString(key) as Type;
        case 'number':
            return context.state.getFloat(key) as Type;
        case 'boolean':
            return context.state.getBool(key) as Type;
        default:
            throw new AppError(`Unknown variable type: ${type}`);
    }
}

const functionRegistry: {
    [Method in FunctionObject['method']]: FunctionObjectCallable<Method>;
} = {
    /**
     * Get the block's height
     * @param context The context of the block
     * @constructor
     */
    GetBlockHeight: (context: BlockContext) => {
        return Math.abs(
            context.block.position[1].y - context.block.position[0].y,
        );
    },
    /**
     * Multiplies two numbers
     * @param context The context of the block
     * @param unitState The state of the unit
     * @param a Multiplier A
     * @param b Multiplier B
     * @constructor
     */
    Multiply: (
        context: BlockContext,
        unitState: WeakTypedState<string>,
        [a, b]: [number | BaseFunctionObject, number | BaseFunctionObject],
    ) => {
        a = parse<number>(a, context, unitState);
        b = parse<number>(b, context, unitState);
        return a * b;
    },
    /**
     * Receives a template and returns it replacing the variables with the values on the block context state
     * @param context The context of the block
     * @param unitState The id of the shape unit
     * @param template The template to replace the variables on
     * @constructor
     */
    Sub: (
        context: BlockContext,
        unitState: WeakTypedState<string>,
        [template]: [string],
    ) => {
        let variable: string;

        // eslint-disable-next-line no-constant-condition
        while (true) {
            const match = template.match(/\${([a-zA-Z\d.]+)}/);
            if (!match) {
                return template;
            }
            variable = match[1];
            const regex = '${' + variable + '}';
            template = template.replace(
                regex,
                getState(variable, context, 'string'),
            );
        }
    },
    /**
     * Get a content key of a block
     * @param context The context of the block
     * @param unitState The id of the shape unit
     * @param name The name of the content key
     * @constructor
     */
    GetBlockContent: <TB extends NirbyBlock>(
        context: BlockContext<TB>,
        unitState: WeakTypedState<string>,
        [name]: [string | BaseFunctionObject],
    ) => {
        const content: TB['content'] = context.block.content;
        const key = parse(name, context, unitState) as keyof TB['content'];
        return content[key];
    },
    /**
     * Get a style key of a block
     * @param context The context of the block
     * @param unitState The unit state
     * @param name The name of the style key
     * @constructor
     */
    GetBlockStyle: <TB extends NirbyBlock>(
        context: BlockContext<TB>,
        unitState: WeakTypedState<string>,
        [name]: [string],
    ) => {
        const style: TB['style'] = context.block.style;
        const key = name as keyof TB['style'];
        return style[key];
    },
    /**
     * Get a value from the context state
     * @param context The context of the block
     * @param unitState The id of the shape unit
     * @param name The name of the state value
     * @param type The type of the state value
     * @constructor
     */
    GetState: (
        context: BlockContext,
        unitState: WeakTypedState,
        [name, type]: [string, TypeNameOf<NirbyVariable>],
    ) => {
        return getState(name, context, type);
    },
    /**
     * Get the block ID
     * @param context The context of the block
     * @constructor
     */
    GetBlockId: (context: BlockContext) => {
        return context.block.hash;
    },
    /**
     * Execute a custom function on the block context and return the result
     * @param context The context of the block
     * @param unitState The id of the shape unit
     * @param func The name of the function to execute
     * @constructor
     */
    Custom: (
        context: BlockContext,
        unitState: WeakTypedState,
        [func]: [ContextFunction],
    ) => {
        return parse(func(context, unitState), context, unitState);
    },
    /**
     * Returns a value if the state variable belongs to any of the cases
     * @param context The context of the block
     * @param unitState The id of the shape unit
     * @param args The cases to check
     * @constructor
     */
    Switch: <Type extends NirbyVariable>(
        context: BlockContext,
        unitState: WeakTypedState,
        args: [string, TypeNameOf<Type>, Type, ...[string, Type][]],
    ) => {
        const [name, typeName, defaultCase, ...cases] = args;
        let casePair: [string, Type];
        for (casePair of cases) {
            const [case_, value] = casePair;
            if (case_ === getState(name, context, typeName)) {
                return value;
            }
        }
        return defaultCase;
    },
    IfElseBlock: (
        context: BlockContext,
        unitState: WeakTypedState<string>,
        args: [
            string,
                NirbyVariable | BaseFunctionObject,
                NirbyVariable | BaseFunctionObject
        ],
    ) => {
        const [name, ifTrue, ifFalse] = args;
        return parse(
            context.state.state.value[name] ? ifTrue : ifFalse,
            context,
            unitState,
        );
    },
    IfElseUnit: (context, unitState: WeakTypedState<string>, args) => {
        const [name, ifTrue, ifFalse] = args;
        return parse(
            unitState.value[name] ? ifTrue : ifFalse,
            context,
            unitState,
        );
    },
    /**
     * If Else hover
     * @param context The context of the block
     * @param unitState The unit state
     * @param name The name of the style key
     * @constructor
     */
    ChangeColorLightness: <TB extends NirbyBlock>(
        context: BlockContext<TB>,
        unitState: WeakTypedState<string>,
        [color, ratio, fallbackColor]: [
                string | BaseFunctionObject,
            number,
                string | BaseFunctionObject | null
        ],
    ) => {
        const colorObject = Color(parse<string>(color, context, unitState));
        const fallbackColorObject = fallbackColor
            ? Color(parse<string>(fallbackColor, context, unitState))
            : null;
        let highlight: Color;

        if (fallbackColorObject !== null && colorObject.alpha() < 0.1) {
            highlight = fallbackColorObject;
        } else {
            // Get highlight color that'll always have alpha of 1
            highlight = colorObject.isDark()
                ? colorObject.lighten(ratio)
                : colorObject.darken(ratio);
            highlight = highlight.alpha(
                clamp(colorObject.alpha() + ratio * 3, 0, 1),
            );
        }
        return highlight.toString();
    },
    GetHighlightColorFor: <TB extends NirbyBlock>(
        context: BlockContext<TB>,
        unitState: WeakTypedState<string>,
        [color, fallbackColor]: [
                string | BaseFunctionObject,
                string | BaseFunctionObject | null
        ],
    ) => {
        const backgroundColor = tryParseColor(
            parse<string>(color, context, unitState),
        );
        if (backgroundColor.alpha() === 0) {
            return parse<string>(
                fallbackColor ?? '#000000',
                context,
                unitState,
            );
        }
        return backgroundColor.isDark()
            ? '#ffffff'
            : backgroundColor.darken(0.8).toString();
    },
    Translate: (
        context: BlockContext,
        unitState: WeakTypedState,
        [key]: [string | BaseFunctionObject],
    ): string => {
        return context.context.translator.translate(
            parse(key, context, unitState),
        );
    },
    GetBlockContentOrElse: <TB extends NirbyBlock>(
        context: BlockContext,
        unitState: WeakTypedState,
        [name, fallback]: [string | BaseFunctionObject, NirbyVariable],
    ): NirbyVariable => {
        const content: TB['content'] = context.block.content;
        const key = parse(name, context, unitState) as keyof TB['content'];
        return (content[key] ?? fallback) as NirbyVariable;
    },
};

/**
 * Call a function object with the given arguments.
 * @param funObj The function object to call.
 * @param context The context of the block.
 * @param unitState The id of the shape unit
 *
 * @returns - The result of the function call.
 */
export function callFunction<Fn extends FunctionObject = FunctionObject>(
    funObj: Fn,
    context: BlockContext,
    unitState: WeakTypedState<string>,
): InferFunctionObjectReturnType<Fn> {
    const fn = functionRegistry[funObj.method];
    if (!fn) {
        Logger.warnStyled('PLAYER', `Function ${funObj.method} not found`);
        return '';
    }
    return fn(
        context,
        unitState,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        funObj.arguments as any,
    );
}

/**
 * Parse a value from the context state.
 * @param value The value to parse.
 * @param context The context of the block.
 * @param unitState The id of the shape unit
 *
 * @returns - The parsed value.
 */
export function parse<T extends NirbyVariable>(
    value: NirbyVariable | BaseFunctionObject,
    context: BlockContext,
    unitState: WeakTypedState<string>,
): T {
    if (typeof value === 'object') {
        return callFunction(value as FunctionObject, context, unitState) as T;
    }
    return value as T;
}
