import {z} from 'zod';

export type RouteConditionType = 'match' | 'begins-with';

export interface RouteCondition {
    type: RouteConditionType;
    content: string;
}

export const ROUTE_CONDITION_SCHEMA = z.object({
    type: z.union([
        z.literal('match'),
        z.literal('begins-with'),
    ]),
    content: z.string(),
});
