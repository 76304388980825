import {Component, TrackByFunction} from '@angular/core';
import {BlockActionComponent} from '../../blocks/block-action.component';
import {GoToPrimeAction} from '@nirby/models/nirby-player';
import {DropdownItem, Form} from '@nirby/shared/widgets';
import {PrimeSourcesService, PrimesService, WorkspaceService} from '@nirby/shared/database';
import {Observable, of, switchMap, tap} from 'rxjs';
import {UntypedFormControl} from '@angular/forms';
import {NirbyDocument} from '@nirby/store/base';
import {map} from 'rxjs/operators';
import {faStar} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'nirby-action-go-to-prime',
    templateUrl: './action-go-to-prime.component.html',
    styleUrls: ['./action-go-to-prime.component.scss'],
})
/**
 * Form component for the GoToPrimeAction.
 */
export class ActionGoToPrimeComponent extends BlockActionComponent<GoToPrimeAction> {
    formG = Form.group<GoToPrimeAction['options']>({
        primeId: null,
        sourceId: null,
        time: 0,
        variablesHandling: 'keep',
        ignoreAggroGeneration: false,
    });
    primeControl = this.formG.controls.primeId as UntypedFormControl;
    sourceControl = this.formG.controls.sourceId as UntypedFormControl;

    timeControl = this.formG.controls.time as UntypedFormControl;
    ignoreAggroGenerationControl = this.formG.controls.ignoreAggroGeneration as UntypedFormControl;

    form = this.formG.form;
    primes$: Observable<DropdownItem<string | null>[]> =
        this.primes.workspacePrime$.pipe(
            map((primes): DropdownItem<string | null>[] => [
                {
                    label: 'No Prime selected',
                    value: null,
                },
                ...primes.map(
                    (prime): DropdownItem<string> => ({
                        label: prime.data.name,
                        value: prime.id,
                    }),
                ),
            ]),
        );

    public readonly icons = {
        startVideo: faStar,
    };

    sources$: Observable<DropdownItem<string | null>[] | null> = this.formG.watch('primeId').pipe(
        switchMap(
            primeId => primeId !== null ?
                this.sources.getSourcesForPrime(this.workspaces.workspaceId, primeId) :
                of(null),
        ),
        map((sources): DropdownItem<string | null>[] | null => {
            if (sources !== null) {
                return [
                    {
                        label: 'Start video',
                        value: null,
                    },
                    ...sources.map((source): DropdownItem<string> => ({
                        label: source.data.name,
                        value: source.id,
                    })),
                ];
            }
            return null;
        }),
        tap(sources => {
            const sourceId = this.formG.get('sourceId');
            if (sources && sourceId && !sources.map(s => s.value).includes(sourceId)) {
                this.formG.set('sourceId', null);
            }
        }),
    );

    /**
     * Functions to track an item in the Prime and sources array.
     * @param index The index of the item in the array.
     * @param item The item in the array.
     *
     * @returns The item's id.
     */
    trackByFn: TrackByFunction<NirbyDocument<object>> = (
        index,
        item: NirbyDocument<object>,
    ): string | null => {
        return item?.id ?? null;
    };

    /**
     * Constructor.
     * @param workspaces Workspace service.
     * @param primes Prime service.
     * @param sources Prime source service.
     */
    constructor(
        private readonly workspaces: WorkspaceService,
        private readonly primes: PrimesService,
        private readonly sources: PrimeSourcesService,
    ) {
        super();
    }

    /**
     * Sets the form data.
     * @param content The content to set.
     */
    setData(content: GoToPrimeAction | null): void {
        if (content) {
            this.formG.value = {
                primeId: content.options.primeId,
                sourceId: content.options.sourceId,
                time: content.options.time,
                variablesHandling: 'keep',
                ignoreAggroGeneration: content.options.ignoreAggroGeneration ?? false,
            };
        }
    }


    /**
     * Go to the video.
     * @param primeId - Prime ID.
     * @param sourceId - Source ID.
     *
     * @returns The URL.
     */
    getPrimeSourceEditorUrl(primeId: string, sourceId?: string): string {
        const workspaceId = this.workspaces.workspaceId;
        if (!sourceId) {
            return `/workspaces/${workspaceId}/primes/${primeId}/editor`;
        }
        return `/workspaces/${workspaceId}/primes/${primeId}/editor?sourceId=${sourceId}`;
    }

    /**
     * Gets the URL to preview a Source.
     * @param primeId - Prime ID.
     * @param sourceId - Source ID.
     *
     * @returns The URL.
     */
    getPreviewUrlForSource(primeId: string, sourceId: string): string {
        const workspaceId = this.workspaces.workspaceId;
        return `/workspaces/${workspaceId}/primes/${primeId}/preview?_v=${sourceId}`;
    }
}
