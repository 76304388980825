import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {StorageReference} from '@angular/fire/storage';

export interface UserFileMetadata {
    size: number;
}

export interface UserFile {
    uploaderUserId: string;
    ref: StorageReference;
    name: string;
    url: string;
    metadata: UserFileMetadata;
}

export interface UploadTask<T> {
    file: Promise<UserFile>;
    progress$: Observable<number>;
    metadata: Promise<T>;
}

@Injectable({
    providedIn: 'root',
})
export abstract class FileUploadService {
    public abstract uploadPublic(
        file: File,
        parent: string
    ): Promise<UploadTask<null>>;

    public abstract uploadPrivate(
        file: File,
        parent: string
    ): Promise<UploadTask<null>>;
}
