import {Logger} from '@nirby/logger';
import googleFonts from 'google-fonts';
import FontFaceObserver from 'fontfaceobserver';
import {FontSource} from '@nirby/media-models';
import {FontStyle} from '../designs';

interface GoogleFontsList {
    [key: string]: boolean | string | string[];
}

export const DEFAULT_FONT = 'Roboto';
export const ICON_FONT = 'FontAwesome';

const LOADED_FONTS = new Set<string>();
LOADED_FONTS.add(DEFAULT_FONT);
LOADED_FONTS.add(ICON_FONT);

/**
 * Try to get a font name from the already loaded ones
 * @param font Font Name
 *
 * @returns The font name if it is already loaded, otherwise ${@link DEFAULT_FONT}
 */
export function tryGetFont(font?: string | FontSource): string {
    if (!font || typeof font !== 'string') {
        return DEFAULT_FONT;
    }

    if (LOADED_FONTS.has(font)) {
        return font;
    }

    return DEFAULT_FONT;
}

/**
 * Loads a font.
 * @param font Font Name
 * @param style Font Style
 *
 * @returns A promise that resolves when the font is loaded
 */
export async function loadFont(font: FontSource | string | undefined | null, style: FontStyle): Promise<boolean> {
    const fontName =
        (typeof font !== 'string' ? font?.name : font) ?? DEFAULT_FONT;
    const observer = new FontFaceObserver(fontName, {style});

    try {
        await observer.load(null, 500);
        LOADED_FONTS.add(fontName);
        return true;
    } catch (e) {
        Logger.logStyled('BLOCK', 'Adding new font: ', fontName);
    }
    const fonts: GoogleFontsList = {};
    fonts[fontName] = ['regular', '400', '400i', '700', '700i'];

    googleFonts.add(fonts);
    try {
        await observer.load(null, 5000);
        Logger.logStyled('BLOCK:FONT', fontName, 'loaded');
        LOADED_FONTS.add(fontName);
        return true;
    } catch (e) {
        Logger.errorStyled('BLOCK:FONT', fontName, 'failed to load');
        return false;
    }
}
