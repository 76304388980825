import {FullProductId} from '@nirby/analytics-typings';
import {Contact, NirbyContext} from '@nirby/runtimes/context';
import {NirbyVariableNullable, WeakAttributes} from '@nirby/runtimes/state';
import {NirbyApi} from './nirby-api';
import {Logger} from '@nirby/logger';

/**
 * Service for handling contacts through the API.
 */
export class ContactsApi {
    /**
     * Constructor.
     * @param api The API service.
     */
    constructor(private api: NirbyApi) {}

    /**
     * Creates a contact at the server
     * @param productId Product ID
     * @param properties Properties to set at the contact
     * @param uniqueAlias An alias to identify the contact
     * @param group Group to add the contact to
     * @private
     */
    public async create(
        productId: FullProductId,
        properties: WeakAttributes = {},
        uniqueAlias?: string,
        group?: string,
    ): Promise<Contact | null> {
        let response: { token: string; contactId: string };
        const body = {
            productId: productId.asProductId().uniqueId,
            properties,
            ...(group ? { group } : {}),
            ...(uniqueAlias ? { uniqueAlias } : {}),
        };
        try {
            response = await this.api.post<
                { token: string; contactId: string },
                { properties: WeakAttributes }
            >(`/workspaces/${productId.workspaceId}/contacts`, body);
            return new Contact(response.token);
        } catch (e) {
            Logger.errorStyled('CONTACT:API', e);
            return null;
        }
    }

    /**
     * Updates a contact at the server
     * @param contact The contact to update
     * @param workspaceId Workspace ID
     * @param properties Properties
     * @param returnRemoteUpdated Whether to return the updated contact
     */
    public async update(
        contact: Contact,
        workspaceId: string,
        properties: WeakAttributes = {},
        returnRemoteUpdated = false,
    ): Promise<Contact | null> {
        if (!contact.token) {
            Logger.warnStyled(
                'CONTACT:UPDATE',
                'Update failed, contact is not authenticated',
            );
            return null;
        }

        if (Object.keys(properties).length > 25) {
            Logger.warnStyled(
                'CONTACT:UPDATE',
                'Tried to update contact with too many properties: ',
                properties,
            );
            return null;
        }
        try {
            Logger.logStyled(
                'CONTACT:UPDATE',
                'Updating contact with properties: ',
                properties,
            );
            const response = await this.api.put<
                { message: string; token?: string },
                {
                    properties: WeakAttributes;
                    token: string;
                }
            >(
                `/workspaces/${workspaceId}/contacts/${contact.id}?returnToken=${returnRemoteUpdated}`,
                { properties, token: contact.token },
            );
            if (response.token) {
                return new Contact(response.token);
            } else {
                return null;
            }
        } catch (e) {
            Logger.errorStyled('CONTACT:API', e);
            return null;
        }
    }

    /**
     * Updates the answer of a contact for a given question
     * @param workspaceId Workspace ID
     * @param contact The contact to update
     * @param questionId The question ID
     * @param answer The answer
     */
    async answerQuestion(
        workspaceId: string,
        contact: Contact,
        questionId: string,
        answer: NirbyVariableNullable,
    ): Promise<void> {
        const answerKey = `${questionId}${NirbyContext.PROPERTIES_SPLITTER}answer`;
        await this.update(contact, workspaceId, {
            [answerKey]: answer,
        });
    }
}
