import {Injectable} from '@angular/core';
import {firstValueFrom, Observable, of} from 'rxjs';
import {DateTime, Duration} from 'luxon';
import {first, map} from 'rxjs/operators';
import {isNowBefore} from '@nirby/shared/widgets';
import {shareReplaySafe} from '@nirby/ngutils';
import {Logger} from '@nirby/logger';

interface MaintenanceTime {
    start: string;
    duration: number;
    timezone: string;
}

export interface Period {
    start: DateTime;
    end: DateTime;
}

@Injectable({
    providedIn: 'root',
})
export class MaintenanceService {

    readonly nextMaintenance$: Observable<Period | null> =
        this.getParameterObjectWithKey<MaintenanceTime>(
            'next_maintenance',
        ).pipe(
            map((nextMaintenance) => {
                if (!nextMaintenance) {
                    return null;
                }
                const period =
                    MaintenanceService.maintenanceTimeToPeriod(nextMaintenance);
                if (!period) {
                    return null;
                }
                return isNowBefore(period.end) ? period : null;
            }),
            shareReplaySafe(),
        );

    private static maintenanceTimeToPeriod(
        nextMaintenance: MaintenanceTime,
    ): Period | null {
        const {start, duration, timezone} = nextMaintenance;
        const startTime = DateTime.fromSQL(start, {zone: timezone}).toLocal();
        if (!startTime.isValid) {
            Logger.errorStandard(
                'CONFIG',
                'MaintenanceGuard',
                `Problem parsing next maintenance time: ${start}`,
            );
            return null;
        }
        const endTime = startTime.plus(Duration.fromMillis(duration * 1000));
        return {start: startTime, end: endTime} as Period;
    }

    private async fetchParameter<T>(key: string): Promise<T | null> {
        return await firstValueFrom(
            this.getParameterObjectWithKey<T>(key).pipe(first()),
        );
    }

    public async fetchIsInMaintenance(): Promise<boolean> {
        return false;
    }

    private getParameterObjectWithKey<T>(key: string): Observable<T | null> {
        return of(null);
    }
}
