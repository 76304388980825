import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Component} from '@angular/core';
import {faBars, faEllipsisH, faLock, faPlus, faSave, faTrash} from '@fortawesome/free-solid-svg-icons';
import {RoutePanelModalComponent} from '../modal/route-panel-modal/route-panel-modal.component';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {AlertsService} from '@nirby/shared/alerts';
import {PublishedService, RouteParametersService, WorkspaceService} from '@nirby/shared/database';
import {RouteCondition} from '@nirby/models/nirby-player';
import {RouteDisplay} from '@nirby/models/editor';
import {PlansService} from '../../../services/plans.service';
import {AppError} from '@nirby/js-utils/errors';

@Component({
    selector: 'nirby-routes-panel',
    templateUrl: './routes-panel.component.html',
    styleUrls: ['./routes-panel.component.scss'],
})
export class RoutesPanelComponent {
    constructor(
        private modal: NgbModal,
        private router: Router,
        private published: PublishedService,
        private widgetService: WorkspaceService,
        private plans: PlansService,
        private routeParams: RouteParametersService,
        private activatedRoute: ActivatedRoute,
        private alerts: AlertsService,
    ) {
        this.activatedRouteId$ = this.routeParams.watchParam('routeId');
        this.priorityList$ = this.routeParams.widgetId$.pipe(
            switchMap((widgetId) => this.widgetService.collection.get(widgetId)),
            map((w) => w?.data?.priorityList ?? []),
            tap((p) => (this.priorityList = p)),
        );
        this.canAddRoute$ = of(true);
    }

    public get widgetId(): string {
        return this.routeParams.workspaceId;
    }

    public get routeRoute(): ActivatedRoute {
        const routes = this.routeParams.allRoutesSnapshot(this.activatedRoute);
        return routes[routes.length - 1];
    }

    public get routeId(): string {
        return this.routeParams.getParamSafe('routeId');
    }

    barsIcon = faBars;
    ellipsisIcon = faEllipsisH;
    iconTrash = faTrash;

    priorityList: RouteDisplay[] | null = null;
    canAddRoute$: Observable<boolean>;

    activatedRouteId$: Observable<string | null>;
    priorityList$: Observable<RouteDisplay[]>;
    iconAdd = faPlus;
    iconSave = faSave;

    isLoading = false;
    lockIcon = faLock;

    openModal(): void {
        this.modal.open(RoutePanelModalComponent, {
            centered: true,
            size: 'lg',
        });
    }

    async removeRoute(route: RouteDisplay): Promise<void> {
        if (!this.priorityList) {
            throw new AppError('No priority list found');
        }
        const response = await this.alerts.askConfirmation(
            '¿Are you sure you want to delete this route?',
            'If you remove this route, no Pops will show on this route',
            'Remove',
        );
        if (!response) {
            return;
        }

        const deletedRouteIndex = this.priorityList.indexOf(route);

        if (deletedRouteIndex !== undefined && deletedRouteIndex !== -1) {
            this.priorityList.splice(deletedRouteIndex, 1);

            if (this.routeId === route.routePath.id) {
                await this.router.navigate([
                    '/workspaces',
                    this.widgetId,
                    'routes',
                ]);
            }

            const newPriorityList = this.priorityList;
            await this.widgetService.collection.runTransaction(async (transaction) => {
                await this.widgetService.collection.updateTransaction(
                    transaction,
                    this.widgetId,
                    {priorityList: newPriorityList},
                );
                await transaction.delete(route.routePath);
            });
        }
    }

    async drop(event: CdkDragDrop<RouteDisplay[]>): Promise<void> {
        if (this.priorityList && this.widgetId && event.currentIndex !== 0) {
            moveItemInArray(
                this.priorityList,
                event.previousIndex,
                event.currentIndex,
            );
            await this.widgetService.update(this.widgetId, {
                priorityList: this.priorityList,
            });
        }
    }

    async save(): Promise<void> {
        this.isLoading = true;
        await this.published.updatePublished(this.widgetId);
        this.isLoading = false;
        await this.alerts.showSuccess('The routes settings have been applied');
    }

    normalizeRoute(route: string | RouteCondition): RouteCondition {
        return typeof route === 'string'
            ? {
                content: route,
                type: 'match',
            }
            : route;
    }

    getRouteTypeDisplayName(route: string | RouteCondition): string {
        const normalized = this.normalizeRoute(route);
        switch (normalized.type) {
            case 'match':
                return 'Exact match';
            case 'begins-with':
                return 'Contains';
        }
    }

    getRouteDisplayName(route: string | RouteCondition): string {
        const normalized = this.normalizeRoute(route);
        return normalized.content;
    }
}
