<form [formGroup]="form">
    <div class="form-group" *ngIf="primes$ | async as primes">
        <label for="prime">Prime</label>
        <nirby-dropdown-filter
            id="prime"
            [filter]="true"
            [items]="primes"
            [formControl]="primeControl"
        ></nirby-dropdown-filter>
    </div>
    <div class="form-group" *ngIf="sources$ | async as sources">
        <label for="source">Source</label>
        <nirby-dropdown-filter
            id="source"
            [filter]="true"
            [items]="sources"
            [formControl]="sourceControl"
        ></nirby-dropdown-filter>
        <nirby-video-timestamp-picker
            *ngIf="sourceControl.value"
            [formControl]="timeControl"
        ></nirby-video-timestamp-picker>
    </div>
    <label for="ignore-aggro-generation">Ignore Aggro Generation</label>
    <nirby-switch-control
        id="ignore-aggro-generation"
        [formControl]="ignoreAggroGenerationControl"
    ></nirby-switch-control>
</form>
<div class="d-flex flex-row mt-2">
    <a
        class="btn btn-brand-primary"
        [href]="getPrimeSourceEditorUrl(primeControl.value, sourceControl.value)"
        target="_blank"
    >
        Go to source <i class="ms-1 fa fa-play"></i>
    </a>
    <a class="btn btn-brand-primary ms-2"
       *ngIf="sourceControl.value"
       [href]="getPreviewUrlForSource(primeControl.value, sourceControl.value)"
       target="_blank">
        Preview <i class="ms-1 fa fa-external-link"></i>
    </a>
</div>
