<div class="d-flex flex-column ms-1" *ngIf="goToOptions$ | async as options; else loading">
    <form [formGroup]="form">
        <nirby-dropdown-filter
            [formControl]="videosControl"
            [items]="options"
            [filter]="true"
            styleClass="w-100"
        >
        </nirby-dropdown-filter>
        <nirby-video-timestamp-picker
            [formControl]="timeControl"
        ></nirby-video-timestamp-picker>
        <label for="ignore-aggro-generation">Ignore Aggro Generation</label>
        <nirby-switch-control
            id="ignore-aggro-generation"
            [formControl]="ignoreAggroGenerationControl"
        ></nirby-switch-control>
    </form>
    <div class="d-flex flex-row mt-2">
        <a class="btn btn-brand-primary"
           [routerLink]="[]"
           [queryParams]="{sourceId: videosControl.value}"
           queryParamsHandling="merge"
           (click)="closeRequest.emit()"
        >
            Go to source <i class="ms-1 fa fa-play"></i>
        </a>
        <a
            *ngIf="videosControl.value"
            class="btn btn-brand-primary ms-2"
            [href]="getPreviewUrlForSource(videosControl.value)"
            target="_blank"
        >
            Preview <i class="ms-1 fa fa-external-link"></i>
        </a>
    </div>
</div>
<ng-template #loading>
    <div class="spinner spinner-border"></div>
</ng-template>
