import {BehaviorSubject, map, Observable} from 'rxjs';

export class ObjectSubject<T extends object> {
    private readonly subject: BehaviorSubject<T>;

    constructor(initialValue: T) {
        this.subject = new BehaviorSubject(initialValue);
    }

    public next(value: T): void {
        this.subject.next(value);
    }

    public set<TK extends keyof T>(key: TK, value: T[TK]): void {
        const currentValue = this.subject.getValue();
        currentValue[key] = value;
        this.subject.next(currentValue);
    }

    public get<TK extends keyof T>(key: TK): T[TK] {
        return this.subject.getValue()[key];
    }

    public watch<TK extends keyof T>(key: TK): Observable<T[TK]> {
        return this.subject.pipe(
            map(value => value[key])
        );
    }

    asObservable(): Observable<T> {
        return this.subject.asObservable();
    }
}
