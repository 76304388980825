import { Injectable, Type } from '@angular/core';
import { ComponentRegisterService } from '../component-register';
import { BlockRectangleStyleComponent } from '../../components/blocks/rectangle/block-rectangle-style/block-rectangle-style.component';
import { IconStyleComponent } from '../../components/blocks/icon/icon-style/icon-style.component';
import { ButtonStyleEditorComponent } from '../../components/blocks/button/button-style-editor/button-style-editor.component';
import { BlockStyleComponent } from '../../components/blocks/block-style.component';
import { BlockImageStyleComponent } from '../../components/blocks/image/block-image-style/block-image-style.component';
import { AnyBlockType } from '@nirby/models/nirby-player';

@Injectable({
    providedIn: 'root',
})
export class BlockStyleRegisterService extends ComponentRegisterService<
    AnyBlockType,
    BlockStyleComponent<any>
> {
    protected register = new Map<AnyBlockType, Type<BlockStyleComponent<any>>>([
        ['Image', BlockImageStyleComponent],
        ['Rectangle', BlockRectangleStyleComponent],
        ['Button', ButtonStyleEditorComponent],
        ['Icon', IconStyleComponent],
    ]);
}
