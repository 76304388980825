import { AppError } from '@nirby/js-utils/errors';
import { v4 } from 'uuid';

export function generateId(length = 16): string {
    return v4();
}

const SPLITTER = '::';

export function joinId(...ids: string[]): string {
    return ids.join(SPLITTER);
}

export function splitId2(id: string): [string, string] {
    const split = splitId(id);
    if (split.length !== 2) {
        throw new AppError(
            `${id} isn't composed of two IDs split by ${SPLITTER}`,
        );
    }
    return [split[0], split[1]];
}

export function splitId(id: string): string[] {
    return id.split('::');
}
