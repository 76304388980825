/* eslint-disable no-console */
type LoggerStyles = {
    [K in 'base' | 'warning' | 'success']: string[];
};

export type LoggerColors = Record<string, [string, string]>;

/**
 * Logger class to log messages to the console.
 */
export class Logger {
    private static readonly styles: LoggerStyles = {
        base: ['padding: 2px 6px', 'border-radius: 12px'],
        warning: ['color: #eee'],
        success: [],
    };

    private static production = false;
    private static logOnly: string[] | null = null;

    private static colors: LoggerColors = {
        'ROUTE-PARAMS': ['#fff', '#c3298c'],
        DB: ['#fff', '#ffb37c'],
        'DB:CONNECTED': ['#fff', '#3ec111'],
        'DB:DISCONNECTED': ['#fff', '#7ab8f8'],
        'DB:CACHE': ['#fff', '#a75e13'],
        'DB:NEW-DATA': ['#fff', '#a75e13'],
        'BLOCK-EDITOR': ['#fff', '#4d2ccf'],
        'ACTION-INPUT': ['#fff', '#4f75e5'],
        MIGRATION: ['#ffffff', '#008a4c'],
        LOGGER: ['#2b7fff', '#ffffff'],
    };

    /**
     * If the logger is in production mode.
     */
    static get prodMode(): boolean {
        return this.production;
    }

    /**
     * Gets the strings to log a styled message.
     * @param tag The tag of the message.
     * @param message The message to log.
     * @private
     *
     * @returns The strings to log a styled message.
     */
    private static getStyled(
        tag: string,
        ...message: unknown[]
    ): [string, string, ...unknown[]] {
        tag = tag.toUpperCase();
        const parentTag = tag.split(':')[0];
        const [color, bgColor] = Logger.colors[tag] ??
            Logger.colors[parentTag] ?? ['#fff', '#444'];
        const style = `${Logger.styles.base.join(
            ';'
        )};background-color:${bgColor};color:${color}`;
        return [`%c${tag}`, style, ...message];
    }

    /**
     * Sets up the logger.
     * @param production If the logger is in production mode. If true, the logger will not log anything.
     * @param logOnly The tags to log. If null, all tags will be logged.
     * @param colors The colors to use for the tags.
     */
    public static setUp(
        production: boolean,
        logOnly: string[] | null,
        colors?: LoggerColors
    ): void {
        Logger.production = production;
        Logger.logOnly = logOnly;
        Logger.colors = colors ?? Logger.colors;
    }

    /**
     * Logs an error.
     * @param tag The tag of the message.
     * @param message The message to log.
     */
    public static errorStyled(tag: string, ...message: unknown[]): void {
        if (!Logger.shouldBeLogged(tag)) {
            return;
        }
        console.error(...Logger.getStyled(tag, message));
    }

    /**
     * Logs a warning.
     * @param tag The tag of the message.
     * @param message The message to log.
     */
    public static warnStyled(tag: string, ...message: unknown[]): void {
        console.warn(...Logger.getStyled(tag, ...message));
    }

    /**
     * Logs a simple message.
     * @param tag The tag of the message.
     * @param message The message to log.
     */
    public static logStyled(tag: string, ...message: unknown[]): void {
        if (!Logger.shouldBeLogged(tag)) {
            return;
        }
        console.log(...Logger.getStyled(tag, ...message));
    }

    /**
     * Checks if a message of a given tag should be logged.
     * @param category The tag of the message.
     *
     * @returns If the message should be logged.
     */
    public static shouldBeLogged(category: string): boolean {
        if (this.production) {
            return false;
        }
        if (this.logOnly === null) {
            return true;
        }
        const categories: string[] = this.logOnly;
        return !!categories.find(
            (c) => c === category.toUpperCase().split(':')[0]
        );
    }

    /**
     * Logs a message.
     * @param message The message to log.
     */
    public static log(...message: unknown[]): void {
        this.logAt('LOG', ...message);
    }

    /**
     * Logs a simple tagged message.
     * @param category The tag of the message.
     * @param message The message to log.
     */
    public static logAt(category: string, ...message: unknown[]): void {
        this.logStyled(category, ...message);
    }

    /**
     * Logs a simple warning.
     * @param message The message to log.
     */
    public static warn(...message: unknown[]): void {
        console.warn(...message);
    }

    /**
     * Logs a simple error.
     * @param message The message to log.
     */
    public static error(...message: unknown[]): void {
        console.error(...message);
    }

    /**
     * Logs an error with some standard information.
     * @param category The tag of the message.
     * @param method The method that caused the error.
     * @param data The data that caused the error.
     */
    public static errorStandard(
        category: string,
        method: string,
        data?: unknown
    ): void {
        if (!Logger.shouldBeLogged(category)) {
            return;
        }
        const style = `${Logger.styles.base.join(
            ';'
        )};background-color:red;color:white`;
        console.log(
            `%c[${category}:ERROR]`,
            style,
            'Error while executing ',
            method,
            '. Data: ',
            data
        );
    }
}
