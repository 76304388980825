import {Component, Input} from '@angular/core';
import {StripeProduct, StripeProductPrice} from '@nirby/models/billing';
import {NirbyDocument} from '@nirby/store/base';
import {AuthenticationService, WorkspaceService} from '@nirby/shared/database';
import {BillingService, PlanComparison} from '../../services/billing.service';
import {BehaviorSubject, combineLatest, map, Observable, of, switchMap, tap} from 'rxjs';
import {LoadScreenService} from '@nirby/shared/load-screen';
import {AlertsService} from '@nirby/shared/alerts';
import {WorkspaceSubscription} from '@nirby/models/editor';

interface PlanGroupItem {
    plan: NirbyDocument<StripeProduct>;
    price: NirbyDocument<StripeProductPrice>;
    limits: {
        videoDeliveryQuota: number | null;
        videoStorage: number | null;
        videoUploadQuota: number | null;
    }
}

interface PlanGroup {
    items: PlanGroupItem[] | null;
}

export interface PlanItemFeatureGroup {
    title: string | null;
    features: string[];
}

export interface PlanTableItemStyle {
    primaryColor: string;
    secondaryColor: string;
}

@Component({
    selector: 'nirby-plan-table-item',
    templateUrl: './plan-table-item.component.html',
    styleUrls: ['./plan-table-item.component.scss'],
})
export class PlanTableItemComponent {
    @Input() label: string | null = null;
    @Input() currentSubscription: WorkspaceSubscription | null = null;
    @Input() features: PlanItemFeatureGroup[] = [];
    @Input() tableStyle: PlanTableItemStyle = {
        primaryColor: 'rgb(77, 50, 231)',
        secondaryColor: 'rgb(77, 50, 231)',
    };

    /**
     * The plan group this item is for.
     * @param value The plan group.
     */
    @Input() set planGroup(value: string | null) {
        this.groupSubject.next(value);
    }

    @Input() set interval(value: StripeProductPrice['interval']) {
        this.intervalSubject.next(value);
    }

    private readonly groupSubject = new BehaviorSubject<string | null>(null);
    private readonly intervalSubject = new BehaviorSubject<StripeProductPrice['interval']>('month');

    public readonly planGroup$: Observable<PlanGroup | null> = this.groupSubject.pipe(
        switchMap((group): Observable<PlanGroup | null> => {
            if (!group) {
                return of<PlanGroup>({
                    items: null,
                });
            }

            return this.billing.getAllPlansOfGroup(group).pipe(
                switchMap((plans) => {
                    return this.getManyPlansItems(plans).pipe(
                        map((items): PlanGroup => ({
                                items,
                            }),
                        ));
                }),
            );
        }),
        tap(group => {
            if (group?.items?.length) {
                this.selectedItem = group.items[0] ?? null;
            }
        }),
    );

    selectedItem: PlanGroupItem | null = null;

    private minutesToHours(minutesStr: string | unknown): number {
        if (typeof minutesStr !== 'string') {
            return 0;
        }
        const minutes = parseInt(minutesStr);
        if (isNaN(minutes)) {
            return 0;
        }
        return minutes / 60;
    }

    private getPlanItems(plan: NirbyDocument<StripeProduct>): Observable<PlanGroupItem | null> {
        return this.intervalSubject.pipe(
            switchMap((interval): Observable<PlanGroupItem | null> => {
                    return this.billing.getPlanPrice(plan.id, interval).pipe(
                        map((price): PlanGroupItem | null => {
                            if (!price) {
                                return null;
                            }
                            return {
                                plan,
                                price,
                                limits: {
                                    videoDeliveryQuota: this.minutesToHours(plan.data.metadata?.videoDeliveryQuota),
                                    videoStorage: this.minutesToHours(plan.data.metadata?.videoStorage),
                                    videoUploadQuota: this.minutesToHours(plan.data.metadata?.videoUploadQuota),
                                },
                            };
                        }),
                    );
                },
            ));
    }

    private getManyPlansItems(plans: NirbyDocument<StripeProduct>[]): Observable<PlanGroupItem[]> {
        if (plans.length === 0) {
            return of([]);
        }
        return combineLatest(plans.map((plan): Observable<PlanGroupItem | null> => this.getPlanItems(plan))).pipe(
            map((items): PlanGroupItem[] => items
                .filter((item): item is PlanGroupItem => !!item)
                .sort((a, b) => {
                    if (a.price.data.unit_amount === null || b.price.data.unit_amount === null) {
                        return 0;
                    }
                    return a.price.data.unit_amount - b.price.data.unit_amount;
                }),
            ),
        );
    }

    /**
     * Constructor.
     * @param workspaces Workspaces service.
     * @param billing Billing service.
     * @param auth Authentication service.
     * @param loader Load screen service.
     * @param alerts Alerts service.
     */
    constructor(
        private readonly workspaces: WorkspaceService,
        private readonly billing: BillingService,
        private readonly auth: AuthenticationService,
        private readonly loader: LoadScreenService,
        private readonly alerts: AlertsService,
    ) {
    }


    /**
     * Creates a checkout session.
     * @param price The price.
     * @returns A promise that resolves when the checkout session has been created.
     */
    async createCheckoutSession(price: NirbyDocument<StripeProductPrice>): Promise<void> {
        const userId = await this.auth.getUserId();
        const url = await this.loader.untilCompletion(
            () => this.billing.createCheckoutSessionForSubscription(
                price.id,
                userId,
                this.workspaces.workspaceId,
            ),
            'block',
            'Creating checkout session...',
        ).catch(() => {
            this.alerts.showDanger('Failed to create checkout session.');
            return null;
        });
        if (url) {
            window.open(url, '_self');
        }
    }

    async updateSubscription(price: NirbyDocument<StripeProductPrice>) {
        const workspaceId = this.workspaces.workspaceId;
        const confirm = await this.alerts.askConfirmation(
            'Are you sure you want to update your subscription?',
            'Update Subscription',
            'Update',
        );
        if (!confirm) {
            return;
        }
        const result = await this.loader.untilCompletion(
            () => this.billing.changeSubscription(
                workspaceId,
                price.id,
            ),
        );
        if (result) {
            this.alerts.showSuccess('Subscription updated. Refreshing...');
            await new Promise(resolve => setTimeout(resolve, 1000));
            window.location.reload();
        } else {
            this.alerts.showDanger('Failed to update subscription.');
        }
    }

    comparePrice(currentSubscription: WorkspaceSubscription, product: NirbyDocument<StripeProduct>, price: NirbyDocument<StripeProductPrice>): PlanComparison {
        return this.billing.comparePrice(currentSubscription, product.data.metadata, price);
    }
}
