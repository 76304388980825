import {Injectable} from '@angular/core';
import {VideoLiteService} from './video-lite.service';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
/**
 * Service to get information from YouTube videos.
 */
export class YoutubeLiteService implements VideoLiteService {
    /**
     * Constructor.
     * @param config The configuration for the service.
     * @param http The HTTP client.
     */
    constructor(
        private http: HttpClient
    ) {
    }

    /**
     * Get the embed URL for a video.
     * @param id The video ID.
     *
     * @returns The embed URL.
     */
    getEmbedUrlFromId(id: string): string {
        return `https://www.youtube.com/embed/${id}`;
    }
}
