import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {v4} from 'uuid';

export interface DropdownItem<T> {
    label: string;
    value: T;
}

@Component({
    selector: 'nirby-dropdown-filter',
    templateUrl: './dropdown-filter.component.html',
    styleUrls: ['./dropdown-filter.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: DropdownFilterComponent,
            multi: true
        }
    ]
})
/**
 * Dropdown with filter helper.
 */
export class DropdownFilterComponent<T> implements ControlValueAccessor, OnInit {
    @Input() label: string | null = null;

    @Input() styleClass = '';
    @Input() items: DropdownItem<T>[] = [];
    @Input() hint: string | null = null;
    @Input() filter = false;

    value: T | null = null;
    disabled = false;
    controlId = 'id';
    onChange: (action: T) => void = () => {
        return;
    };
    onTouched = () => {
        return;
    };

    /**
     * Write a new action to the model.
     * @param obj - Action to write.
     */
    writeValue(obj?: T): void {
        this.value = obj ?? null;
    }

    /**
     * Initializes the control ID.
     */
    ngOnInit(): void {
        this.controlId = 'control-' + v4();
    }

    /**
     * Register a callback function that is called when the value has changed.
     * @param fn - Callback function.
     */
    registerOnChange(fn: (action: T) => void): void {
        this.onChange = fn;
    }

    /**
     * Register a callback function that is called when the control has been touched.
     * @param fn - Callback function.
     */
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    /**
     * Set the disabled state of the component.
     * @param isDisabled Whether the component should be disabled.
     */
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Notifies the control value accessor that a change is being made to the value.
     * @param value - New value.
     */
    update(value: T): void {
        this.value = value;
        this.onChange(value);
    }
}
