<div class="d-flex flex-column">
    <div class="d-flex">
        <label class="flex-grow-1" *ngIf="label" [for]="controlId">{{label}}</label>
        <nirby-hint *ngIf="hint" [hint]="hint" hintPosition="left"></nirby-hint>
    </div>
    <p-dropdown
        [selectId]="controlId"
        [styleClass]="styleClass"
        [disabled]="disabled"
        [options]="items"
        [ngModel]="value"
        (ngModelChange)="update($event)"
        [filter]="filter"
        optionLabel="label"
        optionValue="value"
        appendTo="body"
    ></p-dropdown>
</div>
