import {Inject, Injectable} from '@angular/core';
import {ImageService} from './image.service';
import {createApi} from 'unsplash-js';
import {ImageRecord} from '@nirby/media-models';
import {AppError} from '@nirby/js-utils/errors';
import {
    CREATORS_ENVIRONMENT,
    NirbyEnvironment,
} from '@nirby/environment/base';

@Injectable({
    providedIn: 'root',
})
export class UnsplashService extends ImageService<'unsplash'> {
    constructor(
        @Inject(CREATORS_ENVIRONMENT) private config: NirbyEnvironment
    ) {
        super();
    }

    protected api = createApi({
        accessKey: this.config.unsplash.accessKey,
    });

    async getImage(id: string): Promise<ImageRecord> {
        const response = await this.api.photos.get({photoId: id});
        if (!response.response) {
            throw new AppError('Unsplash response is undefined');
        }
        return {
            source: 'unsplash',
            lastUrl: response.response.urls.regular,
            id,
            metadata: {},
        };
    }

    async search(query: string): Promise<ImageRecord[]> {
        const response = await this.api.search.getPhotos({query});
        if (!response.response) {
            throw new AppError(`Unsplash response is ${response.response}`);
        }
        return response.response.results.map((result) => ({
            source: 'unsplash',
            lastUrl: result.urls.regular,
            id: result.id,
            metadata: {},
        }));
    }

    async getRefreshedRecord(source: ImageRecord): Promise<ImageRecord> {
        return {
            source: 'unsplash',
            lastUrl: source.lastUrl,
            id: source.id,
            metadata: source.metadata,
        };
    }
}
