import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input,} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';

export interface ButtonDisplay<T> {
    label?: string;
    icon?: IconDefinition;
    value: T;
}

@Component({
    selector: 'nirby-button-select',
    templateUrl: './button-select.component.html',
    styleUrls: ['./button-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ButtonSelectComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonSelectComponent<T> implements ControlValueAccessor {
    disabled = false;
    @Input() options: ButtonDisplay<T>[] = [];
    value?: T;

    onChange: (value: T) => void = () => {
        return;
    };
    onTouched: () => void = () => {
        return;
    };

    registerOnChange(fn: (value: unknown) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(value: T): void {
        this.value = value;
        this.cdr.detectChanges();
    }

    constructor(private cdr: ChangeDetectorRef) {}



    onStyleChange(option: T): void {
        this.value = option;
        this.onChange(option);
    }
}
